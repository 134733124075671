export const defaultData = {
  template: "MultipleDirectoryImagesTemplate",
  title: "example title",
  description: "example description",
  header: {
    color: "brand-red",
    text: "Use Cases",
    height: "360px",
    img: "/static/img/header-decorators/tablet-chocolat.png",
    hotDogColor: "brand-red",
    textColor: "white",
    title: "Example and use cases",
    subtitle: "Here you can find the best examples and use case<br/> Hilli you are amazing :)"
  },
  directories: [
    {
      type: "directory",
      props: {
        title: "Directory title",
        titleColor: "brand-red",
        listData: [
          {
            type: "link",
            props: {
              firstTitle: {
                text: "My title for thumbnail"
              },
              color: "brand-red",
              subtitle: "Another subtitle with long text",
              image: "/static/img/thumbnails/animated-lock-icon.jpg",
              link: "/link678",
              buttomLink: {
                show: true,
                text: "Read more >",
                url: "/link678",
                color: "brand-blue"
              }
            }
          }
        ]
      }
    }
  ],
  footerSignup: {},
  socialButtons: {
    show: false
  }
};
