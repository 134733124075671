import Colors from "styles/colors";

export const buttonThemes = {
  iris: {
    color: Colors["iris"],
    background: Colors["white"]
  },
  black: {
    color: Colors["mud"],
    background: Colors["white"]
  },
  white: {
    color: Colors["white"],
    background: Colors["mud"]
  }
};

// PLAY BUTTON SIZES
export const MD = "md";
export const LG = "lg";
