import { PureComponent } from "react";
import { componentStyles } from "./person-textual-info.scss";
import classnames from "classnames";
import DefaultProps from "./default-props";

export default class PersonTextualInfo extends PureComponent {
  getTextualInfoStyle() {
    const { color } = this.props;
    return { color };
  }

  render() {
    const { name, jobDescription, className } = this.props;

    return (
      <div className={classnames("person-textual-info", className)} style={this.getTextualInfoStyle()}>
        <span className="person-name">{name}</span>
        <span className="seperator">{" | "}</span>
        <span className="person-job-description">{jobDescription}</span>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

PersonTextualInfo.defaultProps = DefaultProps;
