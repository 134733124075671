import { useLayoutEffect, useCallback, useState } from "react";
import ResizeObserver from "resize-observer-polyfill";

const useRect = (ref) => {
  const [rect, setRect] = useState(getRect(ref ? ref.current : null));

  const updateRect = useCallback(() => {
    if (!ref.current) return;

    // Update client rect
    setRect(getRect(ref.current));
  }, [ref]);

  useLayoutEffect(() => {
    const element = ref.current;
    if (!element) return;

    updateRect();

    let resizeObserver = new ResizeObserver(() => updateRect());
    resizeObserver.observe(element);

    return () => {
      if (!resizeObserver) return;

      resizeObserver.disconnect();
      resizeObserver = null;
    };
  }, [ref.current]);

  useLayoutEffect(() => {
    window.addEventListener("scroll", updateRect);

    return () => {
      window.removeEventListener("scroll", updateRect);
    };
  }, [ref.current]);

  return rect;
};

function getRect(element) {
  if (!element) {
    return {
      height: 0,
      width: 0,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      clientHeight: 0,
      clientWidth: 0,
      offsetTop: 0,
      offsetBottom: 0,
      offsetLeft: 0,
      offsetRight: 0,
    };
  }

  const { clientHeight, clientWidth, offsetTop, offsetBottom, offsetLeft, offsetRight } = element;

  return {
    ...element.getBoundingClientRect(),
    clientHeight,
    clientWidth,
    offsetTop,
    offsetBottom,
    offsetLeft,
    offsetRight
  };
}

export default useRect;
