import { FIELD_TYPES } from "constants/template-field-type";
import CarouselFieldsConfig from "segments/desktop/core-components/carousel/fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Carousel",
  fields: {
    ...CarouselFieldsConfig
  }
};
