import { comparisonTable } from "../components";
import { FIELD_TYPES } from "constants/template-field-type";

export const COMPETITOR_TEMPLATE_CONFIG = {
  nameSignature: { type: "text", label: "Sign Your Name (optional)" },
  socialButtons: {
    type: "nested",
    label: "Social Buttons",
    fields: {
      show: { type: "boolean", label: "Show social buttons" },
      color: {
        type: "colorPicker",
        label: "Text Color",
        possibleColors: ["black", "white"]
      },
      blogStyle: { type: "boolean", label: "Blog style" }
    }
  },
  footer: {
    type: "nested",
    label: "Footer Config",
    fields: {
      preventFooter: { type: "boolean", label: "Prevent Footer" },
      disclaimer: { type: FIELD_TYPES.TEXT, label: "Disclaimer" }
    }
  },
  topSection: {
    type: "nested",
    label: "Top Section",
    fields: {
      title: { type: "text", label: "Title" },
      subtitle: { type: "text", label: "Subtitle" },
      bullets: {
        type: "multipleTexts",
        text: "Bullets"
      },
      asset: {
        type: "nested",
        label: "Image",
        fields: {
          shadow: { type: "boolean" },
          image: {
            type: "nested",
            label: "Image",
            fields: {
              src: {
                type: "gallery",
                label: "Select an image",
                defaultImagesKeys: ["static/img/templates/competitor/boards/"],
                options: [
                  {
                    value: "/static/img/boards/board-with-cactus-and-phone.png",
                    text: "Board with cactus and phone"
                  },
                  {
                    value: "/static/img/boards/board-with-column-picker.jpg",
                    text: "Board with column picker"
                  },
                  {
                    value: "/static/img/boards/board-with-conversation.png",
                    text: "Board with conversation"
                  },
                  {
                    value: "/static/img/boards/board-with-earphones.png",
                    text: "Board with earphones"
                  },
                  {
                    value: "/static/img/boards/board-with-notifications.jpg",
                    text: "Board with notifications"
                  },
                  {
                    value: "/static/img/boards/board-with-pencils.png",
                    text: "Board with pencils"
                  },
                  {
                    value: "/static/img/boards/board-with-person-picker.jpg",
                    text: "Board with person picker"
                  },
                  {
                    value: "/static/img/boards/board-with-status-menu.jpg",
                    text: "Board with status menu"
                  },
                  {
                    value: "/static/img/boards/board-with-yellow-coffee-cup.png",
                    text: "Board with yellow coffee cup"
                  },
                  {
                    value: "/static/img/boards/cactus-with-board.png",
                    text: "Board with cactus"
                  },
                  {
                    value: "/static/img/boards/upload-file-board.jpg",
                    text: "Board with upload file"
                  }
                ],
                otherOption: {
                  text: "Put your own URL...",
                  inputPlaceholder: "Put your own URL..."
                }
              },
              widthPercentage: {
                type: "text",
                label: "Image width % (Optional, 50-100)"
              },
              alt: { type: "text", label: "Image description (alt)" }
            }
          }
        }
      },
      hotDog: {
        type: "nested",
        label: "Hot Dog",
        fields: {
          color: {
            type: "colorPicker",
            label: "Color"
          }
        }
      }
    }
  },
  comparisonTable: comparisonTable,
  footerSignup: {
    type: "nested",
    label: "Bottom Signup Form",
    fields: {
      title: { type: "text", label: "Title" },
      subtitle: { type: "text", label: "Subtitle" }
    }
  },
  defaultData: {
    template: "CompetitorTemplate",
    topSection: {
      hotDog: {
        color: "brand-yellow"
      },
      asset: {
        image: {
          src: "/static/img/boards/board-with-cactus-and-phone.png"
        }
      },
      title: "May the best</br> product win!",
      subtitle: "monday.com is powerful enough to track everything your team is working on—so you can get results.",
      bullets: [
        "Manage your projects & tasks with ease ",
        "Plan visually on a timeline",
        "Centralize communication in one place"
      ]
    },
    comparisonTable: {
      title: "May the best product win!",
      subtitle: "monday.com is powerful enough to track everything your team is working on—so you can get results.",
      features: []
    }
  }
};
