import {useEffect} from "react";
import VerticalTabs from "segments/desktop/core-components/vertical-tabs/vertical-tabs-desktop/tabs/tabs";
import VerticalTab from "segments/desktop/core-components/vertical-tabs/vertical-tabs-desktop/tab/tab";
import { componentStyles } from "./product-cards-grid-medium-size-component.scss";
import ProductCardGridMediumSizeComponent from "./product-card-grid-component/product-card-grid-medium-size-component";
import DefaultProps, { productCardsImages, productIndexToProductType } from "./default-props";
import prefetchImages from "utils/images";
import {PRODUCTS_THEMES} from "/constants/products";


const ProductCardsGridMediumSizeComponent = (props) => {
  const { cards } = props;

  useEffect(() => {
    let urls = [];
    for (const [key, value] of Object.entries(productCardsImages)) {
      const { mediumScreenImage } = value;
      urls.push(mediumScreenImage?.src);
    }
    prefetchImages(urls);
  }, []);

  const getDefaultActiveTabIndex = () =>{
    let returnedIndex;
    cards?.forEach((card, index) => {
      if(card?.isHighlighted) {
        returnedIndex = returnedIndex === undefined ? index : Math.min(returnedIndex, index)
      };
    });
    return returnedIndex || 0;
    };

  const tabsRenderer = () => {
    return cards?.map((card, index) => {
      const {productType} = card;
      const tabImage = productType ? productCardsImages?.[productType]?.mobileImage: productCardsImages?.[productIndexToProductType[index]]?.mobileImage;
      const tabTheme = productType ? PRODUCTS_THEMES[productType] : PRODUCTS_THEMES[productIndexToProductType[index]];

      return (
        <VerticalTab tabImage={tabImage} theme={tabTheme}>
          <ProductCardGridMediumSizeComponent {...card} index={index}/>
        </VerticalTab>
      );
    });
  };


  return (
    <div className="product-cards-grid-medium-size-component">
      <div className="vertical-tabs-wrapper" role="tablist">
        <VerticalTabs {...props} defaultActiveTabIndex={getDefaultActiveTabIndex()}>
          {tabsRenderer()}
        </VerticalTabs>
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

ProductCardsGridMediumSizeComponent.defaultProps = DefaultProps;

export default ProductCardsGridMediumSizeComponent;
