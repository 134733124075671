import { PureComponent } from "react";
import classnames from "classnames";
import { componentStyles } from "./blog-card-item-footer-component.scss";
import PictureComponent from "components/core/picture/picture-component";
import LinkedinIcon from "static/svg/linkedin2.svg";
import FacebookIcon from "static/svg/facebook2.svg";
import TwitterIcon from "static/svg/twitter2.svg";
import SocialIconLinkComponent from "./social-icon-link/social-icon-link-component";

const SOCIAL_LINKS = [
  {
    icon: LinkedinIcon,
    color: "#006699"
  },
  {
    icon: FacebookIcon,
    color: "#4B69B0"
  },
  {
    icon: TwitterIcon,
    color: "1DA1F2"
  }
];

export default class BlogCardItemFooterComponent extends PureComponent {
  renderSocialLinks() {
    return SOCIAL_LINKS.map((socialLink, index) => {
      return (
        <div key={index} className="social-icon-link-component-wrapper">
          <SocialIconLinkComponent {...socialLink} />
        </div>
      );
    });
  }

  render() {
    const { isCardHoverd, writerImageSrc, writerName, articleDate, minutesToRead } = this.props;

    return (
      <div className="blog-card-item-footer-component">
        <div className="picture-component-wrapper">
          <PictureComponent src={writerImageSrc} />
        </div>

        <div className="blog-footer-details">
          <div className="writer-name">{writerName}</div>

          <div className="more-details">
            <span className="article-date">{articleDate}</span>
            <div className="seperated-dot" />
            <span className="minutes-to-read">{minutesToRead}</span>
          </div>
        </div>

        <div className={classnames("social-links", { show: isCardHoverd })}>{this.renderSocialLinks()}</div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
