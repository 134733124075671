import {
  PROJECTS_ORANGE_COLOR_NAME,
  MARKETING_RED_COLOR_NAME,
  CRM_GREEN_COLOR_NAME,
  DEV_GREEN_COLOR_NAME,
  WORK_OS_IRIS_COLOR_NAME
} from "styles/color-consts";

export default {
  tabs: [
    {
      label: "Work OS",
      tabImage:
        "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/EranHuberman/board-tabs/products/workos.png",
      asset: {
        video: {
          src: {
            formats: ["/static/products/board-tabs/workos-temp-video.mp4"]
          }
        }
      },
      color: WORK_OS_IRIS_COLOR_NAME,
      isMainTab: true
    },
    {
      label: "marketers",
      tabImage:
        "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/EranHuberman/board-tabs/products/marketing.png",
      tabImageSelected:
        "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/EranHuberman/board-tabs/products/marketing_selected.png",
      color: MARKETING_RED_COLOR_NAME,
      asset: {
        image: {
          src:
            "https://dapulse-res.cloudinary.com/image/upload/v1635714654/remote_mondaycom_static/uploads/EranHuberman/board-tabs/marketers.png"
        }
      }
    },
    {
      label: "CRM",
      tabImage:
        "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/EranHuberman/board-tabs/products/crm.png",
      tabImageSelected:
        "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/EranHuberman/board-tabs/products/crm_selected.png",
      color: CRM_GREEN_COLOR_NAME,
      asset: {
        image: {
          src:
            "https://dapulse-res.cloudinary.com/image/upload/v1635714654/remote_mondaycom_static/uploads/EranHuberman/board-tabs/marketers.png"
        }
      }
    },
    {
      label: "projects",
      tabImage:
        "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/EranHuberman/board-tabs/products/projects.png",
      tabImageSelected:
        "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/EranHuberman/board-tabs/products/projects_selected.png",
      color: PROJECTS_ORANGE_COLOR_NAME,
      asset: {
        image: {
          src:
            "https://dapulse-res.cloudinary.com/image/upload/v1635714654/remote_mondaycom_static/uploads/EranHuberman/board-tabs/projects.png"
        }
      }
    },
    {
      label: "dev",
      tabImage:
        "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/EranHuberman/board-tabs/products/dev.png",
      tabImageSelected:
        "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/EranHuberman/board-tabs/products/dev_selected.png",
      color: DEV_GREEN_COLOR_NAME,
      asset: {
        image: {
          src:
            "https://dapulse-res.cloudinary.com/image/upload/v1635714654/remote_mondaycom_static/uploads/EranHuberman/board-tabs/dev.png"
        }
      }
    },
    {
      label: "200+ workflows",
      tabImage:
        "https://dapulse-res.cloudinary.com/image/upload/v1635949178/remote_mondaycom_static/uploads/EranHuberman/board-tabs/products/plus_purple1.png",
      tabImageSelected:
        "https://dapulse-res.cloudinary.com/image/upload/v1635949052/remote_mondaycom_static/uploads/EranHuberman/board-tabs/products/plus1.png",
      color: WORK_OS_IRIS_COLOR_NAME,
      asset: {
        image: {
          src:
            "https://dapulse-res.cloudinary.com/image/upload/v1635714654/remote_mondaycom_static/uploads/EranHuberman/board-tabs/marketers.png"
        }
      }
    }
  ]
};
