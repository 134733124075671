import { FIELD_TYPES } from "constants/template-field-type";
import SelectableTagsGridFieldsConfig from "segments/desktop/core-components/selectable-tags-grid/fields-config";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import assetFieldsConfig from "components/core/asset/fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Vertical Tags with images",
  fields: {
    paragraphText: {
      type: FIELD_TYPES.NESTED,
      label: "Fold paragraph",
      fields: { ...FullParagraphFieldsConfig }
    },
    firstStateText: {
      type: FIELD_TYPES.NESTED,
      label: "First state paragraph",
      fields: { ...FullParagraphFieldsConfig }
    },
    ...SelectableTagsGridFieldsConfig
  }
};
