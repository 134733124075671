export const ICON_HEIGHT = 82;
export const ICON_WIDTH = 75;
export const LOGO_WRAPPER_HEIGHT = 340;
export const LOGO_WRAPPER_WIDTH = 340;
export const SVG_WIDTH = 1200;
export const SVG_HEIGHT= 600; 
export const PATH_WIDTH = 25;
export const CORNER_LENGTH = 15;

const LOGO_WIDTH = 200;
const AFTER_ANIMATION_RIGHT_ICON_X_LOCATION = 950;
const AFTER_ANIMATION_LEFT_ICON_X_LOCATION = 150;
const LONG_PATH_HORIZONTAL_LENGTH = 200;
const RIGHT_TO_LEFT = -1;
const LEFT_TO_RIGHT = 1;


export const ICONS_ARRAY = [
    {
      iconImage: "https://dapulse-res.cloudinary.com/image/upload/Integration_assets/Integrations-icon-1.png",
      startLocation: [100, 250],
      endLocation: [AFTER_ANIMATION_LEFT_ICON_X_LOCATION, 80]
    },
    {
      iconImage: "https://dapulse-res.cloudinary.com/image/upload/Integration_assets/Integrations-icon-2.png",
      startLocation: [300, 100],
      endLocation: [AFTER_ANIMATION_LEFT_ICON_X_LOCATION, 200]
    },
    {
      iconImage: "https://dapulse-res.cloudinary.com/image/upload/Integration_assets/Integrations-icon-3.png",
      startLocation: [300, 450],
      endLocation: [AFTER_ANIMATION_LEFT_ICON_X_LOCATION, 320]
    },
    {
      iconImage: "https://dapulse-res.cloudinary.com/image/upload/Integration_assets/Integrations-icon-4.png",
      startLocation: [500, 250],
      endLocation: [AFTER_ANIMATION_LEFT_ICON_X_LOCATION, 440]
    },
    {
      iconImage: "https://dapulse-res.cloudinary.com/image/upload/Integration_assets/Integrations-icon-5.png",
      startLocation: [600, 450],
      endLocation: [AFTER_ANIMATION_RIGHT_ICON_X_LOCATION, 80]
    },
    {
      iconImage: "https://dapulse-res.cloudinary.com/image/upload/Integration_assets/Integrations-icon-6.png",
      startLocation: [700, 100],
      endLocation: [AFTER_ANIMATION_RIGHT_ICON_X_LOCATION, 200]
    },
    {
      iconImage: "https://dapulse-res.cloudinary.com/image/upload/Integration_assets/Integrations-icon-7.png",
      startLocation: [900, 100],
      endLocation: [AFTER_ANIMATION_RIGHT_ICON_X_LOCATION, 320]
    },
    {
      iconImage: "https://dapulse-res.cloudinary.com/image/upload/Integration_assets/Integrations-icon-8.png",
      startLocation: [900, 450],
      endLocation: [AFTER_ANIMATION_RIGHT_ICON_X_LOCATION, 440]
    }
  ];

  export const LOGO_DATA ={
    xLocation: ((AFTER_ANIMATION_RIGHT_ICON_X_LOCATION+AFTER_ANIMATION_LEFT_ICON_X_LOCATION+ICON_WIDTH)/2)-(LOGO_WRAPPER_WIDTH/2),
    yLocation: ICONS_ARRAY[0].endLocation[1]+(ICONS_ARRAY[3].endLocation[1]+ICON_HEIGHT-ICONS_ARRAY[0].endLocation[1])/2 - (LOGO_WRAPPER_HEIGHT)/2
  };


export const LINES_DATA = [
  {startIcon: 1,
  endIcon:2 },
  {startIcon: 1,
    endIcon:5 },
  {startIcon: 2,
    endIcon:4 },
  {startIcon: 4,
    endIcon:7 },
  {startIcon: 6,
    endIcon:7 }
]

export const ICONS_PATHS_DATA = [
  {origin : 0, destination: 3, length: LONG_PATH_HORIZONTAL_LENGTH},
  {origin : 4, destination: 7, length: -LONG_PATH_HORIZONTAL_LENGTH}
]

const LOGO_SIDE_PATHS_HEIGHT = ICONS_ARRAY[0].endLocation[1]+(ICONS_ARRAY[3].endLocation[1]+ICON_HEIGHT-ICONS_ARRAY[0].endLocation[1])/2;

export const HORIZONTAL_PATHS_DATA=[
  {xStart:ICONS_ARRAY[0].endLocation[0]+ICON_WIDTH+PATH_WIDTH+LONG_PATH_HORIZONTAL_LENGTH,
     xEnd:LOGO_DATA.xLocation+PATH_WIDTH+(LOGO_WRAPPER_WIDTH-LOGO_WIDTH)/2+CORNER_LENGTH,
     yValue:LOGO_SIDE_PATHS_HEIGHT,
    direction: LEFT_TO_RIGHT},
  {xStart:LOGO_DATA.xLocation+LOGO_WRAPPER_WIDTH-(LOGO_WRAPPER_WIDTH-LOGO_WIDTH)/2-PATH_WIDTH-CORNER_LENGTH,
    xEnd:ICONS_ARRAY[5].endLocation[0]-LONG_PATH_HORIZONTAL_LENGTH-PATH_WIDTH,
    yValue:LOGO_SIDE_PATHS_HEIGHT,
    direction: RIGHT_TO_LEFT},
  {xStart:ICONS_ARRAY[0].endLocation[0]+ICON_WIDTH,
    xEnd:ICONS_ARRAY[0].endLocation[0]+ICON_WIDTH+LONG_PATH_HORIZONTAL_LENGTH+CORNER_LENGTH,
    yValue:ICONS_ARRAY[1].endLocation[1]+ICON_HEIGHT/2,
    direction: LEFT_TO_RIGHT},
  {xStart:ICONS_ARRAY[0].endLocation[0]+ICON_WIDTH,
    xEnd:ICONS_ARRAY[0].endLocation[0]+ICON_WIDTH+LONG_PATH_HORIZONTAL_LENGTH+CORNER_LENGTH,
    yValue:ICONS_ARRAY[2].endLocation[1]+ICON_HEIGHT/2,
    direction: LEFT_TO_RIGHT},
  {xStart:ICONS_ARRAY[5].endLocation[0]-LONG_PATH_HORIZONTAL_LENGTH-CORNER_LENGTH,
    xEnd:ICONS_ARRAY[5].endLocation[0],
    yValue:ICONS_ARRAY[5].endLocation[1]+ICON_HEIGHT/2,
    direction: RIGHT_TO_LEFT},
{xStart:ICONS_ARRAY[5].endLocation[0]-LONG_PATH_HORIZONTAL_LENGTH-CORNER_LENGTH,
  xEnd:ICONS_ARRAY[6].endLocation[0],
  yValue:ICONS_ARRAY[6].endLocation[1]+ICON_HEIGHT/2,
  direction: RIGHT_TO_LEFT}
];



