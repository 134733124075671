import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import { LAYER_TWO } from "constants/z-index";

export const componentStyles = css`
  .monday-demo-tabs-mobile-component {
    max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
    margin: auto;

    .monday-demo-tabs-assets-wrapper {
      position: relative;
      :global(.monday-demo-tabs-asset-click-wrapper) {
        :global(.monday-demo-tabs-asset-back-click-wrapper) {
          content: "";
          position: absolute;
          top: 0;
          height: 100%;
          width: 60px;
          background: transparent;
          z-index: ${LAYER_TWO};
        }

        :global(.monday-demo-tabs-asset-wrapper) {
          :global(.asset-inner) {
            :global(.window-mask) {
              padding-left: 0;
              padding-right: 0;

              :global(.window-header) {
                border-radius: 0;
              }

              :global(video) {
                border-radius: 0;
              }
            }
          }
        }
        :global(.monday-demo-tabs-asset-wrapper.current) {
          position: relative;
        }
      }
    }
  }
`;
