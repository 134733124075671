import dynamic from "next/dynamic";

const VerticalTagsWithImagesAssetComponent = require("./vertical-tags-with-images-asset-component").default;

import VerticalTagsWithImagesAssetConfig from "./vertical-tags-with-images-asset-config";
import VerticalTagsWithImagesAssetAdvancedConfig from "./vertical-tags-with-images-asset-advanced-config";

export default {
  VerticalTagsWithImagesAssetComponent,
  VerticalTagsWithImagesAssetConfig,
  VerticalTagsWithImagesAssetAdvancedConfig
};
