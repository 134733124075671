import Layouts from "./layouts";
const {
  // plop_marker:segment_index_import_layout
  SideBySideTabsAndProcessTabs,
  SideBySideTabsAndTestimonialTabs,
  PersonasTabs,
  SideBySideBoardViewsComponentTabs,
  UseCasesTabs,
  MondayDemoTabs,
  BuildingBlocksTabs,
  BoardTabs,
  TimelineTabs,
  ColoredTimerVerticalTabs,
  TimerVerticalTabs,
  VerticalTabs,
  RegularTabs,
} = Layouts;

export default { 
  // plop_marker:segment_index_add_layout
  SideBySideTabsAndProcessTabsComponent: {
    type: "SideBySideTabsAndProcessTabsComponent",
    title: "Side By Side Tabs And Process",
    imgSrc: "/static/img/segments/layouts/side-by-side-tabs-and-process-tabs.png",
    config: SideBySideTabsAndProcessTabs.SideBySideTabsAndProcessTabsConfig,
    advancedConfig: SideBySideTabsAndProcessTabs.SideBySideTabsAndProcessTabsAdvancedConfig,
    component: SideBySideTabsAndProcessTabs.SideBySideTabsAndProcessTabsComponent
  },
  SideBySideTabsAndTestimonialTabsComponent: {
    type: "SideBySideTabsAndTestimonialTabsComponent",
    title: "Side By Side Tabs And Testimonial",
    imgSrc: "/static/img/segments/layouts/side-by-side-tabs-and-testimonial-tabs.png",
    config: SideBySideTabsAndTestimonialTabs.SideBySideTabsAndTestimonialTabsConfig,
    advancedConfig: SideBySideTabsAndTestimonialTabs.SideBySideTabsAndTestimonialTabsAdvancedConfig,
    component: SideBySideTabsAndTestimonialTabs.SideBySideTabsAndTestimonialTabsComponent
  },
  PersonasTabsComponent: {
    type: "PersonasTabsComponent",
    title: "Personas",
    imgSrc: "/static/img/segments/layouts/personas-tabs.png",
    config: PersonasTabs.PersonasTabsConfig,
    advancedConfig: PersonasTabs.PersonasTabsAdvancedConfig,
    component: PersonasTabs.PersonasTabsComponent
  },
  SideBySideBoardViewsComponentTabsComponent: {
    type: "SideBySideBoardViewsComponentTabsComponent",
    title: "Side By Side Board Views Component",
    imgSrc: "/static/img/segments/layouts/side-by-side-board-views-component-tabs.png",
    config: SideBySideBoardViewsComponentTabs.SideBySideBoardViewsComponentTabsConfig,
    advancedConfig: SideBySideBoardViewsComponentTabs.SideBySideBoardViewsComponentTabsAdvancedConfig,
    component: SideBySideBoardViewsComponentTabs.SideBySideBoardViewsComponentTabsComponent
  },
  UseCasesTabsComponent: {
    type: "UseCasesTabsComponent",
    title: "Use Cases",
    imgSrc: "/static/img/segments/layouts/use-cases-tabs.png",
    config: UseCasesTabs.UseCasesTabsConfig,
    advancedConfig: UseCasesTabs.UseCasesTabsAdvancedConfig,
    component: UseCasesTabs.UseCasesTabsComponent
  },
  MondayDemoTabsComponent: {
    type: "MondayDemoTabsComponent",
    title: "Monday Demo",
    imgSrc: "/static/img/segments/layouts/monday-demo-tabs.png",
    config: MondayDemoTabs.MondayDemoTabsConfig,
    advancedConfig: MondayDemoTabs.MondayDemoTabsAdvancedConfig,
    component: MondayDemoTabs.MondayDemoTabsComponent
  },
  BuildingBlocksTabsComponent: {
    type: "BuildingBlocksTabsComponent",
    title: "Building Blocks",
    imgSrc: "/static/img/segments/layouts/building-blocks-tabs.png",
    config: BuildingBlocksTabs.BuildingBlocksTabsConfig,
    advancedConfig: BuildingBlocksTabs.BuildingBlocksTabsAdvancedConfig,
    component: BuildingBlocksTabs.BuildingBlocksTabsComponent
  },
  BoardTabsComponent: {
    type: "BoardTabsComponent",
    title: "Board",
    imgSrc: "/static/img/segments/layouts/board-tabs.png",
    config: BoardTabs.BoardTabsConfig,
    advancedConfig: BoardTabs.BoardTabsAdvancedConfig,
    component: BoardTabs.BoardTabsComponent
  },
  TimelineTabsComponent: {
    type: "TimelineTabsComponent",
    title: "Timeline",
    imgSrc: "/static/img/segments/layouts/timeline-tabs.png",
    config: TimelineTabs.TimelineTabsConfig,
    advancedConfig: TimelineTabs.TimelineTabsAdvancedConfig,
    component: TimelineTabs.TimelineTabsComponent
  },
  ColoredTimerVerticalTabsComponent: {
    type: "ColoredTimerVerticalTabsComponent",
    title: "Colored Timer Vertical",
    imgSrc: "/static/img/segments/layouts/colored-timer-vertical-tabs.png",
    config: ColoredTimerVerticalTabs.ColoredTimerVerticalTabsConfig,
    advancedConfig: ColoredTimerVerticalTabs.ColoredTimerVerticalTabsAdvancedConfig,
    component: ColoredTimerVerticalTabs.ColoredTimerVerticalTabsComponent
  },
  TimerVerticalTabsComponent: {
    type: "TimerVerticalTabsComponent",
    title: "Timer Vertical",
    imgSrc: "/static/img/segments/layouts/timer-vertical-tabs.png",
    config: TimerVerticalTabs.TimerVerticalTabsConfig,
    advancedConfig: TimerVerticalTabs.TimerVerticalTabsAdvancedConfig,
    component: TimerVerticalTabs.TimerVerticalTabsComponent
  },
  VerticalTabsComponent: {
    type: "VerticalTabsComponent",
    title: "Vertical",
    imgSrc: "/static/img/segments/layouts/vertical-tabs.png",
    config: VerticalTabs.VerticalTabsConfig,
    advancedConfig: VerticalTabs.VerticalTabsAdvancedConfig,
    component: VerticalTabs.VerticalTabsComponent
  },
  RegularTabsComponent: {
    type: "RegularTabsComponent",
    title: "Regular",
    imgSrc: "/static/img/segments/layouts/regular-tabs.png",
    config: RegularTabs.RegularTabsConfig,
    advancedConfig: RegularTabs.RegularTabsAdvancedConfig,
    component: RegularTabs.RegularTabsComponent
  },
};