import css from "styled-jsx/css";
import Colors from "styles/colors";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "/constants/vp-sizes";
import { TABLET_QUERY_MAX_WIDTH } from "/constants/vp-sizes";

const MOBILE_SELECTABLE_CONTAINER_HEIGHT_PX = "47px";

export const componentStyles = css.global`
  .selectable-tags-vertical-grid {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .selectable-tags-vertical-container {
      display: flex;

      .selectable-tags-vertical-side-component-wrapper {
        width: 700px;
        height: 362px;
        padding: 24px;
        background-color: ${Colors["riverstone"]};
        border-radius: 12px;
        overflow: hidden;
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;
      }

      .selectable-tags-container-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        flex-direction: column;
        align-items: stretch;
        margin-right: -8px;

        .selectable-tag-wrapper {
          justify-content: flex-start;
          background: transparent;
          border-radius: 0;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          margin: 0 0 16px 0;

          .tag-text {
            font-weight: 300;
          }

          &:hover {
            background: ${Colors["riverstone"]};
          }

          .selectable-tag-image-container {
            height: 22px;
            width: 22px;
            border-width: 2px;
          }
        }
      }
    }

    .selectable-tags-button-container {
      margin-top: 32px;
    }

    &.left {
      align-items: flex-start;

      .selectable-tags-container-wrapper {
        justify-content: flex-start;
      }
    }

    &.center {
      align-items: center;

      .selectable-tags-container-wrapper {
        justify-content: center;
      }
    }

    &.right {
      align-items: flex-end;
      .selectable-tags-container-wrapper {
        justify-content: flex-end;
      }
    }

    &.mobile {
      padding: 0;
      max-width: 100%;
      box-sizing: border-box;

      .selectable-tags-container-wrapper {
        display: unset;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        max-width: 100%;
        scroll-snap-type: x mandatory;

        .grid-selectable-group-container {
          display: inline-flex;
          flex-direction: column;
          margin: 0 5vw;
          scroll-snap-align: center;

          .grid-selectable-container {
            max-width: 350px;
            width: 60vw;

            .selectable-tag-image-container {
              flex-shrink: 0;
            }

            .tag-text {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
            }
          }

          &:last-of-type {
            margin-right: 25vw;
          }
        }
      }
    }
  }

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    .selectable-tags-grid {
      padding: 0px 16px;
      max-width: 100%;
      box-sizing: border-box;

      .grid-selectable-container {
        flex: 1;
        flex-shrink: 0;
        flex-basis: 33%;

        .selectable-tag-image-container {
          flex-shrink: 0;
        }

        .tag-text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
      }

      &.mobile-clusters {
        .selectable-tags-container-wrapper {
          .grid-selectable-container {
            flex-grow: 0;
            flex-basis: auto;
            height: ${MOBILE_SELECTABLE_CONTAINER_HEIGHT_PX};
          }
        }
      }
    }
  }
`;
