import { PureComponent } from "react";
import { componentStyles } from "./icon-with-paragraph-grid-component.scss";
import ImagesGrid from "segments/desktop/core-components/images-grid/images-grid";
import FullParagraphDefaultProps from "segments/desktop/core-components/full-paragraph/default-props";
import { positionTypes } from "segments/desktop/core-components/images-grid/images-grid-service";
import { MD } from "constants/sizes";

export default class IconWithParagraphGridComponent extends PureComponent {
  render() {
    const { images, imagePosition, textAlign, titleSize, textSize, iconWidth, iconHeight } = this.props;

    return (
      <div className="icon-with-paragraph-grid-component">
        <ImagesGrid
          images={images}
          isWithParagraph={true}
          imagePosition={imagePosition}
          textAlign={textAlign}
          titleSize={titleSize}
          textSize={textSize}
          imageStyle={{ width: iconWidth, height: iconHeight }}
          isIcon
        />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

IconWithParagraphGridComponent.defaultProps = {
  images: [
    {
      src: "/static/img/heart_small.png",
      fullParagraph: FullParagraphDefaultProps
    },
    {
      src: "/static/img/heart_small.png",
      fullParagraph: FullParagraphDefaultProps
    },
    {
      src: "/static/img/heart_small.png",
      fullParagraph: FullParagraphDefaultProps
    },
  ],
  paddingTop: "56px",
  paddingBottom: "56px",
  imagePosition: positionTypes.CENTER_LEFT,
  textAlign: "left",
  titleSize: MD,
  textSize: MD,
  iconWidth: "50px",
  iconHeight: "50px"
};
