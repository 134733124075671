import { defaultData } from "./segments-template-config-default-data";
import { socialButtons, footer, topbar } from "../components";
import SegmentsConfig from "segments/desktop";

export const SEGMENTS_TEMPLATE_CONFIG = {
  topbar,
  socialButtons,
  footer,
  segments: [
    {
      type: "ALL_SEGMENTS",
      title: "All Segmements",
      imgSrc: "/static/img/segments/icons/blank.png",
      segmentLayouts: [
        SegmentsConfig.VideosCarouselComponent,
        SegmentsConfig.CompaniesLogosComponent,
        SegmentsConfig.TextAndAssetComponent,
        SegmentsConfig.FormWithTextAndAssetsComponent,
        SegmentsConfig.SimpleColorfulTitleWithSignupComponent,
        SegmentsConfig.SwitchingTitleWithQuotesCarouselComponent,
        SegmentsConfig.LottieAnimationComponent,
        SegmentsConfig.BoardViewsComponent,
        SegmentsConfig.CustomerSuccessComponent,
        SegmentsConfig.SecurityAndPrivacyComponent,
        SegmentsConfig.IntegrationsComponent,
        SegmentsConfig.FactCardsComponent,
        SegmentsConfig.ParagraphComponent,
        SegmentsConfig.MultipleTabsComponent,
        SegmentsConfig.MainYoutubeVideoComponent,
        SegmentsConfig.ButtonWithAnimationComponent,
        SegmentsConfig.SignupWithAnimationComponent,
        SegmentsConfig.EmbeddedFormComponent,
        SegmentsConfig.SimpoComponent,
        SegmentsConfig.ClusterSelectionComponent,
        SegmentsConfig.ClusterSelectionOneStepComponent,
        SegmentsConfig.IndustrySelectionComponent,
        SegmentsConfig.OnePagerWithImageComponent,
        SegmentsConfig.HeroImageComponent,
        SegmentsConfig.ShortFooterComponent,
        SegmentsConfig.ReviewsComponent,
        SegmentsConfig.SocialProofComponent,
        SegmentsConfig.RegularAssetComponent
      ]
    }
  ],
  defaultData
};
