import css from "styled-jsx/css";
import Colors from "/styles/colors";
import FormConstants from "constants/forms";

const FORM_INPUT_PADDING = `${FormConstants.FORM_INPUT_PADDING}px`;

export const componentStyles = css.global`
  .generic-form-input-component {
    display: flex;
    flex-direction: column;

    .input-title {
      position: relative;
      width: fit-content;
      margin-left: ${FORM_INPUT_PADDING};
      margin-bottom: 10px;
      font-size: 0.875rem;
      font-weight: normal;
      line-height: 19px;
      color: ${Colors["mud"]};

      &.contact-form-required-input:after {
        content: "*";
        position: absolute;
        top: 0px;
        right: -10px;
        color: ${Colors["brand-red"]};
      }
    }

    .form-input-wrapper {
      position: relative;
      margin-bottom: 32px;

      .required-error {
        position: absolute;
        color: ${Colors["brand-red"]};
        bottom: -18px;
        font-size: 0.8125rem;
        left: ${FORM_INPUT_PADDING};
      }
    }
  }
`;
