import Colors from "styles/colors";
import PersonalTextualInfoDefaultProps from "segments/desktop/core-components/person-textual-info/default-props";

export default {
  title: "“The virtual manager that all work needs“",
  fontSize: "24px",
  showStars: true,
  starsCount: 5,
  decorationColor: "iris",
  reviewCardOverrideHeight: null,
  ...PersonalTextualInfoDefaultProps
};
