export const MASONRY_IMAGE_ASSETS = {
  TIMELINE_COLUMN: {
    src:
      "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Timeline_column.jpg",
    alt: "Timeline column"
  },
  STATUS_COLUMN: {
    src:
      "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Status_column.jpg",
    alt: "Status column"
  },
  BATTERY: {
    src:
      "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Battery.jpg",
    alt: "Task progress"
  },
  INTEGRATION: {
    src:
      "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Integration.jpg",
    alt: "Integration"
  },
  PIE: {
    src:
      "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Pie.jpg",
    alt: "Pie widget"
  },
  MOBILE: {
    src:
      "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/MobileApp.jpg",
    alt: "Mobile phone with project management board"
  },
  AUTOMATION: {
    src:
      "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Automation.jpg",
    alt: "Automation"
  },
  COMMENT: {
    src:
      "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Talk.jpg",
    alt: "Comment"
  },
  DASHBOARD: {
    src:
      "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Dash.jpg",
    alt: "Dashboard"
  }
};

export const MASONRY_VIDEO_ASSETS = {
  TIMELINE_COLUMN: {
    src: {
      formats: [
        "/Generator_featured images/Home Page - 2022 Rebrand/Masonry/final/Timeline_column.mp4"
      ]
    },
    alt: "Timeline column",
    playWhenVisible: false,
    autoPlay: false,
    loop: false,
    poster: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Timeline_column.jpg",
    startAfter: "500"
  },
  STATUS_COLUMN: {
    src: {
      formats: [
        "/Generator_featured images/Home Page - 2022 Rebrand/Masonry/final/Status_column.mp4"
      ]
    },
    alt: "Status column",
    playWhenVisible: false,
    autoPlay: false,
    loop: false,
    poster: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Status_column.jpg",
    startAfter: "2000"
  },
  BATTERY: {
    src: {
      formats: [
        "/Generator_featured images/Home Page - 2022 Rebrand/Masonry/final/Battery.mp4"
      ]
    },
    alt: "Battery widget",
    playWhenVisible: false,
    autoPlay: false,
    loop: false,
    poster: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Battery.jpg",
    startAfter: "16000"
  },
  INTEGRATION: {
    src: {
      formats: [
        "/Generator_featured images/Home Page - 2022 Rebrand/Masonry/final/Integration.mp4"
      ]
    },
    alt: "Integration",
    playWhenVisible: false,
    autoPlay: false,
    loop: false,
    poster: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Integration.jpg",
    startAfter: "7000"
  },
  PIE: {
    src: {
      formats: [
        "/Generator_featured images/Home Page - 2022 Rebrand/Masonry/final/Pie.mp4"
      ]
    },
    alt: "Pie widget",
    playWhenVisible: false,
    autoPlay: false,
    loop: false,
    poster: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Pie.jpg",
    startAfter: "17500"
  },
  MOBILE: {
    src: {
      formats: [
        "/Generator_featured images/Home Page - 2022 Rebrand/Masonry/final/MobileApp.mp4"
      ]
    },
    alt: "Mobile phone with project management board",
    playWhenVisible: false,
    autoPlay: false,
    loop: false,
    poster: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/MobileApp.jpg",
    startAfter: "15000"
  },
  AUTOMATION: {
    src: {
      formats: [
        "/Generator_featured images/Home Page - 2022 Rebrand/Masonry/final/Automation.mp4"
      ]
    },
    alt: "Automation",
    playWhenVisible: false,
    autoPlay: false,
    loop: false,
    poster: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Automation.jpg",
    startAfter: "9000"
  },
  COMMENT: {
    src: {
      formats: [
        "/Generator_featured images/Home Page - 2022 Rebrand/Masonry/final/Talk.mp4"
      ]
    },
    alt: "Comment",
    playWhenVisible: false,
    autoPlay: false,
    loop: false,
    poster: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Talk.jpg",
    startAfter: "11000"
  },
  DASHBOARD: {
    src: {
      formats: [
        "/Generator_featured images/Home Page - 2022 Rebrand/Masonry/final/Dash.mp4"
      ]
    },
    alt: "Dashboard",
    playWhenVisible: false,
    autoPlay: false,
    loop: false,
    poster: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Dash.jpg",
    startAfter: "4000"
  }
};

export default {
  assets: [
    { asset: { video: MASONRY_VIDEO_ASSETS.TIMELINE_COLUMN, image: MASONRY_IMAGE_ASSETS.TIMELINE_COLUMN } },
    { asset: { video: MASONRY_VIDEO_ASSETS.STATUS_COLUMN, image: MASONRY_IMAGE_ASSETS.STATUS_COLUMN } },
    { asset: { video: MASONRY_VIDEO_ASSETS.BATTERY, image: MASONRY_IMAGE_ASSETS.BATTERY } },
    { asset: { video: MASONRY_VIDEO_ASSETS.INTEGRATION, image: MASONRY_IMAGE_ASSETS.INTEGRATION } },
    { asset: { video: MASONRY_VIDEO_ASSETS.PIE, image: MASONRY_IMAGE_ASSETS.PIE } },
    { asset: { video: MASONRY_VIDEO_ASSETS.MOBILE, image: MASONRY_IMAGE_ASSETS.MOBILE } },
    { asset: { video: MASONRY_VIDEO_ASSETS.AUTOMATION, image: MASONRY_IMAGE_ASSETS.AUTOMATION } },
    { asset: { video: MASONRY_VIDEO_ASSETS.COMMENT, image: MASONRY_IMAGE_ASSETS.COMMENT } },
    { asset: { video: MASONRY_VIDEO_ASSETS.DASHBOARD, image: MASONRY_IMAGE_ASSETS.DASHBOARD } }
  ],
  hideControls: true,
  alignToLogo: true
};
