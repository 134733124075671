import dynamic from "next/dynamic";

const SideBySideHubspotFormComponent = require("./side-by-side-hubspot-form-component").default;

import SideBySideHubspotFormConfig from "./side-by-side-hubspot-form-config";
import SideBySideHubspotFormAdvancedConfig from "./side-by-side-hubspot-form-advanced-config";

export default {
  SideBySideHubspotFormComponent,
  SideBySideHubspotFormConfig,
  SideBySideHubspotFormAdvancedConfig
};
