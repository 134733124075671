import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "/constants/vp-sizes";
import { TABLET_QUERY_MAX_WIDTH } from "/constants/vp-sizes";

const MOBILE_SELECTABLE_CONTAINER_HEIGHT_PX = "47px";

export const componentStyles = css.global`
  .selectable-tags-grid {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .selectable-tags-container-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .selectable-tags-wrapper {
        flex-wrap: wrap;
        justify-content: center;
        margin: auto;
      }
    }

    &.wave-animation {
      .selectable-tags-container-wrapper {
        .grid-selectable-container {
          @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
              .selectable-tag-wrapper {
                animation-delay: #{1000 + $i * 120}ms;
                .selectable-tag-image-container {
                  animation-delay: #{1000 + $i * 120}ms;
                }
              }
            }
          }
        }
      }
    }

    .selectable-tags-button-container {
      margin-top: 32px;
    }

    &.left {
      align-items: flex-start;

      .selectable-tags-container-wrapper {
        justify-content: flex-start;
      }
      .selectable-tags-button-container {
        width: unset;
      }
    }

    &.center {
      align-items: center;

      .selectable-tags-container-wrapper {
        justify-content: center;
      }
      .selectable-tags-button-container {
        width: unset;
      }
    }

    &.right {
      align-items: flex-end;
      .selectable-tags-container-wrapper {
        justify-content: flex-end;
      }
      .selectable-tags-button-container {
        width: unset;
      }
    }

    &.mobile {
      padding: 0;
      max-width: 100%;
      box-sizing: border-box;

      .selectable-tags-container-wrapper {
        display: unset;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        max-width: 100%;
        scroll-snap-type: x mandatory;

        .grid-selectable-group-container {
          display: inline-flex;
          flex-direction: column;
          margin: 0 5vw;
          scroll-snap-align: center;

          .grid-selectable-container {
            max-width: 350px;
            width: 60vw;

            .selectable-tag-image-container {
              flex-shrink: 0;
            }

            .tag-text {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
            }
          }

          &:last-of-type {
            margin-right: 25vw;
          }
        }
      }
    }
  }

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    .selectable-tags-grid {
      padding: 0px 16px;
      max-width: 100%;
      box-sizing: border-box;

      .grid-selectable-container {
        flex: 1;
        flex-shrink: 0;
        flex-basis: 33%;

        .selectable-tag-image-container {
          flex-shrink: 0;
        }

        .tag-text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
      }

      &.mobile-clusters {
        .selectable-tags-container-wrapper {
          .grid-selectable-container {
            flex-grow: 0;
            flex-basis: auto;
            height: ${MOBILE_SELECTABLE_CONTAINER_HEIGHT_PX};
          }
        }
      }
    }
  }
`;
