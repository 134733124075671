import { BACKGROUND_GRAY_COLOR_NAME } from "styles/color-consts";
import { SIGNUP_BUTTON_TYPE } from "segments/desktop/core-components/button/button-consts";

export default {
  theme: BACKGROUND_GRAY_COLOR_NAME,
  fullParagraph: {
    topic: {},
    title: "<light>See work management in </light><b>action</b>",
    body: "",
    textAlign: "center"
  },
  tabs: [
    {
      tabLabel: "Choose a template",
      autoTransition: "10000",
      asset: {
        video: {
          src: {
            singleUrl:
              "https://dapulse-res.cloudinary.com/video/upload/Generator_featured%20images/work%20management_new%20product%20page/demo_section/1._Choose_a_template.mp4"
          },
          ariaLabel: "Demo of choosing a template"
        },
        useWindowMask: true
      }
    },
    {
      tabLabel: "Add your items",
      autoTransition: "10000",
      asset: {
        video: {
          src: {
            singleUrl:
              "https://dapulse-res.cloudinary.com/video/upload/Generator_featured%20images/work%20management_new%20product%20page/demo_section/2._Add_your_items.mp4"
          },
          ariaLabel: "Demo of adding items"
        },
        useWindowMask: true
      }
    },
    {
      tabLabel: "Customize your board",
      autoTransition: "10000",
      asset: {
        video: {
          src: {
            singleUrl:
              "https://dapulse-res.cloudinary.com/video/upload/Generator_featured%20images/work%20management_new%20product%20page/demo_section/3._Customize_your_board.mp4"
          },
          ariaLabel: "Demo of customizing a board"
        },
        useWindowMask: true
      }
    },
    {
      tabLabel: "Invite new members",
      autoTransition: "10000",
      asset: {
        video: {
          src: {
            singleUrl:
              "https://dapulse-res.cloudinary.com/video/upload/Generator_featured%20images/work%20management_new%20product%20page/demo_section/4._Invite_new_members.mp4"
          },
          ariaLabel: "Demo of inviting new members"
        },
        useWindowMask: true
      }
    },
    {
      tabLabel: "Bring all your tools in",
      autoTransition: "10000",
      asset: {
        video: {
          src: {
            singleUrl:
              "https://dapulse-res.cloudinary.com/video/upload/Generator_featured%20images/work%20management_new%20product%20page/demo_section/5._Bring_all_your_tools_in.mp4"
          },
          ariaLabel: "Demo of bringing all your tools in"
        },
        useWindowMask: true
      }
    },
    {
      tabLabel: "Automate routine work",
      autoTransition: "10000",
      asset: {
        video: {
          src: {
            singleUrl:
              "https://dapulse-res.cloudinary.com/video/upload/Generator_featured%20images/work%20management_new%20product%20page/demo_section/6._Automate_routine_work.mp4"
          },
          ariaLabel: "Demo of automating routine work"
        },
        useWindowMask: true
      }
    },
    {
      tabLabel: "Communicate within items",
      autoTransition: "10000",
      asset: {
        video: {
          src: {
            singleUrl:
              "https://dapulse-res.cloudinary.com/video/upload/Generator_featured%20images/work%20management_new%20product%20page/demo_section/7._Communicate_within_items.mp4"
          },
          ariaLabel: "Demo of communicating within items"
        },
        useWindowMask: true
      }
    },
    {
      tabLabel: "See where everything stands",
      autoTransition: "10000",
      asset: {
        video: {
          src: {
            singleUrl:
              "https://dapulse-res.cloudinary.com/video/upload/Generator_featured%20images/work%20management_new%20product%20page/demo_section/8._See_where_everything_stands.mp4"
          },
          ariaLabel: "Demo of seeing where everything stands"
        },
        useWindowMask: true
      }
    }
  ],
  buttonConfig: { buttonType: SIGNUP_BUTTON_TYPE }
};
