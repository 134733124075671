import css from "styled-jsx/css";
import {
  MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH,
  MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH,
  TABLET_QUERY_MAX_WIDTH
} from "constants/vp-sizes";
import colors from "styles/colors";
import {
  BACKGROUND_GRAY_COLOR_NAME,
  DARK_WORK_OS_IRIS_COLOR_NAME,
  WHITE_COLOR_NAME,
  WORK_OS_IRIS_COLOR_NAME,
  DARK_MARKETING_RED_COLOR_NAME,
  DARK_CRM_GREEN_COLOR_NAME,
  DARK_PROJECTS_ORANGE_COLOR_NAME,
  DARK_DEV_GREEN_COLOR_NAME,
  LIGHT_WORK_OS_IRIS_COLOR_NAME,
  LIGHT_MARKETING_RED_COLOR_NAME,
  LIGHT_CRM_GREEN_COLOR_NAME,
  LIGHT_PROJECTS_ORANGE_COLOR_NAME,
  LIGHT_DEV_GREEN_COLOR_NAME,
  PROJECTS_ORANGE_COLOR_NAME,
  CRM_GREEN_COLOR_NAME,
  DEV_GREEN_COLOR_NAME,
  MARKETING_RED_COLOR_NAME
} from "styles/color-consts";
import { TOP_LAYER } from "constants/z-index";

export const componentStyles = css.global`
  .use-cases-tabs-component {
    max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .use-cases-tabs-navigation {
      display: flex;
      gap: 8px;
      justify-content: center;
      max-width: ${TABLET_QUERY_MAX_WIDTH};
      flex-wrap: wrap;
      .use-cases-tab-nav-button-wrapper {
        font-size: 0.875rem;
        font-weight: 500;
        padding: 8px 16px;
        border-radius: 20px;
        border: ${`1px solid ${colors[DARK_WORK_OS_IRIS_COLOR_NAME]}`};
        white-space: nowrap;
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
        &:hover {
          background-color: ${colors[BACKGROUND_GRAY_COLOR_NAME]};
          color: ${colors[DARK_WORK_OS_IRIS_COLOR_NAME]};
          cursor: pointer;
        }
        &.selected {
          background-color: ${colors[DARK_WORK_OS_IRIS_COLOR_NAME]};
          color: ${colors[WHITE_COLOR_NAME]};
        }
      }
    }
    .carousel-wrapper {
      width: calc(1440px - 48px);
      max-width: calc(100vw - 48px);
      align-self: center;
      .slick-slide[aria-hidden="true"] {
        .use-case-product-banner-button {
          .button-component {
            display: none;
          }
        }
      }
      .carousel-item {
        text-align: center;
        .use-case-tab-content {
          padding: 0 48px;
          .use-case-product-banner {
            .use-case-product-banner-text {
              .full-paragraph {
                .title-wrapper {
                  margin-bottom: 0;
                  .core-title {
                    font-size: 1.125rem;
                  }
                }
              }
            }
            .use-case-product-banner-button {
              .regular-button {
                width: max-content;
              }
            }
          }
        }
      }
      .carousel-arrow-wrapper {
        cursor: pointer;
        z-index: ${TOP_LAYER};
        text-align: center;
        width: 64px;
        height: 64px;
        outline: revert;
        top: 22vw;
        &:before {
          display: none;
        }
        &:hover {
          svg {
            path {
              fill: ${colors[WORK_OS_IRIS_COLOR_NAME]};
            }
          }
        }
        svg {
          height: 100%;
          width: 16px;
          path {
            fill: ${colors.mud};
            transition: fill 0.2s ease-in-out;
          }
        }
      }
    }
    &.projects-orange {
      .use-cases-tabs-navigation {
        .use-cases-tab-nav-button-wrapper {
          border: ${`1px solid ${colors[DARK_PROJECTS_ORANGE_COLOR_NAME]}`};
          &:hover {
            color: ${colors[DARK_PROJECTS_ORANGE_COLOR_NAME]};
            background-color: ${colors[LIGHT_PROJECTS_ORANGE_COLOR_NAME]};
          }
          &.selected {
            background-color: ${colors[DARK_PROJECTS_ORANGE_COLOR_NAME]};
            color: ${colors[WHITE_COLOR_NAME]};
          }
        }
      }
      .carousel-wrapper {
        .carousel-arrow-wrapper {
          &:hover {
            svg {
              path {
                fill: ${colors[PROJECTS_ORANGE_COLOR_NAME]};
              }
            }
          }
        }
      }
    }
    &.crm-green {
      .use-cases-tabs-navigation {
        .use-cases-tab-nav-button-wrapper {
          border: ${`1px solid ${colors[DARK_CRM_GREEN_COLOR_NAME]}`};
          &:hover {
            color: ${colors[DARK_CRM_GREEN_COLOR_NAME]};
            background-color: ${colors[LIGHT_CRM_GREEN_COLOR_NAME]};
          }
          &.selected {
            background-color: ${colors[DARK_CRM_GREEN_COLOR_NAME]};
            color: ${colors[WHITE_COLOR_NAME]};
          }
        }
      }
      .carousel-wrapper {
        .carousel-arrow-wrapper {
          &:hover {
            svg {
              path {
                fill: ${colors[CRM_GREEN_COLOR_NAME]};
              }
            }
          }
        }
      }
    }
    &.dev-green {
      .use-cases-tabs-navigation {
        .use-cases-tab-nav-button-wrapper {
          border: ${`1px solid ${colors[DARK_DEV_GREEN_COLOR_NAME]}`};
          &:hover {
            color: ${colors[DARK_DEV_GREEN_COLOR_NAME]};
            background-color: ${colors[LIGHT_DEV_GREEN_COLOR_NAME]};
          }
          &.selected {
            background-color: ${colors[DARK_DEV_GREEN_COLOR_NAME]};
            color: ${colors[WHITE_COLOR_NAME]};
          }
        }
      }
      .carousel-wrapper {
        .carousel-arrow-wrapper {
          &:hover {
            svg {
              path {
                fill: ${colors[DEV_GREEN_COLOR_NAME]};
              }
            }
          }
        }
      }
    }
    &.marketing-red {
      .use-cases-tabs-navigation {
        .use-cases-tab-nav-button-wrapper {
          border: ${`1px solid ${colors[DARK_MARKETING_RED_COLOR_NAME]}`};
          &:hover {
            color: ${colors[DARK_MARKETING_RED_COLOR_NAME]};
            background-color: ${colors[LIGHT_MARKETING_RED_COLOR_NAME]};
          }
          &.selected {
            background-color: ${colors[DARK_MARKETING_RED_COLOR_NAME]};
            color: ${colors[WHITE_COLOR_NAME]};
          }
        }
      }
      .carousel-wrapper {
        .carousel-arrow-wrapper {
          &:hover {
            svg {
              path {
                fill: ${colors[MARKETING_RED_COLOR_NAME]};
              }
            }
          }
        }
      }
    }
    &.workos-iris {
      .use-cases-tabs-navigation {
        .use-cases-tab-nav-button-wrapper {
          border: ${`1px solid ${colors[DARK_WORK_OS_IRIS_COLOR_NAME]}`};
          &:hover {
            color: ${colors[DARK_WORK_OS_IRIS_COLOR_NAME]};
            background-color: ${colors[LIGHT_WORK_OS_IRIS_COLOR_NAME]};
          }
          &.selected {
            background-color: ${colors[DARK_WORK_OS_IRIS_COLOR_NAME]};
            color: ${colors[WHITE_COLOR_NAME]};
          }
        }
      }
      .carousel-wrapper {
        .carousel-arrow-wrapper {
          &:hover {
            svg {
              path {
                fill: ${colors[WORK_OS_IRIS_COLOR_NAME]};
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH}) {
    .use-cases-tabs-component {
      .carousel-wrapper {
        .carousel-arrow-wrapper {
          top: 305px;
        }
      }
    }
  }

  @media (max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH}) {
    .images-grid {
      .grid-image-container {
        width: calc(28vw - 32px) !important;
      }
    }
  }
`;
