import withResponsiveHOC from "/components/enhancements/responsive-hoc/responsive-hoc-component";
import MondayDemoTabsDesktopComponent from "./monday-demo-tabs-desktop-component/monday-demo-tabs-desktop-component";
import MondayDemoTabsMobileComponent from "./monday-demo-tabs-mobile-component/monday-demo-tabs-mobile-component";
import DefaultProps from "./default-props";

const MondayDemoTabsComponent = withResponsiveHOC(MondayDemoTabsDesktopComponent, MondayDemoTabsMobileComponent);

export default MondayDemoTabsComponent;

MondayDemoTabsComponent.defaultProps = DefaultProps;
