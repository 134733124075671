import { ALIGN_TYPES } from "constants/align-types";
import { DEV_GREEN_COLOR_NAME } from "styles/color-consts";
import { sharedDefaultAdvancedProps } from "segments/desktop/shape/layouts/default-props";

const shapeColor = DEV_GREEN_COLOR_NAME;

export default {
  // Base
  x: "0",
  y: "0",
  scale: "1",
  cornerRadius: "0",
  rotation: "45",
  fill: shapeColor,
  align: ALIGN_TYPES.LEFT,
  // Advanced
  ...sharedDefaultAdvancedProps
};
