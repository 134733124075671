import { FIELD_TYPES } from "constants/template-field-type";
import GridParagraphWithImageFieldsConfig from "segments/desktop/core-components/grid-paragraph-with-image/fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Connected products",
  fields: {
    gridItems: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Grid Items",
      maxLimit: 4,
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          ...GridParagraphWithImageFieldsConfig
        }
      }
    }
  }
};
