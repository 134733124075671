import { FIELD_TYPES } from "constants/template-field-type";
import SideBySideTextAndAssetFieldsConfig from "segments/desktop/core-components/side-by-side-text-and-asset/fields-config";
import { BACKGROUND_COLOR_OPTIONS } from "segments/segments-service";
import ThemeConfig from "segments/core-configs/theme-config";

const { fullParagraph, asset, titleSize, ...selectedSideBySideTextAndAssetFieldsConfig } =
  SideBySideTextAndAssetFieldsConfig;

const paragraphVerticalColoredLineColor = {
  type: FIELD_TYPES.COLOR_PICKER,
  label: "Paragraph vertical colored line color",
  possibleColors: BACKGROUND_COLOR_OPTIONS
};

const customFullParagraph = {
  ...fullParagraph,
  fields: { ...fullParagraph.fields, paragraphVerticalColoredLineColor }
};

export default {
  type: FIELD_TYPES.NESTED,
  label: "Two Paragraphs",
  fields: {
    ...ThemeConfig,
    ...selectedSideBySideTextAndAssetFieldsConfig,
    primaryParagraph: { ...customFullParagraph, label: "Primary paragraph" },
    secondaryParagraph: { ...customFullParagraph, label: "Secondary paragraph" }
  }
};
