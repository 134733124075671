import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";

export const componentStyles = css`
  .universe-paragraph-component {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    margin: auto;
    position: relative;
  }
  .stars-canvas {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .full-paragraph-wrapper {
    max-width: 1100px;
    margin: auto;
    display: flex;
    justify-content: center;
    padding: 0px 32px;
  }
`;
