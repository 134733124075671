import dynamic from "next/dynamic";

const RichTextBlogComponent = require("./rich-text-blog-component").default;

import RichTextBlogConfig from "./rich-text-blog-config";
import RichTextBlogAdvancedConfig from "./rich-text-blog-advanced-config";

export default {
  RichTextBlogComponent,
  RichTextBlogConfig,
  RichTextBlogAdvancedConfig
};
