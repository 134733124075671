import { PureComponent } from "react";
import { componentStyles } from "./rich-text-blog-component.scss";
import draftToHtml from "draftjs-to-html";
import classnames from "classnames";
import { ALIGN_TYPES } from "constants/align-types";

const DEFAULT_WIDTH_PRECENTAGE = 70;
const DEFAULT_PROPS_BODY_CONTENT = {
  entityMap: {},
  blocks: [
    {
      key: "637gr",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    }
  ]
};

export default class RichTextBlogComponent extends PureComponent {
  convertBodyFromWswygJSONToHTML = (body) => {
    return draftToHtml(body);
  };

  render() {
    const { body, width, isMobileOrTabletBrowser, align } = this.props;
    const finalWidth = isMobileOrTabletBrowser ? 100 : width;
    const widthInPercentage = `${finalWidth}%`;
    const htmlBody = this.convertBodyFromWswygJSONToHTML(body);

    return (
      <div className={classnames("rich-text-blog-component", align)} style={{ width: widthInPercentage }}>
        <div className="rich-text-body" dangerouslySetInnerHTML={{ __html: htmlBody }} />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

RichTextBlogComponent.defaultProps = {
  width: DEFAULT_WIDTH_PRECENTAGE,
  body: DEFAULT_PROPS_BODY_CONTENT,
  align: ALIGN_TYPES.CENTER
};
