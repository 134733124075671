// plop_marker:layouts_import
import EmbeddedBoardIframe from "./embedded-board-iframe";
import SideBySideHubspotForm from "./side-by-side-hubspot-form";
import IframeDynamicWidthIframe from "./iframe-dynamic-width-iframe";

export default {
  // plop_marker:layouts_add_layout
  EmbeddedBoardIframe,
  SideBySideHubspotForm,
  IframeDynamicWidthIframe
};
