import { isDarkTheme } from "segments/segments-service";
import { componentStyles } from "./monday-integrations-connections-component.scss";
import { WHITE_COLOR_NAME, BRAND_BLACK_TEXT_COLOR } from "styles/color-consts";
import Colors from "styles/colors";
import { ICON_HEIGHT, ICON_WIDTH, ICONS_ARRAY, LINES_DATA, SVG_WIDTH, SVG_HEIGHT } from "./consts";

const LinesSvgComponent = (props) => {
  const { theme } = props;
  const lineColor = isDarkTheme(theme) ? Colors[WHITE_COLOR_NAME] : Colors[BRAND_BLACK_TEXT_COLOR];

  const renderLine = (x1, x2, y1, y2) => {
    return (
      <line stroke-dasharray="3" x1={x1} y1={x2} x2={y1} y2={y2} stroke={lineColor} key={`${x1}_${x2}_${y1}_${y2}`} />
    );
  };

  //draw lines between icons in the same axis
  const drawLineBetweenIcons = (icon1index, icon2index) => {
    if (ICONS_ARRAY[icon1index].startLocation[0] === ICONS_ARRAY[icon2index].startLocation[0]) {
      return renderLine(
        ICONS_ARRAY[icon1index].startLocation[0] + ICON_WIDTH / 2 + "px",
        ICONS_ARRAY[icon1index].startLocation[1] + ICON_HEIGHT + "px",
        ICONS_ARRAY[icon2index].startLocation[0] + ICON_WIDTH / 2 + "px",
        ICONS_ARRAY[icon2index].startLocation[1] + "px"
      );
    } else if (ICONS_ARRAY[icon1index].startLocation[1] === ICONS_ARRAY[icon2index].startLocation[1]) {
      return renderLine(
        ICONS_ARRAY[icon1index].startLocation[0] + ICON_WIDTH + "px",
        ICONS_ARRAY[icon1index].startLocation[1] + ICON_HEIGHT / 2 + "px",
        ICONS_ARRAY[icon2index].startLocation[0] + "px",
        ICONS_ARRAY[icon2index].startLocation[1] + ICON_HEIGHT / 2 + "px"
      );
    }
  };

  const drawHorizontalLine = (startIconIndex, xEnd) => {
    return renderLine(
      ICONS_ARRAY[startIconIndex].startLocation[0] + ICON_WIDTH + "px",
      ICONS_ARRAY[startIconIndex].startLocation[1] + ICON_HEIGHT / 2 + "px",
      ICONS_ARRAY[xEnd].startLocation[0] + ICON_WIDTH / 2 + "px",
      ICONS_ARRAY[startIconIndex].startLocation[1] + ICON_HEIGHT / 2 + "px"
    );
  };

  const drawVerticalLine = (startIconIndex, yEnd) => {
    return renderLine(
      ICONS_ARRAY[startIconIndex].startLocation[0] + ICON_WIDTH / 2 + "px",
      ICONS_ARRAY[startIconIndex].startLocation[1] + ICON_HEIGHT + "px",
      ICONS_ARRAY[startIconIndex].startLocation[0] + ICON_WIDTH / 2 + "px",
      ICONS_ARRAY[yEnd].startLocation[1] + ICON_HEIGHT / 2 + "px"
    );
  };

  return (
    <svg width={SVG_WIDTH} height={SVG_HEIGHT} className="line">
      {LINES_DATA.map((line) => drawLineBetweenIcons(line.startIcon, line.endIcon))}
      {drawHorizontalLine(0, 1)}
      {drawVerticalLine(3, 2)}
      <style jsx>{componentStyles}</style>
    </svg>
  );
};

export default LinesSvgComponent;
