import dynamic from "next/dynamic";

const CarouselTestimonialsComponent = require("./carousel-testimonials-component").default;

import CarouselTestimonialsConfig from "./carousel-testimonials-config";
import CarouselTestimonialsAdvancedConfig from "./carousel-testimonials-advanced-config";

export default {
  CarouselTestimonialsComponent,
  CarouselTestimonialsConfig,
  CarouselTestimonialsAdvancedConfig
};
