import { PureComponent } from "react";
import { getComponentStyles } from "./vertical-tabs-desktop-component.scss";
import VerticalTabs from "./tabs/tabs";
import VerticalTab from "./tab/tab";
import SideBySideTextAndAsset from "segments/desktop/core-components/side-by-side-text-and-asset/side-by-side-text-and-asset";
import { getImagePositionStyles } from "segments/desktop/core-components/images-grid/images-grid-service";
import { XS } from "constants/sizes";
import Title from "segments/desktop/core-components/title/title";

export default class VerticalTabsDesktopComponent extends PureComponent {
  tabsRenderer() {
    const { tabs, tabsColor, addTimeline, isDarkTheme, lineColor } = this.props;

    return tabs.map((tab, index) => {
      const { tabLabel, textAndAsset } = tab;
      const { titleSize } = textAndAsset || {};

      return (
        <VerticalTab
          label={tabLabel}
          color={tabsColor}
          addTimeline={addTimeline}
          key={tabLabel}
          isDarkTheme={isDarkTheme}
          lineColor={lineColor}
        >
          <SideBySideTextAndAsset {...textAndAsset} titleSize={titleSize || XS} key={index} />
        </VerticalTab>
      );
    });
  }

  render() {
    const { title, titleSize, tabsColor, tabsPosition } = this.props;
    const positionStyles = getImagePositionStyles(tabsPosition);
    const componentStyles = getComponentStyles(positionStyles);

    return (
      <div className="vertical-tabs-desktop-component">
        <div className="vertical-tabs-title-wrapper">
          <Title {...title} titleSize={titleSize} />
        </div>

        <div className="vertical-tabs-wrapper" role="tablist">
          <VerticalTabs {...this.props} color={tabsColor} positionStyles={positionStyles}>
            {this.tabsRenderer()}
          </VerticalTabs>
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
