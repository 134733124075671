import { FIELD_TYPES } from "constants/template-field-type";
import FlexibleCardsGridConfig from "segments/desktop/grid/layouts/flexible-cards-grid/flexible-cards-grid-config";
import TitleOnlyParagraphConfig from "segments/desktop/paragraph/layouts/title-only-paragraph/title-only-paragraph-config";
import TitleAdvancedConfig from "segments/desktop/core-components/title/advanced-field-config";
import { ISRAEL_LOCATION, GLOBAL_LOCATION } from "./press-consts";

const { gridItems, ...resConfig } = FlexibleCardsGridConfig.fields;
const { theme, buttonConfig, ...resTitleConfig } = TitleOnlyParagraphConfig.fields;
const { titleSize } = TitleAdvancedConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "Press Component",
  fields: {
    location: {
      type: FIELD_TYPES.COMBO,
      label: "Location",
      options: [
        { value: ISRAEL_LOCATION, text: "Israel" },
        { value: GLOBAL_LOCATION, text: "Global" }
      ]
    },
    ...resConfig,
    titleConfig: {
      type: FIELD_TYPES.NESTED,
      label: "Title Config",
      fields: {
        ...resTitleConfig,
        titleSize
      }
    }
  }
};
