import css from "styled-jsx/css";

export const componentStyles = css`
  .typed-component {
    .content {
      white-space: pre;
      font-size: 0.875rem;
      :global(.tag) {
        color: #0981fc;
      }
    }
  }
`;
