import { PureComponent } from "react";
import { componentStyles } from "./grid-tag.scss";
import DefaultProps from "./default-props";
import TagComponent from "./components/tag/tag-component";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";

export default class GridTag extends PureComponent {
  render() {
    const { tag, fullParagraph, titleSize, textSize } = this.props;

    return (
      <div className="grid-tag">
        <div className="tag-component-wrapper">
          <TagComponent {...tag} />
        </div>

        <div className="full-paragraph-component-wrapper">
          <FullParagraph {...fullParagraph} titleSize={titleSize} textSize={textSize} />
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

GridTag.defaultProps = DefaultProps;
