import { PureComponent } from "react";
import { componentStyles } from "./sales-phone-enterprise-component.scss";
import ButtonTag from "segments/desktop/core-components/button-tag/button-tag";
import Title from "segments/desktop/core-components/title/title";
import Constants from "./constants";
import { XS } from "constants/sizes";
import { UNITED_STATES_COUNTRY_CODE } from "constants/localization.js";

export default class SalesPhoneEnterpriseComponent extends PureComponent {
  constructor(props) {
    super(props);
    this._shouldShowComponent =
      this.props.cloudfrontViewerCountry && this.props.cloudfrontViewerCountry !== UNITED_STATES_COUNTRY_CODE;
  }

  renderPhoneTags() {
    const { phoneLinks } = this.props;
    return phoneLinks.map((phoneTag, index) => {
      return (
        <div className="button-tag-wrapper" key={index}>
          <ButtonTag {...phoneTag} />
        </div>
      );
    });
  }

  render() {
    const { title, isPreview } = this.props;

    if (!this._shouldShowComponent && !isPreview) return null; //Component isn't displayed in the US

    return (
      <div className="sales-phone-enterprise-component">
        <div className="title-wrapper">
          <Title title={title} titleSize={XS} />
        </div>
        <div className="tags-wrapper">{this.renderPhoneTags()}</div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

SalesPhoneEnterpriseComponent.defaultProps = {
  title: "For questions about large scale deployments <monday-bold>contact our sales team</monday-bold>",
  phoneLinks: [
    {
      tagImg: { src: "/static/img/sales/uk_big.png" },
      tagText: Constants.UK_SALES_PHONE_DISPLAY_TEXT,
      link: Constants.UK_SALES_PHONE_LINK
    },
    {
      tagImg: { src: "/static/img/sales/il_big.png" },
      tagText: Constants.ISRAEL_SALES_PHONE_DISPLAY_TEXT,
      link: Constants.ISRAEL_SALES_PHONE_LINK
    },
    {
      tagImg: { src: "/static/img/sales/apac_big.png" },
      tagText: Constants.AUSTRALIA_SALES_PHONE_DISPLAY_TEXT,
      link: Constants.AUSTRALIA_SALES_PHONE_LINK
    }
  ]
};
