import { FIELD_TYPES } from "constants/template-field-type";
import { leadSourceConfigList } from "client/services/hubspot-service/hubspot-constants";
import {CONTACT_SALES_HUBSPOT_FORM_ID, REQUEST_A_DEMO_HUBSPOT_FORM_ID, REPORT_FORM_ID} from "client/services/hubspot-service/contact-form-config"

const CONTACT_SALES_TEXT = `Contact Sales (${CONTACT_SALES_HUBSPOT_FORM_ID})`
const REQUEST_A_DEMO_TEXT = `Request a Demo (${REQUEST_A_DEMO_HUBSPOT_FORM_ID})`
const REPORT_FORM_TEXT = `Download form (${REPORT_FORM_ID})`

export default {
  formId: {
    type: FIELD_TYPES.COMBO,
    options: [{ value: CONTACT_SALES_HUBSPOT_FORM_ID, text: CONTACT_SALES_TEXT }, { value: REQUEST_A_DEMO_HUBSPOT_FORM_ID, text: REQUEST_A_DEMO_TEXT }, { value: REPORT_FORM_ID, text: REPORT_FORM_TEXT }],
    label: "Form ID"
  },
  source: {
    type: FIELD_TYPES.COMBO,
    label: "Lead Source",
    options: leadSourceConfigList
  },
  formTitle: {
    type: FIELD_TYPES.TEXT,
    label: "Form Title"
  }
};
