import { FIELD_TYPES } from "constants/template-field-type";

const testimonialConfig = {
  type: FIELD_TYPES.NESTED,
  label: "Single company testimonial",
  fields: {
    name: {
      label: "Company name",
      type: FIELD_TYPES.TEXT
    },
    testimonialText: {
      type: FIELD_TYPES.TEXT,
      label: "Testimonial text",
      translatable: true
    },
    personName: {
      type: FIELD_TYPES.TEXT,
      label: "Quote sayer's name"
    },
    personRole: {
      type: FIELD_TYPES.TEXT,
      label: "Quote sayer's role",
      translatable: true
    },
    image: {
      type: FIELD_TYPES.NESTED,
      label: "Company logo",
      fields: {
        src: {
          type: FIELD_TYPES.GALLERY,
          defaultImagesKeys: ["static/img/templates/long-template/boards/"],
          label: "Select an image (max 290px width, 90px height)"
        },
        alt: { type: FIELD_TYPES.TEXT, label: "Image description (alt)", translatable: true },
        isDecorative: { type: FIELD_TYPES.BOOLEAN, label: "Decorative image(no alt)" }
      }
    }
  }
};

export default {
  type: FIELD_TYPES.NESTED,
  label: "Testimonials carousel",
  fields: {
    textNote: {
      type: FIELD_TYPES.CONSTANT,
      label: "Note about texts:",
      text:
        "You can either use translation keys for translated text, or enter English text directly, which will be untranslated."
    },
    autoplay: {
      type: FIELD_TYPES.NESTED,
      label: "Auto-play definitions",
      fields: {
        shouldAutoPlay: {
          type: FIELD_TYPES.BOOLEAN,
          label: "Should auto-play testimonial slides"
        },
        interval: {
          type: FIELD_TYPES.TEXT,
          label: "Interval (number of milliseconds)"
        }
      }
    },
    testimonials: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Testimonials",
      nestedConfig: testimonialConfig
    }
  }
};
