import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";
import {getComponentShadow} from "segments/desktop/constants";

const BIG_SCREEN_PADDING = 70;
const BIG_SCREEN_MAX_WIDTH = MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH - BIG_SCREEN_PADDING;
const MEDIUM_SCREEN_PADDING_TOP_BOTTOM = 70;

export const componentStyles = css`
  .plain-classic-testimonials-component {
    max-width: ${BIG_SCREEN_MAX_WIDTH + "px"};
    margin: auto;
    padding-left: ${BIG_SCREEN_PADDING + "px"};
    padding-right: ${BIG_SCREEN_PADDING + "px"};

    .testimonial-card-wrapper {
      height: 100%;
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    .plain-classic-testimonials-component {
      max-width: 100vw;
      padding: ${MEDIUM_SCREEN_PADDING_TOP_BOTTOM + "px"} 0;
    }
  }
`;
