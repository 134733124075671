import { FIELD_TYPES } from "constants/template-field-type";
import ThemeConfig from "segments/core-configs/theme-config";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";

const { textColor, ...selectedFullParagraphFields } = FullParagraphFieldsConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "Full Paragraph",
  fields: {
    ...ThemeConfig,
    ...selectedFullParagraphFields
  }
};
