import PictureComponent from "components/core/picture/picture-component";
import colors from "styles/colors";
import classnames from "classnames";
import {
  SECONDARY_BUTTON_TYPE,
  SIGNUP_BUTTON_TYPE,
  SIGNUP_COMBO_BUTTONS_TYPE
} from "segments/desktop/core-components/button/button-consts";
import { XS } from "components/core/new-button/button-component";
import { componentStyles } from "./product-card-grid-component.scss";
import ProductCardGridBodyComponent from "./product-card-grid-body-component.js";
import {productCardsImages, productIndexToProductType} from "segments/desktop/grid/layouts/product-cards-grid/default-props";
import {PRODUCTS_THEMES} from "/constants/products";

const ProductCardGridDekstopComponent = (props) => {
  const {
    isAtLeastOneCardHighlighted,
    isHighlighted,
    header,
    body,
    showDivider,
    useCases,
    buttonConfig,
    index,
    productType
  } = props;


  const productTheme = productType ? PRODUCTS_THEMES[productType] : PRODUCTS_THEMES[productIndexToProductType[index]];

  const dividerStyle = {
    borderImageSource: `linear-gradient(to right, ${colors[productTheme]}ff, ${colors[productTheme]}00)`
  };

  const getProductButtonConfig = () => {
    switch (buttonConfig.buttonType) {
      case SIGNUP_BUTTON_TYPE:
        return {
          ...buttonConfig,
          invert: true,
          buttonSize: XS
        };
      case SIGNUP_COMBO_BUTTONS_TYPE:
        return {
          ...buttonConfig,
          signupButtonConfig: {
            ...buttonConfig.signupButtonConfig,
            invert: true,
            buttonSize: XS
          }
        };
      default:
        return buttonConfig;
    }
  };

  const productCardButtonConfig = getProductButtonConfig();
  const imageProp = productType ? productCardsImages?.[productType]?.image : productCardsImages?.[productIndexToProductType[index]]?.image ; 
  

  return (
    <div
      className={classnames(
        "product-card",
        { highlighted: isHighlighted },
        { "is-at-least-one-card-highlighted": isAtLeastOneCardHighlighted }
      )}
      style={{ "--color": `linear-gradient(0deg,${colors[productTheme]}ff,${colors[productTheme]}00)` }}
    >
      <div className="product-card-header">
          <div className="product-card-header-image">
            <PictureComponent {...imageProp} />
          </div>
        <div className="product-card-header-subtitle">{header?.subtitleText}</div>
      </div>
      <div>
        <ProductCardGridBodyComponent
          body={body}
          showDivider={showDivider}
          dividerStyle={dividerStyle}
          useCases={useCases}
          theme={productTheme}
          productCardButtonConfig={productCardButtonConfig}
        />
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

ProductCardGridDekstopComponent.defaultProps = {
  showDivider: true,
  buttonConfig: {
    buttonType: SECONDARY_BUTTON_TYPE,
    buttonText: "Learn more",
    isOpenLinkInNewTab: true
  }
};

export default ProductCardGridDekstopComponent;
