import { FIELD_TYPES } from "constants/template-field-type";
import { AUTO, BEST, GOOD, ECO, LOW } from "constants/cloudinary-asset-qualities";
import { DYNAMIC_ASSET_PARAM_PATHS } from "constants/dynamic-asset-by-param";
import { DYNAMIC_ASSET_PARAM } from "/constants/query-params";

export default {
  assetQuality: {
    type: FIELD_TYPES.COMBO,
    label: "Asset Quality",
    options: [
      { value: AUTO, text: "Auto" },
      { value: BEST, text: "Best" },
      { value: GOOD, text: "Good" },
      { value: ECO, text: "Eco" },
      { value: LOW, text: "Low" }
    ]
  },
  useDynamicAsset: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Use dynamic asset (when available)"
  },
  dynamicAssetParamPath: {
    type: FIELD_TYPES.COMBO,
    label: "Use image from parameter",
    labelDescription: `Image (?${DYNAMIC_ASSET_PARAM}=xxxxx) from param will build Cloudinary url under the selected path below and replaces the default image.`,
    options: DYNAMIC_ASSET_PARAM_PATHS
  },
  hideControls: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Hide controls (video only)"
  },
  useWindowMask: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Use window mask"
  },
  minHeight: {
    type: FIELD_TYPES.TEXT,
    label: "Min height"
  }
};
