import PictureComponent from "components/core/picture/picture-component";
import { trackEvent } from "services/bigbrain-service";
import { GRID_TAG_CLICKED } from "constants/bigbrain-event-types";

import colors from "styles/colors";
import { ConditionalWrapper } from "utils/react";
import classnames from "classnames";
import DefaultProps from "./default-props";
import { componentStyles, TAG_IMAGE_SIZE } from "./tag.scss";

const Tag = (props) => {
  const { tagIconsrc, tagText, onClick, tagBackgroundColor, tagImageHeight, href, openInNewTab } = props;

  const onTagClick = () => {
    trackEvent(GRID_TAG_CLICKED, { kind: tagText, info1: href });
    if (onClick) {
      onClick(tagText);
    }
  };

  const anchorWrapper = (children) => {
    const target = openInNewTab ? "_blank" : undefined;
    return (
      <a className="tag-anchor-wrapper" href={href} target={target}>
        {children}
      </a>
    );
  };

  const tagImageSize = tagImageHeight || TAG_IMAGE_SIZE;

  return (
    <ConditionalWrapper condition={href} wrapper={anchorWrapper}>
      <div className={classnames("tag", { "has-on-click-behavior": onClick })} onClick={onTagClick}>
        <div
          className="tag-image-wrapper"
          style={{ backgroundColor: colors[tagBackgroundColor], height: tagImageSize, width: tagImageSize }}
        >
          <PictureComponent
            className="tag-icon"
            pictureClass="tag-picture"
            src={tagIconsrc}
            pictureComponentHeight={tagImageSize}
          />
        </div>
        <div className="tag-text">{tagText}</div>
      </div>
      <style jsx>{componentStyles}</style>
    </ConditionalWrapper>
  );
};

export default Tag;

Tag.defaultProps = DefaultProps;
