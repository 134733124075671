import css from "styled-jsx/css";

export const componentStyles = css.global`
  .side-by-side-with-background-text-and-asset-component {
    display: flex;
    .side-by-side-asset-wrapper {
      flex-grow: 1;
      .side-by-side-text-and-asset-component {
        height: 100%;
      }
    }
  }
`;
