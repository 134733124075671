import { FIELD_TYPES } from "constants/template-field-type";
import fullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import ThemeConfig from "segments/core-configs/theme-config";
import AssetFieldsConfig from "components/core/asset/fields-config";
import ButtonFieldsConfig from "segments/desktop/core-components/button/fields-config";
import { BACKGROUND_GRAY_THEME_ID, GRADIANT_GRAY_THEME_ID, SPACE_BLUE_THEME_ID } from "segments/segments-service";

const { video } = AssetFieldsConfig;
const { fields } = video;
const { loop, playWhenVisible, ...restVideoFields } = fields;

export default {
  type: FIELD_TYPES.NESTED,
  label: "Monday Demo",
  fields: {
    theme: {
      ...ThemeConfig.theme
    },
    fullParagraph: {
      type: FIELD_TYPES.NESTED,
      label: "Paragraph",
      fields: fullParagraphFieldsConfig
    },
    tabs: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Tabs",
      maxLimit: 8,
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          tabLabel: { type: FIELD_TYPES.TEXT, label: "Tab Label", translatable: true },
          asset: {
            type: FIELD_TYPES.NESTED,
            label: "Asset",
            fields: {
              video: {
                ...video,
                fields: {
                  ...restVideoFields
                }
              }
            }
          }
        }
      }
    },
    buttonConfig: ButtonFieldsConfig
  }
};
