import { FIELD_TYPES } from "constants/template-field-type";
import HubspotContactFormFields from "segments/desktop/core-components/hubspot-contact-form/fields-config";
import SideBySideTextAndAssetFieldsConfig from "segments/desktop/core-components/side-by-side-text-and-asset/fields-config";

const { fullParagraph, ...selectedSideBySideTextAndAssetFieldsConfig } = SideBySideTextAndAssetFieldsConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "Hubspot Form Side By Side",
  fields: {
    ...HubspotContactFormFields,
    ...selectedSideBySideTextAndAssetFieldsConfig
  }
};
