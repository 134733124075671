import { PureComponent } from "react";
import ZendeskForm from "segments/desktop/core-components/zendesk-form/zendesk-form";
import { componentStyles } from "./zendesk-form-component.scss";

export default class ZendeskFormComponent extends PureComponent {
  render() {
    return (
      <div className="zendesk-form-component">
        <div className="zendesk-form-wrapper">
          <ZendeskForm />
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
