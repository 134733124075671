import Error from "next/error";
import DynamicTemplate from "components/templates/dynamic-template/dynamic-template";
import { fetchDynamicSegmentsRequestFromServer, getFaqData } from "utils/dynamicPage";
import { getSegmentsDynamicsFetchMapper } from "components/templates-generator/config";
import BaseTemplatePage from "./base-template-page";

const getDynamicData = async ({ superInitialProps, req, res }) => {
  const { pageConfig } = superInitialProps;
  let data = {};
  if (pageConfig?.template) {
    const segmentsDynamicsFetchMapper = getSegmentsDynamicsFetchMapper(pageConfig.template);
    const { dynamicData } = await fetchDynamicSegmentsRequestFromServer({
      pageConfig,
      segmentsDynamicsFetchMapper,
      req,
      res
    });
    data = dynamicData;
  }
  return data;
};

const setFaqSeoData = ({ superInitialProps, req, res }) => {
  const { pageConfig } = superInitialProps;
  if (pageConfig) {
    const seoFAQ = getFaqData({ pageConfig, res, req });
    if (seoFAQ?.length > 0) {
      pageConfig.seoFAQ = seoFAQ;
    }
  }
};

export default class DynamicTemplatePage extends BaseTemplatePage {
  static async getInitialProps({ req, res, asPath }) {
    const superInitialProps = await BaseTemplatePage.getInitialProps({ req, res, asPath });
    setFaqSeoData({ req, res, superInitialProps });
    const dynamicData = await getDynamicData({ req, res, superInitialProps });
    superInitialProps.dynamicData = dynamicData;
    return superInitialProps;
  }

  render() {
    const { errorCode } = this.props;
    if (errorCode) return <Error statusCode={errorCode} />;

    return <DynamicTemplate {...this.props} />;
  }
}
