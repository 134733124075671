import { FIELD_TYPES } from "constants/template-field-type";
import AssetFieldsConfig from "components/core/asset/fields-config";
import ButtonFieldsConfig from "segments/desktop/core-components/button/fields-config";
import { SIGNUP_BUTTON_TYPE } from "segments/desktop/core-components/button/button-consts";
import SignupButtonFieldsConfig from "segments/desktop/core-components/button/signup-button/fields-config";
import { SM, MD } from "components/core/new-button/button-component";

const { image, video } = AssetFieldsConfig;
const { playWhenVisible, loop, ...videoOtherFields } = video.fields;

const parallaxParagraphButtonConfig = {
  ...ButtonFieldsConfig,
  options: [
    {
      text: SIGNUP_BUTTON_TYPE,
      fieldsConfig: {
        ...SignupButtonFieldsConfig,
        invert: {
          type: FIELD_TYPES.BOOLEAN,
          label: "Invert  color"
        },
        buttonSize: {
          type: FIELD_TYPES.COMBO,
          label: "Size",
          options: [
            { value: SM, text: "Small" },
            { value: MD, text: "Medium" }
          ]
        }
      },
      type: FIELD_TYPES.NESTED
    }
  ]
};

export default {
  type: FIELD_TYPES.NESTED,
  label: "Scroll Parallax",
  fields: {
    paragraphs: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Paragraphs",
      maxLimit: 3,
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          title: {
            type: FIELD_TYPES.TEXT,
            label: "Title",
            translatable: true
          },
          description: {
            type: FIELD_TYPES.TEXT,
            label: "Description",
            translatable: true
          },
          avatarSrc: {
            type: FIELD_TYPES.GALLERY,
            label: "Avatar",
            defaultImagesKeys: []
          },
          avatarAlt: {
            type: FIELD_TYPES.TEXT,
            label: "Avatar alt text",
            translatable: true
          },
          testimonial: {
            type: FIELD_TYPES.TEXT,
            label: "Testimonial",
            translatable: true
          },
          name: {
            type: FIELD_TYPES.TEXT,
            label: "Name"
          },
          jobTitle: {
            type: FIELD_TYPES.TEXT,
            label: "Job title",
            translatable: true
          },
          companyName: {
            type: FIELD_TYPES.TEXT,
            label: "Company name"
          },
          buttonConfig: parallaxParagraphButtonConfig
        }
      }
    },
    topAsset: { ...image, label: "Top asset" },
    bottomAsset: { ...video, label: "Bottom asset", fields: videoOtherFields }
  }
};
