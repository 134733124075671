export default [
  // Dev
  {
    type: "team",
    offset: 50,
    duration: 0,
    pictureSrc: "/static/img/first-fold/team_1.png",
    classToggle: "team-first-item-end",
    className: "team-first-item-start"
  },

  // Ops
  {
    type: "team",
    offset: 100,
    duration: 0,
    pictureSrc: "/static/img/first-fold/team_2.png",
    classToggle: "team-second-item-end",
    className: "team-second-item-start"
  },

  // Marketing
  {
    type: "team",
    offset: 150,
    duration: 0,
    pictureSrc: "/static/img/first-fold/team_3.png",
    classToggle: "fade-in",
    className: "team-third-item-start"
  },

  // Design
  {
    type: "team",
    offset: 175,
    duration: 0,
    pictureSrc: "/static/img/first-fold/team_4.png",
    classToggle: "team-fourth-item-end",
    className: "team-fourth-item-start"
  },

  // Finance
  {
    type: "team",
    offset: 200,
    duration: 0,
    pictureSrc: "/static/img/first-fold/team_5.png",
    classToggle: "fade-in",
    className: "team-fifth-item-start"
  },

  // HR
  {
    type: "team",
    offset: 225,
    duration: 0,
    pictureSrc: "/static/img/first-fold/team_6.png",
    classToggle: "fade-in",
    className: "team-sixth-item-start"
  },

  // Product
  {
    type: "team",
    offset: 250,
    duration: 0,
    pictureSrc: "/static/img/first-fold/team_7.png",
    classToggle: "fade-in",
    className: "team-seventh-item-start"
  }
];
