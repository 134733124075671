import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import { componentStyles } from "./review-cards-reviews-component.scss";
import DefaultProps from "./default-props";
import withResponsiveHOC from "components/enhancements/responsive-hoc/responsive-hoc-component";
import { ReviewCardsReviewsComponentMobileComponent } from "./review-cards-reviews-mobile-component/review-cards-reviews-mobile-component";
import ReviewCardComponent from "./review-card-reviews-component/review-card-reviews-component";

const ReviewCardsReviewsComponentDesktopComponent = (props) => {
  const {
    fullParagraph,
    marginAboveCards,
    cards,
    spaceFromParagraph,
    topicBottomMargin,
    buttonTopMargin,
    titleSize,
    textSize
  } = props;
  const fullParagraphAdvancedProps = { spaceFromParagraph, topicBottomMargin, buttonTopMargin, titleSize, textSize };

  return (
    <div className="review-cards-reviews-component">
      <div className="paragraph">
        <FullParagraph {...fullParagraph} {...fullParagraphAdvancedProps} />
      </div>
      <div className="cards" style={{ marginTop: marginAboveCards }}>
        {cards?.map((card) => (
          <ReviewCardComponent reviewCardProps={card} />
        ))}
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

const ReviewCardsReviewsComponent = withResponsiveHOC(
  ReviewCardsReviewsComponentDesktopComponent,
  ReviewCardsReviewsComponentMobileComponent
);

ReviewCardsReviewsComponent.defaultProps = DefaultProps;

export default ReviewCardsReviewsComponent;
