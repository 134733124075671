import { useState, useMemo, useCallback } from "react";
import AssetComponent from "components/core/asset/asset-component";
import { isKeyPressOnCustomControlClickEvent } from "/client/services/accessibility/accessibility-service";
import { BOARD_TAB_CLICKED, BOARD_TAB_HOVERED } from "/constants/bigbrain-event-types";
import { trackEvent } from "/services/bigbrain-service";
import classnames from "classnames";
import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import BoardTab from "./tab/board-tab";
import Colors from "/styles/colors";

import { componentStyles } from "./board-tabs.scss";
import DefaultProps from "./default-props";
import WindowMask from "segments/desktop/core-components/window-mask/window-mask";

const BoardTabs = (props) => {
  const { tabs, contentWrapperClassName, isSmallTabs } = props;

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [hoveredTabIndex, setHoveredTabIndex] = useState(null);
  const workOSTab = tabs[0];

  const selectedTab = useMemo(() => {
    return tabs[selectedTabIndex];
  }, [selectedTabIndex, tabs]);

  const onTabClicked = useCallback(
    (index) => {
      const tab = tabs[index];
      trackEvent(BOARD_TAB_CLICKED, { info1: index, kind: tab?.label, info3: "mouse-event" });
      setSelectedTabIndex(index);
    },
    [tabs]
  );

  const onKeyDown = useCallback(
    (event, index) => {
      if (isKeyPressOnCustomControlClickEvent(event)) {
        event.preventDefault();
        const tab = tabs[index];
        trackEvent(BOARD_TAB_CLICKED, { info1: index, kind: tab?.label, info3: "keyboard-event" });
        setSelectedTabIndex(index);
      }
    },
    [tabs]
  );

  const onMouseOver = useCallback(
    (index) => {
      const tab = tabs[index];
      trackEvent(BOARD_TAB_HOVERED, { info1: index, kind: tab?.label });
      setHoveredTabIndex(index);
    },
    [tabs]
  );

  const onMouseLeave = () => {
    setHoveredTabIndex(null);
  };

  const renderMainTab = () => {
    const tabIndex = 0;
    return (
      <BoardTab
        tab={workOSTab}
        tabIndex={tabIndex}
        onTabClicked={onTabClicked}
        onKeyDown={onKeyDown}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        isSelected={tabIndex === selectedTabIndex}
        isHovered={tabIndex === hoveredTabIndex}
        isSmallTabs={isSmallTabs}
      />
    );
  };

  const renderTabs = () => {
    const restTabs = tabs.slice(1);
    return restTabs.map((tab, index) => {
      const tabIndex = index + 1;
      return (
        <BoardTab
          tab={tab}
          tabIndex={tabIndex}
          onTabClicked={onTabClicked}
          onKeyDown={onKeyDown}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          isSelected={tabIndex === selectedTabIndex}
          isHovered={tabIndex === hoveredTabIndex}
        />
      );
    });
  };

  const renderAsset = () => {
    return (
      <div className="tab-content">
        <AssetComponent className="board-asset" {...selectedTab.asset} hideControls />
      </div>
    );
  };

  const lineStyle = useMemo(() => {
    const colorName = selectedTab?.color || WORK_OS_IRIS_COLOR_NAME;
    return { backgroundColor: Colors[colorName] };
  }, [selectedTab]);

  return (
    <div className={classnames("board-tabs", { "small-tabs": isSmallTabs })}>
      <div className="left-pane-tabs-wrapper">
        <div className="tabs-wrapper">
          {renderMainTab()}
          <div className="products-tab-wrapper">{renderTabs()}</div>
        </div>
        <div className="tab-selected-line" style={lineStyle} />
      </div>
      <WindowMask>
        <div className={contentWrapperClassName}>{renderAsset()}</div>
      </WindowMask>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default BoardTabs;

BoardTabs.defaultProps = DefaultProps;
