import { XS_M } from "constants/sizes";
import LogosTitleComponent from "segments/desktop/logos/components/logos-title/logos-title-component";
import Logos from "segments/desktop/core-components/logos/logos";
import { componentStyles } from "./slim-logos-component.scss";

const SlimLogosComponent = (props) => {
  const { title, titleSize, textAlign, logos } = props;

  return (
    <div className="slim-logos-component">
      <LogosTitleComponent title={title} titleSize={titleSize} textAlign={textAlign} padding={0} />
      <Logos {...logos} slim />

      <style jsx>{componentStyles}</style>
    </div>
  );
}

SlimLogosComponent.defaultProps = {
  ...LogosTitleComponent.defaultProps,
  logos: Logos.defaultProps,
  titleSize: XS_M,
  textAlign: "left",
  paddingTop: "68px",
  paddingBottom: "68px",
  mobilePaddingTop: "24px",
  mobilePaddingBottom: "24px"
};


export default SlimLogosComponent;
