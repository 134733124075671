import css from "styled-jsx/css";
import Colors from "/styles/colors";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";
import { BACKGROUND_GRAY_COLOR_NAME } from "styles/color-consts";

export const componentStyles = css.global`
  .hubspot-with-bullets-side-by-side-form-component {
    max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
    margin: auto;

    .mobile-title {
      display: none;
      padding: 0 20px;
      .core-title {
        font-size: 1.125rem;
        line-height: 32px;
      }
    }

    .contact-sales-details {
      padding-left: 60px;
    }

    .contact-sales-title {
      .core-title {
        font-weight: bold;
      }
    }

    .benefits-wrapper {
      .benefit-wrapper {
        margin-top: 16px;
        display: flex;
        align-items: center;
        &:first-of-type {
          margin-top: 32px;
        }
        .benefit-icon-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 120px;
          flex-shrink: 0;

          .icon-text {
            margin-top: 4px;
            font-size: 0.8125rem;
            text-align: center;
            color: ${Colors.asphalt};
          }
        }

        .benefit-text-divider {
          height: 78px;
          width: 5px;
          border: 0;
          border-right: 1px solid;
          border-color: ${Colors.wolf};
        }

        .benefit-text-wrapper {
          font-size: 0.875rem;
          flex-grow: 1;
          margin-left: 24px;
          line-height: 24px;
          padding-right: 48px;
        }
      }
    }

    .support-paragraph-wrapper {
      padding: 32px 0;

      .support-paragraph-text {
        font-size: 0.8125rem;

        .support-paragraph-link {
          color: ${Colors["brand-blue"]};

          &:hover {
            filter: brightness(85%);
          }

          &:visited,
          a:hover,
          a:active {
            color: ${Colors["brand-blue"]};
          }
        }
      }
    }

    .customer-quote-wrapper {
      background-color: ${Colors[BACKGROUND_GRAY_COLOR_NAME]};
      border-radius: 8px;
      padding: 24px 32px;
      margin-bottom: 32px;
      .quote {
        position: relative;
        margin: auto 0;
        display: flex;
        text-align: left;
        letter-spacing: -0.5px;
        line-height: 30px;
        font-size: 1.125rem;
        .quotationMark {
          position: absolute;
          top: 0;
          left: -12px;
          margin-right: 8px;
        }
      }

      .name-and-job-description {
        margin-top: 32px;
        font-size: 0.8125rem;
        font-weight: 400;
        line-height: 20px;
      }
    }

    @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
      .mobile-title {
        display: block;
        margin: 16px auto;
        text-align: center;
        font-weight: bold;
      }

      .contact-sales-details {
        padding-left: 0;
        .contact-sales-title {
          display: none;
        }
      }

      .benefits-wrapper {
        .benefit-wrapper {
          flex-direction: column;

          &:first-child {
            margin-top: 0;
          }

          .benefit-text-divider {
            height: 0;
            width: 78px;
            border-top: 1px solid;
            border-color: ${Colors.wolf};
          }

          .benefit-text-wrapper {
            padding-right: 0;
            margin: 0 16px;
            text-align: center;
          }
        }
      }

      .support-paragraph-wrapper {
        text-align: center;
      }

      :global(.images-gallery-row) {
        justify-content: center !important;
      }
    }
  }
`;
