import { PureComponent } from "react";
import { componentStyles } from "./blog-card-item-component.scss";
import GridImage from "segments/desktop/core-components/grid-image/grid-image";
import Title from "segments/desktop/core-components/title/title";
import BlogCardItemFooterComponent from "./blog-card-item-footer/blog-card-item-footer-component";
import { XS } from "constants/sizes";
import Button from "segments/desktop/core-components/button/button";

const IMAGE_HEIGHT = "100%";
const IMAGE_WIDTH = "100%";

export default class BlogCardItemComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isCardHoverd: false
    };
  }

  onMouseEnter = () => {
    this.setState({
      isCardHoverd: true
    });
  };

  onMouseLeave = () => {
    this.setState({
      isCardHoverd: false
    });
  };

  renderFooterIfNeeded = () => {
    const { isCardHoverd } = this.state;
    const { footer, hideFooter, buttonConfig } = this.props;

    const hasButtonConfig = buttonConfig?.buttonType;

    if (hideFooter && !hasButtonConfig) return null;

    return (
      <div className="blog-footer-wrapper">
        {hasButtonConfig ? (
          <div className="button-container">
            <Button {...buttonConfig} />
          </div>
        ) : (
          <BlogCardItemFooterComponent {...footer} isCardHoverd={isCardHoverd} />
        )}
      </div>
    );
  };

  render() {
    const { link, image, title, openInNewTab, imageMaxHeight } = this.props;

    return (
      <a
        className="blog-card-item-component"
        href={link}
        target={openInNewTab ? "_blank" : "_self"}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {image?.src && (
          <div className="grid-image-wrapper-component" style={{ maxHeight: imageMaxHeight }}>
            <GridImage {...image} height={IMAGE_HEIGHT} width={IMAGE_WIDTH} />
          </div>
        )}

        <div className="blog-card-item-content">
          <div className="title-component-wrapper">
            <Title title={title} titleSize={XS} />
          </div>

          {this.renderFooterIfNeeded()}
        </div>

        <style jsx>{componentStyles}</style>
      </a>
    );
  }
}
