import { useMemo } from "react";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import Logos from "segments/desktop/core-components/logos/logos";
import DefaultProps from "./default-props";
import { componentStyles } from "./paragraph-and-logos.scss";

const ParagraphAndLogos = (props) => {
  const { fullParagraph, logos, marginBetweenParagraphAndLogos, logosMaxWidth } = props;

  const logosWrapperStyle = useMemo(() => {
    return { marginTop: marginBetweenParagraphAndLogos, maxWidth: logosMaxWidth };
  }, [marginBetweenParagraphAndLogos, logosMaxWidth]);

  return (
    <div className="paragraph-and-logos">
      <div className="paragraph-wrapper">
        <FullParagraph {...fullParagraph} />
      </div>
      <div className="logos-wrapper" style={logosWrapperStyle}>
        <Logos {...logos} />
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default ParagraphAndLogos;

ParagraphAndLogos.defaultProps = DefaultProps;
