export const badges = [
  // IMPORANT: ONLY EVEN NUMBER OF INTERGRATIONS IS SUPPROTED!
  { key: "mailchimp", path: "/static/img/segment-layout-default-images/floating-integrations/mailchimp.png" },
  { key: "twitter", path: "/static/img/segment-layout-default-images/floating-integrations/twitter.png" },
  { key: "box", path: "/static/img/segment-layout-default-images/floating-integrations/box.png" },
  // { key: "eventbrite", path: "/static/img/segment-layout-default-images/floating-integrations/eventbrite.png" },
  { key: "facebook-ads", path: "/static/img/segment-layout-default-images/floating-integrations/facebook-ads.png" },
  { key: "facebook", path: "/static/img/segment-layout-default-images/floating-integrations/facebook.png" },
  { key: "harvest", path: "/static/img/segment-layout-default-images/floating-integrations/harvest.png" },
  { key: "jira-core", path: "/static/img/segment-layout-default-images/floating-integrations/jira-core.png" },
  { key: "surveymonkey", path: "/static/img/segment-layout-default-images/floating-integrations/surveymonkey.png" },
  { key: "twilio", path: "/static/img/segment-layout-default-images/floating-integrations/twilio.png" },
  { key: "zendesk", path: "/static/img/segment-layout-default-images/floating-integrations/zendesk.png" },
  { key: "todoist", path: "/static/img/segment-layout-default-images/floating-integrations/todoist.png" },
  { key: "stripe", path: "/static/img/segment-layout-default-images/floating-integrations/stripe.png" },
  { key: "shopify", path: "/static/img/segment-layout-default-images/floating-integrations/shopify.png" },
  { key: "salesforce", path: "/static/img/segment-layout-default-images/floating-integrations/salesforce.png" },
  { key: "pipedrive", path: "/static/img/segment-layout-default-images/floating-integrations/pipedrive.png" },
  { key: "webhooks", path: "/static/img/segment-layout-default-images/floating-integrations/webhooks.png" },
  { key: "typeform", path: "/static/img/segment-layout-default-images/floating-integrations/typeform.png" },
  // { key: "wave", path: "/static/img/segment-layout-default-images/floating-integrations/wave.png" },
  { key: "redash", path: "/static/img/segment-layout-default-images/floating-integrations/redash.png" },
  { key: "toggl", path: "/static/img/segment-layout-default-images/floating-integrations/toggl.png" },
  { key: "slack", path: "/static/img/segment-layout-default-images/floating-integrations/slack.png" },
  // { key: "paypal", path: "/static/img/segment-layout-default-images/floating-integrations/paypal.png" },
  { key: "outlook", path: "/static/img/segment-layout-default-images/floating-integrations/outlook.png" },
  { key: "ms-teams", path: "/static/img/segment-layout-default-images/floating-integrations/ms-teams.png" },
  { key: "jira", path: "/static/img/segment-layout-default-images/floating-integrations/jira.png" },
  { key: "linkedin", path: "/static/img/segment-layout-default-images/floating-integrations/linkedin.png" },
  { key: "jotform", path: "/static/img/segment-layout-default-images/floating-integrations/jotform.png" },
  { key: "hubspot", path: "/static/img/segment-layout-default-images/floating-integrations/hubspot.png" },
  // { key: "intercom", path: "/static/img/segment-layout-default-images/floating-integrations/intercom.png" },
  { key: "dropbox", path: "/static/img/segment-layout-default-images/floating-integrations/dropbox.png" },
  // { key: "hackerone", path: "/static/img/segment-layout-default-images/floating-integrations/hackerone.png" },
  {
    key: "google-calendar",
    path: "/static/img/segment-layout-default-images/floating-integrations/google-calendar.png"
  },
  { key: "google-drive", path: "/static/img/segment-layout-default-images/floating-integrations/google-drive.png" },
  { key: "github", path: "/static/img/segment-layout-default-images/floating-integrations/github.png" },
  { key: "gmail", path: "/static/img/segment-layout-default-images/floating-integrations/gmail.png" },
  { key: "gitlab", path: "/static/img/segment-layout-default-images/floating-integrations/gitlab.png" },
  { key: "excel", path: "/static/img/segment-layout-default-images/floating-integrations/excel.png" },
  { key: "copper", path: "/static/img/segment-layout-default-images/floating-integrations/copper.png" },
  { key: "github", path: "/static/img/segment-layout-default-images/floating-integrations/github.png" },
  // { key: "aircall", path: "/static/img/segment-layout-default-images/floating-integrations/aircall.png" },
  // { key: "pandadoc", path: "/static/img/segment-layout-default-images/floating-integrations/pandadoc.png" }
];

export const duplicateBadges = () => {
  return badges.concat(badges);
};
