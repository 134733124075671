import { FIELD_TYPES } from "constants/template-field-type";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import { SPACE_BLUE_VIDEO_THEME_ID } from "segments/segments-service";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Universe Paragraph",
  fields: {
    theme: {
      type: FIELD_TYPES.COLOR_PICKER,
      label: "Theme",
      possibleColors: [SPACE_BLUE_VIDEO_THEME_ID]
    },
    ...FullParagraphFieldsConfig
  }
};
