import { PureComponent } from "react";
import { componentStyles } from "./asymmetric-enterprise-component.scss";
import SecurityLogos from "segments/desktop/core-components/security-logos/security-logos";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import FullParagraphDefaultProps from "segments/desktop/core-components/full-paragraph/default-props";
import { TWO_LINK_BUTTONS_TYPE } from "segments/desktop/core-components/button/button-consts";
import SecondaryButtonDefaultProps from "segments/desktop/core-components/button/secondary-button/default-props";
import RegularButtonDefaultProps from "segments/desktop/core-components/button/regular-button/default-props";

export default class AsymmetricEnterpriseComponent extends PureComponent {
  render() {
    const { fullParagraph, topColor } = this.props;

    const componentStylesString = componentStyles(topColor);

    const paragraphProps = { ...fullParagraph };

    return (
      <div className="asymmetric-enterprise-component">
        <div className="asymetric-top-line-part" />

        <div className="enterprise-data">
          <div className="security-logos-wrapper">
            <SecurityLogos smallLogos={true} />
          </div>

          <div className="full-paragraph-component-wrapper">
            <FullParagraph {...paragraphProps} />
          </div>
        </div>

        <div className="bottom-part" />

        <style jsx>{componentStylesString}</style>
      </div>
    );
  }
}

AsymmetricEnterpriseComponent.defaultProps = {
  topColor: "white",
  fullParagraph: {
    ...FullParagraphDefaultProps,
    title: "monday.com for enterprise",
    body: "A fully scalable, flexible, and secure Work OS - powering your organization to outperform, faster",
    buttonConfig: {
      ...FullParagraphDefaultProps.buttonConfig,
      secondaryButtonConfig: { ...SecondaryButtonDefaultProps, color: "white", isWithArrow: false },
      regularButtonConfig: RegularButtonDefaultProps,
      buttonType: TWO_LINK_BUTTONS_TYPE
    },
    textColor: "white",
    textAlign: "center",
    topic: {}
  }
};
