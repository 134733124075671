import css from "styled-jsx/css";

export const componentStyles = css`
  .side-by-side-with-background-hero-component {
    min-height: 650px;
    height: 0px;
    display: flex;
    flex-direction: column;
  }
`;
