import css from "styled-jsx/css";
import { MOBILE_QUERY_MIN_WIDTH } from "constants/vp-sizes";

export const componentStyles = css.global`
  .mobile-tabs-component {
    :global(*:focus) {
      outline: none !important;
    }

    .carousel {
      .slick-slider.focus {
        .slick-list {
          .slick-track {
            padding: 0;
          }
        }
      }
    }
  }

  @media (min-width: ${MOBILE_QUERY_MIN_WIDTH}) {
    .carousel {
      .slick-slide {
        .side-by-side-text-and-asset {
          .side-by-side-content {
            .full-paragraph-component-wrapper {
              padding: 0;
            }
          }
        }
      }
    }
  }
`;
