import TwoThirdsTextAndAssetComponent from "segments/desktop/text-and-asset/layouts/two-thirds-text-and-asset/two-thirds-text-and-asset-component";
import ReviewsCarousel from "segments/desktop/core-components/reviews-carousel/reviews-carousel";
import withResponsiveHOC from "components/enhancements/responsive-hoc/responsive-hoc-component";
import DefaultProps from "./default-props";
import { componentStyles } from "./paragraph-and-carousel-reviews-component.scss";
import { ParagraphAndCarouselReviewsMobileComponent } from "./paragraph-and-carousel-reviews-mobile-component/paragraph-and-carousel-reviews-mobile-component";

const ParagraphAndCarouselReviewsDesktopComponent = (props) => {
  const { carousel, removeQuoteSigns, ...rest } = props;

  return (
    <div className="paragraph-and-carousel-reviews-component">
      <TwoThirdsTextAndAssetComponent
        {...rest}
        replaceAssetComponent={ReviewsCarousel}
        replaceAssetComponentProps={{ ...carousel, removeQuoteSigns }}
      />

      <style jsx>{componentStyles}</style>
    </div>
  );
};

const ParagraphAndCarouselReviewsComponent = withResponsiveHOC(
  ParagraphAndCarouselReviewsDesktopComponent,
  ParagraphAndCarouselReviewsMobileComponent
);

ParagraphAndCarouselReviewsComponent.defaultProps = DefaultProps;

export default ParagraphAndCarouselReviewsComponent;
