import Layouts from "./layouts";
const {
  // plop_marker:segment_index_import_layout
  MondayFormForm,
  HubspotWithBulletsSideBySideForm,
  HubspotAndLogosForm,
  ZendeskSideBySideForm,
  ZendeskForm,
  HubspotSideBySideForm,
} = Layouts;

export default { 
  // plop_marker:segment_index_add_layout
  MondayFormFormComponent: {
    type: "MondayFormFormComponent",
    title: "Monday Form",
    imgSrc: "/static/img/segments/layouts/monday-form-form.png",
    config: MondayFormForm.MondayFormFormConfig,
    advancedConfig: MondayFormForm.MondayFormFormAdvancedConfig,
    component: MondayFormForm.MondayFormFormComponent
  },
  HubspotWithBulletsSideBySideFormComponent: {
    type: "HubspotWithBulletsSideBySideFormComponent",
    title: "Hubspot With Bullets Side By Side",
    imgSrc: "/static/img/segments/layouts/hubspot-with-bullets-side-by-side-form.png",
    config: HubspotWithBulletsSideBySideForm.HubspotWithBulletsSideBySideFormConfig,
    advancedConfig: HubspotWithBulletsSideBySideForm.HubspotWithBulletsSideBySideFormAdvancedConfig,
    component: HubspotWithBulletsSideBySideForm.HubspotWithBulletsSideBySideFormComponent
  },
  HubspotAndLogosFormComponent: {
    type: "HubspotAndLogosFormComponent",
    title: "Hubspot And Logos",
    imgSrc: "/static/img/segments/layouts/hubspot-and-logos-form.png",
    config: HubspotAndLogosForm.HubspotAndLogosFormConfig,
    advancedConfig: HubspotAndLogosForm.HubspotAndLogosFormAdvancedConfig,
    component: HubspotAndLogosForm.HubspotAndLogosFormComponent
  },
  ZendeskSideBySideFormComponent: {
    type: "ZendeskSideBySideFormComponent",
    title: "Zendesk Side By Side",
    imgSrc: "/static/img/segments/layouts/zendesk-side-by-side-form.png",
    config: ZendeskSideBySideForm.ZendeskSideBySideFormConfig,
    advancedConfig: ZendeskSideBySideForm.ZendeskSideBySideFormAdvancedConfig,
    component: ZendeskSideBySideForm.ZendeskSideBySideFormComponent
  },
  ZendeskFormComponent: {
    type: "ZendeskFormComponent",
    title: "Zendesk",
    imgSrc: "/static/img/segments/layouts/zendesk-form.png",
    config: ZendeskForm.ZendeskFormConfig,
    advancedConfig: ZendeskForm.ZendeskFormAdvancedConfig,
    component: ZendeskForm.ZendeskFormComponent
  },
  HubspotSideBySideFormComponent: {
    type: "HubspotSideBySideFormComponent",
    title: "Hubspot Side By Side",
    imgSrc: "/static/img/segments/layouts/hubspot-side-by-side-form.png",
    config: HubspotSideBySideForm.HubspotSideBySideFormConfig,
    advancedConfig: HubspotSideBySideForm.HubspotSideBySideFormAdvancedConfig,
    component: HubspotSideBySideForm.HubspotSideBySideFormComponent
  },
};