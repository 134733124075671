import css from "styled-jsx/css";
import {
  MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH,
  TABLET_QUERY_MAX_WIDTH,
  MOBILE_QUERY_MAX_WIDTH_M
} from "constants/vp-sizes";
import Colors from "styles/colors";
import DesktopHeaderConstants from "segments/desktop/header/layouts/components/basic-header/constants";
import BodyConstants, { getComponentShadow } from "segments/desktop/constants";

const HEADER_LOGO_HORIZONTAL_PADDING = `${DesktopHeaderConstants.HEADER_CONTENT_HORIZONTAL_PADDING}px`;
const BODY_HORIZONTAL_PADDING = `${BodyConstants.BODY_CONTENT_HORIZONTAL_PADDING}px`;

export const componentStyles = css.global`
  .side-by-side-text-and-asset {
    height: 100%;
    display: flex;
    justify-content: center;

    .left-space {
      flex-grow: 1;
    }

    .side-by-side-content {
      width: 100%;
      padding: ${`0 ${BODY_HORIZONTAL_PADDING}`};
      max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
      flex-grow: 1;
      position: relative;
      display: flex;
      align-items: center;
      gap: var(--space-between-text-and-asset);
      &.top {
        align-items: flex-start;
      }

      &.center {
        align-items: center;
      }

      &.bottom {
        align-items: flex-end;
      }

      &.is-swap {
        flex-direction: row-reverse;

        .full-paragraph-component-wrapper {
          padding: 0 0 0 60px;
        }
      }

      &.align-to-logo {
        padding-left: ${HEADER_LOGO_HORIZONTAL_PADDING};
        padding-right: ${HEADER_LOGO_HORIZONTAL_PADDING};
      }

      &.no-text-component {
        justify-content: center;
        .image-wrapper {
          width: 100%;
        }
      }

      .full-paragraph-component-wrapper,
      .image-wrapper {
        position: relative;
        z-index: 1;
        width: 50%;
      }

      .full-paragraph-component-wrapper {
        padding: 0 60px 0 0;
      }

      .image-wrapper {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        &.with-shadow {
          ${getComponentShadow()}
        }

        &.top {
          align-self: flex-start;
        }

        &.center {
          align-self: center;
        }

        &.bottom {
          align-self: flex-end;
        }

        .side-by-side-asset {
          height: 100%;
          width: 100%;

          &.ignore-parent-height {
            height: auto;
          }

          .picture-component {
            width: 100%;
            height: 100%;
            display: block;

            &:before {
              content: "";
              display: inline-block;
              height: 100%;
              vertical-align: middle;
            }
          }

          .text-and-asset-image {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            display: inline-block;
            vertical-align: middle;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }

    .right-space {
      flex-grow: 1;
    }
  }

  @media (min-width: ${TABLET_QUERY_MAX_WIDTH}) {
    &.align-image-to-screen-edge {
      .side-by-side-content {
        max-width: unset;
        &:not(.is-swap) {
          padding-right: 0;
        }
        &.is-swap {
          padding-left: 0;
        }
        .image-wrapper {
          .side-by-side-asset {
            .text-and-asset-image {
              width: 100%;
            }
          }
        }
      }
      &:not(.is-swap) {
        .left-space {
          min-width: ${`calc((100vw - ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px) / 2)`};
        }
        .right-space {
          display: none;
        }
      }
      &.is-swap {
        padding: 0 96px 0 0;
        .left-space {
          display: none;
        }
        .right-space {
          min-width: ${`calc((100vw - ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px) / 2)`};
        }
      }
    }
  }

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    .side-by-side-text-and-asset {
      .side-by-side-content,
      .side-by-side-content.is-swap {
        flex-direction: column;
        gap: var(--mobile-space-between-text-and-asset);

        &.is-mobile-swap {
          flex-direction: column-reverse;
        }

        &:not(.is-full-width) {
          background: ${Colors.white} !important;
          color: ${Colors.black} !important;
        }

        &.is-full-width {
          padding-top: 30px;
          padding-bottom: 45px;
        }

        .full-paragraph-component-wrapper,
        .image-wrapper {
          width: 100% !important;
        }

        .full-paragraph-component-wrapper {
          padding: 0 60px 60px 60px;
        }

        .image-wrapper {
          height: initial;
          padding-bottom: 16px;
        }
      }
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH_M}) {
    .side-by-side-text-and-asset {
      .side-by-side-content {
        padding: 0;
      }
    }
    .side-by-side-text-and-asset {
      .side-by-side-content,
      .side-by-side-content.is-swap {
        .full-paragraph-component-wrapper {
          padding: 0 32px 40px 32px;
        }
        .image-wrapper {
          padding-left: 32px !important;
          padding-right: 32px !important;
        }
      }
    }
  }
`;
