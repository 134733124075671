// plop_marker:layouts_import
import FullParagraphAndTagsHomepageFirstFold from "./full-paragraph-and-tags-homepage-first-fold";
import LiveBoardHomepageFirstFold from "./live-board-homepage-first-fold";
import SignUpWithProductsCardsHomepageFirstFold from "./sign-up-with-products-cards-homepage-first-fold";
import WelcomeBackHomepageFirstFold from "./welcome-back-homepage-first-fold";
import MondayUniverseHomepageFirstFold from "./monday-universe-homepage-first-fold";
import ScrollParallaxHomepageFirstFold from "./scroll-parallax-homepage-first-fold";
import GlobeWithTopCtaHomepageFirstFold from "./globe-with-top-cta-homepage-first-fold";
import GlobeHomepageFirstFold from "./globe-homepage-first-fold";
import AnimatingBoardHomepageFirstFold from "./animating-board-homepage-first-fold";

export default {
  // plop_marker:layouts_add_layout
  FullParagraphAndTagsHomepageFirstFold,
  LiveBoardHomepageFirstFold,
  SignUpWithProductsCardsHomepageFirstFold,
  WelcomeBackHomepageFirstFold,
  MondayUniverseHomepageFirstFold,
  ScrollParallaxHomepageFirstFold,
  GlobeWithTopCtaHomepageFirstFold,
  GlobeHomepageFirstFold,
  AnimatingBoardHomepageFirstFold,
};
