import { FIELD_TYPES } from "constants/template-field-type";

export default {
  testimonial: {
    type: FIELD_TYPES.TEXT,
    label: "Testimonials",
    textArea: true,
    translatable: true
  }
};
