import Layouts from "./layouts";
const {
  // plop_marker:segment_index_import_layout
  FeaturesTablePricing,
  PricesAndFeaturesCardsPricing,
  FrequentlyAskedQuestionsPricing,
} = Layouts;

export default {
  // plop_marker:segment_index_add_layout
  FeaturesTablePricingComponent: {
    type: "FeaturesTablePricingComponent",
    title: "Features Table",
    imgSrc: "/static/img/segments/layouts/features-table-pricing.png",
    config: FeaturesTablePricing.FeaturesTablePricingConfig,
    advancedConfig: FeaturesTablePricing.FeaturesTablePricingAdvancedConfig,
    component: FeaturesTablePricing.FeaturesTablePricingComponent
  },
  PricesAndFeaturesCardsPricingComponent: {
    type: "PricesAndFeaturesCardsPricingComponent",
    title: "Prices And Features Cards",
    imgSrc: "/static/img/segments/layouts/prices-and-features-cards-pricing.png",
    config: PricesAndFeaturesCardsPricing.PricesAndFeaturesCardsPricingConfig,
    advancedConfig: PricesAndFeaturesCardsPricing.PricesAndFeaturesCardsPricingAdvancedConfig,
    component: PricesAndFeaturesCardsPricing.PricesAndFeaturesCardsPricingComponent
  },
  FrequentlyAskedQuestionsPricingComponent: {
    type: "FrequentlyAskedQuestionsPricingComponent",
    title: "Frequently Asked Questions",
    imgSrc: "/static/img/segments/layouts/frequently-asked-questions-pricing.png",
    config: FrequentlyAskedQuestionsPricing.FrequentlyAskedQuestionsPricingConfig,
    advancedConfig: FrequentlyAskedQuestionsPricing.FrequentlyAskedQuestionsPricingAdvancedConfig,
    component: FrequentlyAskedQuestionsPricing.FrequentlyAskedQuestionsPricingComponent
  }
};
