import { PureComponent } from "react";
import { componentStyles } from "./globe-desktop-homepage-first-fold-component.scss";
import SideBySideTextAndAsset from "segments/desktop/core-components/side-by-side-text-and-asset/side-by-side-text-and-asset";

export default class GlobeDesktopHomepageFirstFoldComponent extends PureComponent {
  render() {
    const { height } = this.props;
    return (
      <div className="globe-desktop-homepage-first-fold-component" style={{ height }}>
        <SideBySideTextAndAsset contentClassName="globe-text-and-asset-content" {...this.props} />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
