import css from "styled-jsx/css";

export const componentStyles = css.global`
  .building-blocks-tabs-mobile-component {
    :global(.secondary-button) {
      :global(.secondary-button-text) {
        font-size: 0.8125rem;
      }
      :global(.arrow-wrapper) {
        :global(svg) {
          height: 12px;
          width: 10px;
        }
      }
    }
  }
`;
