import { PureComponent } from "react";
import { componentStyles } from "./side-by-side-with-bullets-text-and-asset-component.scss";
import SideBySideTextAndAssetComponent from "segments/desktop/text-and-asset/layouts/side-by-side-text-and-asset/side-by-side-text-and-asset-component";
import bulletsConfig from "segments/desktop/core-components/bullets/default-props";

export default class SideBySideWithBulletsTextAndAssetComponent extends PureComponent {
  getStyle = () => {
    const { minHeight } = this.props;
    if (!minHeight) return {};

    return { minHeight };
  };

  render() {
    return (
      <div className="side-by-side-with-bullets-text-and-asset-component" style={this.getStyle()}>
        <div className="side-by-side-asset-wrapper">
          <SideBySideTextAndAssetComponent {...this.props} />
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

SideBySideWithBulletsTextAndAssetComponent.defaultProps = {
  bulletsConfig,
  ...SideBySideTextAndAssetComponent.defaultProps
};
