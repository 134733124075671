// plop_marker:layouts_import
import SideBySideWithParagraphsParagraph from "./side-by-side-with-paragraphs-paragraph";
import TwoParagraphsParagraph from "./two-paragraphs-paragraph";
import UniverseParagraph from "./universe-paragraph";
import SideBySideTitleParagraph from "./side-by-side-title-paragraph";
import RotatingTitleParagraph from "./rotating-title-paragraph";
import FullParagraph from "./full-paragraph";
import TitleOnlyParagraph from "./title-only-paragraph";
import FullWithBulletsParagraph from "./full-with-bullets-paragraph";

export default {
  // plop_marker:layouts_add_layout
  SideBySideWithParagraphsParagraph,
  TwoParagraphsParagraph,
  UniverseParagraph,
  SideBySideTitleParagraph,
  RotatingTitleParagraph,
  FullParagraph,
  TitleOnlyParagraph,
  FullWithBulletsParagraph
};
