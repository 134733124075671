import { PureComponent } from "react";
import SideBySideTextAndAsset from "segments/desktop/core-components/side-by-side-text-and-asset/side-by-side-text-and-asset";
import { MD } from "constants/sizes";
import { AUTO } from "constants/cloudinary-asset-qualities";
import { componentStyles } from "./side-by-side-text-and-asset-component.scss";

export default class SideBySideTextAndAssetComponent extends PureComponent {
  render() {
    return (
      <div className="side-by-side-text-and-asset-component">
        <SideBySideTextAndAsset {...this.props} />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

SideBySideTextAndAssetComponent.defaultProps = {
  ...SideBySideTextAndAsset.defaultProps,
  paddingTop: "64px",
  paddingBottom: "64px",
  titleSize: MD,
  textSize: MD,
  assetQuality: AUTO
};
