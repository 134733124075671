import css from "styled-jsx/css";
import colors from "styles/colors";
import { BRAND_BLACK_TEXT_COLOR, WHITE_COLOR_NAME } from "styles/color-consts";
import { LAYER_ONE } from "constants/z-index";

const CARD_BORDER_RADIUS = 8;

export const componentStyles = css`
  .mobile-product-card {
    width: 312px;
    height: auto;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;
    z-index: ${LAYER_ONE};
    margin: 8px;
    padding: 12px;
    border-radius: ${`${CARD_BORDER_RADIUS}px`};
    background-color: ${colors[WHITE_COLOR_NAME]};
    box-shadow: 0 8px 24px rgba(29, 140, 242, 0.16);
    color: ${colors[BRAND_BLACK_TEXT_COLOR]};
    position: relative;
    .card-content {
      margin: 8px;
      .product-card-header {
        margin-bottom: 0;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .product-card-header-image {
          height: unset;
          margin-bottom: unset;

          :global(img) {
            display: flex;
            max-height: 26px;
          }
        }
        .arrow {
          position: relative;
          transition: transform 0.2s ease-in-out;
          &.open {
            transform: rotate(180deg);
          }
        }
      }
      .mobile-product-card-body {
        max-height: 0px;
        transition: max-height 0.15s ease-out;
        overflow: hidden;
        :global(.product-card-body) {
          font-weight: lighter;
          line-height: 1.5;
          font-size: 0.875rem;
          margin-bottom: 16px;
          margin-top: 12px;
        }
        :global(.product-card-use-cases) {
          overflow: hidden;
          :global(.product-card-use-cases-title) {
            margin-bottom: 16px;
            font-size: 0.8125rem;
            font-weight: 500;
          }
          :global(.product-card-use-cases-bullets) {
            :global(.product-card-use-cases-bullet-icon-and-text) {
              display: flex;
              flex-direction: row;
              font-size: 0.8125rem;
              margin-bottom: 12px;
              gap: 4px;
              align-items: center;
              :global(.product-card-use-cases-bullet-icon) {
                display: flex;
              }
            }
          }
        }
        :global(.product-card-footer) {
          :global(.button) {
            :global(.signup-combo-buttons-button) {
              display: flex;
              flex-direction: row;
              align-items: left;
              margin-top: 12px;
              :global(.signup-button) {
                display: none;
              }
              :global(.secondary-button-wrapper) {
                margin-top: 0px;
                font-size: 0.281rem;
                margin-left: 0px;
                :global(.secondary-button) {
                  :global(.secondary-button-text) {
                    font-size: 0.8125rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .mobile-product-card {
    &.open {
      .card-content {
        .mobile-product-card-body {
          max-height: 500px;
          transition: max-height 0.25s ease-in;
        }
      }
    }
  }
`;
