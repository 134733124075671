import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";

export const getComponentStyles = props => {
  return css.global`
    .vertical-tabs-desktop-component {
      max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
      margin: auto;
      padding-left: 32px;

      .vertical-tabs-title-wrapper {
        padding: 32px 48px;
        text-align: center;
      }

      .side-by-side-text-and-asset {
        .full-paragraph-component-wrapper {
          align-self: ${props ? props.alignItems : "auto"};
          width: 40%;
        }

        .image-wrapper {
          width: 60%;
        }
      }
    }
  `;
}