import { FIELD_TYPES } from "constants/template-field-type";
import TitleFieldsConfig from "segments/desktop/core-components/title/fields-config";
import { XXS, XS_M, XS, SM, MD, LG, LGR, XL } from "constants/sizes";

export default {
  ...TitleFieldsConfig,
  theme: {
    type: FIELD_TYPES.COLOR_PICKER,
    label: "Theme",
    possibleColors: ["riverstone", "gradiant-gray"]
  },
  titleSize: {
    type: FIELD_TYPES.COMBO,
    label: "Title Size",
    options: [
      { value: XXS, text:  "11px (formerly 12px)" },
      { value: XS_M, text: "14px (formerly 16px)" },
      { value: XS, text:   "22px (formerly 24px)" },
      { value: SM, text:   "28px (formerly 32px)" },
      { value: MD, text:   "36px (formerly 40px)" },
      { value: LG, text:   "44px (formerly 50px)" },
      { value: LGR, text:  "54px (formerly 60px)" },
      { value: XL, text:   "64px (formerly 72px)" }
    ]
  },
  textAlign: {
    type: FIELD_TYPES.COMBO,
    label: "Text Align",
    options: [
      { value: "left", text: "Left" },
      { value: "center", text: "Center" },
      { value: "right", text: "Right" }
    ]
  },
};
