import { PureComponent } from "react";
import { componentStyles } from "./testimonials-quote.scss";
import DefaultProps from "./default-props";

export default class TestimonialsQuote extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { testimonial } = this.props;

    return (
      <div className="testimonials-quote">
        <div className="quote-background" />
        <div className="testimonials-text">{testimonial}</div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

TestimonialsQuote.defaultProps = DefaultProps;
