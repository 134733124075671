import { FIELD_TYPES } from "constants/template-field-type";
import BoardTabsConfig from "segments/desktop/core-components/board-tabs/fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Board",
  fields: {
    ...BoardTabsConfig
  }
};
