import { defaultData } from "./default-data";

// plop_marker:segments_import
import Templates from "segments/desktop/templates";
import Shape from "segments/desktop/shape";
import Pricing from "segments/desktop/pricing";
import Form from "segments/desktop/form";
import Blog from "segments/desktop/blog";
import Iframe from "segments/desktop/iframe";
import Embedded from "segments/desktop/embedded";
import Banner from "segments/desktop/banner";
import HomepageFirstFold from "segments/desktop/homepage-first-fold";
import Integrations from "segments/desktop/integrations";
import Button from "segments/desktop/button";
import Enterprise from "segments/desktop/enterprise";
import Asset from "segments/desktop/asset";
import Header from "segments/desktop/header";
import Footer from "segments/desktop/footer";
import Grid from "segments/desktop/grid";
import Testimonials from "segments/desktop/testimonials";
import Logos from "segments/desktop/logos";
import Reviews from "segments/desktop/reviews";
import TextAndAsset from "segments/desktop/text-and-asset";
import Paragraph from "segments/desktop/paragraph";
import Tabs from "segments/desktop/tabs";
import Security from "segments/desktop/security";

export const DESKTOP_DYNAMIC_TEMPLATE_CONFIG = {
  segments: [
    // plop_marker:add_segment_config
    {
      type: "TEXT_AND_ASSET",
      title: "Text And Asset",
      imgSrc: "/static/img/segments/icons/text-and-asset.png",
      segmentLayouts: [
        // plop_marker:add_layout_config_text-and-asset
        TextAndAsset.SideBySideWithIconsTextAndAssetComponent,
        TextAndAsset.TwoThirdsTextAndAssetComponent,
        TextAndAsset.FullBackgroundTextAndAssetComponent,
        TextAndAsset.SideBySideWithBackgroundTextAndAssetComponent,
        TextAndAsset.SideBySideTextAndAssetComponent,
        TextAndAsset.SideBySideWithBulletsTextAndAssetComponent,
        TextAndAsset.TestimonialsTextAndAssetComponent,
        TextAndAsset.BoardTabTextAndAssetComponent,
        TextAndAsset.YoutubeTextAndAssetComponent,
        TextAndAsset.YoutubeTwoThirdsTextAndAssetComponent,
        TextAndAsset.AsymmetricSideBySideTextAndAssetComponent
      ]
    },
    {
      type: "ASSET",
      title: "Asset",
      imgSrc: "/static/img/segments/icons/asset.png",
      segmentLayouts: [
        // plop_marker:add_layout_config_asset
        Asset.VideoBackgroundAssetComponent,
        Asset.CarouselAssetComponent,
        Asset.VerticalTagsWithImagesAssetComponent,
        Asset.ImagesCarouselWithTagsGridAssetComponent,
        Asset.RegularAssetComponent,
        Asset.EmbeddedYoutubeAssetComponent,
        Asset.YoutubeVideoAssetComponent
      ]
    },
    {
      type: "PARAGRAPH",
      title: "Paragraph",
      imgSrc: "/static/img/segments/icons/paragraph.png",
      segmentLayouts: [
        // plop_marker:add_layout_config_paragraph
        Paragraph.FullParagraphComponent,
        Paragraph.TitleOnlyParagraphComponent,
        Paragraph.FullWithBulletsParagraphComponent,
        Paragraph.RotatingTitleParagraphComponent,
        Paragraph.SideBySideTitleParagraphComponent,
        Paragraph.UniverseParagraphComponent,
        Paragraph.TwoParagraphsParagraphComponent,
        Paragraph.SideBySideWithParagraphsParagraphComponent
      ]
    },
    {
      type: "GRID",
      title: "Grid",
      imgSrc: "/static/img/segments/icons/grid.png",
      segmentLayouts: [
        // plop_marker:add_layout_config_grid
        Grid.ConnectedProductsGridComponent,
        Grid.FlexibleCardsGridComponent,
        Grid.SquareTagsGridComponent,
        Grid.IconWithParagraphGridComponent,
        Grid.BlogCardsGridComponent,
        Grid.CardsGridComponent,
        Grid.TagsGridComponent,
        Grid.SelectableTagsGridComponent,
        Grid.ImageWithParagraphGridComponent,
        Grid.OnlyImageGridComponent,
        Grid.ButtonTagsGridComponent,
        Grid.ButtonImageTagsGridComponent,
        Grid.TagItemsGridComponent,
        Grid.SelectableTagsWithCarouselGridComponent,
        Grid.MasonryGridComponent,
        Grid.ProductCardsGridComponent,
        Grid.PressGridComponent
      ]
    },
    {
      type: "TEMPLATES",
      title: "Templates",
      imgSrc: "/static/img/segments/icons/templates.png",
      segmentLayouts: [
        // plop_marker:add_layout_config_templates
        Templates.SingleTemplateTemplatesComponent,
        Templates.MultiTemplatesTemplatesComponent
      ]
    },
    {
      type: "BUTTON",
      title: "Button",
      imgSrc: "/static/img/segments/icons/button.png",
      segmentLayouts: [
        // plop_marker:add_layout_config_button
        Button.BasicButtonComponent
      ]
    },
    {
      type: "TABS",
      title: "Tabs",
      imgSrc: "/static/img/segments/icons/tabs.png",
      divider: true,
      segmentLayouts: [
        // plop_marker:add_layout_config_tabs
        Tabs.SideBySideTabsAndProcessTabsComponent,
        Tabs.SideBySideTabsAndTestimonialTabsComponent,
        Tabs.PersonasTabsComponent,
        Tabs.SideBySideBoardViewsComponentTabsComponent,
        Tabs.UseCasesTabsComponent,
        Tabs.MondayDemoTabsComponent,
        Tabs.TimelineTabsComponent,
        Tabs.ColoredTimerVerticalTabsComponent,
        Tabs.TimerVerticalTabsComponent,
        Tabs.RegularTabsComponent,
        Tabs.VerticalTabsComponent,
        Tabs.BuildingBlocksTabsComponent
      ]
    },
    {
      type: "REVIEWS",
      title: "Reviews",
      imgSrc: "/static/img/segments/icons/reviews.png",
      segmentLayouts: [
        // plop_marker:add_layout_config_reviews
        Reviews.ParagraphAndCarouselReviewsComponent,
        Reviews.RegularReviewsComponent,
        Reviews.WithTitleReviewsComponent,
        Reviews.ReviewCardsReviewsComponent
      ]
    },
    {
      type: "TESTIMONIALS",
      title: "Testimonials",
      imgSrc: "/static/img/segments/icons/testimonials.png",
      segmentLayouts: [
        // plop_marker:add_layout_config_testimonials
        Testimonials.CarouselTestimonialsComponent,
        Testimonials.PlainClassicTestimonialsComponent
      ]
    },
    {
      type: "LOGOS",
      title: "Logos",
      imgSrc: "/static/img/segments/icons/logos.png",
      segmentLayouts: [
        // plop_marker:add_layout_config_logos
        Logos.LogosAndParagraphLogosComponent,
        Logos.AllLogosLogosComponent,
        Logos.TopLogosComponent,
        Logos.BottomLogosComponent,
        Logos.ImagesLogosComponent
      ]
    },
    {
      type: "SECURITY",
      title: "Security",
      imgSrc: "/static/img/segments/icons/security.png",
      segmentLayouts: [
        // plop_marker:add_layout_config_security
        Security.LogosAndHeaderSecurityComponent
      ]
    },
    {
      type: "INTEGRATIONS",
      title: "Integrations",
      imgSrc: "/static/img/segments/icons/integrations.png",
      divider: true,
      segmentLayouts: [
        // plop_marker:add_layout_config_integrations
        Integrations.MondayIntegrationsConnectionsComponent,
        Integrations.FloatingIntegrationsComponent
      ]
    },
    {
      type: "ENTERPRISE",
      title: "Enterprise",
      imgSrc: "/static/img/segments/icons/enterprise.png",
      segmentLayouts: [
        // plop_marker:add_layout_config_enterprise
        Enterprise.SalesPhoneEnterpriseComponent,
        Enterprise.AsymmetricEnterpriseComponent
      ]
    },
    {
      type: "FORM",
      title: "Form",
      imgSrc: "/static/img/segments/icons/form.png",
      divider: true,
      segmentLayouts: [
        // plop_marker:add_layout_config_form
        Form.HubspotWithBulletsSideBySideFormComponent,
        Form.HubspotAndLogosFormComponent,
        Form.ZendeskSideBySideFormComponent,
        Form.ZendeskFormComponent,
        Form.HubspotSideBySideFormComponent
      ]
    },
    {
      type: "BLOG",
      title: "Blog",
      imgSrc: "/static/img/segments/icons/blog.png",
      segmentLayouts: [
        // plop_marker:add_layout_config_blog
        Blog.RichTextBlogComponent
      ]
    },
    {
      type: "IFRAME",
      title: "Iframe",
      imgSrc: "/static/img/segments/icons/iframe.png",
      segmentLayouts: [
        // plop_marker:add_layout_config_iframe
        Iframe.EmbeddedBoardIframeComponent,
        Iframe.SideBySideHubspotFormComponent, // old hubspot component returned
        Iframe.IframeDynamicWidthIframeComponent
      ]
    },
    {
      type: "BANNER",
      title: "Banner",
      imgSrc: "/static/img/segments/icons/banner.png",
      segmentLayouts: [
        // plop_marker:add_layout_config_banner
        Banner.PlainBannerComponent,
        Banner.WithBackgroundBannerComponent
      ]
    },
    {
      type: "PRICING",
      title: "Pricing",
      imgSrc: "/static/img/segments/icons/pricing.png",
      segmentLayouts: [
        // plop_marker:add_layout_config_pricing
        Pricing.FeaturesTablePricingComponent,
        Pricing.PricesAndFeaturesCardsPricingComponent,
        Pricing.FrequentlyAskedQuestionsPricingComponent
      ]
    },
    {
      type: "EMBEDDED",
      title: "Embedded",
      imgSrc: "/static/img/segments/icons/embedded.png",
      segmentLayouts: [
        // plop_marker:add_layout_config_embedded
        Embedded.MarkdownEmbeddedComponent
      ]
    },
    {
      type: "HOMEPAGE_FIRST_FOLD",
      title: "Homepage First Fold",
      imgSrc: "/static/img/segments/icons/homepage-first-fold.png",
      divider: true,
      segmentLayouts: [
        // plop_marker:add_layout_config_homepage-first-fold
        HomepageFirstFold.FullParagraphAndTagsHomepageFirstFoldComponent,
        HomepageFirstFold.LiveBoardHomepageFirstFoldComponent,
        HomepageFirstFold.SignUpWithProductsCardsHomepageFirstFoldComponent,
        HomepageFirstFold.WelcomeBackHomepageFirstFoldComponent,
        HomepageFirstFold.MondayUniverseHomepageFirstFoldComponent,
        HomepageFirstFold.ScrollParallaxHomepageFirstFoldComponent,
        HomepageFirstFold.GlobeWithTopCtaHomepageFirstFoldComponent,
        HomepageFirstFold.GlobeHomepageFirstFoldComponent,
        HomepageFirstFold.AnimatingBoardHomepageFirstFoldComponent
      ]
    },
    {
      type: "SHAPE",
      title: "Shapes (Beta)",
      imgSrc: "/static/img/segments/icons/shape.png",
      divider: true,
      segmentLayouts: [
        // plop_marker:add_layout_config_shape
        Shape.SquareShapeComponent,
        Shape.RectangleShapeComponent,
        Shape.StarShapeComponent,
        Shape.HotdogShapeComponent,
        Shape.RingShapeComponent
      ]
    },
    {
      type: "FOOTER",
      title: "Footer",
      imgSrc: "/static/img/segments/icons/footer.png",
      segmentLayouts: [
        // plop_marker:add_layout_config_footer
        Footer.BaseFooterComponent
      ]
    },
    {
      type: "HEADER",
      title: "Header",
      imgSrc: "/static/img/segments/icons/header.png",
      segmentLayouts: [
        // plop_marker:add_layout_config_header
        Header.BaseHeaderComponent
      ]
    }
  ],
  defaultData
};
