import { PureComponent } from "react";
import { componentStyles } from "./zendesk-side-by-side-form-component.scss";
import ZendeskForm from "segments/desktop/core-components/zendesk-form/zendesk-form";
import SideBySideTextAndAsset from "segments/desktop/core-components/side-by-side-text-and-asset/side-by-side-text-and-asset";

export default class ZendeskSideBySideFormComponent extends PureComponent {
  render() {
    return (
      <div className="zendesk-side-by-side-form-component">
        <SideBySideTextAndAsset replaceTextComponent={ZendeskForm} {...this.props} />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

ZendeskSideBySideFormComponent.defaultProps = {
  asset: {
    position: "center",
    fitToContainer: true,
    image: {
      src: "/static/img/segment-layout-default-images/sales_social.png"
    }
  }
};
