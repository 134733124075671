import FullParagraphDefaultProps from "segments/desktop/core-components/full-paragraph/default-props";
import { AUTO } from "constants/cloudinary-asset-qualities";

export default {
  contentClassName: "",
  swapTextAndAssetPosition: false,
  leftSizeWidth: null,
  rightSizeWidth: null,
  background: {},
  fullParagraph: FullParagraphDefaultProps,
  asset: {
    position: "center",
    fitToContainer: true,
    image: {
      src: "/static/img/segment-layout-default-images/text-and-asset/board-with-dragged-column-in-circle.png"
    }
  },
  assetQuality: AUTO,
  withDynamicParagraphTitle: false,
  withDynamicParagraphSubtitle: false
};
