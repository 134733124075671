export const CUSTOMER_KEY_OSCAR = "oscar";
export const CUSTOMER_KEY_THE_STUDENT_HOTEL = "theStudentHotel";
export const CUSTOMER_KEY_TELEFONICA = "telefonica";
export const CUSTOMER_KEY_ZIPPO = "zippo";
export const CUSTOMER_KEY_NISSAN = "nissan";

export default {
  [CUSTOMER_KEY_OSCAR]: {
    companyName: "Oscar",
    name: "Stefana Muller",
    jobDescription: "Senior Director, CTO Product and Program Office",
    quote: "monday.com Work OS saves us about 1,850 hrs of staff time and somewhere in the range of $50,000 a month."
  },
  [CUSTOMER_KEY_THE_STUDENT_HOTEL]: {
    companyName: "The Student Hotel",
    name: "Charlie MacGregor",
    jobDescription: "Founder & CEO",
    quote:
      "Now that we have monday.com Work OS as the backbone of our organization I feel that I have an overview I can trust."
  },
  [CUSTOMER_KEY_TELEFONICA]: {
    companyName: "Telefonica",
    name: "Tye Rapley-Hawkins",
    jobDescription: "Head of Delivery",
    quote:
      "With monday.com, we’re 30% more efficient at delivering hundreds of campaigns seen by millions of customers."
  },
  [CUSTOMER_KEY_ZIPPO]: {
    companyName: "Zippo",
    name: "Chris Funk",
    jobDescription: "Director of Product Innovation",
    quote:
      "monday.com Work OS has enabled our group to launch more product categories and expand into more markets in way less time."
  },
  [CUSTOMER_KEY_NISSAN]: {
    companyName: "Nissan",
    name: "Kenny Wallace",
    jobDescription: "Vendor Tooling Engineer, Nissan North America",
    quote:
      "monday.com has given me the confidence because when I walk into the room I know that when upper management asks for a certain piece of information I’m able to quickly provide it for them."
  }
};
