import { FIELD_TYPES } from "constants/template-field-type";

export default {
  topbar: {
    type: FIELD_TYPES.NESTED,
    label: "Top Bar",
    fields: {
      showMenu: { type: FIELD_TYPES.BOOLEAN, label: "Show Menu" },
      shortHeader: { type: FIELD_TYPES.BOOLEAN, label: "Short Header" }
    }
  }
};
