import {useEffect, useState} from "react";
import classnames from "classnames";
import { SELECTED_TAG_EVENT } from "/constants/bigbrain-event-types";
import Colors from "styles/colors";
import Check from "/static/svg/check.svg";
import { generateOnKeyDownHandler } from "client/services/accessibility/accessibility-service";
import { SPACE_BLUE_COLOR_NAME } from "styles/color-consts";
import { MAIN_CLASSNAME } from "./selectable-tag-constants";
import DefaultProps from "./default-props";
import { componentStyles } from "./selectable-tag.scss";

const SelectableTag = (props) => {
  const { tagText, showMobileClusterTags, darkMode, onHover, value, galleryImage, tagBackgroundColor, overridePageCluster, isSelected } = props;
  const [isSelectedState, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(isSelected);
  },[isSelected])

  const blurTag = (e) => {
    e?.target?.closest(`.${MAIN_CLASSNAME}`)?.blur();
  };

  const onMouseOver = () => {
    const tag = { isSelectedState, tagText, value, galleryImage };
    onHover && onHover(tag);
  };

  const onClick = (e) => {
    blurTag(e);
    toggleTagSelection();
  };

  const toggleTagSelection = () => {
    const nextSelectedState = !isSelectedState;

    setIsSelected(nextSelectedState);

    props.onClick && props.onClick(nextSelectedState);

    BigBrain("track", SELECTED_TAG_EVENT, {
      kind: nextSelectedState,
      info1: tagText,
      info2: tagBackgroundColor
    });
  };

  const getTagContainerStyle = () => {
    if (showMobileClusterTags) {
      return {
        border: `1px solid ${Colors[tagBackgroundColor]}`
      };
    }

    return {};
  };

  const getTagImageContainerStyle = () => {
    return {
      color: Colors[tagBackgroundColor]
    };
  };

  const getCheckIconImageStyle = () => {
    const fill = darkMode ? Colors[SPACE_BLUE_COLOR_NAME] : Colors.white;
    return { fill };
  };

  return (
    <div
      className={classnames(MAIN_CLASSNAME, {
        selected: isSelectedState,
        "dark-mode": darkMode,
        square: !showMobileClusterTags,
        "mobile-clusters": showMobileClusterTags
      })}
      style={getTagContainerStyle()}
      onClick={onClick}
      onKeyDown={generateOnKeyDownHandler(toggleTagSelection)}
      tabIndex={0}
      onMouseEnter={onMouseOver}
      onFocus={onMouseOver}
    >
      {!showMobileClusterTags && (
        <div className="selectable-tag-image-container" style={getTagImageContainerStyle()}>
          <Check className="selectable-tag-image" style={getCheckIconImageStyle()} />
        </div>
      )}
      <span className="tag-text">{tagText}</span>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

SelectableTag.defaultProps = DefaultProps;

export default SelectableTag;