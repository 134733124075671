import { TEMPLATE_NAMES } from "constants/templates-names";

export const defaultData = {
  template: TEMPLATE_NAMES.MobileDynamicTemplate,
  title: "",
  description: "",
  theme: "dark-theme",
  mobileTheme: "dark-theme",
  imagesLazyLoad: false,
  isMobile: true,
  segments: []
};
