import { useRef, useState } from "react";
import classnames from "classnames";
import YoutubeVideoComponent from "components/core/youtube-video/youtube-video-component";
import PictureComponent from "/components/core/picture/picture-component";
import componentStyles from "./youtube-video-with-poster-component.scss";
import { buttonThemes } from "./constants";
import { withGenericConfig } from "/contexts/generic-config-context";
import DefaultProps from "./default-props";

const YOUTUBE_ASPECT_RATIO = 9 / 16;
const YoutubeVideoWithPosterComponent = (props) => {
  const [isPosterVisible, setIsPosterVisible] = useState(true);
  const [playVideo, setPlayVideo] = useState(false);
  const ref = useRef(null);
  const {
    width,
    fitToParent,
    playButtonColor,
    poster,
    previewRenderer,
    alt,
    playButtonSize,
    translate,
    showShadow,
    videoId,
    muted,
    volume,
    videoOpts
  } = props;

  const onPlayButtonClicked = (e) => {
    setIsPosterVisible(false);
    setPlayVideo(true);
  };

  const getInlineStyle = () => {
    const styleWidth = ref.current?.offsetWidth;
    if (fitToParent && styleWidth) {
      return { width: styleWidth, height: styleWidth * YOUTUBE_ASPECT_RATIO };
    }
    return { height: "auto", width };
  };

  const getButtonContainerStyle = () => {
    if (!playButtonColor) return {};

    return { backgroundColor: buttonThemes[playButtonColor]?.color };
  };

  const getButtonArrowStyle = () => {
    if (!playButtonColor) return {};

    return { borderLeftColor: buttonThemes[playButtonColor]?.background };
  };

  const renderPreview = () => {
    if (previewRenderer) {
      return isPosterVisible && previewRenderer();
    }

    return (
      <div
        className={classnames("poster-image-wrapper", { "with-shadow": showShadow })}
        style={{ display: isPosterVisible ? "block" : "none" }}
      >
        <button
          className={classnames("play-button-container", playButtonSize)}
          style={getButtonContainerStyle()}
          aria-label={translate("accessibility.button.playVideo")}
        >
          <div className="play-button" style={getButtonArrowStyle()} />
        </button>
        <PictureComponent
          pictureClass="poster-image"
          alt={alt}
          src={poster}
          screenRatios={{ mobile: 0.99, mobileRetina: 0.99 }}
        />
      </div>
    );
  };

  return (
    <div
      className={classnames("youtube-video-with-poster-component", {
        "hide-poster": !isPosterVisible
      })}
      onClick={onPlayButtonClicked}
      ref={ref}
    >
      <div className="video-component-wrapper" style={{ display: isPosterVisible ? "none" : "block" }}>
        <YoutubeVideoComponent
          videoId={videoId}
          playing={playVideo}
          muted={muted}
          volume={volume}
          opts={{ ...getInlineStyle(), ...videoOpts }}
          showShadow={showShadow}
        />
      </div>
      {renderPreview()}
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default withGenericConfig(YoutubeVideoWithPosterComponent);

YoutubeVideoWithPosterComponent.defaultProps = DefaultProps;
