import { useRef, useEffect, useState } from "react";
import Typed from "typed.js";
import classNames from "classnames";
import DefaultProps from "./default-props";
import { componentStyles } from "./typed-component.scss";

const TypedComponent = (props) => {
  const { typedProps, className, style, stopped } = props;

  const [timeElapsed, setTimeElapsed] = useState(0);
  const el = useRef(null);
  const typed = useRef(null);

  useEffect(() => {
    typed.current = new Typed(el.current, { ...DefaultProps.typedProps, ...typedProps });

    return () => {
      typed.current.destroy();
    };
  }, []);

  useEffect(() => {
    if (stopped) {
      typed.current.stop();
    } else {
      const { startDelay = 0 } = typedProps;
      const startTime = performance.now();

      // Debouncing start if stopped in the proccess
      const delayTime = Math.max(0, startDelay - timeElapsed);
      const handler = setTimeout(() => typed.current.start(), delayTime);

      return () => {
        const endTime = performance.now();
        const newTimeElapsed = timeElapsed + endTime - startTime;
        setTimeElapsed(newTimeElapsed);
        clearTimeout(handler);
      };
    }
  }, [stopped]);

  return (
    <div className={classNames("typed-component", className)} style={style}>
      <span className="content" ref={el} />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default TypedComponent;

TypedComponent.defaultProps = DefaultProps;
