import {
  ZENDESK_FIRST_NAME_INPUT_NAME,
  ZENDESK_LAST_NAME_INPUT_NAME,
  ZENDESK_EMAIL_INPUT_NAME,
  ZENDESK_PHONE_NUM_INPUT_NAME,
  ZENDESK_COMPANY_SIZE_INPUT_NAME,
  ZENDESK_TICKET_SUBJECT_NAME,
  ZENDESK_TOPIC_INPUT_NAME,
  ZENDESK_TICKET_DESCRIPTION_NAME,
  ZENDESK_TICKET_CONTACT_CONSENT_CHECKBOX_NAME,
  getTopicZendeskValue
} from "client/services/zendesk-service/zendesk-inputs";
import { getCookie } from "/services/cookies-service";
import { BIG_BRAIN_VISITOR_ID, MONDAY_PLATFORM_USER_ID, MONDAY_PLATFORM_ACCOUNT_ID } from "/constants/cookies";

const WEBSITE_CREATE_ZENDESK_ENDPOINT = "/dynamic/zendesk/create-ticket";

const getZendeskTicketParams = (formData) => {
  const email = formData[ZENDESK_EMAIL_INPUT_NAME];
  const name = `${formData[ZENDESK_FIRST_NAME_INPUT_NAME]} ${formData[ZENDESK_LAST_NAME_INPUT_NAME]}`;
  const subject = formData[ZENDESK_TICKET_SUBJECT_NAME];
  const phone = formData[ZENDESK_PHONE_NUM_INPUT_NAME];
  const companySize = formData[ZENDESK_COMPANY_SIZE_INPUT_NAME];
  const message = formData[ZENDESK_TICKET_DESCRIPTION_NAME];
  const contactConsent = formData[ZENDESK_TICKET_CONTACT_CONSENT_CHECKBOX_NAME];
  const topic = getTopicZendeskValue(formData[ZENDESK_TOPIC_INPUT_NAME]);
  const vid = getCookie(BIG_BRAIN_VISITOR_ID);
  const uid = getCookie(MONDAY_PLATFORM_USER_ID);
  const aid = getCookie(MONDAY_PLATFORM_ACCOUNT_ID);

  return { email, name, subject, phone, companySize, message, contactConsent, vid, uid, aid, topic };
};

export const createZendeskTicket = async (formData) => {
  const params = getZendeskTicketParams(formData);

  const request = await fetch(WEBSITE_CREATE_ZENDESK_ENDPOINT, {
    method: "POST",
    body: JSON.stringify({
      params
    }),
    headers: {
      "content-type": "application/json"
    }
  });

  try {
    const response = await request.json();
    return { success: response.success, message: response.message };
  } catch (e) {
    return { success: false };
  }
};
