import { PureComponent } from "react";
import { componentStyles } from "./timer-verticals-tabs-mobile-component.scss";
import VerticalTimerTabMobileComponent from "./vertical-timer-tab-mobile/vertical-timer-tab-mobile-component";
import Button from "segments/desktop/core-components/button/button";

export default class TimerVerticalTabsMobileComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 0
    };
  }

  setSelectedTab = (newSelectedTab) => {
    const { timerEnabled } = this.props;

    this.setState(prevState => ({
      selectedTab: !timerEnabled && prevState.selectedTab === newSelectedTab ? null : newSelectedTab
    }));
  };

  renderTabs = () => {
    const { selectedTab } = this.state;
    const { tabs, tabContentRenderer, assetQuality, title, showArrow, progressbarColor, alwaysShowProgressbar, arrowSize } = this.props;
    const { fontSize, selectedFontSize, fontColor, selectedFontColor, fontWeight } = title || {};

    return tabs.map((tab, index) => {
      return (
        <VerticalTimerTabMobileComponent
          key={index}
          index={index}
          {...tab}
          isSelected={index === selectedTab}
          setSelectedTab={this.setSelectedTab}
          assetQuality={assetQuality}
          showArrow={showArrow}
          tabContentRenderer={tabContentRenderer}
          fontSize={fontSize}
          selectedFontSize={selectedFontSize}
          fontColor={fontColor}
          selectedFontColor={selectedFontColor}
          titleFontWeight={fontWeight}
          progressbarColor={progressbarColor}
          alwaysShowProgressbar={alwaysShowProgressbar}
          arrowSize={arrowSize}
        />
      );
    });
  };

  render() {
    const { showButton, buttonConfig } = this.props;
    return (
      <div className="mobile-timer-tabs-component">
        {this.renderTabs()}
        {showButton && (
          <div className="mobile-button-link">
            <Button {...buttonConfig} />
          </div>
        )}
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
