import dynamic from "next/dynamic";

const FullBackgroundHeroComponent = require("./full-background-hero-component").default;

import FullBackgroundHeroConfig from "./full-background-hero-config";

export default {
  FullBackgroundHeroComponent,
  FullBackgroundHeroConfig
};
