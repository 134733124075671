import css from "styled-jsx/css";
import { MOBILE_QUERY_MAX_WIDTH_M, MOBILE_QUERY_MAX_WIDTH, MACBOOK_PRO_MAX_WIDTH } from "constants/vp-sizes";

const MAX_ASYMETRIC_DIV_HEIGHT = 180;
const MACBOOK_ASYMETRIC_DIV_HEIGHT = 116;
const MOBILE_ASYMETRIC_DIV_HEIGHT = 90;
const SMALL_MOBILE_ASYMETRIC_DIV_HEIGHT = 80;

const widthBorder = () => {
  return `50vw solid`;
};

const heightBorder = (divHeight) => {
  return `${divHeight / 2}px solid`;
};

export const componentStyles = css`
  .triangle-with-color {
    height: ${MAX_ASYMETRIC_DIV_HEIGHT} + "px";
    width: 100%;

    border-right: ${widthBorder()};
    border-left: ${widthBorder()};
    border-bottom: ${heightBorder(MAX_ASYMETRIC_DIV_HEIGHT)};
    border-top: ${heightBorder(MAX_ASYMETRIC_DIV_HEIGHT)};

    @media (max-width: ${MACBOOK_PRO_MAX_WIDTH}) {
      height: ${MACBOOK_ASYMETRIC_DIV_HEIGHT} + "px";
      border-bottom: ${heightBorder(MACBOOK_ASYMETRIC_DIV_HEIGHT)};
      border-top: ${heightBorder(MACBOOK_ASYMETRIC_DIV_HEIGHT)};
    }

    @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
      height: ${MOBILE_ASYMETRIC_DIV_HEIGHT}+ "px";
      border-bottom: ${heightBorder(MOBILE_ASYMETRIC_DIV_HEIGHT)};
      border-top: ${heightBorder(MOBILE_ASYMETRIC_DIV_HEIGHT)};
    }

    @media (max-width: ${MOBILE_QUERY_MAX_WIDTH_M}) {
      height: ${SMALL_MOBILE_ASYMETRIC_DIV_HEIGHT}+ "px";
      border-bottom: ${heightBorder(SMALL_MOBILE_ASYMETRIC_DIV_HEIGHT)};
      border-top: ${heightBorder(SMALL_MOBILE_ASYMETRIC_DIV_HEIGHT)};
    }
  }
`;
