import { useMemo, useState } from "react";
import classnames from "classnames";
import useInterval from "client/services/hooks/use-interval";
import PictureComponent from "components/core/picture/picture-component";
import { trackEvent } from "services/bigbrain-service";
import { CAROUSEL_PAGE_CLICKED } from "constants/bigbrain-event-types";
import { generateOnKeyDownHandler } from "client/services/accessibility/accessibility-service";
import DefaultProps from "./default-props";
import { componentStyles } from "./reviews-carousel.scss";

const DEFAULT_AUTOPLAY_INTERVAL = 10000; // 10 seconds
export const INDICATOR_WRAPPER_CLASS_NAME = "indicator-wrapper";

const ReviewsCarousel = (props) => {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const {
    autoplay: { shouldAutoPlay, interval },
    reviews,
    removeQuoteSigns
  } = props;

  useInterval(
    () => {
      setCurrentReviewIndex((currentReviewIndex + 1) % reviews.length);
    },
    shouldAutoPlay ? Number(interval) || DEFAULT_AUTOPLAY_INTERVAL : null,
    [currentReviewIndex]
  );

  const selectReview = (reviewIndex, e) => {
    e?.target?.closest(`.${INDICATOR_WRAPPER_CLASS_NAME}`)?.blur();
    setCurrentReviewIndex(reviewIndex);
    trackEvent(CAROUSEL_PAGE_CLICKED, { info1: reviewIndex });
  };

  const hasCustomerImage = (review) => {
    return review?.customerImage?.src;
  };

  const hasCompanyLogo = (review) => {
    return review?.companyLogo?.src;
  };

  const hasAtLeastOneImage = useMemo(
    () => reviews?.reduce((result, review) => result || hasCustomerImage(review), false),
    [reviews]
  );

  return (
    <div className="reviews-carousel">
      <div
        className={classnames("reviews-carousel-content-and-indicators", { "has-customer-image": hasAtLeastOneImage })}
      >
        <div className="reviews-carousel-content">
          {reviews.map((review, index) => {
            const { quote = "", name = "", jobDescription = "", companyLogo } = review;
            return (
              <div key={index} className={classnames("quote-wrapper", { selected: index === currentReviewIndex })}>
                <div className="content-wrapper">
                  <blockquote className="quote" id={`quote-${index}`}>
                    {!removeQuoteSigns && <span className="quotationMark">“</span>}
                    <span className="quoteText">{`${removeQuoteSigns ? quote : `${quote}”`}`}</span>
                  </blockquote>
                  <div className="footer">
                    <div
                      className="name-and-job-description"
                      id={`reviewer-${index}-name-and-job-description`}
                    >{`${name} | ${jobDescription}`}</div>
                    <div className={classnames("logo", { hidden: !hasCompanyLogo(review) })}>
                      {hasCompanyLogo(review) && <PictureComponent {...companyLogo} height="38px" />}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="indicators">
          {reviews.map((review, indicatorIndex) => (
            <div
              key={indicatorIndex}
              className={INDICATOR_WRAPPER_CLASS_NAME}
              onClick={(e) => selectReview(indicatorIndex, e)}
              onKeyDown={generateOnKeyDownHandler(() => selectReview(indicatorIndex))}
              aria-label={`review${indicatorIndex + 1}`}
              aria-describedby={`reviewer-${indicatorIndex}-name-and-job-description quote-${indicatorIndex}`}
              aria-current={indicatorIndex === currentReviewIndex}
              role="button"
              tabIndex={0}
            >
              <div className={classnames("indicator", { selected: indicatorIndex === currentReviewIndex })} />
            </div>
          ))}
        </div>
      </div>
      <div
        className={classnames("reviews-carousel-asset", { hidden: !hasCustomerImage(reviews?.[currentReviewIndex]) })}
      >
        {hasCustomerImage(reviews?.[currentReviewIndex]) && (
          <PictureComponent {...reviews?.[currentReviewIndex]?.customerImage} />
        )}
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default ReviewsCarousel;

ReviewsCarousel.defaultProps = DefaultProps;
