import { FIELD_TYPES } from "constants/template-field-type";
import EmbeddedBoardFieldsConfig from "segments/desktop/core-components/embedded-board/fields-config";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";

const { textColor, textAlign, topic, buttonConfig, ...selectedFullParagraphFields } = FullParagraphFieldsConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "Embedded Board",
  fields: {
    fullParagraph: {
      type: FIELD_TYPES.NESTED,
      label: "Full Paragraph",
      fields: selectedFullParagraphFields
    },
    ...EmbeddedBoardFieldsConfig
  }
};
