import SquareTagsFieldsConfig from "segments/desktop/core-components/square-tags/fields-config";
import { FIELD_TYPES } from "constants/template-field-type";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Square Tags",
  fields: {
    ...SquareTagsFieldsConfig
  }
};
