import css from "styled-jsx/css";
import DesktopHeaderConstants from "segments/desktop/header/layouts/components/basic-header/constants";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import Colors from "/styles/colors";

const HEADER_LOGO_HORIZONTAL_PADDING = `${DesktopHeaderConstants.HEADER_CONTENT_HORIZONTAL_PADDING}px`;

export const componentStyles = css.global`
  .personas-tabs-component-container {
    margin: 0 auto;
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};

    &.align-to-logo {
      padding-left: ${HEADER_LOGO_HORIZONTAL_PADDING};
      padding-right: ${HEADER_LOGO_HORIZONTAL_PADDING};
    }

    .personas-tabs-component {
      padding: 48px;
      background-color: var(--tab-background-color);
      border-radius: 16px;

      .tabs {
        border-bottom: var(--tab-underline-color) solid; //  makes the undeline under the tab names combined.
        border-bottom-width: 2px;

        .tab-component {
          background-color: transparent; // no background to tab name

          .tab-underline-placeholder {
            background-color: transparent;
          }

          .tab-label {
            font-weight: 300;
          }
          :hover {
            outline: 0;
            background-color: ${Colors["white-iris"]};
            border-radius: 8px 8px 0px 0px;
            .tab-label {
              color: ${Colors["mud"]};
            }
          }
          &.active {
            background-color: transparent;
            .tab-label {
              font-weight: 600;
            }
          }
        }
      }
      .two-thirds-text-and-asset-component {
        padding-top: var(--text-and-asset-padding-top);
      }

      .side-by-side-content {
        background-color: transparent !important;
      }

      .full-paragraph-component {
        padding-top: var(--full-paragraph-padding-top);
      }

      .tag-items-grid-component {
        padding-top: var(--tag-items-padding-top);

        .grid-tag-wrapper {
          margin: 0px 32px 0px 0;
        }
      }

      .flexible-cards-grid-desktop-component {
        padding-top: var(--flexible-cards-padding-top);

        .flexible-cards-grid-desktop-component-inner {
          .grid-paragraph-with-image-component-wrapper {
            width: 100%;
          }
        }
      }

      .grid-paragraph-with-image {
        display: flex;
        align-items: center;

        .grid-image {
          padding-bottom: var(--flexible-cards-padding-bottom);
        }
      }
    }
  }
`;
