import { FIELD_TYPES } from "constants/template-field-type";
import ButtonFieldsConfig from "segments/desktop/core-components/button/fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Basic Button",
  description: "Mind tests on signup form component",
  fields: {
    textAlign: {
      type: FIELD_TYPES.COMBO,
      label: "Button Align",
      options: [
        { value: "left", text: "Left" },
        { value: "center", text: "Center" },
        { value: "right", text: "Right" }
      ]
    },
    buttonConfig: ButtonFieldsConfig
  }
};
