import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
import SelectableTagsGridAdvancedFieldsConfig from "segments/desktop/core-components/selectable-tags-grid/advanced-field-config";
import TitleAdvancedFieldsConfig from "segments/desktop/core-components/title/advanced-field-config";
import ParagraphAdvancedFieldsConfig from "segments/desktop/core-components/paragraph/advanced-fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "",
  fields: {
    withFirstStateArrow: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Show first state arrow"
    },
    paragraphTagsPadding: {
      type: FIELD_TYPES.TEXT,
      label: "Padding between Text and Tags"
    },
    ...SelectableTagsGridAdvancedFieldsConfig,
    ...SharedAdvancedFieldsConfig,
    ...ParagraphAdvancedFieldsConfig,
    titleWidth: TitleAdvancedFieldsConfig.titleWidth
  }
};
