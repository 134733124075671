import { FIELD_TYPES } from "constants/template-field-type";
import AssetFieldsConfig from "components/core/asset/fields-config.js";
import PersonalTextualInfoConfig from "../person-textual-info/fields-config";

const { image } = AssetFieldsConfig;
const { color, ...selectedTextualInfoFields } = PersonalTextualInfoConfig;

export default {
  autoplay: {
    type: FIELD_TYPES.NESTED,
    label: "Auto-play definitions",
    fields: {
      shouldAutoPlay: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Should auto-play reviews"
      },
      interval: {
        type: FIELD_TYPES.TEXT,
        label: "Interval (number of milliseconds)"
      }
    }
  },
  reviews: {
    type: FIELD_TYPES.MULTIPLE_NESTED,
    label: "Reviews",
    maxLimit: 15,
    nestedConfig: {
      type: FIELD_TYPES.NESTED,
      fields: {
        quote: { type: FIELD_TYPES.TEXT, label: "Quote", textArea: true, translatable: true },
        ...selectedTextualInfoFields,
        companyLogo: {
          ...image,
          label: "Company Logo"
        },
        customerImage: {
          ...image,
          label: "Customer Image",
          labelDescription: "Please select an image with 100x100 dimensions."
        }
      }
    }
  }
};
