import css from "styled-jsx/css";
import Colors from "styles/colors";

export const componentStyles = css.global`
  .tag{
      padding: 0 4px;
      margin: 0 2px;
      font-weight: 400;
      font-size: 0.6875rem;
      line-height: 154%;
      border-radius: 2px;
      width: fit-content;
  }
`;