import css from "styled-jsx/css";
import colors from "styles/colors";
import { BRAND_BLACK_TEXT_COLOR, MARKETING_RED_COLOR_NAME } from "styles/color-consts";
import { LAYER_ONE } from "constants/z-index";

export const componentStyles = css.global`
  .product-card-medium {
    width: 650px;
    z-index: ${LAYER_ONE};
    background-color: white;
    box-shadow: 0px 0px 10px 1px rgba(29, 140, 242, 0.16);
    border-radius: 0px 8px 8px 0px;
    color: ${colors[BRAND_BLACK_TEXT_COLOR]};
    position: relative;
    .product-card-content {
      padding: 32px 58px;
      position: relative;

      .product-card-header {
        margin-bottom: 32px;

        .product-card-header-image {
          height: 51px;
          margin-bottom: 5px;

          img {
            max-height: 48px;
          }
        }

        .product-card-header-subtitle {
          color: ${colors.asphalt};
          font-size: 0.8125rem;
        }
      }

      .product-card-body {
        font-weight: lighter;
        line-height: 1.5;
        width: 90%;
      }

      .product-card-divider {
        box-sizing: content-box;
        border-width: 1px;
        border-style: solid;
        border-image: linear-gradient(to right, #ff158aff, #ff158a00);
        border-image-slice: 1;
        margin-bottom: 20px;
        margin-top: 32px;
        margin-bottom: 32px;
      }

      .product-card-use-cases {
        width: 400px;
        .product-card-use-cases-title {
          margin-bottom: 12px;
          font-size: 0.8125rem;
        }

        .product-card-use-cases-bullets {
          display: inline-grid;
          grid-template-columns: max-content max-content;
          column-gap: 20px;
          row-gap: 10px;
          margin-bottom: 10px;
          flex-direction: column;

          .product-card-use-cases-bullet-icon-and-text {
            display: flex;
            margin-bottom: 5px;
            font-size: 0.8125rem;
            align-items: center;
            .product-card-use-cases-bullet-icon {
              display: flex;
              color: ${colors[MARKETING_RED_COLOR_NAME]};
              margin-right: 5px;
            }
          }
        }
      }

      @mixin card-button {
        font-size: 0.8125rem;
        white-space: nowrap;
        min-width: unset;

        * {
          font-weight: 400;
        }
      }

      .product-card-footer {
        padding-top: 20px;
        .signup-combo-buttons-button .signup-button.ladda-button {
          @include card-button;
        }

        .signup-form-core-component-wrapper .signup-form {
          justify-content: flex-start;

          .signup-button {
            width: 160px;
            @include card-button;
          }
        }

        .secondary-button {
          font-size: 0.8125rem;
          margin-left: 26px;
          a {
            &.with-underline {
              background: unset;
              .secondary-button-text {
                color: ${colors.asphalt};
              }
            }
          }

          * {
            font-weight: 500;
          }
        }
      }
    }
    &.has-no-use-cases {
      .product-card-footer {
        padding-top: 0px;
      }
    }
  }
`;
