import css from "styled-jsx/css";
import Colors from "/styles/colors";
import { MAX_DESKTOP_CONTENT_WIDTH } from "constants/vp-sizes";
import FormConstants from "constants/forms";
import { getComponentShadow } from "segments/desktop/constants";

const MAX_DESKTOP_CONTENT_WIDTH_PX = `${MAX_DESKTOP_CONTENT_WIDTH}px`;
const FORM_INPUT_PADDING = `${FormConstants.FORM_INPUT_PADDING}px`;

export const componentStyles = css.global`
  .layered-base-form {
    position: relative;

    .base-form {
      background: white;
    }

    .form-background-layer {
      position: absolute;
      height: 100%;
      width: 100%;
      background: ${Colors["brand-dark-blue"]};
      border-radius: 12px;
    }
  }

  .base-form {
    padding: 48px;
    background: ${Colors.white};
    ${getComponentShadow()}
    border-radius: 12px;
    margin: 24px 12px;
    width: 100%;
    text-align: center;
    .form-title-wrapper {
      margin-bottom: 32px;
      text-align: left;
    }
    .error-wrapper {
      margin: 0 6px 16px 6px;

      .form-error {
        width: 100%;
        display: flex;
        text-align: left;
        font-size: 0.8125rem;
        color: ${Colors["brand-red"]};
      }
    }

    .contact-form-row {
      display: flex;
      justify-content: space-evenly;
      align-items: flex-end;

      .form-input-wrapper {
        font-size: 0.875rem;
        flex-grow: 1;
        padding-left: ${FORM_INPUT_PADDING};
        padding-right: ${FORM_INPUT_PADDING};
      }
    }

    .contact-form-thanks {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: ${Colors.mud};
      padding: 40px 0 100px 0;

      .contact-form-thanks-title {
        font-weight: 500;
        font-size: 1.75rem;
        line-height: 37px;
        padding: 16px;
      }

      .contact-form-thanks-content {
        font-size: 0.875rem;
        line-height: 24px;
        display: flex;
        flex-direction: column;
        padding-top: 8px;
        
        .contact-form-thanks-footer {
          padding-top: 32px;

          .contact-form-footer-link {
            padding: 0 4px;
          }
        }
        .download-button-wrapper{
          padding-top: 20px;
        }
      }

      .submit-image {
        width: 253px;
      }
    }

    .react-tel-input {
      text-align: left;
      height: 40px;

      input {
        margin-left: 35px;
        width: calc(100% - 35px);
        height: 40px;
        padding-left: 16px;

        &.input-required {
          position: relative;
          border-color: ${Colors["brand-red"]} !important;
        }

        &:focus {
          border-color: ${Colors["basic-blue"]} !important;
        }
      }

      .react-tel-input .country-list {
        margin: 3px 0 10px -1px;
        text-align: left;
      }
    }

    .privacy-notice-wrapper {
      display: flex;
      justify-content: center;
      font-size: 0.8125rem;
      line-height: 16px;
      padding-bottom: 16px;

      .privacy-notice {
        max-width: 368px;
      }
    }
  }

  @media (max-width: ${MAX_DESKTOP_CONTENT_WIDTH_PX}) {
    .base-form {
      margin: 16px 0;
      padding: 24px 16px;

      .contact-form-row {
        flex-direction: column;
        align-items: baseline;

        .form-input-wrapper {
          width: 100%;
        }
      }
    }
  }
`;
