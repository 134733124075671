import css from "styled-jsx/css";

export const componentStyles = css`
  .grid-tag {
    display: flex;
    flex-direction: column;

    .tag-component-wrapper {
      display: flex;
    }
  }
`;
