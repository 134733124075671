import dynamic from "next/dynamic";

const RegularAssetComponent = require("./regular-asset-component").default;

import RegularAssetConfig from "./regular-asset-config";
import RegularAssetAdvancedConfig from "./regular-asset-advanced-config";

export default {
  RegularAssetComponent,
  RegularAssetConfig,
  RegularAssetAdvancedConfig
};
