import SelectableTagsGrid from "segments/desktop/core-components/selectable-tags-grid/selectable-tags-grid";
import classnames from "classnames";
import { componentStyles } from "./selectable-tags-grid-component.scss";
import DefaultProps from "./selectable-tags-grid-default-props";

const SelectableTagsGridComponent = (props) => {
  const { websiteAlignmentBranding } = props;
  return (
    <div className={classnames("selectable-tags-grid-component", { "website-alignment-branding": websiteAlignmentBranding })}>
      <SelectableTagsGrid {...props} />

      <style jsx>{componentStyles}</style>
    </div>
  );
};

SelectableTagsGridComponent.defaultProps = DefaultProps;

export default SelectableTagsGridComponent;
