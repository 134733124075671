import boardViewTabsDefaultProps from "segments/desktop/core-components/board-views-tab/default-props";

const { theme, ...boardViewTabsDefaultWithNoThemeProps } = boardViewTabsDefaultProps;

export default {
  ...boardViewTabsDefaultWithNoThemeProps,
  fullParagraph: {
    topic: {},
    title: "<b>Many ways to grasp</b><br>your work",
    body: "Transform the way you see and manage your work with Views—a simple yet powerful feature to help you move from idea to execution, faster."
  }
};
