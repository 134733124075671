import { PureComponent } from "react";
import { componentStyles } from "./animating-board-homepage-first-fold-mobile-component.scss";
import Button from "segments/desktop/core-components/button/button";
import MondayMarkupLanguageComponent from "client/components/system/monday-markup-language/monday-markup-language-component";
import { SIGNUP_BUTTON_TYPE } from "segments/desktop/core-components/button/button-consts";
import PictureComponent from "components/core/picture/picture-component";
import YoutubeVideoModalComponent from "components/core/youtube-video-modal/youtube-video-modal-component";
import { YOUTUBE_MODAL_OPEN, YOUTUBE_MODAL_CLOSE } from "/constants/bigbrain-event-types";

export default class AnimatingBoardHomepageFirstFoldMobileComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { openModal: false, windowWidth: 780, windowHeight: 928 };

    this.onPlayButtonClicked = this.onPlayButtonClicked.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    BigBrain("track", YOUTUBE_MODAL_OPEN, { kind: "monday_2_0_video" });
    this.setState({ windowHeight: window.innerHeight, windowWidth: window.innerWidth });
  }

  renderFirstTitle() {
    const { title } = this.props;

    return <MondayMarkupLanguageComponent tagName="h1" className="first-title" text={title} />;
  }

  renderSecondTitle() {
    const { body } = this.props;

    return <MondayMarkupLanguageComponent tagName="h3" className="second-title" text={body} />;
  }

  onPlayButtonClicked() {
    BigBrain("track", YOUTUBE_MODAL_OPEN, { kind: "monday_2_0_video" });
    this.setState({ windowHeight: window.innerHeight - 100, windowWidth: window.innerWidth - 100 }, () =>
      this.setState({ openModal: true })
    );
  }

  closeModal() {
    BigBrain("track", YOUTUBE_MODAL_CLOSE, { kind: "monday_2_0_video" });
    this.setState({ openModal: false });
  }
  render() {
    const { videoId } = this.props;
    const { openModal, windowWidth, windowHeight } = this.state;

    return (
      <div className="animating-board-homepage-first-fold-mobile-component">
        <div className="header-wrapper">
          {this.renderFirstTitle()}
          {this.renderSecondTitle()}

          <div className="first-fold-signup-button">
            <Button buttonType={SIGNUP_BUTTON_TYPE} buttonColor="dodger-blue" />
          </div>
        </div>

        <div className="table-picture-container">
          <YoutubeVideoModalComponent
            videoId={videoId}
            minHeight={546}
            isOpen={openModal}
            opts={{
              width: windowWidth,
              height: windowHeight,
              playerVars: {
                autoplay: true,
                rel: 0,
                showinfo: 0
              }
            }}
            onCloseModal={this.closeModal}
            withSignup={false}
          />
          <div className="video-part">
            <div className="poster-image-wrapper" onClick={this.onPlayButtonClicked}>
              <PictureComponent pictureClass="poster-image" src={"/static/img/tags/video_image.png"} />
              <div className="play-button-container">
                <div className="play-button" />
              </div>
            </div>
          </div>
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
