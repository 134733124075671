import { FIELD_TYPES } from "constants/template-field-type";
import ButtonTagsFieldsConfig from "segments/desktop/core-components/button-tag/fields-config";
import TitleConfig from "segments/desktop/core-components/title/fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Sales Phone (NOT DISPLAYED IN THE US)",
  fields: {
    ...TitleConfig,
    phoneLinks: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Phone numbers",
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          ...ButtonTagsFieldsConfig
        }
      }
    }
  }
};
