import { FIELD_TYPES } from "constants/template-field-type";
import SideBySideTextAndAssetFieldsConfig from "segments/desktop/core-components/side-by-side-text-and-asset/fields-config";
import { BACKGROUND_COLOR_OPTIONS } from "segments/segments-service";
import TopbarConfig from "segments/core-configs/topbar-config";

const {
  swapTextAndAssetPosition,
  background,
  ...selectedSideBySideTextAndAssetFieldsConfig
} = SideBySideTextAndAssetFieldsConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "Side By Side With Background",
  fields: {
    ...TopbarConfig,
    textAndAsset: {
      type: FIELD_TYPES.NESTED,
      label: "Text And Asset",
      fields: {
        background: {
          type: FIELD_TYPES.NESTED,
          label: "Background",
          fields: {
            color: {
              type: FIELD_TYPES.COLOR_PICKER,
              label: "Color",
              possibleColors: BACKGROUND_COLOR_OPTIONS
            }
          }
        },
        ...selectedSideBySideTextAndAssetFieldsConfig
      }
    }
  }
};
