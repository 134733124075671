import { PureComponent } from "react";
import { componentStyles } from "./vertical-timer-tab-desktop-component.scss";
import ProgressBarComponent from "../../progressbar/progressbar-component";
import classnames from "classnames";
import BadgeComponent from "components/core/badge/badge-component";
import Colors from "styles/colors";
import { generateOnKeyDownHandler } from "/client/services/accessibility/accessibility-service";
import Arrow from "static/svg/docs/arrow-up.svg";
import { TAB_CLICKED } from "/constants/bigbrain-event-types";
import MondayMarkupLanguageComponent
  from "client/components/system/monday-markup-language/monday-markup-language-component";

const VERTICAL_TAB_BUTTON_DIV_CLASS_NAME = "vertical-timer-tab-desktop-title-button";
export default class VerticalTimerTabComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.onKeyDown = generateOnKeyDownHandler(this.handleTitleClick);
  }

  handleTitleClick = () => {
    const { setSelectedTab, index, title } = this.props;
    BigBrain("track", TAB_CLICKED, {
      kind: title,
      info1: index
    });
    setSelectedTab(index);
  };

  onTabClicked = (e) => {
    this.blurTab(e);
    this.handleTitleClick();
  };

  blurTab = (e) => {
    e?.target?.closest(`.${VERTICAL_TAB_BUTTON_DIV_CLASS_NAME}`)?.blur();
  };

  shouldShowProgressbar = () => {
    const { isSelected, timerEnabled, alwaysShowProgressbar } = this.props;
    return alwaysShowProgressbar || (timerEnabled && isSelected);
  }

  render() {
    const { title, tabContentRenderer, content, badgeText, fontColor, selectedFontColor, titleFontWeight, fontSize, selectedFontSize } = this.props;
    const { isSelected, forceProgressbarDone, duration, showArrow, progressbarColor, arrowSize, timerEnabled } = this.props;

    const color = isSelected ? Colors[selectedFontColor] : Colors[fontColor];

    return (
      <div
        className={classnames("vertical-timer-tab-desktop-container", {
          "vertical-timer-tab-desktop-container-selected": isSelected
        })}
        style={{ height: fontSize }}
      >
        <div
          className={classnames(VERTICAL_TAB_BUTTON_DIV_CLASS_NAME, {
            "vertical-timer-tab-desktop-title-button-selected": isSelected
          })}
          onClick={this.onTabClicked}
          onKeyDown={this.onKeyDown}
          aria-selected={isSelected}
          tabIndex={0}
          role={"tab"}
          style={{ fontSize: isSelected ? selectedFontSize : fontSize }}
        >
          <span style={{ color, fontWeight: titleFontWeight }}>{title}</span>
          {badgeText && (
            <div className="vertical-timer-tab-title-badge-margin">
              <BadgeComponent badgeText={badgeText} />
            </div>
          )}
          <span className="vertical-timer-tab-arrow-wrapper">
            {showArrow && <Arrow className={classnames("vertical-timer-tab-arrow", { "selected": isSelected })} style={{ color, width: arrowSize }} />}
          </span>
        </div>
        <div className={classnames("vertical-timer-tab-desktop-progress-container")}>
          {this.shouldShowProgressbar() && (
            <ProgressBarComponent duration={duration} forceProgressbarDone={forceProgressbarDone || !timerEnabled} progressbarColor={progressbarColor} enabled={isSelected} />
          )}
        </div>
        <div
          className={classnames("vertical-timer-tab-desktop-content-container", {
            "vertical-timer-tab-desktop-content-container-selected": isSelected
          })}
        >
          <div className="vertical-timer-tab-desktop-content">{tabContentRenderer ? tabContentRenderer(content) : <MondayMarkupLanguageComponent text={content} />}</div>
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
