import { PureComponent } from "react";
import SideBySideTextAndAssetComponent from "segments/desktop/text-and-asset/layouts/side-by-side-text-and-asset/side-by-side-text-and-asset-component";
import { componentStyles } from "./two-thirds-text-and-asset-component.scss";

export default class TwoThirdsTextAndAssetComponent extends PureComponent {
  render() {
    return (
      <div className="two-thirds-text-and-asset-component">
        <SideBySideTextAndAssetComponent {...this.props} />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

TwoThirdsTextAndAssetComponent.defaultProps = {
  ...SideBySideTextAndAssetComponent.defaultProps,
  fullParagraph: {
    ...SideBySideTextAndAssetComponent.defaultProps.fullParagraph,
    topic: { text: "For the creative process", color: "brand-blue" },
    title: "Streamline your creative workflow",
    body: "Use customizable forms to request all the information needed. Then, easily share assets and receive feedback in real time."
  },
  asset: {
    ...SideBySideTextAndAssetComponent.defaultProps.asset,
    image: {
      ...SideBySideTextAndAssetComponent.defaultProps.asset.image,
      src: "/static/img/segment-layout-default-images/text-and-asset/timeline-view.png"
    }
  }
};
