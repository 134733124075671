import { positionTypes } from "segments/desktop/core-components/images-grid/images-grid-service";

export default {
  maxLimit: 4,
  isFullScreen: true,
  showDivider: true,
  tabsTitle: { title: "A work solution that works the way you do" },
  tabs: [
    {
      title: "For leadership",
      content: {
        topic: {},
        title: null,
        body: "Use Time Tracking to bill clients more accurately. Gain more confidence in estimating future costs."
      },
      asset: {
        image: {
          src: "/static/img/segment-layout-default-images/asset/timeline-view.png"
        }
      }
    },
    {
      title: "For managers",
      content: {
        topic: {},
        title: null,
        body: "Gain greater visibility and transparency over the work being done with robust dashboards and project overviews of where initiatives stand."
      }
    },
    {
      title: "For teams and individuals",
      content: {
        topic: {},
        title: null,
        body: "Use Time Tracking to bill clients more accurately. Gain more confidence in estimating future costs."
      },
      asset: {
        image: {
          src: "/static/img/segment-layout-default-images/asset/timeline-view.png"
        }
      }
    }
  ],
  timerEnabled: false,
  showButton: false,
  arrowSize: "12px",
  showArrow: true,
  assetPosition: positionTypes.CENTER_CENTER,
  title: {
    fontSize: "24px",
    selectedFontSize: "24px"
  }
};
