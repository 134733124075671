import CustomerQuotes, {
  CUSTOMER_KEY_OSCAR,
  CUSTOMER_KEY_THE_STUDENT_HOTEL,
  CUSTOMER_KEY_TELEFONICA,
  CUSTOMER_KEY_NISSAN,
  CUSTOMER_KEY_ZIPPO
} from "constants/customer-quotes";

export default {
  autoplay: {
    shouldAutoPlay: true,
    interval: "10000"
  },
  reviews: [
    {
      ...CustomerQuotes[CUSTOMER_KEY_OSCAR],
      customerImage: {
        src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/reviews/oscar_image.png",
        alt: "Oscar's customer image"
      },
      companyLogo: {
        src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/reviews/oscar_logo.svg",
        alt: "Oscar logo"
      }
    },
    {
      ...CustomerQuotes[CUSTOMER_KEY_THE_STUDENT_HOTEL],
      customerImage: {
        src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/reviews/the_student_hotel_image.png",
        alt: "The Student Hotel's customer image"
      },
      companyLogo: {
        src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/reviews/the_student_hotel_logo.svg",
        alt: "The Student Hotel logo"
      }
    },
    {
      ...CustomerQuotes[CUSTOMER_KEY_TELEFONICA],
      customerImage: {
        src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/reviews/telefonica_image.png",
        alt: "Telefonica's customer image"
      },
      companyLogo: {
        src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/reviews/telefonica_logo.svg",
        alt: "Telefonica logo"
      }
    },
    {
      ...CustomerQuotes[CUSTOMER_KEY_ZIPPO],
      customerImage: {
        src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/reviews/zippo_image.png",
        alt: "Zippo's customer image"
      },
      companyLogo: {
        src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/reviews/zippo.svg",
        alt: "Zippo logo"
      }
    },
    {
      ...CustomerQuotes[CUSTOMER_KEY_NISSAN],
      customerImage: {
        src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/reviews/nissan_image.png",
        alt: "Nissan's customer image"
      },
      companyLogo: {}
    }
  ]
};
