import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import {getComponentShadow, getComponentShadowWithHoverEffect} from "segments/desktop/constants";
import { MOBILE_QUERY_MAX_WIDTH } from "/constants/vp-sizes";


export const componentStyles = css.global`
  .connected-products-grid-component {
    max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
    margin: auto;
    .products-wrapper {
      display: flex;
      background-image: url("https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/products/background_grid.png");
      .work-management-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 25px;
        margin-left: 150px;
      }
      .plus-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .products {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-left: 50px;

        .grid-image-with-paragraph-component-wrapper {
          justify-content: center;
          width: 300px;
          margin: 16px;
          .card-grid-link {
            .grid-image-with-paragraph {
              padding: 26px;
              border-radius: 4px;
              box-shadow: unset;
              ${getComponentShadow()}
              &:hover {
                transform: scale(1.05);
                ${getComponentShadowWithHoverEffect()}
              }
              .paragraph-body-wrapper {
                .paragraph-body {
                  font-size: 0.875rem;
                  line-height: 24px;
                }
              }
              .grid-image-wrapper {
                .grid-image {
                  justify-content: center;
                }
              }
              .grid-paragraph-wrapper {
                .title-wrapper {
                  height: unset;
                  margin-bottom: unset;
                  .core-title {
                    display: none;
                  }
                }
                .paragraph-body-wrapper {
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    .connected-products-grid-component {
      .products-wrapper {
        align-items: center;
        flex-direction: column;
        background-image: unset;
        .work-management-icon {
          margin-left: unset;
          margin-right: unset;
          align-self: center;
          .picture-component{
            img{
              width: 116px;
            }
          }
        }
        .plus-icon {
          align-self: center;
          padding-top: 20px;
          padding-bottom: 20px;
          .picture-component{
            img{
              width: 24px;
            }
          }
          }
          .products {
            max-width: 360px;
            margin-left: 0px;
            justify-content: center;
            .grid-image-with-paragraph-component-wrapper {
              width: unset;
              margin: 8px;
              .card-grid-link {
                .grid-image-with-paragraph {
                padding: 24px;
                &:hover {
                  ${getComponentShadow()}
                  transform: unset;
                }
                .grid-image-wrapper {
                  .grid-image {
                    .picture-component{
                      display:flex;
                      width: 80px;
                      }
                    }
                  }
                }
                .grid-paragraph-wrapper {
                  display:none;
                }
              }
            }
          }
      }
    }
  }
`;
