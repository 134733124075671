import HubspotContactFormProps from "segments/desktop/core-components/hubspot-contact-form/default-props";
import { LOGO_COMPANY_FIELDS } from "segments/desktop/core-components/logos/logos-const";
import { MD, XS_M } from "constants/sizes";

export default {
  ...HubspotContactFormProps,
  backgroundLayers: 3,
  title: "Contact sales to see why monday is the new way of working",
  titleSize: MD,
  benefits: [
    {
      title: "Across <b>190+</b> countries",
      description: "Meet with a product consultant to see how monday.com can fit your exact business needs",
      icon: "/static/img/contact-sales/countries_icon.png"
    },
    {
      title: "<b>152k+</b> paying customers",
      description: "Explore our tailored pricing plans based on your goals and priorities",
      icon: "/static/img/contact-sales/customers_icon.png"
    },
    {
      title: "Serving <b>200+</b> industries",
      description: "Boost productivity from day one by building your team's ideal workflow",
      icon: "/static/img/contact-sales/industries_icon.png"
    }
  ],
  hideSupportStatement: false ,
  logosTitle: {
    title: "<monday-bold>Trusted by top companies and brands</monday-bold>",
    titleSize: XS_M,
    textAlign: "left"
  },
  logos: {
    numberOfCompanies: 5,
    companyField: LOGO_COMPANY_FIELDS.ENTERPRISE
  },
  paddingBottom: "32px"
};
