import { PureComponent } from "react";
import { componentStyles } from "./scene-picture-component.scss";
import PictureComponent from "components/core/picture/picture-component";

const FLOATING_ANIMATION_PX = 2;
export default class ScenePicture extends PureComponent {
  componentDidMount() {
    //this.animate();
  }

  randomNumber(min, max) {
    return Math.random() * (max - min) + min;
  }

  animate = () => {
    const { stopAnimation } = this.props;

    setTimeout(() => {
      window.requestAnimationFrame(() => {
        if (this.container) {
          if (stopAnimation) {
            this.container.style.transform = "translate(0, 0)";
          } else {
            this.container.style.transform = `translate(
                ${this.randomNumber(-FLOATING_ANIMATION_PX, FLOATING_ANIMATION_PX)}px,
                ${this.randomNumber(-FLOATING_ANIMATION_PX, FLOATING_ANIMATION_PX)}px)
            `;
          }
        }

        this.animate();
      });
    }, this.randomNumber(1000, 2000));
  };

  setContainer = (element) => (this.container = element);

  render() {
    const { pictureSrc } = this.props;

    return (
      <div className="scene-container" ref={this.setContainer}>
        <PictureComponent className="scene-picture" pictureClass="scene-picture" src={pictureSrc} />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
