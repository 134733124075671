import PictureComponent from "/components/core/picture/picture-component";
import Carousel from "segments/desktop/core-components/carousel/carousel";
import DefaultProps from "./default-props";
import { componentStyles } from "./carousel-asset-component.scss";

const CarouselAssetComponent = (props) => {
  const { slides } = props;
  const assets = (slides || []).map(slide => slide?.asset?.image);

  const itemRenderer = image => {
    return (<PictureComponent className="image-slide" src={image?.src} alt={image?.alt} />)
  }

  return (
    <div className="carousel-asset-component">
      <Carousel {...props} items={assets} itemRenderer={itemRenderer} />

      <style jsx>{componentStyles}</style>
    </div>
  );
}

CarouselAssetComponent.defaultProps = DefaultProps;

export default CarouselAssetComponent;
