import Colors from "/styles/colors";
import PictureComponent from "/components/core/picture/picture-component";
import classnames from "classnames";
import { componentStyles } from "./animated-tag-component.scss";

const AnimatedTag = (props) => {
  const { tagToAnimate, tagStartingPosition, onAnimationEndCallback } = props;
  const { tagImg, tagBackgroundColor, tagIndex } = tagToAnimate;

  return (
    <div
      className={classnames("animated-tag", "square")}
      style={{
        backgroundColor: Colors[tagBackgroundColor],
        ...tagStartingPosition
      }}
      onAnimationEnd={() => onAnimationEndCallback(tagIndex)}
    >
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default AnimatedTag;
