import { FIELD_TYPES } from "constants/template-field-type";
import TwoThirdsTextAndAssetConfig from "segments/desktop/text-and-asset/layouts/two-thirds-text-and-asset/two-thirds-text-and-asset-config";
import ReviewsCarouselConfig from "segments/desktop/core-components/reviews-carousel/fields-config.js";

const { asset, ...selectedTwoThirdsTextAndAssetConfig } = TwoThirdsTextAndAssetConfig.fields;

export default {
  type: FIELD_TYPES.NESTED,
  label: "Paragraph And Carousel",
  fields: {
    ...selectedTwoThirdsTextAndAssetConfig,
    carousel: {
      type: FIELD_TYPES.NESTED,
      label: "Carousel",
      fields: {
        ...ReviewsCarouselConfig
      }
    }
  }
};
