import css from "styled-jsx/css";

export const componentStyles = css.global`
  .two-thirds-text-and-asset-component {
    .side-by-side-text-and-asset-component {
      .full-paragraph-component-wrapper {
        width: 40%;
      }
      .image-wrapper {
        width: 60%;
      }
    }
  }
`;
