import css from "styled-jsx/css";
import colors from "styles/colors";
import Constants from "../constants";

const TAB_HEIGHT = `${Constants.TAB_HEIGHT}px`;

export const getComponentStyles = (color) => {
  return css`
    .vertical-tab {
      height: ${TAB_HEIGHT};
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 0.875rem;

      &.active {
        color: ${colors[color]};
        &.with-image {
          border-left: 5px solid;
          border-color: var(--border-color);
        }
      }

      :hover {
        color: ${colors[color]};
        outline: 0;
      }

      .vertical-tab-timeline {
        margin-right: 16px;
        height: 100%;
        width: 1px;
        border: 0;
        border-right: 5px;
        border-style: solid;
        transition: border-color 0.3s ease-in-out;
      }

      &:first-child {
        .vertical-tab-timeline {
          border-radius: 5px 5px 0px 0px;
        }
      }

      &:last-child {
        .vertical-tab-timeline {
          border-radius: 0px 0px 5px 5px;
        }
      }
    }
  `;
};
