import SideBySideTextAndAsset from "segments/desktop/core-components/side-by-side-text-and-asset/side-by-side-text-and-asset";
import Title from "segments/desktop/core-components/title/title";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import { componentStyles } from "./side-by-side-title-paragraph-component.scss";

const SideBySideTitleParagraphComponent = (props) => {
  const { titleProps, paragraphProps, titleAdvancedProps, paragraphAdvancedProps, ...restProps } = props;

  const titlePropsIncludingAdvanced = { ...titleProps, ...titleAdvancedProps };
  const paragraphPropsIncludingAdvanced = { ...paragraphProps, ...paragraphAdvancedProps, topic: "", title: "" };
  return (
    <div className="side-by-side-title-paragraph-component">
      <SideBySideTextAndAsset
        paragraphVerticalAlignment="top"
        replaceTextComponent={Title}
        replaceTextComponentProps={titlePropsIncludingAdvanced}
        replaceAssetComponent={FullParagraph}
        replaceAssetComponentProps={paragraphPropsIncludingAdvanced}
        {...restProps}
      />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default SideBySideTitleParagraphComponent;

SideBySideTitleParagraphComponent.defaultProps = {
  paddingTop: "48px",
  paddingBottom: "48px",
  fullParagraph: {},
  titleProps: Title.defaultProps,
  paragraphProps: {
    body: "Boost your team’s alignment, efficiency, and productivity by customizing any workflow to fit your needs."
  },
  alignToLogo: true
};
