import css from "styled-jsx/css";
import {
  MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH,
  TABLET_QUERY_MAX_WIDTH,
  MOBILE_QUERY_MAX_WIDTH_M
} from "constants/vp-sizes";

export const componentStyles = css`
  .full-background-text-and-asset {
    height: 100%;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;

    .full-background-text-and-asset-content {
      margin: 0 auto;
      max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
      width: 100%;
      padding: 0px 32px;
      &.top {
        align-self: flex-start;
      }
      &.bottom {
        align-self: flex-end;
      }
    }
  }
`;
