import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
import ImagesGridAdvnacedFieldsConfig from "segments/desktop/core-components/images-grid/advanced-fields-config";
import FullParagraphAdvancedFieldsConfig from "segments/desktop/core-components/full-paragraph/advanced-fields-config";

const { imagePosition, textAlign } = ImagesGridAdvnacedFieldsConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "",
  fields: {
    ...SharedAdvancedFieldsConfig,
    ...FullParagraphAdvancedFieldsConfig,
    imagePosition,
    textAlign
  }
};
