import { PureComponent } from "react";
import { componentStyles } from "./timer-vertical-tabs-desktop-component.scss";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import VerticalTimerTabComponent from "./vertical-timer-tab-desktop/vertical-timer-tab-desktop-component";
import AssetComponent from "components/core/asset/asset-component";
import Button from "segments/desktop/core-components/button/button";
import Colors from "styles/colors";
import { DEFAULT_TAB_TIMER } from "../../constants/timer";
import { getImagePositionStyles } from "segments/desktop/core-components/images-grid/images-grid-service";
import classnames from "classnames";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import Title from "segments/desktop/core-components/title/title";

export default class TimerVerticalTabsDesktopComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 0,
      forceProgressbarDone: false
    };

    this.tabsInterval = null;

    this.handleOnMouseLeave = this.handleOnMouseLeave.bind(this);
    this.handleOnMouseOver = this.handleOnMouseOver.bind(this);
    this.setSelectedTab = this.setSelectedTab.bind(this);
    this.startTabInterval = this.startTabInterval.bind(this);
    this.clearTabInterval = this.clearTabInterval.bind(this);
    this.startInterval = this.startInterval.bind(this);
    this.stopInterval = this.stopInterval.bind(this);
    this.renderTabs = this.renderTabs.bind(this);
  }

  componentDidMount() {
    this.startInterval();
  }

  handleOnMouseOver() {
    this.setState({ forceProgressbarDone: true });
    this.stopInterval();
  }

  handleOnMouseLeave() {
    this.setState({ forceProgressbarDone: false });
    this.startInterval();
  }

  setSelectedTab(newSelectedTab) {
    this.setState({ selectedTab: newSelectedTab, forceProgressbarDone: false });
    this.startInterval();
  }

  startTabInterval() {
    const { selectedTab } = this.state;
    const { tabs } = this.props;

    this.tabsInterval = setInterval(() => {
      const nextTab = (selectedTab + 1) % tabs.length;
      this.setSelectedTab(nextTab);
    }, parseInt(tabs[selectedTab]?.timer) || DEFAULT_TAB_TIMER);
  }

  clearTabInterval() {
    clearInterval(this.tabsInterval);
  }

  startInterval() {
    const { timerEnabled } = this.props;

    if (timerEnabled) {
      this.stopInterval();
      this.startTabInterval();
    }
  }

  stopInterval() {
    const { timerEnabled } = this.props;

    if (timerEnabled) {
      this.clearTabInterval();
    }
  }

  renderTabs() {
    const { selectedTab, forceProgressbarDone } = this.state;
    const {
      tabs,
      showDivider,
      timerEnabled,
      tabContentRenderer,
      showArrow,
      progressbarColor,
      alwaysShowProgressbar,
      arrowSize,
      title
    } = this.props;
    const { fontSize, selectedFontSize, fontColor, selectedFontColor, fontWeight } = title || {};

    return tabs.map((tab, index) => {
      const { title, content, badgeText, timer, overrideTabFontColor } = tab;
      return (
        <>
          <VerticalTimerTabComponent
            key={index}
            index={index}
            isSelected={index === selectedTab}
            title={title}
            content={content}
            badgeText={badgeText}
            duration={timer || DEFAULT_TAB_TIMER}
            forceProgressbarDone={forceProgressbarDone}
            setSelectedTab={this.setSelectedTab}
            showArrow={showArrow}
            timerEnabled={timerEnabled}
            fontSize={fontSize}
            selectedFontSize={selectedFontSize}
            fontColor={overrideTabFontColor ?? fontColor}
            selectedFontColor={overrideTabFontColor ?? selectedFontColor}
            progressbarColor={progressbarColor}
            alwaysShowProgressbar={alwaysShowProgressbar}
            arrowSize={arrowSize}
            tabContentRenderer={tabContentRenderer}
            titleFontWeight={fontWeight}
          />
          {showDivider && index < tabs.length - 1 && <div className="tabs-divider" />}
        </>
      );
    });
  }

  renderRightSide() {
    const { assetQuality, hideControls, useWindowMask, tabs, backgroundColor, rightSideRenderer, assetPosition } =
      this.props;
    const { selectedTab } = this.state;

    const shouldDisplayImage =
      (tabs[selectedTab]?.asset && tabs[selectedTab].asset?.image?.src) || tabs[selectedTab].asset?.video?.src;

    if (!shouldDisplayImage && rightSideRenderer) return rightSideRenderer(tabs[selectedTab]);

    const assetPositionStyle = getImagePositionStyles(assetPosition);
    const assetInnerStyle = { ...assetPositionStyle, backgroundColor: Colors[backgroundColor] };

    return (
      <div className="desktop-asset-container" role="tabpanel">
        <AssetComponent
          {...tabs[selectedTab]?.asset}
          assetQuality={assetQuality}
          hideControls={hideControls}
          useWindowMask={useWindowMask}
          convertGifToVideo={true}
          className="tab-asset-inner"
          imageClassName="tab-asset-image"
          style={assetInnerStyle}
        />
      </div>
    );
  }

  render() {
    const { showButton, buttonConfig, isFullScreen, tabsTitle, tabsContainerClassName } = this.props;
    const { selectedTab } = this.state;

    return (
      <div className={classnames("timer-vertical-tabs-desktop-component", { "full-screen": isFullScreen })}>
        <div className="vertical-timer-tabs-container" role="tablist">
          <div
            className={classnames("desktop-tabs-text-container", { [tabsContainerClassName]: tabsContainerClassName })}
            onMouseOver={this.handleOnMouseOver}
            onMouseLeave={this.handleOnMouseLeave}
          >
            {tabsTitle && (
              <div className="tabs-paragraph-wrapper">
                <Title {...tabsTitle} />
              </div>
            )}
            {this.renderTabs()}
          </div>
          <SwitchTransition mode="out-in">
            <CSSTransition key={selectedTab} classNames="fade" timeout={200}>
              {this.renderRightSide()}
            </CSSTransition>
          </SwitchTransition>
        </div>
        {showButton && (
          <div className="button-link">
            <Button {...buttonConfig} />
          </div>
        )}
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
