import dynamic from "next/dynamic";

const AsymmetricSideBySideTextAndAssetComponent = require("./asymmetric-side-by-side-text-and-asset-component").default;

import AsymmetricSideBySideTextAndAssetConfig from "./asymmetric-side-by-side-text-and-asset-config";
import AsymmetricSideBySideTextAndAssetAdvancedConfig from "./asymmetric-side-by-side-text-and-asset-advanced-config";

export default {
  AsymmetricSideBySideTextAndAssetComponent,
  AsymmetricSideBySideTextAndAssetConfig,
  AsymmetricSideBySideTextAndAssetAdvancedConfig
};
