import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, TABLET_QUERY_MAX_WIDTH } from "constants/vp-sizes";

const BORDER_RADIUS = 16;
const BORDER_RADIUS_STRING = `${BORDER_RADIUS}px`;

export const componentStyles = css.global`
  .side-by-side-with-paragraphs-paragraph-component {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    margin: auto;
    .side-by-side-text-and-asset {
      border-radius: ${BORDER_RADIUS_STRING};
      .side-by-side-content {
        border-radius: ${BORDER_RADIUS_STRING};
        padding: 50px;
        .full-paragraph-component-wrapper {
          height: 100%;
          .left-section-wrapper {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            height: 100%;
          }
        }
      }
    }
    .image-wrapper {
      display: flex;
      flex-direction: column;
      gap: 50px;
      .right-section-paragraph-wrapper {
        width: 100%;
      }
    }
    @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
      .side-by-side-text-and-asset {
        .side-by-side-content {
          height: fit-content;
        }
      }
    }
  }
`;
