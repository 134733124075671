import MultiTemplatesTemplatesComponent from "./multi-templates-templates-component";
import MultiTemplatesTemplatesConfig from "./multi-templates-templates-config";
import MultiTemplatesTemplatesAdvancedConfig from "./multi-templates-templates-advanced-config";
import MultiTemplatesTemplatesDynamicsFetch from "./multi-templates-templates-dynamics-fetch";

export default {
  MultiTemplatesTemplatesComponent,
  MultiTemplatesTemplatesConfig,
  MultiTemplatesTemplatesAdvancedConfig,
  MultiTemplatesTemplatesDynamicsFetch
};
