import dynamic from "next/dynamic";

const CardsGridComponent = require("./cards-grid-component").default;

import CardsGridConfig from "./cards-grid-config";
import CardsGridAdvancedConfig from "./cards-grid-advanced-config";

export default {
  CardsGridComponent,
  CardsGridConfig,
  CardsGridAdvancedConfig
};
