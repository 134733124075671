import css from "styled-jsx/css";

export const componentStyles = css.global`
  .progressbar {
    margin-top: 16px;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.05);

    .progressbar-fill {
      height: 100%;
      text-align: right;
      box-sizing: border-box;
    }

    .progressbar-start-animation {
      width: 0;
      animation: fill-progresssbar linear;
    }

    .progressbar-stop-animation {
      width: 100%;
      animation: none;
    }

    @keyframes fill-progresssbar {
      from {
        width: 0%;
      }
      to {
        width: 100%;
      }
    }
  }
`;
