import pulsesConfig from "./pulses/config";
import ownersConfig from "./owners/config";
import statusesConfig from "./statuses/config";
import filesConfig from "./files/config";
import timelinesConfig from "./timelines/config";
import teamsConfig from "./teams/config";
import effortsConfig from "./efforts/config";
import iconsConfig from "./icons/config";
import othersConfig from "./others/config";

export const configs = [
  ...pulsesConfig,
  ...ownersConfig,
  ...statusesConfig,
  ...filesConfig,
  ...timelinesConfig,
  ...teamsConfig,
  ...effortsConfig,
  ...iconsConfig,
  ...othersConfig
];
