import { FIELD_TYPES } from "constants/template-field-type";
import { textColorOptions } from "constants/color-picker-constants";

export default {
  body: { type: FIELD_TYPES.TEXT, label: "Text", textArea: true, rows: 5, translatable: true },
  bodyColor: {
    type: FIELD_TYPES.COLOR_PICKER,
    label: "Text color",
    possibleColors: textColorOptions
  }
};
