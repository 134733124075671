import { useMemo } from "react";
import SideBySideTextAndAsset from "segments/desktop/core-components/side-by-side-text-and-asset/side-by-side-text-and-asset";
import HubspotContactForm from "segments/desktop/core-components/hubspot-contact-form/hubspot-contact-form";
import HubspotContactFormProps from "segments/desktop/core-components/hubspot-contact-form/default-props";
import ParagraphAndLogos from "segments/desktop/core-components/paragraph-and-logos/paragraph-and-logos";
import { componentStyles } from "./hubspot-and-logos-form-component.scss";

const NINE_LOGOS_MAX_WIDTH = "590px";

const HubspotAndLogosFormComponent = (props) => {
  const { formId, source, overrideCampaignId, paragraphAndLogos, lastNameFirst, ...restProps } = props;
  const replaceTextComponentProps = {
    formId,
    source,
    overrideCampaignId,
    lastNameFirst
  };

  const replaceAssetComponentProps = useMemo(() => {
    if (paragraphAndLogos?.logos?.numberOfCompanies === "9") {
      paragraphAndLogos.logosMaxWidth = NINE_LOGOS_MAX_WIDTH;
    }

    return { ...paragraphAndLogos };
  }, [paragraphAndLogos]);

  return (
    <div className="hubspot-and-logos-form-component">
      <SideBySideTextAndAsset
        replaceTextComponent={HubspotContactForm}
        {...restProps}
        replaceTextComponentProps={replaceTextComponentProps}
        replaceAssetComponent={ParagraphAndLogos}
        replaceAssetComponentProps={replaceAssetComponentProps}
      />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default HubspotAndLogosFormComponent;

HubspotAndLogosFormComponent.defaultProps = {
  width: "100%",
  ...HubspotContactFormProps,
  paragraphAndLogos: ParagraphAndLogos.defaultProps
};
