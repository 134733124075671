import dynamic from "next/dynamic";

const SalesPhoneEnterpriseComponent = require("./sales-phone-enterprise-component").default;

import SalesPhoneEnterpriseConfig from "./sales-phone-enterprise-config";
import SalesPhoneEnterpriseAdvancedConfig from "./sales-phone-enterprise-advanced-config";

export default {
  SalesPhoneEnterpriseComponent,
  SalesPhoneEnterpriseConfig,
  SalesPhoneEnterpriseAdvancedConfig
};
