import css from "styled-jsx/css";

export const componentStyles = css.global`
  .title-only-paragraph {
    word-break: break-word;
    width: 100%;

    .button-wrapper {
      margin-top: 32px;
      display: flex;

      &.left {
        justify-content: flex-start;
      }

      &.center {
        justify-content: center;
      }

      &.right {
        justify-content: flex-end;
      }
    }
  }
`;
