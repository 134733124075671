import css from "styled-jsx/css";
import { MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";
import Colors from "styles/colors";
import {getComponentShadow} from "segments/desktop/constants";

const CONTENT_MAX_WIDTH = 710;

export default css.global`
  .testimonial-card-component {
    position: relative;
    height: 100%;
    padding: 56px 48px;
    display: flex;
    justify-content: center;
    align-items: center;

    .company-image-wrapper {
      position: absolute;

      width: 180px;
      height: 68px;
      overflow: hidden;

      left: 50%;
      top: 0;
      transform: translateX(-50%) translateY(-50%);

      border-radius: 8px;
      border: ${`1px solid ${Colors.riverstone}`};
      ${getComponentShadow()}

      background-color: ${Colors.white};

      .picture-component {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
        }
      }
    }

    .testimonial-card-content-wrapper {
      max-width: ${`${CONTENT_MAX_WIDTH}px`};
      text-align: center;

      .quote-wrapper {
        margin-bottom: 24px;

        font-size: 2.75rem;
        font-weight: 700;
        line-height: 60px;

        &.xs {
          font-size: 1.375rem;
          line-height: 32px;
          font-weight: 400;
        }

        &.sm {
          font-size: 1.75rem;
          line-height: 40px;
          font-weight: 400;
        }

        &.md {
          font-size: 2.25rem;
          line-height: 48px;
          font-weight: 400;
        }

        &.lg {
          font-size: 2.75rem;
          line-height: 60px;
          font-weight: 700;
        }

        &.lgr {
          font-size: 3.375rem;
          line-height: 68px;
          font-weight: 700;
        }

        &.xl {
          font-size: 4rem;
          line-height: 72px;
          font-weight: 700;
        }
      }
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    .testimonial-card-component {
      .testimonial-card-content-wrapper {
        .quote-wrapper {
          font-size: 1.75rem !important; // overide inline styles
          line-height: 50px;

          &.md,
          &.lg,
          &.xl {
            font-size: 1.75rem !important;
            line-height: 40px;
            font-weight: 700;
          }
        }
      }
    }
  }
`;
