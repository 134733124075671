export const MIN_SCALE_TIMEOUT = 0;
export const MAX_SCALE_TIMEOUT = 10000;
export const MIN_SCALE_VALUE = 0.9;
export const MAX_SCALE_VALUE = 1.1;

export const MIN_TRANSLATE_Y_TIMEOUT = 1500;
export const MAX_TRANSLATE_Y_TIMEOUT = 3000;
export const MIN_TRANSLATE_Y_VALUE = -5;
export const MAX_TRANSLATE_Y_VALUE = 5;

export const ITEM_TOTAL_HEIGHT = 120;
export const ITEM_WIDTH = 107;
export const ITEM_MARGIN = 32;
export const ITEM_TOTAL_WIDTH = ITEM_WIDTH + ITEM_MARGIN;

export const SLIDER_MARGIN = 24;

export const NUMBER_OF_ROWS = 2;
export const MAX_INTEGRATIONS_FLOATING_WIDTH = 3000;
