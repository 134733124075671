import css from "styled-jsx/css";

export const componentStyles = css.global`
  .mobile-timer-tabs-component {
    display: flex;
    flex-direction: column;
    padding: 32px 40px;

    .mobile-button-link {
      padding: 16px 0px;
    }
  }
`;
