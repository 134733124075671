import { useContext } from "react";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import { GenericConfigContext } from "/contexts/generic-config-context";
import { componentStyles } from "./parallex-paragraphs.scss";

const ParallexParagraphs = (props) => {
  const { paragraphs } = props;
  const { translate } = useContext(GenericConfigContext);

  return (
    <div className="parallex-paragraphs">
      {paragraphs.map((paragraph, index) => (
        <div key={`paragraph_${index}`} className="paragraph">
          <div className="headline">
            <FullParagraph
              title={paragraph.title}
              body={paragraph.description}
              topic={{ text: "" }}
              buttonConfig={paragraph.buttonConfig}
            />
          </div>
          <div className="social-proof">
            <img className="avatar" src={paragraph.avatarSrc} alt={paragraph.avatarAlt} />
            <div className="testimonial">
              <span>“</span>
              <div className="content">
                <span className="main">{paragraph.testimonial}”</span>
                <div className="person">
                  <span className="text">{paragraph.name}</span>
                  <span className="divider">|</span>
                  <span className="text">{paragraph.jobTitle}</span>
                </div>
                <div className="company">{paragraph.companyName}</div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default ParallexParagraphs;
