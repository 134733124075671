import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";

export const componentStyles = css`
  .images-carousel-with-tags-grid-asset-component {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    margin: auto;
    padding: 0 32px;
    display: flex;

    .grid-side {
      flex: 1;
    }
  }
`;
