import FullParagraphDefaultProps from "segments/desktop/core-components/full-paragraph/default-props";
import LogosDefaultProps from "segments/desktop/core-components/logos/default-props";

const fullParagraphDefaultProps = {
  ...FullParagraphDefaultProps,
  topic: {},
  title: "Trusted by <monday-paying-companies />+ customers worldwide",
  textAlign: "center",
  body: ""
};
export default {
  marginBetweenParagraphAndLogos: "24px",
  logosMaxWidth: null,
  fullParagraph: fullParagraphDefaultProps,
  logos: LogosDefaultProps
};
