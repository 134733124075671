const SelectableTagsGridComponent = require("./selectable-tags-grid-component").default;

import SelectableTagsGridConfig from "./selectable-tags-grid-config";
import SelectableTagsGridAdvancedConfig from "./selectable-tags-grid-advanced-config";

export default {
  SelectableTagsGridComponent,
  SelectableTagsGridConfig,
  SelectableTagsGridAdvancedConfig
};
