import dynamic from "next/dynamic";

const OnlyImageGridComponent = require("./only-image-grid-component").default;

import OnlyImageGridConfig from "./only-image-grid-config";
import OnlyImageGridAdvancedConfig from "./only-image-grid-advanced-config";

export default {
  OnlyImageGridComponent,
  OnlyImageGridConfig,
  OnlyImageGridAdvancedConfig
};
