import { PureComponent } from "react";
import { componentStyles } from "./logos-and-header-security-component.scss";
import SecurityLogos from "segments/desktop/core-components/security-logos/security-logos";
import Title from "segments/desktop/core-components/title/title";
import { LG } from "constants/sizes";

export default class LogosAndHeaderSecurityComponent extends PureComponent {
  render() {
    const { title, titleSize } = this.props;

    return (
      <div className="logos-and-header-security-component">
        <Title title={title} titleSize={titleSize} />

        <div className="security-logos-wrapper">
          <SecurityLogos />
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

LogosAndHeaderSecurityComponent.defaultProps = {
  title: "Compliance certifications and regulations",
  titleSize: LG,
  paddingTop: "64px",
  paddingBottom: "64px"
};
