import { PureComponent } from "react";
import { componentStyles } from "./vertical-timer-tab-mobile-component.scss";
import classnames from "classnames";
import Colors from "styles/colors";
import Arrow from "static/svg/docs/arrow-up.svg";
import AssetComponent from "components/core/asset/asset-component";
import BadgeComponent from "components/core/badge/badge-component";
import ProgressBarComponent from "../../progressbar/progressbar-component";
import MondayMarkupLanguageComponent
    from "client/components/system/monday-markup-language/monday-markup-language-component";

export default class VerticalTimerTabMobileComponent extends PureComponent {
  handleTitleClick = () => {
    const { index, setSelectedTab } = this.props;
    setSelectedTab(index);
  };

  render() {
    const { title, tabContentRenderer, content, asset, assetQuality, badgeText, fontColor, selectedFontColor, titleFontWeight, fontSize, selectedFontSize } = this.props;
    const { isSelected, alwaysShowProgressbar, showArrow, progressbarColor, arrowSize } = this.props;

    const color = isSelected ? Colors[selectedFontColor] : Colors[fontColor];
    
    return (
      <div className="vertical-timer-tab-mobile-component">
        <div
          className={classnames("vertical-timer-tab-mobile-title", {
            "vertical-timer-tab-mobile-title-selected": isSelected
          })}
          onClick={this.handleTitleClick}
          style={{ fontSize: isSelected ? selectedFontSize : fontSize }}
        >
          <span style={{ color, fontWeight: titleFontWeight }}>{title}</span>

          {badgeText && (
            <div className="vertical-timer-tab-mobile-title-badge-margin">
              <BadgeComponent badgeText={badgeText} />
            </div>
          )}

          <span className="vertical-timer-tab-arrow-wrapper">
            {showArrow && <Arrow className={classnames("vertical-timer-tab-arrow", { "selected": isSelected })} style={{ color, width: arrowSize }} />}
          </span>
        </div>

        <div className="vertical-timer-tab-mobile-progress-container">
          {alwaysShowProgressbar && (
            <ProgressBarComponent forceProgressbarDone progressbarColor={progressbarColor} enabled={isSelected} />
          )}
        </div>

        <div
          className={classnames("vertical-timer-tab-mobile-content-container", {
            "vertical-timer-tab-mobile-content-container-selected": isSelected
          })}
        >
          {isSelected ? (
            <>
              <div className="vertical-timer-tab-mobile-content">
                {tabContentRenderer ? tabContentRenderer(content) : <MondayMarkupLanguageComponent text={content} />}
              </div>
              <div className="vertical-timer-tab-mobile-asset">
                <AssetComponent {...asset} assetQuality={assetQuality} convertGifToVideo={true} />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
