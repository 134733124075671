import { useState } from "react";
import MobileTabNavButton from "segments/desktop/core-components/mobile-tabs/mobile-tab-nav-button";
import MobileTabs from "segments/desktop/core-components/mobile-tabs/mobile-tabs";
import AssetComponent from "components/core/asset/asset-component";
import { XS, XS_L } from "constants/sizes";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import { componentStyles } from "./use-cases-tabs-mobile-component.scss";
import DefaultProps from "../default-props";

const UseCasesTabsMobileComponent = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [wasComponentEverVisible, setWasComponentEverVisible] = useState(false);
  const { buttonsColor } = props;

  const navigationItemRenderer = (tab, index) => {
    return <MobileTabNavButton label={tab.tabLabel} selected={selectedTab === index} theme={buttonsColor} />;
  };

  const carouselItemRenderer = (tab) => {
    const { tabContent } = tab;
    const { mobileAsset, images, mobileTextAlign } = tabContent;

    return (
      <div className="asset-and-paragraphs-wrapper">
        <AssetComponent {...mobileAsset} />
        {images?.map((paragraph) => (
          <FullParagraph
            {...paragraph.fullParagraph}
            titleSize={XS_L}
            textSize={XS}
            mobileTextAlign={mobileTextAlign}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="use-cases-tabs-mobile-component">
      <MobileTabs
        {...props}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        wasComponentEverVisible={wasComponentEverVisible}
        setWasComponentEverVisible={setWasComponentEverVisible}
        navigationItemRenderer={navigationItemRenderer}
        carouselItemRenderer={carouselItemRenderer}
      />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

UseCasesTabsMobileComponent.defaultProps = DefaultProps;

export default UseCasesTabsMobileComponent;
