import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, MOBILE_QUERY_MAX_WIDTH_M } from "constants/vp-sizes";

export const componentStyles = css`
  .image-with-paragraph-grid-component {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    margin: auto;
    padding: 0px;
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH_M}) {
    .image-with-paragraph-grid-component {
      padding: 8px;
    }
  }
`;
