import { FIELD_TYPES } from "constants/template-field-type";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Bullets",
  fields: {
    bulletTextSize: {
      type: FIELD_TYPES.COMBO,
      label: "Bullet text size",
      options: [
        { value: "0.8125rem", text: "14px (formerly 16px)" },
        { value: "0.875rem", text: "16px (formerly 18px)" }
      ]
    },
    bulletsDirection: {
      type: FIELD_TYPES.COMBO,
      label: "Bullets direction",
      options: [
        { value: "row", text: "Row" },
        { value: "column", text: "Column" }
      ]
    },
    bullets: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Bullets",
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          bulletText: { type: FIELD_TYPES.TEXT, label: "Text", translatable: true },
          href: { type: FIELD_TYPES.TEXT, label: "Href" },
          openInNewTab: {
            type: "boolean",
            label: "Should Open In New Tab"
          }
        }
      }
    }
  }
};
