import withResponsiveHOC from "components/enhancements/responsive-hoc/responsive-hoc-component";
import DefaultProps from "./default-props";
import ScrollParallaxHomepageFirstFoldDesktopComponent from "./scroll-parallax-homepage-first-fold-desktop/scroll-parallax-homepage-first-fold-desktop-component";
import ScrollParallaxHomepageFirstFoldMobileComponent from "./scroll-parallax-homepage-first-fold-mobile-component/scroll-parallax-homepage-first-fold-mobile-component";

const ScrollParallaxHomepageFirstFoldComponent = withResponsiveHOC(
  ScrollParallaxHomepageFirstFoldDesktopComponent,
  ScrollParallaxHomepageFirstFoldMobileComponent
);
ScrollParallaxHomepageFirstFoldComponent.defaultProps = DefaultProps;

export default ScrollParallaxHomepageFirstFoldComponent;
