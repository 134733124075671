import { FIELD_TYPES } from "constants/template-field-type";
import { MD, LG, LGR, XL } from "constants/sizes";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Rotating Title",
  fields: {
    title: { type: FIELD_TYPES.TEXT, label: "Title", textArea: true, translatable: true },
    titleSize: {
      type: FIELD_TYPES.COMBO,
      label: "Title Size",
      options: [
        { value: MD, text: "40px" },
        { value: LG, text: "50px" },
        { value: LGR, text: "60px" },
        { value: XL, text: "72px" }
      ]
    },
    rotatingWords: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Rotating words",
      maxLimit: 7,
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          text: { type: FIELD_TYPES.TEXT, label: "Text", translatable: true },
          color: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Text Color"
          }
        }
      }
    },
    infiniteRotation: {
      type: FIELD_TYPES.BOOLEAN,
      label: "infinite rotationn"
    }
  }
};
