import TwoThirdsTextAndAssetComponent from "segments/desktop/text-and-asset/layouts/two-thirds-text-and-asset/two-thirds-text-and-asset-component";
import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import { WORKOS_IRIS_THEME_ID } from "segments/segments-service";
import {
  APPS_MARKETPLACE_LINK,
  AUTOMATIONS_PAGE_LINK, DASHBOARDS_PAGE_LINK,
  INTEGRATIONS_PAGE_LINK,
  WORKDOCS_PAGE_LINK
} from "constants/links";

const {image, ...selectedAssetComponentDefaultProps} = TwoThirdsTextAndAssetComponent.defaultProps.asset

export default {
  buildingBlocksTabsTheme: WORKOS_IRIS_THEME_ID,
  tabs: [
    {
      tabLabel: "Boards",
      tabIcon: `https://dapulse-res.cloudinary.com/image/upload/Generator_featured images/Home Page - 2022 Rebrand/tabs/board_icon.svg`,
      textAndAsset: {
        ...TwoThirdsTextAndAssetComponent.defaultProps,
        swapTextAndAssetPosition: true,
        fullParagraph: {
          ...TwoThirdsTextAndAssetComponent.defaultProps.fullParagraph,
          topic: {},
          title: "Boards",
          titleColor: WORK_OS_IRIS_COLOR_NAME,
          body:
            "Everything starts with a visual board — the core of monday.com Work OS. Tailor it your way and manage anything from projects to departments."
        },
        asset: {
          ...selectedAssetComponentDefaultProps,
          video: {
            loop: true,
            src: {
              formats: ["/Generator_featured images/Home Page - 2022 Rebrand/tabs/final-videos-tabs/7_status.mp4"]
            }
          },
          position: "center",
          useWindowMask: true
        }
      }
    },
    {
      tabLabel: "Views",
      tabIcon: `https://dapulse-res.cloudinary.com/image/upload/Generator_featured images/Home Page - 2022 Rebrand/tabs/views_icon.svg`,
      textAndAsset: {
        ...TwoThirdsTextAndAssetComponent.defaultProps,
        swapTextAndAssetPosition: true,
        fullParagraph: {
          ...TwoThirdsTextAndAssetComponent.defaultProps.fullParagraph,
          topic: {},
          title: "Views",
          titleColor: WORK_OS_IRIS_COLOR_NAME,
          body:
            "Visualize and plan your work more efficiently with multiple views: Kanban board, calendar, timeline, Gantt chart, and more."
        },
        asset: {
          ...selectedAssetComponentDefaultProps,
          video: {
            loop: true,
            src: {
              formats: ["/Generator_featured images/Home Page - 2022 Rebrand/tabs/final-videos-tabs/1_Views.mp4"]
            }
          },
          position: "center",
          useWindowMask: true
        }
      }
    },
    {
      tabLabel: "Dashboards",
      tabIcon: `https://dapulse-res.cloudinary.com/image/upload/Generator_featured images/Home Page - 2022 Rebrand/tabs/dashboard_icon.svg`,
      textAndAsset: {
        ...TwoThirdsTextAndAssetComponent.defaultProps,
        swapTextAndAssetPosition: true,
        fullParagraph: {
          ...TwoThirdsTextAndAssetComponent.defaultProps.fullParagraph,
          topic: {},
          title: "Dashboards",
          titleColor: WORK_OS_IRIS_COLOR_NAME,
          buttonConfig: {
            buttonType: "Secondary",
            buttonText: "Learn more",
            url: DASHBOARDS_PAGE_LINK
          },
          body:
            "Get the insights you need to make decisions with confidence. Keep track of progress, timelines, and budgets with custom dashboards."
        },
        asset: {
          ...selectedAssetComponentDefaultProps,
          video: {
            loop: true,
            src: {
              formats: ["/Generator_featured images/Home Page - 2022 Rebrand/tabs/final-videos-tabs/2_Dashboards.mp4"]
            }
          },
          position: "center",
          useWindowMask: true
        }
      }
    },
    {
      tabLabel: "Integrations",
      tabIcon: `https://dapulse-res.cloudinary.com/image/upload/Generator_featured images/Home Page - 2022 Rebrand/tabs/integrations_icon.svg`,
      textAndAsset: {
        ...TwoThirdsTextAndAssetComponent.defaultProps,
        swapTextAndAssetPosition: true,
        fullParagraph: {
          ...TwoThirdsTextAndAssetComponent.defaultProps.fullParagraph,
          topic: {},
          title: "Integrations",
          titleColor: WORK_OS_IRIS_COLOR_NAME,
          buttonConfig: {
            buttonType: "Secondary",
            buttonText: "Learn more",
            url: INTEGRATIONS_PAGE_LINK
          },
          body:
            "Connect monday.com with all your favorite tools and get more work done. Integrate Slack, Dropbox, Adobe Creative Cloud, and more."
        },
        asset: {
          ...selectedAssetComponentDefaultProps,
          video: {
            loop: true,
            src: {
              formats: ["/Generator_featured images/Home Page - 2022 Rebrand/tabs/final-videos-tabs/4_Integrations.mp4"]
            }
          },
          position: "center",
          useWindowMask: true
        }
      }
    },
    {
      tabLabel: "Automations",
      tabIcon: `https://dapulse-res.cloudinary.com/image/upload/Generator_featured images/Home Page - 2022 Rebrand/tabs/automations_icon.svg`,
      textAndAsset: {
        ...TwoThirdsTextAndAssetComponent.defaultProps,
        swapTextAndAssetPosition: true,
        fullParagraph: {
          ...TwoThirdsTextAndAssetComponent.defaultProps.fullParagraph,
          topic: {},
          title: "Automations",
          titleColor: WORK_OS_IRIS_COLOR_NAME,
          buttonConfig: {
            buttonType: "Secondary",
            buttonText: "Learn more",
            url: AUTOMATIONS_PAGE_LINK
          },
          body:
            "Streamline processes to focus on the work that matters. Choose from a variety of automation recipes or create your own in minutes."
        },
        asset: {
          ...selectedAssetComponentDefaultProps,
          video: {
            loop: true,
            src: {
              formats: ["/Generator_featured images/Home Page - 2022 Rebrand/tabs/final-videos-tabs/3_Automations.mp4"]
            }
          },
          position: "center",
          useWindowMask: true
        }
      }
    },
    {
      tabLabel: "Apps",
      tabIcon: `https://dapulse-res.cloudinary.com/image/upload/Generator_featured images/Home Page - 2022 Rebrand/tabs/apps_icon.svg`,
      textAndAsset: {
        ...TwoThirdsTextAndAssetComponent.defaultProps,
        swapTextAndAssetPosition: true,
        fullParagraph: {
          ...TwoThirdsTextAndAssetComponent.defaultProps.fullParagraph,
          topic: {},
          title: "Apps",
          titleColor: WORK_OS_IRIS_COLOR_NAME,
          buttonConfig: {
            buttonType: "Secondary",
            buttonText: "Learn more",
            url: APPS_MARKETPLACE_LINK
          },
          body:
            "Expand the capabilities of your Work OS with monday apps. Enhance your workflows with custom views, widgets, integrations, and more. "
        },
        asset: {
          ...selectedAssetComponentDefaultProps,
          video: {
            loop: true,
            src: {
              formats: ["/Generator_featured images/Home Page - 2022 Rebrand/tabs/final-videos-tabs/5_Apps.mp4"]
            }
          },
          position: "center",
          useWindowMask: true
        }
      }
    },
    {
      tabLabel: "Docs",
      tabIcon: `https://dapulse-res.cloudinary.com/image/upload/Generator_featured images/Home Page - 2022 Rebrand/tabs/docs_icon.svg`,
      textAndAsset: {
        ...TwoThirdsTextAndAssetComponent.defaultProps,
        swapTextAndAssetPosition: true,
        fullParagraph: {
          ...TwoThirdsTextAndAssetComponent.defaultProps.fullParagraph,
          topic: {},
          title: "Docs",
          titleColor: WORK_OS_IRIS_COLOR_NAME,
          buttonConfig: {
            buttonType: "Secondary",
            buttonText: "Learn more",
            url: WORKDOCS_PAGE_LINK
          },
          body:
            "Transform text into action items, in just a few clicks. Connect, collaborate, and execute ideas and workflows in real-time from any doc."
        },
        asset: {
          ...selectedAssetComponentDefaultProps,
          video: {
            loop: true,
            src: {
              formats: ["/Generator_featured images/Home Page - 2022 Rebrand/tabs/final-videos-tabs/6_Docs.mp4"]
            }
          },
          position: "center",
          useWindowMask: true
        }
      }
    }
  ]
};
