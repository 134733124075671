import {
  CRM_GREEN_THEME_ID,
  DEV_GREEN_THEME_ID,
  MARKETING_RED_THEME_ID,
  PROJECTS_ORANGE_THEME_ID,
  WORKOS_IRIS_THEME_ID
} from "segments/segments-service";
import { SIGNUP_COMBO_BUTTONS_TYPE } from "segments/desktop/core-components/button/button-consts";
import { BRAND_BLACK_TEXT_COLOR } from "styles/color-consts";
import {
  CRM_PRODUCT_TYPE,
  MARKETER_PRODUCT_TYPE,
  PROJECTS_PRODUCT_TYPE,
  DEV_PRODUCT_TYPE,
  WORK_MANAGEMENT_PRODUCT_TYPE,
  CRM_PRODUCT_ID,
  MARKETER_PRODUCT_ID,
  PROJECTS_PRODUCT_ID,
  DEV_PRODUCT_ID,
  WORK_MANAGEMENT_PRODUCT_ID
} from "constants/products";

import { MARKETING, CRM, SOFTWARE } from "constants/clusters";

import {
  WORK_MANAGEMENT_MINI_SITE_LINK,
  MARKETING_MANAGEMENT_MINI_SITE_LINK,
  CRM_MINI_SITE_LINK,
  PROJECT_MANAGEMENT_MINI_SITE_LINK,
  SOFTWARE_MINI_SITE_LINK
} from "constants/links";

export const productCardsImages = {
  [WORK_MANAGEMENT_PRODUCT_TYPE]: {
    image: {
      src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/wm_main_logo.png",
      alt: "monday work-management logo"
    },
    mobileImage: {
      src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/Mobile/Work_management1.png",
      alt: "monday work-management logo"
    },
    mediumScreenImage: {
      src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/medium_size/wm_text.png",
      alt: "work management title"
    }
  },
  [MARKETER_PRODUCT_TYPE]: {
    image: {
      src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/marketer_main_logo.png",
      alt: "monday marketer logo"
    },
    mobileImage: {
      src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/Mobile/Marketer1.png",
      alt: "monday marketer logo"
    },
    mediumScreenImage: {
      src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/medium_size/marketer_text.png",
      alt: "monday marketer title"
    }
  },
  [CRM_PRODUCT_TYPE]: {
    image: {
      src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/crm_main_logo.png",
      alt: "monday sales CRM logo"
    },
    mobileImage: {
      src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/Mobile/Sales_CRM1.png",
      alt: "monday sales CRM logo"
    },
    mediumScreenImage: {
      src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/medium_size/crm_text.png",
      alt: "monday sales title"
    }
  },
  [PROJECTS_PRODUCT_TYPE]: {
    image: {
      src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/projects_main_logo.png",
      alt: "monday projects logo"
    },
    mobileImage: {
      src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/Mobile/Project1.png",
      alt: "monday projects logo"
    },
    mediumScreenImage: {
      src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/medium_size/projects_text.png",
      alt: "monday projects title"
    }
  },
  [DEV_PRODUCT_TYPE]: {
    image: {
      src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/dev_main_logo.png",
      alt: "monday dev logo"
    },
    mobileImage: {
      src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/Mobile/Dev1.png",
      alt: "monday dev logo"
    },
    mediumScreenImage: {
      src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/medium_size/dev_text.png",
      alt: "monday dev title"
    }
  }
};

export const productIndexToProductType = {
  0: WORK_MANAGEMENT_PRODUCT_TYPE,
  1: MARKETER_PRODUCT_TYPE,
  2: CRM_PRODUCT_TYPE,
  3: PROJECTS_PRODUCT_TYPE,
  4: DEV_PRODUCT_TYPE
};

export default {
  cards: [
    {
      showOpen: true,
      isHighlighted: false,
      productType: WORK_MANAGEMENT_PRODUCT_TYPE,
      theme: WORKOS_IRIS_THEME_ID,
      header: {
        subtitleText: "For professionals and teams managing tasks & workflows"
      },
      body: {
        bodyText: "Manage tasks and workflows to fuel team collaboration and productivity at scale."
      },
      showDivider: true,
      useCases: {
        text: "Top use cases:",
        list: [
          { text: "Task management" },
          { text: "Project management" },
          { text: "Automated workflows" },
          { text: "Build your own" }
        ]
      },
      buttonConfig: {
        buttonType: SIGNUP_COMBO_BUTTONS_TYPE,
        buttonText: "Learn more",
        isOpenLinkInNewTab: true,
        color: BRAND_BLACK_TEXT_COLOR,
        signupButtonConfig: {
          buttonColor: BRAND_BLACK_TEXT_COLOR,
          signupProductId: WORK_MANAGEMENT_PRODUCT_ID
        },
        secondaryButtonConfig: {
          buttonText: "Learn more",
          url: WORK_MANAGEMENT_MINI_SITE_LINK,
          color: BRAND_BLACK_TEXT_COLOR,
          isOpenLinkInNewTab: true
        }
      }
    },
    {
      showOpen: false,
      isHighlighted: false,
      productType: MARKETER_PRODUCT_TYPE,
      theme: MARKETING_RED_THEME_ID,
      header: {
        subtitleText: "For marketing and creative professionals and teams"
      },
      body: {
        bodyText: "Manage and collaborate on marketing and creative projects to launch campaigns."
      },
      showDivider: true,
      useCases: {
        text: "Top use cases:",
        list: [
          { text: "Content calendar" },
          { text: "Campaign tracking" },
          { text: "Digital asset management" },
          { text: "Marketing projects" }
        ]
      },
      buttonConfig: {
        buttonType: SIGNUP_COMBO_BUTTONS_TYPE,
        buttonText: "Learn more",
        isOpenLinkInNewTab: true,
        color: BRAND_BLACK_TEXT_COLOR,
        url: "https://monday.com/marketing",
        signupButtonConfig: {
          buttonColor: BRAND_BLACK_TEXT_COLOR,
          signupProductId: MARKETER_PRODUCT_ID,
          signupClusterId: MARKETING
        },
        secondaryButtonConfig: {
          buttonText: "Learn more",
          url: MARKETING_MANAGEMENT_MINI_SITE_LINK,
          color: BRAND_BLACK_TEXT_COLOR,
          isOpenLinkInNewTab: true
        }
      }
    },
    {
      showOpen: false,
      isHighlighted: false,
      productType: CRM_PRODUCT_TYPE,
      theme: CRM_GREEN_THEME_ID,
      header: {
        subtitleText: "For sales professionals and customer-facing teams"
      },
      body: {
        bodyText: "Track and manage all aspects of your sales cycle, customer data, and more in one place."
      },
      showDivider: true,
      useCases: {
        text: "Top use cases:",
        list: [
          { text: "Contact management" },
          { text: "Sales pipeline" },
          { text: "Post-sales management" },
          { text: "Lead management" }
        ]
      },
      buttonConfig: {
        buttonType: SIGNUP_COMBO_BUTTONS_TYPE,
        buttonText: "Learn more",
        isOpenLinkInNewTab: true,
        color: BRAND_BLACK_TEXT_COLOR,
        url: "https://monday.com/crm",
        signupButtonConfig: {
          buttonColor: BRAND_BLACK_TEXT_COLOR,
          signupProductId: CRM_PRODUCT_ID,
          signupClusterId: CRM
        },
        secondaryButtonConfig: {
          buttonText: "Learn more",
          url: CRM_MINI_SITE_LINK,
          color: BRAND_BLACK_TEXT_COLOR,
          isOpenLinkInNewTab: true
        }
      }
    },
    {
      showOpen: false,
      isHighlighted: false,
      productType: PROJECTS_PRODUCT_TYPE,
      theme: PROJECTS_ORANGE_THEME_ID,
      header: {
        subtitleText: "For PMO teams and project manager professionals"
      },
      body: {
        bodyText: "Plan, collaborate, and execute on every project and portfolio to reach your goals, faster."
      },
      showDivider: true,
      useCases: {
        text: "Top use cases:",
        list: [
          { text: "Advanced projects" },
          { text: "Critical path" },
          { text: "Portfolio management" },
          { text: "Program management" }
        ]
      },
      buttonConfig: {
        buttonType: SIGNUP_COMBO_BUTTONS_TYPE,
        buttonText: "Learn more",
        isOpenLinkInNewTab: true,
        color: BRAND_BLACK_TEXT_COLOR,
        url: "https://monday.com/project-management",
        signupButtonConfig: {
          buttonColor: BRAND_BLACK_TEXT_COLOR,
          signupProductId: PROJECTS_PRODUCT_ID
        },
        secondaryButtonConfig: {
          buttonText: "Learn more",
          url: PROJECT_MANAGEMENT_MINI_SITE_LINK,
          color: BRAND_BLACK_TEXT_COLOR,
          isOpenLinkInNewTab: true
        }
      }
    },
    {
      showOpen: false,
      isHighlighted: false,
      productType: DEV_PRODUCT_TYPE,
      theme: DEV_GREEN_THEME_ID,
      header: {
        subtitleText: "For product and development professionals and teams"
      },
      body: {
        bodyText: "Build agile workflows to drive impact across your product, design, and R&D teams."
      },
      showDivider: true,
      useCases: {
        text: "Top use cases:",
        list: [
          { text: "Roadmap planning" },
          { text: "Scrum & Kanban" },
          { text: "Bug tracking" },
          { text: "Sprint dashboards" }
        ]
      },
      buttonConfig: {
        buttonType: SIGNUP_COMBO_BUTTONS_TYPE,
        buttonText: "Learn more",
        isOpenLinkInNewTab: true,
        color: BRAND_BLACK_TEXT_COLOR,
        url: "https://monday.com/dev",
        signupButtonConfig: {
          buttonColor: BRAND_BLACK_TEXT_COLOR,
          signupProductId: DEV_PRODUCT_ID,
          signupClusterId: SOFTWARE
        },
        secondaryButtonConfig: {
          buttonText: "Learn more",
          url: SOFTWARE_MINI_SITE_LINK,
          color: BRAND_BLACK_TEXT_COLOR,
          isOpenLinkInNewTab: true
        }
      }
    }
  ]
};
