import { FIELD_TYPES } from "constants/template-field-type";
import AssetFieldsConfig from "components/core/asset/fields-config";
import TitleFieldsConfig from "segments/desktop/core-components/title/fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  fields: {
    tabsColor: {
      type: FIELD_TYPES.COLOR_PICKER,
      label: "Tabs color"
    },
    showProcessIndicator: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Show Process Indicator"
    },
    tabs: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Tabs",
      maxLimit: 8,
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          tabLabel: { type: FIELD_TYPES.TEXT, label: "Tab Label", translatable: true },
          tabId: { type: FIELD_TYPES.TEXT, label: "Tab Id" },
          asset: {
            type: FIELD_TYPES.NESTED,
            label: "Asset",
            fields: AssetFieldsConfig
          },
          title: {
            type: FIELD_TYPES.NESTED,
            label: "Asset Title",
            fields: TitleFieldsConfig
          }
        }
      }
    }
  }
};
