import { PureComponent } from "react";
import { componentStyles } from "./person-info.scss";
import DefaultProps from "./default-props";
import PictureComponent from "/components/core/picture/picture-component";

export default class PersonInfo extends PureComponent {
  render() {
    const { personImg, name, jobDescription, personPhotoborderRadius } = this.props;
    return (
      <div className="person-info">
        <PictureComponent
          className="avatar-image"
          src={personImg.src}
          alt={personImg.alt}
          specificImageStyle={{ borderRadius: personPhotoborderRadius }}
        />
        <div className="person-text">
          <div className="person-name person">{name}</div>
          <div className="job-description person">{jobDescription}</div>
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

PersonInfo.defaultProps = DefaultProps;
