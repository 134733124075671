import TimerVerticalTabsDesktopComponent from "./components/timer-vertical-tabs-desktop/timer-vertical-tabs-desktop-component";
import TimerVerticalTabsMobileComponent from "./components/timer-vertical-tabs-mobile/timer-verticals-tabs-mobile-component";
import { componentStyles } from "./vertical-timer-tabs.scss";
import DefaultProps from "./default-props";

const VerticalTimerTabs = props => {
  return (
    <div className="vertical-timer-tabs">
      <div className="desktop-wrapper">
        <TimerVerticalTabsDesktopComponent {...props} />
      </div>

      <div className="mobile-wrapper">
        <TimerVerticalTabsMobileComponent {...props} />
      </div>

      <style jsx>{componentStyles}</style>
    </div>
  );
}

VerticalTimerTabs.defaultProps = DefaultProps;

export default VerticalTimerTabs;
