import SideBySideTextAndAsset from "segments/desktop/core-components/side-by-side-text-and-asset/side-by-side-text-and-asset";
import HubspotContactForm from "segments/desktop/core-components/hubspot-contact-form/hubspot-contact-form";
import Title from "segments/desktop/core-components/title/title";
import { MD } from "constants/sizes";
import { withGenericConfig } from "contexts/generic-config-context";
import {
  TEST_CONTACT_SALES_HUBSPOT_FORM_ID,
  PORTAL_ID,
  TEST_PORTAL_ID
} from "client/services/hubspot-service/contact-form-config";
import ContactSalesDetails from "./contact-sales-details";
import DefaultProps from "./default-props";

import { componentStyles } from "./hubspot-with-bullets-side-by-side-form-component.scss";

const isProduction = process.env.NODE_ENV === "production";

const HubspotWithBulletsSideBySideFormComponent = (props) => {
  const { translate } = props;
  const {
    formId,
    source,
    overrideCampaignId,
    lastNameFirst,
    backgroundLayers,
    title,
    titleSize,
    benefits,
    logosTitle,
    logos,
    customerQuoteKey,
    formTitle,
    hideSupportStatement,
    enablePhoneSupportGroupTest,
    contactSalesTestMode,
    ...restProps
  } = props;

  let componentPortalId = PORTAL_ID;
  let componentFormId = formId;

  if (!isProduction || contactSalesTestMode) {
    componentPortalId = TEST_PORTAL_ID;
    componentFormId = TEST_CONTACT_SALES_HUBSPOT_FORM_ID;
  }

  const replaceTextComponentProps = {
    portalId: componentPortalId,
    formId: componentFormId,
    source,
    overrideCampaignId,
    lastNameFirst,
    backgroundLayers,
    formTitle,
    enablePhoneSupportGroupTest
  };

  const replaceAssetComponentProps = {
    title,
    titleSize,
    benefits,
    logosTitle,
    logos,
    customerQuoteKey,
    hideSupportStatement
  };

  return (
    <div className="hubspot-with-bullets-side-by-side-form-component">
      <div className="mobile-title">
        <Title title={translate(title)} titleSize={MD} />
      </div>
      <SideBySideTextAndAsset
        replaceTextComponent={HubspotContactForm}
        replaceAssetComponent={ContactSalesDetails}
        replaceTextComponentProps={replaceTextComponentProps}
        replaceAssetComponentProps={replaceAssetComponentProps}
        {...restProps}
      />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

HubspotWithBulletsSideBySideFormComponent.defaultProps = DefaultProps;

export default withGenericConfig(HubspotWithBulletsSideBySideFormComponent);
