import css from "styled-jsx/css";
import { WHITE_COLOR_NAME, WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import Colors from "styles/colors";

const INDICATION_SIZE = 16;
const INDICATION_SIZE_STRING = `${INDICATION_SIZE}px`;
const PROGRESS_BAR_WIDTH = 2;
const PROGRESS_BAR_WIDTH_STRING = `${PROGRESS_BAR_WIDTH}px`;

export const componentStyles = css`
  .monday-demo-tabs-timeline-and-labels-wrapper {
    display: flex;
    flex-direction: column;
    padding: 56px 32px 0;
    min-width: 320px;
    max-width: 400px;
    &.dark-theme {
      .timeline-tab-indication-label-and-progress-bar-wrapper {
        &.selected {
          .timeline-tab-indication-and-label-wrapper {
            .timeline-tab-indication {
              .timeline-tab-indication-outer-circle {
                border-color: ${Colors[WHITE_COLOR_NAME]};
              }
            }
          }
        }
        .timeline-tab-indication-and-label-wrapper {
          .timeline-tab-indication {
            .timeline-tab-indication-outer-circle {
              .timeline-tab-indication-inner-circle {
                background-color: ${Colors[WHITE_COLOR_NAME]};
              }
            }
          }
        }
        .timeline-progress-bar {
          .timeline-progress-bar-empty,
          .timeline-progress-bar-filled {
            background-color: ${Colors[WHITE_COLOR_NAME]};
          }
        }
      }
    }
    .timeline-tab-indication-label-and-progress-bar-wrapper {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      &.selected {
        .timeline-tab-indication-and-label-wrapper {
          .timeline-tab-indication {
            opacity: 1;

            .timeline-tab-indication-outer-circle {
              border-color: ${Colors[WORK_OS_IRIS_COLOR_NAME]};
            }
          }

          .timeline-tab-label {
            font-weight: 500;
          }
        }
        .timeline-progress-bar {
          .timeline-progress-bar-filled {
            height: 100%;
            animation: fill-progress-bar linear;
          }
        }
      }
      &.watched {
        .timeline-tab-indication-and-label-wrapper {
          .timeline-tab-indication {
            opacity: 1;
          }
        }
        .timeline-progress-bar {
          .timeline-progress-bar-filled {
            height: 100%;
          }
        }
        &.selected {
          .timeline-progress-bar {
            .timeline-progress-bar-filled {
              height: 0%;
            }
          }
        }
      }
      &:hover {
        .timeline-tab-indication-and-label-wrapper {
          .timeline-tab-indication {
            opacity: 1;
          }
        }
      }
      .timeline-tab-indication-and-label-wrapper {
        display: flex;
        align-items: center;
        gap: 16px;
        .timeline-tab-indication {
          opacity: 0.24;
          transition: opacity 0.15s ease-in-out;

          .timeline-tab-indication-outer-circle {
            width: ${INDICATION_SIZE_STRING};
            height: ${INDICATION_SIZE_STRING};
            border-radius: ${`${INDICATION_SIZE / 2}px`};
            border: 1px solid transparent;
            display: flex;
            transition: border-color 0.15s ease-in-out;

            .timeline-tab-indication-inner-circle {
              border-radius: ${`${INDICATION_SIZE / 2}px`};
              background-color: ${Colors[WORK_OS_IRIS_COLOR_NAME]};
              margin: 2px;
              flex: 1;
            }
          }
        }

        .timeline-tab-label {
          font-size: 0.875rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 22px;
        }
      }
      .timeline-progress-bar {
        width: ${PROGRESS_BAR_WIDTH_STRING};
        height: 36px;
        margin-left: ${`${INDICATION_SIZE / 2 - 1}px`};
        position: relative;
        .timeline-progress-bar-empty,
        .timeline-progress-bar-filled {
          border-radius: ${PROGRESS_BAR_WIDTH_STRING};
          background-color: ${Colors[WORK_OS_IRIS_COLOR_NAME]};
          width: 100%;
        }

        .timeline-progress-bar-empty {
          height: 100%;
          opacity: 0.24;
        }

        .timeline-progress-bar-filled {
          height: 0%;
          position: absolute;
          top: 0;
          transition: height 0.15s ease-in-out;
        }
      }
    }
    .button-wrapper {
      display: flex;
      margin-top: 40px;
    }
  }

  @keyframes fill-progress-bar {
    from {
      height: 0%;
    }
    to {
      height: 100%;
    }
  }
`;
