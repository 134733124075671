import classnames from "classnames";
import { isDarkTheme } from "segments/segments-service";
import { componentStyles } from "./monday-demo-mobile-timeline.scss";

const MondayDemoMobileTimeline = (props) => {
  const { tabs, selectedTab, wasComponentEverVisible, videoDuration, theme } = props;

  return (
    <div
      className={classnames("monday-demo-tabs-timeline-and-labels-wrapper", { "dark-theme": isDarkTheme(theme) })}
      role="tablist"
    >
      {tabs.map((tab, index) => {
        const isSelected = index === selectedTab;
        return (
          <div
            className={classnames("timeline-tab-indication-label-and-progress-bar-wrapper", {
              selected: isSelected && wasComponentEverVisible,
              watched: index <= selectedTab
            })}
          >
            {index < tabs.length && (
              <div className="timeline-progress-bar-wrapper">
                <div className="timeline-progress-bar">
                  <div className="timeline-progress-bar-empty" />
                  <div
                    className="timeline-progress-bar-filled"
                    style={{ animationDuration: index <= selectedTab ? `${videoDuration}s` : `unset` }}
                  />
                </div>
              </div>
            )}
          </div>
        );
      })}
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default MondayDemoMobileTimeline;
