import css from "styled-jsx/css";

export const componentStyles = css`
  .scene-container {
    transition: transform ease-in-out 2s;
    height: inherit;
    width: inherit;

    :global(.scene-picture) {
      height: inherit;
      width: inherit;
    }
  }
`;
