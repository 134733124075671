import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, MAX_DESKTOP_CONTENT_WIDTH, XS } from "constants/vp-sizes";

const MAX_DESKTOP_CONTENT_WIDTH_PX = `${MAX_DESKTOP_CONTENT_WIDTH}px`;
const XS_PX = `${XS}px`;

export const componentStyles = css.global`
  .hubspot-side-by-side-form-component {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    margin: auto;
  }

  @media (min-width: ${XS_PX}) and (max-width: ${MAX_DESKTOP_CONTENT_WIDTH_PX}) {
    .hubspot-side-by-side-form-component {
      .full-paragraph-component-wrapper {
        padding: 0 20px 20px 20px !important;
      }
    }
  }

  @media (max-width: ${XS_PX}) {
    .hubspot-side-by-side-form-component {
      .side-by-side-content {
        padding: 0 64px;

        .full-paragraph-component-wrapper {
          padding: 0 20px 20px 20px !important;
        }
      }
    }
  }
`;
