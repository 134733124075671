import { PureComponent } from "react";
import { componentStyles } from "./full-background-text-and-asset.scss";
import DefaultProps from "./default-props";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import { generateCloudinaryImageUrl } from "services/community/cloudinary-service";
import { getDynamicAssetConfigForPath } from "/client/services/cluster-config-service/cluster-config-service";
import { withGenericConfig } from "/contexts/generic-config-context";
import { withRouter } from "next/router";
import classnames from "classnames";

class FullBackgroundTextAndAsset extends PureComponent {
  getImageSrc = () => {
    const { backgroundImage, assetQuality, useDynamicAsset, clusterConfig, activePagePath } = this.props;
    const { src } = backgroundImage;

    const dynamicAssetConfig = useDynamicAsset && getDynamicAssetConfigForPath(clusterConfig, activePagePath);
    const url = dynamicAssetConfig?.src || generateCloudinaryImageUrl(src, null, { assetQuality });
    return `'${url}'`;
  };

  getImageAccessibilityProps = () => {
    const { backgroundImage } = this.props;
    if (!backgroundImage?.alt) return {};

    return {
      "aria-label": backgroundImage.alt,
      role: "img"
    };
  };

  render() {
    const { fullParagraph, titleSize, textSize, withDynamicParagraphTitle, withDynamicParagraphSubtitle, spaceFromParagraph, topicBottomMargin, buttonTopMargin, paragraphVerticalAlignment } = this.props;
    const imageAccessibilityProps = this.getImageAccessibilityProps();

    return (
      <div
        className="full-background-text-and-asset"
        style={{
          backgroundImage: `url(${this.getImageSrc()})`
        }}
        {...imageAccessibilityProps}
      >
        <div className={classnames("full-background-text-and-asset-content", paragraphVerticalAlignment)}>
          <FullParagraph
            titleSize={titleSize}
            textSize={textSize}
            withDynamicParagraphTitle={withDynamicParagraphTitle}
            withDynamicParagraphSubtitle={withDynamicParagraphSubtitle}
            spaceFromParagraph={spaceFromParagraph}
            topicBottomMargin={topicBottomMargin}
            buttonTopMargin={buttonTopMargin}
            {...fullParagraph}
          />
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

FullBackgroundTextAndAsset.defaultProps = DefaultProps;

export default withGenericConfig(withRouter(FullBackgroundTextAndAsset));
