import { FIELD_TYPES } from "constants/template-field-type";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import BoardViewsTabFieldsConfig from "segments/desktop/core-components/board-views-tab/fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Side By Side Board Views Component",
  fields: {
    fullParagraph: {
      type: FIELD_TYPES.NESTED,
      label: "Full Paragraph",
      fields: FullParagraphFieldsConfig
    },
    ...BoardViewsTabFieldsConfig
  }
};
