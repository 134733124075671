import dynamic from "next/dynamic";

const SideBySideWithBackgroundHeroComponent = require("./side-by-side-with-background-hero-component").default;

import SideBySideWithBackgroundHeroConfig from "./side-by-side-with-background-hero-config";

export default {
  SideBySideWithBackgroundHeroComponent,
  SideBySideWithBackgroundHeroConfig
};
