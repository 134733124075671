import { FIELD_TYPES } from "constants/template-field-type";
import HubspotContactFormFields from "segments/desktop/core-components/hubspot-contact-form/fields-config";
import ParagraphAndLogosFieldsConfig from "segments/desktop/core-components/paragraph-and-logos/fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Hubspot And Logos",
  fields: {
    ...HubspotContactFormFields,
    paragraphAndLogos: {
      type: FIELD_TYPES.NESTED,
      label: "Paragraph and Logos",
      fields: ParagraphAndLogosFieldsConfig
    }
  }
};
