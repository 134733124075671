import ReviewCardDefaultProps from "segments/desktop/core-components/review-card/default-props";

export default {
  reviews: [
    {
      ...ReviewCardDefaultProps,
      decorationColor: "green",
      title:
        '"I used monday.com to manage a marketing campaign and had all the stake holders on it, from client to designer, everyone was on the platform. "'
    },
    {
      ...ReviewCardDefaultProps,
      decorationColor: "iris",
      title:
        '"monday.com is excellent. I keep track of everything with it: client projects, marketing calendar, employee time off, expenses, payments & invoicing, company revenue"'
    }
  ],
  reviewCardOverrideHeight: null
};
