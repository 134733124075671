import VerticalTimerTabs from "segments/desktop/core-components/vertical-timer-tabs/vertical-timer-tabs";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import { XS } from "constants/sizes";
import DefaultProps from "./default-props";
import { componentStyles } from "./colored-timer-vertical-tabs-component.scss";

const ColoredTimerVerticalTabsComponent = props => {
  const contentRenderer = contentObject => {
    return <FullParagraph {...contentObject} titleSize={XS} />
  }

  return (
    <div className="colored-timer-vertical-tabs-component">
      <VerticalTimerTabs {...props} tabContentRenderer={contentRenderer} />
      <style jsx>{componentStyles}</style>
    </div>
  );
}

ColoredTimerVerticalTabsComponent.defaultProps = DefaultProps;

export default ColoredTimerVerticalTabsComponent;
