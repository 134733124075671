import { FIELD_TYPES } from "constants/template-field-type";
import { selectTemplateConfig } from "segments/core-configs/template-config";
import { SIGNUP_BUTTON_TYPE } from "segments/desktop/core-components/button//button-consts";
import AssetFieldsConfig from "components/core/asset/fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Multi Templates",
  fields: {
    solutionsInfo: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Templates",
      maxLimit: 3,
      minLimit: 1,
      defaultValue: {
        buttonSelect: {
          buttonType: SIGNUP_BUTTON_TYPE,
          signupButtonText: "Use template",
          seconderyButtonText: "Learn more"
        },
        showTitle: true,
        showDescription: true,
        showImageShadow: true
      },
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          ...selectTemplateConfig,
          overrideImage: { ...selectTemplateConfig.overrideImage, fields: { image: AssetFieldsConfig.image } }
        }
      }
    }
  }
};
