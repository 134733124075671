import { useEffect, useRef, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import { trackEvent } from "services/bigbrain-service";
import { TAB_CLICKED } from "constants/bigbrain-event-types";
import { componentStyles } from "./monday-demo-tabs-desktop-component.scss";
import MondayDemoTabsAsset from "../monday-demo-tab-asset/monday-demo-tabs-asset";
import MondayDemoTimeline from "../monday-demo-timeline/monday-demo-timeline";

export const DEMO_FINISHED_SELECTED_TAB_INDEX = -1;

const MondayDemoTabsDesktopComponent = (props) => {
  const { tabs, ...rest } = props;

  const tabsWithVideoPlayerRefs = [];
  tabs.forEach((tab) => {
    tabsWithVideoPlayerRefs.push({
      ...tab,
      asset: {
        ...tab.asset,
        video: {
          ...tab.asset.video,
          videoPlayerRef: useRef(null)
        }
      }
    });
  });

  return <MondayDemoTabsComponentInner tabs={tabsWithVideoPlayerRefs} {...rest} />;
};

const MondayDemoTabsComponentInner = (props) => {
  const { fullParagraph, tabs, buttonConfig, theme } = props;

  const [selectedTab, setSelectedTab] = useState(0);
  const [wasComponentEverVisible, setWasComponentEverVisible] = useState(false);
  const [videoDurations, setVideoDurations] = useState([]);
  const [buttonHighlighted, setButtonHighlighted] = useState(false);

  const VISIBILITY_SENSOR_OFFSET = 100;

  const onTabClick = ({ index }) => {
    setSelectedTab(index);
    setButtonHighlighted(false);
    trackEvent(TAB_CLICKED, {
      kind: tabs?.[index].tabLabel,
      info1: index
    });
  };

  const onVideoEnded = (index) => {
    if (index < tabs.length - 1) {
      setSelectedTab(index + 1);
    }
    // last tab
    if (index === tabs.length - 1) {
      setSelectedTab(DEMO_FINISHED_SELECTED_TAB_INDEX); // demo finished
      setButtonHighlighted(true);
    }
  };

  const setVideoDuration = (index, duration) => {
    if (videoDurations[index] !== duration) {
      const newVideoDurations = [...videoDurations];
      newVideoDurations[index] = duration;
      setVideoDurations(newVideoDurations);
    }
  };

  const getCurrentAsset = (index) => {
    if (index === DEMO_FINISHED_SELECTED_TAB_INDEX) {
      return tabs?.[tabs.length - 1]?.asset;
    }
    return tabs?.[index]?.asset;
  };

  const onVisibilityChange = (isVisible) => {
    if (!wasComponentEverVisible && isVisible) {
      setWasComponentEverVisible(true);
    }
  };

  useEffect(() => {
    tabs?.[selectedTab]?.asset?.video?.videoPlayerRef?.current?.seekTo(0);
  }, [selectedTab]);

  return (
    <VisibilitySensor
      offset={{ bottom: VISIBILITY_SENSOR_OFFSET, top: VISIBILITY_SENSOR_OFFSET }}
      partialVisibility={true}
      onChange={onVisibilityChange}
      active={!wasComponentEverVisible} // turn it off once the component becomes visible
      scrollCheck={!wasComponentEverVisible} // turn it off once the component becomes visible
    >
      <div className="monday-demo-tabs-desktop-component">
        <FullParagraph {...fullParagraph} />
        <div className="monday-demo-tabs-asset-and-timeline">
          <div className="monday-demo-tabs-assets-wrapper">
            {tabs.map((tab, index) =>
              // get videoDurations by order, render everything only when component is visible
              wasComponentEverVisible && index <= videoDurations.length ? (
                <MondayDemoTabsAsset
                  selectedTab={selectedTab}
                  index={index}
                  isCurrent={selectedTab === index}
                  asset={getCurrentAsset(index)}
                  onVideoEnded={() => onVideoEnded(index)}
                  // play only the current video excluding final button step's video (so triggers when video is done won't be fired)
                  autoPlay={selectedTab === index && index !== DEMO_FINISHED_SELECTED_TAB_INDEX}
                  setVideoDuration={(duration) => setVideoDuration(index, duration)}
                />
              ) : null
            )}
            {wasComponentEverVisible && (
              <MondayDemoTabsAsset
                selectedTab={selectedTab}
                index={DEMO_FINISHED_SELECTED_TAB_INDEX}
                isCurrent={selectedTab === DEMO_FINISHED_SELECTED_TAB_INDEX}
                // show last video first frame
                asset={tabs?.[tabs.length - 1]?.asset}
                // play the last video so it would stay on the last frame
                autoPlay={true}
                onVideoEnded={onVideoEnded}
              />
            )}
          </div>
          <MondayDemoTimeline
            theme={theme}
            tabs={tabs}
            selectedTab={selectedTab}
            buttonConfig={buttonConfig}
            buttonHighlighted={buttonHighlighted}
            wasComponentEverVisible={wasComponentEverVisible}
            onTabClick={onTabClick}
            videoDuration={videoDurations[selectedTab]}
          />
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    </VisibilitySensor>
  );
};

export default MondayDemoTabsDesktopComponent;
