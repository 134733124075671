import { useRef } from "react";
import useWindowSize from "client/services/hooks/use-window-size";
import useRect from "client/services/hooks/use-rect";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import classnames from "classnames";
import { getComponentsConfig, steps, VARIATIONS } from "../config";
import ParallexParagraphs from "../parallex-paragraphs";
import { componentStyles } from "./scroll-parallax-homepage-first-fold-desktop-component.scss";
import { sharedComponentStyles } from "../scroll-parallax-homepage-first-fold-component.scss";

const ScrollParallaxHomepageFirstFoldDesktopComponent = (props) => {
  const { alignToLogo, paragraphs, translate, bottomAsset, topAsset } = props;
  const { width: windowWidth } = useWindowSize();

  const parallexEl = useRef(null);
  const controllerEl = useRef(null);

  const parallexRect = useRect(parallexEl);
  const controllerRect = useRect(controllerEl);

  const variation = windowWidth >= MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH ? VARIATIONS.MEDIUM : VARIATIONS.SMALL;
  const componentsConfig = getComponentsConfig(variation, translate, topAsset, bottomAsset);

  const currentStep = steps[Math.floor((controllerRect.offsetTop * steps.length) / parallexRect.clientHeight)];

  return (
    <div className="scroll-parallax-homepage-first-fold-component-desktop">
      <div className={classnames("container", { "align-to-logo": alignToLogo })}>
        <div className="paragraphs">
          <ParallexParagraphs paragraphs={paragraphs} />
        </div>

        <div ref={parallexEl} className="parallex">
          <div ref={controllerEl} className="controller">
            {componentsConfig.map((cc) => {
              const { id, props, component: Component } = cc;

              return <Component key={id} {...props(currentStep)} />;
            })}
          </div>
        </div>
      </div>

      <style jsx>{componentStyles}</style>
      <style jsx>{sharedComponentStyles}</style>
    </div>
  );
};

export default ScrollParallaxHomepageFirstFoldDesktopComponent;
