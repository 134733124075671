import { PureComponent } from "react";
import { withGenericConfig } from "/contexts/generic-config-context";
import { getContactFormConfigById } from "client/services/hubspot-service/contact-form-config";
import {
  HUBSPOT_API_VISITOR,
  HUBSPOT_API_CLUSTER,
  HUBSPOT_API_FROM,
  HUBSPOT_API_SOURCE,
  HUBSPOT_API_PRIVACY_CONSENT,
  URL_PARAM_FROM,
  HUBSPOT_API_COMPANY_SIZE,
  HUBSPOT_API_1_19_PHONE_SUPPORT_GROUP_TEST_FIELD
} from "client/services/hubspot-service/hubspot-constants";
import { getSingleUrlParamToHubspotForm, sendHubspotForm } from "client/services/hubspot-service/hubspot-service";
import { getCookie } from "/services/cookies-service";
import {
  HUBSPOT_FORM_SUBMIT_CLICKED,
  HUBSPOT_FORM_SUBMIT_FAILED,
  HUBSPOT_FORM_READY,
  HUBSPOT_FORM_INPUT_BLUR,
  CONTACT_SALES_FORM_SUBMISSION_FAILED
} from "constants/bigbrain-event-types";
import { BIG_BRAIN_VISITOR_ID, CLUSTER_COOKIE } from "/constants/cookies";
import DataLayerEventTypes from "constants/data-layer-event-types";
import { fireGtmEvent } from "client/services/gtm-service/gtm-service";
import { IPO_CAMPAIGN_ID, GARTNER_CAMPAIGN_ID } from "constants/sales/campaigns";
import BaseForm from "segments/desktop/core-components/base-form/base-form";
import { SMALLEST_COMPANY_SIZE } from "client/services/form-service/form-constants";
import { withAbTests } from "contexts/ab-tests-context";
import { getAbTestVariantNew, isInTest } from "services/new-ab-tests/new-ab-tests-service";
import { CS_1_19_PHONE_SUPPORT_TEST } from "constants/new-ab-tests";
import { trackEvent } from "services/bigbrain-service";
import { handleClientException } from "client/services/exceptions-handling-service/exceptions-handling-service";
import { getEnrichmentEntriesForSales } from "services/sales-enrichment/sales-enrichment-service";
import DefaultProps from "./default-props";
import { componentStyles } from "./hubspot-contact-form.scss";

const HUBSPOT_INVALID_EMAIL_ERROR_TYPE = "INVALID_EMAIL";
const UTM_CAMPAIGN_PARAM_NAME = "utm_campaign";
const GARTNER_REPORT_URL = "https://www.gartner.com/doc/reprints?id=1-2AKZAKIR&ct=220714&st=sb";

class HubspotContactForm extends PureComponent {
  componentDidMount() {
    const { formId } = this.props;
    trackEvent(HUBSPOT_FORM_READY, { kind: formId });
  }

  generateHiddenData = ({ shouldAdd1_19PhoneSupportTestHiddenField }) => {
    const { withPrivacyNotice, source, overrideCampaignId, abTests } = this.props;

    let urlParams = getEnrichmentEntriesForSales();
    if (overrideCampaignId) {
      const urlParamsWithoutCampaign = urlParams.filter((param) => param?.name !== UTM_CAMPAIGN_PARAM_NAME);
      urlParamsWithoutCampaign.push({ name: UTM_CAMPAIGN_PARAM_NAME, value: overrideCampaignId });
      urlParams = urlParamsWithoutCampaign;
    }

    const hiddenData = [...urlParams];

    const visitorId = getCookie(BIG_BRAIN_VISITOR_ID) || "";
    hiddenData.push({ name: HUBSPOT_API_VISITOR, value: visitorId });

    const clusterId = getCookie(CLUSTER_COOKIE) || "";
    hiddenData.push({ name: HUBSPOT_API_CLUSTER, value: clusterId });

    hiddenData.push({ name: HUBSPOT_API_SOURCE, value: source });

    hiddenData.push({ name: HUBSPOT_API_PRIVACY_CONSENT, value: withPrivacyNotice });

    if (shouldAdd1_19PhoneSupportTestHiddenField) {
      const testVariantValue = getAbTestVariantNew(abTests, CS_1_19_PHONE_SUPPORT_TEST.test_name, false);

      // if getAbTestVariantNew returns null (not in test), it means the enablePhoneSupportGroupTest prop was true
      // thus we'd want to send the new variant
      const value = testVariantValue || CS_1_19_PHONE_SUPPORT_TEST.new_variation_name;

      hiddenData.push({
        name: HUBSPOT_API_1_19_PHONE_SUPPORT_GROUP_TEST_FIELD,
        value
      });
    }

    const fromUrlParamObject = getSingleUrlParamToHubspotForm(window.location, URL_PARAM_FROM, HUBSPOT_API_FROM);
    if (fromUrlParamObject.value) hiddenData.push(fromUrlParamObject);

    return hiddenData;
  };

  getFirstErrorMessageFromResponse = (response) => {
    if (response?.errors?.[0]?.errorType === HUBSPOT_INVALID_EMAIL_ERROR_TYPE) {
      // Formatting Hubspot message into more user friendly one for known message types
      return "form.errors.invalidEmailError";
    }

    return response?.errors?.[0]?.message;
  };

  isEligibleForCS1_19PhoneSupportTest = (formData) => {
    return formData.find((inputField) => inputField.name === HUBSPOT_API_COMPANY_SIZE)?.value === SMALLEST_COMPANY_SIZE;
  };

  sendFormToHubspot = async (formData) => {
    try {
      const { formId, portalId, abTests, enablePhoneSupportGroupTest } = this.props;
      let shouldAdd1_19PhoneSupportTestHiddenField = false;

      if (this.isEligibleForCS1_19PhoneSupportTest(formData)) {
        if (enablePhoneSupportGroupTest || isInTest(CS_1_19_PHONE_SUPPORT_TEST, abTests, true)) {
          shouldAdd1_19PhoneSupportTestHiddenField = true;
        }
      }
      const hiddenDataParams = this.generateHiddenData({ shouldAdd1_19PhoneSupportTestHiddenField });
      const fields = [...formData, ...hiddenDataParams];

      const response = await sendHubspotForm(portalId, formId, fields);
      const success = response && !response.status; // Success request has no status entry
      let errorMessage = null;

      if (!success) {
        const firstErrorType = response?.errors && response?.errors[0]?.errorType;
        errorMessage = this.getFirstErrorMessageFromResponse(response);
        trackEvent(HUBSPOT_FORM_SUBMIT_FAILED, { kind: formId, info1: firstErrorType, info2: errorMessage });
      } else {
        trackEvent(HUBSPOT_FORM_SUBMIT_CLICKED, { kind: formId });
        fireGtmEvent(DataLayerEventTypes.HUBSPOT_FORM_SUBMITTED, { formId });
      }

      return { success, errorMessage };
    } catch (e) {
      await handleClientException({ e, bbEventParams: { name: CONTACT_SALES_FORM_SUBMISSION_FAILED } });
    }
  };

  getSubmitScreenData = () => {
    const { overrideCampaignId } = this.props;
    switch (overrideCampaignId) {
      case IPO_CAMPAIGN_ID:
        return {
          titleKey: "forms.thanksPage.ipoCampaign.title",
          subTitleHtmlKey: "forms.thanksPage.ipoCampaign.subTitleHtml"
        };
      case GARTNER_CAMPAIGN_ID:
        return {
          titleKey: "forms.thanksPage.gartnerCampaign.title",
          subTitleHtmlKey: "forms.thanksPage.gartnerCampaign.subTitleHtml",
          footerHtml: "forms.thanksPage.gartnerCampaign.footerHtml",
          downloadButtonLink: GARTNER_REPORT_URL
        };
      default:
        return null;
    }
  };

  onInputBlur = (name, value) => {
    const { formId } = this.props;
    trackEvent(HUBSPOT_FORM_INPUT_BLUR, { kind: formId, info1: name, info2: value.length });
  };

  render() {
    const {
      withPrivacyNotice,
      formId,
      overrideCampaignId,
      backgroundLayers,
      isMobileOrTabletBrowser,
      formTitle,
      lastNameFirst
    } = this.props;
    const formConfig = getContactFormConfigById({
      formId,
      overrideCampaignId,
      withPrivacyNotice,
      formTitle,
      lastNameFirst
    });
    const submitScreenData = this.getSubmitScreenData();

    return (
      <div className="hubspot-contact-form-wrapper">
        <BaseForm
          backgroundLayers={isMobileOrTabletBrowser ? 0 : backgroundLayers}
          formConfig={formConfig}
          submitFormAsyncCallback={this.sendFormToHubspot}
          submitScreenData={submitScreenData}
          onInputBlur={this.onInputBlur}
          renderPrivacyNotice={withPrivacyNotice}
        />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

HubspotContactForm.defaultProps = DefaultProps;

export default withAbTests(withGenericConfig(HubspotContactForm));
