import TabsComponent from "components/core/tabs/tabs-component";
import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import TwoThirdsTextAndAssetComponent from "segments/desktop/text-and-asset/layouts/two-thirds-text-and-asset/two-thirds-text-and-asset-component";
import FullParagraphComponent from "segments/desktop/paragraph/layouts/full-paragraph/full-paragraph-component";
import TagItemsGridComponent from "segments/desktop/grid/layouts/tag-items-grid/tag-items-grid-component"
import FlexibleCardGridConfig from "segments/desktop/grid/layouts/flexible-cards-grid/flexible-cards-grid-config";
import GridImageWithParagraph from "segments/desktop/core-components/grid-image-with-paragraph/grid-image-with-paragraph";

const getGridImageWithParagraphProps = (imageSrc, fullParagraphTitle, fullParagraphBody) => {
  const fullParagraph = {
    ...GridImageWithParagraph.defaultProps.fullParagraph,
    title: fullParagraphTitle,
    body: fullParagraphBody,
    textAlign: "center",
    mobileTextAlign: "center"
  };

  return {
    ...GridImageWithParagraph.defaultProps,
    src: imageSrc,
    mobileImage: { src: imageSrc },
    fullParagraph,
    cardNumOfColumnsWidth: 4
  };
};

export default {
  ...TabsComponent.defaultProps,
  tabsColor: WORK_OS_IRIS_COLOR_NAME,
  swapTagsWithTextAndAssets: false,
  tabs: [
    {
      tabLabel: "Business owner",
      textAndAssetTwoThirds: {
        ...TwoThirdsTextAndAssetComponent.defaultProps,
        fullParagraph: {
          ...TwoThirdsTextAndAssetComponent.defaultProps.fullParagraph,
        },
        asset: {
          ...TwoThirdsTextAndAssetComponent.defaultProps.asset,
          position: "center"
        }
      },
      fullParagraph: {
        ...FullParagraphComponent.defaultProps,
        topic: {},
        title: "Why use monday sales CRM",
        body: "",
        textAlign: "center",
        mobileTextAlign: "center"
      },
      tagItems: {
        ...TagItemsGridComponent.defaultProps,
        gridTagsBackgroundColor: "transparent",
      },
      flexibleCards: {
        ...FlexibleCardGridConfig.defaultProps,
        imagePaddingBottom: "24px",
        gridItems: [
          getGridImageWithParagraphProps(
            "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/SafaaEek/467px-Monday_logo.svg.png",
            "“I like the ease of use and the ability to do everything from one main dashboard, as well as the ease of adding automations.",
            "Maurice W. | CEO, facilities services "
          ),
        ],
      }
    },
    {
      tabLabel: "Sales Manager",
      textAndAssetTwoThirds: {
        ...TwoThirdsTextAndAssetComponent.defaultProps,
        fullParagraph: {
          ...TwoThirdsTextAndAssetComponent.defaultProps.fullParagraph,
        },
        asset: {
          ...TwoThirdsTextAndAssetComponent.defaultProps.asset,
          position: "center"
        }
      },
      fullParagraph: {
        ...FullParagraphComponent.defaultProps,
        topic: {},
        title: "Why use monday sales CRM",
        body: "",
        textAlign: "center",
        mobileTextAlign: "center"
      },
      tagItems: {
        ...TagItemsGridComponent.defaultProps,
        gridTagsBackgroundColor: "transparent",
      },
      flexibleCards: {
        ...FlexibleCardGridConfig.defaultProps,
        imagePaddingBottom: "24px",
        gridItems: [
          getGridImageWithParagraphProps(
            "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/SafaaEek/467px-Monday_logo.svg.png",
            "“I like the ease of use and the ability to do everything from one main dashboard, as well as the ease of adding automations.",
            "Maurice W. | CEO, facilities services "
          ),
        ],
      }
    },
  ],
};