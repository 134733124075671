import AssetComponent from "components/core/asset/asset-component";
import Button from "segments/desktop/core-components/button/button";
import { XS, XXS } from "constants/sizes";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import DefaultProps from "./default-props";
import { componentStyles } from "./side-by-side-with-icons-text-and-asset-mobile-component.scss.js";

const SideBySideWithIconsTextAndAssetMobileComponent = (props) => {
  const { iconsConfig, asset, fullParagraph, mobileMarginBetweenAssetAndButton, mobileMarginUnderButton } = props;
  const { buttonConfig, ...fullParagraphRest } = fullParagraph;
  return (
    <div
      className="side-by-side-with-icons-text-and-asset-mobile-component"
      style={{
        "--margin-between-asset-and-button": mobileMarginBetweenAssetAndButton
      }}
    >
      <div className="full-paragraph">
        <FullParagraph
          {...fullParagraphRest}
          textAlign="center"
          titleSize={XS}
          textSize={XXS}
          iconsConfig={iconsConfig}
        />
      </div>
      <AssetComponent {...asset} />
      <div className="button-wrapper">
        <Button {...buttonConfig} />
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

SideBySideWithIconsTextAndAssetMobileComponent.defaultProps = DefaultProps;

export default SideBySideWithIconsTextAndAssetMobileComponent;
