import css from "styled-jsx/css";
import { MOBILE_QUERY_MAX_WIDTH_M, SM, MAX_DESKTOP_GRID_WIDTH } from "constants/vp-sizes";
import { MOBILE_GRID_IMAGE_SIZE } from "segments/desktop/grid/grid-service";
import { getComponentShadow } from "segments/desktop/constants";

const MOBILE_GRID_IMAGE_SIZE_PX = `${MOBILE_GRID_IMAGE_SIZE}px`;
const SM_PX = `${SM}px`;
const MAX_DESKTOP_GRID_WIDTH_PX = `${MAX_DESKTOP_GRID_WIDTH}px`;

const ITEMS_MARGIN = 16;
const ITEMS_MARGIN_PX = `${ITEMS_MARGIN}px`;

export const componentStyles = css.global`
  .images-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .grid-image-container {
      margin: ${ITEMS_MARGIN_PX};
      padding: 16px 0;

      .full-paragraph-wrapper {
        margin-top: 16px;
      }
      .grid-image-wrapper {
        &.with-shadow {
          ${getComponentShadow()}
        }
      }
    }
  }

  @media (min-width: ${SM_PX}) and (max-width: ${MAX_DESKTOP_GRID_WIDTH_PX}) {
    .grid-image-container {
      width: calc(33vw - ${ITEMS_MARGIN * 2}px) !important;

      .grid-image {
        width: 100% !important;

        .image {
          width: 100% !important;
        }
      }
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH_M}) {
    .grid-image-container {
      .grid-image-wrapper {
        .grid-image {
          .image {
            width: ${MOBILE_GRID_IMAGE_SIZE_PX};
            height: auto;
          }
        }
      }
    }
  }
`;
