import css from "styled-jsx/css";
import colors from "styles/colors";
import { BRAND_BLACK_TEXT_COLOR, MARKETING_RED_COLOR_NAME } from "styles/color-consts";
import { LAYER_ONE } from "constants/z-index";
import { getComponentShadow, getComponentShadowWithHoverEffect } from "segments/desktop/constants";

const CARD_BORDER_RADIUS = 8;

export const componentStyles = css.global`
  .product-card {
    width: 286px;
    z-index: ${LAYER_ONE};
    margin: 32px 10px 0;
    padding: 32px 24px;
    border-radius: ${`${CARD_BORDER_RADIUS}px`};
    background-color: white;
    ${getComponentShadow()}
    transition: filter 0.2s ease-in-out;
    color: ${colors[BRAND_BLACK_TEXT_COLOR]};
    position: relative;

    &:hover,
    &.highlighted {
      ${getComponentShadowWithHoverEffect()}
    }

    &.highlighted::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: ${`${CARD_BORDER_RADIUS}px`};
      padding: 2px;
      background: var(--color);
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#000 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
    }

    &.is-at-least-one-card-highlighted {
      margin: 44px 10px 12px;
      padding: 32px 24px;
      &.highlighted {
        margin: 32px 2px 0;
        padding: 44px 24px;
      }
    }
    .product-card-header {
      margin-bottom: 32px;

      .product-card-header-image {
        height: 51px;
        margin-bottom: 5px;

        img {
          max-height: 51px;
        }
      }

      .product-card-header-subtitle {
        color: ${colors.asphalt};
        font-size: 0.8125rem;
        line-height: 1.125rem;
      }
    }

    .product-card-body {
      font-size: 0.875rem;
      font-weight: lighter;
      line-height: 1.5;
    }

    .product-card-divider {
      box-sizing: content-box;
      border-width: 1px;
      border-style: solid;
      border-image: linear-gradient(to right, #ff158aff, #ff158a00);
      border-image-slice: 1;
      margin-bottom: 20px;
      margin-top: 32px;
    }

    .product-card-use-cases {
      .product-card-use-cases-title {
        margin-bottom: 12px;
        font-size: 0.8125rem;
      }

      .product-card-use-cases-bullets {
        display: flex;
        margin-bottom: 20px;
        flex-direction: column;

        .product-card-use-cases-bullet-icon-and-text {
          display: flex;
          margin-bottom: 5px;
          font-size: 0.8125rem;
          align-items: center;
          .product-card-use-cases-bullet-icon {
            display: flex;
            color: ${colors[MARKETING_RED_COLOR_NAME]};
            margin-right: 5px;
          }
        }
      }
    }

    @mixin card-button {
      font-size: 0.8125rem;
      white-space: nowrap;
      min-width: unset;

      * {
        font-weight: 400;
      }
    }

    .product-card-footer {
      .signup-combo-buttons-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .signup-button.ladda-button {
          @include card-button;
        }
      }

      .signup-form-core-component-wrapper .signup-form {
        justify-content: flex-start;

        .signup-button {
          width: 220px;
          margin-bottom: 12px;
          @include card-button;
        }
      }

      .secondary-button {
        font-size: 0.8125rem;
        a {
          &.with-underline {
            background: unset;
            .secondary-button-text {
              color: ${colors.asphalt};
            }
          }
        }

        * {
          font-weight: 500;
        }
      }
    }
  }
`;
