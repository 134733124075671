import { PureComponent } from "react";
import classnames from "classnames";
import Colors from "styles/colors";
import { componentStyles } from "./progressbar-component.scss";

export default class ProgressBarComponent extends PureComponent {
  render() {
    const { forceProgressbarDone, duration, progressbarColor, enabled } = this.props;

    return (
      <div className="progressbar">
        <div
          className={classnames({
            "progressbar-fill": enabled,
            "progressbar-start-animation": !forceProgressbarDone,
            "progressbar-stop-animation": forceProgressbarDone || !enabled,
          })}
          style={{ animationDuration: `${duration}ms`, backgroundColor: Colors[progressbarColor] }}
        ></div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
