import css from "styled-jsx/css";
import colors, { asphalt } from "styles/colors";
import { DARK_WORK_OS_IRIS_COLOR_NAME, WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";

const CONTENT_PADDING = "44px";
export const IMAGE_WIDTH = 200;

const INDICATORS_PADDING = 4.5;
const INDICATOR_SIZE = 11;
const INDICATOR_SIZE_ON_HOVER = 16;

const INDICATORS_PADDING_STRING = `${INDICATORS_PADDING}px`;
const INDICATORS_PADDING_ON_HOVER_STRING = `${INDICATORS_PADDING - (INDICATOR_SIZE_ON_HOVER - INDICATOR_SIZE) / 2}px`;
const INDICATOR_SIZE_STRING = `${INDICATOR_SIZE}px`;
const INDICATOR_SIZE_ON_HOVER_STRING = `${INDICATOR_SIZE_ON_HOVER}px`;

const background = `linear-gradient(180deg, ${colors[WORK_OS_IRIS_COLOR_NAME]} 0%, ${colors[WORK_OS_IRIS_COLOR_NAME]} 25%, ${colors[DARK_WORK_OS_IRIS_COLOR_NAME]} 100%)`;

export const componentStyles = css`
  .reviews-carousel {
    .reviews-carousel-content-and-indicators {
      margin-right: ${`${IMAGE_WIDTH * 3 / 5}px`};
      &.has-customer-image {
        margin-bottom: ${`${IMAGE_WIDTH - 100}px`};
      }
      .reviews-carousel-content {
        width: 100%;
        height: 320px;
        background: ${background};
        border-radius: 16px;
        color: white;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        padding: ${CONTENT_PADDING};
        position: relative;
        .quote-wrapper {
          display: flex;
          flex-direction: row;
          font-size: 1.3125rem;
          flex: 1;
          opacity: 0;
          transition: all 0.3s;
          &:not(:first-child) {
            position: absolute;
            top: 0;
            left: 0;
            padding: ${CONTENT_PADDING};
            height: 100%;
          }
          &.selected {
            opacity: 1;
          }
          .content-wrapper {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            transition: all 0.3s;
            .quote {
              position: relative;
              margin: auto 0;
              display: flex;
              text-align: left;
              letter-spacing: -0.5px;
              line-height: 36px;
              .quotationMark {
                position: absolute;
                top: 0;
                left: -12px;
                margin-right: 8px;
              }
            }

            .footer {
              .name-and-job-description {
                font-size: 0.875rem;
              }

              .logo {
                display: flex;
                padding-top: 16px;
                height: 38px;
                :global(img) {
                  max-height: 38px;
                }
                &.hidden {
                  visibility: hidden;
                }
              }
            }
          }
        }
      }
      .indicators {
        display: flex;
        margin-top: 24px;
        justify-content: center;
        align-items: center;
        height: ${INDICATOR_SIZE_ON_HOVER_STRING};
        .indicator-wrapper {
          padding: ${INDICATORS_PADDING_STRING};
          cursor: pointer;
          transition: padding 0.2s ease-in-out;
          .indicator {
            border: 1px solid ${asphalt};
            width: ${INDICATOR_SIZE_STRING};
            height: ${INDICATOR_SIZE_STRING};
            border-radius: 8px;
            transition: width 0.2s ease-in-out, height 0.2s ease-in-out;
            &.selected {
              border: ${`1px solid ${colors[WORK_OS_IRIS_COLOR_NAME]}`};
              background: ${colors[WORK_OS_IRIS_COLOR_NAME]};
            }
          }
          &:hover {
            padding: ${INDICATORS_PADDING_ON_HOVER_STRING};
            .indicator {
              width: ${INDICATOR_SIZE_ON_HOVER_STRING};
              height: ${INDICATOR_SIZE_ON_HOVER_STRING};
            }
          }
          &:hover:focus {
            outline: none;
          }
        }
      }
    }
    .reviews-carousel-asset {
      position: absolute;
      bottom: 0;
      right: 0;
      width: ${`${IMAGE_WIDTH}px`};
      height: ${`${IMAGE_WIDTH}px`};
      border-radius: 16px;
      transition: opacity 0.2s ease-in-out;
      :global(img) {
        max-width: ${`${IMAGE_WIDTH}px`};
        max-height: ${`${IMAGE_WIDTH}px`};
        height: auto;
        width: auto;
        margin: 0 auto;
        border-radius: 16px;
      }
      &.hidden {
        visibility: hidden;
      }
    }
  }
`;
