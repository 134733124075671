import dynamic from "next/dynamic";

const PlainClassicTestimonialsComponent = require("./plain-classic-testimonials-component").default;

import PlainClassicTestimonialsConfig from "./plain-classic-testimonials-config";
import PlainClassicTestimonialsAdvancedConfig from "./plain-classic-testimonials-advanced-config";

export default {
  PlainClassicTestimonialsComponent,
  PlainClassicTestimonialsConfig,
  PlainClassicTestimonialsAdvancedConfig
};
