import { PureComponent } from "react";
import { componentStyles } from "./embedded-board-iframe-component.scss";
import EmbeddedBoard from "segments/desktop/core-components/embedded-board/embedded-board";
import EmbeddedBoardDefaultProps from "segments/desktop/core-components/embedded-board/default-props";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";

export default class EmbeddedBoardIframeComponent extends PureComponent {
  render() {
    const { fullParagraph, embeddedBoardHeight, embeddedBoardWidth, ...restProps } = this.props;

    return (
      <div className="embedded-board-iframe-component">
        <div className="full-paragraph-wrapper">
          <FullParagraph {...fullParagraph} />
        </div>

        <div className="embedded-board-wrapper" style={{ height: embeddedBoardHeight, width: embeddedBoardWidth }}>
          <EmbeddedBoard {...restProps} />
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

EmbeddedBoardIframeComponent.defaultProps = {
  ...EmbeddedBoardDefaultProps,
  fullParagraph: {
    ...FullParagraph.defaultProps,
    title: "Get started with a board",
    body:
      "Drag and drop items, choose a status, add columns and items - everything is fully customizable to fit your workflow",
    textAlign: "center",
    topic: {}
  },
  embeddedBoardHeight: "500px",
  embeddedBoardWidth: "80vw",
  paddingBottom: "64px"
};
