import { memo, useMemo } from "react";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import { ALIGN_TYPES } from "constants/align-types";
import { lineDistance } from "utils/math";
import UniverseStars from "segments/desktop/core-components/universe-stars/universe-stars";
import classnames from "classnames";
import { SPACE_BLUE_GRADIANT_THEME_ID } from "segments/segments-service";
import SquareTagsGridComponent from "segments/desktop/grid/layouts/square-tags-grid/square-tags-grid-component";
import { useCompanyData } from "client/services/enrichment/company-data";
import { isBigCompany } from "client/services/enrichment/clearbit-selectors";
import { WHITE_COLOR_NAME } from "styles/color-consts";
import { CONTACT_SALES_FROM_TAGS_PAGE_LINK } from "constants/links";
import { shouldSeeEnrichmentHomepageSalesCtaAbTest } from "services/ab-tests/acquisition-ab-test-service";
import { withAbTests } from "contexts/ab-tests-context";
import PALETTE_SCHEMES from "segments/desktop/homepage-first-fold/layouts/monday-universe-homepage-first-fold/palette-schemes";
import Title from "segments/desktop/core-components/title/title";
import DefaultProps from "./default-props";
import { componentStyles } from "./monday-universe-homepage-first-fold-component.scss";
import { stars } from "./stars-clusters-config";

const Stars = memo(() => {
  return <UniverseStars stars={stars} />;
});

const MondayUniverseHomepageFirstFoldComponent = (props) => {
  const companyData = useCompanyData();

  const {
    fullParagraph,
    subTitle,
    subTitleAdvancedConfig,
    fullParagraphAdvancedConfig,
    buttonConfig,
    clusters,
    theme,
    abTests,
    translate,
    tagsWidth,
    pallet,
    squareTagsMarginTop
  } = props;

  const paletteScheme = useMemo(() => PALETTE_SCHEMES[pallet] || {}, [pallet]);

  const renderLines = () => {
    const { lines } = props;
    return lines.map((line, index) => {
      const lineStart = {
        top: parseInt(line?.start?.top),
        left: parseInt(line?.start?.left)
      };
      const lineEnd = {
        top: parseInt(line?.end?.top),
        left: parseInt(line?.end?.left)
      };

      if (
        !Number.isInteger(lineStart.top) ||
        !Number.isInteger(lineStart.left) ||
        !Number.isInteger(lineEnd.top) ||
        !Number.isInteger(lineEnd.left)
      ) {
        return null;
      }

      const angle = (Math.atan2(lineEnd.top - lineStart.top, lineEnd.left - lineStart.left) * 180) / Math.PI;
      const distance = lineDistance(lineStart.left, lineStart.top, lineEnd.left, lineEnd.top);

      return (
        <div
          key={`comet-${index}`}
          className={classnames("comet-line", { underline: true })}
          style={{ transform: `rotate(${angle}deg)`, width: distance, ...lineStart }}
        />
      );
    });
  };

  const isUniverseTheme = theme === SPACE_BLUE_GRADIANT_THEME_ID;

  const shouldShowSecondaryButton = useMemo(() => {
    return isBigCompany(companyData) && shouldSeeEnrichmentHomepageSalesCtaAbTest(abTests, true);
  }, [companyData, abTests]);

  const squareTagsProps = {
    tagsWidth,
    buttonConfig,
    clusters,
    theme,
    secondaryButtonConfig: shouldShowSecondaryButton
      ? {
          buttonText: translate("signup.talkToSales"),
          color: WHITE_COLOR_NAME,
          url: CONTACT_SALES_FROM_TAGS_PAGE_LINK,
          isOpenLinkInNewTab: true
        }
      : null,
    marginTop: squareTagsMarginTop
  };

  return (
    <div
      className={classnames("monday-universe-homepage-first-fold-component", {
        "selectable-clusters": true,
        "universe-theme": isUniverseTheme
      })}
    >
      {isUniverseTheme && (
        <div className="stars-canvas">
          {renderLines()}
          <Stars />
        </div>
      )}
      <div className={classnames("paragraph-wrapper")}>
        <FullParagraph
          {...fullParagraphAdvancedConfig}
          {...fullParagraph}
          topic={{}}
          textAlign={ALIGN_TYPES.CENTER}
          titleClassName="universe-title"
        />
        {subTitle?.title && <Title {...subTitle} {...subTitleAdvancedConfig} titleClassName="sub-title" />}
      </div>
      <SquareTagsGridComponent palette={paletteScheme} {...squareTagsProps} />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

const MondayUniverseHomepageFirstFoldComponentWithAbTests = withAbTests(MondayUniverseHomepageFirstFoldComponent);
MondayUniverseHomepageFirstFoldComponentWithAbTests.defaultProps = DefaultProps;

export default MondayUniverseHomepageFirstFoldComponentWithAbTests;
