import { PureComponent } from "react";
import IframeComponent from "segments/desktop/core-components/iframe/iframe-component";
import iframeDefaultProps from "segments/desktop/core-components/iframe/default-props";
import { componentStyles } from "./iframe-dynamic-width-iframe-component.scss";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";

export default class IframeDynamicWidthIframeComponent extends PureComponent {
  render() {
    const { width, maxWidth } = this.props;

    return (
      <div className="iframe-dynamic-width-iframe-component" style={{ width, maxWidth }}>
        <IframeComponent {...this.props} />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

IframeDynamicWidthIframeComponent.defaultProps = {
  width: "100%",
  ...iframeDefaultProps,
  maxWidth: `${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`
};
