import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";

export const componentStyles = css`
  .full-background-hero-component {
    min-height: 650px;
    height: 0px;
    display: flex;
    flex-direction: column;
  }
`;
