import Layouts from "./layouts";
const {
  // plop_marker:segment_index_import_layout
  ReviewCardsReviews,
  ParagraphAndCarouselReviews,
  RegularReviews,
  WithTitleReviews
} = Layouts;

export default {
  // plop_marker:segment_index_add_layout
  ReviewCardsReviewsComponent: {
    type: "ReviewCardsReviewsComponent",
    title: "Review Cards",
    imgSrc: "/static/img/segments/layouts/review-cards-reviews.png",
    config: ReviewCardsReviews.ReviewCardsReviewsConfig,
    advancedConfig: ReviewCardsReviews.ReviewCardsReviewsAdvancedConfig,
    component: ReviewCardsReviews.ReviewCardsReviewsComponent
  },
  ParagraphAndCarouselReviewsComponent: {
    type: "ParagraphAndCarouselReviewsComponent",
    title: "Paragraph And Carousel",
    imgSrc: "/static/img/segments/layouts/paragraph-and-carousel-reviews.png",
    config: ParagraphAndCarouselReviews.ParagraphAndCarouselReviewsConfig,
    advancedConfig: ParagraphAndCarouselReviews.ParagraphAndCarouselReviewsAdvancedConfig,
    component: ParagraphAndCarouselReviews.ParagraphAndCarouselReviewsComponent
  },
  RegularReviewsComponent: {
    type: "RegularReviewsComponent",
    title: "Regular",
    imgSrc: "/static/img/segments/layouts/regular-reviews.png",
    config: RegularReviews.RegularReviewsConfig,
    advancedConfig: RegularReviews.RegularReviewsAdvancedConfig,
    component: RegularReviews.RegularReviewsComponent
  },
  WithTitleReviewsComponent: {
    type: "WithTitleReviewsComponent",
    title: "With Title",
    imgSrc: "/static/img/segments/layouts/with-title-reviews.png",
    config: WithTitleReviews.WithTitleReviewsConfig,
    advancedConfig: WithTitleReviews.WithTitleReviewsAdvancedConfig,
    component: WithTitleReviews.WithTitleReviewsComponent
  }
};
