import { FIELD_TYPES } from "/constants/template-field-type";
import SideBySideTextAndAssetFieldsConfig from "segments/desktop/core-components/side-by-side-text-and-asset/fields-config";

const { background } = SideBySideTextAndAssetFieldsConfig;

export default {
  alignToLogo: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Align to logo (New)"
  },
  leftSizeWidth: {
    type: FIELD_TYPES.TEXT,
    label: "Override left side size"
  },
  rightSizeWidth: {
    type: FIELD_TYPES.TEXT,
    label: "Override right side size"
  },
  spaceBetweenTextAndAsset: {
    type: FIELD_TYPES.TEXT,
    label: "Horizontal space between 2 sides (desktop)"
  },
  mobileSpaceBetweenTextAndAsset: {
    type: FIELD_TYPES.TEXT,
    label: "Vertical space between 2 sides (tablet + mobile)"
  },
  background
};
