import dynamic from "next/dynamic";

const ImagesCarouselWithTagsGridAssetComponent = require("./images-carousel-with-tags-grid-asset-component").default;

import ImagesCarouselWithTagsGridAssetConfig from "./images-carousel-with-tags-grid-asset-config";
import ImagesCarouselWithTagsGridAssetAdvancedConfig from "./images-carousel-with-tags-grid-asset-advanced-config";

export default {
  ImagesCarouselWithTagsGridAssetComponent,
  ImagesCarouselWithTagsGridAssetConfig,
  ImagesCarouselWithTagsGridAssetAdvancedConfig
};
