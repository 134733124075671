import { FIELD_TYPES } from "constants/template-field-type";
import assetFieldsConfig from "components/core/asset/fields-config";

export default {
  autoplay: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Autoplay"
  },
  autoplaySpeed: {
    type: FIELD_TYPES.TEXT,
    label: "Autoplay speed (in miliseconds)"
  },
  infinite: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Slide inifinitely"
  },
  slidesToShow: {
    type: FIELD_TYPES.TEXT,
    label: "Slides to show"
  },
  easing: {
    type: FIELD_TYPES.COMBO,
    label: "Animation ease function",
    options: [
      { value: "cubic", text: "Cubic" },
      { value: "linear", text: "Linear" },
      { value: "ease", text: "Ease" },
      { value: "ease-in", text: "Ease in" },
      { value: "ease-out", text: "Ease out" },
      { value: "ease-in-out", text: "Ease in out" },
    ]
  },
  defaultImage: {
    type: FIELD_TYPES.NESTED,
    label: "Default image",
    fields: assetFieldsConfig.image.fields
  },
  slides: {
    type: FIELD_TYPES.MULTIPLE_NESTED,
    label: "Slides",
    nestedConfig: {
      type: FIELD_TYPES.GALLERY,
      fields: {
        asset: {
          type: FIELD_TYPES.NESTED,
          label: "Asset",
          fields: assetFieldsConfig
        }
      }
    },
  }
};