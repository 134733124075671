import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import Constants from "segments/desktop/grid/layouts/blog-cards-grid/constants";

const CARD_WIDTH = `${Constants.CARD_WIDTH}px`;

export const componentStyles = css.global`
  .blog-cards-grid-component {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    height: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .blog-card-item-component-wrapper {
      width: ${CARD_WIDTH};
      margin: 16px;
      flex-shrink: 0;
    }
  }

  @media (max-width: 422px) {
    .blog-cards-grid-component {
      .blog-card-item-component-wrapper {
        width: 300px;
      }
    }
  }

  @media (max-width: 332px) {
    .blog-cards-grid-component {
      .blog-card-item-component-wrapper {
        width: 280px;
      }
    }
  }
`;
