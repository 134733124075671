export default [
  // Moving Anthoni
  {
    type: "owner",
    offset: 100,
    duration: 0,
    pictureSrc: "/static/img/first-fold/owner_1.png",
    classToggle: "owner-first-item-end",
    className: "owner-first-item-start"
  },

  // Moving Taltalim
  {
    type: "owner",
    offset: 75,
    duration: 0,
    pictureSrc: "/static/img/first-fold/owner_4.png",
    classToggle: "owner-second-item-end",
    className: "owner-second-item-start"
  },

  // Moving Ron
  {
    type: "owner",
    offset: 100,
    duration: 0,
    pictureSrc: "/static/img/first-fold/owner_5.png",
    classToggle: "owner-third-item-end",
    className: "owner-third-item-start"
  },

  // Moving Graham
  {
    type: "owner",
    offset: 50,
    duration: 0,
    pictureSrc: "/static/img/first-fold/owner_6.png",
    classToggle: "owner-fourth-item-end",
    className: "owner-fourth-item-start"
  },

  // Moving Iris
  {
    type: "owner",
    offset: 125,
    duration: 0,
    pictureSrc: "/static/img/first-fold/owner_3.png",
    classToggle: "owner-fifth-item-end",
    className: "owner-fifth-item-start"
  },

  // Moving Julia
  {
    type: "owner",
    offset: 150,
    duration: 0,
    pictureSrc: "/static/img/first-fold/owner_2.png",
    classToggle: "owner-sixth-item-end",
    className: "owner-sixth-item-start"
  },

  {
    type: "owner",
    offset: 200,
    duration: 0,
    pictureSrc: "/static/img/first-fold/owner_1.png",
    classToggle: "fade-in",
    className: "owner-seventh-item-start"
  },

  {
    type: "owner",
    offset: 200,
    duration: 0,
    pictureSrc: "/static/img/first-fold/owner_4.png",
    classToggle: "fade-in",
    className: "owner-eighth-item-start"
  },

  // Chrystal
  {
    type: "owner",
    offset: 300,
    duration: 0,
    pictureSrc: "/static/img/first-fold/owner_7.png",
    classToggle: "fade-in",
    className: "owner-ninth-item-start"
  },

  {
    type: "owner",
    offset: 100,
    duration: 0,
    pictureSrc: "/static/img/first-fold/owner_6.png",
    classToggle: "fade-in",
    className: "owner-tenth-item-start"
  },

  {
    type: "owner",
    offset: 200,
    duration: 0,
    pictureSrc: "/static/img/first-fold/owner_5.png",
    classToggle: "fade-in",
    className: "owner-eleventh-item-start"
  },

  {
    type: "owner",
    offset: 300,
    duration: 0,
    pictureSrc: "/static/img/first-fold/owner_3.png",
    classToggle: "fade-in",
    className: "owner-twelfth-item-start"
  }
];
