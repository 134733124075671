import FullBackgroundTextAndAsset from "segments/desktop/core-components/full-background-text-and-asset/full-background-text-and-asset";
import FullBackgroundTextAndAssetDefaultProps from "segments/desktop/core-components/full-background-text-and-asset/default-props";
import { MD } from "constants/sizes";
import classnames from "classnames";
import { componentStyles } from "./full-background-text-and-asset-component.scss";

const FullBackgroundTextAndAssetComponent = (props) => {
  const { height, alignToLogo } = props;

  return (
    <div
      className={classnames("full-background-text-and-asset-component", { "align-to-logo": alignToLogo })}
      style={{ height }}
    >
      <FullBackgroundTextAndAsset {...props} />

      <style jsx>{componentStyles}</style>
    </div>
  );
};

FullBackgroundTextAndAssetComponent.defaultProps = {
  ...FullBackgroundTextAndAssetDefaultProps,
  titleSize: MD,
  textSize: MD,
  height: "650px",
  alignToLogo: true
};

export default FullBackgroundTextAndAssetComponent;
