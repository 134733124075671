import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import Colors from "styles/colors";
import { IMAGE_WIDTH, IMAGE_HEIGHT } from "../../constants";

const IMAGE_WIDTH_PX = `${IMAGE_WIDTH}px`;
const IMAGE_HEIGHT_PX = `${IMAGE_HEIGHT}px`;

const INITIAL_VISIBLE_TABLE_HEIGHT = 275;

export const componentStyles = css.global`
  .animating-board-homepage-first-fold-mobile-component {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    margin: auto;
    .table-picture-component {
      height: ${IMAGE_HEIGHT_PX};
      width: ${IMAGE_WIDTH_PX};
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
    }

    .folding-table-container {
      width: ${IMAGE_WIDTH_PX};
    }

    .header-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: calc(60vh - ${INITIAL_VISIBLE_TABLE_HEIGHT}px);
      min-height: 500px;

      .first-title {
        font-size: 4rem;
        color: ${Colors["black"]};
        font-weight: 700;
        text-align: center;
        letter-spacing: -1px;
        transform: translateY(-24px);
      }

      .second-title {
        font-size: 1.375rem;
        color: ${Colors["text-gray"]};
        max-width: 600px;
        text-align: center;
        margin-top: 16px;
        line-height: 32px;
        transform: translateY(-24px);
      }

      .first-fold-signup-button {
        margin-top: 32px;
        transform: translateY(-24px);
      }
    }

    .video-part {
      position: relative;
      margin: 0 16px;
      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        .play-button-container {
          background-color: #2f3ac5;
        }
      }

      .poster-image-wrapper {
        display: flex;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .play-button-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background-color: ${Colors["dodger-blue"]};
        box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
        pointer-events: none;

        .play-button {
          box-sizing: border-box;
          border-color: transparent transparent transparent white;
          border-style: solid;
          border-width: 12px 0 12px 20px;
          position: absolute;
          left: calc(50% + 4px);
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    .youtube-video-overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 555;
      background: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .play-button {
        height: 128px;
        width: 128px;
      }
      .youtube-picture {
        width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
      }
    }
  }
`;
