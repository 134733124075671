import css from "styled-jsx/css";
import colors from "styles/colors";
import Constants from "segments/desktop/grid/layouts/blog-cards-grid/constants";

const WRITER_AVATAR_IMAGE_HEIGHT = `${Constants.WRITER_AVATAR_IMAGE_HEIGHT}px`;
const WRITER_AVATAR_IMAGE_WIDTH = `${Constants.WRITER_AVATAR_IMAGE_WIDTH}px`;

export const componentStyles = css.global`
  .blog-card-item-footer-component {
    display: flex;
    align-items: center;

    .picture-component-wrapper {
      height: ${WRITER_AVATAR_IMAGE_HEIGHT};
      width: ${WRITER_AVATAR_IMAGE_WIDTH};
      border-radius: 50%;
      overflow: hidden;
      flex-shrink: 0;
    }

    .blog-footer-details {
      display: flex;
      flex-direction: column;
      color: ${colors.jaco};
      font-size: 0.8125rem;
      margin-left: 16px;
      flex-grow: 1;

      .writer-name {
      }

      .more-details {
        display: flex;
        align-items: center;
        margin-top: 8px;

        .article-date {
        }

        .seperated-dot {
          height: 3px;
          width: 3px;
          background-color: ${colors.jaco};
          border-radius: 50%;
          margin: 0px 8px;
        }

        .minutes-to-read {
        }
      }
    }

    .social-links {
      flex-shrink: 0;
      align-self: flex-end;
      display: flex;
      opacity: 0;
      transition: opacity 300ms ease;
      display: none;

      &.show {
        opacity: 1;
      }

      .social-icon-link-component-wrapper {
        transform: translateY(7px);

        &:not(:last-child) {
          margin-right: 4px;
        }
      }
    }
  }

  @media (max-width: 422px) {
    .blog-card-item-footer-component {
      .social-links {
        display: none;
      }
    }
  }
`;
