import classnames from "classnames";
import SideBySideTextAndAsset from "segments/desktop/core-components/side-by-side-text-and-asset/side-by-side-text-and-asset";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import { componentStyles } from "./two-paragraphs-paragraph-component.scss";

const TwoParagraphsParagraphComponent = (props) => {
  const {
    primaryParagraph,
    secondaryParagraph,
    alignToLogo,
    primaryParagraphAdvancedProps,
    secondaryParagraphAdvancedProps,
    ...restProps
  } = props;
  console.log(primaryParagraph);
  return (
    <div className={classnames("two-paragraphs-paragraph-component", { "align-to-logo": alignToLogo })}>
      <SideBySideTextAndAsset
        fullParagraph={{ ...primaryParagraph }}
        replaceAssetComponent={FullParagraph}
        replaceAssetComponentProps={{ ...secondaryParagraph, ...secondaryParagraphAdvancedProps }}
        asset={{ position: props.paragraphVerticalAlignment }}
        {...restProps}
        {...primaryParagraphAdvancedProps }
      />
      <style jsx>{componentStyles}</style>
    </div>
  );
};
export default TwoParagraphsParagraphComponent;
