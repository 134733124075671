import PictureComponent from "components/core/picture/picture-component";
import { componentStyles } from "./review-card-reviews-component.scss";

const ReviewCardComponent = ({ reviewCardProps }) => {
  const { image, subtitle, title } = reviewCardProps;
  return (
    <div className="card">
      <div className="top-image">
        <PictureComponent {...image} />
      </div>
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default ReviewCardComponent;