import {
  BRAND_AND_WORK_MANAGEMENT_DARK_TINT_01,
  BRAND_AND_WORK_MANAGEMENT_DARKER_TINT_02,
  BRAND_AND_WORK_MANAGEMENT_LIGHT_TINT_02,
  BRAND_BLACK_BACKGROUND_COLOR,
  BRAND_DARK_COLOR,
  BRAND_WORK_MANAGEMENT_PRIMARY_COLOR,
  CRM_DARK_TINT_01,
  CRM_DARKER_TINT_02,
  CRM_LIGHTER_TINT_01,
  CRM_PRIMARY_COLOR,
  PROJECTS_DARK_TINT_01,
  PROJECTS_LIGHTER_TINT_01,
  PROJECTS_PRIMARY_COLOR,
  UI_BORDER_LIGHT_COLOR_NAME,
  WHITE_COLOR_NAME,
  WORK_OS_IRIS_COLOR_NAME
} from "styles/color-consts";

export const PALLET_VALUES = {
  CRM_GREEN: "CRM_GREEN",
  DEV_BLACK: "DEV_BLACK",
  WM_PURPLE: "WM_PURPLE",
  CRM_WHITE: "CRM_WHITE",
  DEV_WHITE: "DEV_WHITE",
  WM_WHITE: "WM_WHITE",
  WM_BLACK: "WM_BLACK"
};

export default {
  [PALLET_VALUES.CRM_GREEN]: {
    tagScheme: {
      hoverColor: CRM_DARK_TINT_01,
      borderColor: CRM_PRIMARY_COLOR,
      selectedBackgroundColor: CRM_PRIMARY_COLOR
    },
    checkboxScheme: {
      selectedBorderColor: WHITE_COLOR_NAME,
      checkColor: WHITE_COLOR_NAME,
      selectedBackgroundColor: CRM_PRIMARY_COLOR
    },
    textScheme: {
      hoverColor: WHITE_COLOR_NAME,
      selectedColor: WHITE_COLOR_NAME,
      color: WHITE_COLOR_NAME
    }
  },
  [PALLET_VALUES.DEV_BLACK]: {
    tagScheme: {
      hoverColor: BRAND_DARK_COLOR,
      borderColor: PROJECTS_PRIMARY_COLOR,
      selectedBackgroundColor: PROJECTS_DARK_TINT_01
    },
    checkboxScheme: {
      selectedBorderColor: WHITE_COLOR_NAME,
      checkColor: WHITE_COLOR_NAME,
      selectedBackgroundColor: PROJECTS_DARK_TINT_01
    },
    textScheme: {
      hoverColor: WHITE_COLOR_NAME,
      selectedColor: WHITE_COLOR_NAME,
      color: WHITE_COLOR_NAME
    }
  },
  [PALLET_VALUES.WM_PURPLE]: {
    tagScheme: {
      hoverColor: BRAND_AND_WORK_MANAGEMENT_DARK_TINT_01,
      borderColor: BRAND_AND_WORK_MANAGEMENT_LIGHT_TINT_02,
      selectedBackgroundColor: WORK_OS_IRIS_COLOR_NAME
    },
    checkboxScheme: {
      selectedBorderColor: WHITE_COLOR_NAME,
      checkColor: WHITE_COLOR_NAME,
      selectedBackgroundColor: WORK_OS_IRIS_COLOR_NAME
    },
    textScheme: {
      hoverColor: WHITE_COLOR_NAME,
      selectedColor: WHITE_COLOR_NAME,
      color: WHITE_COLOR_NAME
    }
  },
  [PALLET_VALUES.WM_WHITE]: {
    tagScheme: {
      hoverColor: BRAND_AND_WORK_MANAGEMENT_LIGHT_TINT_02,
      borderColor: BRAND_AND_WORK_MANAGEMENT_DARK_TINT_01,
      selectedBackgroundColor: BRAND_WORK_MANAGEMENT_PRIMARY_COLOR
    },
    checkboxScheme: {
      selectedBorderColor: WHITE_COLOR_NAME,
      checkColor: BRAND_WORK_MANAGEMENT_PRIMARY_COLOR,
      selectedBackgroundColor: WHITE_COLOR_NAME
    },
    textScheme: {
      hoverColor: BRAND_BLACK_BACKGROUND_COLOR,
      selectedColor: WHITE_COLOR_NAME,
      color: BRAND_BLACK_BACKGROUND_COLOR
    }
  },
  [PALLET_VALUES.CRM_WHITE]: {
    tagScheme: {
      hoverColor: CRM_LIGHTER_TINT_01,
      borderColor: CRM_DARK_TINT_01,
      selectedBackgroundColor: CRM_PRIMARY_COLOR
    },
    checkboxScheme: {
      selectedBorderColor: WHITE_COLOR_NAME,
      checkColor: CRM_DARKER_TINT_02,
      selectedBackgroundColor: WHITE_COLOR_NAME
    },
    textScheme: {
      hoverColor: BRAND_BLACK_BACKGROUND_COLOR,
      selectedColor: BRAND_BLACK_BACKGROUND_COLOR,
      color: BRAND_BLACK_BACKGROUND_COLOR
    }
  },
  [PALLET_VALUES.DEV_WHITE]: {
    tagScheme: {
      hoverColor: PROJECTS_LIGHTER_TINT_01,
      borderColor: PROJECTS_PRIMARY_COLOR,
      selectedBackgroundColor: PROJECTS_DARK_TINT_01
    },
    checkboxScheme: {
      selectedBorderColor: WHITE_COLOR_NAME,
      checkColor: PROJECTS_DARK_TINT_01,
      selectedBackgroundColor: WHITE_COLOR_NAME
    },
    textScheme: {
      hoverColor: BRAND_BLACK_BACKGROUND_COLOR,
      selectedColor: WHITE_COLOR_NAME,
      color: BRAND_BLACK_BACKGROUND_COLOR
    }
  },
  [PALLET_VALUES.WM_BLACK]: {
    tagScheme: {
      hoverColor: BRAND_AND_WORK_MANAGEMENT_DARKER_TINT_02,
      borderColor: UI_BORDER_LIGHT_COLOR_NAME,
      selectedBackgroundColor: BRAND_WORK_MANAGEMENT_PRIMARY_COLOR
    },
    checkboxScheme: {
      selectedBorderColor: WHITE_COLOR_NAME,
      checkColor: WHITE_COLOR_NAME,
      selectedBackgroundColor: BRAND_WORK_MANAGEMENT_PRIMARY_COLOR
    },
    textScheme: {
      hoverColor: WHITE_COLOR_NAME,
      selectedColor: WHITE_COLOR_NAME,
      color: WHITE_COLOR_NAME
    }
  }
};
