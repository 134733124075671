import { useState, useEffect, useMemo } from "react";
import SignUpProductCard from "segments/desktop/core-components/sign-up-product-card/sign-up-product-card";
import SignupButton from "segments/desktop/core-components/button/signup-button/signup-button";
import { BRAND_BLACK_TEXT_COLOR } from "styles/color-consts";
import { PRODUCTS_ID_TO_THEME, PRODUCT_IDS, WORK_MANAGEMENT_PRODUCT_ID } from "constants/products";
import classNames from "classnames";
import Title from "segments/desktop/core-components/title/title";
import Paragraph from "segments/desktop/core-components/paragraph/paragraph";
import { trackEvent } from "/services/bigbrain-service";
import {
  SKIP_BUTTON_CLICKED,
  CONTINUE_BUTTON_CLICKED,
  SIGN_UP_WITH_PRODUCTS_LOADED
} from "/constants/bigbrain-event-types";
import { getUrlParamValue } from "utils/url";
import { withRouter } from "next/router";
import { PRODUCT_ID_PARAM_NAME } from "constants/links";
import * as Sentry from "@sentry/nextjs";
import { organizeCards } from "./consts";
import DefaultProps from "./default-props";
import { componentStyles } from "./sign-up-with-products-cards-homepage-first-fold-component.scss";

const SignUpWithProductsCardsHomepageFirstFoldComponent = (props) => {
  const {
    skipButtonText,
    continueButtonText,
    titleWithProduct,
    titleWithoutProduct,
    subTitle,
    textUnderButtons,
    router
  } = props;

  const productFromUrl = getUrlParamValue(router?.asPath, PRODUCT_ID_PARAM_NAME);
  const [recommendedProduct, title, isLoadedProduct] = PRODUCT_IDS.includes(productFromUrl)
    ? [productFromUrl, titleWithProduct, true]
    : [WORK_MANAGEMENT_PRODUCT_ID, titleWithoutProduct, false];
  if (productFromUrl && !PRODUCT_IDS.includes(productFromUrl)) {
    Sentry.captureMessage(`Sign up product id from url: ${productFromUrl} is not valid`);
  }
  const [activeProductId, setActiveproductId] = useState(recommendedProduct);
  const [productsIds] = useState(useMemo(() => organizeCards(recommendedProduct), [recommendedProduct]));
  const buttonColor = PRODUCTS_ID_TO_THEME[activeProductId];

  const skipButtonPressed = () => {
    trackEvent(SKIP_BUTTON_CLICKED, {
      kind: activeProductId
    });
  };

  const continueButtonPressed = () => {
    trackEvent(CONTINUE_BUTTON_CLICKED, {
      kind: activeProductId
    });
  };

  useEffect(() => {
    trackEvent(SIGN_UP_WITH_PRODUCTS_LOADED, {
      kind: recommendedProduct,
      info1: isLoadedProduct
    });
  }, [recommendedProduct, isLoadedProduct]);

  return (
    <div className={classNames("sign-up-with-products-cards-homepage-first-fold-component")}>
      <div className="title-wrapper">
        <Title title={title} />
        <Paragraph body={subTitle} />
      </div>
      <div className="cards-wrapper" role="radiogroup">
        {productsIds?.map((productId, index) => (
          <SignUpProductCard
            productId={productId}
            isActive={productId === activeProductId}
            setActiveproductId={setActiveproductId}
            index={index}
          />
        ))}
      </div>
      <div className="buttons-wrapper">
        <SignupButton
          buttonColor={BRAND_BLACK_TEXT_COLOR}
          invert={true}
          useCtaText={true}
          ctaText={skipButtonText}
          beforeSubmitCallback={skipButtonPressed}
        />
        <SignupButton
          useCtaText={true}
          buttonColor={buttonColor}
          ctaText={continueButtonText}
          signupProductId={activeProductId}
          forceProductSignup={true}
          beforeSubmitCallback={continueButtonPressed}
        />
      </div>
      <div className="text-under-buttons">
        <Paragraph body={textUnderButtons} />
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default withRouter(SignUpWithProductsCardsHomepageFirstFoldComponent);

SignUpWithProductsCardsHomepageFirstFoldComponent.defaultProps = DefaultProps;
