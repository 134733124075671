import { PureComponent } from "react";
import { componentStyles } from "./vertical-tabs-mobile-component.scss";
import SideBySideTextAndAsset from "segments/desktop/core-components/side-by-side-text-and-asset/side-by-side-text-and-asset";
import Title from "segments/desktop/core-components/title/title";
import Colors from "styles/colors";
import { XS } from "constants/sizes";

export default class VerticalTabsMobileComponent extends PureComponent {
  renderTabs() {
    const { tabs, tabsColor } = this.props;

    return tabs.map((tab, index) => {
      const { tabLabel, textAndAsset } = tab;
      const color = Colors[tabsColor];

      return (
        <div key={index} className="tab-wrapper">
          <div className="tab-label">
            <span className="line" style={{ backgroundColor: color }} />
            <span className="text" style={{ color }}>
              {tabLabel}
            </span>
            <span className="line" style={{ backgroundColor: color }} />
          </div>

          <div className="tab-content">
            <SideBySideTextAndAsset {...textAndAsset} titleSize={XS} />
          </div>
        </div>
      );
    });
  }

  render() {
    const { title, titleSize } = this.props;

    return (
      <div className="vertical-tabs-mobile-component">
        <div className="vertical-tabs-mobile-title-wrapper">
          <Title {...title} titleSize={titleSize} />
        </div>

        {this.renderTabs()}

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
