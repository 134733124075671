export default [
  // PDF
  {
    type: "file",
    offset: 50,
    duration: 0,
    pictureSrc: "/static/img/first-fold/file_4.png",
    classToggle: "file-first-item-end",
    className: "file-first-item-start"
  },

  // WORD
  {
    type: "file",
    offset: 150,
    duration: 0,
    pictureSrc: "/static/img/first-fold/file_1.png",
    classToggle: "file-second-item-end",
    className: "file-second-item-start"
  },

  // PNG
  {
    type: "file",
    offset: 200,
    duration: 0,
    pictureSrc: "/static/img/first-fold/file_5.png",
    classToggle: "file-third-item-end",
    className: "file-third-item-start"
  }
];
