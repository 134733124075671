import classnames from "classnames";
import AssetComponent from "components/core/asset/asset-component";
import { useMemo } from "react";
import { componentStyles } from "./monday-demo-tabs-assets.scss";

const MondayDemoTabsAsset = (props) => {
  const { asset, onVideoEnded, setVideoDuration, isCurrent, autoPlay } = props;
  const assetWithAutoplay = useMemo(
    () => ({
      ...asset,
      video: {
        ...asset.video,
        autoPlay,
        playWhenVisible: false
      }
    }),
    [asset, autoPlay]
  );

  const renderAssetComponent = () => (
    <AssetComponent {...assetWithAutoplay} onEnded={onVideoEnded} onDuration={setVideoDuration} hideControls={true} />
  );

  return (
    <div className={classnames("monday-demo-tabs-asset-wrapper", { current: isCurrent })}>
      {renderAssetComponent()}
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default MondayDemoTabsAsset;
