import { FIELD_TYPES } from "constants/template-field-type";
import buttonConfig from "segments/desktop/core-components/button/signup-button/fields-config.js";

const { signupWithsolutionAppReferenceId, signupClusterId, ...restSignupButtonConfig } = buttonConfig;

export default {
  theme: {
    type: FIELD_TYPES.COLOR_PICKER,
    label: "Theme"
  },
  clusters: {
    type: FIELD_TYPES.MULTIPLE_NESTED,
    label: "Clusters",
    maxLimit: 9,
    nestedConfig: {
      type: FIELD_TYPES.NESTED,
      fields: {
        text: { type: FIELD_TYPES.TEXT, label: "Text", translatable: true },
        image: { type: FIELD_TYPES.GALLERY, label: "Image" },
        colorName: { type: FIELD_TYPES.COLOR_PICKER, label: "Color" },
        clusterId: {
          type: FIELD_TYPES.CLUSTERS,
          label: "Cluster Id"
        },
        useCaseId: {
          type: FIELD_TYPES.SUB_CLUSTERS,
          label: "Usecase Id"
        },
        initiallySelected: { type: FIELD_TYPES.BOOLEAN, label: "Initially selected" }
      }
    }
  },
  buttonConfig: {
    type: FIELD_TYPES.NESTED,
    label: "Signup button",
    fields: restSignupButtonConfig
  }
};
