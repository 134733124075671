import VerticalTimerTabsDefaultProps from "segments/desktop/core-components/vertical-timer-tabs/default-props";

import { positionTypes } from "segments/desktop/core-components/images-grid/images-grid-service";

const getAsset = (videoName) => ({
    video: {
      loop: false,
      src: {
        formats: [`/static/video/capabilites_videos/${videoName}.mp4`]
      }
    }
});

export default {
    ...VerticalTimerTabsDefaultProps,
    tabs: [
      {
        title: "1. Collaborate",
        content: {
          topic: {},
          title: "Accurately estimate costs and increase profitability",
          body: "Use Time Tracking to bill clients more accurately. Gain more confidence in estimating future costs."
        },
        timer: 1000 * 10,
        asset: getAsset("flexibility")
      },
      {
        title: "2. Connect",
        content: {
          topic: {},
          title: "Accurately estimate costs and increase profitability",
          body: "Use Time Tracking to bill clients more accurately. Gain more confidence in estimating future costs."
        },
        timer: 1000 * 10,
        asset: getAsset("insight")
      },
      {
        title: "3. Execute",
        content: {
          topic: {},
          title: "Accurately estimate costs and increase profitability",
          body: "Use Time Tracking to bill clients more accurately. Gain more confidence in estimating future costs."
        },
        timer: 1000 * 10,
        asset: getAsset("data-viz")
      }
    ],
    backgroundColor: "transparent",
    progressbarColor: "dodger-blue",
    showArrow: true,
    assetPosition: positionTypes.CENTER_CENTER,
    showButton: false,
    buttonConfig: {},
    alwaysShowProgressbar: true,
    title: {
        fontColor: "silver",
        selectedFontColor: "dodger-blue",
        fontSize: "40px",
        selectedFontSize: "40px",
        fontWeight: "bold"
    },
};