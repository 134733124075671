import { PureComponent } from "react";
import classnames from "classnames";
import { generateOnKeyDownHandler } from "/client/services/accessibility/accessibility-service";
import { TAB_CLICKED } from "/constants/bigbrain-event-types";
import Colors from "styles/colors";
import { getComponentStyles } from "./tab.scss";
import DefaultProps from "./default-props";
import PictureComponent from "components/core/picture/picture-component";
import { themes } from "segments/segments-service";
import { WHITE_COLOR_NAME, BRAND_BLACK_BACKGROUND_COLOR } from "styles/color-consts";
export default class Tab extends PureComponent {
  constructor(props) {
    super(props);

    this.onTabClicked = this.onTabClicked.bind(this);
    this.onKeyDown = generateOnKeyDownHandler(this.tabClickedAction);
  }

  blurTab = (e) => {
    e?.target?.closest(".vertical-tab")?.blur();
  };

  onTabClicked(e) {
    this.blurTab(e);
    this.tabClickedAction();
  }

  tabClickedAction = () => {
    const { onClick, index, label } = this.props;
    BigBrain("track", TAB_CLICKED, {
      kind: label,
      info1: index
    });
    onClick(index);
  };

  rednerWithImage = () => {
    const { tabImage } = this.props;
    return (
      <div className="tab-image">
        <PictureComponent {...tabImage} />
      </div>
    );
  };

  rednerWithoutImage = () => {
    const { label, isActive, color, addTimeline, lineColor, isDarkTheme } = this.props;

    return (
      <>
        {addTimeline && (
          <hr
            className="vertical-tab-timeline"
            style={{
              borderColor: isActive ? Colors[color] : Colors[lineColor] || Colors[WHITE_COLOR_NAME]
            }}
          />
        )}
        <span
          className="tab-label"
          style={{
            color: isActive
              ? Colors[color]
              : isDarkTheme
              ? Colors[WHITE_COLOR_NAME]
              : Colors[BRAND_BLACK_BACKGROUND_COLOR]
          }}
        >
          {label}
        </span>
      </>
    );
  };

  render() {
    const { tabImage, isActive, color, theme } = this.props;
    const componentStyles = getComponentStyles(color);
    const leftBorderColor = theme ? themes[theme].background : "none";

    return (
      <div
        className={classnames("vertical-tab", { active: isActive, "with-image": tabImage })}
        onClick={this.onTabClicked}
        role={"tab"}
        aria-selected={isActive}
        tabIndex={0}
        onKeyDown={this.onKeyDown}
        style={{ "--border-color": leftBorderColor }}
      >
        {!tabImage && this.rednerWithoutImage()}
        {tabImage && this.rednerWithImage()}
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

Tab.defaultProps = DefaultProps;
