import { FIELD_TYPES } from "constants/template-field-type";
import ThemeConfig from "segments/core-configs/theme-config";
import SideBySideTextAndAssetFieldsConfig from "segments/desktop/core-components/side-by-side-text-and-asset/fields-config";
import TitleOnlyParagraphFieldsConfig from "segments/desktop/core-components/title-only-paragraph/fields-config";

const { background, ...selectedSideBySideTextAndAssetFieldsConfig } = SideBySideTextAndAssetFieldsConfig;
const { textColor, buttonConfig, textAlign, ...selectedTitleOnlyParagraphFields } = TitleOnlyParagraphFieldsConfig;

export default {
  ...ThemeConfig,
  title: {
    type: FIELD_TYPES.NESTED,
    label: "Title",
    fields: selectedTitleOnlyParagraphFields
  },
  backgroundColor: {
    type: FIELD_TYPES.COLOR_PICKER,
    label: "Background color"
  },
  tabsColor: {
    type: FIELD_TYPES.COLOR_PICKER,
    label: "Tabs color"
  },
  tabs: {
    type: FIELD_TYPES.MULTIPLE_NESTED,
    label: "Tabs",
    maxLimit: 8,
    nestedConfig: {
      type: FIELD_TYPES.NESTED,
      fields: {
        tabLabel: { type: FIELD_TYPES.TEXT, label: "Tab Label", translatable: true },
        textAndAsset: {
          type: FIELD_TYPES.NESTED,
          label: "Text and asset",
          fields: selectedSideBySideTextAndAssetFieldsConfig
        }
      }
    }
  }
};
