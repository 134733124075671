import DefaultProps from "./default-props";
import { componentStyles } from "./product-cards-grid-mobile-component.scss";
import ProductCardGridMobileComponent from "./product-card-grid-component/product-card-grid-mobile-component";

const ProductCardsGridMobileComponent = (props) => {
  return (
    <div className="product-cards-grid-mobile-component">
      {props?.cards?.map((card, index) => (
        <ProductCardGridMobileComponent {...card} index={index} />
      ))}
      <style jsx>{componentStyles}</style>
    </div>
  );
};

ProductCardsGridMobileComponent.defaultProps = DefaultProps;

export default ProductCardsGridMobileComponent;
