import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";

export default {
  background: {
    width: "100%",
    backgroundImage: {
      src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/enterprise/forrester_bg.png"
    }
  },
  primaryParagraph: {
    topic: {
      image: {
        src: "/Generator_featured images/enterprise/forrester_logo.png",
        height: "20px"
      }
    },
    title: "Transformation by the numbers",
    body: "Deep dive into The Total Economic Impact™ of monday.com Work OS for Motorola over three years.",
    buttonConfig: {
      buttonType: "Secondary",
      buttonText: "Get the full report",
      url: "https://monday.com/lp/forrester-tei-report-motorola",
      isOpenLinkInNewTab: true
    }
  },
  leftSectionBottomText: "",
  paragraphs: [
    {
      fullParagraph: {
        topic: {},
        title: "346%",
        body: "return on investment (ROI) over three years",
        paragraphVerticalColoredLineColor: WORK_OS_IRIS_COLOR_NAME,
        titleColor: WORK_OS_IRIS_COLOR_NAME
      }
    },
    {
      fullParagraph: {
        topic: {},
        title: "$127K",
        body: "increase in creative team productivity",
        paragraphVerticalColoredLineColor: WORK_OS_IRIS_COLOR_NAME,
        titleColor: WORK_OS_IRIS_COLOR_NAME
      }
    },
    {
      fullParagraph: {
        topic: {},
        title: "$279K",
        body: "net present value (NPV) over three years",
        paragraphVerticalColoredLineColor: WORK_OS_IRIS_COLOR_NAME,
        titleColor: WORK_OS_IRIS_COLOR_NAME
      }
    }
  ]
};
