import { forwardRef, useImperativeHandle, useState, useRef, useEffect, useCallback } from "react";
import classnames from "classnames";
import SignupButton from "segments/desktop/core-components/button/signup-button/signup-button";
import AnimatedTag from "./animated-tag/animated-tag-component";
import { MAX_TAG_WIDTH } from "./animated-tag/animated-tag-constants";
import { componentStyles } from "./selectable-tags-grid-button-component.scss";
import SecondaryButton from "segments/desktop/core-components/button/secondary-button/secondary-button";
import YouTubePopUpButton from "../../button/youtube-popup-button/youtube-popup-button";

const SelectableTagsGridButtonComponent = forwardRef((props, ref) => {
  const {
    buttonConfig,
    beforeRedirectSyncAction,
    secondaryButtonConfig,
    youTubeButtonConfig,
    skipSignupUpdateCluster,
    shouldSendClustersAsParams,
    displayedOnDarkTheme,
    style,
    websiteAlignmentBranding
  } = props;

  const [animatedTags, setAnimatedTags] = useState([]);
  const [tagStartingPosition, setTagStartingPosition] = useState({});
  const [shakeButton, setShakeButton] = useState(false);
  const [paintButtonColor, setPaintButtonColor] = useState(null);
  const buttonContainerRef = useRef();

  const updateStartingPosition = () => {
    requestAnimationFrame(() => {
      //  we want to put it in the center of the button, it's dynamic width so need to calculate it
      const buttonToCalculateFor = buttonContainerRef.current?.querySelector("button");
      if (!buttonToCalculateFor) return;
      const boundClientRect = buttonToCalculateFor.getBoundingClientRect();
      const startingPositionFromLeft = boundClientRect.left + (boundClientRect.width / 2 - MAX_TAG_WIDTH / 2);
      setTagStartingPosition({ left: startingPositionFromLeft });
    });
  };

  const onTagAnimationEndCallback = useCallback(
    (tagIndex) => {
      const filtered = animatedTags.filter((t) => {
        return t.tagIndex !== tagIndex;
      });
      setAnimatedTags([...filtered]);

      if (shakeButton) {
        setShakeButton(false);
        setTimeout(() => {
          setShakeButton(true);
        }, 0);
      } else {
        setShakeButton(true);
      }
    },
    [shakeButton]
  );

  const renderSelectionAnimationIfNeeded = () => {
    if (!tagStartingPosition?.left) return null;

    return animatedTags.map((tagToAnimate) => {
      return (
        <AnimatedTag
          key={tagToAnimate.tagIndex}
          tagToAnimate={tagToAnimate}
          tagStartingPosition={tagStartingPosition}
          onAnimationEndCallback={onTagAnimationEndCallback}
        />
      );
    });
  };

  useEffect(() => {
    if (!websiteAlignmentBranding) {
      updateStartingPosition();
      window.addEventListener("resize", updateStartingPosition, {
        passive: true
      });
      return () => {
        window.removeEventListener("resize", updateStartingPosition, {
          passive: true
        });
      };
    }
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      clickedTag: (gridSelectableTag, isSelected) => {
        if (!isSelected) return;

        if (websiteAlignmentBranding) {
          setPaintButtonColor(gridSelectableTag.tagBackgroundColor);
          setShakeButton(true);
        } else {
          //  adding new and removing current if already there
          setAnimatedTags([
            gridSelectableTag,
            ...animatedTags.filter((t) => {
              return t.tagIndex !== gridSelectableTag.tagIndex;
            })
          ]);
        }
      }
    }),
    [animatedTags]
  );

  return (
    <div className="selectable-tags-button-container" ref={buttonContainerRef} style={style}>
      {!websiteAlignmentBranding && renderSelectionAnimationIfNeeded()}
      <div
        className={classnames("button-wrapper", { "shake-button": shakeButton })}
        onAnimationEnd={() => shakeButton && setShakeButton(false)}
      >
        <SignupButton
          {...buttonConfig}
          shouldSendClustersAsParams={shouldSendClustersAsParams}
          beforeSubmitCallback={beforeRedirectSyncAction}
          skipUpdateCluster={skipSignupUpdateCluster}
          displayedOnDarkTheme={displayedOnDarkTheme}
          paintButtonColor={paintButtonColor}
        />
      </div>
      {secondaryButtonConfig && (
        <div className="secondary-button-wrapper">
          <SecondaryButton {...secondaryButtonConfig} />
        </div>
      )}
      {youTubeButtonConfig && (
        <div className="youtube-button-wrapper">
          <YouTubePopUpButton {...youTubeButtonConfig} />
        </div>
      )}
      <style jsx>{componentStyles}</style>
    </div>
  );
});

export default SelectableTagsGridButtonComponent;
