import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
import VerticalTimerTabsAdvancedConfig from "segments/desktop/core-components/vertical-timer-tabs/fields-advanced-config";
import FullParagraphAdvancedFieldsConfig from "segments/desktop/core-components/full-paragraph/advanced-fields-config";
import TitleAdvancedConfig from "segments/desktop/core-components/title/advanced-field-config";
import AssetAdvancedConfig from "components/core/asset/advanced-field-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "",
  fields: {
    ...SharedAdvancedFieldsConfig,
    tabsTitle: {
      type: FIELD_TYPES.NESTED,
      label: "Title",
      fields: TitleAdvancedConfig
    },
    advancedTitleConfig: {
      ...VerticalTimerTabsAdvancedConfig.title,
      label: "Tab Title"
    },
    tabContent: {
      type: FIELD_TYPES.NESTED,
      label: "Tab Content",
      fields: FullParagraphAdvancedFieldsConfig
    },
    arrowSize: VerticalTimerTabsAdvancedConfig.arrowSize,
    ...AssetAdvancedConfig
  }
};
