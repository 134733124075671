import dynamic from "next/dynamic";

const TwoThirdsTextAndAssetComponent = require("./two-thirds-text-and-asset-component").default;

import TwoThirdsTextAndAssetConfig from "./two-thirds-text-and-asset-config";
import TwoThirdsTextAndAssetAdvancedConfig from "./two-thirds-text-and-asset-advanced-config";

export default {
  TwoThirdsTextAndAssetComponent,
  TwoThirdsTextAndAssetConfig,
  TwoThirdsTextAndAssetAdvancedConfig
};
