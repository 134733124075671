export const toRadians = (angle) => angle * (Math.PI / 180);

export const lineDistance = (x1, y1, x2, y2) => {
  const a = x1 - x2;
  const b = y1 - y2;

  const distance = Math.sqrt(a * a + b * b);
  return distance;
};

export const randomNumber = (min, max) => {
  return Math.random() * (max - min) + min;
};
