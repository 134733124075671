import { PureComponent } from "react";
import { componentStyles } from "./button-tags-grid-component.scss";
import ButtonTag from "segments/desktop/core-components/button-tag/button-tag";

export default class ButtonTagsGridComponent extends PureComponent {
  renderButtonTags() {
    const { gridButtonTags, isOpenLinkInNewTab } = this.props;
    return gridButtonTags.map((buttonTag, index) => {
      return (
        <li className="grid-button-container" key={index}>
          <ButtonTag {...buttonTag} isOpenLinkInNewTab={isOpenLinkInNewTab} />
        </li>
      );
    });
  }

  render() {
    return (
      <div className="button-tags-grid-component">
        <ul className="grid-button-list">{this.renderButtonTags()}</ul>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

ButtonTagsGridComponent.defaultProps = {
  isOpenLinkInNewTab: true,
  gridButtonTags: [
    {
      tagImg: { src: "/static/img/button/pm.png" },
      tagText: "Project Management"
    },
    {
      tagImg: { src: "/static/img/button/software_dev.png" },
      tagText: "Software Developement"
    },
    {
      tagImg: { src: "/static/img/button/cs.png" },
      tagText: "Customer Support"
    },
    {
      tagImg: { src: "/static/img/button/hr.png" },
      tagText: "HR"
    },
    {
      tagImg: { src: "/static/img/button/m.png" },
      tagText: "Marketing"
    },
    {
      tagImg: { src: "/static/img/button/mediaproduction.png" },
      tagText: "Media and Production"
    },
    {
      tagImg: { src: "/static/img/button/opsit.png" },
      tagText: "Operations/IT"
    },
    {
      tagImg: { src: "/static/img/button/sales.png" },
      tagText: "Sales"
    }
  ]
};
