import {
  CRM_PRODUCT_ID,
  MARKETER_PRODUCT_ID,
  PROJECTS_PRODUCT_ID,
  DEV_PRODUCT_ID,
  WORK_MANAGEMENT_PRODUCT_ID
} from "constants/products";

const productsIds = [
  WORK_MANAGEMENT_PRODUCT_ID,
  MARKETER_PRODUCT_ID,
  CRM_PRODUCT_ID,
  DEV_PRODUCT_ID,
  PROJECTS_PRODUCT_ID
];

const centerMainProduct = (defaultActiveProduct) => {
  const fromIndex = productsIds.indexOf(defaultActiveProduct);
  const toIndex = Math.floor(productsIds.length / 2);
  const organizedProductsIds = [...productsIds];
  const mainProductId = organizedProductsIds.splice(fromIndex, 1)[0];
  organizedProductsIds.splice(toIndex, 0, mainProductId);
  return organizedProductsIds;
};

export const organizeCards = (defaultActiveProduct) => {
  return centerMainProduct(defaultActiveProduct);
};
