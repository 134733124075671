import css from "styled-jsx/css";
import { TABLET_QUERY_MAX_WIDTH } from "/constants/vp-sizes";
import Constants from "components/pages/developers/documentation/base-documentation-menu/constants";

const GRID_COLUMNS_WIDTH = `${Constants.DEVELOPERS_MENU_WIDTH}px auto ${Constants.DEVELOPERS_MENU_WIDTH}px`;

export default css.global`
  .dynamic-template {
    &.developers-center-template {
      .full-paragraph-wrapper {
        margin: 0px !important;
      }

      @media (min-width: ${TABLET_QUERY_MAX_WIDTH}) {
        main {
          display: grid;
          grid-template-columns: ${GRID_COLUMNS_WIDTH};
        }
      }
    }
  }
`;
