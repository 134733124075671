import { PureComponent } from "react";
import jquery from "jquery";
import HubspotForm from "react-hubspot-form";
import DataLayerEventTypes from "constants/data-layer-event-types";
import { fireGtmEvent } from "client/services/gtm-service/gtm-service";
import { HUBSPOT_FORM_SUBMIT_CLICKED, HUBSPOT_FORM_READY } from "constants/bigbrain-event-types";
import { getCookie } from "/services/cookies-service";
import { BIG_BRAIN_VISITOR_ID } from "/constants/cookies";
import MondayLoaderComponent from "components/core/loader/monday-loader-component";
import { trackEvent } from "services/bigbrain-service";
import { PORTAL_ID } from "client/services/hubspot-service/contact-form-config";
import { componentStyles } from "./contact-sales-hubspot-form-component.scss";

export default class ContactSalesHubspotFormComponent extends PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.jQuery = window.jQuery || jquery;
  }

  renderLoadingState = () => {
    return <MondayLoaderComponent />;
  };

  onReady = () => {
    // We attach visitor_id as hidden field so we can track from
    // which LPs users make the Hubspot form submissions
    const { formId } = this.props;
    const visitorId = getCookie(BIG_BRAIN_VISITOR_ID);
    if (visitorId) {
      jQuery(`input[name="visitor_id"]`).val(visitorId);
    }
    trackEvent(HUBSPOT_FORM_READY, { kind: formId });
  };

  onSubmit = () => {
    const { formId } = this.props;
    fireGtmEvent(DataLayerEventTypes.HUBSPOT_FORM_SUBMITTED, { formId });
    trackEvent(HUBSPOT_FORM_SUBMIT_CLICKED, { kind: formId });
  };

  render() {
    const { formId, formMinHeight } = this.props;
    return (
      <div className="contact-sales-hubspot-form-component" style={{ minHeight: formMinHeight }}>
        <HubspotForm
          portalId={PORTAL_ID}
          formId={formId}
          loading={this.renderLoadingState()}
          onReady={this.onReady}
          cssClass="hbspt-form"
          onSubmit={this.onSubmit}
          translations={{ en: { required: "Required" } }}
        />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
