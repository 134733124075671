import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
import TitleAdvancedFieldsConfig from "segments/desktop/core-components/title/advanced-field-config";
import ParagraphAdvancedFieldsConfig from "segments/desktop/core-components/paragraph/advanced-fields-config";

const { titleWidth, ...restTitleAdvancedProps } = TitleAdvancedFieldsConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "",
  fields: {
    ...SharedAdvancedFieldsConfig,
    titleAdvancedProps: {
      type: FIELD_TYPES.NESTED,
      label: "Title",
      fields: restTitleAdvancedProps
    },
    paragraphAdvancedProps: {
      type: FIELD_TYPES.NESTED,
      label: "Paragraph",
      fields: ParagraphAdvancedFieldsConfig
    },
    alignToLogo: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Align to logo (New)"
    }
  }
};
