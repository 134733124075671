import TabsComponent from "components/core/tabs/tabs-component";
import TabComponent from "components/core/tabs/tab/tab-component";
import AssetComponent from "components/core/asset/asset-component";
import Title from "segments/desktop/core-components/title/title";
import classnames from "classnames";
import { componentStyles } from "./process-tabs.scss";
import DefaultProps from "./default-props";

const ProcessTabs = ({
  tabs,
  tabsColor,
  activeTabId,
  distanceFromTop,
  autoTransition,
  scrollSpeed,
  scrollOnTabClick,
  showProcessIndicator,
  titleConfig
}) => {
  const tabsRenderer = () => {
    return tabs.map((tab, index) => {
      const { tabLabel, tabId, asset, title } = tab;

      return (
        <TabComponent
          tabClassName={classnames("process-tab", { "process-indicator": showProcessIndicator })}
          tabId={tabId}
          label={tabLabel}
          color={tabsColor}
          key={index}
        >
          <div className="asset-and-text">
            {asset && <AssetComponent {...asset} />}
            {title && <Title titleClassName="asset-title" {...title} {...titleConfig} />}
          </div>
        </TabComponent>
      );
    });
  };

  return (
    <div className="process-tabs">
      <TabsComponent
        autoTransition={autoTransition}
        scrollOnTabClick={scrollOnTabClick}
        scrollSpeed={scrollSpeed}
        distanceFromTop={distanceFromTop}
        activeTabId={activeTabId}
        role="tabpanel"
      >
        {tabsRenderer()}
      </TabsComponent>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default ProcessTabs;

ProcessTabs.defaultProps = DefaultProps;
