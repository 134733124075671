import { useEffect } from "react";
import { componentStyles } from "./parallax-galaxy.scss";

const ParallaxGalaxy = (props) => {
  const createStars = () => {
    const $galaxy = document.getElementById("twinkling-stars");

    const randomNumber = (min, max) => {
      return Math.random() * (max - min) + min;
    };

    const createStar = (isCircle, leftRange, rightRange) => {
      const star = document.createElement("div");
      star.classList.add("star");
      if (isCircle) {
        star.classList.add("circle");
      }
      const x = randomNumber(1, $galaxy.offsetWidth * 0.75);
      const y = randomNumber(1, $galaxy.offsetHeight * 0.8);
      const { style } = star;
      style.left = `${Math.floor(x)}px`;
      style.top = `${Math.floor(y)}px`;
      style.setProperty("--star-size", `${randomNumber(leftRange, rightRange)}px`);
      style.setProperty("--twinkle-duration", `${Math.ceil(randomNumber(1, 3))}s`);
      style.setProperty("--twinkle-delay", `${Math.ceil(randomNumber(1, 8))}s`);
      return star;
    };

    for (let index = 0; index < 24; index++) {
      if (index < 16) {
        $galaxy?.append(createStar(false, 4, 10));
      } else {
        $galaxy?.append(createStar(true, 3, 6));
      }
    }
  };

  useEffect(() => {
    createStars();
  }, []);

  return (
    <div className="parallax-galaxy" style={props.style}>
      <div className="galaxy-container">
        <div className="spin-container">
          <div id="twinkling-stars" />
          <div className="circle-container small-circle" />
          <div className="items-container small-circle">
            <div className="item gmail">
              <img
                src="https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/parallax/integrations/gmail.svg"
                alt="gmail integration icon"
              />
            </div>
            <div className="item linkedin">
              <img
                src="https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/parallax/integrations/linkedin.svg"
                alt="linkedin integration icon"
              />
            </div>
            <div className="item harvest">
              <img
                src="https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/parallax/integrations/harvest.svg"
                alt="harvest integration icon"
                className="system__icon system__icon--sun"
              />
            </div>
          </div>
          <div className="circle-container medium-circle" />
          <div className="items-container medium-circle">
            <div className="item item-inner zoom">
              <img
                src="https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/parallax/integrations/zoom.svg"
                alt="zoom integration icon"
              />
            </div>
            <div className="item item-outer facebook-ads">
              <img
                src="https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/parallax/integrations/facebook_ads.svg"
                alt="facebook-ads integration icon"
              />
            </div>
          </div>
          <div className="circle-container large-circle" />
          <div className="items-container large-circle">
            <div className="item mailchimp">
              <img
                src="https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/parallax/integrations/mailchimp.svg"
                alt="mailchimp integration icon"
              />
            </div>
            <div className="item jira">
              <img
                src="https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/parallax/integrations/jira.svg"
                alt="jira integration icon"
              />
            </div>
            <div className="item salesforce">
              <img
                src="https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/parallax/integrations/salesforce.svg"
                alt="salesforce integration icon"
              />
            </div>
            <div className="item jotform">
              <img
                src="https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/parallax/integrations/jotform.svg"
                alt="jotform integration icon"
              />
            </div>
            <div className="item dropbox">
              <img
                src="https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/parallax/integrations/dropbox.svg"
                alt="dropbox integration icon"
              />
            </div>
          </div>
        </div>
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default ParallaxGalaxy;
