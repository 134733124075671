import { componentStyles } from "./connected-products-grid-component.scss";
import GridImageWithParagraph from "segments/desktop/core-components/grid-image-with-paragraph/grid-image-with-paragraph";
import ConnectedProductsGridDefaultProps from "./default-props";
import PictureComponent from "/components/core/picture/picture-component";


const IMAGE_HEIGHT = "100%";
const IMAGE_WIDTH = "100%"
const WORK_MANAGEMENT_LOGO = "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/products/wm_logo_product.png";
const PLUS_ICON_LINK = "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/products/plus_icon.png";

const ConnectedProductsGridComponent = (props) => {

  const renderGridItems =()=> {
    const { gridItems, textSize } = props;
    return gridItems?.map((gridItem, index) => {
      const { fullParagraph, ...restImageProps } = gridItem;

      return (
        <div key={index} className="grid-image-with-paragraph-component-wrapper">
          <GridImageWithParagraph
            {...restImageProps}
            fullParagraph={fullParagraph}
            width={IMAGE_WIDTH}
            height={IMAGE_HEIGHT}
            textSize={textSize}
            hideButton={true}
          />
        </div>
      );
    });
  };

    return (
      <div className="connected-products-grid-component">
        <div className="products-wrapper">
          <div className="work-management-icon">
          <PictureComponent src={WORK_MANAGEMENT_LOGO}
          alt="monday work management"
          />
          </div>
          <div className="plus-icon">
          <PictureComponent src={PLUS_ICON_LINK}
          />
          </div>
        <div className="products">
        {renderGridItems()}
        </div>
      </div>
      <style jsx>{componentStyles}</style>
      </div>
    );
  }


ConnectedProductsGridComponent.defaultProps = ConnectedProductsGridDefaultProps;
export default ConnectedProductsGridComponent;
