import css from "styled-jsx/css";

const PROCESS_INDICATOR_DIAMETER = "9px";

export const componentStyles = css.global`
  .process-tabs {
    .process-tab {
      flex: 1;
      min-width: fit-content;
      text-align: left;
      padding-left: 0;

      :hover {
        background-color: transparent;
        .tab-label {
          opacity: 0.7;
          color: var(--tab-color);
        }
      }

      &.active {
        .tab-label {
          font-weight: 600;
        }

        background-color: transparent;
        .tab-underline-placeholder {
          background: transparent;

          .tab-underline {
            top: -1px;
            bottom: -1px;
          }

          .tab-underline::before {
            background-color: var(--tab-color);
          }
        }
      }

      .tab-label {
        font-weight: initial;
        color: unset;
        font-size: 1rem;
      }

      .tab-underline-placeholder {
        height: 1px;
        display: flex;
        align-items: center;

        .tab-underline {
          background-color: var(--tab-color);
          left: 0;
          right: 0;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }

      &.process-indicator {
        .tab-underline {
          left: ${PROCESS_INDICATOR_DIAMETER};
        }

        .tab-underline::before {
          content: "";
          display: block;
          width: ${PROCESS_INDICATOR_DIAMETER};
          height: ${PROCESS_INDICATOR_DIAMETER};
          border-radius: 50%;
          background-color: transparent;
          border: 1px solid var(--tab-color);
          position: relative;
          top: 50%;
          left: ${`-${PROCESS_INDICATOR_DIAMETER}`};
          transform: translateY(-50%);
        }
      }
    }

    .asset-and-text {
      .asset-title {
        text-align: center;
      }
    }
  }
`;
