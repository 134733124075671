import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";

export const componentStyles = css.global`
  .monday-universe-homepage-first-fold-component {
    max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
    margin: auto;
    height: 600px;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;

    .stars-canvas {
      position: absolute;
      height: 100%;
      width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};

      .comet-line {
        position: absolute;
        height: 1px;
        background: linear-gradient(90deg, transparent, #fff, transparent);

        &.underline {
          background: linear-gradient(90deg, transparent, #fff);
        }
      }
    }

    .paragraph-wrapper {
      .title-wrapper {
        margin-left: auto;
        margin-right: auto;
        .universe-title {
          padding-bottom: 12px;
          font-weight: 400;
        }
      }

      .sub-title {
        text-align: center;
        margin-top: 40px;
        font-weight: bold;
      }
    }

    &.universe-theme {
      .paragraph-wrapper {
        .title-wrapper {
          .universe-title {
            background: linear-gradient(360deg, #ffffff 53.95%, #0b0b4a 207.37%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }
`;
