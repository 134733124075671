// plop_marker:layouts_import
import HotdogShape from "./hotdog-shape";
import RingShape from "./ring-shape";
import StarShape from "./star-shape";
import RectangleShape from "./rectangle-shape";
import SquareShape from "./square-shape";

export default {
  // plop_marker:layouts_add_layout
  HotdogShape,
  RingShape,
  StarShape,
  RectangleShape,
  SquareShape,
};
