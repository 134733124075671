import { PureComponent } from "react";
import { componentStyles } from "./markdown-embedded-component.scss";
import Markdown from "segments/desktop/core-components/markdown/markdown";

export default class MarkdownEmbeddedComponent extends PureComponent {
  render() {
    return (
      <div className="markdown-embedded-component">
        <div className="markdown-wrapper">
          <Markdown {...this.props} />
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

MarkdownEmbeddedComponent.defaultProps = Markdown.defaultProps;
