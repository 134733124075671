import dynamic from "next/dynamic";

const ImagesLogosComponent = require("./images-logos-component").default;

import ImagesLogosConfig from "./images-logos-config";
import ImagesLogosAdvancedConfig from "./images-logos-advanced-config";

export default {
  ImagesLogosComponent,
  ImagesLogosConfig,
  ImagesLogosAdvancedConfig
};
