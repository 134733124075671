import dynamic from "next/dynamic";

const EmbeddedYoutubeAssetComponent = require("./embedded-youtube-asset-component").default;

import EmbeddedYoutubeAssetConfig from "./embedded-youtube-asset-config";
import EmbeddedYoutubeAssetAdvancedConfig from "./embedded-youtube-asset-advanced-config";

export default {
  EmbeddedYoutubeAssetComponent,
  EmbeddedYoutubeAssetConfig,
  EmbeddedYoutubeAssetAdvancedConfig
};
