import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";

export const componentStyles = css`
  .monday-form-form-component {
    margin: auto;

    .iframe-core-component {
      height: 100vh;
    }
  }

  :global(body) {
    overflow: hidden;
  }
`;
