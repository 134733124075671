import { PureComponent, cloneElement } from "react";
import { componentStyles } from "./tabs.scss";
import Constants from "../constants";
import ArrowRightIcon from "static/svg/arrow-right-v2.svg";
import Colors from "styles/colors";

export default class Tabs extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeTabIndex: props.defaultActiveTabIndex
    };

    this.handleTabClick = this.handleTabClick.bind(this);
  }

  componentDidMount() {
    const { autoTransition } = this.props;
    if (autoTransition) this.playAutoTransition();
  }

  componentWillUnmount() {
    if (this._changeActiveIndexInterval) clearInterval(this._changeActiveIndexInterval);
  }

  playAutoTransition() {
    const { autoTransition } = this.props;

    this._changeActiveIndexInterval = setInterval(() => {
      const { activeTabIndex } = this.state;
      const { children } = this.props;
      this.setState({ activeTabIndex: (activeTabIndex + 1) % children.length });
    }, autoTransition);
  }

  handleTabClick(index) {
    if (this._changeActiveIndexInterval) clearInterval(this._changeActiveIndexInterval);

    this.setState({
      activeTabIndex: index
    });
  }

  contentRenderer() {
    const { children } = this.props;
    const { activeTabIndex } = this.state;

    if (children[activeTabIndex]) {
      return children[activeTabIndex].props.children;
    }
  }

  tabsRenderer() {
    const { children } = this.props;
    const { activeTabIndex } = this.state;

    return children.map((child, index) => {
      return cloneElement(child, {
        onClick: this.handleTabClick,
        index,
        isActive: index === activeTabIndex
      });
    });
  }

  renderSelectedTabIndicator() {
    const { activeTabIndex } = this.state;
    const { color } = this.props;
    const top = activeTabIndex * Constants.TAB_HEIGHT;
    const transform = `translateY(${top}px)`;

    return (
      <div className="selected-tab-indicator-component" style={{ transform, height: Constants.TAB_HEIGHT }}>
        <div className="selected-tab-indicator" style={{ backgroundColor: Colors[color] }}>
          <ArrowRightIcon />
        </div>
      </div>
    );
  }

  render() {
    const { positionStyles, minHeight, addTabIndicator, fullBackground, backgroundColor } = this.props;

    return (
      <div className="vertical-tabs">

        <div className="tabs">
          <div className="tabs-wrapper" style={positionStyles}>
            {this.tabsRenderer()}
            {addTabIndicator && this.renderSelectedTabIndicator()}
          </div>
        </div>

        <div className="content" role="tabpanel" style={{ minHeight, background: !fullBackground && Colors[backgroundColor] }}>
          {this.contentRenderer()}
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
