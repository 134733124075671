// plop_marker:layouts_import
import PressGrid from "./press-grid";
import ConnectedProductsGrid from "./connected-products-grid";
import FlexibleCardsGrid from "./flexible-cards-grid";
import SquareTagsGrid from "./square-tags-grid";
import ProductCardsGrid from "./product-cards-grid";
import MasonryGrid from "./masonry-grid";
import TagItemsGrid from "./tag-items-grid";
import IconWithParagraphGrid from "./icon-with-paragraph-grid";
import SelectableTagsWithCarouselGrid from "./selectable-tags-with-carousel-grid";
import BlogCardsGrid from "./blog-cards-grid";
import ButtonImageTagsGrid from "./button-image-tags-grid";
import ButtonTagsGrid from "./button-tags-grid";
import CardsGrid from "./cards-grid";
import TagsGrid from "./tags-grid";
import SelectableTagsGrid from "./selectable-tags-grid";
import ImageWithParagraphGrid from "./image-with-paragraph-grid";
import OnlyImageGrid from "./only-image-grid";

export default {
  // plop_marker:layouts_add_layout
  PressGrid,
  ConnectedProductsGrid,
  FlexibleCardsGrid,
  SquareTagsGrid,
  ProductCardsGrid,
  MasonryGrid,
  TagItemsGrid,
  IconWithParagraphGrid,
  SelectableTagsWithCarouselGrid,
  BlogCardsGrid,
  ButtonImageTagsGrid,
  ButtonTagsGrid,
  CardsGrid,
  TagsGrid,
  SelectableTagsGrid,
  ImageWithParagraphGrid,
  OnlyImageGrid
};
