import css from "styled-jsx/css";

export const componentStyles = css`
  .side-by-side-with-bullets-text-and-asset-component {
    display: flex;
    .side-by-side-asset-wrapper {
      flex-grow: 1;
      :global(.side-by-side-text-and-asset-component) {
        height: 100%;
      }
    }
  }
`;
