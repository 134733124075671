import { FIELD_TYPES } from "constants/template-field-type";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";

const { textAlign } = FullParagraphFieldsConfig;

import { positionTypes } from "segments/desktop/core-components/images-grid/images-grid-service";

export default {
  imagePosition: {
    type: FIELD_TYPES.COMBO,
    label: "Image Position",
    options: [
      { value: positionTypes.TOP_LEFT, text: "Top Left" },
      { value: positionTypes.TOP_CENTER, text: "Top Center" },
      { value: positionTypes.TOP_RIGHT, text: "Top Right" },
      { value: positionTypes.CENTER_LEFT, text: "Center Left" },
      { value: positionTypes.CENTER_CENTER, text: "Center Center" },
      { value: positionTypes.CENTER_RIGHT, text: "Center Right" },
      { value: positionTypes.BOTTOM_LEFT, text: "Bottom Left" },
      { value: positionTypes.BOTTOM_CENTER, text: "Bottom Center" },
      { value: positionTypes.BOTTOM_RIGHT, text: "Bottom Right" }
    ]
  },
  textAlign
};
