import { componentStyles } from "./monday-integrations-connections-component.scss";
import {
    ICON_HEIGHT,
    ICON_WIDTH,
    ICONS_PATHS_DATA,
    HORIZONTAL_PATHS_DATA,
    ICONS_ARRAY,
    SVG_WIDTH,
    SVG_HEIGHT,
    PATH_WIDTH,
    CORNER_LENGTH
} from "./consts"

const PathsAndCirclesSvgComponent = (props) => {

  const renderPath = (pathData, pathKey) => {
    return(
      <path d={pathData} stroke-opacity="0.24" stroke-width="10" fill="none" stroke="white" key={pathKey} />
    )
  };

  const drawHorizontalPathBetweenPoints = (xStart, xEnd, yValue) =>{
    const startPoint = "M"+xStart+","+yValue;
    const endPoint = ""+xEnd+","+yValue;
    const pathData = startPoint+" "+endPoint;
    return(
      renderPath(pathData, `${xStart}_${xEnd}_${yValue}`)
    )
  };

  //draws a path between 2 icons in the same axis
  const drawPathBetweenIcons = (icon1index, icon2index, horizontalDistance) => {
    const topRightCorner = "a20,20 0 0 1 20,20";
    const bottomRightCorner = "a20,20 0 0 1 -20,20";
    const topLeftCorner = "a20,20 0 0 0 -20,20";
    const bottomLeftCorner = "a20,20 0 0 0 20,20";

    let startPointX = ICONS_ARRAY[icon1index].endLocation[0];
    if (horizontalDistance > 0) startPointX = startPointX + ICON_WIDTH;
    const startPointY = ICONS_ARRAY[icon1index].endLocation[1]+ICON_HEIGHT/2;
    const startPointString = `M${startPointX},${startPointY}`
    const verticalDistance= (ICONS_ARRAY[icon2index].endLocation[1] - ICONS_ARRAY[icon1index].endLocation[1] - ICON_HEIGHT/2);

    const pathData = horizontalDistance > 0 ? `${startPointString} h${horizontalDistance} ${topRightCorner} v${verticalDistance} ${bottomRightCorner} h-${horizontalDistance}` :
    `${startPointString} h-${(horizontalDistance * -1)} ${topLeftCorner} v${verticalDistance} ${bottomLeftCorner} h${horizontalDistance * -1}`;

    return renderPath(pathData, startPointString);
  }


  const drawCirclesOnPath = (icon1Index, icon2Index, distanceBetweenCircles, horizontalDistance) => {
    const locations = [];
    const yAxisPathStartPoint = ICONS_ARRAY[icon1Index].endLocation[1] + ICON_HEIGHT/2;
    const yAxisPathEndPoint = ICONS_ARRAY[icon2Index].endLocation[1]+ ICON_HEIGHT/2 - 1 ;
    const xAxisPathStartPoint = (horizontalDistance > 0) ? ICONS_ARRAY[icon1Index].endLocation[0] + ICON_WIDTH + distanceBetweenCircles : -(ICONS_ARRAY[icon1Index].endLocation[0] -distanceBetweenCircles);
    const xAxisPathEndPoint = (horizontalDistance > 0) ? xAxisPathStartPoint +horizontalDistance -(PATH_WIDTH+CORNER_LENGTH) : xAxisPathStartPoint - horizontalDistance -(PATH_WIDTH+CORNER_LENGTH)  ;
    const sign = (horizontalDistance > 0) ? 1 : -1;
      for(let i = xAxisPathStartPoint ; i < xAxisPathEndPoint ; i += distanceBetweenCircles ){
        locations.push([sign * i, yAxisPathStartPoint]);
      };
      for(let i = yAxisPathStartPoint+distanceBetweenCircles ; i<yAxisPathEndPoint ;i+=distanceBetweenCircles){
        locations.push([sign*xAxisPathEndPoint, i]);
      };
      for(let i = xAxisPathStartPoint ; i < xAxisPathEndPoint ; i += distanceBetweenCircles ){
        locations.push([sign * i, yAxisPathEndPoint]);
      };
    return(
      locations.map((index) => (
        <circle cx={index[0]} cy={index[1]} r="3" stroke-opacity="0.75" fill="white" stroke="white" key={`${index[0]}_${index[1]}`}/>
      ))
    )
  }


  const drawHorizontalCirclesBetweenPoints = (point1X, point2X, YValue, distanceBetweenCircles, direction) =>{
    const locations = [];
    let counter = 1;
    if(direction>0){
    for( let i=point1X+distanceBetweenCircles; i < point2X; i += distanceBetweenCircles){
      locations.push([i,counter]);
    }
  }else{
    for( let i=point2X-distanceBetweenCircles; i > point1X; i -= distanceBetweenCircles){
      locations.push([i,counter]);
    }
  }
    return(
      <>
      {locations.map((array) => (
        <circle cx={array[0]} cy={YValue} r="3" stroke-opacity="0.75" fill="white" stroke="white" key={`${array[0]}_${YValue}`}/>
      ))}
      </>
    )
  }

  const renderPathsAndCircles = () =>{
    return [ICONS_PATHS_DATA.map((path) => (
        drawPathBetweenIcons(path.origin, path.destination, path.length)
        )),
        ICONS_PATHS_DATA.map((path) => (
        drawCirclesOnPath(path.origin, path.destination, 60, path.length)
        )),
        HORIZONTAL_PATHS_DATA.map((path) => (
        drawHorizontalPathBetweenPoints(path.xStart, path.xEnd, path.yValue)
        )),
        HORIZONTAL_PATHS_DATA.map((path) => (
        drawHorizontalCirclesBetweenPoints(path.xStart, path.xEnd, path.yValue,60, path.direction)
        ))]
  }


  return ( 
    <svg width={SVG_WIDTH} height={SVG_HEIGHT} className="paths">
      {renderPathsAndCircles()}
    <style jsx>{componentStyles}</style>
    </svg>
  );
};


export default PathsAndCirclesSvgComponent;
