import Rectangle from "segments/desktop/shape/layouts/rectangle-shape/rectangle";
import Shapes from "segments/desktop/shape/index";
import DefaultProps from "./default-props";

const SquareShapeComponent = (props) => {
  return <Rectangle {...props} componentType={Shapes.SquareShapeComponent.type} />;
};

SquareShapeComponent.defaultProps = DefaultProps;

export default SquareShapeComponent;
