// plop_marker:layouts_import
import ReviewCardsReviews from "./review-cards-reviews";
import ParagraphAndCarouselReviews from "./paragraph-and-carousel-reviews";
import RegularReviews from "./regular-reviews";
import WithTitleReviews from "./with-title-reviews";

export default {
  // plop_marker:layouts_add_layout
  ReviewCardsReviews,
  ParagraphAndCarouselReviews,
  RegularReviews,
  WithTitleReviews,
};
