import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
import IframeAdvancedFieldsConfig from "segments/desktop/core-components/iframe/advanced-field-config";

const { borderWidth, borderColor, ...selectedAdvancedConfig } = IframeAdvancedFieldsConfig; //	border is hidden for now, will show if needed

export default {
  type: FIELD_TYPES.NESTED,
  label: "",
  fields: {
    ...SharedAdvancedFieldsConfig,
    ...selectedAdvancedConfig,
    maxWidth: {
      type: FIELD_TYPES.TEXT,
      lable: "Max Width"
    }
  }
};
