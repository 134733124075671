import Button from "segments/desktop/core-components/button/button";
import Check from "monday-ui-react-core/dist/icons/Check";
import colors from "styles/colors";
import { componentStyles } from "./product-card-grid-component.scss";

const ProductCardGridBodyComponent = (props) => {
  const { body, showDivider, dividerStyle, useCases, theme, productCardButtonConfig } = props;
  return (
    <>
      <div className="product-card-body">{body?.bodyText}</div>
      {showDivider && <div className="product-card-divider" style={dividerStyle} />}
      <div className="product-card-use-cases">
        <div className="product-card-use-cases-title">{useCases?.text}</div>
        <div className="product-card-use-cases-bullets">
          {useCases?.list?.map((useCase) => (
            <div className="product-card-use-cases-bullet-icon-and-text">
              <div className="product-card-use-cases-bullet-icon" style={{ color: colors[theme] }}>
                <Check />
              </div>
              <div className="product-card-use-cases-bullet-text">{useCase?.text}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="product-card-footer">
        <Button {...productCardButtonConfig} />
      </div>
      <style jsx>{componentStyles}</style>
    </>
  );
};

export default ProductCardGridBodyComponent;
