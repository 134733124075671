import dynamic from "next/dynamic";

const SideBySideWithBackgroundTextAndAssetComponent = require("./side-by-side-with-background-text-and-asset-component").default;

import SideBySideWithBackgroundTextAndAssetConfig from "./side-by-side-with-background-text-and-asset-config";
import SideBySideWithBackgroundTextAndAssetAdvancedConfig from "./side-by-side-with-background-text-and-asset-advanced-config";

export default {
  SideBySideWithBackgroundTextAndAssetComponent,
  SideBySideWithBackgroundTextAndAssetConfig,
  SideBySideWithBackgroundTextAndAssetAdvancedConfig
};
