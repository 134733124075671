import { PureComponent } from "react";
import Button from "segments/desktop/core-components/button/button";
import { componentStyles } from "./title-only-paragraph.scss";
import DefaultProps from "./default-props";
import classnames from "classnames";
import Colors from "styles/colors";
import Title from "segments/desktop/core-components/title/title";

export default class TitleOnlyParagraph extends PureComponent {
  getComponentInlineStyles() {
    const { textAlign, textColor, fontSize, titleSize } = this.props;

    const inlineStyles = {
      textAlign,
      color: Colors[textColor]
    };

    if (!titleSize) inlineStyles.fontSize = fontSize;

    return inlineStyles;
  }

  render() {
    const { title, buttonConfig, textAlign, titleSize, isFirstSegment, titleColor } = this.props;
    const inlineComponentStyles = this.getComponentInlineStyles();

    return (
      <div className="title-only-paragraph" style={inlineComponentStyles}>
        <Title title={title} titleSize={titleSize} isFirstSegment={isFirstSegment} titleColor={titleColor} />

        {buttonConfig && (
          <div className={classnames("button-wrapper", textAlign)}>
            <Button {...buttonConfig} />
          </div>
        )}

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

TitleOnlyParagraph.defaultProps = DefaultProps;
