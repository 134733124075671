import { FIELD_TYPES } from "constants/template-field-type";
import TwoThirdsTextAndAssetConfig from "segments/desktop/text-and-asset/layouts/two-thirds-text-and-asset/two-thirds-text-and-asset-config";

const { titleColor, ...fullParagraphFields } = TwoThirdsTextAndAssetConfig.fields.fullParagraph.fields; // title color is controlled from theme
const textAndAssetFields = {
  ...TwoThirdsTextAndAssetConfig.fields,
  asset: {
    ...TwoThirdsTextAndAssetConfig.fields.asset,
    fields: {
      ...TwoThirdsTextAndAssetConfig.fields.asset.fields,
      useWindowMask: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Use window mask"
      }
    }
  },
  fullParagraph: {
    ...TwoThirdsTextAndAssetConfig.fields.fullParagraph,
    fields: {
      ...fullParagraphFields
    }
  }
};

export default {
  type: FIELD_TYPES.NESTED,
  label: "Building Blocks",
  fields: {
    buildingBlocksTabsTheme: {
      type: FIELD_TYPES.COLOR_PICKER,
      label: "Theme"
    },
    tabs: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Tabs",
      maxLimit: 8,
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          tabLabel: { type: FIELD_TYPES.TEXT, label: "Tab Label", translatable: true },
          // Should be added after cloudinary-service.js is refactored
          // tabIcon: {
          //   type: FIELD_TYPES.GALLERY,
          //   defaultImagesKeys: [],
          //   label: "Select an Icon"
          // },
          textAndAsset: {
            type: FIELD_TYPES.NESTED,
            label: "Text and asset",
            fields: textAndAssetFields
          }
        }
      }
    }
  }
};
