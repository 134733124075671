import css from "styled-jsx/css";
import colors from "styles/colors";

export const componentStyles = css.global`
  .images-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .images-carousel-content-wrapper {
      overflow: hidden;
      position: relative;
      margin: 16px;
      width: 100%;
      height: 100%;

      .carousel-image {
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.95);
        opacity: 0;
        transition: opacity 0.2s, transform 0.2s;
        width: calc(100% - 64px);
        height: 100%;
        user-select: none;

        &.current-image {
          z-index: 1;
          opacity: 1;
        }

        &.previous-image {
          transform: translate(-65%, -50%) scale(0.8);
          opacity: 0.5;
        }

        &.next-image {
          transform: translate(-35%, -50%) scale(0.8);
          opacity: 0.5;
        }
      }
    }

    .carousel-navigation {
      cursor: pointer;

      path {
        fill: ${colors.mud};
      }
    }
  }
`;
