import FullParagraphDefaultProps from "segments/desktop/core-components/full-paragraph/default-props";
import { SIGNUP_BUTTON_TYPE } from "segments/desktop/core-components/button/button-consts";
import { LG, MD } from "constants/sizes";
import TopLogosComponent from "segments/desktop/logos/layouts/top-logos/top-logos-component";

const getFullParagraphProps = () => {
  return {
    ...FullParagraphDefaultProps,
    title: "<monday-color green>Together.</monday-color> In projects, processes, and success. Wherever you are.",
    body: "monday.com is the Work OS that powers remote teamwork.",
    topic: {},
    buttonConfig: { buttonType: SIGNUP_BUTTON_TYPE, buttonColor: "dodger-blue" },
    textAlign: "center",
    titleSize: LG
  };
};

const getLogosDefaultProps = () => {
  const logosDefaultProps = TopLogosComponent.defaultProps;

  return {
    ...logosDefaultProps,
    title: "Over 127,000 customers worldwide rely on monday.com",
    logos: { ...logosDefaultProps.logos, numberOfCompanies: 10 },
    paddingTop: 0,
    paddingBottom: 0
  };
};

export default {
  fullParagraph: getFullParagraphProps(),
  asset: {
    position: "center",
    fitToContainer: true,
    image: {
      src: "/static/img/segment-layout-default-images/first-fold/globe_top_people.png"
    }
  },
  logos: getLogosDefaultProps()
};
