import { PureComponent } from "react";
import { componentStyles } from "./radio-button.scss";
import { withGenericConfig } from "/contexts/generic-config-context";
import classnames from "classnames";

class RadioButton extends PureComponent {
  constructor(props) {
    super(props);

    this.onSelect = this.onSelect.bind(this);
  }

  onSelect() {
    const { onSelect, disabled, value } = this.props;
    if (!disabled) onSelect(value);
  }

  getLabel() {
    const { label, labelRenderer, value, translate } = this.props;
    return labelRenderer ? labelRenderer(value) : translate(label);
  }

  renderRadioButton() {
    const { color, disabled } = this.props;
    return <div className={`radio-button-circle color-${color}`} />;
  }

  render() {
    const { disabled, selected, size, className, color, labelStyle } = this.props;
    const label = this.getLabel();

    return (
      <div
        className={classnames("radio-button-component", className, `color-${color}`, `size-${size}`, {
          disabled: disabled,
          selected: selected
        })}
        onClick={this.onSelect}
      >
        {this.renderRadioButton()}
        {label && (
          <span style={labelStyle} className="radio-button-label">
            {label}
          </span>
        )}
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

RadioButton.defaultProps = {
  size: "sm",
  selected: false,
  disabled: false,
  labelStyle: {},
  onSelect: () => {},
  className: ""
};

export default withGenericConfig(RadioButton);
