import colors from "styles/colors";
import { getElementScrollableHeight } from "client/services/hooks/use-element-size";

const DEFAULT_GRADIENT_POINT_VALUE = 50;

const getPointParsedValue = (pointValue) => {
  if (pointValue === "0") return 0;

  return Number(pointValue) || DEFAULT_GRADIENT_POINT_VALUE;
};

export const getFillProps = ({ fill, gradientFill }) => {
  let fillProps = {
    fill: colors[fill]
  };

  if (gradientFill?.useLinearGradientFill) {
    fillProps = {
      fill: null,
      fillLinearGradientStartPoint: { x: 0, y: 0 },
      fillLinearGradientEndPoint: {
        x: getPointParsedValue(gradientFill?.x),
        y: getPointParsedValue(gradientFill?.y)
      },
      fillLinearGradientColorStops: [0, colors[fill], 1, colors[gradientFill?.color] || "white"]
    };
  }

  return fillProps;
};

export const getRelativeToElementY = ({ relativeTo, isPreview }) => {
  if (relativeTo) {
    console.log("isPreview: ", isPreview);
    const relativeElement = document.getElementById(relativeTo);

    if (relativeElement) {
      const elementY = getElementScrollableHeight(
        isPreview ? relativeElement.offsetParent || relativeElement : relativeElement
      );
      return elementY;
    }
  }

  return 0;
};
