import { componentStyles } from "./toggle.scss";
import classnames from "classnames";
import { useState } from "react";
import { generateOnKeyDownHandler } from "/client/services/accessibility/accessibility-service";

const Toggle = (props) => {
  const { handleToggle, toggleButtonColor, toggleBackgroundColor, leftPhrase, rightPhrase, swtichTextsColors } = props;

  const [isOn, setIsOn] = useState(false);

  const toggleSwitch = () => {
    setIsOn(!isOn);
    handleToggle();
  };

  const onKeyDown = generateOnKeyDownHandler(toggleSwitch);

  return (
    <div
      className={classnames("toggle", { on: isOn, "switch-texts-colors": swtichTextsColors })}
      onClick={toggleSwitch}
      style={{ "--background-color": toggleBackgroundColor, "--switch-color": toggleButtonColor }}
      role="tablist"
    >
      <div className="toggle-left" role="tab" tabIndex={0} onKeyDown={onKeyDown} aria-label={leftPhrase}>
        {leftPhrase}
      </div>
      <div className="toggle-right" role="tab" tabIndex={0} onKeyDown={onKeyDown} aria-label={rightPhrase}>
        {rightPhrase}
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default Toggle;
