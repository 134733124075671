import { FIELD_TYPES } from "constants/template-field-type";
import ThemeConfig from "segments/core-configs/theme-config";
import TitleConfig from "segments/desktop/core-components/title/fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Simpo",
  fields: {
    ...TitleConfig,
    ...ThemeConfig
  }
};
