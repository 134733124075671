import ProductCardsGridDekstopComponent from "./product-cards-grid-dekstop-component.js";
import ProductCardsGridMobileComponent from "./product-cards-grid-mobile-component.js";
import DefaultProps from "./default-props";
import ProductCardsGridMediumSizeComponent from "./product-cards-grid-medium-size-component.js";
import { componentStyles } from "./product-cards-grid-component.scss";
import {useEffect} from "react";
import * as Sentry from "@sentry/nextjs";

const ProductCardsGridComponent = (props) => {
  const {cards} = props;

  const productTypeMissing =()=>{
    cards?.forEach((card, index) => {
      const {productType} = card;
      if (!productType){
        Sentry.captureMessage(`Product type is missing in card number: ${index}`);
      }
    }
    )
  };

  useEffect(() => {
    productTypeMissing();
  }, [cards]);

  return (
    <div className="product-cards-grid-component">
      <div className="product-cards-grid-full-size-wrapper">
        <ProductCardsGridDekstopComponent {...props} />
      </div>
      <div className="product-cards-grid-medium-size-wrapper">
        <ProductCardsGridMediumSizeComponent {...props} />
      </div>
      <div className="product-cards-grid-small-size-wrapper">
        <ProductCardsGridMobileComponent {...props} />
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

ProductCardsGridComponent.defaultProps = DefaultProps;

export default ProductCardsGridComponent;
