import { FIELD_TYPES } from "constants/template-field-type";
import AssetFieldsConfig from "components/core/asset/fields-config.js";
import fullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config.js";
import VideoBackgroundFieldsConfig from "segments/desktop/core-components/video-background/fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Video Background",
  fields: {
    fullParagraphConfig: {
      type: FIELD_TYPES.NESTED,
      label: "Full Paragrapph",
      fields: {
        ...fullParagraphFieldsConfig
      }
    },
    assetConfig: {
      type: FIELD_TYPES.NESTED,
      label: "Asset",
      fields: {
        ...AssetFieldsConfig
      }
    },
    ...VideoBackgroundFieldsConfig
  }
};
