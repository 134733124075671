import dynamic from "next/dynamic";

const SimpoCustomerSuccessComponent = require("./simpo-customer-success-component").default;

import SimpoCustomerSuccessConfig from "./simpo-customer-success-config";
import SimpoCustomerSuccessAdvancedConfig from "./simpo-customer-success-advanced-config";

export default {
  SimpoCustomerSuccessComponent,
  SimpoCustomerSuccessConfig,
  SimpoCustomerSuccessAdvancedConfig
};
