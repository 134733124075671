import { PureComponent } from "react";
import { componentStyles } from "./regular-reviews-component.scss";
import Reviews from "segments/desktop/core-components/reviews/reviews";
import ReviewCardDefaultProps from "segments/desktop/core-components/review-card/default-props";

export default class RegularReviewsComponent extends PureComponent {
  render() {
    return (
      <div className="regular-reviews-component">
        <Reviews {...this.props} />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

RegularReviewsComponent.defaultProps = {
  reviews: Reviews.defaultProps.reviews,
  paddingTop: "92px",
  paddingBottom: "92px",
  cardFontSize: ReviewCardDefaultProps.fontSize,
  reviewCardOverrideHeight: null
};
