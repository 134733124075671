import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
import hubspotAdvancedConfig from "segments/desktop/core-components/hubspot-contact-form/advanced-fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "",
  fields: {
    ...SharedAdvancedFieldsConfig,
    ...hubspotAdvancedConfig,
    backgroundLayers: {
      type: FIELD_TYPES.COMBO,
      label: "Background layers",
      options: [
        { value: 0, text: "0" },
        { value: 1, text: "1" },
        { value: 2, text: "2" },
        { value: 3, text: "3" }
      ]
    },
    enablePhoneSupportGroupTest: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Enable phone support group (Don't Use - In test)"
    }
  }
};
