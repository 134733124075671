import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
import AdvancedSquareTagsFieldsConfig from "segments/desktop/core-components/square-tags/advanced-fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "",
  fields: {
    ...SharedAdvancedFieldsConfig,
    ...AdvancedSquareTagsFieldsConfig,

    showGoogleConnectOnDesktop: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Show google connect on desktop (Test)"
    }
  }
};
