import { FIELD_TYPES } from "constants/template-field-type";
import TwoThirdsTextAndAssetConfig from "segments/desktop/text-and-asset/layouts/two-thirds-text-and-asset/two-thirds-text-and-asset-config";
import FullParagraphConfig from "segments/desktop/paragraph/layouts/full-paragraph/full-paragraph-config";
import TagItemsGridConfig from "segments/desktop/grid/layouts/tag-items-grid/tag-items-grid-config";
import FlexibleCardGridConfig from "segments/desktop/grid/layouts/flexible-cards-grid/flexible-cards-grid-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Personas",
  fields: {
    tabsColor: {
      type: FIELD_TYPES.COLOR_PICKER,
      label: "Tabs color"
    },
    tabsBackgroundColor: {
      type: FIELD_TYPES.COLOR_PICKER,
      label: "Tabs background color"
    },
    alignToLogo: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Align to logo (New)"
    },
    swapTagsWithTextAndAssets: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Swap Tags with Text and Asset  (Temporary for test)"
    },
    tabs: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Tabs",
      maxLimit: 6,
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          tabLabel: { type: FIELD_TYPES.TEXT, label: "Tab Label", translatable: true },
          tabId: { type: FIELD_TYPES.TEXT, label: "Tab Id" },
          timer: { type: FIELD_TYPES.TEXT, label: "Tab Timer Length (in milliseconds)" },
          textAndAssetTwoThirds: {
            type: FIELD_TYPES.NESTED,
            label: "Text and asset",
            fields: {
              textAndAssetPaddingTop: { type: FIELD_TYPES.TEXT, label: "Padding top" },
              ...TwoThirdsTextAndAssetConfig.fields
            }
          },
          fullParagraph: {
            type: FIELD_TYPES.NESTED,
            label: "Full Paragraph",
            fields: {
              fullParagraphPaddingTop: { type: FIELD_TYPES.TEXT, label: "Padding top" },
              ...FullParagraphConfig.fields
            }
          },
          tagItems: {
            type: FIELD_TYPES.NESTED,
            label: "Tag Items",
            fields: {
              tagItemsPaddingTop: { type: FIELD_TYPES.TEXT, label: "Padding top" },
              ...TagItemsGridConfig.fields
            }
          },
          flexibleCards: {
            type: FIELD_TYPES.NESTED,
            label: "Flexible Cards",
            fields: {
              flexibleCardsPaddingTop: { type: FIELD_TYPES.TEXT, label: "Padding top" },
              imagePaddingBottom: { type: FIELD_TYPES.TEXT, label: "Padding Bottom (under the image)" },
              ...FlexibleCardGridConfig.fields
            }
          }
        }
      }
    }
  }
};
