import { PureComponent } from "react";
import SideBySideTextAndAsset from "segments/desktop/core-components/side-by-side-text-and-asset/side-by-side-text-and-asset";
import TriangleWithColor from "segments/desktop/core-components/triangle-with-color/triangle-with-color";
import { MD } from "constants/sizes";
import { themes } from "segments/segments-service";
import { componentStyles } from "./asymmetric-side-by-side-text-and-asset-component.scss";

export default class AsymmetricSideBySideTextAndAssetComponent extends PureComponent {
  render() {
    const { theme, ...restProps } = this.props;
    const background = { color: theme, width: "100%" };

    return (
      <div className="asymmetric-side-by-side-text-and-asset-component">
        <TriangleWithColor color={themes[theme].background} />
        <SideBySideTextAndAsset
          {...restProps}
          background={background}
          contentClassName="asymmetric-side-by-side-text-and-asset-content"
        />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

AsymmetricSideBySideTextAndAssetComponent.defaultProps = {
  ...SideBySideTextAndAsset.defaultProps,
  theme: "enterprise",
  titleSize: MD,
  textSize: MD
};
