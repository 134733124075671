import SideBySideTextAndAsset from "segments/desktop/core-components/side-by-side-text-and-asset/side-by-side-text-and-asset";
import { SPACE_BLUE_COLOR_NAME } from "styles/color-consts";
import AssetComponent from "components/core/asset/asset-component";
import SignupButton from "segments/desktop/core-components/button/signup-button/signup-button";
import TypedComponent from "segments/desktop/core-components/typed-component/typed-component";
import PictureComponent from "components/core/picture/picture-component";
import { SM } from "constants/sizes";
import VisibilitySensor from "react-visibility-sensor";
import { useState } from "react";
import ParallaxGalaxy from "../parallax-galaxy";
import { componentStyles } from "./scroll-parallax-homepage-first-fold-mobile-component.scss";
import { sharedComponentStyles } from "../scroll-parallax-homepage-first-fold-component.scss";
import { getTypings, PERSONS_IMAGES } from "../config";

const ScrollParallaxHomepageFirstFoldMobileComponent = (props) => {
  const VISIBILITY_SENSOR_OFFSET = 0;
  const [shouldRerenderWhenVisible, setShouldRerenderWhenVisible] = useState(false);

  const { paragraphs, topAsset, bottomAsset, translate } = props;

  const mobileParagraphs = paragraphs.map((paragraph) => ({
    paragraph: {
      title: paragraph.title,
      titleSize: SM,
      body: paragraph.description,
      topic: ""
    }
  }));

  const topAssetComponent = () => {
    return (
      <div className="top-asset-wrapper">
        <div className="parallax-galaxy-wrapper">
          <ParallaxGalaxy />
        </div>
        <AssetComponent image={topAsset} useWindowMask={true} />
      </div>
    );
  };

  const middleAssetComponent = () => {
    const getComponentProps = (index) => {
      const delays = [250, 3000, 5500];
      return {
        typedProps: {
          strings: [`${getTypings(translate)[index]}`],
          startDelay: delays[index]
        }
      };
    };

    const onVisibilityChange = (isVisible) => {
      if (!shouldRerenderWhenVisible && isVisible) {
        setShouldRerenderWhenVisible(true);
      }
    };

    return (
      <VisibilitySensor
        offset={{ bottom: VISIBILITY_SENSOR_OFFSET, top: VISIBILITY_SENSOR_OFFSET }}
        partialVisibility={true}
        active={true}
        scrollCheck={true}
        onChange={onVisibilityChange}
      >
        <div className="middle-asset-wrapper">
          {PERSONS_IMAGES.map((value, index) => (
            <div className="typing-wrapper">
              <PictureComponent {...PERSONS_IMAGES[index].image} />
              {shouldRerenderWhenVisible && <TypedComponent {...getComponentProps(index)} />}
            </div>
          ))}
        </div>
      </VisibilitySensor>
    );
  };

  const bottomAssetComponent = () => {
    return (
      <div className="bottom-asset-wrapper">
        <AssetComponent video={bottomAsset} useWindowMask={true} />
      </div>
    );
  };

  return (
    <div className="scroll-parallax-homepage-first-fold-mobile-component">
      <SideBySideTextAndAsset
        background={{ color: SPACE_BLUE_COLOR_NAME, width: "100%" }}
        fullParagraph={mobileParagraphs[0].paragraph}
        replaceAssetComponent={topAssetComponent}
        contentClassName="top-paragraph-and-asset"
      />
      <SideBySideTextAndAsset
        background={{ color: SPACE_BLUE_COLOR_NAME, width: "100%" }}
        fullParagraph={mobileParagraphs[1].paragraph}
        replaceAssetComponent={middleAssetComponent}
        contentClassName="middle-paragraph-and-asset"
      />
      <SideBySideTextAndAsset
        background={{ color: SPACE_BLUE_COLOR_NAME, width: "100%" }}
        fullParagraph={mobileParagraphs[2].paragraph}
        replaceAssetComponent={bottomAssetComponent}
        contentClassName="bottom-paragraph-and-asset"
      />
      <style jsx>{sharedComponentStyles}</style>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default ScrollParallaxHomepageFirstFoldMobileComponent;
