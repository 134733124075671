import { PureComponent } from "react";
import { componentStyles } from "./plain-classic-testimonials-component.scss";
import { LG } from "constants/sizes";
import TestimonialCardComponent from "../../components/testimonial-card-component";

export default class PlainClassicTestimonialsComponent extends PureComponent {
  render() {
    const { testimonialCard, fontSize, titleSize } = this.props;

    return (
      <div className="plain-classic-testimonials-component">
        <div className="testimonial-card-wrapper">
          <TestimonialCardComponent {...testimonialCard} fontSize={fontSize} titleSize={titleSize} />
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

PlainClassicTestimonialsComponent.defaultProps = {
  testimonialCard: TestimonialCardComponent.defaultProps,
  paddingTop: "70px",
  paddingBottom: "70px",
  titleSize: LG
};
