import css from "styled-jsx/css";
import Colors from "styles/colors";
import { WHITE_COLOR_NAME } from "styles/color-consts";

export const componentStyles = css`
  .testimonial {
    display: flex;
    padding-top: 64px;
    padding-bottom: 64px;
    justify-content: center;
    height: 100%;
    width: 100%;

    .content {
      display: flex;
      gap: 45px;
      width: 50%;
      justify-content: center;
      flex-direction: column;

      .quote {
        font-size: 30px;
        margin-bottom: 50px;
      }

      .author {
        display: flex;
        align-items: center;
        gap: 25px;
        font-size: 16px;

        :global(.author-image) {
          width: 77px;
        }

        .company {
          font-weight: bold;
          font-size: 16px;
        }
      }
    }
  }

  .white {
    color: ${Colors[WHITE_COLOR_NAME]};
  }

  .black {
    color: #000;
  }
`;
