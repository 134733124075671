import css from "styled-jsx/css";
import colors from "styles/colors";

export const componentStyles = css.global`
  .stars-component {
    display: flex;
    justify-content: center;

    .star-icon-wrapper {
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
`;
