import css from "styled-jsx/css";

export const componentStyles = css.global`
  .use-cases-tabs-mobile-component {
    .mobile-tabs-component {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    .asset-and-paragraphs-wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .full-paragraph {
        padding: 0 32px;
        .title-wrapper {
          margin-bottom: 4px;
        }
      }
      .use-case-product-banner {
        .use-case-product-banner-logo-and-text-wrapper {
          .use-case-product-banner-text {
            .full-paragraph {
              padding: 0 8px;
              .paragraph-body {
                font-size: 0.8125rem;
                line-height: 21px;
                font-weight: 400;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
`;
