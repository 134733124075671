import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
import VerticalTimerTabsAdvancedConfig from "segments/desktop/core-components/vertical-timer-tabs/fields-advanced-config.js";

export default {
  type: FIELD_TYPES.NESTED,
  label: "",
  fields: {
    ...SharedAdvancedFieldsConfig,
    ...VerticalTimerTabsAdvancedConfig
  }
};
