import dynamic from "next/dynamic";

const FullParagraphComponent = require("./full-paragraph-component").default;

import FullParagraphConfig from "./full-paragraph-config";
import FullParagraphAdvancedConfig from "./full-paragraph-advanced-config";

export default {
  FullParagraphComponent,
  FullParagraphConfig,
  FullParagraphAdvancedConfig
};
