export const defaultData = {
  template: "OnePagerWithImageTemplate",
  footer: { preventFooter: true },
  topbar: { showMenu: false },
  imageWithBackgroundPart: {
    backgroundColor: "brand-blue",
    src: "/static/img/boards/board-with-pencils.png",
    alt: "",
    imageSegmentHeight: "100vh"
  },
  textPart: {
    titleLines: ["Project Management", "<b>is better when it's visual</b>"],
    subtitleLines: ["See what everyone on your team is working on in a single glance"],
    bullets: [
      "Manage your projects & tasks with ease",
      "Plan visually on a timeline",
      "Centralize communication in one place"
    ],
    mobileTitleLines: ["Project Management", "<b>is better when it's visual</b>"],
    mobileSubtitleLines: ["See what everyone on your team", "is working on in a single glance"],
    color: "black"
  },
  collaborationWith: {
    show: false,
    color: "black",
    text: "Powered By"
  },
  SocialProof: {
    showSocialProof: false,
    content: {
      CompaniesLogos: {
        paragraph: {},
        showSignup: false
      },
      Reviews: {
        columnWidth: "350px",
        reviews: [
          {
            reviewContent: [
              '"I\'ve tried... Asana, Trello, Smartsheet... I was using a combination of 3 or so at a time to accomplish everything I needed. There was a huge sigh of relief when I found monday.com."'
            ],
            reviewerName: "Dotan Egozi, monday.com"
          },
          {
            reviewContent: [
              '"I\'ve tried... Asana, Trello, Smartsheet... I was using a combination of 3 or so at a time to accomplish everything I needed. There was a huge sigh of relief when I found monday.com."'
            ],
            reviewerName: "Dotan Egozi, monday.com"
          }
        ]
      }
    }
  },
  ShowShortFooter: {
    showShortFooter: false
  },
  signupForm: {
    buttonColor: "brand-pink",
    buttonText: "Get Started",
    inputPlaceholder: "Enter your work email",
    downloadButtonTitle: "Download The App"
  }
};
