import css from "styled-jsx/css";

export const componentStyles = css.global`
  .mobile-tabs-navigation {
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    display: flex;
    gap: 8px;
    padding-left: 16px;
    .tab-nav-button-wrapper {
      display: flex;
      white-space: nowrap;
    }
    &::after {
      content: "";
      padding-right: 16px;
    }
  }
`;
