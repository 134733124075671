import FullParagraphDefaultProps from "segments/desktop/core-components/full-paragraph/default-props";
import { AUTO } from "constants/cloudinary-asset-qualities";

export default {
  fullParagraph: FullParagraphDefaultProps,
  backgroundImage: {
    src: "/static/img/segment-layout-default-images/text-and-asset/multiple-notes-on-the-wall.png"
  },
  assetQuality: AUTO
};
