import { PureComponent } from "react";
import TabsComponent from "/components/core/tabs/tabs-component";
import TabComponent from "/components/core/tabs/tab/tab-component";
import TwoThirdsTextAndAssetComponent from "segments/desktop/text-and-asset/layouts/two-thirds-text-and-asset/two-thirds-text-and-asset-component";
import Colors from "styles/colors";
import { componentStyles } from "./regular-tabs-component.scss";
import DefaultProps from "./deafult-props";

const MINIMUN_NUM_OF_TABS_FOR_SMALL_LABELS = 5;
export default class RegularTabsComponent extends PureComponent {
  tabsRenderer() {
    const { tabs, tabsColor } = this.props;
    const useSmallLabels = tabs.length >= MINIMUN_NUM_OF_TABS_FOR_SMALL_LABELS;

    return tabs.map((tab, index) => {
      const { tabLabel, textAndAsset, tabId } = tab;

      return (
        <TabComponent tabId={tabId} label={tabLabel} color={tabsColor} key={index} useSmallLabels={useSmallLabels}>
          <TwoThirdsTextAndAssetComponent {...textAndAsset} key={index} />
        </TabComponent>
      );
    });
  }

  renderTwoThirdsComponents() {
    const { tabs, tabsColor } = this.props;

    return tabs.map((tab, index) => {
      const { tabLabel, textAndAsset } = tab;

      return (
        <div key={index} className="two-third-text-and-asset-component-wrapper">
          <div className="tab-label-container" style={{ color: Colors[tabsColor] }}>
            <div className="tab-label">
              {tabLabel}
              <div className="tab-label-underline" style={{ backgroundColor: Colors[tabsColor] }} />
            </div>
          </div>

          <TwoThirdsTextAndAssetComponent {...textAndAsset} />
        </div>
      );
    });
  }

  renderLargeScreenComponent() {
    const { autoTransition, scrollOnTabClick, scrollSpeed, distanceFromTop, activeTabId } = this.props;

    return (
      <div className="large-screen-component-wrapper">
        <TabsComponent
          autoTransition={autoTransition}
          scrollOnTabClick={scrollOnTabClick}
          scrollSpeed={scrollSpeed}
          distanceFromTop={distanceFromTop}
          activeTabId={activeTabId}
          role="tabpanel"
        >
          {this.tabsRenderer()}
        </TabsComponent>
      </div>
    );
  }

  renderSmallScreenComponent() {
    return <div className="small-screen-component-wrapper">{this.renderTwoThirdsComponents()}</div>;
  }

  render() {
    return (
      <div className="regular-tabs-component">
        {this.renderLargeScreenComponent()}
        {this.renderSmallScreenComponent()}

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

RegularTabsComponent.defaultProps = DefaultProps;
