import { PureComponent } from "react";
import classnames from "classnames";
import { componentStyles } from "./images-grid.scss";
import DefaultProps from "./default-props";
import GridImage from "segments/desktop/core-components/grid-image/grid-image";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import { getImageSize } from "segments/desktop/grid/grid-service";
import { withGenericConfig } from "/contexts/generic-config-context";
import { gettArrayWithIncrementingNumbers } from "utils/arrays";

class ImagesGrid extends PureComponent {
  renderImages() {
    const {
      images,
      isWithParagraph,
      imagePosition,
      textAlign,
      titleSize,
      textSize,
      isMobileOrTabletBrowser,
      imageStyle,
      isIcon,
      numberOfCardsInRow,
      fitHeightToImgHeight
    } = this.props;

    return images.map((image, index) => {
      const { height, width } = getImageSize(numberOfCardsInRow || images.length, isMobileOrTabletBrowser);
      const { fullParagraph, shadow, ...restImageProps } = image;

      return (
        <div className="grid-image-container" key={index} style={{ width }}>
          {restImageProps?.src && (
            <div className={classnames("grid-image-wrapper", shadow && "with-shadow")}>
              <GridImage
                height={fitHeightToImgHeight || isIcon ? "auto" : height}
                width={width}
                imageStyle={imageStyle}
                imagePosition={imagePosition}
                {...restImageProps}
                className="image"
              />
            </div>
          )}

          {isWithParagraph && (
            <div className="full-paragraph-wrapper">
              <FullParagraph {...fullParagraph} textAlign={textAlign} titleSize={titleSize} textSize={textSize} />
            </div>
          )}
        </div>
      );
    });
  }

  renderEmpty() {
    const { images, numberOfCardsInRow, isMobileOrTabletBrowser } = this.props;
    const emptyPlaces = gettArrayWithIncrementingNumbers(numberOfCardsInRow - images.length);
    const { width } = getImageSize(numberOfCardsInRow || images.length, isMobileOrTabletBrowser);
    return emptyPlaces.map((index) => {
      return <div className="grid-image-container" key={index} style={{ width }} />;
    });
  }

  shouldAddEmptyContainer = () => {
    const { images, numberOfCardsInRow } = this.props;
    return numberOfCardsInRow && numberOfCardsInRow >= images.length;
  };

  render() {
    return (
      <div className="images-grid">
        {this.renderImages()}
        {this.shouldAddEmptyContainer() && this.renderEmpty()}
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
export default withGenericConfig(ImagesGrid);

ImagesGrid.defaultProps = DefaultProps;
