import { ONE_SECOND } from "./constants/timer";
import { positionTypes } from "segments/desktop/core-components/images-grid/images-grid-service";

const getAsset = (videoName) => ({
  video: {
    loop: false,
    src: {
      formats: [`/static/video/capabilites_videos/${videoName}.mp4`]
    }
  }
});

export default {
  tabs: [
    {
      title: "Flexible Database",
      content:
        "A Work OS is flexible enough to fit any team, workflow, or industry. With intuitive analytics, you can run your daily work, analyze your performance, and manage your team in one place. ",
      timer: ONE_SECOND * 10,
      asset: getAsset("flexibility")
    },
    {
      title: "Insights & reporting",
      content: "Track progress and understand how your team is working in a single glance with contextual insights.",
      timer: ONE_SECOND * 10,
      asset: getAsset("insight")
    },
    {
      title: "Data visualization",
      content: "See organization-wide insights and drill down into the details using flexible dashboards.",
      timer: ONE_SECOND * 7,
      asset: getAsset("data-viz")
    },
    {
      title: "Organization-wide use",
      content: "Meet the needs of all your teams and run processes, gain insights, and communicate all in one place.",
      timer: ONE_SECOND * 6,
      asset: getAsset("organization-wide-use")
    },
    {
      title: "Collaboration",
      content: "Share knowledge and collaborate within the context of your work. ",
      timer: ONE_SECOND * 7,
      asset: getAsset("colaboration")
    },
    {
      title: "Integrations",
      content: "Integrate with your favorite tools and build diverse workflows from start to finish. ",
      timer: ONE_SECOND * 8,
      asset: getAsset("integrations")
    },
    {
      title: "Automations",
      content: "Put mundane tasks on autopilot so teams can focus on the work that makes an impact.",
      timer: ONE_SECOND * 12,
      asset: getAsset("automations")
    },
    {
      title: "Working remotely",
      content:
        "Collaborate, track statuses, give feedback, outline projects, and define ownership for your projects, processes, and workflows no matter where you are.",
      timer: ONE_SECOND * 5,
      badgeText: "Popular",
      asset: getAsset("working-remotely")
    }
  ],
  backgroundColor: "light-blue-video-background",
  progressbarColor: "brand-green",
  showArrow: false,
  assetPosition: positionTypes.BOTTOM_CENTER,
  showButton: true,
  buttonConfig: {
    buttonType: "YouTube Popup",
    isWithIcon: true,
    buttonText: "Watch monday.com in action",
    iconColor: "green"
  },
  timerEnabled: true,
  alwaysShowProgressbar: false,
  arrowSize: "22px",
  title: {
    fontSize: "16px",
    selectedFontSize: "20px"
  }
};
