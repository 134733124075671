import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
import { ALIGN_TYPES, ALIGN_LABELS } from "constants/align-types";

export default {
  type: FIELD_TYPES.NESTED,
  label: "",
  fields: {
    ...SharedAdvancedFieldsConfig,
    width: { type: FIELD_TYPES.TEXT, label: "Blog width in desktop (% only)" },
    align: {
      type: FIELD_TYPES.COMBO,
      label: "Alignment",
      options: [
        { value: ALIGN_TYPES.LEFT, text: ALIGN_LABELS.LEFT },
        { value: ALIGN_TYPES.CENTER, text: ALIGN_LABELS.CENTER },
        { value: ALIGN_TYPES.RIGHT, text: ALIGN_LABELS.RIGHT }
      ]
    }
  }
};
