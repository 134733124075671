import css from "styled-jsx/css";
import colors from "styles/colors";

const border = `1px solid ${colors["border-gray"]}`;

export const componentStyles = css.global`
  .social-icon-link-component {
    border: ${border};
    border-radius: 50%;
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

    .social-icon {
      height: 10px;
      width: 10px;
    }
  }
`;
