import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "/constants/vp-sizes";

export const componentStyles = css`
  .selectable-tags-with-carousel-grid-component {

    .selectable-tags-grid-wrapper {
      max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .carousel-wrapper {
      margin-top: 16px;
    }

    :global(.cluster-tag-image) {
      width: 100%;
      height: auto;
    }
  }
`;
