import { FIELD_TYPES } from "constants/template-field-type";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Monday Form",
  fields: {
    formId: {
      type: FIELD_TYPES.TEXT,
      label: "Monday form ID",
      labelDescription: `Monday form ID is the identifier you could find in the monday form url: https://forms.monday.com/forms/<formId>`
    }
  }
};
