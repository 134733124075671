import Colors from "styles/colors";
import { WHITE_COLOR_NAME } from "styles/color-consts";
import {MD, XS} from "constants/sizes";
import RegularButton from "segments/desktop/core-components/button/regular-button/regular-button";
import { productBannerProps } from "constants/products.js";
import { componentStyles } from "./use-case-product-banner.scss";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";

const UseCaseProductBanner = (props) => {
  const { theme, productType, title, body, buttonText } = props;
  const Icon = productBannerProps?.[productType]?.icon;

  return (
    <div className="use-case-product-banner" style={{ background: Colors[theme] || Colors[WHITE_COLOR_NAME] }}>
      <div className="use-case-product-banner-logo-and-text-wrapper">
        <div className="use-case-product-banner-logo">{Icon && <Icon />}</div>
        <div className="use-case-product-banner-text">
          <FullParagraph title={title} body={body} topic={{}} titleSize={XS} textSize={MD} mobileTextAlign={"center"} />
        </div>
      </div>
      <div className="use-case-product-banner-button">
        <RegularButton
          buttonText={buttonText}
          color={productBannerProps?.[productType]?.buttonColor}
          textColor="revert"
          outline
          url={productBannerProps?.[productType]?.url}
          isOpenLinkInNewTab={true}
        />
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default UseCaseProductBanner;
