import dynamic from "next/dynamic";

const TopLogosComponent = require("./top-logos-component").default;

import TopLogosConfig from "./top-logos-config";
import TopLogosAdvancedConfig from "./top-logos-advanced-config";

export default {
  TopLogosComponent,
  TopLogosConfig,
  TopLogosAdvancedConfig
};
