import SideBySideTextAndAssetComponent from "segments/desktop/text-and-asset/layouts/side-by-side-text-and-asset/side-by-side-text-and-asset-component";
import { componentStyles } from "./side-by-side-with-icons-text-and-asset-dekstop-component.scss.js";
import DefaultProps from "./default-props";

const SideBySideWithIconsTextAndAssetDekstopComponent = (props) => {
  const getStyle = () => {
    const { minHeight } = props;
    if (!minHeight) return {};

    return { minHeight };
  };

  return (
    <div className="side-by-side-with-icons-text-and-asset-component" style={getStyle()}>
      <div className="side-by-side-asset-wrapper">
        <SideBySideTextAndAssetComponent {...props} />
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

SideBySideWithIconsTextAndAssetDekstopComponent.defaultProps = DefaultProps;

export default SideBySideWithIconsTextAndAssetDekstopComponent;
