import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";

export const componentStyles = css.global`
  .globe-desktop-homepage-first-fold-component {
    width: 100%;
    height: 730px;
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    margin: auto;

    .globe-text-and-asset-content {
      padding-right: 0px;

      .full-paragraph-component-wrapper {
        width: 40%;
        transform: translateY(-84px);
      }

      .image-wrapper {
        width: 60%;
        pointer-events: none;
      }
    }
  }
`;
