import React from "react";
import TwoThirdsTextAndAssetComponent from "segments/desktop/text-and-asset/layouts/two-thirds-text-and-asset/two-thirds-text-and-asset-component";
import classnames from "classnames";
import Colors from "styles/colors";
import TabsComponent from "/components/core/tabs/tabs-component";
import FullParagraphComponent from "segments/desktop/paragraph/layouts/full-paragraph/full-paragraph-component";
import TabComponent from "/components/core/tabs/tab/tab-component";
import TagItemsGridComponent from "segments/desktop/grid/layouts/tag-items-grid/tag-items-grid-component";
import FlexibleCardGridComponent from "segments/desktop/grid/layouts/flexible-cards-grid/flexible-cards-grid-component";
import DefaultProps from "./default-props";
import { componentStyles } from "./personas-tabs-component.scss";

const PersonasTabsComponent = (props) => {
  const DEFAULT_PADDING_TOP = "24px";
  const { tabs, tabsColor, tabsBackgroundColor, alignToLogo, swapTagsWithTextAndAssets } = props;

  const componentsRenderer = (fullParagraph, tagItems, textAndAssetTwoThirds, flexibleCards) => {
    if (swapTagsWithTextAndAssets) {
      return (
        <div>
          <FullParagraphComponent {...fullParagraph} />
          <TagItemsGridComponent {...tagItems} />
          <TwoThirdsTextAndAssetComponent {...textAndAssetTwoThirds} />
          <FlexibleCardGridComponent {...flexibleCards} />
        </div>
      );
    }
    return (
      <div>
        <TwoThirdsTextAndAssetComponent {...textAndAssetTwoThirds} />
        <FullParagraphComponent {...fullParagraph} />
        <TagItemsGridComponent {...tagItems} />
        <FlexibleCardGridComponent {...flexibleCards} />
      </div>
    );
  };

  const tabsRenderer = () => {
    return tabs?.map((tab, index) => {
      const {
        tabLabel,
        textAndAssetTwoThirds = {},
        fullParagraph = {},
        tagItems = {},
        flexibleCards = {},
        tabId
      } = tab;
      const { textAndAssetPaddingTop } = textAndAssetTwoThirds;
      const { fullParagraphPaddingTop } = fullParagraph;
      const { tagItemsPaddingTop } = tagItems;
      const { flexibleCardsPaddingTop, imagePaddingBottom } = flexibleCards;

      return (
        <TabComponent className="tab-component-container" tabId={tabId} label={tabLabel} color={tabsColor} key={index}>
          <div
            className="components-container"
            style={{
              "--text-and-asset-padding-top": textAndAssetPaddingTop || DEFAULT_PADDING_TOP,
              "--full-paragraph-padding-top": fullParagraphPaddingTop || DEFAULT_PADDING_TOP,
              "--tag-items-padding-top": tagItemsPaddingTop || DEFAULT_PADDING_TOP,
              "--flexible-cards-padding-top": flexibleCardsPaddingTop || DEFAULT_PADDING_TOP,
              "--flexible-cards-padding-bottom": imagePaddingBottom
            }}
          >
            {componentsRenderer(fullParagraph, tagItems, textAndAssetTwoThirds, flexibleCards)}
          </div>
        </TabComponent>
      );
    });
  };

  const backgroundColor = Colors[tabsBackgroundColor];
  const underlineColor = Colors[tabsColor];
  const { autoTransition, scrollOnTabClick, scrollSpeed, distanceFromTop, activeTabId } = props;
  return (
    <div className={classnames("personas-tabs-component-container", { "align-to-logo": alignToLogo })}>
      <div
        className="personas-tabs-component"
        style={{ "--tab-background-color": backgroundColor, "--tab-underline-color": underlineColor }}
      >
        <TabsComponent
          autoTransition={autoTransition}
          scrollOnTabClick={scrollOnTabClick}
          scrollSpeed={scrollSpeed}
          distanceFromTop={distanceFromTop}
          activeTabId={activeTabId}
          role="tabpanel"
        >
          {tabsRenderer()}
        </TabsComponent>
        <style jsx>{componentStyles}</style>
      </div>
    </div>
  );
};

PersonasTabsComponent.defaultProps = DefaultProps;

export default PersonasTabsComponent;
