import css from "styled-jsx/css";
import { toRadians } from "utils/math";

export const triangleHeightFactor = Math.sin(toRadians(8));

export const componentStyles = css`
  .base-layout-segment-component {
    .triangle {
      position: absolute;
      z-index: 2;
      pointer-events: none;
      &.top {
        width: 0;
        height: 0;
        border-right: 100vw solid transparent;
        // the 0.05 addition solves a number rounding bug resulting in a 1px white line displayed  
        margin-top: ${`-${100 * triangleHeightFactor - 0.05}vw`};
      }
      &.bottom {
        width: 0;
        height: 0;
        border-left: 100vw solid transparent;
      }
    }
  }
`;
