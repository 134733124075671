export default {
  typedProps: {
    strings: ["This is typed string"],
    typeSpeed: 20,
    startDelay: 0,
    backSpeed: 20,
    backDelay: 700,
    smartBackspace: true,
    loop: false,
    shuffle: false
  }
};
