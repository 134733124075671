const SolutionsDataProvider = require("../../services/data-service/providers/solutions-data-provider");
const { getCachedData } = require("../../services/data-service/providers/base-data-provider");

const getMondaySolutionData = async (localeId) => {
  const provider = new SolutionsDataProvider({ localeId });
  const data = await getCachedData(provider);

  return data;
};

const getMondaySolutionById = async ({ localeId, solutionId }) => {
  const { solutions } = await getMondaySolutionData(localeId);
  const solution = solutions.find(({ id }) => {
    return `${id}` === `${solutionId}`;
  });
  return solution;
};

module.exports = {
  getMondaySolutionData,
  getMondaySolutionById
};
