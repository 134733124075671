import { directoryListData, socialButtons } from "../components";
import { defaultData } from "./default-data";

export const MULTIPLE_DIRECTORY_IMAGES_TEMPLATE_CONFIG = {
  socialButtons,
  header: {
    type: "nested",
    label: "Top section",
    fields: {
      hotDogColor: { type: "colorPicker", label: "Hotdog color" },
      textColor: { type: "colorPicker", label: "Text Color", possibleColors: ["black", "white"] },
      title: { type: "text", label: "Title" },
      subtitle: { type: "text", label: "Subtitle" },
      searchConfig: {
        type: "nested",
        fields: {
          show: { type: "boolean", label: "Show Search" },
          placeholder: { type: "text", label: "Search Placeholder" }
        }
      },
      img: {
        type: "gallery",
        label: "Select an image",
        defaultImagesKeys: ["static/img/templates/directory-images/header-decorators/"],
        options: [
          {
            value: "/static/img/header-decorators/app-and-cofee.png",
            text: "App and Cofee"
          },
          {
            value: "/static/img/header-decorators/computer-cofee.png",
            text: "Computer and Cofee"
          },
          {
            value: "/static/img/header-decorators/headphone-and-mouse.png",
            text: "Headphones and Mouse"
          },
          {
            value: "/static/img/header-decorators/papers-and-paint.png",
            text: "Papers and Paint"
          },
          {
            value: "/static/img/header-decorators/tablet-chocolat.png",
            text: "Tablet and Chocolat"
          }
        ]
      }
    }
  },
  directories: {
    type: "segments",
    label: "Directories",
    types: {
      directory: {
        type: "nested",
        fields: {
          title: { type: "text", label: "Title" },
          titleColor: { type: "colorPicker", label: "Title Color" },
          listData: directoryListData
        }
      }
    }
  },
  defaultData
};
