import { FIELD_TYPES } from "constants/template-field-type";
import TagFieldsConfig from "./components/tag/fields-config";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";

const { topic, buttonConfig, textAlign, textColor, ...selectedFullParagraphFieldsConfig } = FullParagraphFieldsConfig;

export default {
  tag: {
    type: FIELD_TYPES.NESTED,
    label: "Tag",
    fields: {
      ...TagFieldsConfig
    }
  },
  fullParagraph: {
    type: FIELD_TYPES.NESTED,
    label: "Full Paragraph",
    fields: {
      ...selectedFullParagraphFieldsConfig
    }
  }
};
