import { FIELD_TYPES } from "constants/template-field-type";
import SideBySideTextAndAssetFieldsConfig from "segments/desktop/core-components/side-by-side-text-and-asset/fields-config";
import YoutubeVideoWithPosterFields from "components/core/youtube-video-with-poster/fields-config";
import ThemeConfig from "segments/core-configs/theme-config";

const { asset, ...selectedSideBySideTextAndAssetFieldsConfig } = SideBySideTextAndAssetFieldsConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "Youtube Two Thirds",
  fields: {
    ...ThemeConfig,
    ...selectedSideBySideTextAndAssetFieldsConfig,
    youtubeWithPosterProps: {
      type: FIELD_TYPES.NESTED,
      label: "Youtube video",
      fields: YoutubeVideoWithPosterFields
    }
  }
};
