import { PureComponent } from "react";
import { componentStyles } from "./globe-with-top-cta-homepage-first-fold-component.scss";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import DefaultProps from "./default-props";
import AssetComponent from "components/core/asset/asset-component";
import TopLogosComponent from "segments/desktop/logos/layouts/top-logos/top-logos-component";

export default class GlobeWithTopCtaHomepageFirstFoldComponent extends PureComponent {
  render() {
    const { fullParagraph, asset, logos } = this.props;

    return (
      <div className="globe-with-top-cta-homepage-first-fold-component">
        <div className="full-paragraph-wrapper">
          <FullParagraph {...fullParagraph} />
        </div>
        <div className="asset-and-logos-wrapper">
          <div className="asset-wrapper">
            <AssetComponent {...asset} />
          </div>
          <div className="logos-wrapper">
            <TopLogosComponent {...logos} />
          </div>
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

GlobeWithTopCtaHomepageFirstFoldComponent.defaultProps = DefaultProps;
