import { FIELD_TYPES } from "constants/template-field-type";
import SelectableTagsTagsFieldsConfig from "segments/desktop/grid/layouts/selectable-tags-grid/selectable-tags-grid-config";
import ThemeConfig from "segments/core-configs/theme-config";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";

const { textColor, ...selectedFullParagraphFields } = FullParagraphFieldsConfig;
const { ...selectedSelectableTagsTagsFieldsConfig } = SelectableTagsTagsFieldsConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "Full Paragraph And Tags",
  fields: {
    ...ThemeConfig,
    marginAboveTags: {
      type: FIELD_TYPES.TEXT,
      label: "Margin between paragraph and tags"
    },
    fullParagraph: {
      type: FIELD_TYPES.NESTED,
      label: "Full Paragraph",
      fields: selectedFullParagraphFields
    },
    selectableTags: selectedSelectableTagsTagsFieldsConfig
  }
};
