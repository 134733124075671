import { FIELD_TYPES } from "constants/template-field-type";
import VerticalTabsFieldsConfig from "segments/desktop/core-components/vertical-tabs/fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Vertical",
  fields: {
    ...VerticalTabsFieldsConfig
  }
};
