import { PureComponent } from "react";
import { componentStyles } from "./cards-grid-desktop-component.scss";
import GridImageWithParagraph from "segments/desktop/core-components/grid-image-with-paragraph/grid-image-with-paragraph";
import cardsGridDefaultProps from "../default-props";

const IMAGE_HEIGHT = "100%";
const IMAGE_WIDTH = "100%";

export default class CardsGridDesktopComponent extends PureComponent {
  renderGridItems() {
    const { gridItems, titleSize, textSize } = this.props;

    return gridItems.map((gridItem, index) => {
      const { fullParagraph, ...resImageProps } = gridItem;

      return (
        <div key={index} className="grid-image-with-paragraph-component-wrapper">
          <GridImageWithParagraph
            {...resImageProps}
            fullParagraph={fullParagraph}
            width={IMAGE_WIDTH}
            height={IMAGE_HEIGHT}
            titleSize={titleSize}
            textSize={textSize}
          />
        </div>
      );
    });
  }

  render() {
    return (
      <div className="cards-grid-desktop-component">
        {this.renderGridItems()}

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

CardsGridDesktopComponent.defaultProps = cardsGridDefaultProps;
