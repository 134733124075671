import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, MD_DESKTOP_WIDTH } from "constants/vp-sizes";
import Colors from "styles/colors";


export const componentStyles = css.global`
  .sign-up-with-products-cards-homepage-first-fold-component {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    margin: auto;
    display: flex;
    flex-direction: column;
    .title-wrapper{
      display: flex;
      flex-direction: column;
      gap: 20px;
      text-align: center;
      padding-top: 50px;
      .core-title{
        font-size: 2.531rem;
        line-height: 56px;
      }
      .paragraph-body{
        font-size: 1.375rem;
      }
    }
    .cards-wrapper{
    display: flex;
    min-height: 470px;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
    padding-top: 50px;
    }
    .buttons-wrapper{
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 20px;
      .signup-button{
        width: 240px;
        line-height: 20px;
      }
    }
    .text-under-buttons{
      text-align: center;
      padding-top: 14px;
      color: ${Colors.black};
      .paragraph-body{
      font-size: 0.875rem;
      }
    }
  }

  @media (max-width: ${MD_DESKTOP_WIDTH}) {
    .sign-up-with-products-cards-homepage-first-fold-component {
      .title-wrapper{
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-top: 10px;
      }
      .cards-wrapper{
        padding-bottom: 10px;
        padding-top: 10px;
        min-height: 440px;
        }
        .text-under-buttons{
          text-align: center;
          padding-top: 10px;
        }
    }
  }
`;
