import { useEffect, useRef, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import MobileTabNavPlainButton from "segments/desktop/core-components/mobile-tabs/mobile-tab-nav-plain-button";
import { componentStyles as mobileTabsNavigationComponentStyles } from "segments/desktop/core-components/mobile-tabs/mobile-tabs-navigation.scss";
import { trackEvent } from "services/bigbrain-service";
import { DEMO_ASSET_CLICKED, DEMO_ASSET_LEFT_SIDE_CLICKED, DEMO_STEP_CLICKED } from "constants/bigbrain-event-types";
import { componentStyles } from "./monday-demo-tabs-mobile-component.scss";
import MondayDemoTabsAsset from "../monday-demo-tab-asset/monday-demo-tabs-asset";
import MondayDemoMobileTimeline from "../monday-demo-mobile-timeline/monday-demo-mobile-timeline";

const MondayDemoTabsMobileComponent = (props) => {
  const { tabs } = props;
  const [selectedTab, setSelectedTab] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [wasComponentEverVisible, setWasComponentEverVisible] = useState(false);
  const [videoDurations, setVideoDurations] = useState([]);
  const itemsRef = useRef([]);
  const { theme } = props;

  const VISIBILITY_SENSOR_OFFSET = -200;

  const tabsWithVideoPlayerRefs = [];
  tabs.forEach((tab) => {
    tabsWithVideoPlayerRefs.push({
      ...tab,
      videoPlayerRef: useRef(null)
    });
  });

  useEffect(() => {
    const currentTab = tabsWithVideoPlayerRefs[selectedTab];
    if (currentTab?.videoPlayerRef?.current) {
      currentTab.videoPlayerRef.current.seekTo(0);
    }
    tabsWithVideoPlayerRefs?.[selectedTab]?.asset?.video?.videoPlayerRef?.current?.seekTo(0);
  }, [selectedTab]);

  const scrollToNavigationTabIfVisible = (index) => {
    if (isVisible) {
      itemsRef?.current?.[index]?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
    }
  };

  const onTabNavButtonClick = ({ tab, index }) => {
    trackEvent(DEMO_STEP_CLICKED, {
      kind: tab.tabLabel,
      info1: index
    });
    scrollToNavigationTabIfVisible(index);
    setSelectedTab(index);
  };

  const navigationItemRenderer = (tab, index) => {
    return (
      <div
        className="tab-nav-button-wrapper"
        ref={(element) => (itemsRef.current[index] = element)}
        onClick={() => onTabNavButtonClick({ tab, index })}
      >
        <MobileTabNavPlainButton
          tabNumber={index + 1}
          label={tab.tabLabel}
          selected={selectedTab === index}
          theme={theme}
        />
      </div>
    );
  };

  const onVideoEnded = (index) => {
    if (index < tabsWithVideoPlayerRefs.length - 1) {
      const nextTabIndex = index + 1;
      setSelectedTab(nextTabIndex);
      scrollToNavigationTabIfVisible(nextTabIndex);
    }
  };

  const setVideoDuration = (index, duration) => {
    if (videoDurations[index] !== duration) {
      const newVideoDurations = [...videoDurations];
      newVideoDurations[index] = duration;
      setVideoDurations(newVideoDurations);
    }
  };

  const onVisibilityChange = (isVisibleValue) => {
    if (!wasComponentEverVisible && isVisibleValue) {
      setWasComponentEverVisible(true);
    }

    setIsVisible(isVisibleValue);
  };

  const onAssetClick = () => {
    trackEvent(DEMO_ASSET_CLICKED, {
      kind: tabsWithVideoPlayerRefs?.[selectedTab]?.tabLabel,
      info1: selectedTab
    });
    const nextTabIndex = Math.min(selectedTab + 1, tabsWithVideoPlayerRefs.length - 1);
    setSelectedTab(nextTabIndex);
    scrollToNavigationTabIfVisible(nextTabIndex);
  };

  const onLeftSideAssetClick = (e) => {
    e?.stopPropagation();
    e?.preventDefault();
    trackEvent(DEMO_ASSET_LEFT_SIDE_CLICKED, {
      kind: tabsWithVideoPlayerRefs?.[selectedTab]?.tabLabel,
      info1: selectedTab
    });
    const nextTabIndex = Math.max(selectedTab - 1, 0);
    setSelectedTab(nextTabIndex);
    scrollToNavigationTabIfVisible(nextTabIndex);
  };

  const assetItemRenderer = (tab, index) => {
    const isSelected = index === selectedTab;
    return (
      <div className="monday-demo-tabs-asset-click-wrapper" onClick={onAssetClick}>
        <div className="monday-demo-tabs-asset-back-click-wrapper" onClick={onLeftSideAssetClick} />
        <MondayDemoTabsAsset
          selectedTab={selectedTab}
          index={index}
          isCurrent={isSelected}
          asset={tab.asset}
          onVideoEnded={() => onVideoEnded(index)}
          autoPlay={isSelected && isVisible}
          setVideoDuration={(duration) => setVideoDuration(index, duration)}
        />
      </div>
    );
  };

  return (
    <VisibilitySensor
      offset={{ bottom: VISIBILITY_SENSOR_OFFSET, top: VISIBILITY_SENSOR_OFFSET }}
      partialVisibility={true}
      onChange={onVisibilityChange}
      active={!wasComponentEverVisible}
      scrollCheck={!wasComponentEverVisible}
    >
      <div className="monday-demo-tabs-mobile-component">
        <MondayDemoMobileTimeline
          theme={theme}
          tabs={tabsWithVideoPlayerRefs}
          selectedTab={selectedTab}
          wasComponentEverVisible={wasComponentEverVisible}
          videoDuration={videoDurations[selectedTab]}
        />
        <div className="monday-demo-tabs-assets-wrapper">
          {tabsWithVideoPlayerRefs.map((tab, index) =>
            // get videoDurations by order, render everything only when component is visible
            wasComponentEverVisible && index <= videoDurations.length ? assetItemRenderer(tab, index) : null
          )}
        </div>
        <div className="mobile-tabs-navigation">
          {tabsWithVideoPlayerRefs.map((tab, index) => navigationItemRenderer(tab, index))}
        </div>
        <style jsx>{mobileTabsNavigationComponentStyles}</style>
        <style jsx>{componentStyles}</style>
      </div>
    </VisibilitySensor>
  );
};

export default MondayDemoTabsMobileComponent;
