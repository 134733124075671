import dynamic from "next/dynamic";

const HubspotSideBySideFormComponent = require("./hubspot-side-by-side-form-component").default;

import HubspotSideBySideFormConfig from "./hubspot-side-by-side-form-config";
import HubspotSideBySideFormAdvancedConfig from "./hubspot-side-by-side-form-advanced-config";

export default {
  HubspotSideBySideFormComponent,
  HubspotSideBySideFormConfig,
  HubspotSideBySideFormAdvancedConfig
};
