import classnames from "classnames";
import TwoThirdsTextAndAssetComponent from "segments/desktop/text-and-asset/layouts/two-thirds-text-and-asset/two-thirds-text-and-asset-component";
import colors from "styles/colors";
import { getUrlWithColor } from "services/community/cloudinary-service";
import { componentStyles } from "./building-blocks-tab-content-component.scss";
import { SM } from "constants/sizes";

const BuildingBlocksTabContentComponent = (props) => {
  const { tab, isSelected, themeColor, isAssetVisible, isMobileDisplay } = props;

  const getTextAndAssetProps = () => ({
    ...tab.textAndAsset,
    hideControls: true,
    asset: isAssetVisible
      ? {
          ...tab.textAndAsset.asset,
          video: {
            ...tab.textAndAsset.asset.video,
            videoPlayerRef: tab.videoPlayerRef
          }
        }
      : {},
    fullParagraph: {
      ...tab.textAndAsset.fullParagraph,
      titleIcon: getUrlWithColor({
        url: tab?.tabIcon,
        color: colors[themeColor]
      }),
      titleColor: themeColor,
      title: isMobileDisplay ? "" : tab.textAndAsset.fullParagraph.title
    },
    textSize: isMobileDisplay ? SM : tab.textAndAsset.fullParagraph.textSize
  });

  const textAndAssetProps = getTextAndAssetProps();

  return (
    <div className={classnames("building-blocks-content-wrapper", { selected: isSelected })}>
      <TwoThirdsTextAndAssetComponent {...textAndAssetProps} useWindowMask={tab.textAndAsset.asset.useWindowMask} />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

BuildingBlocksTabContentComponent.defaultProps = {
  isSelected: true,
  isAssetVisible: true
};

export default BuildingBlocksTabContentComponent;
