import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import BoardViewsTab from "segments/desktop/core-components/board-views-tab/board-views-tab";
import { componentStyles } from "./side-by-side-board-views-component-tabs-desktop-component.scss";

const SideBySideBoardViewsComponentTabsDesktopComponent = (props) => {
  const { fullParagraph, theme, tabs } = props;

  return (
    <div className="side-by-side-board-views-component-tabs-component">
      <div className="full-paragraph-wrapper">
        <FullParagraph {...fullParagraph} />
      </div>
      <div className="board-views-tab-wrapper">
        <BoardViewsTab theme={theme} tabs={tabs} />
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default SideBySideBoardViewsComponentTabsDesktopComponent;
