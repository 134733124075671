import css from "styled-jsx/css";
import { WHITE_COLOR_NAME, BRAND_BLACK_TEXT_COLOR } from "styles/color-consts";
import Colors from "styles/colors";

export const componentStyles = css.global`
  .toggle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: var(--background-color);
    border-radius: 30px;
    cursor: pointer;
    width: 500px;
    font-size: 0.875rem;
    .toggle-left {
      width: 250px;
      background: var(--switch-color);
      padding: 12px 20px;
      border-radius: 30px;
      color: ${Colors[WHITE_COLOR_NAME]};
      text-align: center;
    }
    .toggle-right {
      width: 250px;
      padding: 12px 20px;
      border-radius: 30px;
      color: ${Colors[BRAND_BLACK_TEXT_COLOR]};
      text-align: center;
      text-size: 16px;
    }
    &.switch-texts-colors {
      .toggle-left {
        color: ${Colors[BRAND_BLACK_TEXT_COLOR]};
      }
      .toggle-right {
        color: ${Colors[WHITE_COLOR_NAME]};
      }
    }
    &.on {
      .toggle-left {
        background: unset;
        color: ${Colors[BRAND_BLACK_TEXT_COLOR]};
      }
      .toggle-right {
        background: var(--switch-color);
        color: ${Colors[WHITE_COLOR_NAME]};
      }
      &.switch-texts-colors {
        .toggle-left {
          color: ${Colors[WHITE_COLOR_NAME]};
        }
        .toggle-right {
          color: ${Colors[BRAND_BLACK_TEXT_COLOR]};
        }
      }
    }
  }
`;
