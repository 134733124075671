import { PureComponent } from "react";
import Logos from "segments/desktop/core-components/logos/logos";
import LogosTitleComponent from "segments/desktop/logos/components/logos-title/logos-title-component";
import { componentStyles } from "./bottom-logos-component.scss";

export default class BottomLogosComponent extends PureComponent {
  getTitleStyle = () => {
    const { textAndLogosMargin } = this.props;
    if (textAndLogosMargin) {
      return {
        marginTop: textAndLogosMargin
      };
    }
    return null;
  };

  render() {
    const { title, logos } = this.props;
    const titleStyle = this.getTitleStyle();

    return (
      <div className="bottom-logos-component">
        <Logos {...logos} />
        <div className="logosTitleWrapper" style={titleStyle}>
          <LogosTitleComponent title={title} />
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

BottomLogosComponent.defaultProps = {
  logos: Logos.defaultProps,
  ...LogosTitleComponent.defaultProps,
  paddingTop: "68px",
  paddingBottom: "68px",
  mobilePaddingTop: "24px",
  mobilePaddingBottom: "24px",
  textAndLogosMargin: "24px"
};
