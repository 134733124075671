// plop_marker:layouts_import
import SideBySideTabsAndProcessTabs from "./side-by-side-tabs-and-process-tabs";
import SideBySideTabsAndTestimonialTabs from "./side-by-side-tabs-and-testimonial-tabs";
import PersonasTabs from "./personas-tabs";
import SideBySideBoardViewsComponentTabs from "./side-by-side-board-views-component-tabs";
import UseCasesTabs from "./use-cases-tabs";
import MondayDemoTabs from "./monday-demo-tabs";
import BuildingBlocksTabs from "./building-blocks-tabs";
import BoardTabs from "./board-tabs";
import TimelineTabs from "./timeline-tabs";
import ColoredTimerVerticalTabs from "./colored-timer-vertical-tabs";
import TimerVerticalTabs from "./timer-vertical-tabs";
import VerticalTabs from "./vertical-tabs";
import RegularTabs from "./regular-tabs";

export default {
  // plop_marker:layouts_add_layout
  SideBySideTabsAndProcessTabs,
  SideBySideTabsAndTestimonialTabs,
  PersonasTabs,
  SideBySideBoardViewsComponentTabs,
  UseCasesTabs,
  MondayDemoTabs,
  BuildingBlocksTabs,
  BoardTabs,
  TimelineTabs,
  ColoredTimerVerticalTabs,
  TimerVerticalTabs,
  VerticalTabs,
  RegularTabs,
};
