import { useState, useMemo, useCallback, useEffect } from "react";
import { componentStyles } from "./vertical-tags-with-images-asset-component.scss";
import SelectableTagsVerticalGrid from "segments/desktop/core-components/selectable-tags-vertical-grid/selectable-tags-vertical-grid";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import PictureComponent from "components/core/picture/picture-component";
import ArrowSpiralIcon from "/static/svg/arrow-spiral.svg";
import { getCloudinaryUrlSrc } from "/services/community/cloudinary-service";

const VerticalTagsWithImagesAssetComponent = (props) => {
  const {
    paragraphText,
    paragraphTagsPadding,
    titleWidth,
    withDynamicParagraphTitle,
    withDynamicParagraphSubtitle,
    firstStateText,
    withFirstStateArrow,
    isMobileOrTabletBrowser,
    gridSelectableTags
  } = props;

  const [currentTag, setCurrentTag] = useState();

  // Prefetch images
  useEffect(() => {
    if (!gridSelectableTags) return;

    for (const tag of gridSelectableTags) {
      const { galleryImage } = tag;

      if (!galleryImage) continue;

      const { src: rawSrc } = galleryImage;
      const src = getCloudinaryUrlSrc(rawSrc);

      const img = new Image();
      img.src = src;
    }
  }, [gridSelectableTags]);

  const onTagHover = useCallback(
    (tag = {}) => {
      setCurrentTag(tag);
    },
    [setCurrentTag]
  );

  const tagsGridWrapperStyle = useMemo(() => {
    return {
      paddingTop: paragraphTagsPadding
    };
  }, [paragraphTagsPadding]);

  const renderArrowSVG = () => {
    if (!withFirstStateArrow) return null;

    return <ArrowSpiralIcon />;
  };

  const renderFirstState = () => {
    return (
      <div className="vertical-tags-preview-empty-state">
        <FullParagraph {...firstStateText} />
        <div className="vertical-tags-preview-arrow-wrapper">{renderArrowSVG()}</div>
      </div>
    );
  };

  const previewComponent = useCallback(() => {
    if (!currentTag?.galleryImage) return renderFirstState();

    const { galleryImage } = currentTag;
    const { alt: imgText } = galleryImage;

    return (
      <div className="preview-component-wrapper">
        {imgText && <div className="preview-component-image-text" dangerouslySetInnerHTML={{ __html: imgText }} />}
        <PictureComponent {...galleryImage} specificImageStyle={{ height: 284, marginTop: 8 }} />
      </div>
    );
  }, [currentTag, renderFirstState]);

  // Currently prevent from being rendered over tablet or mobile devices
  if (isMobileOrTabletBrowser) return null;

  return (
    <div className="vertical-tags-with-images-asset-component">
      <FullParagraph
        {...paragraphText}
        titleWidth={titleWidth}
        withDynamicParagraphTitle={withDynamicParagraphTitle}
        withDynamicParagraphSubtitle={withDynamicParagraphSubtitle}
      />
      <div className="vertical-tags-with-images-grid">
        <div className="selectable-tags-grid-wrapper" style={tagsGridWrapperStyle}>
          <SelectableTagsVerticalGrid {...props} onTagHover={onTagHover} SideComponent={previewComponent} />
        </div>
      </div>

      <style jsx>{componentStyles}</style>
    </div>
  );
};

VerticalTagsWithImagesAssetComponent.defaultProps = {
  withFirstStateArrow: true
};

export default VerticalTagsWithImagesAssetComponent;
