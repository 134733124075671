import { PureComponent } from "react";
import SideBySideTextAndAssetComponent from "segments/desktop/text-and-asset/layouts/side-by-side-text-and-asset/side-by-side-text-and-asset-component";
import { WORKOS_IRIS_THEME_ID } from "segments/segments-service";
import { componentStyles } from "./side-by-side-with-background-text-and-asset-component.scss";

export default class SideBySideWithBackgroundTextAndAssetComponent extends PureComponent {
  getStyle = () => {
    const { minHeight } = this.props;
    if (!minHeight) return {};

    return { minHeight };
  };

  render() {
    return (
      <div className="side-by-side-with-background-text-and-asset-component" style={this.getStyle()}>
        <div className="side-by-side-asset-wrapper">
          <SideBySideTextAndAssetComponent {...this.props} />
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

SideBySideWithBackgroundTextAndAssetComponent.defaultProps = {
  ...SideBySideTextAndAssetComponent.defaultProps,
  background: {
    width: "75%",
    color: WORKOS_IRIS_THEME_ID
  },
  asset: {
    ...SideBySideTextAndAssetComponent.defaultProps.asset,
    position: "center",
    image: {
      ...SideBySideTextAndAssetComponent.defaultProps.asset.image,
      src: "/static/img/segment-layout-default-images/text-and-asset/designers-sit-together.png"
    }
  },
  paddingTop: "0px",
  paddingBottom: "0px",
  minHeight: "650px"
};
