import { PureComponent } from "react";
import { componentStyles } from "./testimonials-text-and-asset-component.scss";
import FullTestimonialsQuote from "segments/desktop/core-components/full-testimonials-quote/full-testimonials-quote";
import SideBySideTextAndAsset from "segments/desktop/core-components/side-by-side-text-and-asset/side-by-side-text-and-asset";
import { MD } from "constants/sizes";
import { AUTO } from "constants/cloudinary-asset-qualities";

export default class TestimonialsTextAndAssetComponent extends PureComponent {
  render() {
    return (
      <div className="side-by-side-text-and-asset-component">
        <SideBySideTextAndAsset replaceTextComponent={FullTestimonialsQuote} {...this.props} />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

TestimonialsTextAndAssetComponent.defaultProps = {
  paddingTop: "64px",
  paddingBottom: "64px",
  titleSize: MD,
  asset: {
    position: "center",
    fitToContainer: true,
    image: {
      src: "/static/img/segment-layout-default-images/text-and-asset/testimonials.png"
    }
  },
  assetQuality: AUTO,
  fullParagraph: { ...FullTestimonialsQuote.defaultProps }
};
