import { defaultData } from "./default-data";

// plop_marker:segments_import

export const MOBILE_DYNAMIC_TEMPLATE_CONFIG = {
  segments: [
    // plop_marker:add_segment_config
  ],
  defaultData
};
