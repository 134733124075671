import { comparisonTable } from "../components";
import { FIELD_TYPES } from "constants/template-field-type";
import { SUPPORTED_BUTTON_COLORS } from "components/core/button/button-component";

export const ARTICLE_TEMPLATE_CONFIG = {
  nameSignature: { type: "text", label: "Sign Your Name (optional)" },
  socialButtons: {
    type: "nested",
    label: "Social Buttons",
    fields: {
      show: { type: "boolean", label: "Show social buttons" },
      color: {
        type: "colorPicker",
        label: "Text Color",
        possibleColors: ["black", "white"]
      },
      blogStyle: { type: "boolean", label: "Blog style" }
    }
  },
  topbar: {
    type: "nested",
    label: "Topbar Config",
    fields: {
      preventMenu: { type: "boolean", label: "Prevent Menu" }
    }
  },
  headerStrip: {
    type: "nested",
    label: "Top section",
    fields: {
      title: { type: "text", label: "Title" },
      subtitle: { type: "text", label: "Subtitle" },
      preventSignup: { type: "boolean", label: "Prevent Signup" },
      asset: {
        type: "nested",
        label: "Image",
        fields: {
          src: {
            type: "gallery",
            label: "Select an image",
            defaultImagesKeys: ["static/img/templates/articale/strips/"],
            options: [
              { value: "/static/img/strips/strip-hands.jpg", text: "Hands" },
              {
                value: "/static/img/strips/strip-computer-board.jpg",
                text: "Board"
              },
              {
                value: "/static/img/strips/strip-people-table.jpg",
                text: "Table"
              },
              {
                value: "/static/img/strips/strip-photographer.jpg",
                text: "Photographer"
              },
              {
                value: "/static/img/strips/strip-working-together.jpg",
                text: "Working Together"
              }
            ]
          },
          alt: {
            type: "text",
            label: "Image description (will not be visible)"
          }
        }
      }
    }
  },
  parts: {
    type: "segments",
    types: {
      paragraph: {
        type: "nested",
        label: "Paragraph",
        fields: {
          title: { type: "text", label: "Title (optional)" },
          content: { type: "text", label: "Content", textArea: true }
        }
      },
      signup: {
        type: "nested",
        label: "Signup Form",
        fields: {
          title: { type: "text", label: "Title" }
        }
      },
      image: {
        type: "nested",
        label: "Image",
        fields: {
          src: {
            type: "gallery",
            label: "Select an image",
            defaultImagesKeys: ["static/img/templates/articale/segments/"],
            options: [
              {
                value: "/static/img/boards/board-with-cactus-and-phone.png",
                text: "Board with cactus and phone"
              },
              {
                value: "/static/img/boards/board-with-column-picker.jpg",
                text: "Board with column picker"
              },
              {
                value: "/static/img/boards/board-with-conversation.png",
                text: "Board with conversation"
              },
              {
                value: "/static/img/boards/board-with-earphones.png",
                text: "Board with earphones"
              },
              {
                value: "/static/img/boards/board-with-notifications.jpg",
                text: "Board with notifications"
              },
              {
                value: "/static/img/boards/board-with-pencils.png",
                text: "Board with pencils"
              },
              {
                value: "/static/img/boards/board-with-person-picker.jpg",
                text: "Board with person picker"
              },
              {
                value: "/static/img/boards/board-with-status-menu.jpg",
                text: "Board with status menu"
              },
              {
                value: "/static/img/boards/board-with-yellow-coffee-cup.png",
                text: "Board with yellow coffee cup"
              },
              {
                value: "/static/img/boards/cactus-with-board.png",
                text: "Board with cactus"
              },
              {
                value: "/static/img/boards/upload-file-board.jpg",
                text: "Board with upload file"
              },

              {
                value: "/static/img/boards/board-with-pencils-and-earphones.png",
                text: "Board with pencils and earphones"
              },
              {
                value: "/static/img/boards/board-with-timeline-and-cactus.png",
                text: "Board with timeline and cactus"
              },
              {
                value: "/static/img/boards/board-with-green-bricks.png",
                text: "Board with green bricks"
              },
              {
                value: "/static/img/header/phone-app-with-desk.jpg",
                text: "Mobile app with desk"
              },
              { value: "/static/img/header/phone-app.jpg", text: "Mobile app" },
              {
                value: "/static/img/header/desktop.jpg",
                text: "Laptop on a desk"
              },
              {
                value: "/static/img/header/leah-joel.jpg",
                text: "Leah & Joel"
              },
              { value: "/static/img/header/stav.jpg", text: "Stav" },
              {
                value: "/static/img/header/emily.jpg",
                text: "Emily with timeline"
              },
              { value: "/static/img/header/lupo.jpg", text: "Lupo" },
              {
                value: "/static/img/header/asaf-aviram.jpg",
                text: "Asaf & Aviram"
              },
              {
                value: "/static/img/header/aviram-lior.jpg",
                text: "Aviram & Lior"
              },
              { value: "/static/img/header/elina.jpg", text: "Elina" },
              { value: "/static/img/header/tan.jpg", text: "Tan" }
            ],
            otherOption: {
              text: "Put your own URL...",
              inputPlaceholder: "Put your own URL..."
            }
          },
          alt: {
            type: "text",
            label: "Image description (will not be visible)"
          }
        }
      },
      highlight: {
        type: "nested",
        label: "Highlight / Quote",
        fields: {
          content: { type: "text", textArea: true }
        }
      },
      comparisonTable: comparisonTable
    }
  },
  footerSignup: {
    type: "nested",
    label: "Bottom Signup Form",
    fields: {
      preventSignup: { type: "boolean", label: "Prevent Signup" },
      title: { type: "text", label: "Title" },
      subtitle: { type: "text", label: "Subtitle" }
    }
  },
  footer: {
    type: "nested",
    label: "Footer Config",
    fields: {
      preventFooter: { type: "boolean", label: "Prevent Footer" },
      disclaimer: { type: FIELD_TYPES.TEXT, label: "Disclaimer" }
    }
  },
  defaultData: {
    template: "ArticleTemplate",
    title: "Article Page Example Title",
    description: "Article Page Example Description",
    headerStrip: {
      title: "We’ve made a simple and visual CRM tool",
      subtitle: "Manage your workload, communicate with your team, and celebrate success",
      asset: {
        assetType: "picture",
        src: "/static/img/strips/strip-hands.jpg"
      }
    },
    footerSignup: {
      title: "Start using monday.com today",
      subtitle: "Try it now for free / No credit card needed"
    },
    parts: []
  }
};
