import IframeDynamicWidthIframeComponent from "segments/desktop/iframe/layouts/iframe-dynamic-width-iframe/iframe-dynamic-width-iframe-component";
import { componentStyles } from "./monday-form-form-component.scss";

const MondayFormFormComponent = (props) => {
  const MONDAY_FORMS_BASE_URL = "https://forms.monday.com/forms/";
  const { formId } = props;

  const getMondayFormLink = () => {
    if (formId) {
      return `${MONDAY_FORMS_BASE_URL}${formId}`;
    }
    return null;
  };

  const formLink = getMondayFormLink();

  return (
    <div className="monday-form-form-component">
      <IframeDynamicWidthIframeComponent {...props} src={formLink} width={"100%"} maxWidth={"100%"} height={"100vh"}/>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default MondayFormFormComponent;
