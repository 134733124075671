import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
import AssetAdvancedConfig from "components/core/asset/advanced-field-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "",
  fields: {
    ...SharedAdvancedFieldsConfig,
    width: { type: FIELD_TYPES.TEXT, label: "Asset width in desktop (% only)" },
    mobileWidth: { type: FIELD_TYPES.TEXT, label: "Asset width in mobile (% only)" },
    ...AssetAdvancedConfig,
    fullWidth: { type: FIELD_TYPES.BOOLEAN, label: "Disable maximum width limitation (1440px)" }
  }
};
