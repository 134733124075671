import { SPACE_BLUE_THEME_ID } from "segments/segments-service";
import { BRAND_PRIMARY_GREEN, DEV_DARKER_TINT_02, WHITE_COLOR_NAME } from "styles/color-consts.js";
import { XS, MD } from "constants/sizes";

export default {
  theme: SPACE_BLUE_THEME_ID,
  nestedBoardItems: {
    boardBannerText: "Sign up for free to gain full access to all features",
    embeddedBoardTitle: "Project plan",
    addSecondLiveBoardSignUpButton: false
  },
  circleConfig: {
    circleTitle: "Try the<br>interactive demo",
    circleTitleColor: BRAND_PRIMARY_GREEN,
    circleTitleSize: XS,
    circleText: "See how easy it is to manage<br>your work with monday.com",
    circleTextColor: WHITE_COLOR_NAME,
    circleColor: DEV_DARKER_TINT_02,
    buttonText: "Let's go",
    buttonColor: WHITE_COLOR_NAME
  },
  backgroundImage:
    "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/boards/v2_boards.png",
  boardImage:
    "http://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/boards/main_board.png"
};
