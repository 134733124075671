import dynamic from "next/dynamic";

const TestimonialsTextAndAssetComponent = require("./testimonials-text-and-asset-component").default;

import TestimonialsTextAndAssetConfig from "./testimonials-text-and-asset-config";
import TestimonialsTextAndAssetAdvancedConfig from "./testimonials-text-and-asset-advanced-config";

export default {
  TestimonialsTextAndAssetComponent,
  TestimonialsTextAndAssetConfig,
  TestimonialsTextAndAssetAdvancedConfig
};
