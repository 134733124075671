import { PureComponent } from "react";
import { baseDocumentationMenuStyles } from "./base-documentation-menu-component.scss";
import classnames from "classnames";
import MenuConstants from "./constants";
import DownArrow from "/static/svg/arrow-down.svg";
import RightArrow from "static/svg/arrow-right.svg"

// sections props is an array with each time in this structure:
/*
{
      name: "section name",
      href: "/test",
      isSelected: true,
      isUncollapsed: false,                                               //default is false, support for multiple nested menus like API documentation
      subObjects:  [{name, href,isSelected, isUncollapsed, subObjects}]    //array of similar structure objects
}
*/

export default class BaseDocumentationMenuComponent extends PureComponent {
    constructor(props) {
        super(props);
    }

    renderSection = (section, fatherUncollapsed = true) => {
        if (!section.links) return this.renderLink(section, fatherUncollapsed);
        return (
            <div key={section.title} className="documentation-menu-section">
                <div className="documentation-menu-section-title">{section.title}</div>
                <div className="documentation-menu-section-links">{section.links.map(section => this.renderLink(section, fatherUncollapsed))}</div>
            </div>
        );
    };

    onClickArrow = (e, section) => {
        const {toggleCollapse} = this.props;
        e.stopPropagation();
        e.preventDefault();
        toggleCollapse(section);
    }


    renderArrow = (isUncollapsed, section, tabIndex) => {
        return <div className="arrow-wrapper" onClick={e => this.onClickArrow(e, section)} onKeyPress={e => this.onClickArrow(e, section)} tabIndex={tabIndex}>
                {isUncollapsed ? <DownArrow/> : <RightArrow/>}
            </div>
    }

    renderLinkWithCondition = (section, fatherUncollapsed) => {
        const { name, href, isSelected, isUncollapsed, subObjects, target } = section;
        const tabIndex = fatherUncollapsed ? 0 : -1
        return subObjects ?
            <a className={classnames("menu-link", {"selected-link": isSelected})}
               tabIndex={tabIndex}
               href={href} target={target}>
                {this.renderArrow(isUncollapsed, section, tabIndex)}
                {name}
            </a>
        :
            <a className={classnames("menu-link", {"selected-link": isSelected})} href={href} target={target}
               tabIndex={tabIndex}>
                {name}
            </a>
    }

    renderLink = (section, fatherUncollapsed) => {
        const { name, href, isUncollapsed, subObjects} = section;
        return (
            name && <div key={href} className="documentation-menu-link">
                <div className="documentation-menu-link-wrapper">
                    {this.renderLinkWithCondition(section, fatherUncollapsed)}
                </div>
                <div className={classnames({uncollapsed: isUncollapsed}, "documentation-menu-inner")}>
                    {subObjects && subObjects.map((subObject) => this.renderSection(subObject, isUncollapsed))}
                </div>
            </div>
        );
    };

    getStyle = () => {
        const { menuWidth } = this.props;
        return { width: menuWidth };
    };

    render() {
        const { sections } = this.props;
        return (
            <div className="documentation-menu" role="list" style={this.getStyle()}>
                {sections.map(section => this.renderSection(section, true))}
                <style jsx>{baseDocumentationMenuStyles}</style>
            </div>
        );
    }
}

BaseDocumentationMenuComponent.defaultProps = {
    menuWidth: MenuConstants.BASE_MENU_WIDTH
};
