import css from "styled-jsx/css";
import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import colors from "styles/colors";

const IMAGE_SIZE = 113;

export const componentStyles = css.global`
  .paragraph-and-carousel-reviews-mobile-component {
    .carousel-wrapper {
      margin-top: var(--margin-between-paragraph-and-carousel);
      .review-carousel-item {
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .customer-image-wrapper {
          display: flex;
          align-items: center;
          position: absolute;
          justify-content: center;
          .customer-image {
            width: ${`${IMAGE_SIZE}px`};
            border-radius: ${`${IMAGE_SIZE / 2}px`};
          }
        }
        .review-card {
          color: white;
          margin-top: ${`${(IMAGE_SIZE * 2) / 5}px`};
          padding: ${`${(IMAGE_SIZE * 4) / 5}px`} 12px 0 12px;
          background-color: ${colors[WORK_OS_IRIS_COLOR_NAME]};
          border-radius: 8px;
          text-align: center;
          .name-and-job-description {
            font-size: 0.875rem;
            font-weight: 500;
            min-height: 64px;
          }
          .quote {
            font-size: 0.8125rem;
            padding: 16px 0;
            min-height: 112px;
          }
          .company-logo-wrapper {
            padding: 0 0 24px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            min-height: 96px;
            .company-logo {
              width: 92px;
            }
          }
        }
      }
    }
  }
`;
