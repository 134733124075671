import {
  signupForm,
  socialButtons,
  collaborationWith,
  onePagerTextPart,
  onePagerImageWithBackgroundPart,
  footer,
  topbar
} from "../components";

import { defaultData } from "./default-data";

export const MOBILE_ONE_PAGER_WITH_IMAGE_TEMPLATE_CONFIG = {
  topbar,
  footer,
  socialButtons,
  imageWithBackgroundPart: onePagerImageWithBackgroundPart,
  textPart: onePagerTextPart,
  signupForm,
  collaborationWith,
  defaultData
};
