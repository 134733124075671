import { FIELD_TYPES } from "constants/template-field-type";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import TopLogosFieldsConfig from "segments/desktop/logos/layouts/top-logos/top-logos-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Globe With Top Cta",
  fields: {
    fullParagraph: {
      type: FIELD_TYPES.NESTED,
      label: "Full Paragraph",
      fields: FullParagraphFieldsConfig
    },
    logos: { ...TopLogosFieldsConfig }
  }
};
