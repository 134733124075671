import Layouts from "./layouts";
import SegmentFamilies from "constants/segment-families";

const {
  // plop_marker:segment_index_import_layout
  HotdogShape,
  RingShape,
  StarShape,
  RectangleShape,
  SquareShape,
} = Layouts;

export default { 
  // plop_marker:segment_index_add_layout
  HotdogShapeComponent: {
    family: SegmentFamilies.SHAPE,
    type: "HotdogShapeComponent",
    title: "Hotdog",
    imgSrc: "/static/img/segments/layouts/hotdog-shape.png",
    config: HotdogShape.HotdogShapeConfig,
    advancedConfig: HotdogShape.HotdogShapeAdvancedConfig,
    component: HotdogShape.HotdogShapeComponent
  },
  RingShapeComponent: {
    family: SegmentFamilies.SHAPE,
    type: "RingShapeComponent",
    title: "Ring",
    imgSrc: "/static/img/segments/layouts/ring-shape.png",
    config: RingShape.RingShapeConfig,
    advancedConfig: RingShape.RingShapeAdvancedConfig,
    component: RingShape.RingShapeComponent
  },
  StarShapeComponent: {
    family: SegmentFamilies.SHAPE,
    type: "StarShapeComponent",
    title: "Star",
    imgSrc: "/static/img/segments/layouts/star-shape.png",
    config: StarShape.StarShapeConfig,
    advancedConfig: StarShape.StarShapeAdvancedConfig,
    component: StarShape.StarShapeComponent
  },
  RectangleShapeComponent: {
    family: SegmentFamilies.SHAPE,
    type: "RectangleShapeComponent",
    title: "Rectangle",
    imgSrc: "/static/img/segments/layouts/rectangle-shape.png",
    config: RectangleShape.RectangleShapeConfig,
    advancedConfig: RectangleShape.RectangleShapeAdvancedConfig,
    component: RectangleShape.RectangleShapeComponent
  },
  SquareShapeComponent: {
    family: SegmentFamilies.SHAPE,
    type: "SquareShapeComponent",
    title: "Square",
    imgSrc: "/static/img/segments/layouts/square-shape.png",
    config: SquareShape.SquareShapeConfig,
    advancedConfig: SquareShape.SquareShapeAdvancedConfig,
    component: SquareShape.SquareShapeComponent
  },
};