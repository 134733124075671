import { PureComponent } from "react";
import { componentStyles } from "./youtube-two-thirds-text-and-asset-component.scss";
import SideBySideTextAndAssetComponent from "segments/desktop/text-and-asset/layouts/side-by-side-text-and-asset/side-by-side-text-and-asset-component";
import YoutubeVideoWithPosterComponent from "components/core/youtube-video-with-poster/youtube-video-with-poster-component";
import YoutubeVideoWithPosterComponentDefaultProps from "components/core/youtube-video-with-poster/default-props";

export default class YoutubeTwoThirdsTextAndAssetComponent extends PureComponent {
  render() {
    const { youtubeWithPosterProps, ...restProps } = this.props;
    return (
      <div className="youtube-two-thirds-text-and-asset-component">
        <SideBySideTextAndAssetComponent
          replaceAssetComponent={YoutubeVideoWithPosterComponent}
          replaceAssetComponentProps={youtubeWithPosterProps}
          {...restProps}
        />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

YoutubeTwoThirdsTextAndAssetComponent.defaultProps = {
  ...SideBySideTextAndAssetComponent.defaultProps,
  asset: {},
  youtubeWithPosterProps: { ...YoutubeVideoWithPosterComponentDefaultProps, fitToParent: true }
};
