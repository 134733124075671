export default [
  // Purple - Hire new product manager
  {
    type: "pulse",
    offset: 150,
    duration: 0,
    pictureSrc: "/static/img/first-fold/item_4.png",
    classToggle: "pulse-first-item-end",
    className: "pulse-first-item-start"
  },

  {
    type: "pulse",
    offset: 200,
    duration: 0,
    pictureSrc: "/static/img/first-fold/item_6.png",
    classToggle: "fade-in",
    className: "pulse-second-item-start fade-out"
  },

  {
    type: "pulse",
    offset: 250,
    duration: 0,
    pictureSrc: "/static/img/first-fold/item_5.png",
    classToggle: "fade-in",
    className: "pulse-third-item-start fade-out"
  },

  // Orange - New feature release
  {
    type: "pulse",
    offset: 25,
    duration: 0,
    pictureSrc: "/static/img/first-fold/item_1.png",
    classToggle: "pulse-fourth-item-end",
    className: "pulse-fourth-item-start"
  },

  {
    type: "pulse",
    offset: 200,
    duration: 0,
    pictureSrc: "/static/img/first-fold/item_3.png",
    classToggle: "fade-in",
    className: "pulse-fifth-item-start fade-out"
  },

  {
    type: "pulse",
    offset: 225,
    duration: 0,
    pictureSrc: "/static/img/first-fold/item_7.png",
    classToggle: "fade-in",
    className: "pulse-sixth-item-start fade-out"
  },

  {
    type: "pulse",
    offset: 250,
    duration: 0,
    pictureSrc: "/static/img/first-fold/item_2.png",
    classToggle: "fade-in",
    className: "pulse-seventh-item-start fade-out"
  }
];
