import { PureComponent } from "react";
import BaseForm from "segments/desktop/core-components/base-form/base-form";
import {
  getZendeskFormConfig,
  ZENDESK_COMPANY_SIZE_INPUT_NAME,
  ZENDESK_TOPIC_INPUT_NAME
} from "client/services/zendesk-service/zendesk-inputs";
import { componentStyles } from "./zendesk-form.scss";
import DefaultProps from "./default-props";
import { createZendeskTicket } from "client/services/zendesk-service/zendesk-service";
import {
  ZENDESK_FORM_SUBMIT_CLICKED,
  ZENDESK_FORM_SUBMIT_SUCCESS,
  ZENDESK_FORM_SUBMIT_FAILED
} from "constants/bigbrain-event-types";
import { HELP_CENTER_LINK } from "constants/links";
import { withGenericConfig } from "/contexts/generic-config-context";

const SUBMIT_ASSET_SRC = "/static/img/forms/cs_representative.png";

class ZendeskForm extends PureComponent {
  normalizeFormData = (formData) => {
    const normalizedData = {};

    formData.forEach((fieldData) => {
      normalizedData[fieldData.name] = fieldData.value;
    });

    return normalizedData;
  };

  sendFormToZendesk = async (formData) => {
    const normalizedFormData = this.normalizeFormData(formData);
    const companySize = normalizedFormData[ZENDESK_COMPANY_SIZE_INPUT_NAME];
    const topic = normalizedFormData[ZENDESK_TOPIC_INPUT_NAME];

    BigBrain("track", ZENDESK_FORM_SUBMIT_CLICKED, { kind: topic, info1: companySize });
    const responseData = await createZendeskTicket(normalizedFormData);
    const success = responseData?.success;
    let errorMessage = null;
    if (responseData?.success) {
      BigBrain("track", ZENDESK_FORM_SUBMIT_SUCCESS, { kind: topic, info1: companySize });
    } else {
      errorMessage = responseData?.message;
      BigBrain("track", ZENDESK_FORM_SUBMIT_FAILED, { kind: errorMessage });
    }

    return { success, errorMessage };
  };

  getKnowledgeBaseLinkHTML = () => {
    const { translate } = this.props;
    return `<a href="${HELP_CENTER_LINK}" target="_blank">
      ${translate("forms.thanksPage.KnowledgeBase")}</a>`;
  };

  render() {
    const formConfig = getZendeskFormConfig();
    const submitScreenData = {
      subTitleHtmlKey: "forms.zendesk.thanksPage.subTitle",
      postSubmitAssetSrc: SUBMIT_ASSET_SRC,
      footerLinkHtml: this.getKnowledgeBaseLinkHTML()
    };

    return (
      <div className="hubspot-contact-form-wrapper">
        <BaseForm
          formConfig={formConfig}
          submitFormAsyncCallback={this.sendFormToZendesk}
          renderPrivacyNotice={true}
          submitScreenData={submitScreenData}
        />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

export default withGenericConfig(ZendeskForm);

ZendeskForm.defaultProps = DefaultProps;
