import { useState } from "react";
import { isDarkTheme } from "segments/segments-service";
import { componentStyles } from "./monday-integrations-connections-component.scss";
import PictureComponent from "/components/core/picture/picture-component";
import classnames from "classnames";
import Toggle from "components/core/toggle";
import DefaultProps from "./default-props";
import {
  LIGHT_WORK_OS_IRIS_COLOR_NAME,
  WORK_OS_IRIS_COLOR_NAME,
  BACKGROUND_GRAY_COLOR_NAME
} from "styles/color-consts";
import Colors from "styles/colors";
import Title from "segments/desktop/core-components/title/title";
import Paragraph from "segments/desktop/core-components/paragraph/paragraph";
import LinesSvgComponent from "./lines-svg-component";
import PathsAndCirclesSvgComponent from "./paths-and-circles-svg-component";
import { ICONS_ARRAY, LOGO_DATA } from "./consts";

const MondayIntegrationsConnectionsComponent = (props) => {
  const {
    theme,
    componentTitle,
    beforeAnimationSubTitle,
    afterAnimationSubTitle,
    switchRightPhrase,
    switchLeftPhrase
  } = props;
  const [animationIsOn, setAnimationState] = useState(false);
  const switchbackground = isDarkTheme(theme)
    ? Colors[BACKGROUND_GRAY_COLOR_NAME]
    : Colors[LIGHT_WORK_OS_IRIS_COLOR_NAME];
  const switchTextsColors = !isDarkTheme(theme);

  const changeAnimationState = () => {
    setAnimationState(!animationIsOn);
  };

  const renderIcons = () => {
    return ICONS_ARRAY.map((icon) => (
      <div
        className="icon"
        style={{
          "--x-before": `${icon.startLocation[0]}px`,
          "--y-before": `${icon.startLocation[1]}px`,
          "--x-after": `${icon.endLocation[0]}px`,
          "--y-after": `${icon.endLocation[1]}px`
        }}
        key={`${icon.startLocation[0]}_${icon.startLocation[1]}_${icon.endLocation[0]}_${icon.endLocation[1]}}`}
      >
        <PictureComponent src={icon.iconImage} />
        <style jsx>{componentStyles}</style>
      </div>
    ));
  };

  return (
    <div className="monday-integrations-connections-component">
      <div className={classnames("monday-integrations-wrapper", { animation: animationIsOn })}>
        <div className="title-wrapper">
          <Title title={componentTitle} />
        </div>
        <div className="toggle-wrapper">
          <Toggle
            handleToggle={changeAnimationState}
            leftPhrase={switchLeftPhrase}
            rightPhrase={switchRightPhrase}
            toggleBackgroundColor={switchbackground}
            toggleButtonColor={Colors[WORK_OS_IRIS_COLOR_NAME]}
            swtichTextsColors={switchTextsColors}
            isOn={animationIsOn}
          />
        </div>

        <div className="icons-and-lines-wrapper">
          <div
            className="monday-logo"
            style={{
              "--left": `${LOGO_DATA.xLocation}px`,
              "--top": `${LOGO_DATA.yLocation}px`
            }}
          >
            <PictureComponent src="https://dapulse-res.cloudinary.com/image/upload/Integration_assets/2_work-managment-icon_center.png" />
          </div>
          <div classNames="SVGs-container">
            {renderIcons()}

            <LinesSvgComponent theme={theme} />
            <PathsAndCirclesSvgComponent />
          </div>
        </div>
        <div className="before-animation-paragraph-wrapper">
          <Paragraph body={beforeAnimationSubTitle} />
        </div>
        <div className="after-animation-paragraph-wrapper">
          <Paragraph body={afterAnimationSubTitle} />
        </div>
      </div>

      <style jsx>{componentStyles}</style>
    </div>
  );
};

MondayIntegrationsConnectionsComponent.defaultProps = DefaultProps;

export default MondayIntegrationsConnectionsComponent;
