import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
import AssetAdvancedConfig from "components/core/asset/advanced-field-config";
import TitleAdvancedConfig from "segments/desktop/core-components/title/advanced-field-config";
import VerticalTimerTabsAdvancedConfig from "segments/desktop/core-components/vertical-timer-tabs/fields-advanced-config";
import FullParagraphAdvancedFieldsConfig from "segments/desktop/core-components/full-paragraph/advanced-fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "",
  fields: {
    ...SharedAdvancedFieldsConfig,
    title: {
      label: "Title",
      type: FIELD_TYPES.NESTED,
      fields: TitleAdvancedConfig
    },
    advancedTitleConfig: {
      ...VerticalTimerTabsAdvancedConfig.title,
      label: "Tab Title"
    },
    tabContent: {
      type: FIELD_TYPES.NESTED,
      label: "Tab Content",
      fields: FullParagraphAdvancedFieldsConfig
    },
    advancedProcessTabs: {
      type: FIELD_TYPES.NESTED,
      label: "Process tabs",
      fields: {
        titleConfig: {
          type: FIELD_TYPES.NESTED,
          label: "Asset Title",
          fields: TitleAdvancedConfig
        },
        autoTransition: { type: FIELD_TYPES.TEXT, label: "Auto Transition (milliseconds)" },
        scrollOnTabClick: { type: FIELD_TYPES.BOOLEAN, label: "Should scroll to content on tab click" },
        scrollSpeed: { type: FIELD_TYPES.TEXT, label: "Scroll speed on tab click" },
        distanceFromTop: { type: FIELD_TYPES.TEXT, label: "Distance to stop when scrolling (fixed header height)" }
      }
    },
    arrowSize: VerticalTimerTabsAdvancedConfig.arrowSize,
    ...AssetAdvancedConfig
  }
};
