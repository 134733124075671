import { Stage, Layer } from "react-konva";
import Segments from "segments/desktop";
import { CANVAS_FULL_WIDTH } from "./helpers";
import useElementSize from "client/services/hooks/use-element-size";

const ReactCanvas = (props) => {
  const { shapeSegments, isPreview, rootEl } = props;
  const { scrollableHeight } = useElementSize(rootEl);

  // Stage - is a div wrapper
  // Layer - is an actual 2d canvas element, so you can have several layers inside the stage
  return (
    <Stage width={CANVAS_FULL_WIDTH} height={scrollableHeight}>
      <Layer>
        {shapeSegments.map((segment) => {
          const Component = Segments[segment.type].component;

          return (
            <Component
              {...segment.props}
              windowHeight={scrollableHeight}
              isPreview={isPreview}
            />
          );
        })}
      </Layer>
    </Stage>
  );
};

export default ReactCanvas;
