import { PureComponent } from "react";
import SideBySideTextAndAsset from "segments/desktop/core-components/side-by-side-text-and-asset/side-by-side-text-and-asset";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import AssetComponent from "components/core/asset/asset-component";
import ContactSalesHubspotFormComponent from "./components/contact-sales-hubspot-form-component";
import { componentStyles } from "./side-by-side-hubspot-form-component.scss";

const AssetWithParagraph = ({ fullParagraph, asset, gap }) => (
  <div className="side-by-side-asset-and-paragraph" style={{ gap }}>
    {fullParagraph && <FullParagraph {...fullParagraph} />}
    <AssetComponent {...asset} />
  </div>
);

export default class SideBySideHubspotFormComponent extends PureComponent {
  render() {
    const { formId, formMinHeight, asset, fullParagraph, assetAndParagraphGap, ...restProps } = this.props;

    const replaceTextComponentProps = { formId, formMinHeight };
    const replaceAssetComponentProps = { asset, fullParagraph, gap: assetAndParagraphGap };

    return (
      <div className="side-by-side-hubspot-form-component">
        <SideBySideTextAndAsset
          replaceTextComponent={ContactSalesHubspotFormComponent}
          replaceTextComponentProps={replaceTextComponentProps}
          replaceAssetComponent={AssetWithParagraph}
          replaceAssetComponentProps={replaceAssetComponentProps}
          asset={asset}
          {...restProps}
        />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

SideBySideHubspotFormComponent.defaultProps = {
  width: "100%",
  formId: "706f2f71-b48f-4dde-850f-055f5e3664d2",
  asset: {
    fitToContainer: true,
    image: {
      src: "/static/img/segment-layout-default-images/sales_social.png"
    }
  }
};
