import ProcessTabs from "segments/desktop/core-components/process-tabs/process-tabs";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import { XS } from "constants/sizes";
import VerticalTimerTabs from "segments/desktop/core-components/vertical-timer-tabs/vertical-timer-tabs";
import Title from "segments/desktop/core-components/title/title";
import DefaultProps from "./deafult-props";
import { componentStyles } from "./side-by-side-tabs-and-process-tabs-component.scss";

const SideBySideTabsAndProcessTabsComponent = ({ tabContent, title, advancedProcessTabs, ...rest }) => {
  const contentRenderer = (contentObject) => <FullParagraph {...contentObject} {...tabContent} titleSize={XS} />;
  const processRenderer = ({ processTab }) => (
    <div className="process-tabs">
      <ProcessTabs {...processTab} {...advancedProcessTabs} />
    </div>
  );

  const { titleMargin, ...titleProps } = title;

  return (
    <div className="side-by-side-tabs-and-process-tabs-component">
      {titleProps?.title && (
        <div className="title-wrapper" style={{ marginBottom: titleMargin }}>
          <Title titleClassName="main-title" {...titleProps} />
        </div>
      )}
      <VerticalTimerTabs
        {...rest}
        tabContentRenderer={contentRenderer}
        rightSideRenderer={processRenderer}
        tabsContainerClassName="tabs-container"
      />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default SideBySideTabsAndProcessTabsComponent;
SideBySideTabsAndProcessTabsComponent.defaultProps = DefaultProps;
