import { PureComponent } from "react";
import { componentStyles } from "./cards-grid-mobile-component.scss";
import GridImage from "segments/desktop/core-components/grid-image/grid-image";
import SecondaryButton from "segments/desktop/core-components/button/secondary-button/secondary-button";

import cardsGridDefaultProps from "../default-props";

const IMAGE_HEIGHT = "85px";
const IMAGE_WIDTH = "85px";

export default class CardsGridMobileComponent extends PureComponent {
  renderGridItems() {
    const { gridItems } = this.props;

    return gridItems.map((gridItem, index) => {
      const { fullParagraph = {}, ...resImageProps } = gridItem;
      const { title, buttonConfig } = fullParagraph;

      return (
        <div key={index} className="grid-image-with-paragraph-component-wrapper">
          <div className="inner-frame">
            <div className="grid-image-wrapper">
              <GridImage {...resImageProps} width={IMAGE_WIDTH} height={IMAGE_HEIGHT} />
            </div>
            <div className="mobile-paragraph-wrapper">
              <div className="title-wrapper">
                <div className="title">{title}</div>
              </div>
              <div className="button-wrapper">
                <SecondaryButton {...buttonConfig} />
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  render() {
    return (
      <div className="cards-grid-mobile-component">
        {this.renderGridItems()}
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

CardsGridMobileComponent.defaultProps = cardsGridDefaultProps;
