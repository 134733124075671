import { PureComponent } from "react";
import { componentStyles } from "./full-testimonials-quote.scss";
import PersonInfo from "segments/desktop/core-components/person-info/person-info";
import TestimonialsQuote from "segments/desktop/core-components/testimonials-quote/testimonials-quote";
import DefaultProps from "./default-props";
import Title from "segments/desktop/core-components/title/title";

export default class FullTestimonialsQuote extends PureComponent {
  render() {
    const { testimonialsConfig, personConfig, title, titleSize } = this.props;

    return (
      <div className="full-testimonials-quote">
        <div className="full-testimonials-title">
          <Title title={title} titleSize={titleSize} />
        </div>
        <TestimonialsQuote {...testimonialsConfig} />
        <PersonInfo {...personConfig} />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

FullTestimonialsQuote.defaultProps = DefaultProps;
