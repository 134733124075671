import { FIELD_TYPES } from "constants/template-field-type";
import gridImageFieldsConfig from "segments/desktop/core-components/grid-image/fields-config";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import { XS, SM, MD, LG, LGR, XL, XXL } from "constants/sizes";

const { textColor, textAlign, ...selectedFullParagraphFields } = FullParagraphFieldsConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "Icon With Paragraph",
  fields: {
    iconWidth: { type: FIELD_TYPES.TEXT, label: "Icon width" },
    iconHeight: { type: FIELD_TYPES.TEXT, label: "Icon height" },
    titleSize: {
      type: FIELD_TYPES.COMBO,
      label: "Title Size",
      options: [
        { value: XS, text: "24px" },
        { value: SM, text: "32px" },
        { value: MD, text: "40px" },
        { value: LG, text: "50px" },
        { value: LGR, text: "60px" },
        { value: XL, text: "72px" },
        { value: XXL, text: "80px" }
      ]
    },
    textSize: {
      type: FIELD_TYPES.COMBO,
      label: "Body Size",
      options: [
        { value: MD, text: "20px" },
        { value: LG, text: "24px" }
      ]
    },
    images: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Icons",
      maxLimit: 4,
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          ...gridImageFieldsConfig,
          fullParagraph: {
            type: FIELD_TYPES.NESTED,
            label: "Full Paragraph",
            fields: selectedFullParagraphFields
          }
        }
      }
    }
  }
};
