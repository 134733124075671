import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, MD_DESKTOP_WIDTH } from "constants/vp-sizes";
import DesktopHeaderConstants from "segments/desktop/header/layouts/components/basic-header/constants";
import BodyConstants from "segments/desktop/constants";
import colors from "styles/colors";
import { SPACE_BLUE_COLOR_NAME, WHITE_COLOR_NAME } from "styles/color-consts";

const HEADER_LOGO_HORIZONTAL_PADDING = `${DesktopHeaderConstants.HEADER_CONTENT_HORIZONTAL_PADDING}px`;
const BODY_HORIZONTAL_PADDING = `${BodyConstants.BODY_CONTENT_HORIZONTAL_PADDING}px`;

export const componentStyles = css`
  .scroll-parallax-homepage-first-fold-component-desktop {
    background: ${colors[SPACE_BLUE_COLOR_NAME]};
    color: ${colors[WHITE_COLOR_NAME]};
    position: relative;
    padding: 128px 0;

    .container {
      display: flex;
      max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
      margin: auto;
      padding: ${`0 ${BODY_HORIZONTAL_PADDING}`};

      &.align-to-logo {
        padding-left: ${HEADER_LOGO_HORIZONTAL_PADDING};
        padding-right: ${HEADER_LOGO_HORIZONTAL_PADDING};
      }

      .paragraphs {
        flex: 1 0 0;
      }

      .parallex {
        flex: 1 0 0;
        position: relative;
        min-height: 100%;
        padding-left: 50px;

        .controller {
          height: 500px;
          position: sticky;
          top: calc(50% - 250px);
          display: flex;
          flex-direction: column;
          justify-content: center;

          :global(.window-mask) {
            padding: 0;
          }
        }
      }
    }
  }

  @media (max-width: ${MD_DESKTOP_WIDTH}) {
    .parallax-galaxy-wrapper {
      transform: scale(0.75);
    }
  }
`;
