import css from "styled-jsx/css";
import { MAX_DESKTOP_GRID_WIDTH, MAX_DESKTOP_CONTENT_WIDTH, SM, XS_M } from "constants/vp-sizes";

export const componentStyles = css.global`
  .button-image-tags-grid-component {
    max-width: ${MAX_DESKTOP_GRID_WIDTH + "px"};
    width: 100%;
    padding: 16px;
    margin: auto;

    .grid-button-list {
      display: flex;
      flex-wrap: wrap;
      list-style-type: none;
      margin: 0;
      padding: 0;

      .grid-button-container {
        width: 25%;
      }
    }
  }

  @media (max-width: ${MAX_DESKTOP_CONTENT_WIDTH + "px"}) {
    .button-image-tags-grid-component {
      .grid-button-list {
        .grid-button-container {
          width: 33%;
        }
      }
    }
  }

  @media (max-width: ${SM + "px"}) {
    .button-image-tags-grid-component {
      .grid-button-list {
        .grid-button-container {
          width: 50%;
        }
      }
    }
  }

  @media (max-width: ${XS_M + "px"}) {
    .button-image-tags-grid-component {
      .grid-button-list {
        .grid-button-container {
          .button-image-tag {
            padding: 4px 12px;

            .button-image-tag-anchor {
              flex-direction: row;

              .tag-text {
                padding: 6px 12px;
              }
            }
          }

          flex-direction: row;
          width: 100%;
          font-size: 0.875rem;
        }
      }
    }
  }
`;
