import css from "styled-jsx/css";
import { MAX_TAG_WIDTH, MAX_TAG_HEIGHT } from "./animated-tag-constants";

export const componentStyles = css.global`
  .animated-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${MAX_TAG_WIDTH + "px"};
    height: ${MAX_TAG_HEIGHT + "px"};
    border-radius: 50%;
    position: absolute;
    animation: tagAnimation 0.5s 1;

    &.square {
      border-radius: 2px;
    }

    @keyframes tagAnimation {
      0% {
        transform: translateY(0) rotate(24deg) scale(0.2);
        animation-timing-function: linear;
      }

      25% {
        transform: translateY(-18px) rotate(24deg) scale(1);
        animation-timing-function: ease-out;
      }

      50% {
        transform: translateY(-36px) rotate(24deg) scale(1);
        animation-timing-function: ease-in;
      }

      100% {
        transform: translateY(0px) rotate(0) scale(1);
        animation-timing-function: linear;
      }
    }

    .picture-component {
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
      width: 100%;
      height: 100%;

      img {
        width: 50%;
        height: 50%;
      }
    }
  }
`;
