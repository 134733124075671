import Shapes from "segments/desktop/shape/index";
import DefaultProps from "./default-props";
import Rectangle from "./rectangle";

const RectangleShapeComponent = (props) => {
  return <Rectangle {...props} componentType={Shapes.RectangleShapeComponent.type} />;
};

RectangleShapeComponent.defaultProps = DefaultProps;

export default RectangleShapeComponent;