import { FIELD_TYPES } from "constants/template-field-type";
import FullParagraphFieldConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import TitleConfig from "segments/desktop/core-components/title/fields-config";
import { BRAND_DARK_COLOR_THEME_ID, SPACE_BLUE_GRADIANT_THEME_ID } from "segments/segments-service";
import SquareTagsFieldsConfig from "segments/desktop/core-components/square-tags/fields-config";
import {
  BRAND_AND_WORK_MANAGEMENT_DARKER_TINT_02,
  BRAND_BLACK_BACKGROUND_COLOR,
  BRAND_DARK_COLOR,
  BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01,
  CRM_DARKER_TINT_02,
  WHITE_COLOR_NAME
} from "styles/color-consts";
import { PALLET_VALUES } from "segments/desktop/homepage-first-fold/layouts/monday-universe-homepage-first-fold/palette-schemes";

const {
  textAlign,
  mobileTextAlign,
  textColor,
  topic,
  buttonConfig: button,
  ...restFullParagraphFieldsConfig
} = FullParagraphFieldConfig;
const { theme, ...restSquareTagsFieldsConfig } = SquareTagsFieldsConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "Monday Universe",
  fields: {
    theme: {
      type: FIELD_TYPES.COLOR_PICKER,
      label: "Theme",
      possibleColors: [
        SPACE_BLUE_GRADIANT_THEME_ID,
        BRAND_DARK_COLOR_THEME_ID,
        CRM_DARKER_TINT_02,
        BRAND_BLACK_BACKGROUND_COLOR,
        WHITE_COLOR_NAME,
        BRAND_DARK_COLOR,
        BRAND_AND_WORK_MANAGEMENT_DARKER_TINT_02,
        BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01
      ]
    },
    pallet: {
      type: FIELD_TYPES.COMBO,
      label: "Color Pallet",
      options: [
        { value: PALLET_VALUES.CRM_WHITE, text: `CRM pallet - for ${WHITE_COLOR_NAME} background` },
        { value: PALLET_VALUES.CRM_GREEN, text: `CRM pallet - for ${CRM_DARKER_TINT_02} background` },
        { value: PALLET_VALUES.DEV_WHITE, text: `Dev pallet - for ${WHITE_COLOR_NAME} background` },
        { value: PALLET_VALUES.DEV_BLACK, text: `Dev pallet - for ${BRAND_BLACK_BACKGROUND_COLOR} background` },
        {
          value: PALLET_VALUES.WM_WHITE,
          text: `WM pallet - for ${WHITE_COLOR_NAME} / ${BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01} background`
        },
        {
          value: PALLET_VALUES.WM_BLACK,
          text: `WM pallet - for ${BRAND_BLACK_BACKGROUND_COLOR} / ${BRAND_DARK_COLOR} background`
        },
        {
          value: PALLET_VALUES.WM_PURPLE,
          text: `WM pallet - for ${BRAND_AND_WORK_MANAGEMENT_DARKER_TINT_02} background`
        }
      ]
    },
    fullParagraph: {
      type: FIELD_TYPES.NESTED,
      label: "Full paragraph",
      fields: restFullParagraphFieldsConfig
    },
    subTitle: {
      type: FIELD_TYPES.NESTED,
      label: "Sub title",
      fields: TitleConfig
    },
    ...restSquareTagsFieldsConfig,
    lines: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Lines",
      maxLimit: 3,
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          start: {
            type: FIELD_TYPES.NESTED,
            label: "Start point",
            fields: {
              top: {
                type: FIELD_TYPES.TEXT,
                label: "Top"
              },
              left: {
                type: FIELD_TYPES.TEXT,
                label: "Left"
              }
            }
          },
          end: {
            type: FIELD_TYPES.NESTED,
            label: "End point",
            fields: {
              top: {
                type: FIELD_TYPES.TEXT,
                label: "Top"
              },
              left: {
                type: FIELD_TYPES.TEXT,
                label: "Left"
              }
            }
          }
        }
      }
    }
  }
};
