import { FIELD_TYPES } from "constants/template-field-type";
import SelectableTagsGridFieldsConfig from "segments/desktop/core-components/selectable-tags-grid/fields-config";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import assetFieldsConfig from "components/core/asset/fields-config";
import { ALIGN_TYPES, ALIGN_LABELS } from "constants/align-types";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Images Carousel With Tags Grid",
  fields: {
    paragraphText: {
      type: FIELD_TYPES.NESTED,
      label: "Full Paragraph",
      fields: { ...FullParagraphFieldsConfig }
    },
    defaultImage: {
      type: FIELD_TYPES.NESTED,
      label: "Default image",
      fields: { ...assetFieldsConfig.image.fields }
    },
    showDefaultInGallery: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Show default image in gallery"
    },
    tagsAlign: {
      type: FIELD_TYPES.COMBO,
      label: "Tags Align",
      options: [
        { value: ALIGN_TYPES.LEFT, text: ALIGN_LABELS.LEFT },
        { value: ALIGN_TYPES.CENTER, text: ALIGN_LABELS.CENTER },
        { value: ALIGN_TYPES.RIGHT, text: ALIGN_LABELS.RIGHT }
      ]
    },
    ...SelectableTagsGridFieldsConfig
  }
};
