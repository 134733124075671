// plop_marker:segments_import
import Templates from "./templates";
import Shape from "./shape";
import Pricing from "./pricing";
import CustomerSuccess from "./customer-success";
import Form from "./form";
import Blog from "./blog";
import Iframe from "./iframe";
import Static from "./static";
import Embedded from "./embedded";
import Banner from "./banner";
import HomepageFirstFold from "./homepage-first-fold";
import Integrations from "./integrations";
import Button from "./button";
import Enterprise from "./enterprise";
import Asset from "./asset";
import Security from "./security";
import Tabs from "./tabs";
import Header from "./header";
import Hero from "./hero";
import Footer from "./footer";
import Grid from "./grid";
import Testimonials from "./testimonials";
import Logos from "./logos";
import Reviews from "./reviews";
import TextAndAsset from "./text-and-asset";
import Paragraph from "./paragraph";

export default {
  // plop_marker:segments_add_segment
  ...Templates,
  ...Shape,
  ...Pricing,
  ...CustomerSuccess,
  ...Form,
  ...Blog,
  ...Iframe,
  ...Static,
  ...Embedded,
  ...Banner,
  ...HomepageFirstFold,
  ...Integrations,
  ...Button,
  ...Enterprise,
  ...Asset,
  ...Security,
  ...Tabs,
  ...Header,
  ...Hero,
  ...Footer,
  ...Grid,
  ...Testimonials,
  ...Logos,
  ...Reviews,
  ...TextAndAsset,
  ...Paragraph,
};
