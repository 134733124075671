import { FIELD_TYPES } from "constants/template-field-type";
import AssetFieldsConfig from "components/core/asset/fields-config";
import { asset } from "components/templates-generator/config/components/asset/asset-config";
import ThemeConfig from "segments/core-configs/theme-config";


const { image } = asset.fields;

export default {
  ...ThemeConfig,
    tabs: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Tabs",
      maxLimit: 5,
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          image: {
            ...image,
            label: "Icon Image"
          },
          tabLabel: { type: FIELD_TYPES.TEXT, label: "Tab Label", translatable: true },
          tabId: { type: FIELD_TYPES.TEXT, label: "Tab Id" },
          asset: {
            type: FIELD_TYPES.NESTED,
            label: "Asset",
            fields: AssetFieldsConfig
          }
        }
      }
    }
};