const { trackBigBrainEventOnServer } = require("../../bigbrain-tracking-service/bigbrain-tracking-service");
const { BaseDataProvider } = require("./base-data-provider");
const ProviderKeys = require('./providers-keys');

const PLATFORM_SERVICE_HOST = "https://auth.monday.com";
const MONDAY_SOLUTIONS_ENDPOINT = "monday_solutions";

const MONDAY_SOLUTIONS_FETCH_SUCCESS = "monday_solutions_fetch_success";
const MONDAY_SOLUTIONS_FETCH_ERROR = "monday_solutions_fetch_error";
const LOCALE_PARAM_NAME = "locale";

class SolutionsDataProvider extends BaseDataProvider {
    constructor(params) {
        super(params);
    }

    key() {
        const { localeId } = this.params();
        return `${ProviderKeys.SOLUTIONS_DATA}_locale_${localeId}`;
    }

    async getData() {
        const { localeId } = this.params();

        try {
            const options = {
                headers: { "Content-Type": "application/json" },
                method: "GET"
            };

            const url = new URL(`${MONDAY_SOLUTIONS_ENDPOINT}?${LOCALE_PARAM_NAME}=${localeId}`, PLATFORM_SERVICE_HOST).href;
            const res = await fetch(url, options);

            if (res.status === 200) {
                trackBigBrainEventOnServer(MONDAY_SOLUTIONS_FETCH_SUCCESS);
                const response = await res.json();
                return response;
            } else {
                throw new Error(res.statusText)
            }
        } catch (e) {
            trackBigBrainEventOnServer(MONDAY_SOLUTIONS_FETCH_ERROR, { direct_object: e.message });
            console.error(e.message);
            throw e;
        }
    }
}

module.exports = SolutionsDataProvider;
