import { MD } from "./constants";

export default {
  poster: "/static/img/tags/video_image.png",
  videoId: "l-7awVpecvU",
  fitToParent: false,
  playButtonColor: "iris",
  playButtonSize: MD,
  showShadow: true
};
