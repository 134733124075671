import css from "styled-jsx/css";
import {
  ASSET_SIDE_BY_SIDE_WIDTH,
  ASSET_SIDE_BY_SIDE_HEIGHT
} from "segments/desktop/core-components/board-tabs/board-tab-consts";
import { MACBOOK_PRO_MAX_WIDTH } from "/constants/vp-sizes";
import DesktopHeaderConstants from "segments/desktop/header/layouts/components/basic-header/constants";

const HEADER_LOGO_HORIZONTAL_PADDING = `${DesktopHeaderConstants.HEADER_CONTENT_HORIZONTAL_PADDING}px`;

const ASSET_SIDE_BY_SIDE_WIDTH_PX = `${ASSET_SIDE_BY_SIDE_WIDTH}px`;
const ASSET_SIDE_BY_SIDE_HEIGHT_PX = `${ASSET_SIDE_BY_SIDE_HEIGHT}px`;

export const componentStyles = css.global`
  .board-tab-text-and-asset-component {
    .side-by-side-text-and-asset-component {
      .side-by-side-text-and-asset {
        .side-by-side-content {
          padding-right: ${HEADER_LOGO_HORIZONTAL_PADDING};
          .full-paragraph-component-wrapper {
            width: 40%;
            .full-paragraph {
              .title-wrapper {
                .core-title {
                  font-weight: 500;
                }
              }
            }
          }
          .image-wrapper {
            width: 60%;

            .board-side-by-side-content-asset-wrapper {
              height: ${ASSET_SIDE_BY_SIDE_HEIGHT_PX};
              width: ${ASSET_SIDE_BY_SIDE_WIDTH_PX};
            }
          }
        }
      }
    }
  }

  @media (max-width: ${MACBOOK_PRO_MAX_WIDTH}) {
    .board-tab-text-and-asset-component {
      .side-by-side-text-and-asset-component {
        .full-paragraph-component-wrapper {
          .full-paragraph {
            .title-wrapper {
              .core-title {
                font-size: 3.375rem;
                line-height: 65px;
              }
            }
          }
        }
      }
    }
  }
`;
