import { socialButtons, topbar } from "../components";
import SegmentsConfig from "segments/desktop";

export const FIXED_LONG_LP_TEMPLATE_CONFIG = {
  topbar,
  socialButtons,
  segments: [
    {
      type: "ALL_SEGMENTS",
      title: "All Segmements",
      imgSrc: "/static/img/segments/icons/blank.png",
      segmentLayouts: [
        SegmentsConfig.CompaniesLogosComponent,
        SegmentsConfig.TextAndAssetComponent,
        SegmentsConfig.SimpleColorfulTitleWithSignupComponent,
        SegmentsConfig.CustomerSuccessComponent,
        SegmentsConfig.SecurityAndPrivacyComponent,
        SegmentsConfig.ParagraphComponent,
        SegmentsConfig.OnePagerWithImageComponent,
        SegmentsConfig.ShortFooterComponent,
        SegmentsConfig.ReviewsComponent
      ]
    }
  ],
  defaultData: {
    template: "FixedLongLpTemplate",
    title: "",
    description: "",
    theme: "dark-theme",
    mobileTheme: "dark-theme",
    imagesLazyLoad: false,
    showMenu: true,
    topbar: {
      showMenu: true
    },
    segments: [
      {
        type: "OnePagerWithImageComponent",
        props: {
          imageWithBackgroundPart: {
            backgroundColor: "transparent",
            src: "/static/uploads/MayKishon/dceff356-89d1-4e10-8b1d-8426b7941763_board_teamprojects.png",
            alt: "Time tracking and project management board",
            imageSegmentHeight: "90vh",
            straightBorder: false,
            imageAlign: "align-center"
          },
          textPart: {
            titleLines: ["<b>Finally.</b> Track time & manage projects in one place", ""],
            subtitleLines: ["Know how you spend your time and make every minute count."],
            bullets: [],
            mobileTitleLines: ["<b>Track time with the </b>", "<b>#1 productivity software</b>"],
            mobileSubtitleLines: ["", ""],
            color: "black",
            titleLinesFontSize: "42px"
          },
          signupForm: {
            buttonColor: "brand-pink",
            buttonText: "Get Started",
            inputPlaceholder: "Enter your work email",
            downloadButtonTitle: "Download The App",
            focusOnLoad: true
          },
          isFullWidth: true
        }
      },
      {
        type: "CompaniesLogosComponent",
        props: {
          paragraph: {
            title: "",
            textDirection: "center"
          },
          signupForm: {
            showDecorations: false
          },
          withAnimation: true,
          showSignup: false,
          numberOfCompanies: "5",
          paddingBottom: "",
          backgroundColor: "snow",
          paddingTop: "20px"
        }
      },
      {
        type: "ParagraphComponent",
        props: {
          title: "Track time and projects your way",
          textDirection: "center",
          paddingTop: "80px",
          body: ["Know exactly where projects stand and reach every milestone."]
        }
      },
      {
        type: "CustomerSuccessComponent",
        props: {
          columnWidth: "300px",
          imageWidth: "",
          assets: [
            {
              asset: {
                shadow: false,
                image: {
                  src: "/static/uploads/MayKishon/61389087-d6ab-4824-a031-99538572c7ad_Slice-2.png",
                  alt: "timer"
                }
              },
              content: {
                title: "Exact time frames",
                textDirection: "center",
                body: ["Easily switch the timer on and off or enter time spent manually."],
                bottomLink: {
                  text: "",
                  href: ""
                },
                topic: {
                  text: ""
                },
                titleFontSize: "18px"
              }
            },
            {
              asset: {
                shadow: false,
                image: {
                  src: "/static/uploads/MayKishon/b506f2f2-1ead-4df9-8894-3415e22d1675_Slice-1.png",
                  alt: "mobile"
                }
              },
              content: {
                title: "On the go tracking",
                textDirection: "center",
                body: ["Track time anywhere, anytime with our mobile app."],
                bottomLink: {
                  text: "",
                  href: ""
                },
                titleFontSize: "18px"
              }
            },
            {
              asset: {
                shadow: false,
                image: {
                  src: "/static/uploads/MayKishon/9265883f-9c27-4957-9ce7-ee2699df1bc4_Slice.png",
                  alt: "integrate"
                }
              },
              content: {
                title: "Integrate in no time",
                textDirection: "center",
                body: ["Integrate with your favorite tools and centralize your work in one place."],
                bottomLink: {
                  text: "",
                  href: ""
                },
                topic: {
                  text: ""
                },
                titleFontSize: "18px"
              }
            }
          ],
          paddingTop: "24px",
          paddingBottom: "40px"
        }
      },
      {
        type: "SimpleColorfulTitleWithSignupComponent",
        props: {
          title: "",
          showSignup: true,
          subtitle: "<b>Try monday.com time tracking tool </b>",
          paddingTop: "40px",
          paddingBottom: ""
        }
      },
      {
        type: "SimpleColorfulTitleWithSignupComponent",
        props: {
          title: "Seamlessly turn your data into clear insights",
          showSignup: false,
          paddingTop: "80px"
        }
      },
      {
        type: "TextAndAssetComponent",
        props: {
          content: {
            topic: {
              text: "",
              color: "brand-blue"
            },
            title: "Stay on top of your time",
            body: [
              "Know exactly how much time is spent on each project and task. See who's working on what, and what's keeping you behind."
            ],
            bottomLink: {
              href: "",
              text: ""
            },
            titleFontSize: "28px"
          },
          asset: {
            side: "right",
            shadow: false,
            video: {},
            image: {
              src: "/static/uploads/MayKishon/2b5c3545-e7e6-41bf-a36c-d6b1b581c663_Stayontime.png",
              alt: "Easily track times and tasks"
            }
          },
          backgroundColor: "white",
          paddingBottom: "80px",
          paddingTop: "80px"
        }
      },
      {
        type: "TextAndAssetComponent",
        props: {
          content: {
            topic: {
              text: "",
              color: "brand-blue"
            },
            title: "Create Flexible Reports",
            body: [
              "Analyse your data as you wish - see a breakdown of time by projects, clients and tasks - and make data-driven decisions."
            ],
            bottomLink: {
              href: "",
              text: ""
            },
            titleFontSize: "28px",
            textDirection: "left"
          },
          asset: {
            side: "left",
            shadow: false,
            video: {
              playWhenVisible: false
            },
            image: {
              src: "/static/uploads/MayKishon/bf4a862e-c115-42f6-89ca-93cd5396e39b_Dashboardstime.png",
              alt: "Issue time sheets and reports"
            }
          },
          paddingTop: "40px",
          paddingBottom: "40px",
          isFullWidth: false
        }
      },
      {
        type: "TextAndAssetComponent",
        props: {
          content: {
            topic: {
              text: "",
              color: "brand-blue"
            },
            title: "Set Smart Notifications",
            body: [
              "Immediately get notified when a task is done or when a project is past its due date. Set smart reminders for the stuff that matter to you the most - and always deliver on time."
            ],
            bottomLink: {
              href: "",
              text: ""
            },
            titleFontSize: "28px"
          },
          asset: {
            side: "right",
            shadow: false,
            video: {
              loop: false,
              playWhenVisible: false
            },
            image: {
              src: "/static/uploads/MayKishon/1eae46c3-03c9-45f9-8024-f036a618cfc8_notification.png",
              alt: "Custom notifications & reminders"
            }
          },
          paddingTop: "40px",
          paddingBottom: "80px"
        }
      },
      {
        type: "ReviewsComponent",
        props: {
          columnWidth: "350px",
          reviews: [
            {
              reviewContent: [
                '"The UI design is visually pleasing and makes the program easy to use.  The software can be easily customized for different types of projects. Time tracking is great and helps us account for time spent on any given project."'
              ],
              reviewerName: "Charles C.",
              reviewedOn: "Capterra"
            },
            {
              reviewContent: [
                '"Checking numerous tools for project mgmt, monday.com stands out in simplicity of use, getting started right away and easy to share. monday.com gives me a full overview on running projects, as well as helping me keep track of the steps along the way. 5 stars of 5!"'
              ],
              reviewerName: "Lars D.",
              reviewedOn: "Capterra"
            }
          ],
          backgroundColor: "snow",
          paddingTop: "40px",
          paddingBottom: "40px"
        }
      },
      {
        type: "SimpleColorfulTitleWithSignupComponent",
        props: {
          title: "Try monday.com <brand-blue>time tracking tool </brand-blue>",
          showSignup: true,
          paddingTop: "100px",
          paddingBottom: "px"
        }
      },
      {
        type: "SecurityAndPrivacyComponent",
        props: {
          assets: [
            {
              url: "/static/img/security/gdpr.png",
              alt: "GDPR"
            },
            {
              url: "/static/img/security/iso-27001.png",
              alt: "ISO-27001"
            },
            {
              url: "/static/img/security/iso-27018.png",
              alt: "ISO-27018"
            },
            {
              url: "/static/img/security/soc.png",
              alt: "SOC"
            }
          ],
          paragraph: {
            textDirection: "center",
            title: "",
            body: [""]
          },
          backgroundColor: "white",
          paddingBottom: "20px",
          paddingTop: ""
        }
      },
      {
        type: "ShortFooterComponent",
        props: {
          isFullWidth: true,
          paddingTop: ""
        }
      },
      {
        type: "ParagraphComponent",
        props: {
          title: "",
          titleFontSize: "12px",
          textDirection: "center",
          backgroundColor: "snow",
          paddingTop: ""
        }
      }
    ]
  }
};
