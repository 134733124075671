import { FIELD_TYPES } from "constants/template-field-type";
import YoutubeVideoWithPosterFields from "components/core/youtube-video-with-poster/fields-config";
import ThemeConfig from "segments/core-configs/theme-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Embedded Youtube",
  fields: {
    ...ThemeConfig,
    ...YoutubeVideoWithPosterFields
  }
};
