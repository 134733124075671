import { FIELD_TYPES } from "constants/template-field-type";
import gridImageFieldsConfig from "segments/desktop/core-components/grid-image/fields-config";

export default {
  images: {
    type: FIELD_TYPES.MULTIPLE_NESTED,
    label: "Images",
    maxLimit: 12,
    nestedConfig: {
      type: FIELD_TYPES.NESTED,
      fields: gridImageFieldsConfig
    }
  }
};
