import dynamic from "next/dynamic";

const FullWithBulletsParagraphComponent = require("./full-with-bullets-paragraph-component").default;

import FullWithBulletsParagraphConfig from "./full-with-bullets-paragraph-config";
import FullWithBulletsParagraphAdvancedConfig from "./full-with-bullets-paragraph-advanced-config";

export default {
  FullWithBulletsParagraphComponent,
  FullWithBulletsParagraphConfig,
  FullWithBulletsParagraphAdvancedConfig
};
