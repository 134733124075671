import { PureComponent } from "react";
import { componentStyles } from "./markdown.scss";
import DefaultProps from "./default-props";

import dynamic from "next/dynamic";
const ReactMarkdown = dynamic(() => import("react-markdown/with-html"));
const CodeBlock = dynamic(() => import("./codeblock"));

export default class Markdown extends PureComponent {
  render() {
    const { input } = this.props;

    return (
      <div className="markdown">
        <ReactMarkdown source={input} escapeHtml={false} renderers={{ code: CodeBlock }} />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

Markdown.defaultProps = DefaultProps;
