import css from "styled-jsx/css";
import { MOBILE_QUERY_MAX_WIDTH_M, MOBILE_QUERY_MAX_WIDTH, MACBOOK_PRO_MAX_WIDTH } from "/constants/vp-sizes";

export const componentStyles = css.global`
  .security-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .security-logo-wrapper {
      &:not(:last-child) {
        margin-right: 24px;

        @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
          margin-right: 20px;
        }

        @media (max-width: ${MOBILE_QUERY_MAX_WIDTH_M}) {
          margin-right: 16px;
        }
      }

      .security-logo {
        height: 72px;

        &.small-logo {
          height: 48px;
        }
      }
    }
  }
`;
