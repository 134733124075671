import { getQueryParamValue } from "/utils/url";
import { getTopicOptionLabelByZendeskValue, ZENDESK_TOPIC_INPUT_NAME } from "../zendesk-service/zendesk-inputs";

export const inputValidate = (inputData, inputValue, allInputsValues = null) => {
  const { validate, isRequired, errorMessage, validateOnFormSubmission } = inputData;

  if (isRequired && !inputValue) return { isValid: false, errorMessage: "forms.requiredError" };

  const isSingleFieldValid = validate ? validate(inputValue) : true;
  const isFieldValidatedWithOtherFields =
    validateOnFormSubmission && allInputsValues ? validateOnFormSubmission(inputValue, allInputsValues) : true;

  const isValid = isSingleFieldValid && isFieldValidatedWithOtherFields;

  const msg = !isValid && (errorMessage || "forms.requiredError");
  return { isValid, errorMessage: msg };
};

export const getAllInputFieldsNames = (formConfig) => {
  const { rows } = formConfig;

  const inputsNames = new Set();

  for (const row of rows) {
    for (const input of row) {
      const { inputName } = input;
      inputsNames.add(inputName);
    }
  }

  return [...inputsNames];
};

export const getInputDataByName = (formConfig, name) => {
  const { rows } = formConfig;

  for (const row of rows) {
    for (const input of row) {
      const { inputName } = input;

      if (inputName === name) return input;
    }
  }

  return null;
};

export const prePopulateInputsIfNeeded = () => {
  const inputs = {};
  const topicOverride = getQueryParamValue(window.location, ZENDESK_TOPIC_INPUT_NAME);
  if (topicOverride) {
    const topicLabel = getTopicOptionLabelByZendeskValue(topicOverride);
    if (topicLabel) {
      inputs[ZENDESK_TOPIC_INPUT_NAME] = getTopicOptionLabelByZendeskValue(topicOverride);
    }
  }
  return inputs;
};
