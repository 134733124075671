import PersonInfoConfig from "segments/desktop/core-components/person-info/fields-config";
import TestimonialsQuoteConfig from "segments/desktop/core-components/testimonials-quote/fields-config";
import { FIELD_TYPES } from "constants/template-field-type";
import TitleFieldsConfig from "segments/desktop/core-components/title/fields-config";

export default {
  ...TitleFieldsConfig,
  testimonialsConfig: {
    type: FIELD_TYPES.NESTED,
    label: "Testimonials config",
    fields: TestimonialsQuoteConfig
  },
  personConfig: {
    type: FIELD_TYPES.NESTED,
    label: "Person config",
    fields: PersonInfoConfig
  }
};
