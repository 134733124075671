import { PureComponent } from "react";
import { componentStyles } from "./generic-form-input.scss";
import { withGenericConfig } from "/contexts/generic-config-context";
import classnames from "classnames";
import { getInputComponentByType } from "client/services/form-service/form-inputs";

class GenericFormInput extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { inputData, errorState, translate } = this.props;
    const { isRequired, title, style, type, id } = inputData;
    const { isValid, errorMessage } = errorState;

    const requiredTextLabel = translate(errorMessage);
    const InputComponent = getInputComponentByType(type);

    const errorLabelId = `error_${id}`;

    return (
      <div className="generic-form-input-component" style={{ ...style }}>
        <label
          className={classnames("input-title", {
            "contact-form-required-input": isRequired
          })}
          htmlFor={id}
        >
          {translate(title)}
        </label>
        <div className="form-input-wrapper">
          <InputComponent
            id={id}
            {...this.props}
            isRequired={isRequired}
            invalid={!isValid}
            errorLabelId={isValid ? null : errorLabelId}
          />
          {!isValid && (
            <span className="required-error" id={errorLabelId}>
              {requiredTextLabel}
            </span>
          )}
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

GenericFormInput.defaultProps = {
  errorState: {
    isValid: true,
    errorMessage: null
  }
};

export default withGenericConfig(GenericFormInput);
