import { SIGNUP_BUTTON_TYPE } from "segments/desktop/core-components/button/button-consts";
import {
  CRM,
  DESIGN_AND_CREATIVE,
  GENERIC_CLUSTER,
  HR,
  IT,
  MARKETING,
  OPERATIONS,
  PROJECT_MANAGEMENT_USE_CASE,
  SOFTWARE
} from "constants/clusters";
import {
  BRAND_AND_WORK_MANAGEMENT_LIGHT_TINT_02,
  BRAND_PRIMARY_YELLOW,
  CRM_PRIMARY_COLOR,
  DEV_PRIMARY_COLOR,
  MARKETER_PRIMARY_COLOR,
  PROJECTS_PRIMARY_COLOR
} from "styles/color-consts";

export default {
  width: "100%",
  gridSelectableTags: [
    {
      tagText: "Creative & design",
      tagBackgroundColor: "lipstick",
      overridePageCluster: DESIGN_AND_CREATIVE
    },
    {
      tagText: "Sales & CRM",
      tagBackgroundColor: CRM_PRIMARY_COLOR,
      overridePageCluster: CRM
    },
    {
      tagText: "Project management",
      tagBackgroundColor: PROJECTS_PRIMARY_COLOR,
      overridePageCluster: GENERIC_CLUSTER,
      overridePageSubCluster: PROJECT_MANAGEMENT_USE_CASE
    },
    {
      tagText: "Marketing",
      tagBackgroundColor: MARKETER_PRIMARY_COLOR,
      overridePageCluster: MARKETING
    },
    {
      tagText: "HR",
      tagBackgroundColor: "sunset",
      overridePageCluster: HR
    },
    {
      tagText: "Task management",
      tagBackgroundColor: BRAND_AND_WORK_MANAGEMENT_LIGHT_TINT_02,
      overridePageCluster: GENERIC_CLUSTER
    },
    {
      tagText: "Software development",
      tagBackgroundColor: DEV_PRIMARY_COLOR,
      overridePageCluster: SOFTWARE
    },
    {
      tagText: "Operations",
      tagBackgroundColor: BRAND_PRIMARY_YELLOW,
      overridePageCluster: OPERATIONS
    },
    {
      tagText: "More workflows",
      tagBackgroundColor: "chill-blue",
      overridePageCluster: GENERIC_CLUSTER
    }
  ],
  buttonConfig: {
    buttonType: SIGNUP_BUTTON_TYPE,
    additionalText: "No credit card needed &nbsp • &nbsp Unlimited time on Free plan"
  },
  withSecondaryButton: false,
  secondaryButtonConfig: null,
  buttonTopMargin: "44px"
};
