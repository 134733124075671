import css from "styled-jsx/css";
import { REACT_CANVAS, SSR_CANVAS, CANVAS_PREVIEW } from "/constants/z-index";

export const componentStyles = css`
  .canvas-component {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: ${REACT_CANVAS};
    
    &.ssr {
      z-index: ${SSR_CANVAS};
    }

    &.preview {
      z-index: ${CANVAS_PREVIEW};
    }
  }
`;
