import { PureComponent } from "react";
import { componentStyles } from "./developers-apps-menu-static-component.scss";
import BaseDocumentationMenuComponent from "components/pages/developers/documentation/base-documentation-menu/base-documentation-menu-component";
import { sameUrls } from "/utils/url";
import { withRouter } from "next/router";
import { DEVELOPER_APPS_NAVIGATION_MENU_LINKS } from "constants/links";
import MenuConstants from "components/pages/developers/documentation/base-documentation-menu/constants";

class DevelopersAppsMenuStaticComponent extends PureComponent {
  getSections() {
    const { router = {} } = this.props;
    const currentPath = router.asPath;

    return DEVELOPER_APPS_NAVIGATION_MENU_LINKS.map((category) => {
      const links = category.links || [];
      const enrichedLinks = links.map((linkData) => {
        return { ...linkData, isSelected: sameUrls(linkData.href, currentPath) };
      });
      return { ...category, links: enrichedLinks };
    });
  }

  render() {
    const sections = this.getSections();
    return (
      <div className="developers-apps-menu-static-component">
        <BaseDocumentationMenuComponent sections={sections} menuWidth={MenuConstants.DEVELOPERS_MENU_WIDTH} />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

export default withRouter(DevelopersAppsMenuStaticComponent);

DevelopersAppsMenuStaticComponent.defaultProps = {};
