import dynamic from "next/dynamic";

const SideBySideTextAndAssetComponent = require("./side-by-side-text-and-asset-component").default;

import SideBySideTextAndAssetConfig from "./side-by-side-text-and-asset-config";
import SideBySideTextAndAssetAdvancedConfig from "./side-by-side-text-and-asset-advanced-config";

export default {
  SideBySideTextAndAssetComponent,
  SideBySideTextAndAssetConfig,
  SideBySideTextAndAssetAdvancedConfig
};
