import dynamic from "next/dynamic";

const FullBackgroundTextAndAssetComponent = require("./full-background-text-and-asset-component").default;

import FullBackgroundTextAndAssetConfig from "./full-background-text-and-asset-config";
import FullBackgroundTextAndAssetAdvancedConfig from "./full-background-text-and-asset-advanced-config";

export default {
  FullBackgroundTextAndAssetComponent,
  FullBackgroundTextAndAssetConfig,
  FullBackgroundTextAndAssetAdvancedConfig
};
