import { getMondaySolutionById } from "server/services/solutions/solutions-api-service";
import { getSolutionById } from "services/templates-generator/api-service";

const hasSameIds = (solutionsInfo, prevSolutionsInfo) => {
  return !solutionsInfo.find(({ solutionId }) => {
    return !prevSolutionsInfo.find(({ solutionId: prevSolutionId }) => {
      return solutionId === prevSolutionId;
    });
  });
};

export default [
  {
    propsPropertyName: "solutions",
    shouldFetch: ({ props, previousProps, pageConfig, previousPageConfig }) => {
      let res = false;
      // Has ids
      if (props.solutionsInfo?.length > 0) {
        // Add/Remove ids
        if (props.solutionsInfo.length !== previousProps.solutionsInfo?.length) {
          res = true;
          // Id has changed
        } else if (!hasSameIds(props.solutionsInfo, previousProps.solutionsInfo)) {
          res = true;
          // Locale has changed
        } else if (pageConfig.localeId !== previousPageConfig.localeId) {
          res = true;
        }
      }
      return res;
    },
    fetch: async ({ props, pageConfig, isClientFetch }) => {
      const { solutionsInfo } = props;
      const { localeId, path } = pageConfig;
      const solutionRequests = solutionsInfo.map(async (info) => {
        const { solutionId } = info;
        if (solutionId) {
          let solution;
          if (isClientFetch) {
            const res = await getSolutionById({ solutionId, localeId });
            solution = res.solution;
          } else {
            solution = await getMondaySolutionById({ solutionId, localeId });
          }
          if (!solution) {
            throw new Error(`solution (id: ${solutionId}) can't be found in page ${path}`);
          }
          return solution;
        }
      });
      const solutions = await Promise.all(solutionRequests);
      return solutions;
    }
  }
];
