import { PureComponent } from "react";
import { componentStyles } from "./social-icon-link-component.scss";

export default class SocialIconLinkComponent extends PureComponent {
  render() {
    const { icon: Icon, color } = this.props;

    return (
      <div className="social-icon-link-component">
        <Icon className="social-icon" style={{ fill: color }} />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
