import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";

export const componentStyles = css`
  .press-grid-component {
    max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
    margin: auto;
    .title-wrapper {
      padding-top: 32px;
    }
    .cards-wrapper {
      padding-top: 64px;
      padding-bottom: 64px;
    }
  }
`;
