import css from "styled-jsx/css";
import { IMAGE_WIDTH, IMAGE_HEIGHT } from "../../constants";

const IMAGE_WIDTH_PX = `${IMAGE_WIDTH}px`;
const IMAGE_HEIGHT_PX = `${IMAGE_HEIGHT}px`;

const FILE_X_LOCATION = 651;
const EFFORT_X_LOCATION = 1020;
const ICON_X_LOCATION = 14;
const OWNER_X_LOCATION = 425;
const OWNER_FIRST_X_LOCATION = 414;
const OWNER_SECOND_X_LOCATION = 436;
const PULSE_X_LOCATION = 79;
const STATUS_X_LOCATION = 494;
const TEAM_X_LOCATION = 901;
const TIMELINE_X_LOCATION = 716;

export const componentStyles = css.global`
  .folding-table-container {
    .table-picture-component {
      height: ${IMAGE_HEIGHT_PX};
      width: ${IMAGE_WIDTH_PX};
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
    }

    .folding-table-container {
      width: ${IMAGE_WIDTH_PX};
    }

    .scene-picture-wrapper {
      position: absolute;
    }

    .scene-item {
      position: absolute;
      transition: transform ease-in-out 0.6s, opacity ease-in-out 0.5s;
    }

    .fade-in.fade-in.fade-in {
      opacity: 1;
    }

    .fade-out.fade-out {
      opacity: 0.01;
    }

    .sticky {
      position: fixed;
      display: flex;
      justify-content: center;
    }

    .effort-item {
      width: 116px;
      height: 44px;
    }

    .effort-first-item-start {
      transform: translate(1200px, -62vh);
      opacity: 0.32;
    }

    .effort-first-item-end {
      transform: ${`translate(${EFFORT_X_LOCATION}px, 155px)`};
      opacity: 1;
    }

    .effort-second-item-start {
      transform: ${`translate(${EFFORT_X_LOCATION}px, 204px)`};
      opacity: 0.01;
    }

    .effort-third-item-start {
      transform: ${`translate(${EFFORT_X_LOCATION}px, 253px)`};
      opacity: 0.01;
    }

    .effort-fourth-item-start {
      transform: ${`translate(${EFFORT_X_LOCATION}px, 363px)`};
      opacity: 0.01;
    }

    .effort-fifth-item-start {
      transform: ${`translate(${EFFORT_X_LOCATION}px, 412px)`};
      opacity: 0.01;
    }

    .effort-sixth-item-start {
      transform: ${`translate(${EFFORT_X_LOCATION}px, 461px)`};
      opacity: 0.01;
    }

    .effort-seventh-item-start {
      transform: ${`translate(${EFFORT_X_LOCATION}px, 510px)`};
      opacity: 0.01;
    }

    .file-item {
      width: 22px;
      height: 28px;
    }

    .file-first-item-start {
      transform: ${`translate(${FILE_X_LOCATION}px, 162px)`};
      opacity: 0;
    }

    .file-first-item-end {
      opacity: 1;
    }

    .file-second-item-start {
      transform: translate(1211px, -33vh);
      opacity: 0.2;
    }

    .file-second-item-end {
      opacity: 1;
      transform: ${`translate(${FILE_X_LOCATION}px, 371px)`};
    }

    .file-third-item-start {
      transform: translate(-91px, -29vh);
      opacity: 0.43;
    }

    .file-third-item-end {
      transform: ${`translate(${FILE_X_LOCATION}px, 420px)`};
      opacity: 1;
    }

    .icon-item {
    }

    .icon-first-item-start {
      transform: ${`translate(${ICON_X_LOCATION}px, 57px)`};
      opacity: 0.01;
      width: 20px;
      height: 20px;
    }

    .icon-second-item-start {
      transform: ${`translate(${ICON_X_LOCATION}px, 98px)`};
      opacity: 0.01;
      width: 15px;
      height: 15px;
    }

    .icon-third-item-start {
      transform: ${`translate(${ICON_X_LOCATION}px, 135px)`};
      opacity: 0.01;
      width: 15px;
      height: 15px;
    }

    .other-second-item-start {
      border-radius: 50%;
      width: 44px;
      height: 41px;
      transform: translate(53px, -52vh);
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
      opacity: 0.7;
    }

    .other-fourth-item-start {
      height: 57px;
      transform: translate(111px, -29vh);
      opacity: 0.7;
    }

    .owner-item {
      border-radius: 50%;
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
    }

    .owner-first-item-start {
      transform: translate(4px, -50vh);
      width: 56px;
      height: 56px;
      opacity: 0.8;
    }

    .owner-first-item-end {
      transform: ${`translate(${OWNER_X_LOCATION}px, 161px)`};
      width: 32px;
      height: 32px;
      opacity: 1;
    }

    .owner-second-item-start {
      transform: translate(-45px, -48vh);
      width: 24px;
      height: 24px;
      z-index: 1;
      opacity: 0.4;
    }

    .owner-second-item-end {
      transform: ${`translate(${OWNER_FIRST_X_LOCATION}px, 369px)`};
      width: 32px;
      height: 32px;
      opacity: 1;
    }

    .owner-third-item-start {
      transform: translate(-30px, -54vh);
      width: 32px;
      height: 32px;
    }

    .owner-third-item-end {
      transform: ${`translate(${OWNER_X_LOCATION}px, 418px)`};
    }

    .owner-fourth-item-start {
      transform: translate(1151px, -56vh);
      width: 64px;
      height: 64px;
    }

    .owner-fourth-item-end {
      transform: ${`translate(${OWNER_X_LOCATION}px, 210px)`};
      width: 32px;
      height: 32px;
    }

    .owner-fifth-item-start {
      transform: translate(1106px, -51vh);
      width: 33px;
      height: 33px;
      opacity: 0.2;
    }

    .owner-fifth-item-end {
      transform: ${`translate(${OWNER_SECOND_X_LOCATION}px, 259px)`};
      width: 32px;
      height: 32px;
      opacity: 1;
    }

    .owner-sixth-item-start {
      transform: translate(1139px, -46vh);
      width: 41px;
      height: 41px;
    }

    .owner-sixth-item-end {
      transform: ${`translate(${OWNER_X_LOCATION}px, 467px)`};
      width: 32px;
      height: 32px;
    }

    .owner-seventh-item-start {
      transform: ${`translate(${OWNER_SECOND_X_LOCATION}px, 369px)`};
      width: 32px;
      height: 32px;
      opacity: 0.01;
    }

    .owner-eighth-item-start {
      transform: ${`translate(${OWNER_FIRST_X_LOCATION}px, 259px)`};
      width: 32px;
      height: 32px;
      opacity: 0.01;
      z-index: 1;
    }

    .owner-ninth-item-start {
      transform: ${`translate(${OWNER_X_LOCATION}px, 516px)`};
      width: 32px;
      height: 32px;
      opacity: 0.01;
    }

    .owner-tenth-item-start {
      transform: translate(1043px, 33px);
      width: 24px;
      height: 24px;
      opacity: 0.01;
    }

    .owner-eleventh-item-start {
      transform: translate(1061px, 33px);
      width: 24px;
      height: 24px;
      opacity: 0.01;
      z-index: 1;
    }

    .owner-twelfth-item-start {
      transform: translate(1078px, 33px);
      width: 24px;
      height: 24px;
      opacity: 0.01;
      z-index: 2;
    }

    .pulse-item {
      width: 309px;
      height: 44px;
    }

    .pulse-first-item-start {
      transform: translate(-148px, -38vh);
      opacity: 0.38;
    }

    .pulse-first-item-end {
      transform: ${`translate(${PULSE_X_LOCATION}px, 363px)`};
      opacity: 1;
    }

    .pulse-second-item-start {
      transform: ${`translate(${PULSE_X_LOCATION}px, 204px)`};
    }

    .pulse-third-item-start {
      transform: ${`translate(${PULSE_X_LOCATION}px, 253px)`};
    }

    .pulse-fourth-item-start {
      opacity: 0.5;
      transform: translate(-77px, -63vh);
    }

    .pulse-fourth-item-end {
      opacity: 1;
      transform: ${`translate(${PULSE_X_LOCATION}px, 155px)`};
    }

    .pulse-fifth-item-start {
      transform: ${`translate(${PULSE_X_LOCATION}px, 412px)`};
    }

    .pulse-sixth-item-start {
      transform: ${`translate(${PULSE_X_LOCATION}px, 461px)`};
    }

    .pulse-seventh-item-start {
      transform: ${`translate(${PULSE_X_LOCATION}px, 510px)`};
    }

    .status-item {
      width: 127px;
      height: 44px;
    }

    .status-first-item-start {
      transform: translate(1235px, -44vh);
    }

    .status-first-item-end {
      transform: ${`translate(${STATUS_X_LOCATION}px, 155px)`};
    }

    .status-second-item-start {
      transform: ${`translate(${STATUS_X_LOCATION}px, 204px)`};
    }

    .status-third-item-start {
      transform: translate(1205px, -14vh);
      opacity: 0.1;
    }

    .status-third-item-end {
      transform: ${`translate(${STATUS_X_LOCATION}px, 253px)`};
      opacity: 1;
    }

    .status-fourth-item-start {
      transform: translate(-51px, -23vh);
    }

    .status-fourth-item-end {
      transform: ${`translate(${STATUS_X_LOCATION}px, 363px)`};
    }

    .status-fifth-item-start {
      transform: ${`translate(${STATUS_X_LOCATION}px, 461px)`};
    }

    .status-sixth-item-start {
      transform: translate(56px, -15vh);
      opacity: 0.2;
    }

    .status-sixth-item-end {
      transform: ${`translate(${STATUS_X_LOCATION}px, 412px)`};
      opacity: 1;
    }

    .status-seventh-item-start {
      transform: ${`translate(${STATUS_X_LOCATION}px, 510px)`};
    }

    .team-item {
      width: 114px;
      height: 44px;
    }

    .team-first-item-start {
      opacity: 0;
      transform: ${`translate(${TEAM_X_LOCATION}px, 155px)`};
    }

    .team-first-item-end {
      opacity: 1;
    }

    .team-second-item-start {
      transform: translate(1000px, -35vh);
      opacity: 0.3;
    }

    .team-second-item-end {
      transform: ${`translate(${TEAM_X_LOCATION}px, 204px)`};
      opacity: 1;
    }

    .team-third-item-start {
      transform: ${`translate(${TEAM_X_LOCATION}px, 253px)`};
      opacity: 0.01;
    }

    .team-fourth-item-start {
      transform: translate(952px, -20vh);
      opacity: 0.2;
    }

    .team-fourth-item-end {
      transform: ${`translate(${TEAM_X_LOCATION}px, 363px)`};
      opacity: 1;
    }

    .team-fifth-item-start {
      transform: ${`translate(${TEAM_X_LOCATION}px, 412px)`};
      opacity: 0.01;
    }

    .team-sixth-item-start {
      transform: ${`translate(${TEAM_X_LOCATION}px, 461px)`};
      opacity: 0.01;
    }

    .team-seventh-item-start {
      transform: ${`translate(${TEAM_X_LOCATION}px, 510px)`};
      opacity: 0.01;
    }
    .timeline-item {
      width: 167px;
      height: 27px;
    }

    .timeline-first-item-start {
      transform: translate(1147px, -24vh);
      opacity: 0.7;
    }

    .timeline-first-item-end {
      transform: ${`translate(${TIMELINE_X_LOCATION}px, 164px)`};
      opacity: 1;
    }

    .timeline-second-item-start {
      transform: ${`translate(${TIMELINE_X_LOCATION}px, 213px)`};
      opacity: 0.01;
    }

    .timeline-third-item-start {
      transform: ${`translate(${TIMELINE_X_LOCATION}px, 262px)`};
      opacity: 0.01;
    }

    .timeline-fourth-item-start {
      transform: ${`translate(${TIMELINE_X_LOCATION}px, 372px)`};
      opacity: 0.01;
    }

    .timeline-fifth-item-start {
      transform: ${`translate(${TIMELINE_X_LOCATION}px, 421px)`};
      opacity: 0.01;
    }

    .timeline-sixth-item-start {
      transform: ${`translate(${TIMELINE_X_LOCATION}px, 470px)`};
      opacity: 0.01;
    }

    .timeline-seventh-item-start {
      transform: ${`translate(${TIMELINE_X_LOCATION}px, 519px)`};
      opacity: 0.01;
    }
  }
`;
