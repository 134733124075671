import BoardTabs from "segments/desktop/core-components/board-tabs/board-tabs";
import { componentStyles } from "./board-tabs-component.scss";

const BoardTabsComponent = (props) => {
  return (
    <div className="board-tabs-component">
      <BoardTabs {...props} contentWrapperClassName="board-content-asset-wrapper" />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default BoardTabsComponent;

BoardTabsComponent.defaultProps = BoardTabs.defaultProps;
