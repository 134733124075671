// plop_marker:layouts_import
import FullBackgroundHero from "./full-background-hero";
import SideBySideWithBackgroundHero from "./side-by-side-with-background-hero";
import SideBySideHero from "./side-by-side-hero";

export default {
  // plop_marker:layouts_add_layout
  FullBackgroundHero,
  SideBySideWithBackgroundHero,
  SideBySideHero,
};
