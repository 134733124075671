import css from "styled-jsx/css";
import { ITEM_WIDTH, ITEM_TOTAL_HEIGHT } from "../../constants";

const IMAGE_HEIGHT = `${ITEM_TOTAL_HEIGHT}px`;
const IMAGE_WIDTH = `${ITEM_WIDTH}px`;

export const componentStyles = css`
  .integration-badge-component {
    transition: transform 1s linear;
  }

  :global(.integration-badge-image) {
    height: ${IMAGE_HEIGHT};
    width: ${IMAGE_WIDTH};
  }
`;
