import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
import FullParagraphAdvancedConfig from "segments/desktop/core-components/full-paragraph/advanced-fields-config";
import TitleAdvancedConfig from "segments/desktop/core-components/title/advanced-field-config";
import AdvancedSquareTagsFieldsConfig from "segments/desktop/core-components/square-tags/advanced-fields-config";

const { topicBottomMargin, buttonTopMargin, ...restOfParagraphAdvancedConfig } = FullParagraphAdvancedConfig;

const { topTriangle, bottomTriangle, ...restOfSharedFieldConfig } = SharedAdvancedFieldsConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "",
  fields: {
    fullParagraphAdvancedConfig: {
      type: FIELD_TYPES.NESTED,
      label: "Full Paragraph",
      fields: restOfParagraphAdvancedConfig
    },
    subTitleAdvancedConfig: {
      type: FIELD_TYPES.NESTED,
      label: "Sub title",
      fields: TitleAdvancedConfig
    },
    squareTagsMarginTop: {
      type: FIELD_TYPES.TEXT,
      label: "Tags margin top"
    },
    ...AdvancedSquareTagsFieldsConfig,
    ...restOfSharedFieldConfig
  }
};
