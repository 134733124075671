import css from "styled-jsx/css";

export const componentStyles = css`
  .side-by-side-with-icons-text-and-asset-mobile-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 32px 40px;
    :global(.full-paragraph) {
      :global(.icons-wrapper) {
        margin-bottom: 8px;
      }
    }
    .button-wrapper {
      margin-top: var(--margin-between-asset-and-button);
    }
    :global(.two-link-buttons-button.swap-buttons-order) {
      flex-direction: column-reverse;
    }
  }
`;
