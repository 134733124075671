import { handleServerException } from "../server/services/exceptions-handling-service/exceptions-handling-service";

const fetchAllRequestsAndPopulateDynamicData = async ({ segmentsRequest, dynamicData }) => {
    let hasUpdated = false;
    (await Promise.all(segmentsRequest)).forEach(({ id, fetchRes, error, propsPropertyName }) => {
        if (!error) {
            hasUpdated = true;
            dynamicData[id] = { ...(dynamicData[id] || {}), [propsPropertyName]: fetchRes };
        }
    });
    return hasUpdated;
}

const getAllAvailableDynamicRequestsData = ({ pageConfig, segmentsDynamicsFetchMapper }) => {
    const requests = (pageConfig.segments || []).reduce((accRequests, segment) => {
        const {
            type: segmentLayoutType,
            props: segmentLayoutProps,
            id: segmentLayoutId
        } = segment;
        const dynamicFetchRequests = segmentsDynamicsFetchMapper[segmentLayoutType];
        if (dynamicFetchRequests) {
            dynamicFetchRequests.forEach((fetchRequest) => {
                const { shouldFetch, fetch, propsPropertyName } = fetchRequest;
                accRequests.push({ shouldFetch, fetch, propsPropertyName, segmentLayoutProps, segmentLayoutId, segmentLayoutType });
            });
        };
        return accRequests;
    }, []);
    return requests;
}

const collectAllRequestsForClient = ({ pageConfig, segmentsDynamicsFetchMapper, previousPageConfig }) => {
    const previousPropsBySegmentIds = (previousPageConfig.segments || []).reduce((acc, { id, props }) => {
        acc[id] = props
        return acc
    }, {});
    const allRequests = getAllAvailableDynamicRequestsData({ pageConfig, segmentsDynamicsFetchMapper })
    const segmentsRequest = allRequests.reduce((accRequests, request) => {
        const { shouldFetch, fetch, propsPropertyName, segmentLayoutProps, segmentLayoutId } = request;
        if (shouldFetch({
            props: segmentLayoutProps,
            previousProps: previousPropsBySegmentIds[segmentLayoutId] || {},
            pageConfig,
            previousPageConfig
        })) {
            accRequests.push(
                (async () => {
                    try {
                        const fetchRes = await fetch({ props: segmentLayoutProps, pageConfig, isClientFetch: true });
                        return { id: segmentLayoutId, fetchRes, propsPropertyName };
                    } catch (error) {
                        return { error };
                    }
                })()
            );
        }
        return accRequests;
    }, []);
    return segmentsRequest;
}

const collectAllRequestsForServer = ({ pageConfig, segmentsDynamicsFetchMapper, req, res }) => {
    const allRequests = getAllAvailableDynamicRequestsData({ pageConfig, segmentsDynamicsFetchMapper })
    const segmentsRequest = allRequests.reduce((accRequests, request) => {
        const { fetch, propsPropertyName, segmentLayoutProps, segmentLayoutId, segmentLayoutType } = request;
        accRequests.push(
            (async () => {
                try {
                    const fetchRes = await fetch({ props: segmentLayoutProps, pageConfig, isClientFetch: false });
                    return { id: segmentLayoutId, fetchRes, propsPropertyName };
                } catch (error) {
                    const extraLogParams = {
                        segmentLayoutType,
                        segmentLayoutId,
                        fetchProperty: propsPropertyName,
                    }
                    handleServerException({
                        externalMessage: "Faild to fetch dynamic data on template generator",
                        e: error,
                        req,
                        res,
                        extraLogParams
                    });
                    return { error };
                }
            })()
        );
        return accRequests;
    }, []);
    return segmentsRequest;
}

export const fetchDynamicSegmentsRequestFromServer = async ({
    pageConfig = {},
    segmentsDynamicsFetchMapper,
    req,
    res
}) => {
    const dynamicData = {};
    try {
        const segmentsRequest = collectAllRequestsForServer({ pageConfig, segmentsDynamicsFetchMapper, req, res });
        await fetchAllRequestsAndPopulateDynamicData({ segmentsRequest, dynamicData });
    } catch (error) {
        handleServerException({
            externalMessage: "Fatal error: Faild in the proccess of fetching dynamic data on template generator",
            e: error,
            req,
            res,
        });
    }
    return { dynamicData }
};

export const fetchDynamicSegmentsRequestFromClient = async ({
    pageConfig = {},
    previousPageConfig = {},
    segmentsDynamicsFetchMapper,
    previousDynamicData = {},
}) => {
    const dynamicData = { ...previousDynamicData };
    const segmentsRequest = collectAllRequestsForClient({ pageConfig, segmentsDynamicsFetchMapper, previousPageConfig });
    const hasUpdated = await fetchAllRequestsAndPopulateDynamicData({ segmentsRequest, dynamicData });
    return { hasUpdated, dynamicData }
};

export const getDynamicPropsBySegmentLayoutId = (dynamicData, id) => {
    return (dynamicData && dynamicData[id]) || {};
};


export const getFaqData = ({ pageConfig, req, res }) => {
    let faq = [];
    try {
        faq = (pageConfig.segments || []).reduce((accFaq, segment) => {
            const { type, props } = segment;

            if (type === "FrequentlyAskedQuestionsPricingComponent") {
                accFaq.push(...props.questions);
            }
            return accFaq;
        }, []);
    } catch (error) {
        handleServerException({
            externalMessage: "Faild to get FAQ data from segments",
            e: error,
            req,
            res,
        });
    }
    return faq;
};
