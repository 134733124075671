import dynamic from "next/dynamic";

const AllLogosLogosComponent = require("./all-logos-logos-component").default;

import AllLogosLogosConfig from "./all-logos-logos-config";
import AllLogosLogosAdvancedConfig from "./all-logos-logos-advanced-config";

export default {
  AllLogosLogosComponent,
  AllLogosLogosConfig,
  AllLogosLogosAdvancedConfig
};
