import css from "styled-jsx/css";

export const componentStyles = css`
  .full-background-text-and-asset-component {
    height: 650px;
    &:not(.align-to-logo) {
      :global(.full-background-text-and-asset-content) {
        padding: 0 96px;
      }
    }
  }
`;
