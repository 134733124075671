import {
  signupForm,
  socialButtons,
  collaborationWith,
  onePagerTextPart,
  onePagerFullHeightVideoPart,
  popupVideo
} from "../components";

export const ONE_PAGER_WITH_VIDEO_TEMPLATE_CONFIG = {
  socialButtons,
  assetPart: onePagerFullHeightVideoPart,
  popupVideo,
  textPart: onePagerTextPart,
  signupForm,
  collaborationWith,

  defaultData: {
    template: "OnePagerWithVideoTemplate",
    assetPart: {
      type: "video",
      props: {
        formats: [
          "/static/video/one-pager-full-height/stuck-board-video.mp4",
          "/static/video/one-pager-full-height/stuck-board-video.ogv"
        ],
        poster: "/static/video/one-pager-full-height/stuck-board-poster.png"
      }
    },
    popupVideo: {
      show: true,
      videoId: "1UbaEcto56k",
      playButtonText: "Play a 60 sec intro video",
      footerText: "Simplify the way your team works"
    },
    textPart: {
      titleLines: ["Project Management", "<b>is better when it's visual</b>"],
      subtitleLines: ["See what everyone on your team is working on in a single glance"],
      bullets: [
        "Manage your projects & tasks with ease",
        "Plan visually on a timeline",
        "Centralize communication in one place"
      ],
      mobileTitleLines: ["Project Management", "<b>is better when it's visual</b>"],
      mobileSubtitleLines: ["See what everyone on your team", "is working on in a single glance"],
      color: "black"
    },
    collaborationWith: {
      show: false,
      color: "black",
      text: "Powered By"
    },
    signupForm: {
      buttonColor: "brand-pink",
      buttonText: "Get Started",
      inputPlaceholder: "Enter your work email",
      downloadButtonTitle: "Download The App"
    }
  }
};
