import { useState } from "react";
import AssetComponent from "components/core/asset/asset-component";
import MobileTabs from "segments/desktop/core-components/mobile-tabs/mobile-tabs";
import Title from "segments/desktop/core-components/title/title";
import { XS_M } from "constants/sizes";
import classnames from "classnames";
import { componentStyles } from "./side-by-side-board-views-component-tabs-mobile-component.scss";

const SideBySideBoardViewsComponentTabsMobileComponent = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [wasComponentEverVisible, setWasComponentEverVisible] = useState(false);
  const { tabs } = props;

  const navigationItemRenderer = (tab, index) => {
    const { tabLabel, tabId, image } = tab;
    return (
      <div className={classnames("tab-nav-board-views-button",{ selected: selectedTab === index })}>
        <Title title={tabLabel} titleIcon={image?.src} titleSize={XS_M} />
      </div>
    );
  };

  const carouselItemRenderer = (tab) => {
    const { asset } = tab;

    return (
      <div className="asset-and-paragraphs-wrapper">
        <AssetComponent {...asset} />
      </div>
    );
  };

  return (
    <div className="use-cases-tabs-mobile-component">
      <MobileTabs
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        wasComponentEverVisible={wasComponentEverVisible}
        setWasComponentEverVisible={setWasComponentEverVisible}
        navigationItemRenderer={navigationItemRenderer}
        carouselItemRenderer={carouselItemRenderer}
      />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default SideBySideBoardViewsComponentTabsMobileComponent;
