import { FIELD_TYPES } from "constants/template-field-type";
import SelectableTagsFieldsConfig from "segments/desktop/core-components/selectable-tag/fields-config";
import SignupButtonFieldsConfig from "segments/desktop/core-components/button/signup-button/fields-config";
import ThemeConfig from "segments/core-configs/theme-config";

export default {
  ...ThemeConfig,
  darkMode: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Dark mode"
  },
  width: { type: FIELD_TYPES.TEXT, label: "Max Width (%)" },
  gridSelectableTags: {
    type: FIELD_TYPES.MULTIPLE_NESTED,
    label: "Grid Selectable Tags",
    nestedConfig: {
      type: FIELD_TYPES.NESTED,
      fields: {
        ...SelectableTagsFieldsConfig
      }
    }
  },
  buttonConfig: {
    type: FIELD_TYPES.NESTED,
    label: "Signup button config",
    fields: SignupButtonFieldsConfig
  },
  buttonTopMargin: { type: FIELD_TYPES.TEXT, label: "Margin above button (default 32px)" }
};
