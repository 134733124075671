import css from "styled-jsx/css";
import { WHITE_COLOR_NAME, WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import Colors from "styles/colors";

const PROGRESS_BARS_GAP = 8;
const PROGRESS_BARS_GAP_STRING = `${PROGRESS_BARS_GAP}px`;
const PROGRESS_BAR_WIDTH = 36;
const PROGRESS_BAR_WIDTH_STRING = `${PROGRESS_BAR_WIDTH}px`;

export const componentStyles = css`
  .monday-demo-tabs-timeline-and-labels-wrapper {
    display: flex;
    gap: ${PROGRESS_BARS_GAP_STRING};
    flex-direction: row;
    padding: 16px 32px 0;
    &.dark-theme {
      .timeline-tab-indication-label-and-progress-bar-wrapper {
        .timeline-progress-bar-wrapper {
          .timeline-progress-bar {
            .timeline-progress-bar-empty,
            .timeline-progress-bar-filled {
              background-color: ${Colors[WHITE_COLOR_NAME]};
            }
          }
        }
      }
    }
    .timeline-tab-indication-label-and-progress-bar-wrapper {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      width: 100%;
      &.selected {
        .timeline-progress-bar {
          .timeline-progress-bar-filled {
            width: 100%;
            animation: fill-progress-bar linear;
          }
        }
      }
      &.watched {
        .timeline-tab-indication-and-label-wrapper {
          .timeline-tab-indication {
            opacity: 1;
          }
        }
        .timeline-progress-bar-wrapper {
          .timeline-progress-bar {
            .timeline-progress-bar-filled {
              width: 100%;
            }
          }
        }
        &.selected {
          .timeline-progress-bar-wrapper {
            .timeline-progress-bar {
              .timeline-progress-bar-filled {
                width: 100%;
              }
            }
          }
        }
      }
      &:hover {
        .timeline-tab-indication-and-label-wrapper {
          .timeline-tab-indication {
            opacity: 1;
          }
        }
      }
      .timeline-progress-bar-wrapper {
        width: 100%;
        .timeline-progress-bar {
          width: 100%;
          height: 2px;
          display: flex;
          position: relative;

          .timeline-progress-bar-empty,
          .timeline-progress-bar-filled {
            border-radius: ${PROGRESS_BAR_WIDTH_STRING};
            background-color: ${Colors[WORK_OS_IRIS_COLOR_NAME]};
            height: 100%;
          }

          .timeline-progress-bar-empty {
            width: 100%;
            opacity: 0.24;
          }

          .timeline-progress-bar-filled {
            width: 0%;
            position: absolute;
            left: 0;
            transition: height 0.15s ease-in-out;
          }
        }
      }
    }
  }

  @keyframes fill-progress-bar {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
`;
