import css from "styled-jsx/css";
import Colors from "/styles/colors";
import { MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";

export const componentStyles = css.global`
  .contact-sales-hubspot-form-component {
    padding: 48px 48px 80px 48px;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.100091);
    border-radius: 12px;
    margin: 24px 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    #reactHubspotForm2 {
      display: flex;
      justify-content: center;
    }

    .hbspt-form {
      fieldset {
        max-width: 100%;
        text-align: left;

        &.form-columns-1,
        &.form-columns-2 {
          .hs-form-field {
            text-align: left;
            margin-bottom: 24px;
            font-size: 0.731rem;
            position: relative;
          }

          .hs-input {
            width: 100%;
          }
        }

        &.form-columns-1 {
          .hs-form-field {
            width: 100%;
          }
        }

        &.form-columns-2 {
          .hs-form-field {
            width: 50%;
          }
        }

        .input {
          input,
          select,
          textarea {
            height: 40px;
            border-radius: 4px;
            border: #d3d2d2 1px solid;
            text-indent: 10px;
            color: #333;
            width: 100%;
            outline: none;
          }

          select {
            text-indent: 10px;
          }

          textarea {
            height: 80px;
            width: 100% !important;
            resize: none;
          }
        }

        .hs-form-field {
          label {
            margin-bottom: 5px;
            display: inline-block;
            text-indent: 5px;
            font-size: 0.875rem;
            color: ${Colors["black"]};
          }
        }

        .invalid.error {
          border-color: ${Colors["brand-red"]};
        }
      }

      .hs-form-required {
        margin-left: 8px;
        color: ${Colors["brand-red"]};
      }

      .no-list {
        &.hs-error-msgs {
          padding-left: 0;
          margin-top: 2px;
          &.inputs-list {
            list-style-type: none !important;
            position: absolute;
            margin-left: 5px;
            width: 100%;

            & li:not(:first-child) {
              display: none;
            }

            label {
              font-size: 0.6875rem;
              text-indent: 0px;
            }
          }
        }
      }

      .hs_submit {
        float: right;

        input {
          user-select: none;
          line-height: 17px;
          outline: none;
          text-align: center;
          cursor: pointer;
          border-radius: 40px;
          border: none;
          font-size: 0.875rem;
          transition: 0.1s ease background-color;
          padding: 14px 32px;
          font-weight: 300;
          font-size: 0.731rem;
          background: ${Colors["light-blue"]};
          color: ${Colors["white"]};
        }
      }

      .hs_how_can_our_consulting_team_help_you {
        margin-bottom: 5px;
        display: inline-block;
        font-size: 0.731rem;
        width: 100%;
      }

      .hs_error_rollup {
        display: none;
      }
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    .contact-sales-hubspot-form-component {
      padding: 24px 12px 80px 12px;
      margin: 24px 0;
    }
  }
`;
