import MobileTabs from "segments/desktop/core-components/mobile-tabs/mobile-tabs";
import { useState } from "react";
import MobileTabNavButton from "segments/desktop/core-components/mobile-tabs/mobile-tab-nav-button";
import BuildingBlocksTabContentComponent from "../building-blocks-tab-content/building-blocks-tab-content-component";
import { componentStyles } from "./building-blocks-tabs-mobile-component.scss";

const BuildingBlocksTabsMobileComponent = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [wasComponentEverVisible, setWasComponentEverVisible] = useState(false);
  const { buildingBlocksTabsTheme } = props;

  const navigationItemRenderer = (tab, index) => {
    return <MobileTabNavButton label={tab.tabLabel} selected={selectedTab === index} theme={buildingBlocksTabsTheme} />;
  };

  const carouselItemRenderer = (tab, index) => {
    const isSelected = index === selectedTab;
    // bring the first asset on load, and bring everything else when component is visible
    const isAssetVisible = wasComponentEverVisible || isSelected;

    return (
      <BuildingBlocksTabContentComponent
        key={index}
        tab={tab}
        isAssetVisible={isAssetVisible}
        buildingBlocksTabsTheme={buildingBlocksTabsTheme}
        themeColor={buildingBlocksTabsTheme}
        isMobileDisplay={true}
      />
    );
  };

  return (
    <div className="building-blocks-tabs-mobile-component">
      <MobileTabs
        {...props}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        wasComponentEverVisible={wasComponentEverVisible}
        setWasComponentEverVisible={setWasComponentEverVisible}
        navigationItemRenderer={navigationItemRenderer}
        carouselItemRenderer={carouselItemRenderer}
      />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default BuildingBlocksTabsMobileComponent;
