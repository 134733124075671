import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH,
  MOBILE_QUERY_MIN_WIDTH,
  MOBILE_QUERY_MAX_WIDTH,
  TABLET_QUERY_MIN_WIDTH,
  TABLET_QUERY_MAX_WIDTH,
  MOBILE_QUERY_MAX_WIDTH_M,
  SMALL_MOBILE_QUERY_MAX_WIDTH} from "constants/vp-sizes";


export const componentStyles = css.global`
  .monday-integrations-connections-component {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow : hidden;
    .monday-integrations-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      .title-wrapper{
        padding-bottom: 50px;
        text-align: center;
      }
      .toggle-wrapper {
        display: flex;
        justify-content: center;
      }
      .before-animation-paragraph-wrapper{
        text-align: center;
        padding-top: 30px;
        padding-botton: 20px;
      }
      .after-animation-paragraph-wrapper{
        display: none;
        text-align: center;
        padding-top: 30px;
        padding-botton: 20px;
      }
      

      .icons-and-lines-wrapper{
      position: relative;
      .monday-logo {
        position: absolute;
        left: var(--left);
        top: var(--top);
        opacity: 0;
        transition: opacity 1s;
        .picture-component{
          img{
            max-height: 340px;
          }
        }
      }
      .icon {
        position: absolute;
        top: var(--y-before);
        left: var(--x-before);
        transition: top 1s, left 1s;
      }
      .line {
        transition: opacity 1s;
      }
      .paths{
        display:none;
      }
    }
      &.animation {
        
        .before-animation-paragraph-wrapper{
          display:none;
        }
        .after-animation-paragraph-wrapper{
          display: unset;
        }
        .icons-and-lines-wrapper{
          .monday-logo {
            opacity: 1;
          }
        .icon {
          top: var(--y-after);
          left: var(--x-after);
        }
        .line {
          opacity: 0;
          display:none;
        }
        .paths{
          display:unset;
        }
      }
      }
    }
  }
  


  @media (min-width: ${TABLET_QUERY_MIN_WIDTH}) and (max-width: ${TABLET_QUERY_MAX_WIDTH}){ 
    .monday-integrations-connections-component {
      .monday-integrations-wrapper {
        display: flex;
        .before-animation-paragraph-wrapper{
          padding: 0 350px;
        }
        .after-animation-paragraph-wrapper{
          padding: 0 350px;
        }
        .icons-and-lines-wrapper{
          transform : scale(0.75);
        }
      }
    }
  }

  @media (min-width: ${MOBILE_QUERY_MIN_WIDTH}) and (max-width: ${MOBILE_QUERY_MAX_WIDTH}){ 
    .monday-integrations-connections-component {
      .monday-integrations-wrapper {
        display: flex;
        .title-wrapper{
          padding-bottom: 20px;
          .core-title {
            font-size: 1.575rem;
          }
        }
        .toggle-wrapper {
          .toggle{
            width: 350px;
            .toggle-left{
              width: 175px;
              font-size: 0.6875rem;
              padding: 10px 10px;
            }
            .toggle-right{
              width: 175px;
              font-size: 0.6875rem;
              padding: 10px 10px;
            }
          }
        }
        .before-animation-paragraph-wrapper{
          padding: 20px 350px;
          .paragraph-body{
            font-size: 0.875rem;
            line-height: 30px;
          }
        }
        .after-animation-paragraph-wrapper{
          padding: 20px 350px;
          .paragraph-body{
            font-size: 0.875rem;
            line-height: 30px;
          }
        }
        .icons-and-lines-wrapper{
          transform : scale(0.45) translatey(-50px);
          height: 60vw;
        }
      }
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH_M}) {
    .monday-integrations-connections-component {
      .monday-integrations-wrapper {
        display: flex;
        .title-wrapper{
          padding-bottom: 20px;
          .core-title {
            font-size: 1.125rem;
          }
        }
        .toggle-wrapper {
          .toggle{
            width: 330px;
            .toggle-left{
              width: 165px;
              font-size: 0.6875rem;
              padding: 8px 4px;
            }
            .toggle-right{
              width: 165px;
              font-size: 0.6875rem;
              padding: 8px 4px;
            }
          }
        }
        .before-animation-paragraph-wrapper{
          padding: 20px 450px;
          .paragraph-body{
            font-size: 0.8125rem;
            line-height: 20px;
          }
        }
        .after-animation-paragraph-wrapper{
          padding: 20px 450px;
          .paragraph-body{
            font-size: 0.8125rem;
            line-height: 20px;
          }
        }
        .icons-and-lines-wrapper{
          transform : scale(0.35) translatey(-150px);
          height: 60vw;
        }
      }
    }
  }

  @media (max-width: ${SMALL_MOBILE_QUERY_MAX_WIDTH}) {
    .monday-integrations-connections-component {
      .monday-integrations-wrapper {
        display: flex;
        .title-wrapper{
          .core-title {
            font-size: 0.875rem;
          }
        }
        .toggle-wrapper {
          .toggle{
            width: 280px;
            .toggle-left{
              width: 140px;
              font-size: 0.563rem;
              padding: 6px 4px;
            }
            .toggle-right{
              width: 140px;
              font-size: 0.563rem;
              padding: 6px 4px;
            }
          }
        }
        .icons-and-lines-wrapper{
          transform : scale(0.3) translatey(-150px);
        }
      }
  }
  }

`;
