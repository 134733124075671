import css from "styled-jsx/css";
import Colors from "styles/colors";
import { getComponentShadow, getComponentShadowWithHoverEffect } from "segments/desktop/constants";
import { MD_DESKTOP_WIDTH } from "/constants/vp-sizes";

export const componentStyles = css.global`
  .sign-up-product-card {
    width: 250px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 16px 20px;
    background-color: ${Colors.white};
    transition: transform 300ms ease;
    border-radius: 8px;
    ${getComponentShadow()}
    &:hover {
      ${getComponentShadowWithHoverEffect()}
      outline: none;
    }
    .product-logo-wrapper{
      padding-bottom: 10px;
      .picture-component{
        display: flex;
        justify-content: center;
        img{
        height: 80px;
        }
      }
    }
    .text-wrapper{
      margin-top: 12px;
      .paragraph-body{
        text-align: center;
        color: ${Colors.black};
        line-height: 18px;
        font-size: 0.875rem;
      }
    }
    .seperator-line{
      height: 0.5px;
      background: ${Colors.gray};
      margin-top: 20px;
      margin-bottom: 20px;
      opacity: 0.5;
    }
    .product-tags{
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-bottom: 30px;
      align-items: center;
      .tags-title{
        font-size: 0.6875rem;
        text-align: center;
        color: ${Colors.black};
      }
    }
    .radio-button-component{
      .radio-button-circle{
        border-color: ${Colors.gray};
      }
    }
    
  &.active{
    border-top: 6px solid;
    border-color: currentcolor;
    width: 300px;
    padding-bottom: 20px;
    outline: none;
    .product-logo-wrapper{
      .picture-component{
        display: flex;
        justify-content: center;
        img{
          height: 110px;
        }
      }
    }
    .radio-button-component{
      .radio-button-circle{
        border-color: currentcolor;
        width: 20px;
        height: 20px;
        &:after {
          background: currentcolor;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  }

  @media (max-width: ${MD_DESKTOP_WIDTH}) {
    .sign-up-product-card {
      width: 220px;
      .product-logo-wrapper{
        .picture-component{
          img{
            height: 70px;
          }
        }
      }
      .text-wrapper{
        .paragraph-body{
          font-size: 0.8125rem;
        }
      }
      .product-tags{
        padding-bottom: 20px;
      }
      &.active{
        width: 250px;
        .product-logo-wrapper{
          .picture-component{
            img{
              height: 90px;
            }
          }
        }
      }
    }
  }
`;
