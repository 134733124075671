import {
    WORK_MANAGEMENT_PRODUCT_ID
} from "constants/products";

export default{
    defaultActiveProduct : WORK_MANAGEMENT_PRODUCT_ID,
    skipButtonText: "I’ll decide later",
    continueButtonText: "Continue to sign up",
    titleWithProduct: "We’ve recommended a product<br>based on your selection ",
    subTitle: "You can change or add a product at any time.",
    titleWithoutProduct: "What will you be using monday.com for?",
    textUnderButtons: "Full access. No credit card needed."
};