import { useRef, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import BuildingBlocksTabComponent from "../building-blocks-tab/building-blocks-tab-component";
import BuildingBlocksTabContentComponent from "../building-blocks-tab-content/building-blocks-tab-content-component";
import { componentStyles } from "./building-blocks-tabs-desktop-component.scss";

const BuildingBlocksTabsDesktopComponent = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [wasComponentEverVisible, setWasComponentEverVisible] = useState(false);
  const { tabs, buildingBlocksTabsTheme } = props;

  const VISIBILITY_SENSOR_OFFSET = -100;

  const tabsWithVideoPlayerRefs = [];
  tabs.forEach((tab) => {
    tabsWithVideoPlayerRefs.push({
      ...tab,
      videoPlayerRef: useRef(null)
    });
  });

  const onVisibilityChange = (isVisible) => {
    if (!wasComponentEverVisible && isVisible) {
      setWasComponentEverVisible(true);
    }
  };

  return (
    <VisibilitySensor
      offset={{ bottom: VISIBILITY_SENSOR_OFFSET, top: VISIBILITY_SENSOR_OFFSET }}
      partialVisibility={true}
      onChange={onVisibilityChange}
      active={!wasComponentEverVisible} // turn it off once the component becomes visible
      scrollCheck={!wasComponentEverVisible} // turn it off once the component becomes visible
    >
      <div className="building-blocks-tabs-desktop-component">
        <div className="building-blocks-tabs-wrapper" role="tablist">
          {tabsWithVideoPlayerRefs.map((value, index) => {
            return (
              <BuildingBlocksTabComponent
                key={index}
                buildingBlock={value}
                index={index}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                themeColor={buildingBlocksTabsTheme}
              />
            );
          })}
        </div>
        <div className="building-blocks-contents-wrapper">
          {tabsWithVideoPlayerRefs.map((tab, index) => {
            const isSelected = index === selectedTab;
            // bring the first asset on load, and bring everything else when component is visible
            const isAssetVisible = wasComponentEverVisible || isSelected;
            return (
              <BuildingBlocksTabContentComponent
                key={index}
                tab={tab}
                isSelected={isSelected}
                isAssetVisible={isAssetVisible}
                buildingBlocksTabsTheme={buildingBlocksTabsTheme}
                themeColor={buildingBlocksTabsTheme}
              />
            );
          })}
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    </VisibilitySensor>
  );
};

export default BuildingBlocksTabsDesktopComponent;