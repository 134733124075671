export default [
  {
    type: "icon",
    offset: 100,
    duration: 0,
    pictureSrc: "/static/img/first-fold/icon_1.png",
    classToggle: "fade-in",
    className: "icon-first-item-start"
  },

  {
    type: "icon",
    offset: 200,
    duration: 0,
    pictureSrc: "/static/img/first-fold/icon_2.png",
    classToggle: "fade-in",
    className: "icon-second-item-start"
  },

  {
    type: "icon",
    offset: 300,
    duration: 0,
    pictureSrc: "/static/img/first-fold/icon_3.png",
    classToggle: "fade-in",
    className: "icon-third-item-start"
  }
];
