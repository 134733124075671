import dynamic from "next/dynamic";

const BottomLogosComponent = require("./bottom-logos-component").default;

import BottomLogosConfig from "./bottom-logos-config";
import BottomLogosAdvancedConfig from "./bottom-logos-advanced-config";

export default {
  BottomLogosComponent,
  BottomLogosConfig,
  BottomLogosAdvancedConfig
};
