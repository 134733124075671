import VideoBackground from "segments/desktop/core-components/video-background/video-background";
import { componentStyles } from "./video-background-asset-component.scss";

const VideoBackgroundAssetComponent = (props) => {
  const { assetConfig, fullParagraphConfig, addDarkOverlay, hideBlackBars } = props;

  return (
    <div className="video-background-asset-component">
      <VideoBackground
        assetConfig={assetConfig}
        fullParagraphConfig={fullParagraphConfig}
        addDarkOverlay={addDarkOverlay}
        hideBlackBars={hideBlackBars}
      />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default VideoBackgroundAssetComponent;
