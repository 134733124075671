import {
  signupForm,
  collaborationWith,
  socialButtons,
  onePagerAssetPart,
  onePagerTextPart,
  onePagerMobileType,
  onePagerMobileAssetType,
  popupVideo
} from "../components";
import { FIELD_TYPES } from "constants/template-field-type";
import SocialProof from "components/templates-generator/config/segments/social-proof/social-proof-config";
import ShortFooter from "components/templates-generator/config/segments/short-footer/short-footer-config";

export const ONE_PAGER_ASSET_TEMPLATE_CONFIG = {
  nameSignature: { type: "text", label: "Sign Your Name (optional)" },
  socialButtons,
  assetPart: onePagerAssetPart,
  textPart: onePagerTextPart,
  mobileVersion: onePagerMobileType,
  mobileAssetType: onePagerMobileAssetType,
  collaborationWith,
  popupVideo,
  signupForm,
  SocialProof: {
    type: FIELD_TYPES.NESTED,
    label: "Social Proof",
    fields: {
      showSocialProof: { type: FIELD_TYPES.BOOLEAN, label: "Show Social Proof" },
      content: SocialProof
    }
  },
  ShortFooter: {
    type: FIELD_TYPES.NESTED,
    label: "Short Footer",
    fields: {
      showShortFooter: { type: FIELD_TYPES.BOOLEAN, label: "Show Short Footer" },
      content: ShortFooter
    }
  },
  defaultData: {
    template: "OnePagerAssetTemplate",
    assetPart: {
      type: "video",
      props: {
        formats: ["/static/video/one-pager/status-video.mp4", "/static/video/one-pager/status-video.ogv"],
        poster: "/static/video/one-pager/status-video-poster.jpg"
      }
    },
    textPart: {
      titleLines: ["Project Management", "<b>is better when it's visual</b>"],
      subtitleLines: ["See what everyone on your team is working on in a single glance"],
      bullets: [
        "Manage your projects & tasks with ease",
        "Plan visually on a timeline",
        "Centralize communication in one place"
      ],
      mobileTitleLines: ["Project Management", "<b>is better when it's visual</b>"],
      mobileSubtitleLines: ["See what everyone on your team", "is working on in a single glance"],
      color: "black"
    },
    collaborationWith: {
      show: false,
      color: "black",
      text: "Powered By"
    },
    popupVideo: {
      show: false,
      videoId: "21NBzs5juJk",
      playButtonText: "Watch a demo",
      footerText: "Simplify the way your team works"
    },
    signupForm: {
      buttonColor: "brand-pink",
      buttonText: "Get Started",
      inputPlaceholder: "Enter your work email",
      downloadButtonTitle: "Download The App"
    },
    SocialProof: {
      showSocialProof: false,
      content: {
        CompaniesLogos: {
          paragraph: {},
          showSignup: false
        },
        Reviews: {
          columnWidth: "350px",
          reviews: [
            {
              reviewContent: [
                '"I\'ve tried... Asana, Trello, Smartsheet... I was using a combination of 3 or so at a time to accomplish everything I needed. There was a huge sigh of relief when I found monday.com."'
              ],
              reviewerName: "Dotan Egozi, monday.com"
            },
            {
              reviewContent: [
                '"I\'ve tried... Asana, Trello, Smartsheet... I was using a combination of 3 or so at a time to accomplish everything I needed. There was a huge sigh of relief when I found monday.com."'
              ],
              reviewerName: "Dotan Egozi, monday.com"
            }
          ]
        }
      }
    },
    ShowShortFooter: {
      showShortFooter: false
    }
  }
};
