import { FIELD_TYPES } from "constants/template-field-type";
import SignupButtonFieldsConfig from "segments/desktop/core-components/button/signup-button/fields-config";
import YoutubePopupButtonFieldsConfig from "segments/desktop/core-components/button/youtube-popup-button/fields-config";

export default {
  signupButtonConfig: {
    type: FIELD_TYPES.NESTED,
    label: "Signup Button",
    fields: { ...SignupButtonFieldsConfig }
  },
  youtubePopupButtonConfig: {
    type: FIELD_TYPES.NESTED,
    label: "Youtube Button",
    fields: { ...YoutubePopupButtonFieldsConfig }
  }
}