import { FIELD_TYPES } from "constants/template-field-type";
import AssetFieldsConfig from "components/core/asset/fields-config";
import ThemeConfig from "segments/core-configs/theme-config";
import {
  SIGNUP_BUTTON_TYPE,
  SIGNUP_COMBO_BUTTONS_TYPE,
  SECONDARY_BUTTON_TYPE
} from "segments/desktop/core-components/button/button-consts";
import ButtonFieldsConfig from "segments/desktop/core-components/button/fields-config";
import ProductsConfig from "segments/core-configs/products-config";


const { options } = ButtonFieldsConfig;
const productCardButtonOptions = options.filter((option) =>
  [SIGNUP_BUTTON_TYPE, SIGNUP_COMBO_BUTTONS_TYPE, SECONDARY_BUTTON_TYPE].includes(option.text)
);

export default {
  type: FIELD_TYPES.NESTED,
  label: "Product Cards",
  fields: {
    ...ThemeConfig,
    cards: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Card Items",
      maxLimit: 5,
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          ...ProductsConfig,
          isHighlighted: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Is highlighted"
          },
          showOpen: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Show card open in mobile version"
          },
          header: {
            type: FIELD_TYPES.NESTED,
            label: "Header",
            fields: {
              subtitleText: {
                type: FIELD_TYPES.TEXT,
                label: "Text",
                translatable: true
              }
            }
          },
          body: {
            type: FIELD_TYPES.NESTED,
            label: "Body",
            fields: {
              bodyText: {
                type: FIELD_TYPES.TEXT,
                label: "Text",
                translatable: true
              }
            }
          },
          showDivider: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Show divider"
          },
          useCases: {
            type: FIELD_TYPES.NESTED,
            label: "Use cases",
            fields: {
              text: {
                type: FIELD_TYPES.TEXT,
                label: "Text",
                translatable: true
              },
              list: {
                type: FIELD_TYPES.MULTIPLE_NESTED,
                label: "Use cases list",
                maxLimit: 6,
                nestedConfig: {
                  type: FIELD_TYPES.NESTED,
                  fields: {
                    text: {
                      type: FIELD_TYPES.TEXT,
                      label: "Text",
                      translatable: true
                    }
                  }
                }
              }
            }
          },
          buttonConfig: {
            type: FIELD_TYPES.CONDITIONAL_NESTED,
            label: "Button",
            conditionalKeyName: "buttonType",
            options: productCardButtonOptions
          }
        }
      }
    }
  }
};
