import css from "styled-jsx/css";

export const componentStyles = css.global`
  .table-video-component {
    .table-video-overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .play-button {
      height: 128px;
      width: 128px;
      cursor: pointer;
      &:hover {
        filter: brightness(85%);
      }
    }

    .video-component {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 9999;
    }
  }
`;
