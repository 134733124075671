import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
import FullParagraphAdvancedFieldsConfig from "segments/desktop/core-components/full-paragraph/advanced-fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "",
  fields: {
    ...SharedAdvancedFieldsConfig,
    ...FullParagraphAdvancedFieldsConfig,
    maxWidth: {
      type: FIELD_TYPES.TEXT,
      label: "Max Width"
    },
    removeBulletsMobileMarginTop: { type: FIELD_TYPES.BOOLEAN, label: "Remove Bullets Margin Top On Mobile" }
  }
};
