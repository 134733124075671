import { FIELD_TYPES } from "constants/template-field-type";
import RegularTabsAdvancedConfig from "segments/desktop/tabs/layouts/regular-tabs/regular-tabs-advanced-config";
import TagItemsGridAdvancedConfig from "segments/desktop/grid/layouts/tag-items-grid/tag-items-grid-advanced-config";
import FullParagraphAdvancedFieldsConfig from "segments/desktop/core-components/full-paragraph/advanced-fields-config";
import AssetAdvancedConfig from "components/core/asset/advanced-field-config";
import SideBySideTextAndAssetAdvancedFieldsConfig from "segments/desktop/core-components/side-by-side-text-and-asset/advanced-field-config";

const { width } = TagItemsGridAdvancedConfig.fields;

export default {
  type: FIELD_TYPES.NESTED,
  label: "",
  fields: {
    ...RegularTabsAdvancedConfig.fields,
    tabs: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Tabs",
      maxLimit: 5,
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          textAndAssetTwoThirds: {
            type: FIELD_TYPES.NESTED,
            label: "Text and asset",
            fields: {
              ...FullParagraphAdvancedFieldsConfig,
              ...AssetAdvancedConfig,
              ...SideBySideTextAndAssetAdvancedFieldsConfig
            }
          },
          fullParagraph: {
            type: FIELD_TYPES.NESTED,
            label: "Full Paragraph",
            fields: {
              ...FullParagraphAdvancedFieldsConfig
            }
          },
          tagItems: {
            type: FIELD_TYPES.NESTED,
            label: "Tag Items",
            fields: { width }
          },
          flexibleCards: {
            type: FIELD_TYPES.NESTED,
            label: "Flexible Cards",
            fields: {
              ...FullParagraphAdvancedFieldsConfig
            }
          }
        }
      }
    }
  }
};
