import { defaultData as dynamicTemplateDefaultData } from "components/templates-generator/config/desktop-dynamic-template/default-data";
import { TEMPLATE_NAMES } from "constants/templates-names";
import { getSegmentsConfigs } from "segments/segments-config-service";

const isMobile = false;
const segmentsConfig = getSegmentsConfigs(isMobile);

export const defaultData = {
  ...dynamicTemplateDefaultData,
  template: TEMPLATE_NAMES.WelcomeNewJoinersTemplate,
  segments: [
    {
      type: segmentsConfig.WelcomeNewJoinersHeaderComponent.type,
      props: segmentsConfig.WelcomeNewJoinersHeaderComponent.component.defaultProps
    },
    {
      type: segmentsConfig.WelcomeNewJoinersFooterComponent.type,
      props: segmentsConfig.WelcomeNewJoinersFooterComponent.component.defaultProps
    }
  ]
};
