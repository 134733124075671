import { FIELD_TYPES } from "constants/template-field-type";
import HubspotContactFormFields from "segments/desktop/core-components/hubspot-contact-form/fields-config";
import LogosTitleFieldsConfig from "segments/desktop/logos/components/logos-title/fields-config";
import LogosFieldsConfig from "segments/desktop/core-components/logos/fields-config";
import TitleAdvancedConfig from "segments/desktop/core-components/title/advanced-field-config";
import sideBySideTextAndAssetFieldsConfig from "segments/desktop/core-components/side-by-side-text-and-asset/fields-config.js";
import {
  CUSTOMER_KEY_NISSAN,
  CUSTOMER_KEY_OSCAR,
  CUSTOMER_KEY_TELEFONICA,
  CUSTOMER_KEY_THE_STUDENT_HOTEL,
  CUSTOMER_KEY_ZIPPO
} from "constants/customer-quotes";

const { titleSize } = TitleAdvancedConfig;

const { swapTextAndAssetPosition, mobileSwapTextAndAssetPosition } = sideBySideTextAndAssetFieldsConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "Hubspot With Bullets Side By Side",
  fields: {
    ...HubspotContactFormFields,
    swapTextAndAssetPosition,
    mobileSwapTextAndAssetPosition,
    title: {
      type: FIELD_TYPES.TEXT,
      label: "Title",
      translatable: true
    },
    titleSize,
    benefits: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Benefits",
      maxLimit: 3,
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          icon: { type: FIELD_TYPES.GALLERY, label: "Icon" },
          title: { type: FIELD_TYPES.TEXT, label: "Title", translatable: true },
          description: { type: FIELD_TYPES.TEXT, label: "Description", translatable: true }
        }
      }
    },
    hideSupportStatement :{
      type: FIELD_TYPES.BOOLEAN,
      label: "Hide support statement",
    },
    customerQuoteKey: {
      type: FIELD_TYPES.COMBO,
      label: "Customer Quote",
      options: [
        {
          value: CUSTOMER_KEY_OSCAR,
          text: "Oscar - Work OS saves time and money"
        },
        {
          value: CUSTOMER_KEY_THE_STUDENT_HOTEL,
          text: "The Student Hotel - Work OS provides a trustful overview"
        },
        {
          value: CUSTOMER_KEY_TELEFONICA,
          text: "Telefonica - 30% more efficient in campaign delivering"
        },
        {
          value: CUSTOMER_KEY_ZIPPO,
          text: "Zippo - launch projects and expand to new markets in less time"
        },
        {
          value: CUSTOMER_KEY_NISSAN,
          text: "Nissan - been given confidence to provide management information"
        }
      ]
    },
    logosTitle: {
      type: FIELD_TYPES.NESTED,
      label: "Logos title",
      fields: LogosTitleFieldsConfig
    },
    logos: {
      type: FIELD_TYPES.NESTED,
      label: "Logos",
      fields: LogosFieldsConfig
    }
  }
};
