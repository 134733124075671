import colors from "styles/colors";
import { DARK_WORK_OS_IRIS_COLOR_NAME, SPACE_BLUE_COLOR_NAME } from "styles/color-consts";
import { getUrlWithColor } from "services/community/cloudinary-service";
import classnames from "classnames";
import AssetComponent from "components/core/asset/asset-component";
import { trackEvent } from "services/bigbrain-service";
import { TAB_CLICKED } from "constants/bigbrain-event-types";
import { componentStyles } from "./building-blocks-tab-component.scss";
import { generateOnKeyDownHandler } from "client/services/accessibility/accessibility-service";
import { productsColorOptionsOvertonesMap } from "constants/color-picker-constants";

const DEFAULT_ICON_SIZE = "16px";

const BuildingBlocksTabComponent = (props) => {
  const { buildingBlock, index, selectedTab, setSelectedTab, themeColor } = props;
  const isSelected = index === selectedTab;

  const tabSelectedColor = colors[productsColorOptionsOvertonesMap[themeColor]?.dark || DARK_WORK_OS_IRIS_COLOR_NAME];

  const getTabIconAsset = ({ isSelected }) => {
    return {
      image: {
        src: getUrlWithColor({
          url: buildingBlock.tabIcon,
          color: isSelected ? tabSelectedColor : colors[SPACE_BLUE_COLOR_NAME]
        })
      }
    };
  };

  const selectedTabIconAsset = getTabIconAsset({ isSelected: true });
  const unselectedTabIconAsset = getTabIconAsset({ isSelected: false });

  const selectTab = ({ buildingBlock, index }) => {
    trackEvent(TAB_CLICKED, {
      kind: buildingBlock.tabLabel,
      info1: index
    });
    setSelectedTab(index);
    // start video at 0 time
    if (buildingBlock?.videoPlayerRef?.current) {
      buildingBlock.videoPlayerRef.current.seekTo(0);
    }
  };

  return (
    <div
      key={index}
      className={classnames("tab", { selected: isSelected })}
      onClick={() => selectTab({ buildingBlock, index })}
      onKeyDown={generateOnKeyDownHandler(() => selectTab({ buildingBlock, index }))}
      role="tab"
      aria-selected={isSelected}
      tabIndex={0}
    >
      <div className="icon-wrapper">
        <div className={classnames("icon-absolute-container", { visible: isSelected })}>
          <AssetComponent {...selectedTabIconAsset} width={DEFAULT_ICON_SIZE} height={DEFAULT_ICON_SIZE} />
        </div>
        <div className={classnames("icon-absolute-container", { visible: !isSelected })}>
          <AssetComponent {...unselectedTabIconAsset} width={DEFAULT_ICON_SIZE} height={DEFAULT_ICON_SIZE} />
        </div>
      </div>
      <div
        className="name"
        style={{
          color: isSelected ? tabSelectedColor : colors[SPACE_BLUE_COLOR_NAME]
        }}
      >
        {buildingBlock.tabLabel}
      </div>
      <div
        className="marker"
        style={{
          backgroundColor: isSelected ? tabSelectedColor : "transparent"
        }}
      />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default BuildingBlocksTabComponent;
