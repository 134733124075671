import { FIELD_TYPES } from "constants/template-field-type";
import ImagesGridAdvancedFieldsConfig from "segments/desktop/core-components/images-grid/advanced-fields-config";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";

const { imagePosition, textAlign } = { ...ImagesGridAdvancedFieldsConfig };

export default {
  type: FIELD_TYPES.NESTED,
  label: "",
  fields: {
    imagePosition,
    textAlign,
    ...SharedAdvancedFieldsConfig
  }
};
