import { PureComponent } from "react";
import { withGenericConfig } from "/contexts/generic-config-context";
import { trackEvent } from "/services/bigbrain-service";
import { SEGMENT_LAYOUT_NOT_FOUND } from "/constants/bigbrain-event-types";
import { withPreviewBaseSegment } from "contexts/preview-base-segment-context";
import { themes } from "segments/segments-service";
import Segments from "segments/desktop";
import classnames from "classnames";
import colors from "styles/colors";
import { SPACE_BLUE_COLOR_NAME } from "styles/color-consts";
import { componentStyles, triangleHeightFactor } from "./base-layout-segment-component.scss";

class BaseLayoutSegmentComponent extends PureComponent {
  getSegmentComponent() {
    const { segment } = this.props;
    const segmentComponent = Segments[segment.type]?.component;

    if (!segmentComponent) {
      trackEvent(SEGMENT_LAYOUT_NOT_FOUND, { kind: segment.type });
      return false;
    }

    return segmentComponent;
  }

  getHtmlComponentElementFromDom() {
    const baseLayoutComponentClassName = this.getBaseLayoutComponentClassName();
    const ssrComponent = document.querySelector(`.${baseLayoutComponentClassName}`);
    return ssrComponent ? ssrComponent.innerHTML : null;
  }

  getSegmentStyle(segmentProps) {
    let { paddingTop, paddingBottom, segmentMinHeight } = segmentProps;
    const { theme, mobilePaddingTop, mobilePaddingBottom, baseLayoutStyleProps } = segmentProps;
    const { isMobileOrTabletBrowser } = this.props;
    const themeStyles = themes[theme];

    if (isMobileOrTabletBrowser) {
      paddingTop = mobilePaddingTop || paddingTop;
      paddingBottom = mobilePaddingBottom || paddingBottom;
    }

    return {
      ...themeStyles,
      ...baseLayoutStyleProps,
      paddingTop,
      paddingBottom,
      minHeight: segmentMinHeight
    };
  }

  getBaseLayoutComponentClassName() {
    const { index } = this.props;
    return `base-layout-segment-component-${index}`;
  }

  getTriangleBorderStyle(triangle) {
    const color = colors[triangle?.color] || colors[SPACE_BLUE_COLOR_NAME];
    return `${100 * triangleHeightFactor}vw solid ${color}`;
  }

  render() {
    const { segment, isUserLoggedIn, extraProps, isMobileBrowser, dynamicProps } = this.props;
    if (!segment) return null;

    const { segmentId, hideOnMobile, topTriangle, bottomTriangle, ...restSegmentProps } = segment.props;

    if (hideOnMobile && isMobileBrowser) return null; // this segment needs to be hidden in mobile views

    const SegmentComponent = this.getSegmentComponent();
    if (!SegmentComponent) return null;

    const SegmentComponentWithGenericConfig = withGenericConfig(SegmentComponent);
    const segmentStyle = this.getSegmentStyle(segment.props);

    const topTriangleBorderBottom = this.getTriangleBorderStyle(topTriangle);
    const bottomTriangleBorderTop = this.getTriangleBorderStyle(bottomTriangle);

    return (
      <div
        id={segmentId}
        className={classnames("base-layout-segment-component", this.getBaseLayoutComponentClassName())}
        style={segmentStyle}
      >
        {topTriangle?.isVisible && (
          <div
            className={classnames("triangle", "top", "bottom-left-corner")}
            style={{ borderBottom: topTriangleBorderBottom }}
          />
        )}
        <SegmentComponentWithGenericConfig
          {...extraProps}
          {...restSegmentProps}
          dynamicProps={dynamicProps}
          isUserLoggedIn={isUserLoggedIn}
          segmentType={segment.type}
        />
        {bottomTriangle?.isVisible && (
          <div
            className={classnames("triangle", "bottom", "top-right-corner")}
            style={{ borderTop: bottomTriangleBorderTop }}
          />
        )}
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

BaseLayoutSegmentComponent.defaultProps = {
  extraProps: {}
};

const BaseLayoutSegmentComponentContainer = (props) => {
  const Component = props.isPreview
    ? withPreviewBaseSegment(BaseLayoutSegmentComponent)
    : withGenericConfig(BaseLayoutSegmentComponent);

  return <Component {...props} />;
};

export default BaseLayoutSegmentComponentContainer;
