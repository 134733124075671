import { directoryListData, socialButtons } from "../components";
import { defaultData } from "./default-data";

export const DIRECTORY_IMAGES_TEMPLATE_CONFIG = {
  socialButtons,
  header: {
    type: "nested",
    label: "Top section",
    fields: {
      hotDogColor: { type: "colorPicker", label: "Hotdog color" },
      textColor: { type: "colorPicker", label: "Text Color", possibleColors: ["black", "white"] },
      title: { type: "text", label: "Title" },
      subtitle: { type: "text", label: "Subtitle" },
      searchConfig: {
        type: "nested",
        fields: {
          show: { type: "boolean", label: "Show Search" },
          placeholder: { type: "text", label: "Search Placeholder" }
        }
      },
      img: {
        type: "gallery",
        defaultImagesKeys: ["static/img/templates/directory-images/header-decorators/"],
        label: "Select an image"
      }
    }
  },
  listData: directoryListData,
  defaultData
};
