import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
import TitleOnlyParagraphFieldsAdvnacedConfig from "segments/desktop/core-components/title-only-paragraph/fields-advanced-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "",
  fields: {
    ...SharedAdvancedFieldsConfig,
    ...TitleOnlyParagraphFieldsAdvnacedConfig
  }
};
