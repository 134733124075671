import { FIELD_TYPES } from "constants/template-field-type";
import ThemeConfig from "segments/core-configs/theme-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Monday Integrations",
  fields: {
    ...ThemeConfig,
    componentTitle: { type: FIELD_TYPES.TEXT, label: "Title", translatable: true },
    beforeAnimationSubTitle: { type: FIELD_TYPES.TEXT, label: "Subtitle- before animation", translatable: true },
    afterAnimationSubTitle: { type: FIELD_TYPES.TEXT, label: "Subtitle- after animation", translatable: true },
    switchRightPhrase: { type: FIELD_TYPES.TEXT, label: "Right phrase of switch", translatable: true },
    switchLeftPhrase: { type: FIELD_TYPES.TEXT, label: "Left phrase of switch", translatable: true }
  }
};
