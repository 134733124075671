import { LG } from "constants/sizes";
import { BRAND_BLACK_TEXT_COLOR } from "styles/color-consts";

export default {
  titleConfig: {
    titleSize: LG,
    title: "<l>About monday.com<l/>",
    textAlign: "center",
    titleColor: BRAND_BLACK_TEXT_COLOR
  }
};
