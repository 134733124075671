import { FIELD_TYPES } from "constants/template-field-type";

export default {
  height: {
    type: FIELD_TYPES.TEXT,
    label: "Height"
  },
  borderWidth: {
    type: FIELD_TYPES.TEXT,
    label: "Border Width"
  },
  borderColor: {
    type: FIELD_TYPES.COLOR_PICKER,
    label: "Border Color"
  },
  hideScrolling: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Hide scrollbars"
  }
};
