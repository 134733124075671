import { PureComponent } from "react";
import { componentStyles } from "./floating-integrations-component.scss";
import IntegrationsSlider from "./components/integrations-slider/integrations-slider-component";
import { duplicateBadges } from "./integrationBadges";

export default class FloatingIntegrationsComponent extends PureComponent {
  render() {
    return (
      <div className="floating-integrations-component">
        <IntegrationsSlider integrationBadges={duplicateBadges()} />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
