import { FIELD_TYPES } from "constants/template-field-type";
import ButtonTagsFieldsConfig from "segments/desktop/core-components/button-tag/fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Button Tags",
  fields: {
    isOpenLinkInNewTab: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Open Link In New Tab"
    },
    gridButtonTags: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Grid Button Tags",
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          ...ButtonTagsFieldsConfig
        }
      }
    }
  }
};
