import { TEMPLATE_NAMES } from "constants/templates-names";
import BaseHeaderDefaultProps from "segments/desktop/header/layouts/base-header/default-props";
import BaseFooterDefaultProps from "segments/desktop/footer/layouts/base-footer/default-props";

export const defaultData = {
  template: TEMPLATE_NAMES.DynamicTemplate,
  theme: "dark-theme",
  mobileTheme: "dark-theme",
  imagesLazyLoad: false,
  segments: [
    { type: "BaseHeaderComponent", props: BaseHeaderDefaultProps },
    { type: "BaseFooterComponent", props: BaseFooterDefaultProps },
  ]
};
