import { componentStyles } from "./press-grid-component.scss";
import FlexibleCardGridComponent from "segments/desktop/grid/layouts/flexible-cards-grid/flexible-cards-grid-component";
import TitleOnlyParagraph from "segments/desktop/core-components/title-only-paragraph/title-only-paragraph";
import { ISRAEL_LOCATION, israelGridItems, globalGridItems, cardsProps } from "./press-consts";
import DefaultProps from "./default-props";

const PressGridComponent = (props) => {
  const { location, gridAlignment, removeShadowOnHover, shadowCards, innerPadding, titleConfig } = props;

  const currentData = location === ISRAEL_LOCATION ? israelGridItems : globalGridItems;

  return (
    <div className="press-grid-component">
      <div className="title-wrapper">
        <TitleOnlyParagraph {...titleConfig} />
      </div>
      <div className="cards-wrapper">
        <FlexibleCardGridComponent
          gridItems={currentData}
          {...cardsProps}
          gridAlignment={gridAlignment}
          removeShadowOnHover={removeShadowOnHover}
          shadowCards={shadowCards}
          innerPadding={innerPadding}
        />
      </div>

      <style jsx>{componentStyles}</style>
    </div>
  );
};

PressGridComponent.defaultProps = DefaultProps;

export default PressGridComponent;
