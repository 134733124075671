import { PureComponent } from "react";
import { withGenericConfig } from "/contexts/generic-config-context";
import {
  getAllInputFieldsNames,
  getInputDataByName,
  prePopulateInputsIfNeeded,
  inputValidate
} from "client/services/form-service/form-service";
import Button from "components/core/button/button-component";
import PictureComponent from "components/core/picture/picture-component";
import GenericFormInput from "client/components/system/generic-form-input/generic-form-input";
import { SUPPORT_PAGE_LINK, PRIVACY_POLICY_LINK } from "constants/links";
import { ConditionalWrapper } from "utils/react";
import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import Title from "segments/desktop/core-components/title/title";
import { XS } from "constants/sizes";
import RegularButton from "segments/desktop/core-components/button/regular-button/regular-button";
import DefaultProps from "./default-props";
import { componentStyles } from "./base-form.scss";

const DONE_ASSET_SRC = "/static/img/done_asset.png";
const MAX_BACKGROUND_LAYERS = 3;
const BACKGROUND_LAYER_MARGIN = 12;

class BaseForm extends PureComponent {
  constructor(props) {
    super(props);

    const { formConfig } = props;

    this._allInputsNames = getAllInputFieldsNames(formConfig);

    this.state = {
      inputs: {},
      errorState: {},
      isLoading: false,
      hasFormSubmitted: false,
      formError: false,
      formSubmitErrorMessage: null
    };
  }

  componentDidMount() {
    const { inputs } = this.state;
    const prePopulatedInputs = prePopulateInputsIfNeeded();
    if (Object.keys(prePopulatedInputs).length > 0) {
      this.setState({ inputs: { ...inputs, ...prePopulatedInputs } });
    }
  }

  onInputChange = ({ target }) => {
    const { name, value } = target;

    this.setState((state) => ({
      ...state,
      inputs: { ...state.inputs, [name]: value },
      errorState: { ...state.errorState, [name]: { isValid: true } }
    }));
  };

  onInputBlur = ({ target }) => {
    const { onInputBlur } = this.props;
    const { name, value } = target;

    if (onInputBlur) {
      onInputBlur(name, value);
    }

    this.validateInputOnBlur(name);
  };

  focusOnFirstInvalidElement = () => {
    const ariaInvalidInputs = document.querySelectorAll('[aria-invalid="true"]');
    ariaInvalidInputs && ariaInvalidInputs[0]?.focus();
  };

  validateInputOnBlur = (inputName) => {
    const { formConfig } = this.props;
    const { inputs, errorState } = this.state;
    const inputValue = inputs[inputName];

    const inputData = getInputDataByName(formConfig, inputName);
    const { isRequired, validate } = inputData;

    if (!isRequired && !validate) return;

    const { isValid, errorMessage } = inputValidate(inputData, inputValue);

    this.setState({ errorState: { ...errorState, [inputName]: { isValid, errorMessage } } });
  };

  validateInputs = () => {
    const { formConfig } = this.props;
    const { inputs } = this.state;

    const newErrorState = {};

    for (const inputName of this._allInputsNames) {
      const inputValue = inputs[inputName];
      const inputData = getInputDataByName(formConfig, inputName);

      const { isValid, errorMessage } = inputValidate(inputData, inputValue, inputs);

      if (!isValid) newErrorState[inputName] = { isValid, errorMessage };
    }

    this.setState({ errorState: newErrorState }, this.focusOnFirstInvalidElement);
    return Object.keys(newErrorState).length > 0;
  };

  renderInputList = () => {
    const { formConfig } = this.props;
    const { rows } = formConfig;
    const { inputs, errorState } = this.state;

    return rows.map((row, rowIndex) => {
      return (
        <div className="contact-form-row" key={`input_row_${rowIndex}`}>
          {row.map((inputData) => {
            const { inputName, style } = inputData;
            const value = inputs[inputName];
            const hasError = errorState[inputName];

            return (
              <div className="form-input-wrapper" style={{ ...style }} key={`input_${inputName}`}>
                <GenericFormInput
                  inputData={inputData}
                  value={value}
                  errorState={hasError}
                  onInputChange={this.onInputChange}
                  onInputBlur={this.onInputBlur}
                />
              </div>
            );
          })}
        </div>
      );
    });
  };

  getHelpCenterLinkHTML = () => {
    const { translate } = this.props;
    return `<a href="${SUPPORT_PAGE_LINK}" target="_blank">
      ${translate("forms.thanksPage.helpCenter")}</a>`;
  };

  getSubmitScreenData = () => {
    const { translate, translateWithParam, submitScreenData } = this.props;
    const footerLinkHtml = submitScreenData?.footerLinkHtml || this.getHelpCenterLinkHTML();

    return {
      postSubmitAssetSrc: submitScreenData?.postSubmitAssetSrc || DONE_ASSET_SRC,
      title: translate(submitScreenData?.titleKey) || translate("forms.thanksPage.title"),
      subTitleHtml: translate(submitScreenData?.subTitleHtmlKey) || translate("forms.thanksPage.subTitle"),
      footerHtml:
        translate(submitScreenData?.footerHtml) || translateWithParam("forms.thanksPage.footer", footerLinkHtml),
      downloadButtonLink: submitScreenData?.downloadButtonLink || false
    };
  };

  renderPostFormSubmit = () => {
    const { translate } = this.props;
    const submitScreenData = this.getSubmitScreenData();
    const DownloadButtonText = translate("forms.thanksPage.gartnerCampaign.buttonText");

    return (
      <div className="contact-form-thanks">
        <PictureComponent src={submitScreenData.postSubmitAssetSrc} className="submit-image" isDecorative={true} />
        <h2 className="contact-form-thanks-title">{submitScreenData?.title}</h2>
        <div className="contact-form-thanks-content">
          {submitScreenData.subTitleHtml && (
            <span dangerouslySetInnerHTML={{ __html: submitScreenData.subTitleHtml }} />
          )}
          {submitScreenData.footerHtml && (
            <span
              className="contact-form-thanks-footer"
              dangerouslySetInnerHTML={{ __html: submitScreenData.footerHtml }}
            />
          )}
          {submitScreenData?.downloadButtonLink && (
            <div className="download-button-wrapper">
              <RegularButton
                color={WORK_OS_IRIS_COLOR_NAME}
                url={submitScreenData?.downloadButtonLink}
                isOpenLinkInNewTab={true}
                buttonText={DownloadButtonText}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  renderPrivacyNoticeIfNeeded = () => {
    const { renderPrivacyNotice, translateWithParam, translate } = this.props;
    if (!renderPrivacyNotice) return null;

    const privacyPolicyLink = `<a href="${PRIVACY_POLICY_LINK}" target="_blank">
      ${translate("forms.privacyPolicy")}</a>`;

    const privacyHtml = translateWithParam("forms.privacyNotice", privacyPolicyLink);

    return (
      <div className="privacy-notice-wrapper">
        <span className="privacy-notice" dangerouslySetInnerHTML={{ __html: privacyHtml }} />
      </div>
    );
  };

  renderErrorIfNeeded = () => {
    const { formError, formSubmitErrorMessage } = this.state;
    if (!formError) return null;
    const { translate } = this.props;

    const errorMessage = formSubmitErrorMessage || "form.errors.formSubmitError";

    return (
      <div className="error-wrapper">
        <span className="form-error" role="alert">
          {translate(errorMessage)}
        </span>
      </div>
    );
  };

  renderForm = () => {
    const { translate, formConfig } = this.props;
    const { isLoading } = this.state;
    const { submitButtonText, formTitle } = formConfig;
    const buttonText = translate(submitButtonText || "forms.defaultSubmitButton");

    return (
      <>
        {formTitle && (
          <div className="form-title-wrapper">
            <Title title={formTitle} titleSize={XS} />
          </div>
        )}
        {this.renderInputList()}
        {this.renderErrorIfNeeded()}
        {this.renderPrivacyNoticeIfNeeded()}
        <Button color={WORK_OS_IRIS_COLOR_NAME} isLoading={isLoading} onClickCallback={this.onButtonClick}>
          {buttonText}
        </Button>
      </>
    );
  };

  getFormData = () => {
    const { inputs } = this.state;
    const formData = [];

    for (const input in inputs) {
      formData.push({
        name: input,
        value: inputs[input]
      });
    }

    return formData;
  };

  onButtonClick = async () => {
    const { submitFormAsyncCallback } = this.props;

    const hasErrors = this.validateInputs();
    if (hasErrors) {
      return;
    }
    const formData = this.getFormData();

    this.setState({ isLoading: true, formError: false, formSubmitErrorMessage: null });

    const formResponse = await submitFormAsyncCallback(formData);
    this.setState({ isLoading: false });

    if (!formResponse?.success) {
      this.setState({ formError: true, formSubmitErrorMessage: formResponse?.errorMessage });
      return;
    }

    this.setState({ hasFormSubmitted: true });
  };

  renderBackgroundLayers = () => {
    let { backgroundLayers } = this.props;
    backgroundLayers = Math.min(backgroundLayers, MAX_BACKGROUND_LAYERS);

    const layers = Array(backgroundLayers)
      .fill()
      .map((element, index) => index + 1);

    return layers.map((layer) => (
      <div
        key={`bg_layer_${layer}`}
        className="form-background-layer"
        style={{
          zIndex: -1 * layer,
          marginTop: BACKGROUND_LAYER_MARGIN * layer,
          marginLeft: -BACKGROUND_LAYER_MARGIN * (layer - 1),
          opacity: 1 - 0.25 * layer
        }}
      />
    ));
  };

  render() {
    const { backgroundLayers } = this.props;
    const { hasFormSubmitted } = this.state;

    return (
      <ConditionalWrapper
        condition={backgroundLayers}
        wrapper={(children) => (
          <div className="layered-base-form">
            {this.renderBackgroundLayers()}
            {children}
          </div>
        )}
      >
        <div className="base-form">
          {hasFormSubmitted && this.renderPostFormSubmit()}
          {!hasFormSubmitted && this.renderForm()}
        </div>
        <style jsx>{componentStyles}</style>
      </ConditionalWrapper>
    );
  }
}

BaseForm.defaultProps = DefaultProps;

export default withGenericConfig(BaseForm);
