import css from "styled-jsx/css";
import { SLIDER_MARGIN } from '../../constants';

const SLIDER_MARGIN_PX = `${SLIDER_MARGIN}px`;

export const componentStyles = css`
  .integrations-slider-component {
      position: relative;
      padding: ${SLIDER_MARGIN_PX} 0;

      :global(.integration-badge-wrapper) {
        position: absolute;
      }
  }
`;
