import css from "styled-jsx/css";
import Colors from "/styles/colors";
import { TOP_LAYER } from "/constants/z-index";
import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";

export const componentStyles = css.global`
  .board-tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    cursor: pointer;
    overflow: hidden;

    .selection-indicator {
      position: absolute;
      background: currentColor;
      width: 48px;
      height: 26px;
      align-self: flex-end;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      .curved-corner {
        content: "";
        position: absolute;
        background-color: transparent;
        height: 24px;
        width: 18px;
        right: -1px;
        border-radius: 50px;

        &.bottom {
          bottom: -24px;
          box-shadow: currentColor 8px -8px 0px 0px;
        }

        &.top {
          bottom: 26px;
          box-shadow: currentColor 8px 8px 0px 0px;
        }
      }
    }
    .tab-image-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 5px;
      z-index: ${TOP_LAYER};

      .tab-image {
        height: 16px;
      }
    }
    .tab-text {
      margin-top: 8px;
      font-size: 0.563rem;
      text-align: center;
      color: ${Colors.white};
    }

    &.main-tab {
      background: ${Colors[WORK_OS_IRIS_COLOR_NAME]};
      border-radius: 8px 0px 0px 8px;
      height: 67px;
      margin-top: 0;
      padding-top: 8px;

      .tab-image-wrapper {
        background: ${Colors["developers-dark-blue"]};
        width: 33px;
        height: 33px;
        border-radius: 8px;
        padding-top: 0;

        .tab-image {
          height: 22px;
        }
      }
      .tab-text {
        font-size: 0.619rem;
      }
    }

    &.small-tabs {
      margin-top: 18px;
      &.main-tab {
        margin-top: 0px;
      }
    }
  }
`;
