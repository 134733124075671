import dynamic from "next/dynamic";

const GlobeHomepageFirstFoldComponent = require("./globe-homepage-first-fold-component").default;

import GlobeHomepageFirstFoldConfig from "./globe-homepage-first-fold-config";
import GlobeHomepageFirstFoldAdvancedConfig from "./globe-homepage-first-fold-advanced-config";

export default {
  GlobeHomepageFirstFoldComponent,
  GlobeHomepageFirstFoldConfig,
  GlobeHomepageFirstFoldAdvancedConfig
};
