import { SIGNUP_BUTTON_TYPE } from "segments/desktop/core-components/button/button-consts";

export default {
  width: "100%",
  gridSelectableTags: [
    {
      tagText: "Tag name",
      tagBackgroundColor: "indigo"
    },
    {
      tagText: "name",
      tagBackgroundColor: "winter"
    },
    {
      tagText: "Tag name Long",
      tagBackgroundColor: "peach"
    },
    {
      tagText: "Tag name very long",
      tagBackgroundColor: "winter"
    },
    {
      tagText: "Tag name 111",
      tagBackgroundColor: "brown"
    },
    {
      tagText: "tag-tag-name",
      tagBackgroundColor: "chill-blue"
    },
    {
      tagText: "Tag name",
      tagBackgroundColor: "bubble"
    },
    {
      tagText: "Tag name Long",
      tagBackgroundColor: "aquamarine"
    },
    {
      tagText: "Tag",
      tagBackgroundColor: "lipstick"
    }
  ],
  buttonConfig: { buttonType: SIGNUP_BUTTON_TYPE, buttonColor: "brand-pink" },
  withSecondaryButton: false,
  secondaryButtonConfig: null
};
