import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";

export const componentStyles = css`
  .simpo-customer-success-component {
    max-width: 720px;
    margin: auto;

    .simpo-title-wrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 16px;
    }
  }
`;
