import FullParagraphComponent from "segments/desktop/paragraph/layouts/full-paragraph/full-paragraph-component";
import { componentStyles } from "./video-background.scss";
import DefaultProps from "./default-props";
import AssetComponent from "/components/core/asset/asset-component";

const VideoBackground = (props) => {
  const { fullParagraphConfig, assetConfig, addDarkOverlay, hideBlackBars } = props;
  return (
    <div className="video-background">
      <div className="video-wrapper">
        <AssetComponent {...assetConfig} hideControls={true} hideBlackBars={hideBlackBars} />
        {addDarkOverlay && <div className="dark-overlay" />}
      </div>
      <div className="full-paragraph-wrapper">
        <FullParagraphComponent {...fullParagraphConfig} />
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default VideoBackground;

VideoBackground.defaultProps = DefaultProps;
