import dynamic from "next/dynamic";

const SideBySideWithBulletsTextAndAssetComponent = require("./side-by-side-with-bullets-text-and-asset-component").default;

import SideBySideWithBulletsTextAndAssetConfig from "./side-by-side-with-bullets-text-and-asset-config";
import SideBySideWithBulletsTextAndAssetAdvancedConfig from "./side-by-side-with-bullets-text-and-asset-advanced-config";

export default {
  SideBySideWithBulletsTextAndAssetComponent,
  SideBySideWithBulletsTextAndAssetConfig,
  SideBySideWithBulletsTextAndAssetAdvancedConfig
};
