import { PureComponent } from "react";
import { componentStyles } from "./image-with-paragraph-grid-component.scss";
import ImagesGrid from "segments/desktop/core-components/images-grid/images-grid";
import FullParagraphDefaultProps from "segments/desktop/core-components/full-paragraph/default-props";
import { positionTypes } from "segments/desktop/core-components/images-grid/images-grid-service";
import { MD } from "constants/sizes";

export default class ImageWithParagraphGridComponent extends PureComponent {
  render() {
    const { images, imagePosition, textAlign, titleSize, textSize } = this.props;

    return (
      <div className="image-with-paragraph-grid-component">
        <ImagesGrid
          images={images}
          isWithParagraph={true}
          imagePosition={imagePosition}
          textAlign={textAlign}
          titleSize={titleSize}
          textSize={textSize}
        />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

ImageWithParagraphGridComponent.defaultProps = {
  images: [
    {
      src: "/static/img/segment-layout-default-images/grid/man-with-blue-shirt.png",
      fullParagraph: FullParagraphDefaultProps
    },
    {
      src: "/static/img/segment-layout-default-images/grid/woman-with-sunglasses-and-orange-shirt.png",
      fullParagraph: FullParagraphDefaultProps
    },
    {
      src: "/static/img/segment-layout-default-images/grid/man-with-hat-and-sunglasses.png",
      fullParagraph: FullParagraphDefaultProps
    },
    {
      src: "/static/img/segment-layout-default-images/grid/woman-with-sunglasses-in-the-wind.png",
      fullParagraph: FullParagraphDefaultProps
    }
  ],
  paddingTop: "56px",
  paddingBottom: "56px",
  imagePosition: positionTypes.CENTER_CENTER,
  textAlign: "left",
  titleSize: MD,
  textSize: MD
};
