import classNames from "classnames";
import { randomNumber } from "utils/math";
import { componentStyles } from "./universe-star-component.scss";

const UniverseStarComponent = (props) => {
  const { isCircle, leftRange, rightRange, left, top, right } = props;

  const getStarSize = () => {
    return `${randomNumber(leftRange, rightRange)}px`;
  };

  const getTwinkleDuration = () => {
    return `${Math.ceil(randomNumber(1, 3))}s`;
  };

  const getTwinkleDelay = () => {
    return `${Math.ceil(randomNumber(1, 8))}s`;
  };

  const getStyle = () => {
    return {
      left,
      top,
      right,
      "--star-size": getStarSize(),
      "--twinkle-duration": getTwinkleDuration(),
      "--twinkle-delay": getTwinkleDelay()
    };
  };

  return (
    <div className={classNames("universe-star", { circle: isCircle })} style={getStyle()}>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default UniverseStarComponent;
