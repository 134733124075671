import { FIELD_TYPES } from "constants/template-field-type";
import LogosFieldsConfig from "segments/desktop/core-components/logos/fields-config";
import fullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config.js";
import { SUPPORTED_LOGOS_NUMBER_FOR_NARROW_DISPLAY_OPTIONS } from "segments/desktop/core-components/logos/logos-const";

LogosFieldsConfig.numberOfCompanies.options = SUPPORTED_LOGOS_NUMBER_FOR_NARROW_DISPLAY_OPTIONS; // override options

export default {
  fullParagraph: {
    type: FIELD_TYPES.NESTED,
    label: "Paragraph",
    fields: fullParagraphFieldsConfig
  },
  logos: {
    type: FIELD_TYPES.NESTED,
    label: "Logos",
    fields: LogosFieldsConfig
  },
  marginBetweenParagraphAndLogos: {
    // Not in advanced since the import of advanced doesn't work well with nested
    type: FIELD_TYPES.TEXT,
    label: "Margin between paragraph and logos"
  }
};
