import Layouts from "./layouts";
const {
  // plop_marker:segment_index_import_layout
  LogosAndParagraphLogos,
  SlimLogos,
  AllLogosLogos,
  TopLogos,
  BottomLogos,
  ImagesLogos
} = Layouts;

export default {
  // plop_marker:segment_index_add_layout
  LogosAndParagraphLogosComponent: {
    type: "LogosAndParagraphLogosComponent",
    title: "Logos And Paragraph",
    imgSrc: "/static/img/segments/layouts/logos-and-paragraph-logos.png",
    config: LogosAndParagraphLogos.LogosAndParagraphLogosConfig,
    advancedConfig: LogosAndParagraphLogos.LogosAndParagraphLogosAdvancedConfig,
    component: LogosAndParagraphLogos.LogosAndParagraphLogosComponent
  },
  SlimLogosComponent: {
    type: "SlimLogosComponent",
    title: "Slim",
    imgSrc: "/static/img/segments/layouts/slim-logos.png",
    config: SlimLogos.SlimLogosConfig,
    advancedConfig: SlimLogos.SlimLogosAdvancedConfig,
    component: SlimLogos.SlimLogosComponent
  },
  AllLogosLogosComponent: {
    type: "AllLogosLogosComponent",
    title: "All Logos",
    imgSrc: "/static/img/segments/layouts/all-logos-logos.png",
    config: AllLogosLogos.AllLogosLogosConfig,
    advancedConfig: AllLogosLogos.AllLogosLogosAdvancedConfig,
    component: AllLogosLogos.AllLogosLogosComponent
  },
  TopLogosComponent: {
    type: "TopLogosComponent",
    title: "Top",
    imgSrc: "/static/img/segments/layouts/top-logos.png",
    config: TopLogos.TopLogosConfig,
    advancedConfig: TopLogos.TopLogosAdvancedConfig,
    component: TopLogos.TopLogosComponent
  },
  BottomLogosComponent: {
    type: "BottomLogosComponent",
    title: "Bottom",
    imgSrc: "/static/img/segments/layouts/bottom-logos.png",
    config: BottomLogos.BottomLogosConfig,
    advancedConfig: BottomLogos.BottomLogosAdvancedConfig,
    component: BottomLogos.BottomLogosComponent
  },
  ImagesLogosComponent: {
    type: "ImagesLogosComponent",
    title: "Images",
    imgSrc: "/static/img/segments/layouts/images-logos.png",
    config: ImagesLogos.ImagesLogosConfig,
    advancedConfig: ImagesLogos.ImagesLogosAdvancedConfig,
    component: ImagesLogos.ImagesLogosComponent
  }
};
