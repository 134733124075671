import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import Colors from "/styles/colors";
import { BRAND_GREEN_DARKER_TINT_02 } from "styles/color-consts";
import { ALWAYS_ON_TOP, LAYER_ONE, LAYER_TWO } from "constants/z-index";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH } from "/constants/vp-sizes";

const BOARD_HEIGHT = 29;
const PADDING_BOTTOM = 8.5;
const BOARD_WIDTH = 43;
const BOARD_TOP_LOCATION = 3.3;

export const componentStyles = css.global`
  .live-board-homepage-first-fold-component {
    margin: auto;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    justify-content: center;
    position: relative;
    padding-bottom: ${PADDING_BOTTOM + "vw"};
    overflow: hidden;
    &.live-board-closed {
      padding-bottom: 0;
      .live-board-wrapper {
        align-self: end;
        top: 2px;
      }
    }
    .boards-picture {
      transition: opacity 1.5s ease-in-out;
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      align-self: end;
      .picture-component {
        display: flex;
        img {
          width: 100vw;
        }
      }
    }
    .live-board-wrapper {
      will-change: transform;
      z-index: ${LAYER_ONE};
      height: ${BOARD_HEIGHT + "vw"};
      width: ${BOARD_WIDTH + "vw"};
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      overflow: hidden;
      transform-origin: top;
      cursor: pointer;
      transition: transform 1.5s ease-in-out;
      justify-self: center;
      position: relative;
      top: ${BOARD_TOP_LOCATION + "vw"};

      .animation-circle {
        height: 30vw;
        width: 30vw;
        border-radius: 50%;
        background-color: ${Colors[BRAND_GREEN_DARKER_TINT_02]};
        position: absolute;
        left: calc(100% - 22vw);
        top: calc(100% - 18vw);
        &.play-animation {
          animation: scale-up-and-down 2s infinite;
        }

        z-index: ${ALWAYS_ON_TOP - 1};
        opacity: 1;
        transition: opacity 0.5s;
        &.hide-button {
          opacity: 0;
        }
      }

      .open-button-wrapper {
        height: 30vw;
        width: 30vw;
        border-radius: 50%;
        z-index: ${ALWAYS_ON_TOP};
        background-color: ${Colors[BRAND_GREEN_DARKER_TINT_02]};
        position: absolute;
        left: calc(100% - 22vw);
        top: calc(100% - 18vw);
        opacity: 1;
        transition: opacity 2s;
        &.hide-button {
          opacity: 0;
        }

        .open-button-content-wrapper {
          padding-left: 6vw;
          padding-top: 6vw;
          .open-button-title-wrapper {
            text-align: left;
            padding-bottom: 8px;
          }
          .open-button-paragraph-wrapper {
            text-align: left;
            padding-bottom: 14px;
            .paragraph-body {
              line-height: 20px;
              font-size: 14px;
            }
          }
          .open-button-button-wrapper {
            display: flex;
            justify-content: left;
            .button-component {
              padding: 8px 24px;
            }
          }
          @media (max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH}) {
            padding-left: 6vw;
            padding-top: 5vw;
            .open-button-title-wrapper {
              padding-bottom: 4px;
              .core-title {
                font-size: 16px;
                line-height: 24px;
              }
            }
            .open-button-paragraph-wrapper {
              padding-bottom: 10px;
              .paragraph-body {
                line-height: 20px;
                font-size: 12px;
              }
            }
            .open-button-button-wrapper {
              .button-component {
                padding: 8px 18px;
                font-size: 12px;
              }
            }
          }
        }
      }
      .live-board-image-wrapper {
        z-index: ${LAYER_TWO};
        .picture-component {
          display: flex;
          img {
            width: 100%;
          }
        }
      }
    }
    &.full-screen-board {
      .boards-picture {
        opacity: 0;
      }
      .live-board-wrapper {
        animation: scale-up 1s forwards;
      }
    }
    &.animation-played {
      .boards-picture {
        opacity: 0.3;
      }
      .live-board-wrapper {
        transform: scale(1.26);
      }
    }
  }

  @keyframes scale-up-and-down {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes scale-up {
    from {
      transform: scale(1.26);
      opacity: 1;
    }
    to {
      transform: scale(1.5);
      opacity: 0;
    }
  }
`;
