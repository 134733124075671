import dynamic from "next/dynamic";

const YoutubeTwoThirdsTextAndAssetComponent = require("./youtube-two-thirds-text-and-asset-component").default;

import YoutubeTwoThirdsTextAndAssetConfig from "./youtube-two-thirds-text-and-asset-config";
import YoutubeTwoThirdsTextAndAssetAdvancedConfig from "./youtube-two-thirds-text-and-asset-advanced-config";

export default {
  YoutubeTwoThirdsTextAndAssetComponent,
  YoutubeTwoThirdsTextAndAssetConfig,
  YoutubeTwoThirdsTextAndAssetAdvancedConfig
};
