import classnames from "classnames";
import { generateOnKeyDownHandler } from "client/services/accessibility/accessibility-service";
import Button from "segments/desktop/core-components/button/button";
import { SIGNUP_BUTTON_TYPE } from "segments/desktop/core-components/button/button-consts";
import { XS } from "components/core/new-button/button-component";
import { isDarkTheme } from "segments/segments-service";
import { componentStyles } from "./monday-demo-timeline.scss";

const MondayDemoTimeline = (props) => {
  const {
    tabs,
    selectedTab,
    buttonConfig,
    buttonHighlighted,
    wasComponentEverVisible,
    onTabClick,
    videoDuration,
    theme
  } = props;

  const getButtonProps = () => {
    switch (buttonConfig.buttonType) {
      case SIGNUP_BUTTON_TYPE:
        return {
          ...buttonConfig,
          buttonSize: XS,
          invert: !buttonHighlighted
        };
      default:
        return buttonConfig;
    }
  };

  return (
    <div
      className={classnames("monday-demo-tabs-timeline-and-labels-wrapper", { "dark-theme": isDarkTheme(theme) })}
      role="tablist"
    >
      {tabs.map((tab, index) => {
        const isSelected = index === selectedTab;
        return (
          <div
            className={classnames("timeline-tab-indication-label-and-progress-bar-wrapper", {
              selected: isSelected && wasComponentEverVisible && !buttonHighlighted,
              watched: index < selectedTab || buttonHighlighted
            })}
            onClick={(e) => onTabClick({ index })}
          >
            <div
              className="timeline-tab-indication-and-label-wrapper"
              onKeyDown={generateOnKeyDownHandler((e) => onTabClick({ index }))}
              role="tab"
              aria-selected={isSelected}
              tabIndex={0}
            >
              <div className="timeline-tab-indication">
                <div className="timeline-tab-indication-outer-circle">
                  <div className="timeline-tab-indication-inner-circle" />
                </div>
              </div>
              <div className="timeline-tab-label">{tab.tabLabel}</div>
            </div>
            {index < tabs.length - 1 && (
              <div className="timeline-progress-bar-wrapper">
                <div className="timeline-progress-bar">
                  <div className="timeline-progress-bar-empty" />
                  <div
                    className="timeline-progress-bar-filled"
                    style={{ animationDuration: index <= selectedTab ? `${videoDuration}s` : `unset` }}
                  />
                </div>
              </div>
            )}
          </div>
        );
      })}
      {buttonConfig && (
        <div className={classnames("button-wrapper")}>
          <Button {...getButtonProps()} />
        </div>
      )}
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default MondayDemoTimeline;
