import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";

export const componentStyles = css`
  .logos-and-paragraph-logos-component {
    max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
    margin: auto;
    :global(.images-logos-component) {
      :global(.logos) {
        :global(.images-logos-wrapper) {
          :global(.images-gallery-row) {
            :global(.image-gallery) {
              padding: 8px 0;
              width: 128px;
              :global(.social-proof-gallery-picture) {
                :global(img) {
                  max-height: 48px;
                  max-width: 84px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    .logos-and-paragraph-logos-component {
      max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
      margin: auto;
      :global(.images-logos-component) {
        :global(.logos) {
          :global(.images-logos-wrapper) {
            :global(.images-gallery-row) {
              :global(.image-gallery) {
                padding: 8px 0;
                width: 84px;
                :global(.social-proof-gallery-picture) {
                  :global(img) {
                    max-height: 36px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
