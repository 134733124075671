import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";

export const componentStyles = css`
  .tag-items-grid-component {
    max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .grid-tag-wrapper {
      margin: 16px 32px 16px 0;
    }
  }
`;
