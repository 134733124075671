import { FIELD_TYPES } from "constants/template-field-type";
import { tagsSupportedColors } from "/constants/color-picker-constants";
import assetFieldsConfig from "components/core/asset/fields-config";

export default {
  tagText: { type: FIELD_TYPES.TEXT, label: "Text", translatable: true },
  tagBackgroundColor: {
    type: FIELD_TYPES.COLOR_PICKER,
    label: "Color",
    possibleColors: tagsSupportedColors
  },
  tagValue: {
    type: FIELD_TYPES.TEXT,
    label: "Key (DEPRECATED)",
    labelDescription: "Will be removed (copy to use case key)"
  },
  overridePageCluster: {
    type: FIELD_TYPES.CLUSTERS,
    label: "Cluster key",
    labelDescription: "Key which represents the selected tag cluster"
  },
  overridePageSubCluster: {
    type: FIELD_TYPES.SUB_CLUSTERS,
    label: "Use Case key",
    labelDescription: "Key which represents the selected tag use case"
  },
  galleryImage: {
    type: FIELD_TYPES.NESTED,
    label: "Gallery image",
    labelDescription: "Images carousel component related only",
    fields: { ...assetFieldsConfig.image.fields }
  },
  initiallySelected: { type: FIELD_TYPES.BOOLEAN, label: "Initially selected" }
};
