import { PureComponent } from "react";
import { componentStyles } from "./tag-component.scss";
import { themes } from "segments/segments-service";
import DefaultProps from "./default-props";

export default class TagComponent extends PureComponent {
  getTagStyle() {
    const { color } = this.props;

    return themes[color];
  }

  render() {
    const { text } = this.props;
    const tagStyle = this.getTagStyle();

    return (
      <div className="tag-component" style={tagStyle}>
        {text}

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

TagComponent.defaultProps = DefaultProps;
