import { PureComponent } from "react";
import { componentStyles } from "./tags-grid-component.scss";
import GridTag from "segments/desktop/core-components/grid-tag/grid-tag";
import { MD } from "constants/sizes";

export default class TagsGridComponent extends PureComponent {
  renderGridItems() {
    const { gridItems, titleSize, textSize } = this.props;

    return gridItems.map((gridItem, index) => {
      return (
        <div key={index} className="grid-tag-component-wrapper">
          <GridTag {...gridItem} titleSize={titleSize} textSize={textSize} />
        </div>
      );
    });
  }

  render() {
    return (
      <div className="tags-grid-component">
        {this.renderGridItems()}

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

const getGridTagProps = (tagText, tagColor, fullParagraphTitle, fullParagraphBody) => {
  const tag = { ...GridTag.defaultProps.tag, text: tagText, color: tagColor };
  const fullParagraph = { ...GridTag.defaultProps.fullParagraph, title: fullParagraphTitle, body: fullParagraphBody };

  return {
    ...GridTag.defaultProps,
    tag,
    fullParagraph
  };
};

TagsGridComponent.defaultProps = {
  gridItems: [
    getGridTagProps(
      "Views",
      "pink",
      "Gain valuable insights",
      "Look at your data differently with multiple View options from Calendar to Map."
    ),
    getGridTagProps(
      "Automations",
      "green",
      "Turn on your autopilot",
      "Automate repetitive tasks and have more time for the work that motivates you."
    ),
    getGridTagProps(
      "Dashboards",
      "purple",
      "See the bigger picture",
      "Get a high-level overview at a glance and make smarter decisions, faster."
    )
  ],
  paddingTop: "64px",
  paddingBottom: "64px",
  titleSize: MD,
  textSize: MD
};
