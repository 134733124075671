import css from "styled-jsx/css";
import Colors from "styles/colors";

export const componentStyles = css.global`
  .button-tag {
    padding: 7px 14px;
    .button-tag-anchor {
      height: 52px;
      width: 100%;
      cursor: pointer;
      display: flex;
      padding: 0px 16px 0 8px;
      align-items: center;
      border-radius: 2px;
      flex-direction: row;
      color: ${Colors["tuna"]};
      font-size: 0.875rem;
      text-decoration: none;
      background-color: ${Colors["riverstone"]};
      :hover {
        background-color: ${Colors["snow"]};
      }
      .tag-text {
        padding: 0px 16px;
        align-items: center;
      }
      .button-tag-image {
        height: 40px;
      }
    }
  }
`;
