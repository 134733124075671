import css from "styled-jsx/css";

export const sharedComponentStyles = css`
  :global(.text-with-pdf) {
    @keyframes fade-in {
      0% {
        opacity: 0;
        transform: scale(0.5);
      }
      80% {
        opacity: 1;
        transform: scale(1.15);
      }
      100% {
        transform: scale(1);
      }
    }

    :global(.pdf-icon) {
      width: 70px;
      margin-left: -20px;
      margin-top: -8px;
      animation: fade-in 0.5s ease-in-out;
    }
  }
`;
