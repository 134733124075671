import { componentStyles } from "./sign-up-product-card.scss";
import DefaultProps from "./default-props";
import {SIGN_UP_PRODUCT_CARDS_DATA} from "./consts";
import { themes } from "segments/segments-service";
import { XS } from "constants/sizes";
import { withGenericConfig } from "/contexts/generic-config-context";
import classnames from "classnames";
import ProductTag from "components/core/product-tag/product-tag";
import RadioButton from "client/components/system/radio-button/radio-button";
import Paragraph from "segments/desktop/core-components/paragraph/paragraph";
import PictureComponent from "components/core/picture/picture-component";
import { SIGNUP_PRODUCT_CARD_CLICKED } from "/constants/bigbrain-event-types";
import { trackEvent } from "/services/bigbrain-service";
import { generateOnKeyDownHandler } from "/client/services/accessibility/accessibility-service";


const SignUpProductCard = (props) => {
  const {productId, translate, isActive, setActiveproductId, index} = props;
  const data = SIGN_UP_PRODUCT_CARDS_DATA[productId];
  const {image, body, tags, theme} = data;
  const tagsTitle = "header.tabs.products.tags.title";
  const cardColor = themes[theme]?.background;
  
  const selectCard =()=>{
    if(!isActive){
      setActiveproductId(productId)}
      trackEvent(SIGNUP_PRODUCT_CARD_CLICKED, {
        kind: productId,
        info1: index
      });
  };


  return (
    <div className={classnames("sign-up-product-card", {active: isActive})} style={{"color": cardColor}} onClick={selectCard} tabIndex="0" onKeyDown={generateOnKeyDownHandler(selectCard)} role="radio">
      <div className="radio-button-wrapper">
        <RadioButton selected={isActive}/>
      </div>
      <div className="product-logo-wrapper">
        <PictureComponent {...image}/>
      </div>
      <div className="text-wrapper">
        <Paragraph body={translate(body)} textSize={XS}/>
      </div>
      <div className="seperator-line"/>
      <div className="product-tags">
      <div className="tags-title">{`${translate(tagsTitle)}`}</div>
      {tags && tags.map((tag, index) => (
      <ProductTag tag={tag} productId={productId} index={index}/>)
      )}
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
}

export default withGenericConfig(SignUpProductCard);

SignUpProductCard.defaultProps = DefaultProps;
