import SingleTemplateTemplatesComponent from "./single-template-templates-component";
import SingleTemplateTemplatesConfig from "./single-template-templates-config";
import SingleTemplateTemplatesAdvancedConfig from "./single-template-templates-advanced-config";
import SingleTemplateTemplatesDynamicsFetch from "./single-template-templates-dynamics-fetch";

export default {
  SingleTemplateTemplatesComponent,
  SingleTemplateTemplatesConfig,
  SingleTemplateTemplatesAdvancedConfig,
  SingleTemplateTemplatesDynamicsFetch
};
