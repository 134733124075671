import { PureComponent } from "react";
import { componentStyles } from "./button-image-tag.scss";
import DefaultProps from "./default-props";
import classnames from "classnames";
import PictureComponent from "components/core/picture/picture-component";

const imageHeight = "64px";

export default class ButtonImageTag extends PureComponent {
  getInputProps = () => {
    const { link, isOpenLinkInNewTab } = this.props;
    const inputProps = { };

    // In order to avoid empty href that causes links to root
    if (link) {
      inputProps.href = link;
      inputProps.target = isOpenLinkInNewTab ? "_blank" : "_self";
    }

    return inputProps;
  }

  render() {
    const { tagImg, tagText, link } = this.props;

    return (
      <div className="button-image-tag">
        <a className={classnames("button-image-tag-anchor", { "clickable": link })} {...this.getInputProps()}>
          <PictureComponent
            {...tagImg}
            specificImageStyle={{ height: imageHeight }}
            pictureClass="button-tag-image"
            isDecorative={true}
          />
          <span className={"tag-text"}>{tagText}</span>
          <style jsx>{componentStyles}</style>
        </a>
      </div>
    );
  }
}

ButtonImageTag.defaultProps = DefaultProps;
