import TabsComponent from "components/core/tabs/tabs-component";
import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import SideBySideTextAndAssetComponent from "segments/desktop/text-and-asset/layouts/side-by-side-text-and-asset/side-by-side-text-and-asset-component";
import { XS_M } from "constants/sizes";

export default {
  ...TabsComponent.defaultProps,
  tabsColor: WORK_OS_IRIS_COLOR_NAME,
  showProcessIndicator: true,
  tabs: [
    {
      tabLabel: "Clients Projects",
      asset: {
        image: {
          ...SideBySideTextAndAssetComponent.defaultProps.asset.image,
          src: "/static/img/segment-layout-default-images/text-and-asset/timeline-view.png"
        },
        position: "center"
      },
      title: {
        titleSize: XS_M,
        title: null
      }
    },
    {
      tabLabel: "Content Planning",
      asset: {
        image: {
          ...SideBySideTextAndAssetComponent.defaultProps.asset.image,
          src: "/static/img/segment-layout-default-images/text-and-asset/timeline-view.png"
        },
        position: "center"
      },
      title: {
        titleSize: XS_M,
        title: null
      }
    }
  ]
};
