import css from "styled-jsx/css";
import { TABLET_QUERY_MAX_WIDTH } from "/constants/vp-sizes";
import HeaderConstants from "segments/desktop/header/layouts/components/basic-header/constants";
import DevelopersBannerConstants from "segments/desktop/header/layouts/developers-center-header/developers-banner/constants";

const MENU_TOP = `${HeaderConstants.BASIC_HEADER_HEIGHT + DevelopersBannerConstants.DEVELOPERS_BANNER_HEIGHT}px`;

export const componentStyles = css`
  .developers-apps-menu-static-component {
    position: fixed;
    top: ${MENU_TOP};
    left: 0;
  }

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    .developers-apps-menu-static-component {
      display: none;
    }
  }
`;
