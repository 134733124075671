export const defaultData = {
  template: "SegmentsTemplate",
  title: "",
  description: "",
  theme: "dark-theme",
  mobileTheme: "dark-theme",
  imagesLazyLoad: false,
  showMenu: true,
  topbar: {
    showMenu: true
  },
  segments: []
};
