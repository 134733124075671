import PictureComponent from "components/core/picture/picture-component";
import {
  SECONDARY_BUTTON_TYPE,
  SIGNUP_BUTTON_TYPE,
  SIGNUP_COMBO_BUTTONS_TYPE
} from "segments/desktop/core-components/button/button-consts";
import { XS } from "components/core/new-button/button-component";
import {themes, WORKOS_IRIS_THEME_ID} from "segments/segments-service";
import { componentStyles } from "./product-card-grid-medium-size-component.scss";
import ProductCardGridBodyComponent from "./product-card-grid-body-component";
import { productCardsImages, productIndexToProductType } from "segments/desktop/grid/layouts/product-cards-grid/default-props";
import {PRODUCTS_THEMES} from "/constants/products";
import classnames from "classnames";

const ProductCardGridMediumSizeComponent = (props) => {
  const { header, body, useCases, buttonConfig, productType, index } = props;
  const productTheme = productType ? PRODUCTS_THEMES[productType] : PRODUCTS_THEMES[productIndexToProductType[index]];
  const dividerStyle = {
    borderImageSource: `linear-gradient(to right, ${themes[productTheme].background}ff, ${themes[productTheme].background}00)`
  };

  const getProductButtonConfig = () => {
    switch (buttonConfig.buttonType) {
      case SIGNUP_BUTTON_TYPE:
        return {
          ...buttonConfig,
          invert: true,
          buttonSize: XS
        };
      case SIGNUP_COMBO_BUTTONS_TYPE:
        return {
          ...buttonConfig,
          signupButtonConfig: {
            ...buttonConfig.signupButtonConfig,
            invert: true,
            buttonSize: XS
          }
        };
      default:
        return buttonConfig;
    }
  };

  const hasNoUseCases = () =>{
    const {useCases} = props;
    return (useCases?.text==="") && (useCases.list.length === 0) ;
  }

  const productCardButtonConfig = getProductButtonConfig();
  const mediumScreenImageProp = productType ? productCardsImages?.[productType]?.mediumScreenImage : productCardsImages?.[productIndexToProductType[index]]?.mediumScreenImage;

  return (
    <div className={classnames("product-card-medium", { "has-no-use-cases": hasNoUseCases() })}>
      <div className="product-card-content">
        <div className="product-card-header">
          <div className="product-card-header-image">
            <PictureComponent {...mediumScreenImageProp} />
          </div>
          <div className="product-card-header-subtitle">{header?.subtitleText}</div>
        </div>
        <div>
          <ProductCardGridBodyComponent
            body={body}
            showDivider={true}
            dividerStyle={dividerStyle}
            useCases={useCases}
            theme={productTheme}
            productCardButtonConfig={productCardButtonConfig}
          />
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    </div>
  );
};

ProductCardGridMediumSizeComponent.defaultProps = {
  theme: WORKOS_IRIS_THEME_ID,
  showDivider: true,
  buttonConfig: {
    buttonType: SECONDARY_BUTTON_TYPE,
    buttonText: "Learn more",
    isOpenLinkInNewTab: true
  }
};

export default ProductCardGridMediumSizeComponent;
