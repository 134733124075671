import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, MAX_DESKTOP_CONTENT_WIDTH, SM, XS_M } from "constants/vp-sizes";

export const componentStyles = css.global`
  .sales-phone-enterprise-component {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .title-wrapper {
      margin-bottom: 24px;
      text-align: center;
      padding: 0 16px;
    }

    .tags-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .button-tag-wrapper {
        width: 25%;
      }
    }
  }

  @media (max-width: ${MAX_DESKTOP_CONTENT_WIDTH + "px"}) {
    .sales-phone-enterprise-component {
      .tags-wrapper {
        .button-tag-wrapper {
          width: 33%;
        }
      }
    }
  }
  @media (max-width: ${SM + "px"}) {
    .sales-phone-enterprise-component {
      .tags-wrapper {
        .button-tag-wrapper {
          width: 50%;
        }
      }
    }
  }
  @media (max-width: ${XS_M + "px"}) {
    .sales-phone-enterprise-component {
      .tags-wrapper {
        .button-tag-wrapper {
          width: 100%;
        }
      }
    }
  }
`;
