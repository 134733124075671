import dynamic from "next/dynamic";

const GlobeWithTopCtaHomepageFirstFoldComponent = require("./globe-with-top-cta-homepage-first-fold-component").default;

import GlobeWithTopCtaHomepageFirstFoldConfig from "./globe-with-top-cta-homepage-first-fold-config";
import GlobeWithTopCtaHomepageFirstFoldAdvancedConfig from "./globe-with-top-cta-homepage-first-fold-advanced-config";

export default {
  GlobeWithTopCtaHomepageFirstFoldComponent,
  GlobeWithTopCtaHomepageFirstFoldConfig,
  GlobeWithTopCtaHomepageFirstFoldAdvancedConfig
};
