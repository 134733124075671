import { FIELD_TYPES } from "constants/template-field-type";
import { IPO_CAMPAIGN_ID, GARTNER_CAMPAIGN_ID } from "constants/sales/campaigns";

export default {
  overrideCampaignId: {
    type: FIELD_TYPES.COMBO,
    options: [{ value: IPO_CAMPAIGN_ID, text: "IPO promotion" }, {value: GARTNER_CAMPAIGN_ID, text: "Gartner campaign"}],
    label: "Override campaign"
  },
  lastNameFirst: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Place Last Name before First Name",
  }
};
