import Layouts from "./layouts";
const {
  // plop_marker:segment_index_import_layout
  DevelopersAppsMenuStatic,
} = Layouts;

export default { 
  // plop_marker:segment_index_add_layout
  DevelopersAppsMenuStaticComponent: {
    type: "DevelopersAppsMenuStaticComponent",
    title: "Developers Apps Menu",
    imgSrc: "/static/img/segments/layouts/developers-apps-menu-static.png",
    config: DevelopersAppsMenuStatic.DevelopersAppsMenuStaticConfig,
    advancedConfig: DevelopersAppsMenuStatic.DevelopersAppsMenuStaticAdvancedConfig,
    component: DevelopersAppsMenuStatic.DevelopersAppsMenuStaticComponent
  },
};