import { memo } from "react";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import FullParagraphDefaultProps from "segments/desktop/core-components/full-paragraph/default-props";
import { LG, MD } from "constants/sizes";
import { SPACE_BLUE_VIDEO_THEME_ID } from "segments/segments-service";
import UniverseStars from "segments/desktop/core-components/universe-stars/universe-stars";
import { ALIGN_TYPES } from "constants/align-types";
import { componentStyles } from "./universe-paragraph-component.scss";

const mobileStarsLocations = [
  { top: 40, left: 25 },
  { top: 50, left: 18 },
  { top: 150, right: 30 },
  { top: 140, right: 45 }
];

const MobileStars = memo(() => {
  return <UniverseStars stars={mobileStarsLocations} />;
});

const UniverseParagraphComponent = (props) => {
  const { isMobileOrTabletBrowser, buttonConfig } = props;
  const extendedButtonConfig = { ...buttonConfig, displayedOnDarkTheme: true };
  return (
    <div className="universe-paragraph-component">
      {isMobileOrTabletBrowser && (
        <div className="stars-canvas">
          <MobileStars />
        </div>
      )}
      <div className="full-paragraph-wrapper">
        <FullParagraph {...props} buttonConfig={extendedButtonConfig} />
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default UniverseParagraphComponent;

UniverseParagraphComponent.defaultProps = {
  ...FullParagraphDefaultProps,
  theme: SPACE_BLUE_VIDEO_THEME_ID,
  mobilePaddingTop: "40px",
  mobilePaddingBottom: "24px",
  titleSize: LG,
  textSize: MD,
  textAlign: ALIGN_TYPES.CENTER
};
