import Layouts from "./layouts";
const {
  // plop_marker:segment_index_import_layout
  MarkdownEmbedded,
} = Layouts;

export default { 
  // plop_marker:segment_index_add_layout
  MarkdownEmbeddedComponent: {
    type: "MarkdownEmbeddedComponent",
    title: "Markdown",
    imgSrc: "/static/img/segments/layouts/markdown-embedded.png",
    config: MarkdownEmbedded.MarkdownEmbeddedConfig,
    advancedConfig: MarkdownEmbedded.MarkdownEmbeddedAdvancedConfig,
    component: MarkdownEmbedded.MarkdownEmbeddedComponent
  },
};