import { SUPPORT_PAGE_LINK } from "constants/links";
import { withGenericConfig } from "/contexts/generic-config-context";
import SlimLogos from "segments/desktop/logos/layouts/slim-logos/slim-logos-component";
import PictureComponent from "/components/core/picture/picture-component";
import Title from "segments/desktop/core-components/title/title";
import CustomerQuotes from "constants/customer-quotes";
// import { useCompanyData } from "client/services/enrichment/company-data";

const ContactSalesDetails = (props) => {
  // const companyData = useCompanyData();

  const { benefits, title, titleSize, logosTitle, logos, translate, translateWithParam, isMobileOrTabletBrowser, customerQuoteKey, hideSupportStatement} = props;
  const iconStyle = { width: 37, height: 37 };

  const supportLink = `<a href="${SUPPORT_PAGE_LINK}" class="support-paragraph-link" target="_blank">${translate(
    "contactSales.hubspotForm.support_center"
  )}</a>`;
  const supportStatement = translateWithParam("contactSales.hubspotForm.support_statement", supportLink);
  const customerQuote = CustomerQuotes[customerQuoteKey];

  return (
    <div className="contact-sales-details">
      <div className="contact-sales-title">
        <Title title={translate(title)} titleSize={titleSize} />
      </div>

      <div className="benefits-wrapper">
        {benefits.map((benefit) => (
          <div key={benefit.key} className="benefit-wrapper">
            <div className="benefit-icon-wrapper">
              <PictureComponent src={benefit.icon} specificImageStyle={iconStyle} />
              <span className="icon-text" dangerouslySetInnerHTML={{ __html: translate(benefit.title) }} />
            </div>
            <hr className="benefit-text-divider"></hr>
            <div className="benefit-text-wrapper">{translate(benefit.description)}</div>
          </div>
        ))}
      </div>
      <div className="support-paragraph-wrapper">
      {!hideSupportStatement && (
        <span className="support-paragraph-text" dangerouslySetInnerHTML={{ __html: supportStatement }} />
        )
      }
      </div>
      {customerQuote && (
        <div className="customer-quote-wrapper">
          <blockquote className="quote">
            <span className="quotationMark">“</span>
            <span className="quoteText">{`${customerQuote?.quote}”`}</span>
          </blockquote>
          <div className="name-and-job-description">{`${customerQuote?.name} | ${customerQuote?.jobDescription} | ${customerQuote?.companyName}`}</div>
        </div>)
      }
      <SlimLogos {...logosTitle} logos={logos} textAlign={isMobileOrTabletBrowser ? "center" : "left"} />
    </div>
  );
};

export default withGenericConfig(ContactSalesDetails);
