import { XS } from "constants/sizes";

export const ISRAEL_LOCATION = "israel";
export const GLOBAL_LOCATION = "global";

export const cardsProps = {
  shadowCards: true,
  removeShadowOnHover: false,
  gridAlignment: "center",
  titleSize: XS
};

export const israelGridItems = [
  {
    cardNumOfColumnsWidth: 1.2,
    height: 300,
    width: 300,
    fullParagraph: {
      topic: {
        image: {
          src: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/recruitment/small%20logos/Fast_company_logo_small.png"
        }
      },
      title: "<b>With apps for forms and whiteboards, monday.com is building a suite</b>",
      body: ""
    },
    src: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/recruitment/thumbnails/Fast_company_thumbnail.png",
    mobileImage: {
      src: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/recruitment/thumbnails/Fast_company_thumbnail.png"
    },
    link: {
      href: "https://www.fastcompany.com/90724310/monday-com-workforms-canvas"
    }
  },
  {
    cardNumOfColumnsWidth: 1.2,
    height: 300,
    width: 300,
    fullParagraph: {
      topic: {
        image: {
          src: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/recruitment/small%20logos/Protocol_logo_small.png"
        }
      },
      title: "<b>Why monday thinks it can win the future of work</b>",
      body: ""
    },
    src: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/recruitment/thumbnails/Protocol_thumbnail.png",
    mobileImage: {
      src: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/recruitment/thumbnails/Protocol_thumbnail.png"
    },
    link: {
      href: "https://www.protocol.com/workplace/monday-app-ipo"
    }
  },
  {
    cardNumOfColumnsWidth: 1.2,
    height: 300,
    width: 300,
    fullParagraph: {
      topic: {
        image: {
          src: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/recruitment/small%20logos/Maariv_logo_small.png"
        }
      },
      title: "<b>monday.com is the 5th Best Place to work in Israel - Dun's 100</b>",
      body: ""
    },
    src: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/recruitment/thumbnails/Maariv_thumbnail.png",
    mobileImage: {
      src: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/recruitment/thumbnails/Maariv_thumbnail.png"
    },
    link: {
      href: "https://www.maariv.co.il/business/tech/Article-958561"
    }
  }
];

export const globalGridItems = [
  {
    cardNumOfColumnsWidth: 1.2,
    height: 300,
    width: 300,
    fullParagraph: {
      topic: {
        image: {
          src: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/recruitment/small%20logos/Fast_company_logo_small.png"
        }
      },
      title: "<b>With apps for forms and whiteboards, monday.com is building a suite</b>",
      body: ""
    },
    src: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/recruitment/thumbnails/Fast_company_thumbnail.png",
    mobileImage: {
      src: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/recruitment/thumbnails/Fast_company_thumbnail.png"
    },
    link: {
      href: "https://www.fastcompany.com/90724310/monday-com-workforms-canvas"
    }
  },
  {
    cardNumOfColumnsWidth: 1.2,
    height: 300,
    width: 300,
    fullParagraph: {
      topic: {
        image: {
          src: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/recruitment/small%20logos/Protocol_logo_small.png"
        }
      },
      title: "<b>Why monday thinks it can win the future of work</b>",
      body: ""
    },
    src: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/recruitment/thumbnails/Protocol_thumbnail.png",
    mobileImage: {
      src: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/recruitment/thumbnails/Protocol_thumbnail.png"
    },
    link: {
      href: "https://www.protocol.com/workplace/monday-app-ipo"
    }
  },
  {
    cardNumOfColumnsWidth: 1.2,
    height: 300,
    width: 300,
    fullParagraph: {
      topic: {
        image: {
          src: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/recruitment/small%20logos/Great_placel_logo_small.png"
        }
      },
      title: "<b>Great place to work certified</b>",
      body: ""
    },
    src: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/recruitment/thumbnails/Great_place_to_work_thumbnail.png",
    mobileImage: {
      src: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/recruitment/thumbnails/Great_place_to_work_thumbnail.png"
    },
    link: {
      href: "https://www.maariv.co.il/business/tech/Article-958561"
    }
  }
];
