import dynamic from "next/dynamic";

const ZendeskSideBySideFormComponent = require("./zendesk-side-by-side-form-component").default;

import ZendeskSideBySideFormConfig from "./zendesk-side-by-side-form-config";
import ZendeskSideBySideFormAdvancedConfig from "./zendesk-side-by-side-form-advanced-config";

export default {
  ZendeskSideBySideFormComponent,
  ZendeskSideBySideFormConfig,
  ZendeskSideBySideFormAdvancedConfig
};
