import { FIELD_TYPES } from "constants/template-field-type";
import SecondaryButtonFieldsConfig from "segments/desktop/core-components/button/secondary-button/fields-config";
import SignupButtonFieldsConfig from "segments/desktop/core-components/button/signup-button/fields-config";

export default {
  signupButtonConfig: {
    type: FIELD_TYPES.NESTED,
    label: "Signup Button",
    fields: { ...SignupButtonFieldsConfig }
  },
  secondaryButtonConfig: {
    type: FIELD_TYPES.NESTED,
    label: "Secondary Button",
    fields: { ...SecondaryButtonFieldsConfig }
  },
  swapButtonsOrder: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Swap buttons order"
  }
};
