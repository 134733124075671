import SideBySideTextAndAssetDefaultProps from "segments/desktop/core-components/side-by-side-text-and-asset/default-props";
import { SIGNUP_BUTTON_TYPE } from "segments/desktop/core-components/button/button-consts";
import { MD } from "constants/sizes";

const getFullParagraph = () => {
  const { fullParagraph } = SideBySideTextAndAssetDefaultProps;

  return {
    ...fullParagraph,
    title: "<monday-color green>Together.</monday-color> In projects, processes, and success. Wherever you are.",
    body: "monday.com is the Work OS that powers teams to run projects and workflows with confidence.",
    topic: {},
    buttonConfig: { buttonType: SIGNUP_BUTTON_TYPE, buttonColor: "dodger-blue" }
  };
};

const getAssetDefaultProps = () => {
  const { asset } = SideBySideTextAndAssetDefaultProps;
  const { image } = asset;
  const newImage = { ...image, src: "/static/img/segment-layout-default-images/first-fold/globe2.png" };

  return {
    ...asset,
    image: newImage
  };
};

export default {
  fullParagraph: getFullParagraph(),
  asset: getAssetDefaultProps(),
  titleSize: MD
};
