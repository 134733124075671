import { PureComponent } from "react";
import YoutubeVideoWithPosterComponent from "components/core/youtube-video-with-poster/youtube-video-with-poster-component";
import YoutubeVideoWithPosterComponentDefaultProps from "components/core/youtube-video-with-poster/default-props";

import { componentStyles } from "./embedded-youtube-asset-component.scss";

export default class EmbeddedYoutubeAssetComponent extends PureComponent {
  getStyle = () => {
    const { width, mobileWidth, isMobileOrTabletBrowser } = this.props;
    let finalWidth = isMobileOrTabletBrowser && mobileWidth ? mobileWidth : width;
    finalWidth = finalWidth || "auto";
    return { width: finalWidth };
  };

  render() {
    return (
      <div className="embedded-youtube-asset-component" style={this.getStyle()}>
        <YoutubeVideoWithPosterComponent {...this.props} fitToParent={true} />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

EmbeddedYoutubeAssetComponent.defaultProps = {
  ...YoutubeVideoWithPosterComponentDefaultProps
};
