import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, MOBILE_QUERY_MAX_WIDTH_M } from "constants/vp-sizes";

export const componentStyles = css`
  .title-only-paragraph-component {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    margin: auto;

    .title-only-paragraph-wrapper {
      max-width: 1100px;
      margin: auto;
      display: flex;
      justify-content: center;
      padding: 0px 32px;
    }
  }
`;
