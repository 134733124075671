import dynamic from "next/dynamic";

const EmbeddedBoardIframeComponent = require("./embedded-board-iframe-component").default;

import EmbeddedBoardIframeConfig from "./embedded-board-iframe-config";
import EmbeddedBoardIframeAdvancedConfig from "./embedded-board-iframe-advanced-config";

export default {
  EmbeddedBoardIframeComponent,
  EmbeddedBoardIframeConfig,
  EmbeddedBoardIframeAdvancedConfig
};
