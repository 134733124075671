import { FIELD_TYPES } from "constants/template-field-type";
import AssetFieldsConfig from "components/core/asset/fields-config";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import ThemeConfig from "segments/core-configs/theme-config";
const { textColor, ...selectedFullParagraphFields } = FullParagraphFieldsConfig;

const { image } = AssetFieldsConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "Review Cards",
  fields: {
    ...ThemeConfig,
    fullParagraph: {
      type: FIELD_TYPES.NESTED,
      label: "Paragraph",
      fields: selectedFullParagraphFields
    },
    cards: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Cards",
      maxLimit: 4,
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          image,
          title: { type: FIELD_TYPES.TEXT, label: "Title" },
          subtitle: { type: FIELD_TYPES.TEXT, label: "Subtitle" }
        }
      }
    }
  }
};
