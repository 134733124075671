import dynamic from "next/dynamic";

const ZendeskFormComponent = require("./zendesk-form-component").default;

import ZendeskFormConfig from "./zendesk-form-config";
import ZendeskFormAdvancedConfig from "./zendesk-form-advanced-config";

export default {
  ZendeskFormComponent,
  ZendeskFormConfig,
  ZendeskFormAdvancedConfig
};
