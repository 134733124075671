import css from "styled-jsx/css";
import {
  MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH,
  MAX_DESKTOP_GRID_WIDTH,
  FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH
} from "constants/vp-sizes";
import Colors from "styles/colors";
import BodyConstants from "segments/desktop/constants";

const CONTAINER_HEIGHT = 550;
const CONTAINER_HEIGHT_PX = `${CONTAINER_HEIGHT}px`;
const BODY_HORIZONTAL_PADDING = `${BodyConstants.BODY_CONTENT_HORIZONTAL_PADDING}px`;

export const componentStyles = css.global`
  .timer-vertical-tabs-desktop-component {
    max-width: ${MAX_DESKTOP_GRID_WIDTH + "px"};
    width: 85%;
    margin: 0 auto;
    padding: 8px 0px;

    .tabs-divider {
      border-top: ${`1px solid ${Colors.gainsboro}`};
      width: 100%;
      margin-bottom: 32px;
    }

    .tabs-paragraph-wrapper {
      margin-bottom: 45px;
    }

    .vertical-timer-tabs-container {
      display: flex;

      .desktop-tabs-text-container {
        margin-right: 75px;
        min-height: ${CONTAINER_HEIGHT_PX};
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .desktop-asset-container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .tab-asset-inner {
          align-self: flex-end;
          display: flex;
          width: 760px;
          height: ${CONTAINER_HEIGHT_PX};
          padding: 28px;

          .tab-asset-image {
            max-width: 100%;
            max-height: ${CONTAINER_HEIGHT_PX};
            width: auto;
          }
        }
      }
      .fade-enter {
        opacity: 0;
      }
      .fade-exit {
        opacity: 1;
      }
      .fade-enter-active {
        opacity: 1;
      }
      .fade-exit-active {
        opacity: 0;
      }
      .fade-enter-active,
      .fade-exit-active {
        transition: opacity 200ms;
      }
    }

    .button-link {
      margin-top: 40px;
    }
  }

  .full-screen {
    width: 100%;
    max-width: ${FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    padding: 0;

    .vertical-timer-tabs-container {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .desktop-asset-container {
        .tab-asset-inner {
          height: 100%;
          width: 100%;
          padding: 0;
          align-self: center;
        }
      }

      .desktop-tabs-text-container {
        box-sizing: content-box;
        margin-left: auto;
        padding-left: ${`calc(max(min(100vw, ${FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px) - ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px, 0px) / 2 + ${BODY_HORIZONTAL_PADDING})`};
        padding-right: 60px;
        padding-top: 64px;
        padding-bottom: 64px;
        max-width: ${`calc((${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px - ${BODY_HORIZONTAL_PADDING} * 2) / 2) })`};
      }
    }
  }
`;
