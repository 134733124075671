import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";

export const componentStyles = css.global`
  .side-by-side-tabs-and-process-tabs-component {
    max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
    margin: auto;

    .process-tabs {
      width: 100%;
    }

    .vertical-timer-tabs-container {
      .desktop-tabs-text-container {
        &.tabs-container {
          max-width: 450px;
          min-width: 300px;
          justify-content: flex-start;
          margin-top: 16px;
        }
      }
    }

    .main-title {
      text-align: center;
      font-weight: 600;
    }
  }
`;
