import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, TABLET_QUERY_MAX_WIDTH } from "constants/vp-sizes";
import colors from "styles/colors";
import { DARK_WORK_OS_IRIS_COLOR_NAME, WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import { getComponentShadow } from "segments/desktop/constants";

export const componentStyles = css.global`
  .youtube-video-asset-component {
    margin: auto;
    .video-part {
      position: relative;
      ${getComponentShadow()}
      border-radius: 4px;
      cursor: pointer;
      margin: auto;

      .poster-image-wrapper {
        display: flex;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        video {
          width: 100%;
          height: 100%;
          display: flex;
        }

        &:hover {
          .play-button-container {
            transform: translate(-50%, -50%) scale(1.05);
            background: ${colors[DARK_WORK_OS_IRIS_COLOR_NAME]};
            transition: all 0.2s ease-in-out;
          }
        }

        .play-button-container {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 128px;
          height: 128px;
          border-radius: 50%;
          background-color: ${colors[WORK_OS_IRIS_COLOR_NAME]};
          box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
          pointer-events: none;
          border: none;

          .play-button {
            box-sizing: border-box;
            border-color: transparent transparent transparent white;
            border-style: solid;
            border-width: 24px 0 24px 36px;
            position: absolute;
            left: calc(50% + 8px);
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }

        @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
          .play-button-container {
            width: 64px;
            height: 64px;

            &:hover {
              transform: translate(-50%, -50%) scale(1.1);
            }

            .play-button {
              border-width: 12px 0 12px 20px;
              left: calc(50% + 4px);
            }
          }
        }
      }
    }

    &:not(.full-width) {
      max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
    }
    .youtube-video-overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 555;
      background: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .play-button {
        height: 128px;
        width: 128px;
      }
      .youtube-picture {
        width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
      }
      .youtube-picture-container {
      }
    }
  }
`;
