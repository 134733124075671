import dynamic from "next/dynamic";

const SideBySideHeroComponent = require("./side-by-side-hero-component").default;

import SideBySideHeroConfig from "./side-by-side-hero-config";

export default {
  SideBySideHeroComponent,
  SideBySideHeroConfig
};
