import { PureComponent } from "react";
import { componentStyles } from "./globe-homepage-first-fold-component.scss";
import GlobeDesktopHomepageFirstFoldComponent from "./components/globe-desktop-homepage-first-fold/globe-desktop-homepage-first-fold-component";
import GlobeMobileHomepageFirstFoldComponent from "./components/globe-mobile-homepage-first-fold/globe-mobile-homepage-first-fold-component";
import DefaultProps from "./default-props";

export default class GlobeHomepageFirstFoldComponent extends PureComponent {
  render() {
    return (
      <div className="globe-homepage-first-fold-component">
        <div className="desktop-wrapper">
          <GlobeDesktopHomepageFirstFoldComponent {...this.props} />
        </div>

        <div className="mobile-wrapper">
          <GlobeMobileHomepageFirstFoldComponent {...this.props} />
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

GlobeHomepageFirstFoldComponent.defaultProps = DefaultProps;
