import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH ,MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH, TABLET_QUERY_MAX_WIDTH } from "constants/vp-sizes";


export const componentStyles = css`
  .product-cards-grid-component {
    .product-cards-grid-full-size-wrapper{
      display: unset;
  }
  .product-cards-grid-medium-size-wrapper{
    display:none;
  }
  .product-cards-grid-small-size-wrapper{
    display: none;
  }

  @media (max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH}) and (min-width:${TABLET_QUERY_MAX_WIDTH}) {
    .product-cards-grid-medium-size-wrapper{
      display: unset;
    }
    .product-cards-grid-full-size-wrapper{
      display: none;
    }
    .product-cards-grid-small-size-wrapper{
      display: none;
  }
}

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    .product-cards-grid-full-size-wrapper{
      display: none;
    }
    .product-cards-grid-medium-size-wrapper{
      display:none;
    }
    .product-cards-grid-small-size-wrapper{
      display: unset;
    }
  
  }

  }
`;
