import { FIELD_TYPES } from "constants/template-field-type";
import FullBackgroundTextAndAssetFieldsConfig from "segments/desktop/core-components/full-background-text-and-asset/fields-config";
import ThemeConfig from "segments/core-configs/theme-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Full Background",
  fields: {
    paragraphVerticalAlignment: {
      type: FIELD_TYPES.COMBO,
      label: "Paragraph Vertical Alignment",
      options: ["top", "center", "bottom"]
    },
    ...ThemeConfig,
    ...FullBackgroundTextAndAssetFieldsConfig
  }
};
