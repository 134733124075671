import { FIELD_TYPES } from "constants/template-field-type";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";

const { ...selectedFullParagraphFields } = FullParagraphFieldsConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "Asymmetric",
  fields: {
    topColor: {
      type: FIELD_TYPES.COLOR_PICKER,
      label: "Top Color"
    },
    fullParagraph: {
      type: FIELD_TYPES.NESTED,
      label: "Full Paragraph",
      fields: selectedFullParagraphFields
    }
  }
};
