import Layouts from "./layouts";
const {
  // plop_marker:segment_index_import_layout
  BasicButton,
} = Layouts;

export default { 
  // plop_marker:segment_index_add_layout
  BasicButtonComponent: {
    type: "BasicButtonComponent",
    title: "Basic",
    imgSrc: "/static/img/segments/layouts/basic-button.png",
    config: BasicButton.BasicButtonConfig,
    advancedConfig: BasicButton.BasicButtonAdvancedConfig,
    component: BasicButton.BasicButtonComponent
  },
};