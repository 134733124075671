import { FIELD_TYPES } from "constants/template-field-type";
import SideBySideTextAndAssetFieldsConfig from "segments/desktop/core-components/side-by-side-text-and-asset/fields-config";

const {
  asset: {
    fields: { position, ...restOfAssetFields },
    ...restOfAssetConfig
  },
  ...selectedSideBySideTextAndAssetFieldsConfig
} = SideBySideTextAndAssetFieldsConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "Side By Side Hubspot",
  fields: {
    formId: {
      type: FIELD_TYPES.TEXT,
      label: "Form ID"
    },
    ...selectedSideBySideTextAndAssetFieldsConfig,
    asset: {
      fields: {
        ...restOfAssetFields
      },
      ...restOfAssetConfig
    }
  }
};
