import css from "styled-jsx/css";

export const componentStyles = css.global`
  .timeline-tabs-component {
    display: flex;
    align-items: center;
    justify-content: center;
    .vertical-tabs {
      .desktop-wrapper {
        .vertical-tabs-desktop-component {
          .vertical-tabs-wrapper {
            .vertical-tabs {
              .tabs {
                .tabs-wrapper {
                  .vertical-tab {
                    .vertical-tab-timeline {
                      height: 100%;
                      margin-right: 10px;
                      border: 3px solid;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
