import Shapes from "segments/desktop/shape";
import Colors from "styles/colors";
import Konva from "konva";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import { ALIGN_TYPES } from "constants/align-types";

// The base of the canvas is 1440 and is considered as middle
// For larger screen we give extra 500px on each side
export const CANVAS_SIDE_OFFSET = 1000;
export const CANVAS_FULL_WIDTH = MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + CANVAS_SIDE_OFFSET;
export const SSR_CANVAS_FULL_HEIGHT = 2000;

export const getKonvaElement = (segmentType) =>
  ({
    [Shapes.RectangleShapeComponent.type]: Konva.Rect,
    [Shapes.StarShapeComponent.type]: Konva.Star
  }[segmentType]);

// Used to put left/right aligned items in the center of 1440
const getExtraXOffset = (align) => {
  return align === ALIGN_TYPES.CENTER ? 0 : CANVAS_SIDE_OFFSET / 2;
};

export const getShapeProps = (segmentProps, segmentType) => {
  let extraProps = {};

  const align = segmentProps.align;

  const fill = Colors[segmentProps.fill];
  const stroke = Colors[segmentProps.stroke];
  const shadowColor = Colors[segmentProps.shadowColor];

  const y = parseFloat(segmentProps.y) || 0;
  const x = parseFloat(segmentProps.x) || 0;
  const scale = parseFloat(segmentProps.scale) || 1;
  const cornerRadius = parseFloat(segmentProps.cornerRadius) || 0;
  const rotation = parseFloat(segmentProps.rotation) || 0;
  const strokeWidth = parseFloat(segmentProps.strokeWidth) || 0;
  const opacity = parseFloat(segmentProps.opacity) || 1;
  const shadowBlur = parseFloat(segmentProps.shadowBlur) || 0;
  const shadowOffsetX = parseFloat(segmentProps.shadowOffsetX) || 0;
  const shadowOffsetY = parseFloat(segmentProps.shadowOffsetY) || 0;

  // Star props
  if (segmentType === Shapes.StarShapeComponent.type) {
    extraProps = {
      ...extraProps,
      numPoints: parseInt(segmentProps.numPoints) || 4,
      innerRadius: parseInt(segmentProps.innerRadius) || 100,
      outerRadius: parseInt(segmentProps.outerRadius) || 35
    };
  }

  // Ring props
  if (segmentType === Shapes.RingShapeComponent.type) {
    extraProps = {
      ...extraProps,
      innerRadius: parseInt(segmentProps.innerRadius) || 100,
      outerRadius: parseInt(segmentProps.outerRadius) || 35
    };
  }

  // Hotdog and Rectangle props
  const width = 100;
  if ([Shapes.HotdogShapeComponent.type, Shapes.RectangleShapeComponent.type].includes(segmentType)) {
    extraProps = {
      ...extraProps,
      width: parseInt(segmentProps.width) || 100
    };
  }

  const { relativeX, xDirectionFactor } = {
    [ALIGN_TYPES.RIGHT]: {
      relativeX: CANVAS_FULL_WIDTH,
      xDirectionFactor: -1
    },
    [ALIGN_TYPES.CENTER]: {
      relativeX: CANVAS_FULL_WIDTH / 2,
      xDirectionFactor: -1
    },
    [ALIGN_TYPES.LEFT]: {
      relativeX: 0,
      xDirectionFactor: 1
    }
  }[align];

  return {
    height: 100,
    width,
    x: relativeX + (x + getExtraXOffset(align)) * xDirectionFactor,
    y,
    scale: { x: scale, y: scale },
    cornerRadius,
    rotation,
    fill,
    stroke,
    strokeWidth,
    opacity,
    shadowColor,
    shadowBlur,
    shadowOffsetX,
    shadowOffsetY,
    ...extraProps
  };
};
