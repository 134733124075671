import { PureComponent } from "react";
import { componentStyles } from "./side-by-side-hero-component.scss";
import Topbar from "/components/core/top-bar-new/top-bar-component";
import SideBySideTextAndAsset from "segments/desktop/core-components/side-by-side-text-and-asset/side-by-side-text-and-asset";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";

export default class SideBySideHeroComponent extends PureComponent {
  render() {
    const { textAndAsset, topbar } = this.props;

    return (
      <div className="side-by-side-hero-component">
        <Topbar maxWidth="100vw" {...topbar} />
        <SideBySideTextAndAsset {...textAndAsset} />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

SideBySideHeroComponent.defaultProps = {
  topbar: { showMenu: true },
  textAndAsset: {
    ...SideBySideTextAndAsset.defaultProps,
    asset: {
      position: "center",
      src: "/static/img/segment-layout-default-images/hero/board-with-timeline.png"
    }
  }
};
