import Layouts from "./layouts";

const {
  // plop_marker:segment_index_import_layout
  ConnectedProductsGrid,
  FlexibleCardsGrid,
  SquareTagsGrid,
  ProductCardsGrid,
  MasonryGrid,
  TagItemsGrid,
  IconWithParagraphGrid,
  SelectableTagsWithCarouselGrid,
  BlogCardsGrid,
  ButtonImageTagsGrid,
  ButtonTagsGrid,
  CardsGrid,
  TagsGrid,
  SelectableTagsGrid,
  ImageWithParagraphGrid,
  OnlyImageGrid,
  PressGrid
} = Layouts;

export default {
  // plop_marker:segment_index_add_layout
  PressGridComponent: {
    type: "PressGridComponent",
    title: "Press",
    imgSrc: "/static/img/segments/layouts/press-grid.png",
    config: PressGrid.PressGridConfig,
    advancedConfig: PressGrid.PressGridAdvancedConfig,
    component: PressGrid.PressGridComponent
  },
  ConnectedProductsGridComponent: {
    type: "ConnectedProductsGridComponent",
    title: "Connected Products",
    imgSrc: "/static/img/segments/layouts/connected-products-grid.png",
    config: ConnectedProductsGrid.ConnectedProductsGridConfig,
    advancedConfig: ConnectedProductsGrid.ConnectedProductsGridAdvancedConfig,
    component: ConnectedProductsGrid.ConnectedProductsGridComponent
  },
  FlexibleCardsGridComponent: {
    type: "FlexibleCardsGridComponent",
    title: "Flexible Cards",
    imgSrc: "/static/img/segments/layouts/flexible-cards-grid.png",
    config: FlexibleCardsGrid.FlexibleCardsGridConfig,
    advancedConfig: FlexibleCardsGrid.FlexibleCardsGridAdvancedConfig,
    component: FlexibleCardsGrid.FlexibleCardsGridComponent
  },
  SquareTagsGridComponent: {
    type: "SquareTagsGridComponent",
    title: "Square Tags",
    imgSrc: "/static/img/segments/layouts/square-tags-grid.png",
    config: SquareTagsGrid.SquareTagsGridConfig,
    advancedConfig: SquareTagsGrid.SquareTagsGridAdvancedConfig,
    component: SquareTagsGrid.SquareTagsGridComponent
  },
  ProductCardsGridComponent: {
    type: "ProductCardsGridComponent",
    title: "Product Cards",
    imgSrc: "/static/img/segments/layouts/product-cards-grid.png",
    config: ProductCardsGrid.ProductCardsGridConfig,
    advancedConfig: ProductCardsGrid.ProductCardsGridAdvancedConfig,
    component: ProductCardsGrid.ProductCardsGridComponent
  },
  MasonryGridComponent: {
    type: "MasonryGridComponent",
    title: "Masonry",
    imgSrc: "/static/img/segments/layouts/masonry-grid.png",
    config: MasonryGrid.MasonryGridConfig,
    advancedConfig: MasonryGrid.MasonryGridAdvancedConfig,
    component: MasonryGrid.MasonryGridComponent
  },
  TagItemsGridComponent: {
    type: "TagItemsGridComponent",
    title: "Tag Items",
    imgSrc: "/static/img/segments/layouts/tag-items-grid.png",
    config: TagItemsGrid.TagItemsGridConfig,
    advancedConfig: TagItemsGrid.TagItemsGridAdvancedConfig,
    component: TagItemsGrid.TagItemsGridComponent
  },
  IconWithParagraphGridComponent: {
    type: "IconWithParagraphGridComponent",
    title: "Icon With Paragraph",
    imgSrc: "/static/img/segments/layouts/icon-with-paragraph-grid.png",
    config: IconWithParagraphGrid.IconWithParagraphGridConfig,
    advancedConfig: IconWithParagraphGrid.IconWithParagraphGridAdvancedConfig,
    component: IconWithParagraphGrid.IconWithParagraphGridComponent
  },
  SelectableTagsWithCarouselGridComponent: {
    type: "SelectableTagsWithCarouselGridComponent",
    title: "Selectable Tags With Carousel",
    imgSrc: "/static/img/segments/layouts/selectable-tags-with-carousel-grid.png",
    config: SelectableTagsWithCarouselGrid.SelectableTagsWithCarouselGridConfig,
    advancedConfig: SelectableTagsWithCarouselGrid.SelectableTagsWithCarouselGridAdvancedConfig,
    component: SelectableTagsWithCarouselGrid.SelectableTagsWithCarouselGridComponent
  },
  BlogCardsGridComponent: {
    type: "BlogCardsGridComponent",
    title: "Blog Cards",
    imgSrc: "/static/img/segments/layouts/blog-cards-grid.png",
    config: BlogCardsGrid.BlogCardsGridConfig,
    advancedConfig: BlogCardsGrid.BlogCardsGridAdvancedConfig,
    component: BlogCardsGrid.BlogCardsGridComponent
  },
  ButtonImageTagsGridComponent: {
    type: "ButtonImageTagsGridComponent",
    title: "Button Image Tags",
    imgSrc: "/static/img/segments/layouts/button-image-tags-grid.png",
    config: ButtonImageTagsGrid.ButtonImageTagsGridConfig,
    advancedConfig: ButtonImageTagsGrid.ButtonImageTagsGridAdvancedConfig,
    component: ButtonImageTagsGrid.ButtonImageTagsGridComponent
  },
  ButtonTagsGridComponent: {
    type: "ButtonTagsGridComponent",
    title: "Button Tags",
    imgSrc: "/static/img/segments/layouts/button-tags-grid.png",
    config: ButtonTagsGrid.ButtonTagsGridConfig,
    advancedConfig: ButtonTagsGrid.ButtonTagsGridAdvancedConfig,
    component: ButtonTagsGrid.ButtonTagsGridComponent
  },
  CardsGridComponent: {
    type: "CardsGridComponent",
    title: "Cards",
    imgSrc: "/static/img/segments/layouts/cards-grid.png",
    config: CardsGrid.CardsGridConfig,
    advancedConfig: CardsGrid.CardsGridAdvancedConfig,
    component: CardsGrid.CardsGridComponent
  },
  TagsGridComponent: {
    type: "TagsGridComponent",
    title: "Tags",
    imgSrc: "/static/img/segments/layouts/tags-grid.png",
    config: TagsGrid.TagsGridConfig,
    advancedConfig: TagsGrid.TagsGridAdvancedConfig,
    component: TagsGrid.TagsGridComponent
  },
  SelectableTagsGridComponent: {
    type: "SelectableTagsGridComponent",
    title: "Selectable Tags Grid",
    imgSrc: "/static/img/segments/layouts/selectable-tags-grid.png",
    config: SelectableTagsGrid.SelectableTagsGridConfig,
    advancedConfig: SelectableTagsGrid.SelectableTagsGridAdvancedConfig,
    component: SelectableTagsGrid.SelectableTagsGridComponent
  },
  ImageWithParagraphGridComponent: {
    type: "ImageWithParagraphGridComponent",
    title: "Image With Paragraph",
    imgSrc: "/static/img/segments/layouts/image-with-paragraph-grid.png",
    config: ImageWithParagraphGrid.ImageWithParagraphGridConfig,
    advancedConfig: ImageWithParagraphGrid.ImageWithParagraphGridAdvancedConfig,
    component: ImageWithParagraphGrid.ImageWithParagraphGridComponent
  },
  OnlyImageGridComponent: {
    type: "OnlyImageGridComponent",
    title: "Only Image",
    imgSrc: "/static/img/segments/layouts/only-image-grid.png",
    config: OnlyImageGrid.OnlyImageGridConfig,
    advancedConfig: OnlyImageGrid.OnlyImageGridAdvancedConfig,
    component: OnlyImageGrid.OnlyImageGridComponent
  }
};
