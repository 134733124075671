import { PureComponent } from "react";
import { componentStyles } from "./animating-board-homepage-first-fold-desktop-component.scss";
import { SIGNUP_BUTTON_TYPE } from "segments/desktop/core-components/button/button-consts";
import FoldingTable from "../folding-table/folding-table-component";
import TableVideo from "../table-video/table-video-component";
import Button from "segments/desktop/core-components/button/button";
import MondayMarkupLanguageComponent from "client/components/system/monday-markup-language/monday-markup-language-component";

export default class AnimatingBoardHomepageFirstFoldDesktopComponent extends PureComponent {
  renderFirstTitle() {
    const { title } = this.props;

    return <MondayMarkupLanguageComponent tagName="h1" className="first-title" text={title} />;
  }

  renderSecondTitle() {
    const { body } = this.props;

    return <MondayMarkupLanguageComponent tagName="h3" className="second-title" text={body} />;
  }

  render() {
    const { videoId } = this.props;

    return (
      <div className="animating-board-homepage-first-fold-desktop-component">
        <div className="header-wrapper">
          {this.renderFirstTitle()}
          {this.renderSecondTitle()}

          <div className="first-fold-signup-button">
            <Button buttonType={SIGNUP_BUTTON_TYPE} buttonColor="dodger-blue" />
          </div>
        </div>

        <div className="table-picture-container">
          <TableVideo videoId={videoId} />
          <FoldingTable />
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
