import css from "styled-jsx/css";

import {
  BACKGROUND_GRAY_COLOR_NAME,
  DARK_WORK_OS_IRIS_COLOR_NAME,
  SPACE_BLUE_COLOR_NAME,
  WHITE_COLOR_NAME,
  DARK_CRM_GREEN_COLOR_NAME,
  DARK_MARKETING_RED_COLOR_NAME,
  DARK_DEV_GREEN_COLOR_NAME,
  DARK_PROJECTS_ORANGE_COLOR_NAME
} from "styles/color-consts";
import colors from "styles/colors";

export const componentStyles = css.global`
  .tab-nav-button {
    font-size: 0.875rem;
    font-weight: 500;
    padding: 7px 16px 9px;
    border-radius: 20px;
    background-color: ${colors[BACKGROUND_GRAY_COLOR_NAME]};
    color: ${colors[DARK_WORK_OS_IRIS_COLOR_NAME]};
    white-space: nowrap;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    &.selected {
      background-color: ${colors[SPACE_BLUE_COLOR_NAME]};
      color: ${colors[WHITE_COLOR_NAME]};
    }
    &.background-gray {
      background-color: ${colors[WHITE_COLOR_NAME]};
      &.selected {
        background-color: ${colors[SPACE_BLUE_COLOR_NAME]};
      }
    }
    &.crm-green{
      background-color: ${colors[WHITE_COLOR_NAME]};
      color: ${colors[DARK_CRM_GREEN_COLOR_NAME]};
      &.selected {
        background-color: ${colors[DARK_CRM_GREEN_COLOR_NAME]};
        color: ${colors[WHITE_COLOR_NAME]};
      }
    }
    &.marketing-red{
      background-color: ${colors[WHITE_COLOR_NAME]};
      color: ${colors[DARK_MARKETING_RED_COLOR_NAME]};
      &.selected {
        background-color: ${colors[DARK_MARKETING_RED_COLOR_NAME]};
        color: ${colors[WHITE_COLOR_NAME]};
      }
    }
    &.projects-orange{
      background-color: ${colors[WHITE_COLOR_NAME]};
      color: ${colors[DARK_PROJECTS_ORANGE_COLOR_NAME]};
      &.selected {
        background-color: ${colors[DARK_PROJECTS_ORANGE_COLOR_NAME]};
        color: ${colors[WHITE_COLOR_NAME]};
      }
    }
    &.dev-green{
      background-color: ${colors[WHITE_COLOR_NAME]};
      color: ${colors[DARK_DEV_GREEN_COLOR_NAME]};
      &.selected {
        background-color: ${colors[DARK_DEV_GREEN_COLOR_NAME]};
        color: ${colors[WHITE_COLOR_NAME]};
      }
    }

  }
`;
