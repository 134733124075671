import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, TABLET_QUERY_MAX_WIDTH } from "constants/vp-sizes";

export const componentStyles = css.global`
  .regular-tabs-component {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    margin: auto;

    .large-screen-component-wrapper {
      display: block;
    }

    .small-screen-component-wrapper {
      display: none;

      .two-third-text-and-asset-component-wrapper {
        .tab-label-container {
          display: flex;
          justify-content: center;

          .tab-label {
            font-weight: 700;
            font-size: 1.375rem;
            position: relative;
            z-index: 1;
            margin-bottom: 50px;

            .tab-label-underline {
              bottom: -10px;
              right: -20px;
              left: -20px;
              height: 5px;
              background-color: blue;
              position: absolute;
              z-index: 2;
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: 60px;
        }

        .full-paragraph {
          text-align: center !important;
        }
      }
    }
  }

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    .regular-tabs-component {
      .large-screen-component-wrapper {
        display: none;
      }

      .small-screen-component-wrapper {
        display: block;
      }
    }
  }
`;
