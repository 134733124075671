import { ALIGN_TYPES } from "constants/align-types";
import { SPACE_BLUE_COLOR_NAME } from "styles/color-consts";
import { sharedDefaultAdvancedProps } from "segments/desktop/shape/layouts/default-props";

const shapeColor = SPACE_BLUE_COLOR_NAME;

export default {
  // Base
  numPoints: 4,
  innerRadius: 100,
  outerRadius: 35,
  x: "0",
  y: "0",
  scale: "1",
  cornerRadius: "0",
  rotation: "45",
  fill: shapeColor,
  align: ALIGN_TYPES.LEFT,
  // Advanced
  ...sharedDefaultAdvancedProps
};
