import ImagesGrid from "segments/desktop/core-components/images-grid/images-grid";
import { ALIGN_TYPES } from "constants/align-types";
import { positionTypes } from "segments/desktop/core-components/images-grid/images-grid-service";
import { SM } from "constants/sizes";
import { componentStyles } from "./multi-templates-templates-component.scss";
import { getFullParagraph, getImageSrcAndAlt } from "../utils";

const MultiTemplatesTemplatesComponent = (props) => {
  const {
    mobileTextAlign,
    solutionsInfo,
    imagePosition,
    textAlign,
    titleSize,
    textSize,
    dynamicProps: { solutions = [] } = {}
  } = props;
  const getSolutions = () => {
    return solutionsInfo.map(
      ({
        overrideTitle,
        overrideDescription,
        overrideImage = {},
        topicText,
        buttonSelect,
        solutionId,
        showTitle,
        showDescription,
        showImageShadow
      }) => {
        const fetchSolution =
          solutions.find((solution) => {
            return solutionId === solution?.id;
          }) || {};
        const { data: { name: title, description, images } = {} } = fetchSolution;
        return {
          ...getImageSrcAndAlt({ images, overrideImage }),
          shadow: showImageShadow,
          fullParagraph: getFullParagraph({
            title,
            description,
            overrideTitle,
            overrideDescription,
            topicText,
            buttonSelect,
            solutionId,
            showTitle,
            showDescription,
            textAlign,
            mobileTextAlign
          })
        };
      }
    );
  };

  return (
    <div className="multi-templates-templates-component">
      <ImagesGrid
        images={getSolutions(solutionsInfo, solutions)}
        isWithParagraph={true}
        imagePosition={imagePosition}
        textAlign={textAlign}
        titleSize={titleSize}
        textSize={textSize}
        numberOfCardsInRow={3}
        fitHeightToImgHeight={true}
      />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

MultiTemplatesTemplatesComponent.defaultProps = {
  solutionsInfo: [],
  paddingTop: "56px",
  paddingBottom: "56px",
  imagePosition: positionTypes.CENTER_CENTER,
  textAlign: ALIGN_TYPES.LEFT,
  titleSize: SM,
  textSize: SM
};

export default MultiTemplatesTemplatesComponent;
