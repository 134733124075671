import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";

export const componentStyles = css.global`
  .cards-grid-desktop-component {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    height: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .grid-image-with-paragraph-component-wrapper {
      width: 300px;
      margin: 16px;
    }
  }
`;
