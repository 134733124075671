import { FIELD_TYPES } from "constants/template-field-type";
import ThemeConfig from "segments/core-configs/theme-config";
import TitleOnlyParagraphFieldsConfig from "segments/desktop/core-components/title-only-paragraph/fields-config.js";

const { textColor, ...selectedTitleOnlyParagraphFields } = TitleOnlyParagraphFieldsConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "Title Only Paragraph",
  fields: {
    ...ThemeConfig,
    ...selectedTitleOnlyParagraphFields
  }
};
