import css from "styled-jsx/css";

export const componentStyles = css`
  .carousel-asset-component {
    margin: auto;

    :global(.image-slide) {
      width: 100%;
      height: auto;
    }
  }
`;
