import dynamic from "next/dynamic";

const AnimatingBoardHomepageFirstFoldComponent = require("./animating-board-homepage-first-fold-component").default;

import AnimatingBoardHomepageFirstFoldConfig from "./animating-board-homepage-first-fold-config";
import AnimatingBoardHomepageFirstFoldAdvancedConfig from "./animating-board-homepage-first-fold-advanced-config";

export default {
  AnimatingBoardHomepageFirstFoldComponent,
  AnimatingBoardHomepageFirstFoldConfig,
  AnimatingBoardHomepageFirstFoldAdvancedConfig
};
