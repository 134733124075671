import SideBySideTextAndAssetComponent from "segments/desktop/text-and-asset/layouts/side-by-side-text-and-asset/side-by-side-text-and-asset-component";
import YoutubeVideoWithPosterComponent from "components/core/youtube-video-with-poster/youtube-video-with-poster-component";
import YoutubeVideoWithPosterComponentDefaultProps from "components/core/youtube-video-with-poster/default-props";
import { componentStyles } from "./youtube-text-and-asset-component.scss";

const YoutubeTextAndAssetComponent = (props) => {
  const { youtubeWithPosterProps, ...restProps } = props;

  return (
    <div className="youtube-text-and-asset-component">
      <SideBySideTextAndAssetComponent
        replaceAssetComponent={YoutubeVideoWithPosterComponent}
        replaceAssetComponentProps={youtubeWithPosterProps}
        {...restProps}
      />

      <style jsx>{componentStyles}</style>
    </div>
  );
};

YoutubeTextAndAssetComponent.defaultProps = {
  ...SideBySideTextAndAssetComponent.defaultProps,
  asset: {},
  youtubeWithPosterProps: { ...YoutubeVideoWithPosterComponentDefaultProps, fitToParent: true }
};

export default YoutubeTextAndAssetComponent;
