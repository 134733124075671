import { FIELD_TYPES } from "constants/template-field-type";
import { buttonColors } from "segments/button-service";

export default {
  buttonColor: {
    type: FIELD_TYPES.COLOR_PICKER,
    label: "Color",
    possibleColors: buttonColors
  },
  buttonText: { type: FIELD_TYPES.TEXT, label: "Text", translatable: true }
};
