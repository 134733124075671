import {
    CRM_PRODUCT_ID,
    MARKETER_PRODUCT_ID,
    PROJECTS_PRODUCT_ID,
    DEV_PRODUCT_ID,
    WORK_MANAGEMENT_PRODUCT_ID
  } from "constants/products";

  import {
    CRM_GREEN_COLOR_NAME,
    DARK_WORK_OS_IRIS_COLOR_NAME,
    DEV_GREEN_COLOR_NAME,
    MARKETING_RED_COLOR_NAME,
    PROJECTS_ORANGE_COLOR_NAME,
  } from "styles/color-consts";

import colors from "styles/colors";
import { hexToRgba } from "utils/general";

export const COLORS_BY_PRODUCTS = {
        [WORK_MANAGEMENT_PRODUCT_ID]: {
            color: colors[DARK_WORK_OS_IRIS_COLOR_NAME],
            backgroundColor: hexToRgba("#babafd", 0.2)
            },
          [MARKETER_PRODUCT_ID] : {
            color: "#9A0550",
            backgroundColor: hexToRgba(colors[MARKETING_RED_COLOR_NAME], 0.08),
          },
          [CRM_PRODUCT_ID] : {
            color: "#025050",
            backgroundColor: hexToRgba(colors[CRM_GREEN_COLOR_NAME], 0.16),
          },
          [PROJECTS_PRODUCT_ID] : {
            color: "#BC5004",
          backgroundColor: hexToRgba(colors[PROJECTS_ORANGE_COLOR_NAME], 0.08),
          },
          [DEV_PRODUCT_ID] : {
            color: "#015727",
            backgroundColor: hexToRgba(colors[DEV_GREEN_COLOR_NAME], 0.08),
          }
};