import { FIELD_TYPES } from "constants/template-field-type";
import buttonConfig from "segments/desktop/core-components/button/fields-config";
import TitleConfig from "segments/desktop/core-components/title/fields-config";
import ParagraphBodyConfig from "segments/desktop/core-components/paragraph/fields-config";
import { asset } from "components/templates-generator/config/components/asset/asset-config";

const { image } = asset.fields;

export default {
  textAlign: {
    type: FIELD_TYPES.COMBO,
    label: "Desktop Text Align",
    options: [
      { value: "left", text: "Left" },
      { value: "center", text: "Center" },
      { value: "right", text: "Right" }
    ]
  },
  mobileTextAlign: {
    type: FIELD_TYPES.COMBO,
    label: "Mobile Text Align (if different)",
    options: [
      { value: "left", text: "Left" },
      { value: "center", text: "Center" },
      { value: "right", text: "Right" }
    ]
  },
  textColor: {
    type: FIELD_TYPES.COLOR_PICKER,
    label: "Old text Color (Don't use - deprecating)",
    possibleColors: ["white", "black"]
  },
  topic: {
    type: FIELD_TYPES.NESTED,
    label: "Topic",
    fields: {
      text: {
        type: FIELD_TYPES.TEXT,
        label: "Text",
        labelDescription: "Selecting an image would replace the text with it",
        translatable: true
      },
      color: { type: FIELD_TYPES.COLOR_PICKER, label: "Text Color" },
      image: {
        ...image,
        fields: {
          ...image.fields,
          height: { type: FIELD_TYPES.TEXT, label: "Height" }
        }
      }
    }
  },
  ...TitleConfig,
  ...ParagraphBodyConfig,
  buttonConfig
};
