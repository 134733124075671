import dynamic from "next/dynamic";

const BlogCardsGridComponent = require("./blog-cards-grid-component").default;

import BlogCardsGridConfig from "./blog-cards-grid-config";
import BlogCardsGridAdvancedConfig from "./blog-cards-grid-advanced-config";

export default {
  BlogCardsGridComponent,
  BlogCardsGridConfig,
  BlogCardsGridAdvancedConfig
};
