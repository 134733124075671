import { componentStyles } from "./product-cards-grid-desktop-component.scss";
import ProductCardGridDekstopComponent from "./product-card-grid-component/product-card-grid-dekstop-component";
import DefaultProps from "./default-props";

const ProductCardsGridDekstopComponent = (props) => {
  const { cards } = props;
  const isAtLeastOneCardHighlighted = cards
    ?.map((card) => card?.isHighlighted)
    ?.reduce((previousValue, currentValue) => previousValue || currentValue, false);

  return (
    <div className="product-cards-grid-desktop-component">
      {cards?.map((card) => (
        <ProductCardGridDekstopComponent {...card} isAtLeastOneCardHighlighted={isAtLeastOneCardHighlighted} />
      ))}
      <style jsx>{componentStyles}</style>
    </div>
  );
};

ProductCardsGridDekstopComponent.defaultProps = DefaultProps;

export default ProductCardsGridDekstopComponent;
