import dynamic from "next/dynamic";

const WithTitleReviewsComponent = require("./with-title-reviews-component").default;

import WithTitleReviewsConfig from "./with-title-reviews-config";
import WithTitleReviewsAdvancedConfig from "./with-title-reviews-advanced-config";

export default {
  WithTitleReviewsComponent,
  WithTitleReviewsConfig,
  WithTitleReviewsAdvancedConfig
};
