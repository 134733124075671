import { FIELD_TYPES } from "constants/template-field-type";
import ThemeConfig from "segments/core-configs/theme-config";
import PersonalTextualInfoConfig from "segments/desktop/core-components/person-textual-info/fields-config";

const { color, ...selectedTextualInfoFields } = PersonalTextualInfoConfig;

export default {
  ...ThemeConfig,
  companyImage: {
    type: FIELD_TYPES.GALLERY,
    defaultImagesKeys: [],
    label: "Select an Image"
  },
  alt: { type: FIELD_TYPES.TEXT, label: "Image description (alt)", translatable: true },
  quote: { type: FIELD_TYPES.TEXT, label: "Quote", textArea: true, translatable: true },
  ...selectedTextualInfoFields
};
