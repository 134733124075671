import { FIELD_TYPES } from "constants/template-field-type";
import LogosTitleFieldsConfig from "segments/desktop/logos/components/logos-title/fields-config";
import LogosFieldsConfig from "segments/desktop/core-components/logos/fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Bottom",
  fields: {
    ...LogosTitleFieldsConfig,
    logos: {
      type: FIELD_TYPES.NESTED,
      label: "Logos",
      fields: LogosFieldsConfig
    }
  }
};
