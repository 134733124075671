import css from "styled-jsx/css";

export const componentStyles = css.global`
  .iframe-core-component-wrapper {
    width: 100%;
    display: flex;
    .iframe-core-component {
      width: 100%;
    }
  }
`;
