import css from "styled-jsx/css";

export const componentStyles = css`
  .person-textual-info {
    font-size: 1.125rem;
    line-height: 30px;

    .person-name {
      font-weight: 700;
    }
  }

  @media (max-width: 720px) {
    .person-textual-info {
      font-size: 1rem;
      line-height: 30px;
    }
  }
`;
