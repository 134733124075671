import { FIELD_TYPES } from "constants/template-field-type";
import FullBackgroundTextAndAssetFieldsConfig from "segments/desktop/core-components/full-background-text-and-asset/fields-config";
import TopbarConfig from "segments/core-configs/topbar-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Full Background",
  fields: {
    ...TopbarConfig,
    textAndAsset: {
      type: FIELD_TYPES.NESTED,
      label: "Text And Asset",
      fields: FullBackgroundTextAndAssetFieldsConfig
    }
  }
};
