import { FIELD_TYPES } from "constants/template-field-type";
import IframeFieldsConfig from "segments/desktop/core-components/iframe/fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Basic Iframe",
  fields: {
    ...IframeFieldsConfig,
    width: {
      type: FIELD_TYPES.TEXT,
      label: "Width"
    }
  }
};
