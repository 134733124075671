// plop_marker:layouts_import
import YoutubeTextAndAsset from "./youtube-text-and-asset";
import SideBySideWithIconsTextAndAsset from "./side-by-side-with-icons-text-and-asset";
import BoardTabTextAndAsset from "./board-tab-text-and-asset";
import YoutubeTwoThirdsTextAndAsset from "./youtube-two-thirds-text-and-asset";
import AsymmetricSideBySideTextAndAsset from "./asymmetric-side-by-side-text-and-asset";
import TestimonialsTextAndAsset from "./testimonials-text-and-asset";
import SideBySideWithBulletsTextAndAsset from "./side-by-side-with-bullets-text-and-asset";
import TwoThirdsTextAndAsset from "./two-thirds-text-and-asset";
import FullBackgroundTextAndAsset from "./full-background-text-and-asset";
import SideBySideWithBackgroundTextAndAsset from "./side-by-side-with-background-text-and-asset";
import SideBySideTextAndAsset from "./side-by-side-text-and-asset";

export default {
  // plop_marker:layouts_add_layout
  YoutubeTextAndAsset,
  SideBySideWithIconsTextAndAsset,
  BoardTabTextAndAsset,
  TwoThirdsTextAndAsset,
  FullBackgroundTextAndAsset,
  SideBySideWithBackgroundTextAndAsset,
  SideBySideTextAndAsset,
  YoutubeTwoThirdsTextAndAsset,
  AsymmetricSideBySideTextAndAsset,
  SideBySideWithBulletsTextAndAsset,
  TestimonialsTextAndAsset
};
