import SquareTags from "segments/desktop/core-components/square-tags/square-tags";
import { componentStyles } from "./square-tags-grid-component.scss";
import DefaultProps from "./default-props.js";

const SquareTagsGridComponent = (props) => {
  return (
    <div className="square-tags-grid-component">
      <SquareTags {...props} />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

SquareTagsGridComponent.defaultProps = DefaultProps;

export default SquareTagsGridComponent;
