import { Rect } from "react-konva";
import { getShapeProps } from "components/templates/dynamic-template/segments-layout-list/canvas/helpers";
import { getFillProps, getRelativeToElementY } from "segments/desktop/shape/shapes-service";
import { useMemo } from "react";

const Rectangle = (props) => {
  const { componentType, windowHeight, relativeTo, isPreview } = props;
  const shapeProps = getShapeProps(props, componentType);

  const relativeToElementY = useMemo(() => getRelativeToElementY({ relativeTo, isPreview }), [
    windowHeight,
    relativeTo,
    isPreview
  ]);

  return <Rect {...shapeProps} {...getFillProps(props)} y={shapeProps.y + relativeToElementY} />;
};

export default Rectangle;
