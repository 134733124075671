import { FIELD_TYPES } from "constants/template-field-type";
import { XS, SM, MD, LG, LGR, XL, XXL } from "constants/sizes";

export default {
  titleSize: {
    type: FIELD_TYPES.COMBO,
    label: "Title Size",
    options: [
      { value: XS,  text: "22px (formerly 24px)" },
      { value: SM,  text: "28px (formerly 32px)" },
      { value: MD,  text: "36px (formerly 40px)" },
      { value: LG,  text: "44px (formerly 50px)" },
      { value: LGR, text: "54px (formerly 60px)" },
      { value: XL,  text: "64px (formerly 72px)" },
      { value: XXL, text: "72px (formerly 80px)" }
    ]
  },
  titleMinWidth: {
    type: FIELD_TYPES.TEXT,
    label: "Title min width"
  },
  titleWidth: {
    type: FIELD_TYPES.TEXT,
    label: "Title max width"
  }
};
