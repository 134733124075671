import { FIELD_TYPES } from "constants/template-field-type";

export default {
  addDarkOverlay: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Add Dark Overlay On Video"
  },
  hideBlackBars: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Hide Black Bars"
  }
};
