import { PureComponent } from "react";
import { componentStyles } from "./button-image-tags-grid-component.scss";
import ButtonImageTag from "./components/button-image-tag/button-image-tag";

export default class ButtonImageTagsGridComponent extends PureComponent {
  renderButtonTags() {
    const { gridButtonTags, isOpenLinkInNewTab } = this.props;
    return gridButtonTags.map((buttonTag, index) => {
      return (
        <li className="grid-button-container" key={index}>
          <ButtonImageTag {...buttonTag} isOpenLinkInNewTab={isOpenLinkInNewTab} />
        </li>
      );
    });
  }

  render() {
    return (
      <div className="button-image-tags-grid-component">
        <ul className="grid-button-list">{this.renderButtonTags()}</ul>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

ButtonImageTagsGridComponent.defaultProps = {
  isOpenLinkInNewTab: true,
  gridButtonTags: [
    {
      tagImg: { src: "/static/img/tags/dashboards.png" },
      tagText: "Dashboards"
    },
    {
      tagImg: { src: "/static/img/tags/integrations.png" },
      tagText: "Integrations"
    },
    {
      tagImg: { src: "/static/img/tags/forms.png" },
      tagText: "Forms"
    },
    {
      tagImg: { src: "/static/img/tags/calendar_view.png" },
      tagText: "Calendar View"
    },
    {
      tagImg: { src: "/static/img/tags/automations.png" },
      tagText: "Automations"
    },
    {
      tagImg: { src: "/static/img/tags/timeline.png" },
      tagText: "Timeline View"
    },
    {
      tagImg: { src: "/static/img/tags/kanban.png" },
      tagText: "Kanban View"
    },
    {
      tagImg: { src: "/static/img/tags/notifications.png" },
      tagText: "Notifications"
    },
    {
      tagImg: { src: "/static/img/tags/workload.png" },
      tagText: "Workload"
    },
    {
      tagImg: { src: "/static/img/tags/subitems.png" },
      tagText: "Subitems (In beta)"
    },
    {
      tagImg: { src: "/static/img/tags/updates.png" },
      tagText: "Updates"
    },
    {
      tagImg: { src: "/static/img/tags/mapview.png" },
      tagText: "Map View"
    },
    {
      tagImg: { src: "/static/img/tags/guests.png" },
      tagText: "Guests Permissions"
    },
    {
      tagImg: { src: "/static/img/tags/timetracking.png" },
      tagText: "Time Tracking"
    },
    {
      tagImg: { src: "/static/img/tags/filesharing.png" },
      tagText: "File Sharing"
    },
    {
      tagImg: { src: "/static/img/tags/workspaces.png" },
      tagText: "Workspaces"
    }
  ]
};
