import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";

export const componentStyles = css`
  .review-cards-reviews-component {
    max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
    margin: auto;

    :global(.full-paragraph-component .full-paragraph-wrapper) {
      max-width: unset;
    }
    .cards {
      margin: auto;
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
`;
