import Tag from "segments/desktop/core-components/tag/tag";

import { componentStyles } from "./tag-items-grid-component.scss";

const TagItemsGridComponent = (props) => {
  const { gridTags = [], width, gridTagsBackgroundColor, gridTagsImageHeight } = props;

  const renderTag = (gridTag, index) => {
    return (
      <div className="grid-tag-wrapper" key={index}>
        <Tag {...gridTag} tagBackgroundColor={gridTagsBackgroundColor} tagImageHeight={gridTagsImageHeight}/>
        <style jsx>{componentStyles}</style>
      </div>
    );
  };

  return (
    <div className="tag-items-grid-component" style={{ width }}>
      {gridTags.map(renderTag)}
      <style jsx>{componentStyles}</style>
    </div>
  );
};

TagItemsGridComponent.defaultProps = {
  paddingTop: "16px",
  paddingBottom: "16px",
  gridTags: [
    {
      tagText: "Projects",
      tagIconsrc:
        "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/products/product-icons/icons_3d/Projects_icon_circle.png"
    },
    {
      tagText: "Marketing",
      tagIconsrc:
        "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/products/product-icons/icons_3d/Marketing_icon_circle.png"
    },
    {
      tagText: "CRM",
      tagIconsrc:
        "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/products/product-icons/icons_3d/CRM_icon_circle.png"
    },
    {
      tagText: "Software",
      tagIconsrc:
        "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/products/product-icons/icons_3d/software_icon_circle.png"
    },
    {
      tagText: "Creative & design",
      tagIconsrc:
        "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/products/product-icons/icons_3d/Creative_design_icon_circle.png"
    },
    {
      tagText: "HR",
      tagIconsrc:
        "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/products/product-icons/icons_3d/HR_icon_circle.png"
    },
    {
      tagText: "Operations",
      tagIconsrc:
        "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/products/product-icons/icons_3d/Operations_icon_circle.png"
    },
    {
      tagText: "IT",
      tagIconsrc:
        "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/products/product-icons/icons_3d/IT_icon_circle.png"
    },
    {
      tagText: "200+ workflows",
      tagIconsrc:
        "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/products/product-icons/icons_3d/200_workflows_icon_circle.png"
    }
  ]
};

export default TagItemsGridComponent;
