import {
  signupForm,
  socialButtons,
  collaborationWith,
  onePagerTextPart,
  onePagerImageWithBackgroundPart,
  footer,
  topbar
} from "../components";
import { FIELD_TYPES } from "constants/template-field-type";
import SocialProof from "components/templates-generator/config/segments/social-proof/social-proof-config";
import ShortFooter from "components/templates-generator/config/segments/short-footer/short-footer-config";
import { defaultData } from "./default-data";

export const ONE_PAGER_WITH_IMAGE_TEMPLATE_CONFIG = {
  topbar,
  footer,
  socialButtons,
  imageWithBackgroundPart: onePagerImageWithBackgroundPart,
  textPart: onePagerTextPart,
  signupForm,
  collaborationWith,
  SocialProof: {
    type: FIELD_TYPES.NESTED,
    label: "Social Proof",
    fields: {
      showSocialProof: { type: FIELD_TYPES.BOOLEAN, label: "Show Social Proof" },
      content: SocialProof
    }
  },
  ShortFooter: {
    type: FIELD_TYPES.NESTED,
    label: "Short Footer",
    fields: {
      showShortFooter: { type: FIELD_TYPES.BOOLEAN, label: "Show Short Footer" },
      content: ShortFooter
    }
  },
  defaultData
};
