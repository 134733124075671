import { PureComponent } from "react";
import { componentStyles } from "./basic-button-component.scss";
import Button from "segments/desktop/core-components/button/button";
import classnames from "classnames";

export default class BasicButtonComponent extends PureComponent {
  render() {
    const { textAlign, buttonConfig } = this.props;
    return (
      <div className="basic-button-component">
        <div className={classnames("button-wrapper", textAlign)}>
          <Button {...buttonConfig} />
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

BasicButtonComponent.defaultProps = {
  paddingTop: "24px",
  paddingBottom: "24px",
  buttonConfig: { ...Button.defaultProps }
};
