import dynamic from "next/dynamic";

const AsymmetricEnterpriseComponent = require("./asymmetric-enterprise-component").default;

import AsymmetricEnterpriseConfig from "./asymmetric-enterprise-config";
import AsymmetricEnterpriseAdvancedConfig from "./asymmetric-enterprise-advanced-config";

export default {
  AsymmetricEnterpriseComponent,
  AsymmetricEnterpriseConfig,
  AsymmetricEnterpriseAdvancedConfig
};
