import { FIELD_TYPES } from "constants/template-field-type";
import SideBySideTextAndAssetFieldsConfig from "segments/desktop/core-components/side-by-side-text-and-asset/fields-config";
import TopbarConfig from "segments/core-configs/topbar-config";

const {
  swapTextAndAssetPosition,
  background,
  ...selectedSideBySideTextAndAssetFieldsConfig
} = SideBySideTextAndAssetFieldsConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "Side By Side",
  fields: {
    ...TopbarConfig,
    textAndAsset: {
      type: FIELD_TYPES.NESTED,
      label: "Text And Asset",
      fields: selectedSideBySideTextAndAssetFieldsConfig
    }
  }
};
