import Layouts from "./layouts";
const {
  // plop_marker:segment_index_import_layout
  RichTextBlog,
} = Layouts;

export default { 
  // plop_marker:segment_index_add_layout
  RichTextBlogComponent: {
    type: "RichTextBlogComponent",
    title: "Rich Text",
    imgSrc: "/static/img/segments/layouts/rich-text-blog.png",
    config: RichTextBlog.RichTextBlogConfig,
    advancedConfig: RichTextBlog.RichTextBlogAdvancedConfig,
    component: RichTextBlog.RichTextBlogComponent
  },
};