import Colors from "styles/colors";

import classnames from "classnames";
import { componentStyles } from "./board-tab.scss";

const BoardTab = (props) => {
  const {
    tab,
    tabIndex,
    onTabClicked,
    onKeyDown,
    onMouseOver,
    onMouseLeave,
    isSelected,
    isHovered,
    isSmallTabs
  } = props;
  const { label, isMainTab, tabImage, tabImageSelected, color } = tab;

  const tabImageSrc = (isSelected || isHovered) && tabImageSelected ? tabImageSelected : tabImage;

  const selectionIndicator = (tabColor) => {
    return (
      <div className="selection-indicator" style={{ color: Colors[tabColor] }}>
        <div className="curved-corner bottom" />
        <div className="curved-corner top" />
      </div>
    );
  };

  return (
    <div
      className={classnames("board-tab", { "main-tab": isMainTab, "small-tabs": isSmallTabs })}
      onClick={() => onTabClicked(tabIndex)}
      onKeyDown={(e) => onKeyDown(e, tabIndex)}
      onMouseEnter={() => onMouseOver(tabIndex)}
      onMouseLeave={onMouseLeave}
      key={label}
      role="tab"
      aria-selected={isSelected}
      tabIndex={0}
    >
      {isSelected && !isMainTab && selectionIndicator(color)}
      <div className="tab-image-wrapper">
        <img className="tab-image" src={tabImageSrc} />
      </div>
      <div className="tab-text">{label}</div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default BoardTab;
