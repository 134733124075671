import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";

export const componentStyles = css`
  .full-paragraph-and-tags-homepage-first-fold-component {
    max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
    margin: auto;
    display: flex;
    flex-direction: column;
    :global(.full-paragraph-component) {
      :global(.title-wrapper) {
        :global(.core-title) {
          font-weight: 600;
        }
      }
    }
    :global(.additional-button-text.below) {
      color: white;
      margin-top: 16px;
    }
  }
`;
