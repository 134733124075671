import { FIELD_TYPES } from "constants/template-field-type";
import TitleOnlyFieldsConfig from "segments/desktop/core-components/title-only-paragraph/fields-config";
import ReviewsFieldsConfig from "segments/desktop/core-components/reviews/fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "With Title",
  fields: {
    titleOnlyParagraph: { type: FIELD_TYPES.NESTED, label: "Title Only", fields: TitleOnlyFieldsConfig },
    ...ReviewsFieldsConfig
  }
};
