import { PureComponent } from "react";
import { componentStyles } from "./full-background-hero-component.scss";
import Topbar from "/components/core/top-bar-new/top-bar-component";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import FullBackgroundTextAndAsset from "segments/desktop/core-components/full-background-text-and-asset/full-background-text-and-asset";

export default class FullBackgroundHeroComponent extends PureComponent {
  render() {
    const { textAndAsset, topbar } = this.props;

    return (
      <div className="full-background-hero-component">
        <div className="topbar-wrapper">
          <Topbar maxWidth="100vw" {...topbar} />
        </div>

        <FullBackgroundTextAndAsset {...textAndAsset} />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

FullBackgroundHeroComponent.defaultProps = {
  topbar: { showMenu: true },
  textAndAsset: {
    ...FullBackgroundTextAndAsset.defaultProps,
    backgroundImage: {
      src: "/static/img/segment-layout-default-images/hero/balloons-with-pink-background.png"
    }
  }
};
