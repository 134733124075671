import dynamic from "next/dynamic";

const DevelopersAppsMenuStaticComponent = require("./developers-apps-menu-static-component").default;

import DevelopersAppsMenuStaticConfig from "./developers-apps-menu-static-config";
import DevelopersAppsMenuStaticAdvancedConfig from "./developers-apps-menu-static-advanced-config";

export default {
  DevelopersAppsMenuStaticComponent,
  DevelopersAppsMenuStaticConfig,
  DevelopersAppsMenuStaticAdvancedConfig
};
