// plop_marker:layouts_import
import LogosAndParagraphLogos from "./logos-and-paragraph-logos";
import SlimLogos from "./slim-logos";
import AllLogosLogos from "./all-logos-logos";
import TopLogos from "./top-logos";
import BottomLogos from "./bottom-logos";
import ImagesLogos from "./images-logos";

export default {
  // plop_marker:layouts_add_layout
  LogosAndParagraphLogos,
  SlimLogos,
  AllLogosLogos,
  TopLogos,
  BottomLogos,
  ImagesLogos
};
