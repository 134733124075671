import { FIELD_TYPES } from "constants/template-field-type";
import gridImageFieldsConfig from "segments/desktop/core-components/grid-image/fields-config";
import {
  REGULAR_BUTTON_TYPE,
  SECONDARY_BUTTON_TYPE,
  SIGNUP_BUTTON_TYPE
} from "segments/desktop/core-components/button/button-consts";
import RegularButtonFieldsConfig from "segments/desktop/core-components/button/regular-button/fields-config";
import SecondaryButtonFieldsConfig from "segments/desktop/core-components/button/secondary-button/fields-config";
import SignupButtonFieldsConfig from "segments/desktop/core-components/button/signup-button/fields-config";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";

const { ...restGridFields } = gridImageFieldsConfig;

export default {
  ...restGridFields,
  showParagraph: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Show paragraph"
  },
  fullParagraph: {
    type: FIELD_TYPES.NESTED,
    label: "Full Paragraph",
    fields: {
      ...FullParagraphFieldsConfig,
      buttonConfig: {
        type: FIELD_TYPES.CONDITIONAL_NESTED,
        label: "Button",
        conditionalKeyName: "buttonType",
        options: [
          { text: REGULAR_BUTTON_TYPE, fieldsConfig: RegularButtonFieldsConfig },
          { text: SECONDARY_BUTTON_TYPE, fieldsConfig: SecondaryButtonFieldsConfig },
          { text: SIGNUP_BUTTON_TYPE, fieldsConfig: SignupButtonFieldsConfig }
        ]
      }
    }
  }
};
