import { FIELD_TYPES } from "constants/template-field-type";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import { BACKGROUND_COLOR_OPTIONS } from "segments/segments-service";

import { XS, SM, MD, LG, LGR, XL, XXL } from "constants/sizes";
import AssetFieldsConfig from "components/core/asset/fields-config";

const { image } = AssetFieldsConfig;
const { src } = image.fields;
const { textColor, ...selectedFullParagraphFields } = FullParagraphFieldsConfig;

export default {
  swapTextAndAssetPosition: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Swap Text And Asset Position - Desktop"
  },
  mobileSwapTextAndAssetPosition: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Swap Form and Texts position - Mobile"
  },
  hideParagraph: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Hide paragraph"
  },
  paragraphVerticalAlignment: {
    type: FIELD_TYPES.COMBO,
    label: "Paragraph Vertical Alignment",
    options: ["top", "center", "bottom"]
  },
  alignImageToScreenEdge: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Align Image To Screen Edge"
  },
  background: {
    type: FIELD_TYPES.NESTED,
    label: "Background",
    fields: {
      width: {
        type: FIELD_TYPES.COMBO,
        label: "Width",
        options: ["50%", "75%", "100%"]
      },
      color: {
        type: FIELD_TYPES.COLOR_PICKER,
        label: "Color",
        possibleColors: BACKGROUND_COLOR_OPTIONS
      },
      verticalPadding: {
        type: FIELD_TYPES.TEXT,
        label: "Vertical Padding"
      },
      backgroundImage: {
        ...image,
        label: "Background image",
        fields: {
          backgroundPosition: {
            type: FIELD_TYPES.COMBO,
            label: "Background position",
            options: ["left", "center", "right"]
          },
          ...image.fields,
          src: {
            ...src,
            getFullUrl: true
          }
        }
      }
    }
  },
  fullParagraph: {
    type: FIELD_TYPES.NESTED,
    label: "Full Paragraph",
    fields: selectedFullParagraphFields
  },
  titleSize: {
    type: FIELD_TYPES.COMBO,
    label: "Title Size",
    options: [
      { value: XS, text: "22px (formerly 24px)" },
      { value: SM, text: "28px (formerly 32px)" },
      { value: MD, text: "36px (formerly 40px)" },
      { value: LG, text: "44px (formerly 50px)" },
      { value: LGR, text: "54px (formerly 60px)" },
      { value: XL, text: "64px (formerly 72px)" },
      { value: XXL, text: "72px (formerly 80px)" }
    ]
  },
  asset: {
    type: FIELD_TYPES.NESTED,
    label: "Asset",
    fields: {
      position: {
        type: FIELD_TYPES.COMBO,
        label: "Position",
        options: ["top", "center", "bottom"]
      },
      fitToContainer: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Fit to container"
      },
      ...AssetFieldsConfig
    }
  }
};
