import paragraphAdvancedConfig from "segments/desktop/core-components/paragraph/advanced-fields-config";
import sideBySideTextAndAssetConfig from "segments/desktop/core-components/side-by-side-text-and-asset/fields-config";
import sideBySideTextAndAssetAdvencedConfig from "segments/desktop/core-components/side-by-side-text-and-asset/advanced-field-config"
import { FIELD_TYPES } from "constants/template-field-type";
import AssetFieldsConfig from "components/core/asset/fields-config";
import { SIGNUP_BUTTON_TYPE, SIGNUP_COMBO_BUTTONS_TYPE } from "segments/desktop/core-components/button//button-consts";
import fullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import sharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";

export const selectTemplateConfig = {
  solutionId: {
    type: FIELD_TYPES.SOLUTION,
    label: "Template"
  },
  showTitle: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Show title"
  },
  showDescription: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Show description"
  },
  showImageShadow: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Show image shadow"
  },
  overrideTitle: {
    type: FIELD_TYPES.TEXT,
    label: "Override Default Template Title"
  },
  overrideDescription: {
    type: FIELD_TYPES.TEXT,
    label: "Override Default Template Description",
    textArea: true
  },
  overrideImage: {
    type: FIELD_TYPES.NESTED,
    label: "Override Default Image",
    fields: AssetFieldsConfig
  },
  topicText: {
    type: FIELD_TYPES.TEXT,
    label: "Topic"
  },
  buttonSelect: {
    type: FIELD_TYPES.CONDITIONAL_NESTED,
    label: "Button",
    conditionalKeyName: "buttonType",
    options: [
      {
        type: FIELD_TYPES.NESTED,
        text: SIGNUP_BUTTON_TYPE,
        fieldsConfig: {
          signupButtonText: {
            type: FIELD_TYPES.TEXT,
            label: "Signup Button Text"
          }
        }
      },
      {
        type: FIELD_TYPES.NESTED,
        text: SIGNUP_COMBO_BUTTONS_TYPE,
        fieldsConfig: {
          signupButtonText: {
            type: FIELD_TYPES.TEXT,
            label: "Signup Button Text"
          },
          seconderyButtonText: {
            type: FIELD_TYPES.TEXT,
            label: "Secondery Button Text"
          }
        }
      }
    ]
  }
};

export const advancedTemplateConfig = {
  textSize: { ...paragraphAdvancedConfig.textSize, label: "Template Description Text Size" },
  titleSize: { ...sideBySideTextAndAssetConfig.titleSize, label: "Template Title Text Size" },
  mobileTextAlign: fullParagraphFieldsConfig.mobileTextAlign,
  hideOnMobile: sharedAdvancedFieldsConfig.hideOnMobile,
  alignToLogo: { ...sideBySideTextAndAssetAdvencedConfig.alignToLogo }
};
