import css from "styled-jsx/css";
import Colors from "/styles/colors";

const BOX_ELEMENTS_MARGIN = "40px 0";
const BOX_BORDER_RADIUS = "8px";
const BOX_BORDER = `1px solid ${Colors["border-gray"]}`;

export const componentStyles = css.global`
  .markdown {
    font-size: 1rem;

    strong {
      font-weight: 700;
    }

    h1 {
      font-size: 2.925rem;
      margin: 40px 0;
      font-weight: 500;
    }

    h2 {
      font-size: 1.75rem;
      font-weight: 700;
      margin-top: 56px;
    }

    h3 {
      margin-top: 40px;
      font-weight: 700;
      font-size: 1.125rem;
    }

    h4 {
      font-weight: 700;
      font-size: 1.125rem;
      margin-bottom: 20px;
    }

    p {
      line-height: 30px;
    }

    ul,
    ol {
      line-height: 40px;
      padding-inline-start: 20px;
    }

    a {
      color: ${Colors["brand-blue"]};
      text-decoration: none;
    }

    blockquote {
      border-radius: ${BOX_BORDER_RADIUS};
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
      border-left: ${`${Colors["dodger-blue"]} 6px solid`};
      margin: ${BOX_ELEMENTS_MARGIN};

      p {
        padding: 20px 32px;
      }
    }

    img {
      margin-top: 40px;
      border-radius: ${BOX_BORDER_RADIUS};
      border: ${BOX_BORDER};
      max-width: 100%;
    }

    code {
      background-color: ${Colors["riverstone"]};
      border-radius: 4px;
      padding: 2px 6px 4px 6px;
    }

    pre {
      border: ${BOX_BORDER};
      border-radius: ${BOX_BORDER_RADIUS};
      padding: 12px 24px !important;
      background: none !important;
      margin: ${BOX_ELEMENTS_MARGIN} !important;
      overflow: auto !important;
      max-width: 900px !important;

      code {
        span {
          font-family: monospace, monospace;
        }
      }
    }

    table {
      border: ${BOX_BORDER};
      border-radius: ${BOX_BORDER_RADIUS};
      border-collapse: separate;
      border-spacing: 0;
      margin: ${BOX_ELEMENTS_MARGIN};

      th {
        border-bottom: ${BOX_BORDER};
        text-align: left;
      }

      th,
      td {
        padding: 20px;
      }
    }
  }
`;
