import { FIELD_TYPES } from "constants/template-field-type";
import SideBySideTextAndAssetFieldsConfig from "segments/desktop/core-components/side-by-side-text-and-asset/fields-config";
import { BACKGROUND_COLOR_OPTIONS } from "segments/segments-service";
import { textColorOptions } from "constants/color-picker-constants";

const { fullParagraph, asset, titleSize, ...selectedSideBySideTextAndAssetFieldsConfig } =
  SideBySideTextAndAssetFieldsConfig;

const customFullParagraph = {
  ...fullParagraph,
  fields: {
    ...fullParagraph.fields,
    paragraphVerticalColoredLineColor: {
      type: FIELD_TYPES.COLOR_PICKER,
      label: "Paragraph vertical colored line color",
      possibleColors: BACKGROUND_COLOR_OPTIONS
    }
  }
};

export default {
  type: FIELD_TYPES.NESTED,
  label: "Side By Side With Paragraphs",
  fields: {
    ...selectedSideBySideTextAndAssetFieldsConfig,
    primaryParagraph: { ...customFullParagraph, label: "Primary paragraph" },
    leftSectionBottomText: {
      type: FIELD_TYPES.TEXT,
      label: "Left section bottom text"
    },
    leftSectionBottomTextColor: {
      type: FIELD_TYPES.COLOR_PICKER,
      label: "Left section bottom text color",
      possibleColors: textColorOptions
    },
    paragraphs: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Right section paragraphs",
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          fullParagraph: {
            ...customFullParagraph,
            label: "Full paragraph"
          }
        }
      }
    }
  }
};
