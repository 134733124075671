import { FIELD_TYPES } from "constants/template-field-type";

import TestimonialsCardFieldsConfig from "../../components/fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Plain Classic",
  fields: {
    testimonialCard: {
      type: FIELD_TYPES.NESTED,
      label: "Testominal Card",
      fields: TestimonialsCardFieldsConfig
    }
  }
};
