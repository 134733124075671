import Layouts from "./layouts";
const {
  // plop_marker:segment_index_import_layout
  EmbeddedBoardIframe,
  SideBySideHubspotForm,
  IframeDynamicWidthIframe
} = Layouts;

export default {
  // plop_marker:segment_index_add_layout
  EmbeddedBoardIframeComponent: {
    type: "EmbeddedBoardIframeComponent",
    title: "Embedded Board",
    imgSrc: "/static/img/segments/layouts/embedded-board-iframe.png",
    config: EmbeddedBoardIframe.EmbeddedBoardIframeConfig,
    advancedConfig: EmbeddedBoardIframe.EmbeddedBoardIframeAdvancedConfig,
    component: EmbeddedBoardIframe.EmbeddedBoardIframeComponent
  },
  SideBySideHubspotFormComponent: {
    type: "SideBySideHubspotFormComponent",
    title: "Hubspot old form (Use only in case of custom form id)",
    imgSrc: "/static/img/segments/layouts/side-by-side-hubspot-form.png",
    config: SideBySideHubspotForm.SideBySideHubspotFormConfig,
    advancedConfig: SideBySideHubspotForm.SideBySideHubspotFormAdvancedConfig,
    component: SideBySideHubspotForm.SideBySideHubspotFormComponent
  },
  IframeDynamicWidthIframeComponent: {
    type: "IframeDynamicWidthIframeComponent",
    title: "Basic Iframe",
    imgSrc: "/static/img/segments/layouts/iframe-dynamic-width-iframe.png",
    config: IframeDynamicWidthIframe.IframeDynamicWidthIframeConfig,
    advancedConfig: IframeDynamicWidthIframe.IframeDynamicWidthIframeAdvancedConfig,
    component: IframeDynamicWidthIframe.IframeDynamicWidthIframeComponent
  }
};
