import { PureComponent } from "react";
import PictureComponent from "/components/core/picture/picture-component";
import { componentStyles } from "./integration-badge-component.scss";
import {
  MIN_SCALE_VALUE,
  MAX_SCALE_VALUE,
  MIN_SCALE_TIMEOUT,
  MAX_SCALE_TIMEOUT,
  MIN_TRANSLATE_Y_VALUE,
  MAX_TRANSLATE_Y_VALUE,
  MIN_TRANSLATE_Y_TIMEOUT,
  MAX_TRANSLATE_Y_TIMEOUT
} from "../../constants";

export default class IntegrationBadge extends PureComponent {
  componentDidMount() {
    this.animate();
  }

  animate = () => {
    // Scale animation
    this.animatePicture({
      repeat: true,
      min: MIN_SCALE_VALUE,
      max: MAX_SCALE_VALUE,
      attribute: "scale",
      timeout: () => this.randomNumber(MIN_SCALE_TIMEOUT, MAX_SCALE_TIMEOUT)
    });

    // Floating animation
    this.animatePicture({
      repeat: true,
      min: MIN_TRANSLATE_Y_VALUE,
      max: MAX_TRANSLATE_Y_VALUE,
      attribute: "translateY",
      unit: "px",
      timeout: () => this.randomNumber(MIN_TRANSLATE_Y_TIMEOUT, MAX_TRANSLATE_Y_TIMEOUT)
    });
  };

  animatePicture = (options) => {
    const randomTimeout = options.timeout();

    setTimeout(() => {
      window.requestAnimationFrame(() => {
        const random = this.randomNumber(options.min, options.max);

        if (this.pictureContainer) {
          this.pictureContainer.style.transform = `${options.attribute}(${random}${options.unit || ""})`;
        }

        if (options.repeat) {
          this.animatePicture(options);
        }
      });
    }, randomTimeout);
  };

  randomNumber(min, max) {
    return Math.random() * (max - min) + min;
  }

  setPictureContainer = (element) => {
    this.pictureContainer = element;
  };

  render() {
    const { path } = this.props;

    return (
      <div className="integration-badge-component" ref={this.setPictureContainer}>
        <PictureComponent className="integration-badge-image" src={path} />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
