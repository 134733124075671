import { FIELD_TYPES } from "constants/template-field-type";
import AssetFieldsConfig from "components/core/asset/fields-config";

const { video } = AssetFieldsConfig;
const { loop, playWhenVisible, ...rest } = video.fields;

export default {
  type: FIELD_TYPES.NESTED,
  label: "Masonry",
  fields: {
    assets: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Assets",
      maxLimit: 9,
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          asset: {
            type: FIELD_TYPES.NESTED,
            label: "Asset",
            fields: {
              ...AssetFieldsConfig,
              video: {
                type: FIELD_TYPES.NESTED,
                label: "Video",
                fields: {
                  ...rest,
                  startAfter: {
                    type: FIELD_TYPES.TEXT,
                    label: "Start After"
                  },
                  poster: {
                    type: FIELD_TYPES.GALLERY,
                    label: "Select an poster"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};
