import { PureComponent } from "react";
import classnames from "classnames";
import BaseTemplate from "../base-template";
import SegmentsLayoutListComponent from "./segments-layout-list/segments-layout-list-component";
import CoreAddonsComponent from "/components/addons/core-addons/core-addons-component";
import componentStyles from "./dynamic-template.scss";

export default class DynamicTemplate extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      rootEl: null
    }
  }

  render() {
    const { rootEl } = this.state;
    const { pageConfig, segmentComponents, isPreview, exitPopup, dynamicData } = this.props;
    const { segments, isMobile, templateClassName, exitPopupConfig } = pageConfig;
    return (
      <BaseTemplate {...this.props} {...pageConfig}>
        <div ref={el => this.setState({ rootEl: el })} className={classnames("dynamic-template", templateClassName)}>
          <SegmentsLayoutListComponent
            isMobile={isMobile}
            isPreview={isPreview}
            segments={segments}
            dynamicData={dynamicData}
            segmentComponents={segmentComponents}
            rootEl={rootEl}
          />

          <CoreAddonsComponent exitPopup={exitPopup} exitPopupConfig={exitPopupConfig} />
        </div>
        <style jsx>{componentStyles}</style>
      </BaseTemplate>
    );
  }
}

DynamicTemplate.defaultProps = {
  exitPopup: true,
  segmentComponents: []
};
