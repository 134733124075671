import css from "styled-jsx/css";
import { BACKGROUND_GRAY_COLOR_NAME, WHITE_COLOR_NAME } from "styles/color-consts";
import Colors from "styles/colors";

const BORDER_RADIUS = "8px";
const COMPONENT_TOP_MARGIN = 80;

const BORDER = `2px solid ${Colors[BACKGROUND_GRAY_COLOR_NAME]}`;

export const componentStyles = css.global`
  .welcome-back-homepage-first-fold-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 24px;

    .welcome-back-first-fold-message {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: ${COMPONENT_TOP_MARGIN} + px;

      .monday-logo {
        display: flex;
        justify-content: center;

        .picture-component {
          display: flex;
          .monday-logo-picture {
            width: 62px;
          }
        }
      }

      .welcome-text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;

        font-size: 2.25rem;
        color: ${Colors[WHITE_COLOR_NAME]};
        .welcome-text {
          font-weight: 300;
        }
        .user-name {
          font-weight: 700;
          padding-bottom: 8px;
          transition: opacity 0.1s;
          opacity: 1;
        }
      }
    }

    .pick-up-text {
      font-size: 1.125rem;
      color: ${Colors[WHITE_COLOR_NAME]};
      margin-top: 72px;
    }

    .accounts-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 32px;

      .accounts-details-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: ${BORDER};
        border-radius: ${BORDER_RADIUS};
        background: ${Colors[WHITE_COLOR_NAME]};
        padding: 0 32px 0 24px;
        min-width: 800px;

        .account-box {
          width: 100%;

          .account-box-data {
            margin: 24px 0;
            display: flex;
            align-items: center;

            .account-image-wrapper {
              margin-left: 6px;
              .account-user-image {
                width: 32px;
              }
            }

            .account-data {
              display: flex;
              flex-direction: column;
              color: ${Colors.mud};
              margin: 0 12px;
              transition: opacity 0.1s;
              opacity: 1;

              .account-user-details {
                display: flex;
                flex-direction: row;
                font-size: 1.125rem;
                align-items: center;

                .account-user-company {
                  font-weight: 700;
                }
                .seperator {
                  margin-left: 6px;
                  margin-right: 6px;
                  font-weight: 700;
                }
                .account-user-email {
                  font-weight: 300;
                }
              }
              .last-used-data {
                display: flex;
                flex-direction: row;
                font-weight: 300;
                font-size: 0.875rem;
                margin-top: 4px;
                color: ${Colors.asphalt};

                .last-used-text {
                }

                .last-used-time {
                  margin-left: 2px;
                }
              }
            }

            .account-button-wrapper {
              margin-left: auto;
            }
          }
        }
        .border-line {
          border-top: 0.5px solid #bdbec7;
          width: 100%;
        }
      }
    }
    .general-login-wrapper {
      margin-top: 24px;
      font-weight: 300;
      font-size: 0.875rem;
      color: ${Colors[WHITE_COLOR_NAME]};

      .no-account-link {
        padding-left: 6px;
        color: ${Colors[WHITE_COLOR_NAME]};
      }
    }

    &.slugs-not-loaded {
      .welcome-text-wrapper {
        .user-name {
          opacity: 0;
        }
      }
      .accounts-wrapper {
        .accounts-details-box {
          .account-box {
            .account-box-data {
              .account-data {
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }
`;
