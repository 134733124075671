import { useState, useCallback, useMemo, useEffect } from "react";
import { componentStyles } from "./images-carousel.scss";
import DefaultProps from "./default-props";
import classnames from "classnames";
import ArrowLeft from "static/svg/arrow-left.svg";
import ArrowRight from "static/svg/arrow-right.svg";
import PictureComponent from "components/core/picture/picture-component";

const ImagesCarousel = ({ images }) => {
  /*
    State
  */
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  /*
    Accessors
  */
  const scrollToLatestImage = useCallback(() => {
    setCurrentImageIndex(images.length - 1);
  }, [images, setCurrentImageIndex]);

  // Scroll to last image on images change
  useEffect(() => {
    scrollToLatestImage();
  }, [images, scrollToLatestImage]);

  const shouldShowNavigation = useMemo(() => images.length > 1, [images]);

  const previousImageIndex = useMemo(() => {
    return currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1;
  }, [currentImageIndex, images]);

  const nextImageIndex = useMemo(() => {
    return (currentImageIndex + 1) % images.length;
  }, [currentImageIndex, images]);

  /*
    Handlers
  */
  const showNextImage = useCallback(() => {
    setCurrentImageIndex(nextImageIndex);
  }, [setCurrentImageIndex, nextImageIndex]);

  const showPreviousImage = useCallback(() => {
    setCurrentImageIndex(previousImageIndex);
  }, [setCurrentImageIndex, previousImageIndex]);

  const getClassByImageIndex = useCallback(
    (imageIndex) => {
      switch (imageIndex) {
        case currentImageIndex:
          return "current-image";
        case nextImageIndex:
          return "next-image";
        case previousImageIndex:
          return "previous-image";
        default:
          return "";
      }
    },
    [currentImageIndex, previousImageIndex, nextImageIndex]
  );

  /*
    Renderers
  */
  const renderImage = useCallback(
    (image, index) => {
      return (
        <div className={classnames("carousel-image", getClassByImageIndex(index))} key={index}>
          <PictureComponent {...image} specificImageStyle={{ width: "100%", height: "100%", objectFit: "contain" }} />
        </div>
      );
    },
    [getClassByImageIndex]
  );

  const renderImages = useCallback(() => {
    if (!images) return null;

    return <div className="images-carousel-content-wrapper">{images.map(renderImage)}</div>;
  }, [images, renderImage]);

  return (
    <div className="images-carousel">
      {shouldShowNavigation && <ArrowLeft className="carousel-navigation" onClick={showPreviousImage} />}

      {renderImages()}

      {shouldShowNavigation && <ArrowRight className="carousel-navigation" onClick={showNextImage} />}

      <style jsx>{componentStyles}</style>
    </div>
  );
};

ImagesCarousel.defaultProps = DefaultProps;

export default ImagesCarousel;
