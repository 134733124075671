import { FIELD_TYPES } from "constants/template-field-type";
import SecondaryButtonFieldsConfig from "segments/desktop/core-components/button/secondary-button/fields-config";
import RegularButtonFieldsConfig from "segments/desktop/core-components/button/regular-button/fields-config";

export default {
  swapButtonsOrder: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Swap buttons order"
  },
  secondaryButtonConfig: {
    type: FIELD_TYPES.NESTED,
    label: "Secondary Button",
    fields: { ...SecondaryButtonFieldsConfig }
  },
  regularButtonConfig: {
    type: FIELD_TYPES.NESTED,
    label: "Regular Button",
    fields: { ...RegularButtonFieldsConfig }
  }
}