import VerticalTabs from "segments/desktop/core-components/vertical-tabs/vertical-tabs";
import Colors from "styles/colors";
import { isDarkTheme } from "segments/segments-service";
import { componentStyles } from "./timeline-tabs-component.scss";
import DefaultProps from "./default-props";

const TimelineTabsComponent = (props) => {
  const { fullBackground, theme } = props;

  return (
    <div className="timeline-tabs-component" style={{ background: fullBackground && Colors[theme] }}>
      <VerticalTabs {...props} isDarkTheme={isDarkTheme(theme)} />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

TimelineTabsComponent.defaultProps = DefaultProps;

export default TimelineTabsComponent;
