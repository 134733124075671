import { FIELD_TYPES } from "constants/template-field-type";
import { XS, SM, MD, LG, LGR, XL } from "constants/sizes";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "",
  fields: {
    ...SharedAdvancedFieldsConfig,
    titleSize: {
      type: FIELD_TYPES.COMBO,
      label: "Title Size",
      options: [
        { value: XS, text: "24px" },
        { value: SM, text: "32px" },
        { value: MD, text: "40px" },
        { value: LG, text: "50px" },
        { value: LGR, text: "60px" },
        { value: XL, text: "72px" }
      ]
    },
    height: {
      type: FIELD_TYPES.TEXT,
      label: "Height"
    }
  }
};
