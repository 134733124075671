import { FIELD_TYPES } from "constants/template-field-type";

export default {
  itemScale: {
    type: FIELD_TYPES.TEXT,
    label: "Item scale"
  },
  defaultIndex: {
    type: FIELD_TYPES.TEXT,
    label: "Default slide index"
  },
  swipeToSlide: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Swipe to slide"
  },
  pauseOnFocus: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Pause on focus"
  },
  showFocusEffect: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Show focus effect"
  },
  lazyLoad: {
    type: FIELD_TYPES.BOOLEAN,
    label: "Lazy load slides"
  }
};
