export default [
  {
    type: "effort",
    offset: 25,
    duration: 0,
    pictureSrc: "/static/img/first-fold/effort_1.png",
    classToggle: "effort-first-item-end",
    className: "effort-first-item-start"
  },

  {
    type: "effort",
    offset: 125,
    duration: 0,
    pictureSrc: "/static/img/first-fold/effort_2.png",
    classToggle: "fade-in",
    className: "effort-second-item-start"
  },

  {
    type: "effort",
    offset: 150,
    duration: 0,
    pictureSrc: "/static/img/first-fold/effort_3.png",
    classToggle: "fade-in",
    className: "effort-third-item-start"
  },

  {
    type: "effort",
    offset: 175,
    duration: 0,
    pictureSrc: "/static/img/first-fold/effort_4.png",
    classToggle: "fade-in",
    className: "effort-fourth-item-start"
  },

  {
    type: "effort",
    offset: 200,
    duration: 0,
    pictureSrc: "/static/img/first-fold/effort_5.png",
    classToggle: "fade-in",
    className: "effort-fifth-item-start"
  },

  {
    type: "effort",
    offset: 225,
    duration: 0,
    pictureSrc: "/static/img/first-fold/effort_6.png",
    classToggle: "fade-in",
    className: "effort-sixth-item-start"
  },

  {
    type: "effort",
    offset: 250,
    duration: 0,
    pictureSrc: "/static/img/first-fold/effort_7.png",
    classToggle: "fade-in",
    className: "effort-seventh-item-start"
  }
];
