import Shapes from "segments/desktop/shape/index";
import DefaultProps from "./default-props";
import Star from "./star";

const StarShapeComponent = (props) => {
  return <Star {...props} componentType={Shapes.StarShapeComponent.type} />;
}

StarShapeComponent.defaultProps = DefaultProps;

export default StarShapeComponent;