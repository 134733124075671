import { PureComponent } from "react";
import { componentStyles } from "./button-tag.scss";
import DefaultProps from "./default-props";
import PictureComponent from "components/core/picture/picture-component";

const imageHeight = "40px";
export default class ButtonTag extends PureComponent {
  render() {
    const { tagImg, link, tagText, isOpenLinkInNewTab } = this.props;
    return (
      <div className="button-tag">
        <a href={link} target={isOpenLinkInNewTab ? "_blank" : "_self"} className="button-tag-anchor">
          <PictureComponent
            {...tagImg}
            specificImageStyle={{ height: imageHeight }}
            pictureClass="button-tag-image"
            isDecorative={true}
          />
          <span className={"tag-text"}>{tagText}</span>
          <style jsx>{componentStyles}</style>
        </a>
      </div>
    );
  }
}

ButtonTag.defaultProps = DefaultProps;
