import css from "styled-jsx/css";
import Colors from "styles/colors";

export const componentStyles = css.global`
  .button-image-tag {
    padding: 34px 14px;

    .button-image-tag-anchor {
      cursor: unset;
      height: 100px;
      width: 100%;
      display: flex;
      padding: 0px 16px 0 8px;
      align-items: center;
      border-radius: 2px;
      flex-direction: column;
      color: ${Colors["tuna"]};
      font-size: 1.125rem;
      text-decoration: none;

      &:hover.clickable {
        filter: brightness(85%);
        cursor: pointer;
      }

      .tag-text {
        padding: 16px 0px;
        align-items: center;
        text-align: center;
      }

      .button-tag-image {
        height: 64px;
      }
    }
  }
`;
