export const stars = [
  // left
  { top: 160, left: -129 },
  { top: 227, left: -106 },
  { top: 20, left: -20 },
  { top: 128, left: 220 },
  { top: 322, left: 200 },
  { top: 73, left: 315 },
  { top: 260, left: 120 },
  { top: 450, left: 150 },
  { top: 218, left: 12 },

  // middle
  { top: 50, left: 510 },
  { top: 35, left: 855 },
  { top: 100, left: 800 },

  // right
  { top: 24, left: 1036 },
  { top: 59, left: 1138 },
  { top: 86, left: 1087 },
  { top: 380, left: 1378 },
  { top: 202, left: 1522 },
  { top: 260, left: 1220 },
  { top: 330, left: 1400 },
  { top: 400, left: 1180 }
];
