import { PureComponent } from "react";
import { componentStyles } from "./globe-mobile-homepage-first-fold-component.scss";
import SideBySideTextAndAsset from "segments/desktop/core-components/side-by-side-text-and-asset/side-by-side-text-and-asset";

export default class GlobeMobileHomepageFirstFoldComponent extends PureComponent {
  render() {
    const { fullParagraph } = this.props;
    const newFullParagraph = { ...fullParagraph, body: "", textAlign: "center" };

    return (
      <div className="globe-mobile-homepage-first-fold-component">
        <SideBySideTextAndAsset
          contentClassName="globe-text-and-asset-content"
          {...this.props}
          fullParagraph={newFullParagraph}
        />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
