import SideBySideTextAndAsset from "segments/desktop/core-components/side-by-side-text-and-asset/side-by-side-text-and-asset";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import { componentStyles } from "./side-by-side-with-paragraphs-paragraph-component.scss";
import Paragraph from "segments/desktop/core-components/paragraph/paragraph";
import { SM } from "constants/sizes";
import DefaultProps from "./default-props";

const SideBySideWithParagraphsParagraphComponent = (props) => {
  const {
    primaryParagraph,
    leftSectionBottomText,
    leftSectionBottomTextColor,
    leftSectionParagraphAdvancedProps,
    rightSectionParagraphAdvancedProps,
    paragraphs,
    ...restProps
  } = props;

  const rightSectionParagraphs = () => {
    return paragraphs.map((paragraph, index) => {
      const { fullParagraph } = paragraph;
      return (
        <div className="right-section-paragraph-wrapper">
          <FullParagraph {...fullParagraph} {...rightSectionParagraphAdvancedProps} />
        </div>
      );
    });
  };

  const leftSection = () => {
    return (
      <div className="left-section-wrapper">
        <div className="full-paragraph-wrapper">
          <FullParagraph {...primaryParagraph} {...leftSectionParagraphAdvancedProps} />
        </div>
        <div className="paragraph-wrapper">
          <Paragraph body={leftSectionBottomText} bodyColor={leftSectionBottomTextColor} textSize={SM} />
        </div>
      </div>
    );
  };

  return (
    <div className="side-by-side-with-paragraphs-paragraph-component">
      <SideBySideTextAndAsset
        replaceAssetComponent={rightSectionParagraphs}
        replaceTextComponent={leftSection}
        {...restProps}
      />

      <style jsx>{componentStyles}</style>
    </div>
  );
};

SideBySideWithParagraphsParagraphComponent.defaultProps = DefaultProps;
export default SideBySideWithParagraphsParagraphComponent;
