import TabsComponent from "components/core/tabs/tabs-component";
import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import TwoThirdsTextAndAssetComponent from "segments/desktop/text-and-asset/layouts/two-thirds-text-and-asset/two-thirds-text-and-asset-component";

export default {
  ...TabsComponent.defaultProps,
  tabsColor: WORK_OS_IRIS_COLOR_NAME,
  tabs: [
    {
      tabLabel: "Clients Projects",
      textAndAsset: {
        ...TwoThirdsTextAndAssetComponent.defaultProps,
        fullParagraph: {
          ...TwoThirdsTextAndAssetComponent.defaultProps.fullParagraph,
          topic: {},
          title: "Accurately estimate costs and increase profitability",
          body: "Use Time Tracking to bill clients more accurately. Gain more confidence in estimating future costs."
        },
        asset: {
          ...TwoThirdsTextAndAssetComponent.defaultProps.asset,
          position: "center"
        }
      }
    },
    {
      tabLabel: "Content Planning",
      textAndAsset: {
        ...TwoThirdsTextAndAssetComponent.defaultProps,
        fullParagraph: {
          ...TwoThirdsTextAndAssetComponent.defaultProps.fullParagraph,
          topic: {},
          title: "Plan your content in advance for maximum productivity",
          body: "Organize all your content pieces by channel and publish date. Easily assign writers, designers, and editors to every post."
        },
        asset: {
          ...TwoThirdsTextAndAssetComponent.defaultProps.asset,
          position: "center"
        }
      }
    }
  ],
  paddingTop: "64px"
};
