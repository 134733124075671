import { PureComponent } from "react";
import DefaultProps from "./default-props";
import colors from "/styles/colors";
import { IFRAME_COMPONENT_RENDERED, IFRAME_LOADED, IFRAME_LOAD_ERROR } from "/constants/bigbrain-event-types";
import { withRouter } from "next/router";
import { componentStyles } from "./iframe.scss";

class IframeComponent extends PureComponent {
  componentDidMount() {
    this.trackIframeEvent(IFRAME_COMPONENT_RENDERED);
  }

  trackIframeEvent(kind) {
    const { src, router } = this.props;
    BigBrain("track", "homepage_iframe_tracking", { kind, placement: router.asPath, info1: src });
  }

  iframeLoaded = () => {
    this.trackIframeEvent(IFRAME_LOADED);
  };

  iframeLoadingError = () => {
    this.trackIframeEvent(IFRAME_LOAD_ERROR);
  };

  buildStyle() {
    const { height, borderColor, borderWidth } = this.props;
    const borderHexColor = colors[borderColor];
    return {
      height,
      border: `${borderWidth} ${borderHexColor} solid`
    };
  }

  buildIframeParams = () => {
    const { src, hideScrolling } = this.props;
    return {
      src,
      scrolling: hideScrolling ? "no" : "auto",
      onLoad: this.iframeLoaded,
      onError: this.iframeLoadingError,
      style: this.buildStyle()
    };
  };

  render() {
    return (
      <div className="iframe-core-component-wrapper">
        <iframe className="iframe-core-component" {...this.buildIframeParams()} />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

export default withRouter(IframeComponent);

IframeComponent.defaultProps = DefaultProps;
