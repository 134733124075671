import { PureComponent } from "react";
import { componentStyles } from "./security-logos.scss";
import DefaultProps from "./default-props";
import PictureComponent from "components/core/picture/picture-component";
import classnames from "classnames";

export const LOGO_SIZES = {
  LARGE: "large",
  MEDIUM: "medium"
};
const LOGOS = [
  "/static/img/security/gdpr_v2.png",
  "/static/img/security/iso_27001_v2.png",
  "/static/img/security/iso_27018_v2.png",
  "/static/img/security/soc_v2.png",
  "/static/img/security/hipaa_v2.png"
];

export default class SecurityLogos extends PureComponent {
  renderLogo = (logo, index) => {
    const { smallLogos } = this.props;

    return (
      <div key={index} className="security-logo-wrapper">
        <PictureComponent src={logo} className={classnames("security-logo", { "small-logo": smallLogos })} />
      </div>
    );
  };

  render() {
    const { logosNumber } = this.props;
    const logosToRender = LOGOS.slice(0, logosNumber);

    return (
      <div className="security-logos">
        {logosToRender.map((logo, index) => this.renderLogo(logo, index))}
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

SecurityLogos.defaultProps = DefaultProps;
