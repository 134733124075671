import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";

export const componentStyles = css`
  .monday-demo-tabs-desktop-component {
    max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
    margin: auto;
    .monday-demo-tabs-asset-and-timeline {
      display: flex;
      gap: 24px;
      justify-content: center;
      .monday-demo-tabs-assets-wrapper {
        position: relative;
        max-width: 900px;
        width: 900px;
        max-height: 680px;
        height: 680px;
      }
    }
  }
`;
