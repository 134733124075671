import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";

export const componentStyles = css.global`
  .vertical-tags-with-images-asset-component {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    margin: auto;
    padding: 0 32px;

    .vertical-tags-preview-empty-state {
      .core-title {
        font-size: 1.75rem;
      }

      .vertical-tags-preview-arrow-wrapper {
        position: absolute;
        bottom: 88px;
        left: 64px;
      }
    }

    .preview-component-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .preview-component-image-text {
        font-weight: 400;
        font-size: 0.875rem;
        text-align: center;
      }
    }
  }
`;
