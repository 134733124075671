import { PureComponent } from "react";
import { componentStyles } from "./side-by-side-with-background-hero-component.scss";
import Topbar from "/components/core/top-bar-new/top-bar-component";
import SideBySideTextAndAsset from "segments/desktop/core-components/side-by-side-text-and-asset/side-by-side-text-and-asset";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import Colors from "styles/colors";

export default class SideBySideWithBackgroundHeroComponent extends PureComponent {
  render() {
    const { textAndAsset, topbar } = this.props;
    const { background } = textAndAsset;
    const updatedBackground = { ...background, width: "100%" };
    const updatedTextAndAsset = { ...textAndAsset, background: updatedBackground };

    return (
      <div className="side-by-side-with-background-hero-component">
        <Topbar maxWidth="100vw" {...topbar} />

        <SideBySideTextAndAsset {...updatedTextAndAsset} />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

SideBySideWithBackgroundHeroComponent.defaultProps = {
  topbar: { showMenu: true },
  textAndAsset: {
    ...SideBySideTextAndAsset.defaultProps,
    background: {
      color: "iris"
    },
    asset: {
      ...SideBySideTextAndAsset.defaultProps.asset,
      position: "center",
      image: {
        ...SideBySideTextAndAsset.defaultProps.asset.image,
        src: "/static/img/segment-layout-default-images/hero/board-with-timeline-in-circle.png"
      }
    }
  }
};
