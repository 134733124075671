import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
import SideBySideTextAndAssetAdvancedFieldsConfig
  from "segments/desktop/core-components/side-by-side-text-and-asset/advanced-field-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "",
  fields: {
    ...SharedAdvancedFieldsConfig,
    ...SideBySideTextAndAssetAdvancedFieldsConfig,
    removeQuoteSigns: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Remove carousel texts quote signs"
    },
    mobileMarginBetweenParagraphAndCarousel: {
      type: FIELD_TYPES.TEXT,
      label: "Margin above carousel"
    }
  }
};
