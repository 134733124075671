import { PureComponent } from "react";
import { componentStyles } from "./reviews.scss";
import DefaultProps from "./default-props";
import Colors from "styles/colors";
import ReviewCard from "segments/desktop/core-components/review-card/review-card";

export default class Reviews extends PureComponent {
  constructor(props) {
    super(props);
  }

  renderReviews() {
    const { cardFontSize, reviews, reviewCardOverrideHeight } = this.props;

    return reviews.map((review, index) => {
      return (
        <div key={index} className="review-card-component-wrapper">
          <ReviewCard {...review} fontSize={cardFontSize} reviewCardOverrideHeight={reviewCardOverrideHeight} />
        </div>
      );
    });
  }

  render() {
    return (
      <div className="reviews">
        {this.renderReviews()}

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

Reviews.defaultProps = DefaultProps;
