import dynamic from "next/dynamic";

const YoutubeVideoAssetComponent = require("./youtube-video-asset-component").default;

import YoutubeVideoAssetConfig from "./youtube-video-asset-config";
import YoutubeVideoAssetAdvancedConfig from "./youtube-video-asset-advanced-config";

export default {
  YoutubeVideoAssetComponent,
  YoutubeVideoAssetConfig,
  YoutubeVideoAssetAdvancedConfig
};
