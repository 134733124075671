import css from "styled-jsx/css";
import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import Colors from "styles/colors";

export const componentStyles = css`
  :global(.mobile-tabs-component) {
    :global(.mobile-tabs-navigation) {
      gap: 16px;
      :global(.tab-nav-board-views-button) {
        &:after {
          content: "";
          height: 2px;
          width: 100%;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          background-color: transparent;
          display: flex;
          transition: background-color 0.15s ease-in-out;
        }
      }
      :global(.tab-nav-board-views-button.selected) {
        &:after {
          background-color: ${Colors[WORK_OS_IRIS_COLOR_NAME]};
        }
      }
      :global(.title-and-icon-wrapper) {
        padding: 0 4px;
        :global(.icon-wrapper) {
          margin-top: 4px;
          margin-right: 4px;
          align-items: center;

          :global(.picture-icon-wrapper.picture-component) {
            width: 18px;
            height: 18px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;
