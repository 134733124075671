import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import headerConstants from "segments/desktop/header/layouts/components/basic-header/constants";

const HORIZONTAL_MARGIN = headerConstants.HEADER_CONTENT_HORIZONTAL_PADDING;
const HORIZONTAL_MARGIN_STRING = `${HORIZONTAL_MARGIN}px`;

const BORDER_RADIUS = 16;
const BORDER_RADIUS_STRING = `${BORDER_RADIUS}px`;

export const componentStyles = css`
  .two-paragraphs-paragraph-component {
    max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
    margin: auto;

    :global(.side-by-side-text-and-asset) {
      border-radius: ${BORDER_RADIUS_STRING};
      margin-left: ${HORIZONTAL_MARGIN_STRING};
      margin-right: ${HORIZONTAL_MARGIN_STRING};
      :global(.side-by-side-content) {
        border-radius: ${BORDER_RADIUS_STRING};
      }
    }
  }
`;
