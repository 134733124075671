import { FIELD_TYPES } from "constants/template-field-type";
import MarkdownConfig from "segments/desktop/core-components/markdown/fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Markdown",
  fields: {
    ...MarkdownConfig
  }
};
