import css from "styled-jsx/css";

export const componentStyles = css`
  .universe-star {
    --twinkle-duration: "";
    --twinkle-delay: "";
    --star-size: "";
    position: absolute;
    background-image: url("https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/parallax/integrations/star.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: var(--star-size);
    height: var(--star-size);
    border-radius: 50%;
    animation: twinkle infinite alternate;
    animation-duration: var(--twinkle-duration);
    animation-delay: var(--twinkle-delay);
    will-change: transform;

    &.circle {
      background-image: unset;
      background: #fff;
    }

    @keyframes twinkle {
      from {
        transform: scale(0);
      }
      to {
        transform: scale(1.2);
      }
    }
  }
`;
