import { FIELD_TYPES } from "constants/template-field-type";
import gridImageFieldsConfig from "segments/desktop/core-components/grid-image/fields-config";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import ThemeConfig from "segments/core-configs/theme-config";

const { textColor, textAlign, ...selectedFullParagraphFields } = FullParagraphFieldsConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "Image With Paragraph",
  fields: {
    ...ThemeConfig,
    images: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Images",
      maxLimit: 4,
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          ...gridImageFieldsConfig,
          fullParagraph: {
            type: FIELD_TYPES.NESTED,
            label: "Full Paragraph",
            fields: selectedFullParagraphFields
          }
        }
      }
    }
  }
};
