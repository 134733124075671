import { FIELD_TYPES } from "constants/template-field-type";
import AssetFieldsConfig from "components/core/asset/fields-config";
import {
  PROJECTS_ORANGE_COLOR_NAME,
  MARKETING_RED_COLOR_NAME,
  CRM_GREEN_COLOR_NAME,
  DEV_GREEN_COLOR_NAME,
  WORK_OS_IRIS_COLOR_NAME
} from "styles/color-consts";

export default {
  tabs: {
    type: FIELD_TYPES.MULTIPLE_NESTED,
    label: "Tabs",
    maxLimit: 5,
    nestedConfig: {
      type: FIELD_TYPES.NESTED,
      fields: {
        label: { type: FIELD_TYPES.TEXT, label: "Tab Label", translatable: true },
        isMainTab: {
          type: FIELD_TYPES.BOOLEAN,
          label: "Main Work-OS tab"
        },
        color: {
          type: FIELD_TYPES.COLOR_PICKER,
          label: "Color"
        },
        tabImage: {
          type: FIELD_TYPES.GALLERY,
          defaultImagesKeys: ["static/img/templates/long-template/boards/"],
          label: "Tab image"
        },
        tabImageSelected: {
          type: FIELD_TYPES.GALLERY,
          defaultImagesKeys: ["static/img/templates/long-template/boards/"],
          label: "Tab Hover image"
        },
        asset: {
          type: FIELD_TYPES.NESTED,
          label: "Asset",
          fields: {
            ...AssetFieldsConfig
          }
        }
      }
    }
  }
};
