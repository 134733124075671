import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";

export const componentStyles = css`
  .side-by-side-board-views-component-tabs-component {
    max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
    margin: auto;
    display: flex;
    flex-direction: row;
    .full-paragraph-wrapper {
      width: 45%;
      padding: 45px 150px 50px 30px;
    }
    .board-views-tab-wrapper {
      width: 55%;
      padding-right: 20px;
    }
  }
`;
