import GridImageWithParagraph from "segments/desktop/core-components/grid-image-with-paragraph/grid-image-with-paragraph";
import { MD, XS} from "constants/sizes";

const getGridImageWithParagraphProps = (imageSrc, fullParagraphTitle, fullParagraphBody) => {
  const fullParagraph = {
    ...GridImageWithParagraph.defaultProps.fullParagraph,
    title: fullParagraphTitle,
    body: fullParagraphBody
  };

  return {
    ...GridImageWithParagraph.defaultProps,
    src: imageSrc,
    fullParagraph
  };
};

export default {
  gridItems: [
    getGridImageWithParagraphProps(
      "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/products/marketer_product_logo.png",
      "marketing",
      "Strategize, execute, and manage campaigns from idea to launch."
    ),
    getGridImageWithParagraphProps(
      "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/products/crm_product_logo.png",
      "crm",
      "Gain visibility into your sales pipeline to boost productivity."
    ),
    getGridImageWithParagraphProps(
      "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/products/dev_product_logo.png",
      "dev",
      "Plan, execute, and collaborate to deliver better products faster."
    ),
    getGridImageWithParagraphProps(
      "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/products/projects_product_logo.png",
      "projects",
      "Streamline portfolios to finish on time and on budget."
    )
  ],
  paddingTop: "64px",
  paddingBottom: "64px",
  titleSize: XS,
  textSize: MD
};
