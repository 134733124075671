import css from "styled-jsx/css";
import colors from "styles/colors";

export const componentStyles = css.global`
  .vertical-tabs {
    display: flex;
    justify-content: center;

    .tabs {
      display: flex;
      padding-top: 64px;
      width: 200px;
      flex-shrink: 0;

      .tabs-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;

        .selected-tab-indicator-component {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          right: -16px;
          transition: transform 300ms ease;
          z-index: 1;

          .selected-tab-indicator {
            height: 32px;
            width: 32px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            g {
              stroke: ${colors["white"]};
            }
          }
        }
      }
    }

    .content {
      display: flex;
      flex-grow: 1;
      padding: 64px 0px;
      border-radius: 4px;
      padding-bottom: 0;
    }
  }
`;
