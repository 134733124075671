import css from "styled-jsx/css";
import Colors from "styles/colors";
import { TABLET_QUERY_MAX_WIDTH } from "constants/vp-sizes";

export const componentStyles = css.global`
  .animating-board-homepage-first-fold-component {
    .desktop-wrapper {
      display: block;
    }

    .mobile-wrapper {
      display: none;
    }

    @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
      .desktop-wrapper {
        display: none;
      }
      .mobile-wrapper {
        display: block;
      }

      .second-title {
        font-size: 1.575rem;
        color: ${Colors["black"]};
        max-width: 660px;
        text-align: center;
        margin-top: 24px;
        line-height: 38px;
        transform: translateY(-24px);
      }

      .first-fold-signup-button {
        margin-top: 24px;
        transform: translateY(-24px);
        .mobile-wrapper {
          display: block;
        }

        .first-fold-signup-button {
          margin-top: 24px;
          transform: translateY(-24px);
        }
      }
    }
  }
`;
