import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import FullParagraphDefaultProps from "segments/desktop/core-components/full-paragraph/default-props";
import bulletsConfig from "segments/desktop/core-components/bullets/default-props";
import { MD } from "constants/sizes";
import {
  componentStyles,
  FULL_WITH_BULLETS_PARAGRAPH_WRAPPER_MAX_WIDTH
} from "./full-with-bullets-paragraph-component.scss";

const FullWithBulletsParagraphComponent = (props) => {
  const { maxWidth } = props;
  return (
    <div className="full-with-bullets-paragraph-component">
      <div className="full-with-bullets-paragraph-wrapper" style={{ maxWidth }}>
        <FullParagraph {...props} />
      </div>

      <style jsx>{componentStyles}</style>
    </div>
  );
};

FullWithBulletsParagraphComponent.defaultProps = {
  ...FullParagraphDefaultProps,
  bulletsConfig,
  paddingTop: "64px",
  paddingBottom: "64px",
  titleSize: MD,
  textSize: MD,
  maxWidth: FULL_WITH_BULLETS_PARAGRAPH_WRAPPER_MAX_WIDTH
};

export default FullWithBulletsParagraphComponent;
