import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, TABLET_QUERY_MAX_WIDTH } from "constants/vp-sizes";
import ReviewCardConstants from "segments/desktop/core-components/review-card/constants";
import ReviewsConstants from "segments/desktop/core-components/reviews/constants";

const MEDIUM_SCREEN = "1350px";
const SMALL_SCREEN = TABLET_QUERY_MAX_WIDTH;

const TITLE_ONLY_WIDTH_IN_MEDIUM_SCREEN = `${
  ReviewCardConstants.REVIEW_CARD_WIDTH * 2 + ReviewsConstants.REVIEW_CARD_MARGIN
}px`;

export const componentStyles = css.global`
  .with-title-reviews-component {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    margin: auto;
    min-height: 700px;
    display: flex;
    align-items: center;
    padding: 0px 64px;

    .title-only-paragrph-component-wrapper {
      flex-grow: 1;
      padding-right: 24px;
      width: 327px;
    }
  }

  @media (max-width: ${MEDIUM_SCREEN}) {
    .with-title-reviews-component {
      flex-direction: column;

      .title-only-paragrph-component-wrapper {
        padding-right: 0px;
        flex-grow: 0;
        width: ${TITLE_ONLY_WIDTH_IN_MEDIUM_SCREEN};
        margin-bottom: 48px;

        .title-only-paragraph {
          text-align: center !important;
        }
      }
    }
  }

  @media (max-width: ${SMALL_SCREEN}) {
    .with-title-reviews-component {
      .title-only-paragrph-component-wrapper {
        width: 100%;
        padding: 0px 24px;
      }
    }
  }
`;
