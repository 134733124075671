import { FIELD_TYPES } from "constants/template-field-type";
import fullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import logosFieldsConfig from "../../../core-components/logos/fields-config";

const SUPPORTED_LOGOS_NUMBER_OPTIONS = ["12"];

const numberOfCompaniesConfig = {
  numberOfCompanies: {
    ...logosFieldsConfig.numberOfCompanies,
    options: SUPPORTED_LOGOS_NUMBER_OPTIONS
  }
};

export default {
  type: FIELD_TYPES.NESTED,
  label: "Logos and Paragraph",
  fields: {
    logos: {
      type: FIELD_TYPES.NESTED,
      label: "Logos",
      fields: {
        ...logosFieldsConfig,
        ...numberOfCompaniesConfig
      }
    },
    fullParagraph: {
      type: FIELD_TYPES.NESTED,
      label: "Paragraph",
      fields: {
        ...fullParagraphFieldsConfig
      }
    }
  }
};
