import { FIELD_TYPES } from "constants/template-field-type";
import { PRODUCT_TYPES } from "constants/products";

export default {
  productType: {
    type: FIELD_TYPES.COMBO,
    label: "Product type",
    options: PRODUCT_TYPES
  }
};
