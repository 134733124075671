import css from "styled-jsx/css";
import { MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";

export const componentStyles = css`
  .building-blocks-content-wrapper {
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    // keep 1st child relative to determine the component size
    &:not(:first-child) {
      position: absolute;
      top: 0;
      left: 0;
    }
    &.selected {
      visibility: visible;
      opacity: 1;
    }

    @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
      :global(.side-by-side-content.is-swap) {
        flex-direction: column-reverse;
      }
    }
  }
`;
