import { SIGNUP_BUTTON_TYPE } from "segments/desktop/core-components/button//button-consts";
import TwoThirdsTextAndAssetComponent from "segments/desktop/text-and-asset/layouts/two-thirds-text-and-asset/two-thirds-text-and-asset-component";
import { AUTO } from "constants/cloudinary-asset-qualities";
import { ALIGN_TYPES } from "constants/align-types";
import { componentStyles } from "./single-template-templates-component.scss";
import { getAsset, getFullParagraph } from "../utils";

const SingleTemplateTemplatesComponent = (props) => {
  const {
    dynamicProps: { solution: { data: { name: title, description, images } = {} } = {} } = {},
    overrideTitle,
    overrideDescription,
    overrideImage = {},
    topicText,
    buttonSelect,
    solutionId,
    showTitle,
    showDescription,
    showImageShadow,
    mobileTextAlign,
    ...restProps
  } = props;

  const fullParagraph = getFullParagraph({
    title,
    description,
    overrideTitle,
    overrideDescription,
    topicText,
    buttonSelect,
    solutionId,
    showTitle,
    showDescription,
    textAlign: ALIGN_TYPES.LEFT,
    mobileTextAlign
  });

  const asset = getAsset({ overrideImage, images, showImageShadow });

  return (
    <div className="single-template-templates-component">
      <TwoThirdsTextAndAssetComponent fullParagraph={fullParagraph} asset={asset} assetQuality={AUTO} {...restProps} />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

SingleTemplateTemplatesComponent.defaultProps = {
  buttonSelect: {
    buttonType: SIGNUP_BUTTON_TYPE,
    signupButtonText: "Use template",
    seconderyButtonText: "Learn more"
  },
  paddingTop: "64px",
  paddingBottom: "64px",
  showTitle: true,
  showDescription: true,
  showImageShadow: true
};

export default SingleTemplateTemplatesComponent;
