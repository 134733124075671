import css from "styled-jsx/css";
import { MD } from "constants/vp-sizes";

const mdScreenSize = MD + "px";

export const componentStyles = css`
  .vertical-timer-tabs {
    .desktop-wrapper {
      display: block;
    }

    .mobile-wrapper {
      display: none;
    }

    @media (max-width: ${mdScreenSize}) {
      .desktop-wrapper {
        display: none;
      }

      .mobile-wrapper {
        display: block;
      }
    }
  }
`;