import { FIELD_TYPES } from "constants/template-field-type";
import GridImageFieldsConfig from "segments/desktop/core-components/grid-image/fields-config";
import TitleConfig from "segments/desktop/core-components/title/fields-config";
import BlogCardItemFooterFieldsConfig from "segments/desktop/grid/layouts/blog-cards-grid/blog-card-item/blog-card-item-footer/fields-config";
import Constants from "segments/desktop/grid/layouts/blog-cards-grid/constants";

const { link, src, ...restFieldsConfig } = GridImageFieldsConfig;
const newSrc = { ...src, label: `select an image (${Constants.CARD_WIDTH}X${Constants.IMAGE_HEIGHT})` };
const selectedGridImageFieldsConfig = { src: newSrc, ...restFieldsConfig };

export default {
  link: { type: FIELD_TYPES.TEXT, label: "Blog Link" },
  openInNewTab: {
    type: "boolean",
    label: "Should Open In New Tab"
  },
  image: {
    type: FIELD_TYPES.NESTED,
    label: "Image",
    fields: {
      ...selectedGridImageFieldsConfig
    }
  },
  ...TitleConfig,
  footer: {
    type: FIELD_TYPES.NESTED,
    label: "Card Footer",
    fields: BlogCardItemFooterFieldsConfig
  }
};
