import Shapes from "segments/desktop/shape/index";
import RingComponent from "./ring";
import DefaultProps from "./default-props";

const RingShapeComponent = (props) => {
  return (
    <RingComponent {...props} componentType={Shapes.RingShapeComponent.type}/>
  );
};

RingShapeComponent.defaultProps = DefaultProps;

export default RingShapeComponent;
