import { FIELD_TYPES } from "constants/template-field-type";
import LogosFieldsConfig from "segments/desktop/core-components/logos/fields-config";
import {BACKGROUND_GRAY_COLOR_NAME} from "styles/color-consts";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Images",
  fields: {
    theme: {
      type: FIELD_TYPES.COLOR_PICKER,
      label: "Theme",
      possibleColors: ["riverstone", "gradiant-gray", BACKGROUND_GRAY_COLOR_NAME]
    },
    logos: {
      type: FIELD_TYPES.NESTED,
      label: "Logos",
      fields: LogosFieldsConfig
    }
  }
};
