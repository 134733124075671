import dynamic from "next/dynamic";

const ButtonImageTagsGridComponent = require("./button-image-tags-grid-component").default;

import ButtonImageTagsGridConfig from "./button-image-tags-grid-config";
import ButtonImageTagsGridAdvancedConfig from "./button-image-tags-grid-advanced-config";

export default {
  ButtonImageTagsGridComponent,
  ButtonImageTagsGridConfig,
  ButtonImageTagsGridAdvancedConfig
};
