import { useEffect, useRef, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { trackEvent } from "services/bigbrain-service";
import { TAB_CLICKED } from "constants/bigbrain-event-types";
import Carousel from "../carousel/carousel";
import { componentStyles as mobileTabsNavigationComponentStyles } from "./mobile-tabs-navigation.scss";
import { componentStyles } from "./mobile-tabs.scss";

const VISIBILITY_SENSOR_OFFSET = -100;

const CAROUSEL_LEFT_SWIPE = "left";
const CAROUSEL_RIGHT_SWIPE = "right";

const MobileTabs = (props) => {
  const [carouselRef, setCarouselRef] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const itemsRef = useRef([]);
  const {
    tabs,
    selectedTab,
    setSelectedTab,
    navigationItemRenderer,
    carouselItemRenderer,
    wasComponentEverVisible,
    setWasComponentEverVisible
  } = props;

  const onVisibilityChange = (isVisibleValue) => {
    if (!wasComponentEverVisible && isVisibleValue) {
      setWasComponentEverVisible(true);
    }

    setIsVisible(isVisibleValue);
  };

  const tabsWithVideoPlayerRefs = [];
  tabs.forEach((tab) => {
    tabsWithVideoPlayerRefs.push({
      ...tab,
      videoPlayerRef: useRef(null)
    });
  });

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, tabsWithVideoPlayerRefs.length);
  }, [tabsWithVideoPlayerRefs]);

  useEffect(() => {
    const currentTab = tabsWithVideoPlayerRefs[selectedTab];
    if (currentTab?.videoPlayerRef?.current) {
      currentTab.videoPlayerRef.current.seekTo(0);
    }
    carouselRef?.slickGoTo(selectedTab);
  }, [selectedTab]);

  const scrollToNavigationTabIfVisible = (index) => {
    if (isVisible) {
      itemsRef?.current?.[index]?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
    }
  };

  const navItemRenderer = (tab, index) => {
    return (
      <div
        className="tab-nav-button-wrapper"
        ref={(element) => (itemsRef.current[index] = element)}
        onClick={() => {
          setSelectedTab(index);
          trackEvent(TAB_CLICKED, {
            kind: tab.tabLabel,
            info1: index
          });
        }}
      >
        {navigationItemRenderer(tab, index)}
      </div>
    );
  };

  const carouselProps = {
    items: tabsWithVideoPlayerRefs,
    itemRenderer: carouselItemRenderer,
    defaultIndex: 0,
    slidesToShow: 1,
    autoplay: false,
    autoplaySpeed: -1,
    centerPadding: "5%",
    centerMode: false,
    infinite: false,
    fade: false,
    onRefSet: setCarouselRef
  };

  const onCarouselChange = (current) => {
    // fix selectedTab index in case of a glitch
    if (current !== selectedTab) {
      setSelectedTab(current);
      scrollToNavigationTabIfVisible(current);
    }
  };

  const onCarouselSwipe = (direction) => {
    let newSlide = selectedTab;
    if (direction === CAROUSEL_LEFT_SWIPE && selectedTab < tabs.length - 1) {
      newSlide = selectedTab + 1;
    }
    if (direction === CAROUSEL_RIGHT_SWIPE && selectedTab > 0) {
      newSlide = selectedTab - 1;
    }
    setSelectedTab(newSlide);
    scrollToNavigationTabIfVisible(selectedTab);
  };

  return (
    <VisibilitySensor
      offset={{ bottom: VISIBILITY_SENSOR_OFFSET, top: VISIBILITY_SENSOR_OFFSET }}
      partialVisibility={true}
      onChange={onVisibilityChange}
      active={!wasComponentEverVisible} // turn it off once the component becomes visible
      scrollCheck={!wasComponentEverVisible} // turn it off once the component becomes visible
    >
      <div className="mobile-tabs-component">
        <div className="mobile-tabs-navigation">
          {tabsWithVideoPlayerRefs.map((tab, index) => navItemRenderer(tab, index))}
        </div>
        <div className="carousel-wrapper">
          <Carousel {...carouselProps} onChange={(current) => onCarouselChange(current)} onSwipe={onCarouselSwipe} />
        </div>
        <style jsx>{mobileTabsNavigationComponentStyles}</style>
        <style jsx>{componentStyles}</style>
      </div>
    </VisibilitySensor>
  );
};

export default MobileTabs;
