import DefaultProps from "./default-props";
import Shapes from "segments/desktop/shape/index.js";
import Rectangle from "segments/desktop/shape/layouts/rectangle-shape/rectangle.js";

const HotdogShapeComponent = (props) => {
  return <Rectangle {...props} componentType={Shapes.HotdogShapeComponent.type} />;
};

HotdogShapeComponent.defaultProps = DefaultProps;

export default HotdogShapeComponent;
