import { PureComponent } from "react";
import { componentStyles } from "./only-image-grid-component.scss";
import ImagesGrid from "segments/desktop/core-components/images-grid/images-grid";
import { positionTypes } from "segments/desktop/core-components/images-grid/images-grid-service";

export default class OnlyImageGridComponent extends PureComponent {
  render() {
    const { images, imagePosition } = this.props;

    return (
      <div className="only-image-grid-component">
        <ImagesGrid images={images} imagePosition={imagePosition} />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

OnlyImageGridComponent.defaultProps = {
  images: [
    {
      src: "/static/img/segment-layout-default-images/grid/man-with-blue-shirt.png"
    },
    {
      src: "/static/img/segment-layout-default-images/grid/woman-with-sunglasses-and-orange-shirt.png"
    },
    {
      src: "/static/img/segment-layout-default-images/grid/man-with-hat-and-sunglasses.png"
    },
    {
      src: "/static/img/segment-layout-default-images/grid/woman-with-sunglasses-in-the-wind.png"
    }
  ],
  paddingTop: "56px",
  paddingBottom: "56px",
  imagePosition: positionTypes.CENTER_CENTER
};
