import { PureComponent } from "react";
import classnames from "classnames";
import { componentStyles } from "./side-by-side-text-and-asset.scss";
import DefaultProps from "./default-props";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import { themes } from "segments/segments-service";
import AssetComponent from "/components/core/asset/asset-component";

const FULL_WIDTH = "100%";

export default class SideBySideTextAndAsset extends PureComponent {
  getLeftSpaceStyle() {
    const { background } = this.props;
    const { color, width } = background;

    if (!width) return {};

    const themeStyles = themes[color];

    return {
      ...themeStyles
    };
  }

  getBackgroundStyle() {
    const { background, swapTextAndAssetPosition, spaceBetweenTextAndAsset } = this.props;
    const { width, color, verticalPadding } = background;
    let backgroundStyle = verticalPadding ? { paddingBottom: verticalPadding, paddingTop: verticalPadding } : {};

    const themeStyles = themes[color];
    if (!width || !themeStyles) return backgroundStyle;

    const direction = swapTextAndAssetPosition ? "left" : "right";
    backgroundStyle = {
      ...backgroundStyle,
      background: `linear-gradient(to ${direction}, ${themeStyles.background} ${width}, white 0)`,
      color: themeStyles.color,
      gap: spaceBetweenTextAndAsset
    };

    return backgroundStyle;
  }

  getContentStyle() {
    const { spaceBetweenTextAndAsset, mobileSpaceBetweenTextAndAsset } = this.props;
    const backgroundStyle = this.getBackgroundStyle();

    return {
      "--space-between-text-and-asset": spaceBetweenTextAndAsset,
      "--mobile-space-between-text-and-asset": mobileSpaceBetweenTextAndAsset || 0,
      ...backgroundStyle
    };
  }

  getSideBySideComponentStyle() {
    const { background } = this.props;
    const backgroundImage = background?.backgroundImage?.src;
    if (backgroundImage) {
      return {
        background: `url(${backgroundImage})`,
        backgroundPosition: background?.backgroundImage?.backgroundPosition || "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      };
    }
    return null;
  }

  getRightSpaceStyle() {
    const { background } = this.props;
    const { width, color } = background;
    const themeStyles = themes[color];

    if (!themeStyles) return {};

    return {
      backgroundColor: width === FULL_WIDTH ? themeStyles.background : undefined
    };
  }

  renderAsset = () => {
    const {
      replaceAssetComponent: ReplaceAssetComponent,
      replaceAssetComponentProps,
      asset,
      assetQuality,
      useDynamicAsset,
      hideControls,
      useWindowMask,
      dynamicAssetParamPath
    } = this.props;

    if (ReplaceAssetComponent) {
      return <ReplaceAssetComponent {...replaceAssetComponentProps} />;
    }

    const { fitToContainer, ...assetRestProps } = asset;
    const assetClassName = classnames("side-by-side-asset", { "ignore-parent-height": !fitToContainer });

    return (
      <AssetComponent
        {...assetRestProps}
        assetQuality={assetQuality}
        useDynamicAsset={useDynamicAsset}
        hideControls={hideControls}
        useWindowMask={useWindowMask}
        dynamicAssetParamPath={dynamicAssetParamPath}
        convertGifToVideo={true}
        className={assetClassName}
        imageClassName="text-and-asset-image"
      />
    );
  };

  getFullParagraphComponentStyle = () => {
    const { leftSizeWidth } = this.props;
    return { width: leftSizeWidth };
  };

  getImageWrapperStyle = () => {
    const { rightSizeWidth, allowImageOverflow } = this.props;
    let style = { width: rightSizeWidth };
    if (allowImageOverflow) {
      style = { ...style, overflow: "visible" };
    }
    return style;
  };

  getReplaceTextProps = () => {
    const {
      bulletsConfig,
      iconsConfig,
      titleSize,
      textSize,
      spaceFromParagraph,
      withDynamicParagraphTitle,
      withDynamicParagraphSubtitle,
      topicBottomMargin,
      buttonTopMargin
    } = this.props;

    const replaceTextProps = {};
    if (bulletsConfig) replaceTextProps.bulletsConfig = bulletsConfig;
    if (iconsConfig) replaceTextProps.iconsConfig = iconsConfig;
    if (titleSize) replaceTextProps.titleSize = titleSize;
    if (textSize) replaceTextProps.textSize = textSize;
    if (spaceFromParagraph) replaceTextProps.spaceFromParagraph = spaceFromParagraph;
    if (withDynamicParagraphTitle) replaceTextProps.withDynamicParagraphTitle = withDynamicParagraphTitle;
    if (withDynamicParagraphSubtitle) replaceTextProps.withDynamicParagraphSubtitle = withDynamicParagraphSubtitle;
    if (topicBottomMargin) replaceTextProps.topicBottomMargin = topicBottomMargin;
    if (buttonTopMargin) replaceTextProps.buttonTopMargin = buttonTopMargin;

    return replaceTextProps;
  };

  render() {
    const {
      replaceTextComponent,
      replaceTextComponentProps,
      contentClassName,
      fullParagraph,
      asset,
      swapTextAndAssetPosition,
      mobileSwapTextAndAssetPosition,
      background,
      alignToLogo,
      paragraphVerticalAlignment,
      alignImageToScreenEdge,
      hideParagraph
    } = this.props;

    const leftSpaceStyle = swapTextAndAssetPosition ? this.getRightSpaceStyle() : this.getLeftSpaceStyle();
    const contentStyle = this.getContentStyle();
    const sideBySideComponentStyle = this.getSideBySideComponentStyle();
    const rightSpaceStyle = swapTextAndAssetPosition ? this.getLeftSpaceStyle() : this.getRightSpaceStyle();

    const ReplaceTextComponent = replaceTextComponent || FullParagraph;
    const { width } = background;
    const isFullWidth = width === FULL_WIDTH;

    return (
      <div
        className={classnames("side-by-side-text-and-asset", {
          "align-image-to-screen-edge": alignImageToScreenEdge,
          "is-swap": swapTextAndAssetPosition,
          "is-mobile-swap": mobileSwapTextAndAssetPosition
        })}
        style={sideBySideComponentStyle}
      >
        <div className="left-space" style={leftSpaceStyle} />

        <div
          className={classnames("side-by-side-content", paragraphVerticalAlignment, contentClassName, {
            "is-swap": swapTextAndAssetPosition,
            "is-mobile-swap": mobileSwapTextAndAssetPosition,
            "is-full-width": isFullWidth,
            "align-to-logo": !!alignToLogo,
            "no-text-component": hideParagraph
          })}
          style={contentStyle}
        >
          {!hideParagraph && (
            <div className="full-paragraph-component-wrapper" style={this.getFullParagraphComponentStyle()}>
              <ReplaceTextComponent {...replaceTextComponentProps} {...fullParagraph} {...this.getReplaceTextProps()} />
            </div>
          )}
          <div
            className={classnames("image-wrapper", asset?.position, asset?.shadow && "with-shadow")}
            style={this.getImageWrapperStyle()}
          >
            {this.renderAsset()}
          </div>
        </div>

        <div className="right-space" style={rightSpaceStyle} />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

SideBySideTextAndAsset.defaultProps = DefaultProps;
