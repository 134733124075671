import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
import FullParagraphAdvancedFieldsConfig from "segments/desktop/core-components/full-paragraph/advanced-fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "",
  fields: {
    ...SharedAdvancedFieldsConfig,
    formMinHeight: {
      type: FIELD_TYPES.TEXT,
      label: "Min Form Height"
    },
    assetAndParagraphGap: {
      type: FIELD_TYPES.TEXT,
      label: "Asset And Paragraph Gap"
    },
    fullParagraph: {
      type: FIELD_TYPES.NESTED,
      label: "Full Paragraph",
      fields: FullParagraphAdvancedFieldsConfig
    }
  }
};
