import { FIELD_TYPES } from "constants/template-field-type";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Animating Board",
  fields: {
    title: { type: FIELD_TYPES.TEXT, label: "Title", textArea: true, translatable: true },
    body: { type: FIELD_TYPES.TEXT, label: "Body", textArea: true, rows: 5, translatable: true },
    videoId: { type: FIELD_TYPES.TEXT, label: "Video Id" }
  }
};
