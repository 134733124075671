import css from "styled-jsx/css";
import Constants from "segments/desktop/core-components/reviews/constants";
import { TABLET_QUERY_MAX_WIDTH } from "constants/vp-sizes";

const REVIEW_CARD_MARGIN_RIGHT = `${Constants.REVIEW_CARD_MARGIN}px`;

export const componentStyles = css.global`
  .reviews {
    display: flex;

    .review-card-component-wrapper {
      flex-shrink: 0;

      &:not(:last-child) {
        margin-right: ${REVIEW_CARD_MARGIN_RIGHT};
      }
    }
  }

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    .reviews {
      flex-direction: column;

      .review-card-component-wrapper {
        &:not(:last-child) {
          margin-right: 0;
          margin-bottom: ${REVIEW_CARD_MARGIN_RIGHT};
        }
      }
    }
  }
`;
