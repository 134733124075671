import css from "styled-jsx/css";
import Colors from "/styles/colors";

const borderWidthNum = 1;
const borderWidth = borderWidthNum + "px";
const borderNegativeWidth = borderWidthNum * -1 + "px";
const hoverBorderWidthNum = 2;
const hoverBorderWidth = hoverBorderWidthNum + "px";
const hoverBorderNegativeWidth = hoverBorderWidthNum * -1 + "px";

export const componentStyles = css.global`
  .radio-button-component {
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    .radio-button-circle {
      margin-right: 8px;
      border-radius: 50%;
      border: ${borderWidth} solid;
      border-color: ${Colors["basic-blue"]};
      &.color-white {
        border-color: #fff;
      }
      position: relative;
    }

    @mixin radio-option-size($size) {
      .radio-button-circle {
        width: $size;
        height: $size;
        flex-shrink: 0;
      }

      &.selected {
        .radio-button-circle {
          &:after {
            width: $size;
            height: $size;
          }
        }
      }

      &.color-white {
        .radio-button-circle {
          &:after {
            width: $size;
            height: $size;
          }
        }
      }

      .radio-button-label {
        font-size: $size;
      }
    }

    &.size-sm {
      @include radio-option-size(0.875rem);
    }

    &.selected {
      .radio-button-circle {
        &:after {
          content: "";
          border-radius: 50%;
          background: ${Colors["basic-blue"]};
          position: absolute;
          top: ${borderNegativeWidth};
          left: ${borderNegativeWidth};
          transform: scale(0.5);
        }

        &.color-white {
          &:after {
            background: #fff;
          }
        }
      }
    }

    &:hover {
      &.color-white {
        .radio-button-circle {
          &:after {
            content: "";
            border-radius: 50%;
            background: #fff;
            position: absolute;
            top: ${borderNegativeWidth};
            left: ${borderNegativeWidth};
            transform: scale(0.5);
          }
        }
      }

      &:not(.disabled) {
        &:not(.color-white) {
          .radio-button-circle {
            border-width: ${hoverBorderWidth};
            background: lighten(#0ca5e7, 48%);

            &:after {
              top: ${hoverBorderNegativeWidth};
              left: ${hoverBorderNegativeWidth};
            }
          }
        }
      }
    }

    &.disabled {
      cursor: default;

      .radio-button-label {
        color: ${Colors["silver"]};
      }

      .radio-button-circle {
        border-color: ${Colors["silver"]};
        &:after {
          background: ${Colors["silver"]};
        }
      }
    }
  }
`;
