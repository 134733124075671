import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import Colors from "/styles/colors";
import { GHOST_WHITE_COLOR_NAME } from "styles/color-consts";
import { LAYER_TWO, LAYER_ONE } from "constants/z-index";


export const componentStyles = css.global`
  .product-cards-grid-medium-size-component {
    max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
    display: flex;
    padding-top: 64px;
    justify-content: center;
    .vertical-tabs-wrapper {
      z-index: ${LAYER_ONE};
      .vertical-tabs {
        .content{
          margin-left: -1px; // fixes a problem in the component layout
          padding: 0px 0px;
        }
        .tabs {
          padding-top: 0px;
          width: 350px;
          .tabs-wrapper {
            background-color: ${Colors[GHOST_WHITE_COLOR_NAME]};
            border-radius: 8px 0px 0px 8px;
            .vertical-tab {
              padding: 46px 24px;
              background-color: ${Colors[GHOST_WHITE_COLOR_NAME]};
              border-radius: 8px 0px 0px 8px;
              &.active {
                background-color: white;
                box-shadow: -10px 0px 10px 1px rgba(29, 140, 242, 0.16);
                z-index: ${LAYER_TWO};
              }
              .tab-image {
                :global(img) {
                  display: flex;
                  max-height: 30px;
                  padding-left: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
`;
