import { FIELD_TYPES } from "constants/template-field-type";
import { selectTemplateConfig } from "segments/core-configs/template-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Single Template",
  fields: {
    ...selectTemplateConfig
  }
};
