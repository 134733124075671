import dynamic from "next/dynamic";

const FloatingIntegrationsComponent = require("./floating-integrations-component").default;

import FloatingIntegrationsConfig from "./floating-integrations-config";
import FloatingIntegrationsAdvancedConfig from "./floating-integrations-advanced-config";

export default {
  FloatingIntegrationsComponent,
  FloatingIntegrationsConfig,
  FloatingIntegrationsAdvancedConfig
};
