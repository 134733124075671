import { PureComponent } from "react";
import SideBySideTextAndAsset from "segments/desktop/core-components/side-by-side-text-and-asset/side-by-side-text-and-asset";
import HubspotContactForm from "segments/desktop/core-components/hubspot-contact-form/hubspot-contact-form";
import HubspotContactFormProps from "segments/desktop/core-components/hubspot-contact-form/default-props";
import { componentStyles } from "./hubspot-side-by-side-form-component.scss";

export default class HubspotSideBySideFormComponent extends PureComponent {
  render() {
    const { formId, source, overrideCampaignId, lastNameFirst, ...restProps } = this.props;
    const replaceTextComponentProps = {
      formId,
      source,
      overrideCampaignId,
      lastNameFirst
    };

    return (
      <div className="hubspot-side-by-side-form-component">
        <SideBySideTextAndAsset
          replaceTextComponent={HubspotContactForm}
          {...restProps}
          replaceTextComponentProps={replaceTextComponentProps}
        />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

HubspotSideBySideFormComponent.defaultProps = {
  width: "100%",
  asset: {
    position: "center",
    fitToContainer: true,
    image: {
      src: "/static/img/segment-layout-default-images/sales_social.png"
    }
  },
  ...HubspotContactFormProps
};
