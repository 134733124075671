/* eslint-disable camelcase */
import { useEffect, useMemo, useState, memo } from "react";
import {
  getMondaySlugsDetails,
  getLastUsedAccountSlug,
  getMondayFullUrlForSlug,
  setWelcomeBackCookie,
  DEFAULT_PLATFORM_USER_IMAGE_SOURCE
} from "services/monday-platform/existing-accounts-service";
import RegularButton from "segments/desktop/core-components/button/regular-button/regular-button";
import { LOGIN_PAGE_LINK, ROOT_LINK } from "constants/links";
import PictureComponent from "/components/core/picture/picture-component";
import { trackEvent } from "/services/bigbrain-service";
import {
  WELCOME_PAGE_DIDNT_FIND_ACCOUNT_CLICKED,
  WELCOME_PAGE_GO_TO_ACCOUNT_CLICKED,
  WELCOME_PAGE_LOADED_WITH_NO_ACCOUNTS,
  WELCOME_PAGE_LOADED
} from "constants/bigbrain-event-types";
import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import classnames from "classnames";
import { withAbTests } from "contexts/ab-tests-context";
import { shouldGetWelcomeBackPageTest } from "services/ab-tests/acquisition-ab-test-service";
import { getUrlParamValue } from "utils/url";
import {
  WELCOME_PAGE_SOURCE_PARAM,
  WELCOME_PAGE_SOURCE_HOMEPAGE_VALUE,
  WELCOME_PAGE_PATH
} from "constants/welcome-page-constants";
import UniverseStars from "segments/desktop/core-components/universe-stars/universe-stars";
import moment from "moment";
import { stars } from "./welcome-stars-clusters-config";

import DefaultProps from "./default-props";

import { componentStyles } from "./welcome-back-homepage-first-fold-component.scss";

const MONDAY_LOGO = "https://dapulse-res.cloudinary.com/image/upload/welcome-back/Logo_monday.com.svg";

const FAKE_LAST_SLUG_DATA = {
  up: DEFAULT_PLATFORM_USER_IMAGE_SOURCE,
  un: "John Doe",
  ue: "john.doe@gmail.com",
  an: "monday-demo",
  sl: "monday-demo",
  is_fake_slug: true
};

const WelcomeBackHomepageFirstFoldComponent = (props) => {
  const { translate, abTests, introText, persistantCookies } = props;

  const [slugsDetails, setslugsDetails] = useState([]);
  const [lastSlug, setlastSlug] = useState(null);

  useEffect(() => {
    const sendWelcomePageAbTestEventIfNeeded = (slugsDetailsFromCookie) => {
      const pathName = window?.location?.pathname;
      const sourceParamValue = getUrlParamValue(window?.location?.href, WELCOME_PAGE_SOURCE_PARAM);
      if (pathName === WELCOME_PAGE_PATH && sourceParamValue === WELCOME_PAGE_SOURCE_HOMEPAGE_VALUE) {
        // eslint-disable-next-line camelcase
        const pulse_user_serial_number = slugsDetailsFromCookie?.[0]?.us;
        const pulse_user_id = slugsDetailsFromCookie?.[0]?.ui;
        const pulse_account_id = slugsDetailsFromCookie?.[0]?.ai;
        if (pulse_user_id && pulse_account_id && pulse_user_serial_number === 1) {
          if (
            // fire event for visitors who got the welcome page (platform event is fired inside the platform)
            shouldGetWelcomeBackPageTest(abTests, persistantCookies, true, {
              pulse_user_id,
              pulse_account_id,
              pulse_user_serial_number
            })
          ) {
            setWelcomeBackCookie();
          }
        }
      }
    };

    const slugsDetailsFromCookie = getMondaySlugsDetails();
    const lastSlugFromCookie = getLastUsedAccountSlug();

    if (slugsDetailsFromCookie.length === 0) {
      trackEvent(WELCOME_PAGE_LOADED_WITH_NO_ACCOUNTS, { info1: lastSlugFromCookie });
      window.location = ROOT_LINK;
    }
    trackEvent(WELCOME_PAGE_LOADED, { kind: slugsDetailsFromCookie.length, info1: lastSlugFromCookie });
    setslugsDetails(slugsDetailsFromCookie);
    setlastSlug(lastSlugFromCookie);
    sendWelcomePageAbTestEventIfNeeded(slugsDetailsFromCookie);
  }, [abTests]); // abTests should never change - so still this will be called once during mount

  const lastSlugDetails = useMemo(() => {
    const slug = slugsDetails.find((slugDetail) => slugDetail?.sl === lastSlug);
    return slug || FAKE_LAST_SLUG_DATA;
  }, [lastSlug, slugsDetails]);

  const olderSlugDetails = useMemo(() => {
    return slugsDetails.filter((slugDetail) => slugDetail?.sl !== lastSlug);
  }, [lastSlug, slugsDetails]);

  const isSlugLoaded = useMemo(() => {
    return !lastSlugDetails?.is_fake_slug;
  }, [lastSlugDetails]);

  const slugDetailsNewestFirst = useMemo(() => {
    const olderAccountSorted = [...olderSlugDetails].sort((a, b) => {
      if (a?.lu > b.lu) return -1;
      if (b.lu > a.lu) return 1;
      return 0;
    });

    return [lastSlugDetails].concat(olderAccountSorted);
  }, [lastSlugDetails, olderSlugDetails]);

  const Stars = memo(() => {
    return <UniverseStars stars={stars} />;
  });
  const normalizeString = (str) => {
    return str?.replace(/\+/g, " "); // platform saves the name of the user with a + instead of space
  };

  const renderFirstFoldMessage = () => {
    return (
      <>
        <div className="monday-logo">
          <PictureComponent className="monday-logo-picture" src={MONDAY_LOGO} />
        </div>
        <div className="welcome-text-wrapper">
          <div className="welcome-text">{translate("welcomeBackComponent.welcomeBack")}</div>
          <div className="user-name">{normalizeString(lastSlugDetails?.un)} </div>
        </div>
      </>
    );
  };

  const renderAccountsData = () => {
    if (slugDetailsNewestFirst.length === 0) return null;

    return (
      <div className="accounts-details-box">
        {slugDetailsNewestFirst.map((slugDetails, index) => renderAccountBox(slugDetails, index))}
      </div>
    );
  };

  const renderAccountBox = (slugDetails, index) => {
    const url = getMondayFullUrlForSlug(slugDetails?.sl);
    const renderBorder = index < slugsDetails.length - 1;

    return (
      <>
        <div className="account-box">
          <div className="account-box-data">
            <div className="account-image-wrapper">
              <PictureComponent className="account-user-image" src={slugDetails?.up} skipCloudinaryConversion={true} />
            </div>
            <div className="account-data">
              <div className="account-user-details">
                <div className="account-user-company">{normalizeString(slugDetails?.an)}</div>
                <div className="seperator">|</div>
                <div className="account-user-email">{slugDetails?.ue}</div>
              </div>
              {slugDetails.lu && (
                <div className="last-used-data">
                  <div className="last-used-text">{translate("welcomeBackComponent.lastUsage")}:</div>
                  <div className="last-used-time">{moment(slugDetails.lu).fromNow()}</div>
                </div>
              )}
            </div>

            <div className="account-button-wrapper">
              <RegularButton
                buttonText={translate("welcomeBackComponent.goToAccount")}
                url={url}
                isOpenLinkInNewTab={true}
                outline={index > 0}
                color={WORK_OS_IRIS_COLOR_NAME}
                onClick={() => onGoToAccountClicked(index === 0, url)}
              />
            </div>
          </div>
        </div>
        {renderBorder && <div className="border-line" />}
      </>
    );
  };

  const onNoAccountClicked = () => {
    trackEvent(WELCOME_PAGE_DIDNT_FIND_ACCOUNT_CLICKED, { info1: slugsDetails.length });
  };

  const onGoToAccountClicked = (isLastAccount, url) => {
    trackEvent(WELCOME_PAGE_GO_TO_ACCOUNT_CLICKED, {
      kind: isLastAccount ? "last_account" : "old_account",
      info1: slugsDetails.length,
      info2: url
    });
  };

  const renderLoginOption = () => {
    return (
      <>
        <span className="no-account-text">{translate("welcomeBackComponent.notSeeingAccount")}</span>
        <a className="no-account-link" href={LOGIN_PAGE_LINK} onClick={onNoAccountClicked}>
          {translate("welcomeBackComponent.clickHere")}
        </a>
      </>
    );
  };

  return (
    <div
      className={classnames("welcome-back-homepage-first-fold-component", {
        "slugs-not-loaded": !isSlugLoaded
      })}
    >
      <Stars />
      <div className="welcome-back-first-fold-message">{renderFirstFoldMessage()}</div>
      <div className="pick-up-text">{introText || translate("welcomeBackComponent.pickUp")}</div>
      <div className="accounts-wrapper">{renderAccountsData()}</div>
      <div className="general-login-wrapper">{renderLoginOption()}</div>

      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default withAbTests(WelcomeBackHomepageFirstFoldComponent);

WelcomeBackHomepageFirstFoldComponent.defaultProps = DefaultProps;
