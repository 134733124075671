export const defaultData = {
  template: "DirectoryImagesTemplate",
  title: "Article Page Example Title",
  description: "Article Page Example Description",
  header: {
    color: "brand-red",
    text: "Use Cases",
    height: "360px",
    img: "/static/img/header-decorators/tablet-chocolat.png",
    hotDogColor: "brand-red",
    textColor: "white",
    title: "Example and use cases",
    subtitle: "Here you can find the best examples and use case<br/> Hilli you are amazing :)"
  },
  listData: [
    {
      type: "link",
      props: {
        firstTitle: {
          text: "My title for thumbnail"
        },
        color: "brand-red",
        subtitle: "Another subtitle with long text",
        image: "/static/img/thumbnails/animated-lock-icon.jpg",
        link: "/link678",
        buttomLink: {
          show: true,
          text: "Read more >",
          url: "/link678",
          color: "brand-blue"
        }
      }
    }
  ],
  footerSignup: {},
  socialButtons: {
    show: false
  }
};
