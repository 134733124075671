import DefaultProps from "./default-props";
import withResponsiveHOC from "components/enhancements/responsive-hoc/responsive-hoc-component";
import BuildingBlocksTabsMobileComponent
  from "./building-blocks-tabs-mobile-component/building-blocks-tabs-mobile-component";
import BuildingBlocksTabsDesktopComponent
  from "./building-blocks-tabs-desktop-component/building-blocks-tabs-desktop-component";

const BuildingBlocksTabsComponent = withResponsiveHOC(
  BuildingBlocksTabsDesktopComponent,
  BuildingBlocksTabsMobileComponent
);

BuildingBlocksTabsComponent.defaultProps = DefaultProps;

export default BuildingBlocksTabsComponent;
