import VerticalTimerTabs from "segments/desktop/core-components/vertical-timer-tabs/vertical-timer-tabs";
import { componentStyles } from "./timer-vertical-tabs-component.scss";
import DefaultProps from "./default-props";

const TimerVerticalTabsComponent = props => {
  return (
    <div className="timer-vertical-tabs-component">
      <VerticalTimerTabs {...props} />
      <style jsx>{componentStyles}</style>
    </div>
  );
}

TimerVerticalTabsComponent.defaultProps = DefaultProps;

export default TimerVerticalTabsComponent;
