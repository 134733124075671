import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import { TABLET_QUERY_MAX_WIDTH, MOBILE_QUERY_MAX_WIDTH, MOBILE_QUERY_MAX_WIDTH_M } from "constants/vp-sizes";

export const componentStyles = css.global`
  .globe-with-top-cta-homepage-first-fold-component {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .full-paragraph-wrapper {
      max-width: 750px;
    }

    .asset-and-logos-wrapper {
      margin-top: 24px;
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 48px;

      .asset-wrapper {
        max-width: 1200px;
        min-height: 1000px;
      }

      .logos-wrapper {
        position: absolute;
        top: 50%;

        .top-logos-component {
          .core-title {
            font-size: 2.25rem;
            line-height: 48px;
            font-weight: 400;
          }

          .logos {
            margin-top: 16px;
          }
        }
      }
    }
  }

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    .globe-with-top-cta-homepage-first-fold-component {
      .full-paragraph-wrapper {
        padding: 0 32px;
      }

      .asset-and-logos-wrapper {
        padding: 0;

        .asset-wrapper {
          min-height: 800px;
        }

        .logos-wrapper {
          position: relative;
          transform: translateY(-350px);

          .top-logos-component {
            .core-title {
              font-size: 1.375rem;
              line-height: 32px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    .globe-with-top-cta-homepage-first-fold-component {
      .asset-and-logos-wrapper {
        .asset-wrapper {
          min-height: 600px;
        }

        .logos-wrapper {
          transform: translateY(-240px);
        }
      }
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH_M}) {
    .globe-with-top-cta-homepage-first-fold-component {
      .asset-and-logos-wrapper {
        .asset-wrapper {
          min-height: 200px;
        }

        .logos-wrapper {
          transform: translateY(-150px);
        }
      }
    }
  }
`;
