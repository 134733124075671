import { FIELD_TYPES } from "constants/template-field-type";
import SideBySideTextAndAssetFieldsConfig from "segments/desktop/core-components/side-by-side-text-and-asset/fields-config";
import bulletsConfig from "segments/desktop/core-components/bullets/fields-config";

const { background, ...selectedSideBySideTextAndAssetFieldsConfig } = SideBySideTextAndAssetFieldsConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "Side By Side with Bullets",
  fields: { ...selectedSideBySideTextAndAssetFieldsConfig, bulletsConfig }
};
