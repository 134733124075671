export default [
  // {
  //   type: "other",
  //   offset: 50,
  //   duration: 0,
  //   pictureSrc: "/static/img/first-fold/others_2.png",
  //   classToggle: "fade-out",
  //   className: "other-first-item-start"
  // },

  {
    type: "other",
    offset: 50,
    duration: 0,
    pictureSrc: "/static/img/first-fold/others_2.png",
    classToggle: "fade-out",
    className: "other-second-item-start"
  },

  // // Content Box
  // {
  //   type: "other",
  //   offset: 175,
  //   duration: 0,
  //   pictureSrc: "/static/img/first-fold/others_3.png",
  //   classToggle: "fade-out",
  //   className: "other-third-item-start"
  // },

  // Mail Icon
  {
    type: "other",
    offset: 125,
    duration: 0,
    pictureSrc: "/static/img/first-fold/others_4.png",
    classToggle: "fade-out",
    className: "other-fourth-item-start"
  }
];
