import dynamic from "next/dynamic";

const TagsGridComponent = require("./tags-grid-component").default;

import TagsGridConfig from "./tags-grid-config";
import TagsGridAdvancedConfig from "./tags-grid-advanced-config";

export default {
  TagsGridComponent,
  TagsGridConfig,
  TagsGridAdvancedConfig
};
