import ProcessTabsDefaultProps from "segments/desktop/core-components/process-tabs/default-props";

export default {
  maxLimit: 4,
  showDivider: true,
  title: {
    title: "Built for any kind of work process"
  },
  titleMargin: "50px",
  tabs: [
    {
      title: "For leadership",
      content: {
        topic: {},
        title: null,
        body: "Use Time Tracking to bill clients more accurately. Gain more confidence in estimating future costs."
      },
      processTab: ProcessTabsDefaultProps
    },
    {
      title: "For managers",
      content: {
        topic: {},
        title: null,
        body: "Gain greater visibility and transparency over the work being done with robust dashboards and project overviews of where initiatives stand."
      },
      processTab: ProcessTabsDefaultProps
    },
    {
      title: "For teams and individuals",
      content: {
        topic: {},
        title: null,
        body: "Use Time Tracking to bill clients more accurately. Gain more confidence in estimating future costs."
      },
      processTab: ProcessTabsDefaultProps
    }
  ],
  advancedProcessTabs: {
    defaultActiveTabIndex: 0,
    autoTransition: null,
    scrollOnTabClick: false,
    distanceFromTop: 0
  },
  timerEnabled: false,
  showButton: false,
  arrowSize: "12px",
  showArrow: true
};
