import VerticalTabs from "segments/desktop/core-components/vertical-tabs/vertical-tabs";
import { componentStyles } from "./vertical-tabs-component.scss";
import DefaultProps from "./default-props";

const VerticalTabsComponent = props => {
  return (
    <div className="vertical-tabs-component">
      <VerticalTabs {...props} />
      <style jsx>{componentStyles}</style>
    </div>
  );
}

VerticalTabsComponent.defaultProps = DefaultProps;

export default VerticalTabsComponent;