import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "",
  fields: {
    ...SharedAdvancedFieldsConfig,
    autoTransition: { type: FIELD_TYPES.TEXT, label: "Auto Transition (milliseconds)" },
    scrollOnTabClick: { type: FIELD_TYPES.BOOLEAN, label: "Should scroll to content on tab click" },
    scrollSpeed: { type: FIELD_TYPES.TEXT, label: "Scroll speed on tab click" },
    distanceFromTop: { type: FIELD_TYPES.TEXT, label: "Distance to stop when scrolling (fixed header height)" }
  }
};
