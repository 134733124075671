import { getMondaySolutionById } from "server/services/solutions/solutions-api-service";
import { getSolutionById } from "services/templates-generator/api-service";

export default [
  {
    propsPropertyName: "solution",
    shouldFetch: ({ props, previousProps, pageConfig, previousPageConfig }) => {
      return (
        props.solutionId &&
        (props.solutionId !== previousProps.solutionId || pageConfig.localeId !== previousPageConfig.localeId)
      );
    },
    fetch: async ({ props, pageConfig, isClientFetch }) => {
      const { solutionId } = props;
      const { localeId, path } = pageConfig;
      let solution;
      if (isClientFetch) {
        const res = await getSolutionById({ solutionId, localeId });
        solution = res.solution;
      } else {
        solution = await getMondaySolutionById({ solutionId, localeId });
      }
      if (!solution) {
        throw new Error(`solution (id: ${solutionId}) can't be found in page ${path}`);
      }
      return solution;
    }
  }
];
