import { PureComponent } from "react";
import StarsComponent from "components/core/stars/stars-component";
import PersonalTextualInfo from "segments/desktop/core-components/person-textual-info/person-textual-info";
import { componentStyles } from "./review-card.scss";
import DefaultProps from "./default-props";
import Colors from "styles/colors";

export default class ReviewCard extends PureComponent {
  render() {
    const {
      decorationColor,
      starsCount,
      name,
      jobDescription,
      title,
      showStars,
      fontSize,
      reviewCardOverrideHeight
    } = this.props;

    return (
      <div className="review-card" style={{ height: reviewCardOverrideHeight }}>
        <div className="review-card-color-decoration" style={{ backgroundColor: Colors[decorationColor] }} />

        <div className="review-card-quote-title" style={{ fontSize }} dangerouslySetInnerHTML={{ __html: title }} />

        {showStars && (
          <div className="review-card-stars">
            <StarsComponent starsCount={starsCount} />
          </div>
        )}

        <div className="review-card-quote-name">
          <PersonalTextualInfo
            name={name}
            jobDescription={jobDescription}
            color={Colors["mud"]}
            className="review-card-person-info"
          />
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

ReviewCard.defaultProps = DefaultProps;
