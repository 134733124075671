import TitleOnlyParagraphDefaultProps from "segments/desktop/core-components/title-only-paragraph/default-props";
import SideBySideTextAndAssetDefaultProps from "segments/desktop/core-components/side-by-side-text-and-asset/default-props";
import { MD } from "constants/sizes";

export default {
  children: [],
  defaultActiveTabIndex: 0,
  autoTransition: "10000",
  theme: "gradiant-gray",
  title: TitleOnlyParagraphDefaultProps,
  tabsColor: "iris",
  tabs: [
    { tabLabel: "Tab 1", textAndAsset: SideBySideTextAndAssetDefaultProps },
    { tabLabel: "Tab 2", textAndAsset: SideBySideTextAndAssetDefaultProps },
    { tabLabel: "Tab 3", textAndAsset: SideBySideTextAndAssetDefaultProps },
    { tabLabel: "Tab 4", textAndAsset: SideBySideTextAndAssetDefaultProps },
    { tabLabel: "Tab 5", textAndAsset: SideBySideTextAndAssetDefaultProps },
    { tabLabel: "Tab 6", textAndAsset: SideBySideTextAndAssetDefaultProps }
  ],
  paddingTop: "64px",
  paddingBottom: "64px",
  titleSize: MD,
  autoTransition: null,
  backgroundColor: "white",
  minHeight: "600px",
  addTimeline: false,
  addTabIndicator: true,
  fullBackground: false
};