import { FIELD_TYPES } from "constants/template-field-type";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Multiple Reviews",
  fields: {
    columnWidth: { type: FIELD_TYPES.TEXT, label: "Column Width" },
    reviews: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Reviews",
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          reviewContent: { type: FIELD_TYPES.MULTIPLE_TEXTS, label: "Review Content" },
          reviewerName: { type: FIELD_TYPES.TEXT, label: "Reviewer Name" },
          reviewedOn: {
            type: FIELD_TYPES.COMBO,
            label: "Reviewed On",
            options: ["Capterra", "G2 Crowd", "Trustpilot"]
          }
        }
      }
    }
  }
};
