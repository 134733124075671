import dynamic from "next/dynamic";

const MarkdownEmbeddedComponent = require("./markdown-embedded-component").default;

import MarkdownEmbeddedConfig from "./markdown-embedded-config";
import MarkdownEmbeddedAdvancedConfig from "./markdown-embedded-advanced-config";

export default {
  MarkdownEmbeddedComponent,
  MarkdownEmbeddedConfig,
  MarkdownEmbeddedAdvancedConfig
};
