const sharedDefaultAdvancedProps = {
  strokeWidth: "0",
  opacity: "1",
  shadowBlur: "0",
  shadowOffsetX: "0",
  shadowOffsetY: "0",
  zIndex: 0
};

export { sharedDefaultAdvancedProps };
