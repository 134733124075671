import { PureComponent } from "react";
import { componentStyles } from "./vertical-tabs.scss";
import DefaultProps from "./default-props";
import VerticalTabsDesktopComponent from "./vertical-tabs-desktop/vertical-tabs-desktop-component";
import VerticalTabsMobileComponent from "./vertical-tabs-mobile/vertical-tabs-mobile-component";

export default class VerticalTabs extends PureComponent {
  render() {
    return (
      <div className="vertical-tabs">
        <div className="desktop-wrapper">
          <VerticalTabsDesktopComponent {...this.props} />
        </div>

        <div className="mobile-wrapper">
          <VerticalTabsMobileComponent {...this.props} />
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

VerticalTabs.defaultProps = DefaultProps;
