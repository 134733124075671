import { PureComponent } from "react";
import classnames from "classnames";
import { componentStyles } from "./integrations-slider-component.scss";
import IntegrationBadge from "../integration-badge/integration-badge-component";
import { MAX_INTEGRATIONS_FLOATING_WIDTH } from "segments/desktop/integrations/layouts/floating-integrations/constants";
import { ITEM_TOTAL_WIDTH, ITEM_TOTAL_HEIGHT, NUMBER_OF_ROWS } from "../../constants";

export default class IntegrationsSliderComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.itemRefs = [];
    this.size = (props.integrationBadges.length / NUMBER_OF_ROWS) * ITEM_TOTAL_WIDTH;
  }

  animate = () => {
    const step = () => {
      window.requestAnimationFrame(step);

      this.itemRefs.forEach((item) => {
        item.xPos = item.xPos + 0.5;
        if (item.xPos > this.size - ITEM_TOTAL_WIDTH) {
          item.xPos -= this.size;
        }

        if (item.element) {
          item.element.style.transform = `translate(${item.xPos}px, ${item.yPos}px)`;
        }
      });
    };

    window.requestAnimationFrame(step);
  };

  componentDidMount() {
    this.animate();
  }

  setItems = (element, xPos, yPos) => {
    this.itemRefs.push({ element, xPos, yPos });
  };

  render() {
    const { integrationBadges, className } = this.props;
    const containerClasses = classnames(className, "integrations-slider-component");

    return (
      <div className={containerClasses}>
        {integrationBadges.map((i, index) => {
          // X Position is translated to be absolute the index * width
          const xPos = -1 * index * (ITEM_TOTAL_WIDTH / NUMBER_OF_ROWS) + MAX_INTEGRATIONS_FLOATING_WIDTH;

          // Y Position will change whether it's odd or even accordingly
          const yPos = index % 2 === 0 ? 0 : ITEM_TOTAL_HEIGHT;

          return (
            <div
              key={`integration_${index}`}
              className="integration-badge-wrapper"
              ref={(e) => this.setItems(e, xPos, yPos)}
            >
              <IntegrationBadge key={i.key} path={i.path} />
            </div>
          );
        })}

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
