import css from "styled-jsx/css";
import colors from "styles/colors";
import Constants from "segments/desktop/grid/layouts/blog-cards-grid/constants";
import { getComponentShadow, getComponentShadowWithHoverEffect } from "segments/desktop/constants";

const IMAGE_HEIGHT = `${Constants.IMAGE_HEIGHT}px`;

export const componentStyles = css.global`
  .blog-card-item-component {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    overflow: hidden;
    background: ${colors.white};
    ${getComponentShadow()}
    &:hover {
      ${getComponentShadowWithHoverEffect()}
    }
    display: flex;
    flex-direction: column;
    cursor: pointer;
    text-decoration: none;
    transition: transform 300ms ease, box-shadow 300ms ease;

    &:hover {
      transform: translateY(-10px);
    }

    .grid-image-wrapper-component {
      width: 100%;
      max-height: ${IMAGE_HEIGHT};
      overflow: hidden;
      flex-shrink: 0;
    }

    .blog-card-item-content {
      padding: 24px 24px 40px 24px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      color: ${colors.mud};

      .title-component-wrapper {
        flex-grow: 1;
      }

      .blog-footer-wrapper {
        flex-shrink: 0;
        margin-top: 24px;

        .button-container {
          width: 100%;
          display: flex;
          align-items: left;
        }
      }
    }
  }
`;
