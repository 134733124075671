import { useEffect, useState } from "react";
import { BOUNCE_ANIMATION_DURATION_MILISECONDS, BOUNCE_PERCENTAGE_OFFSET } from "./constants";
import Title from "segments/desktop/core-components/title/title";
import Colors from "/styles/colors";

const Word = (props) => {
  const [changing, setChanging] = useState(false);
  const { text, color, titleSize, wordIndex, direction } = props;

  useEffect(() => {
    setChanging(true);
    setTimeout(() => setChanging(false), BOUNCE_ANIMATION_DURATION_MILISECONDS);
  }, [wordIndex]);

  const getStyle = () => {
    const minWordIndex = direction === 1 ? 0 : -1;
    const padded = changing && wordIndex > minWordIndex;

    return {
      color: Colors[color],
      transform: `translateY(${-100 * wordIndex - (padded ? direction * BOUNCE_PERCENTAGE_OFFSET : 0)}%)`
    };
  };

  return (
    <span className="word" style={getStyle()}>
      <Title title={text} titleSize={titleSize} />
    </span>
  );
};

export default Word;
