import dynamic from "next/dynamic";

const ButtonTagsGridComponent = require("./button-tags-grid-component").default;

import ButtonTagsGridConfig from "./button-tags-grid-config";
import ButtonTagsGridAdvancedConfig from "./button-tags-grid-advanced-config";

export default {
  ButtonTagsGridComponent,
  ButtonTagsGridConfig,
  ButtonTagsGridAdvancedConfig
};
