export default [
  {
    type: "timeline",
    offset: 75,
    duration: 0,
    pictureSrc: "/static/img/first-fold/timeline_1.png",
    classToggle: "timeline-first-item-end",
    className: "timeline-first-item-start"
  },

  {
    type: "timeline",
    offset: 125,
    duration: 0,
    pictureSrc: "/static/img/first-fold/timeline_2.png",
    classToggle: "fade-in",
    className: "timeline-second-item-start"
  },

  {
    type: "timeline",
    offset: 150,
    duration: 0,
    pictureSrc: "/static/img/first-fold/timeline_3.png",
    classToggle: "fade-in",
    className: "timeline-third-item-start"
  },

  {
    type: "timeline",
    offset: 175,
    duration: 0,
    pictureSrc: "/static/img/first-fold/timeline_4.png",
    classToggle: "fade-in",
    className: "timeline-fourth-item-start"
  },

  {
    type: "timeline",
    offset: 200,
    duration: 0,
    pictureSrc: "/static/img/first-fold/timeline_5.png",
    classToggle: "fade-in",
    className: "timeline-fifth-item-start"
  },

  {
    type: "timeline",
    offset: 225,
    duration: 0,
    pictureSrc: "/static/img/first-fold/timeline_6.png",
    classToggle: "fade-in",
    className: "timeline-sixth-item-start"
  },

  {
    type: "timeline",
    offset: 250,
    duration: 0,
    pictureSrc: "/static/img/first-fold/timeline_7.png",
    classToggle: "fade-in",
    className: "timeline-seventh-item-start"
  }
];
