import css from "styled-jsx/css";
import { getComponentShadow } from "segments/desktop/constants";
import { MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";

export const componentStyles = css`
  .use-case-product-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${getComponentShadow()}
    gap: 32px;
    padding: 16px 24px;
    .use-case-product-banner-logo-and-text-wrapper {
      display: flex;
      gap: 16px;
      .use-case-product-banner-logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        .core-badge-component {
          height: fit-content;
          margin-top: 10px;
        }
        svg {
          margin-top: 4px;
          border-radius: 8px;
          border: 1px solid #dcdfec;
          width: 52px;
          height: 52px;
        }
      }
      .use-case-product-banner-text {
        text-align: left;
        max-width: 940px;
      }
    }
  }
  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    .use-case-product-banner {
      display: flex;
      flex-direction: column;
      margin: 0 32px 32px;
      padding: 8px 16px 20px;
      gap: 16px;
      .use-case-product-banner-logo-and-text-wrapper {
        display: flex;
        flex-direction: column;
        gap: 0px;
        .use-case-product-banner-logo {
          svg {
            margin-top: 0;
            border: none;
          }
        }
        .use-case-product-banner-text {
          text-align: center;
          :global(.full-paragraph) {
            font-size: 0.8125rem;
            line-height: 21px;
            font-weight: 400;
          }
        }
      }
    }
  }
`;
