import AssetComponent from "components/core/asset/asset-component";
import Colors from "styles/colors";
import classnames from "classnames";
import { isDarkTheme } from "segments/segments-service";
import { useMemo } from "react";
import Title from "segments/desktop/core-components/title/title";
import DefaultProps from "./default-props";
import { componentStyles } from "./testimonial.scss";

const Testimonial = ({ quote, asset, author, company, backgroundColor }) => {
  const additionalClassName = useMemo(() => {
    if (!backgroundColor) return;
    return isDarkTheme(backgroundColor) ? "white" : "black";
  }, [backgroundColor]);

  return (
    <div
      className={classnames("testimonial", additionalClassName)}
      style={{ backgroundColor: Colors[backgroundColor] }}
    >
      <div className="content">
        <Title {...quote} />
        <div className="author">
          {asset?.src && <AssetComponent {...{ image: asset }} className="author-image" />}
          <div className="author-details-container">
            <div className="author-name">{author}</div>
            <span className="company">{company}</span>
          </div>
        </div>
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default Testimonial;

Testimonial.defaultProps = DefaultProps;
