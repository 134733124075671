import { FIELD_TYPES } from "constants/template-field-type";
import SideBySideTextAndAssetFieldsConfig from "segments/desktop/core-components/side-by-side-text-and-asset/fields-config";
import iconsConfig from "segments/desktop/core-components/icons/fields-config";

const { background, ...selectedSideBySideTextAndAssetFieldsConfig } = SideBySideTextAndAssetFieldsConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "Side By Side With Icons",
  fields: {
    ...selectedSideBySideTextAndAssetFieldsConfig,
    iconsConfig
  }
};
