import { PureComponent } from "react";
import { componentStyles } from "./simpo.scss";
import DefaultProps from "./default-props";

const MAIN_SCRIPT = `!function(){var r=window.actionbar=window.actionbar||[];if(!r.initialize&&!r.invoked){r.invoked=!0,r.methods=["configure","identify","reset","ready","debug","off","on"],r.generator=function(t){return function(){var e=Array.prototype.slice.call(arguments);return e.unshift(t),r.push(e),r}};for(var e=0;e<r.methods.length;e++){var t=r.methods[e];r[t]=r.generator(t)}r.load=function(e){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src="https://cdn.simpo.io/actionbar.js",r.ucid=e;var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n)},r.SNIPPET_VERSION="2.0.0"}}();;
actionbar.load("fnvrfechqj2ppxqjue0ketduibbin46thb9wcatpbz8");`;

const STARTUP_SCRIPT = `actionbar.configure({
  bar: {
      embedMode: 'inline',
      container: '.action-bar-wrapper',
      width: '100%'
  },
  widget: {
      show: false
  }
});
`;

const HIDE_SCRIPT = `actionbar.on('ready', function() {
  actionbar.jQuery("form[role='search']").remove();
});`;

export default class Simpo extends PureComponent {
  constructor(props) {
    super(props);
    this._instance = null;
  }

  componentDidMount() {
    this.runScript(MAIN_SCRIPT);
    this.runScript(STARTUP_SCRIPT);
    this.runScript(HIDE_SCRIPT);
  }

  runScript(script) {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.innerHTML = script;
    this._instance?.appendChild(s);
  }

  render() {
    return (
      <div className="simpo-core-wrapper" ref={(el) => (this._instance = el)}>
        <div className="action-bar-wrapper" />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

Simpo.defaultProps = DefaultProps;
