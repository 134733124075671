import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";

export const componentStyles = css`
  .paragraph-and-carousel-reviews-component {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    margin: auto;
    :global(.full-paragraph-component-wrapper) {
      padding-bottom: 100px;
    }
    :global(.image-wrapper) {
      filter: drop-shadow(-16px 23px 56px rgba(29, 140, 242, 0.3));
    }
  }
`;
