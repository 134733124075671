import { useState } from "react";
import PictureComponent from "components/core/picture/picture-component";
import DownArrow from "static/svg/small-down-arrow.svg";
import classnames from "classnames";
import {
  SECONDARY_BUTTON_TYPE,
  SIGNUP_BUTTON_TYPE,
  SIGNUP_COMBO_BUTTONS_TYPE
} from "segments/desktop/core-components/button/button-consts";
import { XXS } from "components/core/new-button/button-component";
import { componentStyles } from "./product-card-grid-mobile-component.scss";
import ProductCardGridBodyComponent from "./product-card-grid-body-component";
import { trackEvent } from "/services/bigbrain-service";
import { MOBILE_PRODUCT_COMPONENT_OPENED, MOBILE_PRODUCT_COMPONENT_CLOSED } from "/constants/bigbrain-event-types";
import {productCardsImages, productIndexToProductType} from "segments/desktop/grid/layouts/product-cards-grid/default-props";
import {PRODUCTS_THEMES} from "/constants/products";

const ProductCardGridMobileComponent = (props) => {
  const { body, useCases, buttonConfig, productType, index, showOpen } = props;
  const [isOpen, setIsOpen] = useState(showOpen);

  const onCardHeaderClick = () => {
    if (!isOpen) {
      trackEvent(MOBILE_PRODUCT_COMPONENT_OPENED, {
        kind: productType,
        index
      });
    } else {
      trackEvent(MOBILE_PRODUCT_COMPONENT_CLOSED, {
        kind: productType,
        index
      });
    }
    setIsOpen(!isOpen);
  };

  const getProductButtonConfig = () => {
    switch (buttonConfig.buttonType) {
      case SIGNUP_BUTTON_TYPE:
        return {
          ...buttonConfig,
          invert: true,
          buttonSize: XXS
        };
      case SIGNUP_COMBO_BUTTONS_TYPE:
        return {
          ...buttonConfig,
          signupButtonConfig: {
            ...buttonConfig.signupButtonConfig,
            invert: true,
            buttonSize: XXS
          }
        };
      default:
        return buttonConfig;
    }
  };


  const productCardButtonConfig = getProductButtonConfig();
  const mobileImageProp = productType ? productCardsImages?.[productType]?.mobileImage: productCardsImages?.[productIndexToProductType[index]]?.mobileImage; 
  const productTheme = productType ? PRODUCTS_THEMES[productType] : PRODUCTS_THEMES[productIndexToProductType[index]];

  return (
    <div className={classnames("mobile-product-card", { open: isOpen })}>
      <div className="card-content">
        <div className="product-card-header" onClick={onCardHeaderClick}>
            <div className="product-card-header-image">
              <PictureComponent {...mobileImageProp} />
            </div>
          <div className={classnames("arrow", { open: isOpen })}>
            <DownArrow />
          </div>
        </div>
        <div className="mobile-product-card-body">
          <ProductCardGridBodyComponent
            body={body}
            showDivider={false}
            useCases={useCases}
            theme={productTheme}
            productCardButtonConfig={productCardButtonConfig}
          />
        </div>
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

ProductCardGridMobileComponent.defaultProps = {
  showDivider: true,
  buttonConfig: {
    buttonType: SECONDARY_BUTTON_TYPE,
    buttonText: "Learn more",
    isOpenLinkInNewTab: true
  }
};

export default ProductCardGridMobileComponent;
