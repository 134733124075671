import { PureComponent } from "react";

import { themes } from "segments/segments-service";

import PersonalTextualInfo from "segments/desktop/core-components/person-textual-info/person-textual-info";
import Picture from "/components/core/picture/picture-component";

import testimonialCardDefaultProps from "./default-props";
import componentStyle from "./testimonial-card-component.scss";

import classnames from "classnames";

export default class TestimonialCardComponent extends PureComponent {
  getThemeStyle() {
    const { theme } = this.props;
    return themes[theme] || {};
  }

  getQuoteInlineStyle() {
    const { fontSize, titleSize } = this.props;

    const inlineStyles = {};

    if (!titleSize) inlineStyles.fontSize = fontSize;

    return inlineStyles;
  }

  render() {
    const { quote, name, jobDescription, companyImage, titleSize, alt } = this.props;
    const themeStyle = this.getThemeStyle();
    const quoteInlineStyle = this.getQuoteInlineStyle();

    return (
      <div className="testimonial-card-component" style={themeStyle}>
        {companyImage && (
          <div className="company-image-wrapper">
            <Picture src={companyImage} alt={alt} />
          </div>
        )}

        <div className="testimonial-card-content-wrapper">
          <div
            className={classnames("quote-wrapper", titleSize)}
            dangerouslySetInnerHTML={{ __html: quote }}
            style={quoteInlineStyle}
          />
          <div className="person-details-wrapper">
            <PersonalTextualInfo name={name} jobDescription={jobDescription} color={themeStyle.color} />
          </div>
        </div>
        <style jsx>{componentStyle}</style>
      </div>
    );
  }
}

TestimonialCardComponent.defaultProps = testimonialCardDefaultProps;
