import { isServerSide } from "utils/general";
import { useCallback } from "react";
import classNames from "classnames";
import useIsMounted from "client/services/hooks/use-is-mounted";
import { componentStyles } from "./canvas.scss";

const Canvas = (props) => {
  const { shapeSegments, isPreview, rootEl } = props;

  // This is used for re-rendering the canvas-component div
  // Since server renders it with "ssr" class
  // Then when client hydrates the new DOM, it does not
  // re-evaluate the new classes
  // isMounted is running only on client! (using useEffect)
  const isMounted = useIsMounted();

  const getCanvasComponent = useCallback(() => {
    const sortedShapes = shapeSegments.sort((a, b) => {
      const bZindex = parseFloat(b?.props?.zIndex) || 0;
      const aZindex = parseFloat(a?.props?.zIndex) || 0;

      return aZindex - bZindex;
    });

    if (isServerSide()) {
      // Render different canvas component on SSR
      // Used only for first fold, to avoid flickering effects
      // Temp. commented out until memory leaks are solved on server side
      // const SsrCanvas = require("./ssr-canvas").default;
      // return <SsrCanvas shapeSegments={sortedShapes} />;
      return null;
    } else {
      if (!rootEl) return null;

      const ReactCanvas = require("./react-canvas").default;
      return <ReactCanvas shapeSegments={sortedShapes} isPreview={isPreview} rootEl={rootEl} />;
    }
  }, [shapeSegments, isPreview, rootEl]);

  return (
    <div
      key={`canvas_${isServerSide() ? "server" : "client"}_${isMounted}`}
      className={classNames("canvas-component", { ssr: isServerSide(), preview: isPreview })}
    >
      {getCanvasComponent()}
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default Canvas;
