import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";

export const componentStyles = css`
  .side-by-side-hubspot-form-component {
    max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
    margin: auto;

    :global(.side-by-side-asset-and-paragraph) {
      display: flex;
      flex-direction: column;
    }
  }
`;
