import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import Carousel from "segments/desktop/core-components/carousel/carousel";
import PictureComponent from "components/core/picture/picture-component";
import { componentStyles } from "./review-cards-reviews-mobile-component.scss";
import ReviewCardComponent from "../review-card-reviews-component/review-card-reviews-component";

export const ReviewCardsReviewsComponentMobileComponent = (props) => {
  const { fullParagraph, marginAboveCards, cards, spaceFromParagraph, buttonTopMargin, titleSize, textSize } = props;

  const { topic, buttonConfig, topicBottomMargin, ...fullParagraphForMobile } = fullParagraph;
  const fullParagraphAdvancedProps = { spaceFromParagraph, buttonTopMargin, titleSize, textSize, topic: {} };

  const itemRenderer = (card) => {
    return (
      <div className="card-item">
        <ReviewCardComponent reviewCardProps={card} />
      </div>
    );
  };

  const carouselProps = {
    items: cards,
    itemRenderer,
    defaultIndex: 0,
    infinite: false,
    slidesToShow: 1,
    dots: true,
    autoplaySpeed: 5000,
    centerPadding: "15%"
  };

  return (
    <div className="review-cards-reviews-mobile-component">
      <div className="paragraph-and-topic">
        <div className="paragraph-wrapper">
          <FullParagraph {...fullParagraphForMobile} {...fullParagraphAdvancedProps} />
        </div>
        <div className="topic-wrapper" style={{ "--topic-margin": topicBottomMargin }}>
          <PictureComponent {...topic?.image} />
        </div>
      </div>
      <div className="carousel-wrapper" style={{ "--margin-above-cards": marginAboveCards }}>
        <Carousel {...carouselProps} />
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};
