import { PureComponent } from "react";
import { componentStyles } from "./all-logos-logos-component.scss";
import Logos from "segments/desktop/core-components/logos/logos";

export default class AllLogosLogosComponent extends PureComponent {
  render() {
    return (
      <div className="all-logos-logos-component">
        <Logos showAllLogos={true} />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

AllLogosLogosComponent.defaultProps = {
  paddingTop: "68px",
  paddingBottom: "68px",
  mobilePaddingTop: "24px",
  mobilePaddingBottom: "24px"
};
