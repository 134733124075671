import { PureComponent } from "react";
import classnames from "classnames";
import { Controller, Scene } from "react-scrollmagic";
import ScenePicture from "../scene-picture/scene-picture-component";
import PictureComponent from "components/core/picture/picture-component";
import { componentStyles } from "./folding-table-component.scss";
import { configs } from "./scenes";

class FoldingTable extends PureComponent {
  renderScenes = () => {
    return configs.map((scene, index) => {
      const { duration, pictureSrc, offset, className, classToggle, type } = scene;
      const itemClasses = classnames("scene-item", `${type}-item`, className);

      return (
        <Scene
          key={`scene_${index}`}
          duration={duration}
          offset={offset || 0}
          triggerHook={0}
          triggerElement=".header-wrapper"
          classToggle={classToggle}
          reverse={true}
          indicators={false}
        >
          {(progress) => (
            <div className="scene-picture-wrapper" className={itemClasses}>
              <ScenePicture pictureSrc={pictureSrc} stopAnimation={false} />
            </div>
          )}
        </Scene>
      );
    });
  };

  render() {
    return (
      <div className="folding-table-container">
        <Controller>{this.renderScenes()}</Controller>
        <PictureComponent src="/static/img/first-fold/table.png" className="table-picture-component" />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

export default FoldingTable;
