import Layouts from "./layouts";
const {
  // plop_marker:segment_index_import_layout
  CarouselTestimonials,
  PlainClassicTestimonials
} = Layouts;

export default {
  // plop_marker:segment_index_add_layout
  CarouselTestimonialsComponent: {
    type: "CarouselTestimonialsComponent",
    title: "Testimonials Carousel",
    imgSrc: "/static/img/segments/layouts/carousel-testimonials.png",
    config: CarouselTestimonials.CarouselTestimonialsConfig,
    advancedConfig: CarouselTestimonials.CarouselTestimonialsAdvancedConfig,
    component: CarouselTestimonials.CarouselTestimonialsComponent
  },
  PlainClassicTestimonialsComponent: {
    type: "PlainClassicTestimonialsComponent",
    title: "Plain Classic",
    imgSrc: "/static/img/segments/layouts/plain-classic-testimonials.png",
    config: PlainClassicTestimonials.PlainClassicTestimonialsConfig,
    advancedConfig: PlainClassicTestimonials.PlainClassicTestimonialsAdvancedConfig,
    component: PlainClassicTestimonials.PlainClassicTestimonialsComponent
  }
};
