import withResponsiveHOC from "/components/enhancements/responsive-hoc/responsive-hoc-component";
import SideBySideWithIconsTextAndAssetDekstopComponent from "./side-by-side-with-icons-text-and-asset-dekstop-component.js";
import SideBySideWithIconsTextAndAssetMobileComponent from "./side-by-side-with-icons-text-and-asset-mobile-component.js";
import DefaultProps from "./default-props";

const SideBySideWithIconsTextAndAsset = withResponsiveHOC(
  SideBySideWithIconsTextAndAssetDekstopComponent,
  SideBySideWithIconsTextAndAssetMobileComponent
);

export default SideBySideWithIconsTextAndAsset;

SideBySideWithIconsTextAndAsset.defaultProps = DefaultProps;
