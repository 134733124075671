import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
import { AUTO, BEST, ECO, GOOD, LOW } from "constants/cloudinary-asset-qualities";

export default {
  type: FIELD_TYPES.NESTED,
  label: "",
  fields: {
    ...SharedAdvancedFieldsConfig,
    assetQuality: {
      type: FIELD_TYPES.COMBO,
      label: "Asset Quality",
      options: [
        { value: AUTO, text: "Auto" },
        { value: BEST, text: "Best" },
        { value: GOOD, text: "Good" },
        { value: ECO, text: "Eco" },
        { value: LOW, text: "Low" }
      ]
    },
    width: { type: FIELD_TYPES.TEXT, label: "Asset width in desktop (% only)" },
    mobileWidth: { type: FIELD_TYPES.TEXT, label: "Asset width in mobile (% only)" },
    fullWidth: { type: FIELD_TYPES.BOOLEAN, label: "Disable maximum width limitation (1440px)" }
  }
};
