import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import { BOUNCE_ANIMATION_DURATION_MILISECONDS } from "./constants";

const TRANSITION_DURATION = `${BOUNCE_ANIMATION_DURATION_MILISECONDS}ms`;

export const componentStyles = css`
  .rotating-title-paragraph-component {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    margin: auto;

    .body {
      display: flex;
      justify-content: center;
      font-variant-ligatures: none;

      .title {
        overflow: hidden;
      }

      .words {
        overflow: hidden;
        margin-left: 16px;
        flex-shrink: 0;

        :global(.word) {
          text-align: left;
          display: block;
          height: 100%;
          transition-property: transform;
          transition-duration: ${TRANSITION_DURATION};
          transition-timing-function: ease;
        }
      }
    }
  }
`;
