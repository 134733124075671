import UniverseStarComponent from "./universe-star/universe-star-component";
import DefaultProps from "./default-props";

const UniverseStars = (props) => {
  const { stars } = props;

  return (
    <>
      {stars.map((star, index) => {
        return <UniverseStarComponent key={`star-${index}`} leftRange={3} rightRange={10} {...star} />;
      })}
    </>
  );
};

export default UniverseStars;

UniverseStars.defaultProps = DefaultProps;
