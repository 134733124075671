import { FIELD_TYPES } from "constants/template-field-type";
import ProcessTabsConfig from "segments/desktop/core-components/process-tabs/fields-config";
import ThemeConfig from "segments/core-configs/theme-config";
import VerticalTimerTabsConfig from "segments/desktop/core-components/vertical-timer-tabs/fields-config";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import TitleConfig from "segments/desktop/core-components/title/fields-config";

const {
  timerEnabled,
  buttonConfig,
  showButton,
  progressbarColor,
  backgroundColor,
  tabs: {
    nestedConfig: {
      fields: { timer, ...nestedTabsConfig }
    },
    ...tabsConfig
  },
  ...rest
} = VerticalTimerTabsConfig;

const {
  textAlign,
  mobileTextAlign,
  buttonConfig: titleButtonConfig,
  title,
  titleColor,
  textColor,
  bodyColor,
  topic,
  ...resOfParagraphConfig
} = FullParagraphFieldsConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "Side By Side Tabs And Process",
  fields: {
    ...ThemeConfig,
    title: {
      type: FIELD_TYPES.NESTED,
      label: "Title",
      fields: {
        ...TitleConfig,
        titleMargin: { label: "Title bottom margin", type: FIELD_TYPES.TEXT }
      }
    },
    tabs: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Tabs",
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          title: { type: FIELD_TYPES.TEXT, label: "Tab Title", translatable: true },
          overrideTabFontColor: { type: FIELD_TYPES.COLOR_PICKER, label: "Title Font Color" },
          content: {
            type: FIELD_TYPES.NESTED,
            label: "Tab Full Paragraph",
            fields: { ...resOfParagraphConfig, buttonConfig: titleButtonConfig }
          },
          processTab: ProcessTabsConfig
        },
        ...nestedTabsConfig
      },
      ...tabsConfig
    },
    ...rest
  }
};
