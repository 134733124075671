import dynamic from "next/dynamic";

const ImageWithParagraphGridComponent = require("./image-with-paragraph-grid-component").default;

import ImageWithParagraphGridConfig from "./image-with-paragraph-grid-config";
import ImageWithParagraphGridAdvancedConfig from "./image-with-paragraph-grid-advanced-config";

export default {
  ImageWithParagraphGridComponent,
  ImageWithParagraphGridConfig,
  ImageWithParagraphGridAdvancedConfig
};
