import css from "styled-jsx/css";

export const componentStyles = css`
  .monday-demo-tabs-asset-wrapper {
    position: absolute;
    opacity: 0;
    top: 0;
    width: 100%;
    &.current {
      opacity: 1;
    }
  }
`;
