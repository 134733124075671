import { PureComponent } from "react";
import TitleOnlyParagraph from "segments/desktop/core-components/title-only-paragraph/title-only-paragraph";
import Reviews from "segments/desktop/core-components/reviews/reviews";
import ReviewCardDefaultProps from "segments/desktop/core-components/review-card/default-props";
import { componentStyles } from "./with-title-reviews-component.scss";

export default class WithTitleReviewsComponent extends PureComponent {
  render() {
    const { reviews, titleOnlyParagraph, cardFontSize, reviewCardOverrideHeight, minHeight } = this.props;

    return (
      <div className="with-title-reviews-component" style={{ minHeight }}>
        <div className="title-only-paragrph-component-wrapper">
          <TitleOnlyParagraph {...titleOnlyParagraph} />
        </div>

        <div className="review-cards-wrapper">
          <Reviews cardFontSize={cardFontSize} reviews={reviews} reviewCardOverrideHeight={reviewCardOverrideHeight} />
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

WithTitleReviewsComponent.defaultProps = {
  titleOnlyParagraph: { ...TitleOnlyParagraph.defaultProps, title: "Why Teams love monday.com" },
  ...Reviews.defaultProps,
  paddingTop: "92px",
  paddingBottom: "92px",
  cardFontSize: ReviewCardDefaultProps.fontSize,
  reviewCardOverrideHeight: null
};
