import SideBySideTextAndAssetComponent from "segments/desktop/text-and-asset/layouts/side-by-side-text-and-asset/side-by-side-text-and-asset-component";
import iconsConfig from "segments/desktop/core-components/icons/default-props";
import { TWO_LINK_BUTTONS_TYPE } from "segments/desktop/core-components/button/button-consts";
import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import { LG } from "constants/sizes";

export default {
  iconsConfig,
  ...SideBySideTextAndAssetComponent.defaultProps,
  asset: {
    image: {
      src:
        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/customer-support/support_globe.png"
    }
  },
  fullParagraph: {
    topic: {},
    title: "Supporting <light>your growth<br>every step of the way</light>",
    body:
      "Our support superheroes are a click away to help you get the most out of monday.com, so you can focus on working without limits.",
    buttonConfig: {
      buttonType: TWO_LINK_BUTTONS_TYPE,
      swapButtonsOrder: true,
      regularButtonConfig: {
        color: WORK_OS_IRIS_COLOR_NAME,
        buttonText: "Get in touch",
        url: "https://monday.com/contact-us"
      },
      secondaryButtonConfig: {
        color: WORK_OS_IRIS_COLOR_NAME,
        buttonText: "Visit our support center",
        url: "https://support.monday.com/hc/en-us"
      }
    }
  },
  titleSize: LG,
  leftSizeWidth: "60%",
  rightSizeWidth: "40%",
  mobileMarginBetweenAssetAndButton: "48px"
};
