import { PureComponent } from "react";
import classnames from "classnames";
import times from "lodash/times";
import { componentStyles } from "./stars-component.scss";
import PictureComponent from "/components/core/picture/picture-component";

export default class StarsComponent extends PureComponent {
  renderStars() {
    const { numberOfSelectedStars, starsCount } = this.props;
    const numberOfSelectedStarsAsInt = parseInt(numberOfSelectedStars);
    const starsCountAsInt = parseInt(starsCount);

    return times(starsCountAsInt, (index) => {
      const isSelected = numberOfSelectedStarsAsInt > index;

      return (
        <div key={index} className={classnames("star-icon-wrapper", { selected: isSelected })}>
          <PictureComponent src={"/static/img/star.png"} />
        </div>
      );
    });
  }

  render() {
    const { paddingTop } = this.props;

    return (
      <div className="stars-component" style={{ paddingTop }}>
        {this.renderStars()}

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

StarsComponent.defaultProps = {
  paddingTop: 0,
  numberOfSelectedStars: "5",
  starsCount: "5"
};
