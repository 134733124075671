import FullParagraphComponent from "segments/desktop/paragraph/layouts/full-paragraph/full-paragraph-component";
import SelectableTagsGridComponent from "segments/desktop/grid/layouts/selectable-tags-grid/selectable-tags-grid-component";
import { isDarkTheme } from "segments/segments-service";
import { componentStyles } from "./full-paragraph-and-tags-homepage-first-fold-component.scss";
import DefaultProps from "./default-props";

const FullParagraphAndTagsHomepageFirstFoldComponent = (props) => {
  const { marginAboveTags, fullParagraph, selectableTags, theme, titleSize, textSize } = props;

  const extraFullParagraphParams = {
    titleSize,
    textSize
  };

  return (
    <div className="full-paragraph-and-tags-homepage-first-fold-component" style={{ gap: marginAboveTags }}>
      <FullParagraphComponent {...fullParagraph} {...extraFullParagraphParams} />
      <SelectableTagsGridComponent {...selectableTags} websiteAlignmentBranding darkMode={isDarkTheme(theme)} />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

FullParagraphAndTagsHomepageFirstFoldComponent.defaultProps = DefaultProps;

export default FullParagraphAndTagsHomepageFirstFoldComponent;
