import { FIELD_TYPES } from "constants/template-field-type";
import TitleConfig from "segments/desktop/core-components/title/fields-config";
import FullParagraphConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import ThemeConfig from "segments/core-configs/theme-config";

const { topic, title, ...restParagraphProps } = FullParagraphConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "Side By Side Title and Paragraph",
  fields: {
    ...ThemeConfig,
    titleProps: {
      type: FIELD_TYPES.NESTED,
      label: "Title",
      fields: TitleConfig
    },
    paragraphProps: {
      type: FIELD_TYPES.NESTED,
      label: "Paragraph",
      fields: restParagraphProps
    }
  }
};
