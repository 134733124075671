import { FIELD_TYPES } from "constants/template-field-type";
import TwoThirdsTextAndAssetConfig from "segments/desktop/text-and-asset/layouts/two-thirds-text-and-asset/two-thirds-text-and-asset-config";
import bulletsConfig from "segments/desktop/core-components/bullets/fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Regular",
  fields: {
    tabsColor: {
      type: FIELD_TYPES.COLOR_PICKER,
      label: "Tabs color"
    },
    tabs: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Tabs",
      maxLimit: 8,
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          tabLabel: { type: FIELD_TYPES.TEXT, label: "Tab Label", translatable: true },
          tabId: { type: FIELD_TYPES.TEXT, label: "Tab Id" },
          textAndAsset: {
            type: FIELD_TYPES.NESTED,
            label: "Text and asset",
            fields: { ...TwoThirdsTextAndAssetConfig.fields, bulletsConfig }
          }
        }
      }
    }
  }
};
