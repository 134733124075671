import { FIELD_TYPES } from "constants/template-field-type";
import TagsFieldsConfig from "segments/desktop/core-components/tag/fields-config";
import ThemeConfig from "segments/core-configs/theme-config";
import { TRANSPARENT_COLOR_NAME } from "styles/color-consts";
import { newBrandColors } from "constants/color-picker-constants";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Tags Grid",
  fields: {
    ...ThemeConfig,
    gridTagsBackgroundColor: {
      type: FIELD_TYPES.COLOR_PICKER,
      label: "Tags background color",
      possibleColors: [...newBrandColors, TRANSPARENT_COLOR_NAME]
    },
    gridTagsImageHeight: { type: FIELD_TYPES.TEXT, label: "Tag images height" },
    gridTags: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Tag",
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          ...TagsFieldsConfig
        }
      }
    }
  }
};
