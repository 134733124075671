import { FIELD_TYPES } from "constants/template-field-type";
import Reviews from "components/templates-generator/config/segments/reviews/reviews-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Social Proof",
  fields: {
    Signup: {
      type: FIELD_TYPES.NESTED,
      label: "Signup",
      fields: {
        buttonColor: {
          type: FIELD_TYPES.COLOR_PICKER,
          label: "Signup Button Color",
          possibleColors: ["brand-blue", "brand-pink", "brand-iris", "brand-green", "brand-red", "brand-purple"]
        }
      }
    },
    Reviews
  }
};
