import { FIELD_TYPES } from "constants/template-field-type";
import PersonalTextualInfoConfig from "segments/desktop/core-components/person-textual-info/fields-config";

const { color, ...selectedTextualInfoFields } = PersonalTextualInfoConfig;

export default {
  decorationColor: {
    type: FIELD_TYPES.COLOR_PICKER,
    label: "Decoration Color"
  },
  title: {
    type: FIELD_TYPES.TEXT,
    label: "Title",
    textArea: true,
    translatable: true
  },
  showStars: { type: "boolean", label: "Show Stars" },
  starsCount: {
    type: FIELD_TYPES.COMBO,
    label: "How many stars",
    options: ["1", "2", "3", "4", "5"]
  },
  ...selectedTextualInfoFields
};
