import { PureComponent } from "react";
import AssetComponent from "components/core/asset/asset-component";

const MONDAY_LOADER_IMAGE = { src: "/static/img/loader/monday_loader.gif" };

export default class MondayLoaderComponent extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="loader-wrapper">
        <AssetComponent image={MONDAY_LOADER_IMAGE} />
      </div>
    );
  }
}
