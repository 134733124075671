import Layouts from "./layouts";
const {
  // plop_marker:segment_index_import_layout
  VideoBackgroundAsset,
  CarouselAsset,
  VerticalTagsWithImagesAsset,
  ImagesCarouselWithTagsGridAsset,
  EmbeddedYoutubeAsset,
  YoutubeVideoAsset,
  RegularAsset
} = Layouts;

export default {
  // plop_marker:segment_index_add_layout
  VideoBackgroundAssetComponent: {
    type: "VideoBackgroundAssetComponent",
    title: "Video Background",
    imgSrc: "/static/img/segments/layouts/video-background-asset.png",
    config: VideoBackgroundAsset.VideoBackgroundAssetConfig,
    advancedConfig: VideoBackgroundAsset.VideoBackgroundAssetAdvancedConfig,
    component: VideoBackgroundAsset.VideoBackgroundAssetComponent
  },
  CarouselAssetComponent: {
    type: "CarouselAssetComponent",
    title: "Carousel",
    imgSrc: "/static/img/segments/layouts/carousel-asset.png",
    config: CarouselAsset.CarouselAssetConfig,
    advancedConfig: CarouselAsset.CarouselAssetAdvancedConfig,
    component: CarouselAsset.CarouselAssetComponent
  },
  VerticalTagsWithImagesAssetComponent: {
    type: "VerticalTagsWithImagesAssetComponent",
    title: "Vertical Tags With Images",
    imgSrc: "/static/img/segments/layouts/vertical-tags-with-images-asset.png",
    config: VerticalTagsWithImagesAsset.VerticalTagsWithImagesAssetConfig,
    advancedConfig: VerticalTagsWithImagesAsset.VerticalTagsWithImagesAssetAdvancedConfig,
    component: VerticalTagsWithImagesAsset.VerticalTagsWithImagesAssetComponent
  },
  ImagesCarouselWithTagsGridAssetComponent: {
    type: "ImagesCarouselWithTagsGridAssetComponent",
    title: "Images Carousel With Tags Grid",
    imgSrc: "/static/img/segments/layouts/images-carousel-with-tags-grid-asset.png",
    config: ImagesCarouselWithTagsGridAsset.ImagesCarouselWithTagsGridAssetConfig,
    advancedConfig: ImagesCarouselWithTagsGridAsset.ImagesCarouselWithTagsGridAssetAdvancedConfig,
    component: ImagesCarouselWithTagsGridAsset.ImagesCarouselWithTagsGridAssetComponent
  },
  EmbeddedYoutubeAssetComponent: {
    type: "EmbeddedYoutubeAssetComponent",
    title: "Embedded Youtube",
    imgSrc: "/static/img/segments/layouts/embedded-youtube-asset.png",
    config: EmbeddedYoutubeAsset.EmbeddedYoutubeAssetConfig,
    advancedConfig: EmbeddedYoutubeAsset.EmbeddedYoutubeAssetAdvancedConfig,
    component: EmbeddedYoutubeAsset.EmbeddedYoutubeAssetComponent
  },
  YoutubeVideoAssetComponent: {
    type: "YoutubeVideoAssetComponent",
    title: "Youtube Video Popup",
    imgSrc: "/static/img/segments/layouts/youtube-video-asset.png",
    config: YoutubeVideoAsset.YoutubeVideoAssetConfig,
    advancedConfig: YoutubeVideoAsset.YoutubeVideoAssetAdvancedConfig,
    component: YoutubeVideoAsset.YoutubeVideoAssetComponent
  },
  RegularAssetComponent: {
    type: "RegularAssetComponent",
    title: "Regular",
    imgSrc: "/static/img/segments/layouts/regular-asset.png",
    config: RegularAsset.RegularAssetConfig,
    advancedConfig: RegularAsset.RegularAssetAdvancedConfig,
    component: RegularAsset.RegularAssetComponent
  }
};
