import withResponsiveHOC from "components/enhancements/responsive-hoc/responsive-hoc-component";
import DefaultProps from "./default-props";
import SideBySideBoardViewsComponentTabsMobileComponent from "./side-by-side-board-views-component-tabs-mobile-component/side-by-side-board-views-component-tabs-mobile-component";
import SideBySideBoardViewsComponentTabsDesktopComponent from "./side-by-side-board-views-component-tabs-desktop-component/side-by-side-board-views-component-tabs-desktop-component";

const SideBySideBoardViewsComponentTabsComponent = withResponsiveHOC(
  SideBySideBoardViewsComponentTabsDesktopComponent,
  SideBySideBoardViewsComponentTabsMobileComponent
);

SideBySideBoardViewsComponentTabsComponent.defaultProps = DefaultProps;

export default SideBySideBoardViewsComponentTabsComponent;
