import dynamic from "next/dynamic";

const IframeDynamicWidthIframeComponent = require("./iframe-dynamic-width-iframe-component").default;

import IframeDynamicWidthIframeConfig from "./iframe-dynamic-width-iframe-config";
import IframeDynamicWidthIframeAdvancedConfig from "./iframe-dynamic-width-iframe-advanced-config";

export default {
  IframeDynamicWidthIframeComponent,
  IframeDynamicWidthIframeConfig,
  IframeDynamicWidthIframeAdvancedConfig
};
