import withResponsiveHOC from "/components/enhancements/responsive-hoc/responsive-hoc-component";
import UseCasesTabsDesktopComponent from "./use-cases-tabs-desktop-component/use-cases-tabs-desktop-component.js";
import UseCasesTabsMobileComponent from "./use-cases-tabs-mobile-component/use-cases-tabs-mobile-component.js";
import DefaultProps from "./default-props";

const UseCasesTabsComponent = withResponsiveHOC(UseCasesTabsDesktopComponent, UseCasesTabsMobileComponent);

export default UseCasesTabsComponent;

UseCasesTabsComponent.defaultProps = DefaultProps;
