import { FIELD_TYPES } from "constants/template-field-type";
import FullTestimonialsQuoteFieldsConfig from "segments/desktop/core-components/full-testimonials-quote/fields-config";
import SideBySideTextAndAssetFieldsConfig from "segments/desktop/core-components/side-by-side-text-and-asset/fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Testimonials Side By Side",
  fields: {
    ...SideBySideTextAndAssetFieldsConfig,
    fullParagraph: {
      type: FIELD_TYPES.NESTED,
      label: "FullTestimonialsQuoteFieldsConfig",
      fields: FullTestimonialsQuoteFieldsConfig
    }
  }
};
