import classnames from "classnames";
import { componentStyles } from "./regular-asset-component.scss";
import Colors from "styles/colors";
import AssetComponent from "components/core/asset/asset-component";
import { withGenericConfig } from "contexts/generic-config-context";
import { AUTO } from "constants/cloudinary-asset-qualities";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { trackEvent } from "/services/bigbrain-service";
import { ASSET_COMPONENT_IS_FULLY_VISIBLE } from "constants/bigbrain-event-types";
import { withSegmentsData } from "contexts/segment-context";

const RegularAssetComponent = (props) => {
  const {
    isMobileOrTabletBrowser,
    backgroundColor,
    width,
    mobileWidth,
    position,
    asset,
    assetQuality,
    useDynamicAsset,
    hideControls,
    dynamicAssetParamPath,
    fullWidth,
    useWindowMask,
    minHeight,
    segmentNumber
  } = props;
  const finalWidth = isMobileOrTabletBrowser && mobileWidth ? mobileWidth : width;
  const widthInPercentage = finalWidth ? `${finalWidth}%` : "auto";
  const [assetRef, assetFullyInView] = useInView({
    threshold: 1.0,
    triggerOnce: true
  });

  useEffect(() => {
    if (asset && assetFullyInView) {
      const { image, video } = asset;
      trackEvent(ASSET_COMPONENT_IS_FULLY_VISIBLE, { kind: image?.src || video?.src, info1: segmentNumber });
    }
  }, [assetFullyInView]);

  return (
    <div
      className="regular-asset-component"
      style={{ backgroundColor: Colors[backgroundColor], minHeight }}
      ref={assetRef}
    >
      <div className={classnames("asset-component-wrapper", position, { "full-width": fullWidth })}>
        <AssetComponent
          {...asset}
          assetQuality={assetQuality}
          useDynamicAsset={useDynamicAsset}
          hideControls={hideControls}
          width={widthInPercentage}
          convertGifToVideo={true}
          className="regular-asset-image-wrapper"
          imageClassName="regular-asset-image"
          dynamicAssetParamPath={dynamicAssetParamPath}
          useWindowMask={useWindowMask}
        />
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

RegularAssetComponent.defaultProps = {
  width: 100,
  position: "center",
  asset: {
    image: {
      src: "/static/img/segment-layout-default-images/asset/timeline-view.png"
    }
  },
  assetQuality: AUTO,
  fullWidth: false
};

export default withSegmentsData(withGenericConfig(RegularAssetComponent));
