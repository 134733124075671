import SideBySideTextAndAssetComponent from "segments/desktop/text-and-asset/layouts/side-by-side-text-and-asset/side-by-side-text-and-asset-component";
import BoardTabs from "segments/desktop/core-components/board-tabs/board-tabs";
import BoardTabsDefaultProps from "segments/desktop/core-components/board-tabs/default-props";
import { componentStyles } from "./board-tab-text-and-asset-component.scss";

const BoardTabTextAndAssetComponent = (props) => {
  const { boardTabsProps, ...restProps } = props;

  return (
    <div className="board-tab-text-and-asset-component">
      <SideBySideTextAndAssetComponent
        replaceAssetComponent={BoardTabs}
        replaceAssetComponentProps={{
          ...boardTabsProps,
          isSmallTabs: true,
          contentWrapperClassName: "board-side-by-side-content-asset-wrapper"
        }}
        allowImageOverflow={true}
        {...restProps}
      />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default BoardTabTextAndAssetComponent;

BoardTabTextAndAssetComponent.defaultProps = {
  ...SideBySideTextAndAssetComponent.defaultProps,
  asset: {},
  boardTabsProps: BoardTabsDefaultProps
};
