import { coupon, extendTrialCookie, exitPopupConfig } from "./components";
import { FIELD_TYPES } from "constants/template-field-type";
import { TEMPLATE_NAMES } from "constants/templates-names";
import AUTOCOMPLETE_TYPES from "constants/autocomplete-types";

const DESKTOP_DYNAMIC_TEMPLATE = { name: TEMPLATE_NAMES.DynamicTemplate, label: "Dynamic Template [Desktop]" };
const MOBILE_DYNAMIC_TEMPLATE = { name: TEMPLATE_NAMES.MobileDynamicTemplate, label: "Dynamic Template [Mobile]" };
const DEVELOPERS_CENTER_TEMPLATE = {
  name: TEMPLATE_NAMES.DevelopersCenterTemplate,
  label: "Developers Center Template"
};
const WELCOME_NEW_JOINERS_TEMPLATE = {
  name: TEMPLATE_NAMES.WelcomeNewJoinersTemplate,
  label: "Welcome New Joiners Template"
};
const REMOTE_WORK_TEMPLATE = {
  name: TEMPLATE_NAMES.RemoteWorkTemplate,
  label: "Remote Work Template"
};

export const TEMPLATE_TYPES = [
  DESKTOP_DYNAMIC_TEMPLATE,
  MOBILE_DYNAMIC_TEMPLATE,
  DEVELOPERS_CENTER_TEMPLATE,
  WELCOME_NEW_JOINERS_TEMPLATE,
  REMOTE_WORK_TEMPLATE,
  { name: TEMPLATE_NAMES.LongTemplate, label: "Landing Page Template" },
  { name: TEMPLATE_NAMES.SegmentsTemplate, label: "Multi Segment Template" },
  { name: TEMPLATE_NAMES.FixedLongLpTemplate, label: "Fixed Long Landing Page" },
  { name: TEMPLATE_NAMES.ArticleTemplate, label: "Article Template" },
  { name: TEMPLATE_NAMES.DirectoryImagesTemplate, label: "Directory Images Template" },
  {
    name: TEMPLATE_NAMES.MultipleDirectoryImagesTemplate,
    label: "Multiple Directories Template"
  },
  { name: TEMPLATE_NAMES.OnePagerAssetTemplate, label: "One Pager Asset Template" },
  { name: TEMPLATE_NAMES.OnePagerWithImageTemplate, label: "One Pager With Image Template" },
  {
    name: TEMPLATE_NAMES.MobileOnePagerWithImageTemplate,
    label: "Mobile only One Pager With Image Template"
  },
  { name: TEMPLATE_NAMES.OnePagerWithVideoTemplate, label: "One Pager With Video Template" },
  { name: TEMPLATE_NAMES.CompetitorTemplate, label: "Competitor Template" }
];

export const ADDABLE_TEMPLATE_TYPES = [
  DESKTOP_DYNAMIC_TEMPLATE,
  DEVELOPERS_CENTER_TEMPLATE,
  WELCOME_NEW_JOINERS_TEMPLATE
];

import { LONG_TEMPLATE_CONFIG } from "./long-template/long-template-config";
import { SEGMENTS_TEMPLATE_CONFIG } from "./segments-template/segments-template-config";
import { FIXED_LONG_LP_TEMPLATE_CONFIG } from "./fixed-long-lp-template/fixed-long-lp-template-config";
import { ARTICLE_TEMPLATE_CONFIG } from "./article-template/article-template-config";
import { DIRECTORY_IMAGES_TEMPLATE_CONFIG } from "./directory-images-template/directory-images-template-config";
import { MULTIPLE_DIRECTORY_IMAGES_TEMPLATE_CONFIG } from "./multiple-directory-images-template/multiple-directory-images-template-config";
import { ONE_PAGER_ASSET_TEMPLATE_CONFIG } from "./one-pager-asset-template/one-pager-asset-template-config";
import { ONE_PAGER_WITH_IMAGE_TEMPLATE_CONFIG } from "./one-pager-with-image-template/one-pager-with-image-template-config";
import { MOBILE_ONE_PAGER_WITH_IMAGE_TEMPLATE_CONFIG } from "./mobile-one-pager-with-image-template/mobile-one-pager-with-image-template-config";
import { ONE_PAGER_WITH_VIDEO_TEMPLATE_CONFIG } from "./one-pager-with-video-template/one-pager-with-video-template-config";
import { COMPETITOR_TEMPLATE_CONFIG } from "./competitor-template/competitor-template-config";
import { DESKTOP_DYNAMIC_TEMPLATE_CONFIG } from "./desktop-dynamic-template/desktop-dynamic-template-config";
import { MOBILE_DYNAMIC_TEMPLATE_CONFIG } from "./mobile-dynamic-template/mobile-dynamic-template-config";
import { DEVELOPERS_CENTER_TEMPLATE_CONFIG } from "./developers-center-template/developers-center-template-config";
import { WELCOME_NEW_JOINERS_TEMPLATE_CONFIG } from "./welcome-new-joiners-template/welcome-new-joiners-template-config";
import { REMOTE_WORK_TEMPLATE_CONFIG } from "./remote-work-template/remote-work-template-config";
import { DYNAMIC_ASSET_PARAM_PATHS } from "/constants/dynamic-asset-by-param";
import { DYNAMIC_ASSET_PARAM } from "/constants/query-params";

const TEMPLATE_CONFIGS = {
  LongTemplate: LONG_TEMPLATE_CONFIG,
  SegmentsTemplate: SEGMENTS_TEMPLATE_CONFIG,
  FixedLongLpTemplate: FIXED_LONG_LP_TEMPLATE_CONFIG,
  ArticleTemplate: ARTICLE_TEMPLATE_CONFIG,
  DirectoryImagesTemplate: DIRECTORY_IMAGES_TEMPLATE_CONFIG,
  MultipleDirectoryImagesTemplate: MULTIPLE_DIRECTORY_IMAGES_TEMPLATE_CONFIG,
  OnePagerAssetTemplate: ONE_PAGER_ASSET_TEMPLATE_CONFIG,
  OnePagerWithImageTemplate: ONE_PAGER_WITH_IMAGE_TEMPLATE_CONFIG,
  MobileOnePagerWithImageTemplate: MOBILE_ONE_PAGER_WITH_IMAGE_TEMPLATE_CONFIG,
  OnePagerWithVideoTemplate: ONE_PAGER_WITH_VIDEO_TEMPLATE_CONFIG,
  CompetitorTemplate: COMPETITOR_TEMPLATE_CONFIG,
  DynamicTemplate: DESKTOP_DYNAMIC_TEMPLATE_CONFIG,
  MobileDynamicTemplate: MOBILE_DYNAMIC_TEMPLATE_CONFIG,
  DevelopersCenterTemplate: DEVELOPERS_CENTER_TEMPLATE_CONFIG,
  WelcomeNewJoinersTemplate: WELCOME_NEW_JOINERS_TEMPLATE_CONFIG,
  RemoteWorkTemplate: REMOTE_WORK_TEMPLATE_CONFIG
};

const getTemplateBaseConfig = (templateName) => {
  const templateConfig = TEMPLATE_CONFIGS[templateName];
  const { isMobile } = templateConfig.defaultData;

  return {
    template: { type: FIELD_TYPES.CONSTANT, label: "Template Type" },
    path: { type: FIELD_TYPES.HOMEPAGE_URL, label: "Page URL" },
    mobilePageUrl: {
      type: FIELD_TYPES.AUTOCOMPLETE,
      label: "Mobile Page Url",
      hide: isMobile,
      autocompleteType: AUTOCOMPLETE_TYPES.PAGES
    },
    title: { type: FIELD_TYPES.TEXT, label: "Topic Title" },
    description: { type: FIELD_TYPES.TEXT, label: "Topic Description" },
    clusterMiniSite: { type: FIELD_TYPES.BOOLEAN, label: "Serve through Mini Site" },
    useProductMiniSiteConfig: { type: FIELD_TYPES.BOOLEAN, label: "Use product Mini Site" },
    pageProductId: { type: FIELD_TYPES.PRODUCTS, label: "Connect to product signup" },
    clusterId: { type: FIELD_TYPES.CLUSTERS, label: "Cluster" },
    subClusterId: { type: FIELD_TYPES.SUB_CLUSTERS, label: "Use Case" },
    fetchClusterConfig: { type: FIELD_TYPES.BOOLEAN, label: "Fetch User Cluster Config" },
    ignorePageClusterConfig: { type: FIELD_TYPES.BOOLEAN, label: "Ignore clusterized page behaviour" },
    localeId: { type: FIELD_TYPES.LOCALES, label: "Locale" },
    imagesLazyLoad: { type: FIELD_TYPES.BOOLEAN, label: "Images Lazy Load (Deprecated!)" },
    lazyImages: { type: FIELD_TYPES.BOOLEAN, label: "Images Lazy Load" },
    skipClustersQuestion: { type: FIELD_TYPES.BOOLEAN, label: "Skip cluster question" },
    withFullstory: { type: FIELD_TYPES.BOOLEAN, label: "With FullStory" },
    fullstoryRecordingFrequency: {
      type: FIELD_TYPES.FULLSTORY_RECORDING_FREQUENCY,
      label: "FullStory Recording Frequency"
    },
    ogImagePath: { type: FIELD_TYPES.TEXT, label: "Facebook and Linkedin Sharable Image Preview (1200 X 628)" },
    twitterImagePath: { type: FIELD_TYPES.TEXT, label: "Twitter Sharable Image Preview (800 X 418)" },
    dynamicMetaTagImagesParamPath: {
      type: FIELD_TYPES.COMBO,
      label: "Use image from parameter",
      labelDescription: `Image (?${DYNAMIC_ASSET_PARAM}=xxxxx) from param build Cloudinary url under the selected path below, and will use it as meta-tags images.`,
      options: DYNAMIC_ASSET_PARAM_PATHS
    },
    dynamicMetaTagImageWidth: {
      type: FIELD_TYPES.TEXT,
      label: "Width of dynamic shareable image"
    },
    dynamicMetaTagImageHeight: {
      type: FIELD_TYPES.TEXT,
      label: "Height of dynamic shareable image"
    },
    canonicalUrl: { type: FIELD_TYPES.TEXT, label: "Canonical Url" },
    abTestName: { type: FIELD_TYPES.TEXT, label: "AB Test Name" },
    abTestVariant: { type: FIELD_TYPES.TEXT, label: "AB Test Variant" },
    marketingTemplateBoardIds: {
      type: FIELD_TYPES.TEXT,
      label: "Featured Board Template IDs (comma separated)"
    },
    showTrustpilotReviews: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Show trustpilot reviews (Appears only with the footer)"
    },
    fontLazyLoad: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Load fonts after page displays"
    },
    aboveTheFoldSegmentsNumber: {
      type: FIELD_TYPES.TEXT,
      label: "Number of segments above the fold"
    },
    useExpandingSignup: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Use expanding signup"
    },
    noIndex: {
      type: FIELD_TYPES.BOOLEAN,
      label: "No index (default false)"
    },
    noFollow: {
      type: FIELD_TYPES.BOOLEAN,
      label: "No follow (default false)"
    },
    disableGTM: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Disable GTM (DON'T TOUCH!)"
    },
    gtmContainerId: {
      type: FIELD_TYPES.TEXT,
      label: "GTM Container ID"
    },
    useSectionsMenu: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Use sections Menu (Don't touch)"
    },
    enableDriftChatBot: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Enable Drift Chat Bot"
    },
    driftPreventLoadOnLaunch: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Prevent drift from launching immediately (Load will be called seperately)"
    },
    driftOpenChatDialogOnLaunch: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Drift - Launch chatbot dialog open"
    },
    driftDelayedLoadMilliseconds: {
      type: FIELD_TYPES.TEXT,
      label: "Drift delayed load in milliseconds(for mobile)"
    },
    enableHotjar: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Enable Hotjar",
      labelDescription: "Selecting a recording expiration date is required"
    },
    hotjarFinalDate: {
      type: FIELD_TYPES.CALENDAR,
      label: "Hotjar recording expiration date",
      calendarDaysRangeSize: 14
    },
    enablePageUsageDurationTracking: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Enable Page Usage Duration Tracking",
      labelDescription: "Via page_usage_duration event name and info1 as the time passed in the page"
    },
    loadCompanyDetails: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Load company details (Clearbit)"
    },
    pageBackgroundColorName: {
      type: FIELD_TYPES.COLOR_PICKER,
      label: "Page background color"
    },
    is404Page: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Is 404 page"
    },
    useSignUpPopUp: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Test signUp pop up window"
    },
    testTypeformContactSales: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Test Typeform contact sales (in released regions)"
    },
    forceTypeformContactSalesExperience: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Force Typeform contact sales experience (for Bizops QA)"
    },
    sendToSignupWithProductPage: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Send to signup with product page"
    },
    addLiveBoardExperience: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Add live board experience"
    },
    embeddedBoardLink: {
      type: FIELD_TYPES.TEXT,
      label: "Embedded board link"
    },
    contactSalesTestMode: {
      type: FIELD_TYPES.BOOLEAN,
      label: "Contact sales form test mode (don't use)"
    },
    coupon,
    extendTrialCookie
  };
};

const getAdvancedTemplateBaseConfig = () => {
  return {
    exitPopupConfig
  };
};

const getConfigWithoutSegments = (templateName) => {
  const specificTemplateConfig = TEMPLATE_CONFIGS[templateName];
  const { segments, ...restTemplateConfig } = specificTemplateConfig;

  return restTemplateConfig;
};

export const getTemplateConfig = (templateName) => {
  const baseConfig = getTemplateBaseConfig(templateName);
  const advancedBaseConfig = getAdvancedTemplateBaseConfig();
  const specificConfig = getConfigWithoutSegments(templateName);

  return { ...baseConfig, ...specificConfig, ...advancedBaseConfig };
};

export const getSegmentsConfig = (templateName) => {
  const specificTemplateConfig = TEMPLATE_CONFIGS[templateName];
  const { segments } = specificTemplateConfig;

  return segments ? { segments } : {};
};

export const getTemplateConfigWithoutBase = (templateName) => {
  // create a copy so we won't change the original
  const specificConfig = getConfigWithoutSegments(templateName);

  return { ...specificConfig };
};

export const getSegmentsDynamicsFetchMapper = (templateName) => {
  const templateConfig = TEMPLATE_CONFIGS[templateName];
  return templateConfig.segments?.reduce((acc, segment) => {
    segment.segmentLayouts.forEach((item) => {
      if (item) {
        const { type, dynamicsFetch } = item;
        if (dynamicsFetch) {
          acc[type] = dynamicsFetch;
        }
      }
    });

    return acc;
  }, {});
};
