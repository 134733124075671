import { PureComponent } from "react";
import Constants from "segments/desktop/grid/layouts/blog-cards-grid/constants";
import { componentStyles } from "./blog-cards-grid-component.scss";
import BlogCardItemComponent from "./blog-card-item/blog-card-item-component";

export default class BlogCardsGridComponent extends PureComponent {
  renderBlogCards() {
    const { gridItems, hideFooter, buttonConfig, imageMaxHeight } = this.props;

    return gridItems.map((gridItem, index) => {
      return (
        <div key={index} className="blog-card-item-component-wrapper">
          <BlogCardItemComponent
            {...gridItem}
            hideFooter={hideFooter}
            buttonConfig={buttonConfig}
            imageMaxHeight={imageMaxHeight}
          />
        </div>
      );
    });
  }

  render() {
    return (
      <div className="blog-cards-grid-component">
        {this.renderBlogCards()}

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

BlogCardsGridComponent.defaultProps = {
  hideFooter: false,
  gridItems: [
    {
      image: { src: "/static/img/segment-layout-default-images/grid/painted-people.png" },
      title: "New Feature Updates: Check Out Our New Zoom Integration!",
      footer: {
        writerImageSrc: "/static/img/segment-layout-default-images/grid/article-writer-woman.png",
        writerName: "Eliana Atia",
        articleDate: "Mar 12,2020",
        minutesToRead: "3 min read"
      }
    },
    {
      image: { src: "/static/img/segment-layout-default-images/grid/monday-zoom-integration.png" },
      title: "6 reasons why distributed teams are the future of work",
      footer: {
        writerImageSrc: "/static/img/segment-layout-default-images/grid/article-writer-woman.png",
        writerName: "Eliana Atia",
        articleDate: "Mar 12,2020",
        minutesToRead: "8 min read"
      }
    },
    {
      image: { src: "/static/img/segment-layout-default-images/grid/persoon-with-mountain-view.png" },
      title: "How Kim Garst rapidly scaled her business",
      footer: {
        writerImageSrc: "/static/img/segment-layout-default-images/grid/article-writer-woman.png",
        writerName: "Eliana Atia",
        articleDate: "Mar 12,2020",
        minutesToRead: "16 min read"
      }
    }
  ],
  paddingTop: 64,
  paddingBottom: 64,
  buttonConfig: {
    buttonType: null
  },
  imageMaxHeight: Constants.IMAGE_HEIGHT
};
