import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "",
  fields: {
    textAndLogosMargin: { type: FIELD_TYPES.TEXT, label: "Space between text and logo" },
    ...SharedAdvancedFieldsConfig
  }
};
