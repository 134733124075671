import css from "styled-jsx/css";
import { SPACE_BLUE_COLOR_NAME } from "styles/color-consts";
import colors from "styles/colors";

export const componentStyles = css`
  .scroll-parallax-homepage-first-fold-mobile-component {
    background: ${colors[SPACE_BLUE_COLOR_NAME]};
    :global(.full-paragraph-component-wrapper) {
      padding-bottom: 20px;
    }

    :global(.middle-paragraph-and-asset) {
      padding-bottom: 24px;
    }

    :global(.top-asset-wrapper) {
      :global(.asset-inner) {
        padding-top: 72px;
      }
    }

    :global(.top-asset-wrapper),
    :global(.bottom-asset-wrapper) {
      :global(.parallax-galaxy-wrapper) {
        transform: scale(0.4);
        top: 5vw;
        right: -28vw;
        position: relative;
      }
    }

    :global(.middle-asset-wrapper) {
      width: 100%;
      min-height: 250px;
      :global(.typing-wrapper) {
        display: flex;
        margin-top: 24px;
        :global(.picture-component) {
          padding-right: 8px;
          :global(img) {
            width: 40px;
          }
        }
      }
      :global(.typing-wrapper:last-child) {
        :global(.typed-component) {
          display: flex;
        }
      }
    }

    :global(.side-by-side-text-and-asset) {
      :global(.side-by-side-content.is-full-width) {
        padding-top: 60px;
        padding-bottom: 0;
      }
    }
  }
`;
