// plop_marker:layouts_import
import VideoBackgroundAsset from "./video-background-asset";
import CarouselAsset from "./carousel-asset";
import VerticalTagsWithImagesAsset from "./vertical-tags-with-images-asset";
import ImagesCarouselWithTagsGridAsset from "./images-carousel-with-tags-grid-asset";
import EmbeddedYoutubeAsset from "./embedded-youtube-asset";
import YoutubeVideoAsset from "./youtube-video-asset";
import RegularAsset from "./regular-asset";

export default {
  // plop_marker:layouts_add_layout
  VideoBackgroundAsset,
  CarouselAsset,
  VerticalTagsWithImagesAsset,
  ImagesCarouselWithTagsGridAsset,
  EmbeddedYoutubeAsset,
  YoutubeVideoAsset,
  RegularAsset,
};
