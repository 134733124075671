import PictureComponent from "components/core/picture/picture-component";
import FullParagraphComponent from "segments/desktop/paragraph/layouts/full-paragraph/full-paragraph-component";
import Carousel from "segments/desktop/core-components/carousel/carousel";
import { componentStyles } from "./paragraph-and-carousel-reviews-mobile-component.scss";

export const ParagraphAndCarouselReviewsMobileComponent = (props) => {
  const { carousel, fullParagraph, mobileMarginBetweenParagraphAndCarousel, removeQuoteSigns } = props;
  const { reviews } = carousel;

  const itemRenderer = (review) => {
    const { customerImage, name, jobDescription, quote, companyLogo } = review;
    return (
      <div className="review-carousel-item">
        <div className="customer-image-wrapper">
          {customerImage?.src && (
            <PictureComponent className="customer-image" src={customerImage?.src} alt={customerImage?.alt} />
          )}
        </div>
        <div className="review-card">
          <div className="name-and-job-description">
            {name} | {jobDescription}
          </div>
          <div className="quote">{removeQuoteSigns ? quote : `“ ${quote}“`}</div>
          <div className="company-logo-wrapper">
            {companyLogo?.src && (
              <PictureComponent className="company-logo" src={companyLogo?.src} alt={companyLogo?.alt} />
            )}
          </div>
        </div>
      </div>
    );
  };

  const carouselProps = {
    items: reviews,
    itemRenderer,
    defaultIndex: 0,
    infinite: false,
    slidesToShow: 1,
    dots: true,
    autoplaySpeed: 5000,
    centerPadding: "15%"
  };

  return (
    <div
      className="paragraph-and-carousel-reviews-mobile-component"
      style={{ "--margin-between-paragraph-and-carousel": mobileMarginBetweenParagraphAndCarousel }}
    >
      <FullParagraphComponent {...fullParagraph} />
      <div className="carousel-wrapper">
        <Carousel {...carouselProps} />
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};
