import dynamic from "next/dynamic";

const BasicButtonComponent = require("./basic-button-component").default;

import BasicButtonConfig from "./basic-button-config";
import BasicButtonAdvancedConfig from "./basic-button-advanced-config";

export default {
  BasicButtonComponent,
  BasicButtonConfig,
  BasicButtonAdvancedConfig
};
