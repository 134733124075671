import { FIELD_TYPES } from "constants/template-field-type";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";

export default {
  fullParagraph: {
    type: FIELD_TYPES.NESTED,
    label: "Full Paragraph",
    fields: FullParagraphFieldsConfig
  },
  backgroundImage: {
    type: FIELD_TYPES.NESTED,
    label: "Background Image",
    fields: {
      src: {
        type: FIELD_TYPES.GALLERY,
        defaultImagesKeys: [],
        label: "Select an Image",
        getFullUrl: true
      },
      alt: {
        type: FIELD_TYPES.TEXT,
        label: "Image description (Don't fill if image is a decorative image)",
        translatable: true
      }
    }
  }
};
