import css from "styled-jsx/css";
import Colors from "styles/colors";
import { MOBILE_QUERY_MAX_WIDTH } from "/constants/vp-sizes";
import { SPACE_BLUE_COLOR_NAME } from "styles/color-consts";

const tagSelectedBackgroundColor = "#dff0ff";
const tagSelectedBackgroundColorDarkMode = "#49497D";

export const componentStyles = css.global`
  .selectable-tag-wrapper {
    @mixin visible-checkmark {
      background-color: currentColor;
      box-shadow: inset 0px 0px 0px 1.5px currentColor;
      border: none;
    }

    padding: 4px 16px 4px 4px;
    border-radius: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 6px 12px 6px 0;
    background: ${Colors["riverstone"]};
    &.dark-mode {
      background: ${Colors["midnightblue"]};
      &.selected {
        background-color: ${tagSelectedBackgroundColorDarkMode};

        &:hover {
          background-color: ${tagSelectedBackgroundColorDarkMode};
        }
      }
      &.square .selectable-tag-image-container {
        background: ${Colors[SPACE_BLUE_COLOR_NAME]};
      }
    }

    @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
      &.mobile-clusters {
        margin-right: 6px;
        background: white;
        padding: 4px 12px 5px 4px;

        &:hover {
          background: white;
        }

        .tag-text {
          font-size: 0.844rem;
        }
      }
    }

    &.square {
      padding: 8px 16px 8px 12px;

      .selectable-tag-image-container {
        border-radius: 2px;
        width: 18px;
        height: 18px;
        background-color: ${Colors["white"]};
        box-shadow: none;
        border: 1px solid currentColor;
      }

      .tag-text {
        font-size: 0.875rem;
      }

      &.selected {
        .selectable-tag-image-container {
          @include visible-checkmark;
        }
      }
    }

    &:hover {
      background: ${Colors["border-gray"]};
      outline: 0;
      &.dark-mode {
        background: ${Colors["midnightblue-hover"]};
      }
    }

    .selectable-tag-image-container {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;

      .selectable-tag-image {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    .tag-text {
      margin-left: 8px;
      font-size: 0.8125rem;
      line-height: 22px;
    }

    &.selected {
      background-color: ${tagSelectedBackgroundColor};

      &:hover {
        background-color: ${tagSelectedBackgroundColor};
      }
    }
  }
`;
