import { PureComponent } from "react";
import { componentStyles } from "./table-video-component.scss";
import YoutubeVideoModalComponent from "components/core/youtube-video-modal/youtube-video-modal-component";
import PictureComponent from "/components/core/picture/picture-component";
import { VIDEO_IMAGE_SCALE } from "../../constants";
import { YOUTUBE_MODAL_OPEN, YOUTUBE_MODAL_CLOSE } from "/constants/bigbrain-event-types";

export default class TableVideo extends PureComponent {
  state = {
    isOpened: false
  };

  componentDidMount() {
    this.setState({
      windowHeight: window.outerHeight * VIDEO_IMAGE_SCALE,
      windowWidth: window.outerWidth * VIDEO_IMAGE_SCALE
    });
  }

  openModal = () => {
    BigBrain("track", YOUTUBE_MODAL_OPEN, { kind: "monday_2_0_video" });
    this.setState({ isOpened: true });
  };

  closeModal = () => {
    BigBrain("track", YOUTUBE_MODAL_CLOSE, { kind: "monday_2_0_video" });
    this.setState({ isOpened: false });
  };

  onVideoEnd = () => {
    this.closeModal();
  };

  render() {
    const { isOpened, windowHeight, windowWidth } = this.state;
    const { videoId } = this.props;

    return (
      <div className="table-video-component">
        <div className="table-video-overlay" onClick={this.openModal}>
          <PictureComponent src="/static/img/first-fold/play-button.png" className="play-button" />
        </div>

        <YoutubeVideoModalComponent
          videoId={videoId}
          isOpen={isOpened}
          withSignup={false}
          onEnd={this.onVideoEnd}
          minHeight={546}
          opts={{
            width: windowWidth,
            height: windowHeight,
            playerVars: {
              autoplay: true,
              rel: 0,
              showinfo: 0
            }
          }}
          onCloseModal={this.closeModal}
        />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
