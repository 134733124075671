import { useMemo } from "react";
import { SegmentContext } from "/contexts/segment-context";
import { MAIN_ID } from "constants/element-ids";
import { ERROR_GROUPS } from "components/enhancements/error-wrapper/error-state-constants";
import { getDynamicPropsBySegmentLayoutId } from "utils/dynamicPage";
import { ErrorBoundary } from "react-error-boundary";
import { CLIENT_ERROR_STATE_CAUGHT } from "constants/bigbrain-event-types";
import BaseLayoutSegmentComponent from "./base-layout-segment/base-layout-segment-component";
import Canvas from "./canvas/canvas";
import { trackEvent } from "/services/bigbrain-service";
import {
  isFooterComponent,
  isHeaderComponent,
  isMainContentComponent,
  isShapeComponent
} from "../dynamic-template-service";

const SegmentsLayoutListComponent = (props) => {
  const { segments } = props;

  const components = useMemo(() => {
    return segments.filter((segment) => !isShapeComponent(segment.family));
  }, [segments]);

  const shapes = useMemo(() => {
    return segments.filter((segment) => isShapeComponent(segment.family));
  }, [segments]);

  const mainContentComponents = useMemo(() => {
    return segments.filter((segment) => isMainContentComponent(segment.type));
  }, [segments]);

  const withHeaderComponent = useMemo(() => {
    const firstComponentName = components.length > 0 ? components[0].type : "";
    return isHeaderComponent(firstComponentName);
  }, [components]);

  const withFooterComponent = useMemo(() => {
    const lastComponentName = components.length > 0 ? components[components.length - 1].type : "";
    return isFooterComponent(lastComponentName);
  }, [components]);

  const getSegmentNumber = (index) => {
    return withHeaderComponent ? index : index + 1;
  };

  const myErrorHandler = (error, info, segment) => {
    trackEvent(CLIENT_ERROR_STATE_CAUGHT, {
      placement: ERROR_GROUPS.SEGMENT_LAYOUT,
      info1: segment?.type,
      info2: JSON.stringify(error?.message || {}).substring(0, 500),
      info3: JSON.stringify(info?.componentStack || {}).substring(0, 500),
      data: segment?.props
    });
  };

  const renderSegment = (segment, index) => {
    const { isUserLoggedIn, extraProps, segmentComponents, isPreview, isMobile, dynamicData } = props;
    const segmentComponent = segmentComponents[index];
    const segmentType = segment?.type;

    return (
      <SegmentContext.Provider
        value={{
          isFirstSegment: mainContentComponents?.[0] === segment,
          segmentNumber: getSegmentNumber(index),
          segmentType
        }}
        key={index}
      >
        <ErrorBoundary
          FallbackComponent={() => {
            return null;
          }}
          onError={(error, info) => myErrorHandler(error, info, segment)}
        >
          <BaseLayoutSegmentComponent
            dynamicProps={getDynamicPropsBySegmentLayoutId(dynamicData, segment.id)}
            isMobile={isMobile}
            isPreview={isPreview}
            index={index}
            extraProps={extraProps}
            segment={segment}
            isUserLoggedIn={isUserLoggedIn}
            segmentComponent={segmentComponent}
          />
        </ErrorBoundary>
      </SegmentContext.Provider>
    );
  };

  const renderHeaderIfNeeded = () => {
    if (!withHeaderComponent) return null;

    const headerIndex = 0;
    return renderSegment(components[headerIndex], headerIndex);
  };

  const renderFooterIfNeeded = () => {
    if (!withFooterComponent) return null;

    const footerIndex = components.length - 1;
    return renderSegment(components[footerIndex], footerIndex);
  };

  const renderCanvasIfNeeded = () => {
    const { isPreview, rootEl } = props;

    if (shapes.length === 0) return null;

    return <Canvas shapeSegments={shapes} isPreview={isPreview} rootEl={rootEl} />;
  };

  const renderMainSegments = () => {
    return segments.map((s, index) => {
      if (!isHeaderComponent(s.type) && !isFooterComponent(s.type) && !isShapeComponent(s.family)) {
        return renderSegment(s, index);
      }
    });
  };

  return (
    <>
      {renderHeaderIfNeeded()}
      {renderCanvasIfNeeded()}
      <main id={MAIN_ID}>{renderMainSegments()}</main>
      {renderFooterIfNeeded()}
    </>
  );
};

export default SegmentsLayoutListComponent;
