import css from "styled-jsx/css";
import colors from "/styles/colors";

import { MOBILE_QUERY_MAX_WIDTH_M, MOBILE_QUERY_MAX_WIDTH, MACBOOK_PRO_MAX_WIDTH } from "/constants/vp-sizes";

const MAX_ASYMETRIC_DIV_HEIGHT = 180;
const MACBOOK_ASYMETRIC_DIV_HEIGHT = 116;
const MOBILE_ASYMETRIC_DIV_HEIGHT = 90;
const SMALL_MOBILE_ASYMETRIC_DIV_HEIGHT = 80;

const widthBorder = (colorName) => {
  return `50vw solid ${colors[colorName]}`;
};

const heightBorder = (colorName, divHeight) => {
  return `${divHeight / 2}px solid ${colors[colorName]}`;
};

export const componentStyles = function (topColor) {
  return css.global`
    .asymmetric-enterprise-component {
      background: ${colors["brand-enterprise-dark"]};
      display: flex;
      flex-direction: column;
      align-items: center;

      .asymetric-top-line-part {
        height: ${MAX_ASYMETRIC_DIV_HEIGHT} + "px";
        width: 100%;

        border-right: ${widthBorder("brand-enterprise-dark")};
        border-left: ${widthBorder(topColor)};
        border-bottom: ${heightBorder("brand-enterprise-dark", MAX_ASYMETRIC_DIV_HEIGHT)};
        border-top: ${heightBorder(topColor, MAX_ASYMETRIC_DIV_HEIGHT)};

        @media (max-width: ${MACBOOK_PRO_MAX_WIDTH}) {
          height: ${MACBOOK_ASYMETRIC_DIV_HEIGHT} + "px";
          border-bottom: ${heightBorder("brand-enterprise-dark", MACBOOK_ASYMETRIC_DIV_HEIGHT)};
          border-top: ${heightBorder(topColor, MACBOOK_ASYMETRIC_DIV_HEIGHT)};
        }

        @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
          height: ${MOBILE_ASYMETRIC_DIV_HEIGHT}+ "px";
          border-bottom: ${heightBorder("brand-enterprise-dark", MOBILE_ASYMETRIC_DIV_HEIGHT)};
          border-top: ${heightBorder(topColor, MOBILE_ASYMETRIC_DIV_HEIGHT)};
        }

        @media (max-width: ${MOBILE_QUERY_MAX_WIDTH_M}) {
          height: ${SMALL_MOBILE_ASYMETRIC_DIV_HEIGHT}+ "px";
          border-bottom: ${heightBorder("brand-enterprise-dark", SMALL_MOBILE_ASYMETRIC_DIV_HEIGHT)};
          border-top: ${heightBorder(topColor, SMALL_MOBILE_ASYMETRIC_DIV_HEIGHT)};
        }
      }

      .enterprise-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;

        .security-logos-wrapper {
          margin-top: 32px;
        }

        .full-paragraph-component-wrapper {
          max-width: 670px;
          margin: 0 24px;
        }
      }

      .bottom-part {
        height: ${MAX_ASYMETRIC_DIV_HEIGHT - 20}+ "px";

        @media (max-width: ${MACBOOK_PRO_MAX_WIDTH}) {
          height: ${MACBOOK_ASYMETRIC_DIV_HEIGHT - 20}+ "px";
        }
        @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
          height: ${MOBILE_ASYMETRIC_DIV_HEIGHT - 20}+ "px";
        }

        @media (max-width: ${MOBILE_QUERY_MAX_WIDTH_M}) {
          height: ${SMALL_MOBILE_ASYMETRIC_DIV_HEIGHT - 20}+ "px";
        }
      }
    }
  `;
};
