import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH ,MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH } from "constants/vp-sizes";


export const componentStyles = css`
  .product-cards-grid-desktop-component {
    max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
    margin: auto;
    display: flex;
    justify-content: center;
  }
`;
