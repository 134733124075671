import dynamic from "next/dynamic";

const RegularReviewsComponent = require("./regular-reviews-component").default;

import RegularReviewsConfig from "./regular-reviews-config";
import RegularReviewsAdvancedConfig from "./regular-reviews-advanced-config";

export default {
  RegularReviewsComponent,
  RegularReviewsConfig,
  RegularReviewsAdvancedConfig
};
