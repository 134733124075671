import { BACKGROUND_GRAY_COLOR_NAME } from "styles/color-consts";

const USE_CASES_KEYS = {
  FINANCE: "Finance",
  PROJECT_MANAGEMENT: "Project management",
  MARKETING_WORKFLOWS: "Marketing workflows",
  CREATIVE_AND_DESIGN: "Creative and design",
  IT: "IT",
  TASK_MANAGEMENT: "Task management",
  OPERATIONS: "Operations",
  SALES_ENABLEMENT: "Sales enablement",
  CONSTRUCTION: "Construction",
  HR: "HR"
};

const getParagraphProps = ({ title, body }) => ({
  title,
  body,
  topic: {}
});

const tabsProps = {
  [USE_CASES_KEYS.FINANCE]: {
    alt: "Finance requests board, a finance overview dashboard and a Stripe notification"
  },
  [USE_CASES_KEYS.PROJECT_MANAGEMENT]: {
    alt: "Project requests & approvals board, team iteration gantt and a project approval process form",
  },
  [USE_CASES_KEYS.MARKETING_WORKFLOWS]: {
    alt: "Marketing calendar, social media post request form, click rate pie chart and monthly budget amount"
  },
  [USE_CASES_KEYS.CREATIVE_AND_DESIGN]: {
    alt: "Creative projects kanban, creative brief form request form and a user update with teammate's reply"
  },
  [USE_CASES_KEYS.IT]: {
    alt: "IT requests board with a request update and an IT ticket request form"
  },
  [USE_CASES_KEYS.TASK_MANAGEMENT]: {
    alt: "Team planning board, task overview dashboard and a user update with teammate's reply"
  },
  [USE_CASES_KEYS.OPERATIONS]: {
    alt: "Office request overview dashboard, request form and event RSVP board shown in the mobile app"
  },
  [USE_CASES_KEYS.SALES_ENABLEMENT]: {
    alt: "Sales enablement materials dashboard, a user update with teammates' replies and a slack automation"
  },
  [USE_CASES_KEYS.CONSTRUCTION]: {
    alt: "Construction project dashboard and a construction schedule board shown in the mobile app"
  },
  [USE_CASES_KEYS.HR]: {
    alt: "Recruitment pipeline dashboard, an application form and zoom, slack and gmail integrations"
  }
};

export default {
  theme: BACKGROUND_GRAY_COLOR_NAME,
  autoplay: true,
  autoplaySpeed: "10000",
  tabs: [
    {
      tabLabel: "Finance",
      tabContent: {
        asset: {
          image: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/use_case/Finance.png",
            alt: tabsProps[USE_CASES_KEYS.FINANCE].alt
          }
        },
        mobileAsset: {
          image: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/Use_case_mobile/Finance.png",
            alt: tabsProps[USE_CASES_KEYS.FINANCE].alt
          }
        },
        images: [
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Plan and manage budgets",
              body: "See a full breakdown of all cross-department budgets, track costs, and budget allocation in one place."
            })
          },
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Real-time dashboards",
              body: "Share data across your organization through fully customizable dashboards and widgets."
            })
          },
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Keep track of requests and approvals",
              body: "Manage and track in real-time all incoming requests and approvals by using a custom request form."
            })
          }
        ]
      }
    },
    {
      tabLabel: "Project management",
      tabContent: {
        asset: {
          image: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/use_case/Project_managemnt.png",
            alt: tabsProps[USE_CASES_KEYS.PROJECT_MANAGEMENT].alt
          }
        },
        mobileAsset: {
          image: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/use_case_mobile/Project_managemnt.png",
            alt: tabsProps[USE_CASES_KEYS.PROJECT_MANAGEMENT].alt
          }
        },
        images: [
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Collaborate on projects in real-time",
              body: "Collaborate with relevant stakeholders on projects. Stay on top of deadlines, priorities, and changes as they happen."
            })
          },
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Get a full project overview",
              body: "See where projects stand at a glance with Gantt and Kanban views to maximize your workflow."
            })
          },
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Plan and track projects",
              body: "Bring together deadlines, stakeholders, and budgets in one place. Plan, track, and manage every aspect of your project."
            })
          }
        ],
        banner: {
          productType: "projects",
          title: "<b>A product built for teams managing advanced projects</b>",
          body: "Explore monday projects for exclusive capabilities and integrations",
          buttonText: "Visit monday projects"
        }
      }
    },
    {
      tabLabel: "Marketing workflows",
      tabContent: {
        asset: {
          image: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/use_case/Marketing.png",
            alt: tabsProps[USE_CASES_KEYS.MARKETING_WORKFLOWS].alt
          }
        },
        mobileAsset: {
          image: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/use_case_mobile/Marketing.png",
            alt: tabsProps[USE_CASES_KEYS.MARKETING_WORKFLOWS].alt
          }
        },
        images: [
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Manage marketing requests",
              body: "Organize how you receive and handle marketing requests so you get them to the right people."
            })
          },
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Streamline content creation",
              body: "Organize and track progress at a glance to ensure every piece moves through the right stages."
            })
          },
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Centralize marketing projects",
              body: "Plan and track business goals, budgets, and marketing campaigns from A-Z."
            })
          }
        ],
        banner: {
          productType: "marketer",
          title: "<b>A product built for marketing and creative teams</b>",
          body: "Explore monday marketer for exclusive capabilities and integrations",
          buttonText: "Visit monday marketer"
        }
      }
    },
    {
      tabLabel: "Creative and design",
      tabContent: {
        asset: {
          image: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/use_case/Creative_and_design.png",
            alt: tabsProps[USE_CASES_KEYS.CREATIVE_AND_DESIGN].alt
          }
        },
        mobileAsset: {
          image: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/use_case_mobile/Creative_and_design.png",
            alt: tabsProps[USE_CASES_KEYS.CREATIVE_AND_DESIGN].alt
          }
        },
        images: [
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Shareable forms for quick approvals",
              body: "Create and customize your own forms to streamline creative and design requests and approvals, in one place."
            })
          },
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Organize digital assets in one place",
              body: "Manage and collaborate on digital assets, and easily share files with stakeholders and your team in one place."
            })
          },
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Design team planning",
              body: "Keep your design team's board organized, whether it's reviewing briefs, organizing files, and more."
            })
          }
        ]
      }
    },
    {
      tabLabel: "IT",
      tabContent: {
        asset: {
          image: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/use_case/IT.png",
            alt: tabsProps[USE_CASES_KEYS.IT].alt
          }
        },
        mobileAsset: {
          image: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/use_case_mobile/IT.png",
            alt: tabsProps[USE_CASES_KEYS.IT].alt
          }
        },
        images: [
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Visualize service desk performance",
              body: "See your IT operation at a glance with widget-rich dashboards illustrating SLAs, priorities, and issue types."
            })
          },
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Auto-assign IT requests",
              body: "Automate employee requests, assign them to the right person, and receive notifications when updates are made."
            })
          },
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Service management in one place",
              body: "View all requests in one place and view important information such as requester's details, issue type, and uploaded files."
            })
          }
        ]
      }
    },
    {
      tabLabel: "Task management",
      tabContent: {
        asset: {
          image: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/use_case/Task_management.png",
            alt: tabsProps[USE_CASES_KEYS.TASK_MANAGEMENT].alt
          }
        },
        mobileAsset: {
          image: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/use_case_mobile/Task_management.png",
            alt: tabsProps[USE_CASES_KEYS.TASK_MANAGEMENT].alt
          }
        },
        images: [
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Collaborate on tasks",
              body: "Work together effectively and on time with all task-related information, including files, ideas, and feedback."
            })
          },
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Turn requests into tasks",
              body: "Turn form submissions into tasks, and assign the right people to them to move work forward."
            })
          },
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Visualize task progress",
              body: "Custom dashboards display important info. such as status and priority to give you a clear picture of where tasks stand."
            })
          }
        ]
      }
    },
    {
      tabLabel: "Operations",
      tabContent: {
        asset: {
          image: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/use_case/Operations.png",
            alt: tabsProps[USE_CASES_KEYS.OPERATIONS].alt
          }
        },
        mobileAsset: {
          image: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/use_case_mobile/Operations.png",
            alt: tabsProps[USE_CASES_KEYS.OPERATIONS].alt
          }
        },
        images: [
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Manage online and offline events",
              body: "Manage and plan all your events in one place. Track tasks and statuses and make it easy for everyone to collaborate."
            })
          },
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Streamline facility requests",
              body: "Organize all facility requests in one place. Create a request form so employees can submit requests easily."
            })
          },
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Identify patterns and trends",
              body: "Gain insight into gaps within your organization and improve operations and workflows."
            })
          }
        ]
      }
    },
    {
      tabLabel: "Sales enablement",
      tabContent: {
        asset: {
          image: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/use_case/Sales.png",
            alt: tabsProps[USE_CASES_KEYS.SALES_ENABLEMENT].alt
          }
        },
        mobileAsset: {
          image: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/use_case_mobile/Sales.png",
            alt: tabsProps[USE_CASES_KEYS.SALES_ENABLEMENT].alt
          }
        },
        images: [
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "One place for sales enablement",
              body: "From case studies to pitch decks, organize and manage all sales materials. Let sales reps know when new content is ready."
            })
          },
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Real-time visibility",
              body: "Easily create dashboards that reflect team performance, deal status, sales activities, and more."
            })
          },
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Manage sales workflows",
              body: "Manage tasks and projects for the team, sales updates, and prospect inquiries in one place via custom forms."
            })
          }
        ],
        banner: {
          productType: "crm",
          title: "<b>A product built for customer-facing teams</b>",
          body: "Explore monday sales CRM for exclusive capabilities and integrations",
          buttonText: "Visit monday sales CRM"
        }
      }
    },
    {
      tabLabel: "Construction",
      tabContent: {
        asset: {
          image: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/use_case/Construction.png",
            alt: tabsProps[USE_CASES_KEYS.CONSTRUCTION].alt
          }
        },
        mobileAsset: {
          image: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/use_case_mobile/Construction.png",
            alt: tabsProps[USE_CASES_KEYS.CONSTRUCTION].alt
          }
        },
        images: [
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Stay connected on the go",
              body: "Organize projects and stay on schedule wherever you are with monday.com's iOS and Android apps."
            })
          },
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Track projects at every stage",
              body: "Streamline the office-field communication, and monitor the progress of projects in real time."
            })
          },
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "See the full picture",
              body: "Keep track of construction projects at a glance using automatically updating dashboards, and high-level overviews."
            })
          }
        ]
      }
    },
    {
      tabLabel: "HR",
      tabContent: {
        asset: {
          image: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/use_case/HR.png",
            alt: tabsProps[USE_CASES_KEYS.HR].alt
          }
        },
        mobileAsset: {
          image: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/use_case_mobile/HR.png",
            alt: tabsProps[USE_CASES_KEYS.HR].alt
          }
        },
        images: [
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Recruitment workflow overview",
              body: "View the entire hiring process in a high-level dashboard, from candidate to hire."
            })
          },
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "Centralize employee information",
              body: "Manage and collaborate on HR documents from resumes to contracts and in one place."
            })
          },
          {
            src: null,
            fullParagraph: getParagraphProps({
              title: "High-level overview",
              body: "Keep track of requests per department, request types, and more, to improve your workflow."
            })
          }
        ]
      }
    }
  ]
};
