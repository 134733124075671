import dynamic from "next/dynamic";

const RegularTabsComponent = require("./regular-tabs-component").default;

import RegularTabsConfig from "./regular-tabs-config";
import RegularTabsAdvancedConfig from "./regular-tabs-advanced-config";

export default {
  RegularTabsComponent,
  RegularTabsConfig,
  RegularTabsAdvancedConfig
};
