import { FIELD_TYPES } from "constants/template-field-type";
import AssetFieldsConfig from "components/core/asset/fields-config";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
import VerticalTimerTabsFieldsConfig from "segments/desktop/core-components/vertical-timer-tabs/fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Colored Timer Vertical",
  fields: {
    ...VerticalTimerTabsFieldsConfig,
    tabs: {
      type: FIELD_TYPES.MULTIPLE_NESTED,
      label: "Tabs",
      maxLimit: 5,
      nestedConfig: {
        type: FIELD_TYPES.NESTED,
        fields: {
          title: { type: FIELD_TYPES.TEXT, label: "Tab Title", translatable: true },
          content: {
            type: FIELD_TYPES.NESTED,
            label: "Tab Full Paragraph",
            fields: FullParagraphFieldsConfig
          },
          timer: { type: FIELD_TYPES.TEXT, label: "Tab Timer Length (in milliseconds)" },
          badgeText: { type: FIELD_TYPES.TEXT, label: "Badge text (if needed)", translatable: true },
          asset: {
            type: FIELD_TYPES.NESTED,
            label: "Asset",
            fields: AssetFieldsConfig
          }
        }
      }
    }
  }
};
