import css from "styled-jsx/css";
import colors from "styles/colors";
import { TABLET_QUERY_MAX_WIDTH } from "/constants/vp-sizes";

export const baseDocumentationMenuStyles = css.global`
    

  .documentation-menu {
    padding: 0 50px;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
    height: calc(100vh - 120px);
    bottom: 0px;
    overflow-y: hidden;
    
    :hover {
      ::-webkit-scrollbar {
        -webkit-appearance: none;
      }
      
      ::-webkit-scrollbar:vertical {
        width: 11px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white;
        background-color: rgba(0, 0, 0, .5);
      }
      overflow-y: scroll;
    }
    
 

    .documentation-menu-section {
      margin-bottom: 32px;
      cursor: default;

      .documentation-menu-section-title {
        margin-bottom: 8px;
        font-weight: 700;
      }

      .documentation-menu-section-links {
      }
    }


    .documentation-menu-link {
      padding-left: 16px;
      .documentation-menu-link-wrapper {
        padding: 8px 0;

        .menu-link {
          color: ${colors["shuttle-gray"]};
          text-decoration: none;
          font-size: 0.875rem;
          width: 100%;
          display: flex;
          flex-direction: row;

          &:hover,
          &.selected-link {
            color: ${colors["dodger-blue"]};
          }
              
          .arrow-wrapper {
            margin-right: 8px
            :global(svg) {
              width: 10px;
              height: 10px;
            }
          }
        }
      }

      .documentation-menu-inner {
        transition: opacity 300ms ease-in-out;
        opacity: 0;
        height: 0;
        overflow: hidden;
      }

      .uncollapsed {
        opacity: 1;
        height: auto;
        flex-direction: column;
      }
    }
  }

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    .documentation-menu {
      display: none;
    }
  }
`;
