import VerticalTabsDefaultProps from "segments/desktop/core-components/vertical-tabs/default-props";
import { positionTypes } from "segments/desktop/core-components/images-grid/images-grid-service";
import { LG } from "constants/sizes";
import { WHITE_COLOR_NAME } from "styles/color-consts";
import Colors from "/styles/colors";

export default {
  ...VerticalTabsDefaultProps,
  backgroundColor: "light-blue-video-background",
  paddingTop: 0,
  paddingBottom: 0,
  titleSize: LG,
  tabsPosition: positionTypes.TOP_LEFT,
  minHeight: 500,
  addTimeline: true,
  addTabIndicator: false,
  fullBackground: true,
  lineColor: Colors[WHITE_COLOR_NAME]
};
