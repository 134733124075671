import { useState, useRef } from "react";
import VisibilitySensor from "react-visibility-sensor";
import Carousel from "segments/desktop/core-components/carousel/carousel";
import classnames from "classnames";
import CarouselPrev from "static/svg/carousel-prev.svg";
import CarouselNext from "static/svg/carousel-next.svg";
import ImageWithParagraphGridComponent from "segments/desktop/grid/layouts/image-with-paragraph-grid/image-with-paragraph-grid-component";
import { XS, XS_L } from "constants/sizes";
import AssetComponent from "components/core/asset/asset-component";
import { generateOnKeyDownHandler } from "client/services/accessibility/accessibility-service";
import { trackEvent } from "services/bigbrain-service";
import { CAROUSEL_ARROW_CLICKED, TAB_CLICKED } from "constants/bigbrain-event-types";
import UseCaseProductBanner from "segments/desktop/tabs/layouts/use-cases-tabs/use-case-product-banner/use-case-product-banner";
import constants from "segments/desktop/header/layouts/components/basic-header/constants";
import { scrollIntoViewWithOffset } from "utils/dom";
import { componentStyles } from "./use-cases-tabs-desktop-component.scss";

const CAROUSEL_LEFT_SWIPE = "left";
const CAROUSEL_RIGHT_SWIPE = "right";

const VISIBILITY_SENSOR_OFFSET = -100;
const DEFAULT_AUTO_PLAY_SPEED = 10000;

const UseCasesTabsDesktopComponent = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [carouselRef, setCarouselRef] = useState(null);
  const [wasComponentEverVisible, setWasComponentEverVisible] = useState(false);
  const ref = useRef(null);
  const { autoplay, autoplaySpeed, tabs, theme, buttonsColor, scrollWhenButtonClicked } = props;

  const itemRenderer = (tab) => {
    const { asset, images, banner = {} } = tab.tabContent;

    return (
      <div className="use-case-tab-content">
        {asset && (
          <div className="use-case-tab-asset">
            <AssetComponent {...asset} />
          </div>
        )}
        <div className="use-case-tab-paragraphs">
          <ImageWithParagraphGridComponent images={images} titleSize={XS_L} textSize={XS} textAlign="center" />
        </div>
        {banner?.productType && <UseCaseProductBanner theme={theme} {...banner} />}
      </div>
    );
  };

  const navItemRenderer = (tab, index) => {
    const onNavItemClick = () => {
      setSelectedTab(index);
      if (scrollWhenButtonClicked) {
        scrollIntoViewWithOffset(ref, constants.BASIC_HEADER_HEIGHT);
      }
      trackEvent(TAB_CLICKED, {
        kind: tab?.tabLabel,
        info1: index
      });
      carouselRef.slickGoTo(index);
    };
    const selected = selectedTab === index;

    return (
      <span
        className={classnames("use-cases-tab-nav-button-wrapper", { selected })}
        key={index}
        onClick={onNavItemClick}
        onKeyDown={generateOnKeyDownHandler(onNavItemClick)}
        role="tab"
        aria-selected={selected}
        tabIndex={0}
      >
        {tab.tabLabel}
      </span>
    );
  };

  const PrevArrow = (arrowProps) => {
    const { className, style, onClick } = arrowProps;
    const onPrevArrowClick = () => {
      trackEvent(CAROUSEL_ARROW_CLICKED, {
        kind: tabs?.[selectedTab].tabLabel,
        info1: selectedTab,
        info3: "prev"
      });
      onClick();
    };

    return (
      <button
        className={classnames(className, "carousel-arrow-wrapper", "prev")}
        onClick={onPrevArrowClick}
        tabIndex={0}
        onKeyDown={generateOnKeyDownHandler(onPrevArrowClick)}
      >
        <CarouselPrev />
      </button>
    );
  };

  const NextArrow = (arrowProps) => {
    const { className, style, onClick } = arrowProps;
    const onNextArrowClick = () => {
      trackEvent(CAROUSEL_ARROW_CLICKED, {
        kind: tabs?.[selectedTab].tabLabel,
        info1: selectedTab,
        info3: "next"
      });
      onClick();
    };

    return (
      <button
        className={classnames(className, "carousel-arrow-wrapper", "next")}
        onClick={onNextArrowClick}
        tabIndex={0}
        onKeyDown={generateOnKeyDownHandler(onClick)}
      >
        <CarouselNext />
      </button>
    );
  };

  const carouselProps = {
    items: tabs,
    itemRenderer,
    defaultIndex: 0,
    slidesToShow: 1,
    autoplay,
    autoplaySpeed: parseInt(autoplaySpeed) || DEFAULT_AUTO_PLAY_SPEED,
    centerMode: false,
    infinite: true,
    onRefSet: setCarouselRef,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  const onCarouselChange = (current) => {
    // fix selectedTab index in case of a glitch
    if (current !== selectedTab) {
      setSelectedTab(current);
    }
  };

  const onCarouselSwipe = (direction) => {
    let newSlide = selectedTab;
    if (direction === CAROUSEL_LEFT_SWIPE && selectedTab < tabs.length - 1) {
      newSlide = selectedTab + 1;
    }
    if (direction === CAROUSEL_RIGHT_SWIPE && selectedTab > 0) {
      newSlide = selectedTab - 1;
    }
    setSelectedTab(newSlide);
  };

  const onVisibilityChange = (isVisible) => {
    if (!wasComponentEverVisible && isVisible) {
      setWasComponentEverVisible(true);
    }
  };

  return (
    <VisibilitySensor
      offset={{ bottom: VISIBILITY_SENSOR_OFFSET, top: VISIBILITY_SENSOR_OFFSET }}
      partialVisibility={true}
      onChange={onVisibilityChange}
      active={!wasComponentEverVisible}
      scrollCheck={!wasComponentEverVisible}
    >
      <div className={classnames("use-cases-tabs-component", buttonsColor)} ref={ref}>
        <div className="use-cases-tabs-navigation" role="tablist" aria-label="Use cases tabs">
          {tabs.map((tab, index) => navItemRenderer(tab, index))}
        </div>
        <div className="carousel-wrapper">
          <Carousel {...carouselProps} onChange={(current) => onCarouselChange(current)} onSwipe={onCarouselSwipe} />
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    </VisibilitySensor>
  );
};

export default UseCasesTabsDesktopComponent;
