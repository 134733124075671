import classnames from "classnames";
import { componentStyles } from "./mobile-tab-nav-button.scss";

const MobileTabNavButton = (props) => {
  const { label, selected, theme } = props;
  return (
    <>
      <span className={classnames("tab-nav-button", { selected }, theme)}>{label}</span>
      <style jsx>{componentStyles}</style>
    </>
  );
};

export default MobileTabNavButton;
