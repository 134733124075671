import { FIELD_TYPES } from "constants/template-field-type";
import VerticalTabsFieldsConfig from "segments/desktop/core-components/vertical-tabs/fields-config";

const { backgroundColor, theme, tabsColor, ...resconfig } = VerticalTabsFieldsConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "Timeline",
  fields: {
    theme,
    tabsColor,
    lineColor: {
      type: FIELD_TYPES.COLOR_PICKER,
      label: "Menu line Color"
    },
    ...resconfig
  }
};
