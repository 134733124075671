export default [
  // Upper Right Green -> First Row
  {
    type: "status",
    offset: 50,
    duration: 0,
    pictureSrc: "/static/img/first-fold/status_1.png",
    classToggle: "status-first-item-end",
    className: "status-first-item-start"
  },

  // Yellow -> Second row
  {
    type: "status",
    offset: 200,
    duration: 0,
    pictureSrc: "/static/img/first-fold/status_2.png",
    classToggle: "fade-in",
    className: "status-second-item-start fade-out"
  },

  // Lower Right Red -> Third Row
  {
    type: "status",
    offset: 250,
    duration: 0,
    pictureSrc: "/static/img/first-fold/status_3.png",
    classToggle: "status-third-item-end",
    className: "status-third-item-start"
  },

  // Center Left Green -> Fourth Row
  {
    type: "status",
    offset: 100,
    duration: 0,
    pictureSrc: "/static/img/first-fold/status_1.png",
    classToggle: "status-fourth-item-end",
    className: "status-fourth-item-start"
  },

  // Yellow -> Fifth Row
  {
    type: "status",
    offset: 200,
    duration: 0,
    pictureSrc: "/static/img/first-fold/status_2.png",
    classToggle: "fade-in",
    className: "status-fifth-item-start fade-out"
  },

  // Lower Left Yellow -> Sixth Row
  {
    type: "status",
    offset: 225,
    duration: 0,
    pictureSrc: "/static/img/first-fold/status_2.png",
    classToggle: "status-sixth-item-end",
    className: "status-sixth-item-start"
  },

  // Red -> Seventh Row
  {
    type: "status",
    offset: 250,
    duration: 0,
    pictureSrc: "/static/img/first-fold/status_3.png",
    classToggle: "fade-in",
    className: "status-seventh-item-start fade-out"
  }
];
