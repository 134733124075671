import {SPACE_BLUE_THEME_ID} from "segments/segments-service";

export default {
    theme: SPACE_BLUE_THEME_ID,
    componentTitle: "Your everyday tools. All in <b>one place.</b>",
    beforeAnimationSubTitle: "All your information and data—in one place.<br/> Expand the capabilities of work management with over 200 integrations and custom-built apps to<br/> save time and boost productivity, together. ",
    afterAnimationSubTitle: "All your information and data—in one place.<br/> Expand the capabilities of work management with over 200 integrations and custom-built apps to<br/> save time and boost productivity, together. ",
    switchRightPhrase:"With work management" ,
    switchLeftPhrase: "Without work management"
};
