import { PureComponent } from "react";
import { componentStyles } from "./title-only-paragraph-component.scss";
import TitleOnlyParagraph from "segments/desktop/core-components/title-only-paragraph/title-only-paragraph";

export default class TitleOnlyParagraphComponent extends PureComponent {
  render() {
    return (
      <div className="title-only-paragraph-component">
        <div className="title-only-paragraph-wrapper">
          <TitleOnlyParagraph {...this.props} />
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

TitleOnlyParagraphComponent.defaultProps = {
  ...TitleOnlyParagraph.defaultProps,
  paddingTop: "64px",
  paddingBottom: "64px"
};
