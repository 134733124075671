import css from "styled-jsx/css";
import Colors from "/styles/colors";
import { TOP_LAYER, ZERO } from "/constants/z-index";
import { TABS_WIDTH } from "./board-tab-consts";

const TABS_WIDTH_PX = `${TABS_WIDTH}px`;

export const componentStyles = css.global`
  .board-tabs {
    display: flex;
    width: 100%;

    .left-pane-tabs-wrapper {
      display: flex;
      width: ${TABS_WIDTH_PX};
      margin-top: 32px;
      margin-bottom: 64px;

      .tabs-wrapper {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        flex-grow: 1;

        background: ${Colors["developers-dark-blue"]};
        border-radius: 10px 0 0px 10px;

        .products-tab-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          padding-bottom: 32px;
        }
      }
      .tab-selected-line {
        width: 5px;
        height: 100%;
        z-index: ${ZERO};
        position: relative;
      }
    }

    .board-content {
      display: flex;
      flex-direction: column;
      filter: drop-shadow(0px 8px 24px rgba(29, 140, 242, 0.5));

      .tab-content {
        padding-bottom: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background: ${Colors.white};
        width: 100%;
        z-index: ${TOP_LAYER};
        .board-asset {
        }
      }
    }
    &.small-tabs {
      .left-pane-tabs-wrapper {
        margin-bottom: 32px;

        .tabs-wrapper {
          .products-tab-wrapper {
            padding-bottom: 16px;
          }
        }
      }
      .board-content {
        filter: drop-shadow(0px 8px 16px rgba(29, 140, 242, 0.5));
      }
    }
  }
`;
