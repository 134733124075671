import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import { IMAGE_WIDTH } from "../../constants";
import Colors from "styles/colors";

const IMAGE_WIDTH_PX = `${IMAGE_WIDTH}px`;

const INITIAL_VISIBLE_TABLE_HEIGHT = 275;

export const componentStyles = css.global`
  .animating-board-homepage-first-fold-desktop-component {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    margin: auto;

    .header-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: calc(100vh - ${INITIAL_VISIBLE_TABLE_HEIGHT}px);
      min-height: 500px;

      .first-title {
        font-size: 3.038rem;
        color: ${Colors["black"]};
        font-weight: 700;
        text-align: center;
        letter-spacing: -1px;
        transform: translateY(-24px);
        max-width: 900px;
      }

      .second-title {
        font-size: 1.375rem;
        color: ${Colors["text-gray"]};
        max-width: 600px;
        text-align: center;
        margin-top: 16px;
        line-height: 32px;
        transform: translateY(-24px);
      }

      .first-fold-signup-button {
        margin-top: 32px;
        transform: translateY(-24px);
      }
    }

    .table-picture-container {
      display: flex;
      justify-content: center;
      position: relative;
      width: ${IMAGE_WIDTH_PX};
      margin: auto;
    }
  }
`;
