import css from "styled-jsx/css";

export const componentStyles = css`
  .paragraph-and-logos {
    display: flex;
    flex-direction: column;
    align-items: center;

    .paragraph-wrapper {
    }

    .logos-wrapper {
    }
  }
`;
