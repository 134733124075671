import { SIGNUP_BUTTON_TYPE, SIGNUP_COMBO_BUTTONS_TYPE } from "segments/desktop/core-components/button//button-consts";
import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";

const getParagraphButtonConfig = ({ buttonSelect, solutionId }) => {
  const { buttonType, signupButtonText, seconderyButtonText } = buttonSelect || {};

  const buttonConfig = {};
  const signupButtonConfig = {
    useCtaText: true,
    ctaText: signupButtonText,
    buttonColor: WORK_OS_IRIS_COLOR_NAME,
    signupWithsolutionAppReferenceId: solutionId
  };

  if (buttonType === SIGNUP_COMBO_BUTTONS_TYPE) {
    buttonConfig.buttonType = buttonType;
    buttonConfig.swapButtonsOrder = true;
    buttonConfig.secondaryButtonConfig = {
      buttonText: seconderyButtonText,
      url: solutionId ? `/templates/template/${solutionId}` : ""
    };
    buttonConfig.signupButtonConfig = signupButtonConfig;
  } else if (buttonType === SIGNUP_BUTTON_TYPE) {
    buttonConfig.buttonType = buttonType;
    Object.assign(buttonConfig, signupButtonConfig);
  }
  return buttonConfig;
};

export const getFullParagraph = ({
  title,
  description,
  overrideTitle,
  overrideDescription,
  topicText,
  buttonSelect,
  solutionId,
  showTitle,
  showDescription,
  textAlign,
  mobileTextAlign
}) => {
  return {
    topic: {
      text: topicText,
      color: "brand-blue"
    },
    title: showTitle && (overrideTitle || title || "Template title"),
    body: showDescription && (overrideDescription || description || "Template description"),
    textAlign,
    mobileTextAlign,
    buttonConfig: getParagraphButtonConfig({ buttonSelect, solutionId })
  };
};

export const getImageSrcAndAlt = ({ overrideImage, images }) => {
  return {
    alt: overrideImage.image?.alt || (images && images[0]?.title),
    src:
      overrideImage.image?.src ||
      (images && images[0]?.url) ||
      "/static/img/segment-layout-default-images/template/template-image-placeholder.png"
  };
};

export const getAsset = ({ overrideImage, images, showImageShadow }) => {
  return {
    ...overrideImage,
    image: {
      ...(overrideImage.image || {}),
      ...getImageSrcAndAlt({ images, overrideImage })
    },
    shadow: showImageShadow
  };
};
