import { useState } from "react";
import Title from "segments/desktop/core-components/title/title";
import useInterval from "client/services/hooks/use-interval";
import { titleFontSizeNumber } from "segments/desktop/core-components/title/consts";
import Word from "./word-component";
import { componentStyles } from "./rotating-title-paragraph-component.scss";

const RotatingTitleParagraphComponent = (props) => {
  const { title, titleSize, rotatingWords, animationDuration, infiniteRotation } = props;

  const [wordIndex, setWordIndex] = useState(0);
  const [direction, setDirection] = useState(1);

  const getNextIndex = (index) => {
    if (index === rotatingWords.length - 1) {
      setDirection(-1);
      return index - 1;
    }
    if (index === 0) {
      setDirection(1);
      return index + 1;
    }
    return index + direction;
  };

  useInterval(
    () => setWordIndex(infiniteRotation ? getNextIndex(wordIndex) : wordIndex + 1),
    wordIndex < rotatingWords.length - 1 || infiniteRotation ? parseInt(animationDuration) : null
  );

  return (
    <div className="rotating-title-paragraph-component">
      <div className="body" style={{ height: `${1.4 * titleFontSizeNumber[titleSize]}rem` }}>
        <div className="title">
          <Title title={title} titleSize={titleSize} />
        </div>
        <div className="words">
          {rotatingWords.map((word, index) => (
            <Word key={`word_${index}`} {...props} {...word} wordIndex={wordIndex} direction={direction} />
          ))}
        </div>
      </div>

      <style jsx>{componentStyles}</style>
    </div>
  );
};

RotatingTitleParagraphComponent.defaultProps = {
  title: "Get the most of your",
  rotatingWords: [
    {
      text: "marketing",
      color: "brand-blue"
    },
    {
      text: "sales and CRM",
      color: "brand-red"
    },
    {
      text: "operations",
      color: "brand-green"
    },
    {
      text: "workflows",
      color: "brand-iris"
    }
  ],
  animationDuration: 2000
};

export default RotatingTitleParagraphComponent;
