export const prefetchImages = imagesUrls => {
  if (imagesUrls && typeof document !== "undefined") {
    window.imageCache = window.imageCache || {};
    imagesUrls.forEach(imageUrl => {
      const img = new Image();
      img.src = imageUrl;
      window.imageCache[imageUrl] = img;
    });
  }
};

export default prefetchImages;