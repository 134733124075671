import { FIELD_TYPES } from "constants/template-field-type";
import { PRODUCT_OPTIONS } from "constants/products";
import {SIGN_UP_WITH_PRODUCTS_BACKGROUND_ID} from "segments/segments-service";
 
export default {
  type: FIELD_TYPES.NESTED,
  label: "Sign Up With Products Cards",
  fields: {
    theme: {
      type: FIELD_TYPES.COLOR_PICKER,
      label: "Theme",
      possibleColors: [SIGN_UP_WITH_PRODUCTS_BACKGROUND_ID]
    },
    defaultActiveProduct: {
      type: FIELD_TYPES.COMBO,
      label: "Default active product",
      options: PRODUCT_OPTIONS
    },
    skipButtonText: {
      type: FIELD_TYPES.TEXT,
      label: "Skip button CTA"
    },
    continueButtonText: {
      type: FIELD_TYPES.TEXT,
      label: "Continue button CTA"
    },
    titleWithProduct: {
      type: FIELD_TYPES.TEXT,
      label: "Title when product type exist"
    },
    subTitle: {
      type: FIELD_TYPES.TEXT,
      label: "Subtitle when product type exist"
    },
    titleWithoutProduct: {
      type: FIELD_TYPES.TEXT,
      label: "Title when product type doesnt's exist"
    },
    textUnderButtons: {
      type: FIELD_TYPES.TEXT,
      label: "Text under button"
    }
  }
};
