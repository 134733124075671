import ReactDOMServer from "react-dom/server";
import AssetComponent from "/components/core/asset/asset-component";
import TypedComponent from "segments/desktop/core-components/typed-component/typed-component";
import ParallaxGalaxy from "./parallax-galaxy";

export const VARIATIONS = {
  SMALL: "small",
  MEDIUM: "medium"
};

const STEP_1 = "step1";
const STEP_2 = "step2";
const STEP_3 = "step3";

const PERSON_WIDTH = 50;

const TYPED_COLUMN = 75;

const PERSON_ONE_SECOND_ROW = 100;
const PERSON_TWO_SECOND_ROW = 185;
const PERSON_THREE_SECOND_ROW = 270;

const PERSON_SECOND_SCALE = "scale(1)";

const SCENE_TRANSITION = "opacity 0.5s";

const PERSONS_FIRST_ANIMATION_TIMING = "0.6s";
const PERSONS_SECOND_ANIMATION_TIMING = "0.8s";

const PERSONS_FIRST_TRANSITION = `left ${PERSONS_FIRST_ANIMATION_TIMING} ease-out, top ${PERSONS_FIRST_ANIMATION_TIMING} ease-in-out, transform ${PERSONS_FIRST_ANIMATION_TIMING} ease-in-out`;
const PERSONS_SECOND_TRANSITION = `left ${PERSONS_SECOND_ANIMATION_TIMING} ease-in-out, top ${PERSONS_SECOND_ANIMATION_TIMING} ease-in-out, transform ${PERSONS_SECOND_ANIMATION_TIMING} ease-in-out`;

export const PERSONS_IMAGES = [
  {
    image: {
      src:
        "https://dapulse-res.cloudinary.com/image/upload/v1649149959/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/parallax/person1.png",
      alt: "Samantha's profile image"
    }
  },
  {
    image: {
      src:
        "https://dapulse-res.cloudinary.com/image/upload/v1649149959/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/parallax/person2.png",
      alt: "Nate's profile image"
    }
  },
  {
    image: {
      src:
        "https://dapulse-res.cloudinary.com/image/upload/v1649149959/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/parallax/person3.png",
      alt: "Paloma's profile image"
    }
  }
];

const sizesMapping = (variation) =>
  ({
    [VARIATIONS.SMALL]: {
      // ASSETS
      GALAXY_SCALE: "scale(0.75)",
      SPACE_BOARD_WIDTH: 450,
      SPACE_DASHBOARD_WIDTH: 450,
      // FIRST
      PERSON_FIRST_SCALE: "scale(0.3)",
      PERSON_FIRST_COLUMN: 145,
      PERSON_ONE_FIRST_ROW: 166,
      PERSON_TWO_FIRST_ROW: 229,
      PERSON_THREE_FIRST_ROW: 289,
      // SECOND
      PERSON_ONE_SECOND_ROW: 100,
      PERSON_TWO_SECOND_ROW: 185,
      PERSON_THREE_SECOND_ROW: 270,
      // THIRD
      PERSON_THIRD_SCALE: "scale(0.32)",
      PERSON_THIRD_ROW: 351,
      PERSON_ONE_THIRD_ROW: 250,
      PERSON_TWO_THIRD_ROW: 305,
      PERSON_THREE_THIRD_ROW: 360,
      TYPED_FONT_SIZE: 18
    },
    [VARIATIONS.MEDIUM]: {
      // ASSETS
      GALAXY_SCALE: "scale(1)",
      SPACE_BOARD_WIDTH: 600,
      SPACE_DASHBOARD_WIDTH: 600,
      // FIRST
      PERSON_FIRST_SCALE: "scale(0.4)",
      PERSON_FIRST_COLUMN: 202,
      PERSON_ONE_FIRST_ROW: 145,
      PERSON_TWO_FIRST_ROW: 229,
      PERSON_THREE_FIRST_ROW: 309,
      // SECOND
      PERSON_ONE_SECOND_ROW: 105,
      PERSON_TWO_SECOND_ROW: 190,
      PERSON_THREE_SECOND_ROW: 275,
      // THIRD
      PERSON_THIRD_SCALE: "scale(0.42)",
      PERSON_THIRD_ROW: 392,
      PERSON_ONE_THIRD_ROW: 343,
      PERSON_TWO_THIRD_ROW: 414,
      PERSON_THREE_THIRD_ROW: 487,
      TYPED_FONT_SIZE: 22
    }
  }[variation]);

export const getTypings = (translate) => {
  return [
    translate(`parallex.typings.first`),
    translate(`parallex.typings.second`),
    `${ReactDOMServer.renderToString(
      <div className="text-with-pdf">
        <div>
          <span>{translate(`parallex.typings.third`)}</span>
        </div>
        <img
          className="pdf-icon"
          src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaystagingcom_static/uploads/Bar%20Ben/pdf_icon.png"
        />
      </div>
    )}`
  ];
};

export const getComponentsConfig = (variation, translate, topAsset, bottomAsset) => {
  const SIZES = sizesMapping(variation);
  const typings = getTypings(translate);

  return [
    //
    //
    // SPACE
    //
    //
    {
      id: "galaxy",
      props: (step) => ({
        style: {
          position: "absolute",
          zIndex: 0,
          transition: SCENE_TRANSITION,
          transform: SIZES.GALAXY_SCALE,
          right: -150,
          top: -125,
          opacity: 0,
          ...{
            [STEP_1]: { opacity: 1 },
            [STEP_2]: { opacity: 0 },
            [STEP_3]: { opacity: 0 }
          }[step]
        }
      }),
      component: ParallaxGalaxy
    },
    {
      id: "space-board",
      props: (step) => ({
        image: topAsset,
        useWindowMask: true,
        width: SIZES.SPACE_BOARD_WIDTH,
        style: {
          position: "absolute",
          zIndex: 1,
          transition: SCENE_TRANSITION,
          opacity: 0,
          ...{
            [STEP_1]: { opacity: 1 },
            [STEP_2]: { opacity: 0 },
            [STEP_3]: { opacity: 0 }
          }[step]
        }
      }),
      component: AssetComponent
    },
    {
      id: "space-dashboard",
      props: (step) => ({
        video: {
          ...bottomAsset,
          playWhenVisible: false,
          loop: false,
          autoPlay: step === STEP_3
        },
        hideControls: true,
        useWindowMask: true,
        width: SIZES.SPACE_DASHBOARD_WIDTH,
        style: {
          position: "absolute",
          zIndex: 1,
          transition: SCENE_TRANSITION,
          opacity: 0,
          ...{
            [STEP_1]: { opacity: 0 },
            [STEP_2]: { opacity: 0 },
            [STEP_3]: { opacity: 1 }
          }[step]
        }
      }),
      component: AssetComponent
    },
    //
    //
    // PEOPLE
    //
    //
    {
      id: "person-one",
      props: (step) => ({
        ...PERSONS_IMAGES[0],
        width: PERSON_WIDTH,
        style: {
          position: "absolute",
          zIndex: 2,
          transition: PERSONS_FIRST_TRANSITION,
          opacity: 0,
          ...{
            step1: {
              left: SIZES.PERSON_FIRST_COLUMN,
              top: SIZES.PERSON_ONE_FIRST_ROW,
              transform: SIZES.PERSON_FIRST_SCALE,
              opacity: 1
            },
            step2: {
              left: 0,
              top: SIZES.PERSON_ONE_SECOND_ROW,
              transform: PERSON_SECOND_SCALE,
              opacity: 1
            },
            step3: {
              transition: PERSONS_SECOND_TRANSITION,
              left: SIZES.PERSON_ONE_THIRD_ROW,
              top: SIZES.PERSON_THIRD_ROW,
              transform: SIZES.PERSON_THIRD_SCALE,
              opacity: 1
            }
          }[step]
        }
      }),
      component: AssetComponent
    },
    {
      id: "person-two",
      props: (step) => ({
        ...PERSONS_IMAGES[1],
        width: PERSON_WIDTH,
        style: {
          position: "absolute",
          zIndex: 2,
          transition: PERSONS_FIRST_TRANSITION,
          opacity: 0,
          ...{
            step1: {
              left: SIZES.PERSON_FIRST_COLUMN,
              top: SIZES.PERSON_TWO_FIRST_ROW,
              transform: SIZES.PERSON_FIRST_SCALE,
              opacity: 1
            },
            step2: {
              left: 0,
              top: SIZES.PERSON_TWO_SECOND_ROW,
              transform: PERSON_SECOND_SCALE,
              opacity: 1
            },
            step3: {
              transition: PERSONS_SECOND_TRANSITION,
              left: SIZES.PERSON_TWO_THIRD_ROW,
              top: SIZES.PERSON_THIRD_ROW,
              transform: SIZES.PERSON_THIRD_SCALE,
              opacity: 1
            }
          }[step]
        }
      }),
      component: AssetComponent
    },
    {
      id: "person-three",
      props: (step) => ({
        ...PERSONS_IMAGES[2],
        width: PERSON_WIDTH,
        style: {
          position: "absolute",
          zIndex: 2,
          transition: PERSONS_FIRST_TRANSITION,
          opacity: 0,
          ...{
            step1: {
              left: SIZES.PERSON_FIRST_COLUMN,
              top: SIZES.PERSON_THREE_FIRST_ROW,
              transform: SIZES.PERSON_FIRST_SCALE,
              opacity: 1
            },
            step2: {
              left: 0,
              top: SIZES.PERSON_THREE_SECOND_ROW,
              transform: PERSON_SECOND_SCALE,
              opacity: 1
            },
            step3: {
              transition: PERSONS_SECOND_TRANSITION,
              left: SIZES.PERSON_THREE_THIRD_ROW,
              top: SIZES.PERSON_THIRD_ROW,
              transform: SIZES.PERSON_THIRD_SCALE,
              opacity: 1
            }
          }[step]
        }
      }),
      component: AssetComponent
    },
    //
    //
    // TYPED COMPONENTS
    //
    //
    {
      id: "typed-1",
      props: (step) => ({
        style: {
          left: TYPED_COLUMN,
          top: PERSON_ONE_SECOND_ROW,
          position: "absolute",
          fontSize: SIZES.TYPED_FONT_SIZE,
          transition: "opacity 0.4s linear",
          opacity: 0,
          ...{
            [STEP_1]: { opacity: 0 },
            [STEP_2]: { opacity: 1 },
            [STEP_3]: { opacity: 0 }
          }[step]
        },
        stopped: step !== STEP_2,
        typedProps: {
          strings: [`${typings[0]}`],
          startDelay: 250
        }
      }),
      component: TypedComponent
    },
    {
      id: "typed-2",
      props: (step) => ({
        style: {
          left: TYPED_COLUMN,
          top: PERSON_TWO_SECOND_ROW,
          position: "absolute",
          fontSize: SIZES.TYPED_FONT_SIZE,
          transition: "opacity 0.4s linear",
          opacity: 0,
          ...{
            [STEP_1]: { opacity: 0 },
            [STEP_2]: { opacity: 1 },
            [STEP_3]: { opacity: 0 }
          }[step]
        },
        stopped: step !== STEP_2,
        typedProps: {
          strings: [`${typings[1]}`],
          startDelay: 3000
        }
      }),
      component: TypedComponent
    },
    {
      id: "typed-3",
      props: (step) => ({
        style: {
          left: TYPED_COLUMN,
          top: PERSON_THREE_SECOND_ROW,
          position: "absolute",
          display: "flex",
          fontSize: SIZES.TYPED_FONT_SIZE,
          transition: "opacity 0.4s linear",
          opacity: 0,
          ...{
            [STEP_1]: { opacity: 0 },
            [STEP_2]: { opacity: 1 },
            [STEP_3]: { opacity: 0 }
          }[step]
        },
        stopped: step !== STEP_2,
        typedProps: {
          strings: [`${typings[2]}`],
          startDelay: 5500
        }
      }),
      component: TypedComponent
    }
  ];
};

export const steps = [STEP_1, STEP_2, STEP_3];
