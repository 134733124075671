import dynamic from "next/dynamic";

const TitleOnlyParagraphComponent = require("./title-only-paragraph-component").default;

import TitleOnlyParagraphConfig from "./title-only-paragraph-config";
import TitleOnlyParagraphAdvancedConfig from "./title-only-paragraph-advanced-config";

export default {
  TitleOnlyParagraphComponent,
  TitleOnlyParagraphConfig,
  TitleOnlyParagraphAdvancedConfig
};
