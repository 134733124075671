import { useState, useMemo, useCallback } from "react";
import { componentStyles } from "./images-carousel-with-tags-grid-asset-component.scss";
import SelectableTagsGrid from "segments/desktop/core-components/selectable-tags-grid/selectable-tags-grid";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import ImagesCarousel from "segments/desktop/core-components/images-carousel/images-carousel";
import { ALIGN_TYPES } from "constants/align-types";

const ImagesCarouselWithTagsGridAssetComponent = (props) => {
  const {
    paragraphText,
    defaultImage,
    showDefaultInGallery,
    tagsAlign,
    paragraphTagsPadding,
    titleWidth,
    withDynamicParagraphTitle,
    withDynamicParagraphSubtitle
  } = props;
  const [selectedTags, setSelectedTags] = useState([]);

  const onTagToggle = useCallback(
    (tags = []) => {
      setSelectedTags(tags);
    },
    [setSelectedTags]
  );

  const selectedTagGalleryImages = useMemo(() => {
    return selectedTags.map(({ galleryImage } = {}) => galleryImage).filter((image) => image);
  }, [selectedTags]);

  const imagesToRender = useMemo(() => {
    if (!selectedTagGalleryImages?.length) {
      return defaultImage ? [defaultImage] : [];
    }

    return showDefaultInGallery ? [defaultImage, ...selectedTagGalleryImages] : selectedTagGalleryImages;
  }, [selectedTagGalleryImages, defaultImage, showDefaultInGallery]);

  const tagsGridWrapperStyle = useMemo(() => {
    return {
      paddingTop: paragraphTagsPadding
    };
  }, [paragraphTagsPadding]);

  return (
    <div className="images-carousel-with-tags-grid-asset-component">
      <div className="grid-side">
        <FullParagraph
          {...paragraphText}
          titleWidth={titleWidth}
          withDynamicParagraphTitle={withDynamicParagraphTitle}
          withDynamicParagraphSubtitle={withDynamicParagraphSubtitle}
        />
        <div className="selectable-tags-grid-wrapper" style={tagsGridWrapperStyle}>
          <SelectableTagsGrid {...props} onTagToggle={onTagToggle} align={tagsAlign} />
        </div>
      </div>

      <div className="grid-side">
        <ImagesCarousel images={imagesToRender} />
      </div>

      <style jsx>{componentStyles}</style>
    </div>
  );
};

ImagesCarouselWithTagsGridAssetComponent.defaultProps = {
  showDefaultInGallery: false,
  defaultImage: {
    src: "/static/img/segment-layout-default-images/grid/man-with-blue-shirt.png"
  },
  tagsAlign: ALIGN_TYPES.LEFT
};

export default ImagesCarouselWithTagsGridAssetComponent;
