import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";

export const componentStyles = css.global`
  .regular-asset-component {
    width: 100%;

    .asset-component-wrapper {
      max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
      margin: auto;
      display: flex;

      &.left {
        justify-content: flex-start;
      }

      &.center {
        justify-content: center;
      }

      &.right {
        justify-content: flex-end;
      }

      &.full-width {
        max-width: none;
      }

      .regular-asset-image-wrapper {
        .regular-asset-image {
          width: 100% !important;
          height: auto;
        }
      }

      video {
        display: block !important;
      }
    }
  }
`;
