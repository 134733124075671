import { useEffect, useState } from "react";
import Dimensions from "constants/generator-dimensions";
import ResizeObserver from "resize-observer-polyfill";
import { isServerSide } from "utils/general";

export const getElementScrollableHeight = (element) => {
  return Math.max(element.scrollTop, element.offsetTop) - Dimensions.TOP_BAR_HEIGHT;
};

export default function useElementSize(element) {
  const [elementSize, setElementSize] = useState({});

  useEffect(() => {
    if (isServerSide()) {
      return false;
    }

    const ro = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const cr = entry.contentRect;
        setElementSize({ scrollableWidth: cr.width, scrollableHeight: cr.height });
      }
    });

    ro.observe(element);
    return () => ro.disconnect();
  }, []);

  return elementSize;
}
