import css from "styled-jsx/css";

import { DARK_WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import colors from "styles/colors";

export const componentStyles = css.global`
  .tab-nav-plain-button {
    font-size: 0.875rem;
    font-weight: 300;
    padding: 0px 16px;
    line-height: 28px;
    white-space: nowrap;
    &:not(.selected) {
      opacity: 0.6;
    }
    &.selected {
      .tab-nav-plain-button-text-number,
      .tab-nav-plain-button-text-label {
        font-weight: 500;
      }
      .tab-nav-plain-button-text-label {
        text-decoration: underline;
      }
    }
    &.background-gray {
      color: ${colors[DARK_WORK_OS_IRIS_COLOR_NAME]};
    }
  }
`;
