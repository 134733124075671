import SideBySideTextAndAssetComponent from "segments/desktop/text-and-asset/layouts/side-by-side-text-and-asset/side-by-side-text-and-asset-component";
import SideBySideTextAndAsset from "segments/desktop/core-components/side-by-side-text-and-asset/side-by-side-text-and-asset";
import FullParagraphComponent from "segments/desktop/paragraph/layouts/full-paragraph/full-paragraph-component";
import { withGenericConfig } from "/contexts/generic-config-context";
import Logos from "segments/desktop/core-components/logos/logos";
import ImagesLogosComponent from "../images-logos/images-logos-component";
import { componentStyles } from "./logos-and-paragraph-logos-component.scss";
import { SIGNUP_BUTTON_TYPE } from "../../../core-components/button/button-consts";

const NUMBER_OF_COMPANIES = "12";

const LogosAndParagraphLogosComponent = (props) => {
  const { logos, fullParagraph, isMobileOrTabletBrowser } = props;

  const leadingComponent = isMobileOrTabletBrowser ? FullParagraphComponent : ImagesLogosComponent;
  const leadingComponentProps = isMobileOrTabletBrowser ? fullParagraph : { logos };
  const trailingComponent = isMobileOrTabletBrowser ? ImagesLogosComponent : FullParagraphComponent;
  const trailingComponentProps = isMobileOrTabletBrowser ? { logos } : fullParagraph;

  return (
    <div className="logos-and-paragraph-logos-component">
      <SideBySideTextAndAssetComponent
        replaceTextComponent={leadingComponent}
        replaceTextComponentProps={leadingComponentProps}
        replaceAssetComponent={trailingComponent}
        replaceAssetComponentProps={trailingComponentProps}
        {...props}
      />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

LogosAndParagraphLogosComponent.defaultProps = {
  ...SideBySideTextAndAsset.defaultProps,
  logos: {
    ...Logos.defaultProps,
    numberOfCompanies: NUMBER_OF_COMPANIES
  },
  fullParagraph: {
    ...FullParagraphComponent.defaultProps,
    topic: { text: "" },
    title: "Join successful companies",
    body: "who choose monday.com",
    buttonConfig: {
      buttonType: SIGNUP_BUTTON_TYPE
    },
    mobileTextAlign: "center"
  }
};

export default withGenericConfig(LogosAndParagraphLogosComponent);
