import { useEffect, useState } from "react";
import { componentStyles } from "./live-board-homepage-first-fold-component.scss";
import PictureComponent from "/components/core/picture/picture-component";
import Paragraph from "segments/desktop/core-components/paragraph/paragraph";
import RegularButton from "segments/desktop/core-components/button/regular-button/regular-button";
import Title from "segments/desktop/core-components/title/title";
import classNames from "classnames";
import DefaultProps from "./default-props";
import {
  LIVE_BOARD_OPEN_BUTTON_HOVERED,
  LIVE_BOARD_SCALED,
  LIVE_BOARD_OPEN_BUTTON_CLICKED
} from "constants/bigbrain-event-types";
import { trackEvent } from "/services/bigbrain-service";
import { useInView } from "react-intersection-observer";
import Colors from "/styles/colors";

function LiveBoardHomepageFirstFoldComponent(props) {
  const [scaledEventSent, setScaledEventSent] = useState(false);
  const [liveBoardWrapperRef, liveBoardFullyInView] = useInView({
    threshold: 1,
    triggerOnce: true
  });
  const [liveBoardImageWrapperRef, liveBoardPartiallyInView] = useInView({
    threshold: 0.7,
    triggerOnce: true
  });
  const [playAnimation, setplayAnimation] = useState(false);

  const {
    backgroundImage,
    boardImage,
    openLiveBoard,
    showLiveBoardImage,
    liveBoardClosed,
    nestedBoardItems,
    circleConfig
  } = props;

  const {
    circleTitle,
    circleTitleColor,
    circleTitleSize,
    circleText,
    circleTextColor,
    circleColor,
    buttonText,
    buttonColor
  } = circleConfig;

  const { boardBannerText, embeddedBoardTitle, addSecondLiveBoardSignUpButton } = nestedBoardItems;

  const liveBoardOpenButtonClicked = () => {
    trackEvent(LIVE_BOARD_OPEN_BUTTON_CLICKED);
  };

  const liveBoardOpenButtonHovered = () => {
    trackEvent(LIVE_BOARD_OPEN_BUTTON_HOVERED);
  };

  const isAnimationPlayed = () => {
    if (!liveBoardClosed && playAnimation) {
      return true;
    }
  };

  const liveBoardClicked = () => {
    openLiveBoard({ boardBannerText, embeddedBoardTitle, addSecondLiveBoardSignUpButton });
  };

  const renderLiveBoardOpenButton = () => {
    return (
      <div className="open-button-content-wrapper" onMouseEnter={liveBoardOpenButtonHovered}>
        <div className="open-button-title-wrapper">
          <Title title={circleTitle} titleSize={circleTitleSize} titleColor={circleTitleColor} />
        </div>
        <div className="open-button-paragraph-wrapper">
          <Paragraph body={circleText} bodyColor={circleTextColor} />
        </div>
        <div className="open-button-button-wrapper">
          <RegularButton
            buttonText={buttonText}
            onClick={liveBoardOpenButtonClicked}
            color={buttonColor}
            isWithArrow={false}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (scaledEventSent) trackEvent(LIVE_BOARD_SCALED);
  }, [scaledEventSent]);

  useEffect(() => {
    if (liveBoardFullyInView) {
      setplayAnimation(true);
      setScaledEventSent(true);
    }
  }, [liveBoardFullyInView]);

  return (
    <div
      className={classNames("live-board-homepage-first-fold-component", {
        "animation-played": isAnimationPlayed(),
        "full-screen-board": !showLiveBoardImage,
        "live-board-closed": liveBoardClosed
      })}
    >
      <div className="boards-picture">
        <PictureComponent src={backgroundImage} />
      </div>

      <div className="live-board-wrapper" ref={liveBoardWrapperRef} onClick={liveBoardClicked}>
        <div
          className={classNames("animation-circle", {
            "hide-button": !liveBoardPartiallyInView,
            "play-animation": !liveBoardClosed
          })}
          style={{ backgroundColor: Colors[circleColor] }}
        />
        <div
          className={classNames("open-button-wrapper", { "hide-button": !liveBoardPartiallyInView })}
          style={{ backgroundColor: Colors[circleColor] }}
        >
          {renderLiveBoardOpenButton()}
        </div>
        <div className="live-board-image-wrapper" ref={liveBoardImageWrapperRef}>
          <PictureComponent src={boardImage} />
        </div>
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
}

LiveBoardHomepageFirstFoldComponent.defaultProps = DefaultProps;

export default LiveBoardHomepageFirstFoldComponent;
