import {
    CRM_PRODUCT_ID,
    MARKETER_PRODUCT_ID,
    PROJECTS_PRODUCT_ID,
    DEV_PRODUCT_ID,
    WORK_MANAGEMENT_PRODUCT_ID
  } from "constants/products";

  import {
    CRM_GREEN_THEME_ID,
    DEV_GREEN_THEME_ID,
    MARKETING_RED_THEME_ID,
    PROJECTS_ORANGE_THEME_ID,
    WORKOS_IRIS_THEME_ID
  } from "segments/segments-service";



export const SIGN_UP_PRODUCT_CARDS_DATA = {
    [WORK_MANAGEMENT_PRODUCT_ID]: {
        image: {
          src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/products/wm_logo_sign_up.png",
          alt: "monday work-management logo"
        },
        theme: WORKOS_IRIS_THEME_ID,
        body: "productsSignUp.cards.products.mondayWorkManagement.title",
        tags:
          ["productsSignUp.cards.products.mondayWorkManagement.tags.tag1",
          "productsSignUp.cards.products.mondayWorkManagement.tags.tag2",
          "productsSignUp.cards.products.mondayWorkManagement.tags.tag3"
          ]
        },
      [MARKETER_PRODUCT_ID] : {
        image: {
          src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/products/marketer_product_logo.png",
          alt: "monday marketer logo"
        },
        theme: MARKETING_RED_THEME_ID,
        body: "productsSignUp.cards.products.mondayMarketer.title",
        tags: [
          "productsSignUp.cards.products.mondayMarketer.tags.tag1",
          "productsSignUp.cards.products.mondayMarketer.tags.tag2",
          "productsSignUp.cards.products.mondayMarketer.tags.tag3"
        ]
      },
      [CRM_PRODUCT_ID] : {
        image: {
          src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/products/crm_product_logo.png",
          alt: "monday sales CRM logo"
        },
        theme: CRM_GREEN_THEME_ID,
        body: "productsSignUp.cards.products.mondaySalesCRM.title",
        tags: [
          "productsSignUp.cards.products.mondaySalesCRM.tags.tag1",
          "productsSignUp.cards.products.mondaySalesCRM.tags.tag2",
          "productsSignUp.cards.products.mondaySalesCRM.tags.tag3"
        ]
      },
      [PROJECTS_PRODUCT_ID] : {
        image: {
          src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/products/projects_product_logo.png",
          alt: "monday projects logo"
        },
        theme: PROJECTS_ORANGE_THEME_ID,
        body: "productsSignUp.cards.products.mondayProjects.title",
        tags:[
          "productsSignUp.cards.products.mondayProjects.tags.tag1",
          "productsSignUp.cards.products.mondayProjects.tags.tag2",
          "productsSignUp.cards.products.mondayProjects.tags.tag3"
        ]
      },
      [DEV_PRODUCT_ID] : {
        image: {
          src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/products/dev_product_logo.png",
          alt: "monday dev logo"
        },
        theme: DEV_GREEN_THEME_ID,
        body: "productsSignUp.cards.products.mondayDev.title",
        tags: [
          "productsSignUp.cards.products.mondayDev.tags.tag1",
          "productsSignUp.cards.products.mondayDev.tags.tag2",
          "productsSignUp.cards.products.mondayDev.tags.tag3"
        ]
      }
    }