import { FIELD_TYPES } from "constants/template-field-type";
import TitleFieldsConfig from "segments/desktop/core-components/title/fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Logos And Header",
  fields: {
    ...TitleFieldsConfig
  }
};
