import { FIELD_TYPES } from "constants/template-field-type";
import buttonConfig from "segments/desktop/core-components/button/fields-config";
import titleConfig from "segments/desktop/core-components/title/fields-config";

export default {
  textAlign: {
    type: FIELD_TYPES.COMBO,
    label: "Text Align",
    options: [
      { value: "left", text: "Left" },
      { value: "center", text: "Center" },
      { value: "right", text: "Right" }
    ]
  },
  ...titleConfig,
  textColor: {
    type: FIELD_TYPES.COLOR_PICKER,
    label: "Text Color",
    possibleColors: ["brand-blue", "brand-pink", "brand-iris", "brand-green", "brand-red", "brand-purple"]
  },
  buttonConfig
};
