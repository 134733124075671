import { PureComponent } from "react";
import { componentStyles } from "./animating-board-homepage-first-fold-component.scss";
import AnimatingBoardHomepageFirstFoldDesktopComponent from "./components/animating-board-homepage-first-fold-desktop/animating-board-homepage-first-fold-desktop-component";
import AnimatingBoardHomepageFirstFoldMobileComponent from "./components/animating-board-homepage-first-fold-mobile/animating-board-homepage-first-fold-mobile-component";

export default class AnimatingBoardHomepageFirstFoldComponent extends PureComponent {
  render() {
    return (
      <div className="animating-board-homepage-first-fold-component">
        <div className="desktop-wrapper">
          <AnimatingBoardHomepageFirstFoldDesktopComponent {...this.props} />
        </div>

        <div className="mobile-wrapper">
          <AnimatingBoardHomepageFirstFoldMobileComponent {...this.props} />
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

AnimatingBoardHomepageFirstFoldComponent.defaultProps = {
  title: "Together. In workflows, projects, and success. Wherever you are.",
  body: "monday.com is the Work OS that powers teams to run projects and workflows with confidence.",
  videoId: "l-7awVpecvU"
};
