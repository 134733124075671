import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, MOBILE_QUERY_MAX_WIDTH_M } from "constants/vp-sizes";

export const componentStyles = css.global`
  .tags-grid-component {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px 56px;

    .grid-tag-component-wrapper {
      width: 300px;
      margin: 24px;
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH_M}) {
    .tags-grid-component {
      padding: 0px 16px !important;
    }
  }
`;
