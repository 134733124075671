import { defaultData as dynamicTemplateDefaultData } from "components/templates-generator/config/desktop-dynamic-template/default-data";
import { TEMPLATE_NAMES } from "constants/templates-names";
import { getSegmentsConfigs } from "segments/segments-config-service";

const isMobile = false;
const segmentsConfig = getSegmentsConfigs(isMobile);

export const defaultData = {
  ...dynamicTemplateDefaultData,
  baseLayoutStyleProps: { gridColumnStart: 2 },
  templateClassName: "developers-center-template",
  template: TEMPLATE_NAMES.DevelopersCenterTemplate,
  segments: [
    {
      type: segmentsConfig.DevelopersCenterHeaderComponent.type,
      props: {
        ...segmentsConfig.DevelopersCenterHeaderComponent.component.defaultProps,
        baseLayoutStyleProps: { gridColumnStart: 1, gridColumnEnd: 3 }
      }
    },
    {
      type: segmentsConfig.DevelopersAppsMenuStaticComponent.type,
      props: {
        ...segmentsConfig.DevelopersAppsMenuStaticComponent.component.defaultProps,
        baseLayoutStyleProps: { gridColumnStart: 1 }
      }
    }
  ]
};
