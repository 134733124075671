import dynamic from "next/dynamic";

const LogosAndHeaderSecurityComponent = require("./logos-and-header-security-component").default;

import LogosAndHeaderSecurityConfig from "./logos-and-header-security-config";
import LogosAndHeaderSecurityAdvancedConfig from "./logos-and-header-security-advanced-config";

export default {
  LogosAndHeaderSecurityComponent,
  LogosAndHeaderSecurityConfig,
  LogosAndHeaderSecurityAdvancedConfig
};
