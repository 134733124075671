import ReviewsCarouselDefaultProps from "segments/desktop/core-components/reviews-carousel/default-props";
import { XL } from "constants/sizes";
import FullParagraphDefaultProps from "../../../core-components/full-paragraph/default-props";

export default {
  titleSize: XL,
  alignToLogo: true,
  fullParagraph: {
    ...FullParagraphDefaultProps,
    topic: "",
    title: "<light>Hear<br>it from our<br></light><b>customers</b>",
    body: "Real people, real reviews"
  },
  carousel: {
    ...ReviewsCarouselDefaultProps
  },
  mobileMarginBetweenParagraphAndCarousel: "32px"
};
