import { PureComponent } from "react";
import { componentStyles } from "./triangle-with-color.scss";
import DefaultProps from "./default-props";
import colors from "styles/colors";

export default class TriangleWithColor extends PureComponent {
  getStyle() {
    const { color } = this.props;

    return {
      borderTopColor: colors.white,
      borderRightColor: color,
      borderBottomColor: color,
      borderLeftColor: colors.white
    };
  }

  render() {
    const style = this.getStyle();

    return (
      <div className="triangle-with-color" style={style}>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

TriangleWithColor.defaultProps = DefaultProps;
