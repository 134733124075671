import { FIELD_TYPES } from "constants/template-field-type";
import ThemeConfig from "segments/core-configs/theme-config";
import AssetFieldsConfig from "components/core/asset/fields-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Regular",
  fields: {
    ...ThemeConfig,
    position: {
      type: FIELD_TYPES.COMBO,
      label: "Position",
      options: ["left", "center", "right"]
    },
    asset: {
      type: FIELD_TYPES.NESTED,
      label: "Asset",
      fields: AssetFieldsConfig
    }
  }
};
