import { PureComponent } from "react";
import { componentStyles } from "./simpo-customer-success-component.scss";
import Simpo from "segments/desktop/core-components/simpo/simpo";
import Title from "segments/desktop/core-components/title/title";

export default class SimpoCustomerSuccessComponent extends PureComponent {
  render() {
    const { title, titleSize } = this.props;
    return (
      <div className="simpo-customer-success-component">
        {title && (
          <div className="simpo-title-wrapper">
            <Title title={title} titleSize={titleSize} />
          </div>
        )}
        <div className="simpo-wrapper">
          <Simpo />
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
