import PersonInfoDefaultProps from "segments/desktop/core-components/person-info/default-props";
import TestimonialsQuoteDefaultProps from "segments/desktop/core-components/testimonials-quote/default-props";
import { MD } from "constants/sizes";

export default {
  title: "Enjoy working in a platform that adapts to your needs<monday-dot pink />",
  titleSize: MD,
  ...PersonInfoDefaultProps,
  ...TestimonialsQuoteDefaultProps
};
