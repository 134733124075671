import FullParagraphDefaultProps from "segments/desktop/core-components/full-paragraph/default-props";
import SelectableTagsGridDefaultProps from "segments/desktop/grid/layouts/selectable-tags-grid/selectable-tags-grid-default-props";
import { LG, XXL } from "constants/sizes";
import { BRAND_DARK_COLOR_THEME_ID } from "segments/segments-service";

export default {
  theme: BRAND_DARK_COLOR_THEME_ID,
  fullParagraph: {
    ...FullParagraphDefaultProps,
    textAlign: "center",
    topic: {},
    title: "A platform built for a new way of working",
    titleWidth: "800px",
    body: "What would you like to manage with monday.com Work OS?",
    spaceFromParagraph: "40px",
    marginAboveTags: "16px"
  },
  selectableTags: { ...SelectableTagsGridDefaultProps },
  paddingTop: "96px",
  paddingBottom: "32px",
  titleSize: XXL,
  textSize: LG
};
