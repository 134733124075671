import { useEffect, useRef, useState } from "react";
import SelectableTag from "segments/desktop/core-components/selectable-tag/selectable-tag";
import classnames from "classnames";
import { GENERIC_CLUSTER } from "constants/clusters";
import { isDarkTheme } from "segments/segments-service";
import { getUrlParamValueFromCurrentLocation } from "utils/url";
import SelectableTagsGridButtonComponent from "./selectable-tags-grid-button-component/selectable-tags-grid-button-component";
import { updateUserClustersListWithMultipleClusters } from "/services/clusters-service";
import { HP_TAGS_SELECTION } from "/constants/clusters";
import { CLUSTER_TYPE, USE_CASE_TYPE } from "/constants/clusters/types.js";
import { componentStyles } from "./selectable-tags-grid.scss";
import DefaultProps from "./default-props";
import { withGenericConfig } from "/contexts/generic-config-context";
import { PRESELECTED_TAG_PARAM_NAME } from "../square-tags/square-tags";

const SelectableTagsGrid = (props) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const buttonContainer = useRef(null);
  const {
    onTagToggle,
    darkMode,
    gridSelectableTags,
    clusterId,
    clusterVariant,
    useMobileClusterTags,
    isMobileOrTabletBrowser,
    sendClustersAsParams,
    buttonConfig,
    align,
    secondaryButtonConfig,
    youTubeButtonConfig,
    withSecondaryButton,
    withYouTubeButton,
    width,
    theme,
    showGoogleConnectOnDesktop,
    buttonTopMargin,
    websiteAlignmentBranding
  } = props;

  const getSelectedTagFromUrl = () => {
    const selectedTagId = getUrlParamValueFromCurrentLocation(PRESELECTED_TAG_PARAM_NAME);
    if (!selectedTagId) return;

    return gridSelectableTags.find(
      (cluster) => cluster.overridePageCluster === selectedTagId || cluster.overridePageSubCluster === selectedTagId
    );
  };

  useEffect(() => {
    // pre-select tags that were marked with 'Initially Selected' or taken from URL
    setSelectedTags(gridSelectableTags.filter((tag) => tag.initiallySelected || tag === getSelectedTagFromUrl()));
  }, []);

  const onSelectableTagClick = (gridSelectableTag, index, isSelected) => {
    buttonContainer.current?.clickedTag({ tagIndex: index, ...gridSelectableTag }, isSelected);
    const newSelectedTags = isSelected
      ? [...selectedTags, { ...gridSelectableTag, index }]
      : selectedTags.filter((s) => s.index !== index);
    setSelectedTags(newSelectedTags);
    onTagToggle && onTagToggle(newSelectedTags);
  };

  const renderSelectableTags = (gridTag, index) => {
    const showMobileClusterTags = shouldShowMobileClusterTags();

    const gridSelectableTag = {
      ...gridTag,
      isSelected: selectedTags.find((tag) => gridTag.tagText === tag.tagText) !== undefined
    };
    return (
      <div className={classnames("grid-selectable-container", { "no-select": showMobileClusterTags })} key={index}>
        <SelectableTag
          {...gridSelectableTag}
          onClick={(isSelected) => onSelectableTagClick(gridSelectableTag, index, isSelected)}
          showMobileClusterTags={showMobileClusterTags}
          darkMode={darkMode}
        />
      </div>
    );
  };

  const renderSelectableTagsGrid = () => {
    return gridSelectableTags.map(renderSelectableTags);
  };

  const updateClustersBeforeSignup = () => {
    let extraParams = {};

    const clustersToUpdate = selectedTags.map((cluster) => {
      const { tagValue, overridePageCluster: clusterFromTag, overridePageSubCluster: useCaseFromTag } = cluster;
      const useCaseId = useCaseFromTag || tagValue; // tagValue is for old prop support
      const res = [];
      if (clusterFromTag) {
        res.push({ clusterId: clusterFromTag, clusterType: CLUSTER_TYPE });
      }
      if (useCaseId) {
        const clusterIdToUse = clusterFromTag || clusterId;
        const extraData = clusterIdToUse ? { parent_cluster_id: clusterIdToUse } : {};
        res.push({ clusterId: useCaseId, clusterType: USE_CASE_TYPE, extraData });
      }
      return res;
    });

    if (clustersToUpdate.length === 0 && clusterId) {
      clustersToUpdate.push({ clusterId, clusterType: CLUSTER_TYPE }); // if not tags selected use the page cluster
    }

    const flattenedArray = clustersToUpdate.flat();
    if (flattenedArray.length > 0) {
      updateUserClustersListWithMultipleClusters(flattenedArray, HP_TAGS_SELECTION);

      // This is temporarily here because we concat it to the url
      const clusterIds = [...new Set(flattenedArray.map((c) => c.clusterId).filter((c) => c !== GENERIC_CLUSTER))];
      extraParams = { clusterIds };
    }

    return { params: { ...extraParams, clusterVariant } };
  };

  const shouldShowMobileClusterTags = () => {
    return isMobileOrTabletBrowser && useMobileClusterTags;
  };

  const shouldSendClustersAsParams = () => {
    return shouldShowMobileClusterTags() && sendClustersAsParams;
  };

  const showMobileClusterTags = shouldShowMobileClusterTags();
  const displayedOnDarkTheme = isDarkTheme(theme);

  const selectableTagsGridButtonInlineStyle = {
    marginTop: buttonTopMargin
  };

  return (
    <div
      className={classnames("selectable-tags-grid", align, {
        "mobile-clusters": showMobileClusterTags
      })}
      style={{ width: showMobileClusterTags ? "100%" : width }}
    >
      <div className="selectable-tags-container-wrapper">{renderSelectableTagsGrid()}</div>

      <SelectableTagsGridButtonComponent
        ref={buttonContainer}
        buttonConfig={{ ...buttonConfig, forceInputHide: true, showGoogleConnectOnDesktop }}
        beforeRedirectSyncAction={updateClustersBeforeSignup}
        secondaryButtonConfig={withSecondaryButton && secondaryButtonConfig}
        youTubeButtonConfig={withYouTubeButton && youTubeButtonConfig}
        skipSignupUpdateCluster={true}
        shouldSendClustersAsParams={shouldSendClustersAsParams()}
        displayedOnDarkTheme={displayedOnDarkTheme}
        style={selectableTagsGridButtonInlineStyle}
        websiteAlignmentBranding={websiteAlignmentBranding}
      />

      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default withGenericConfig(SelectableTagsGrid);

SelectableTagsGrid.defaultProps = DefaultProps;
