// plop_marker:layouts_import
import MondayFormForm from "./monday-form-form";
import HubspotWithBulletsSideBySideForm from "./hubspot-with-bullets-side-by-side-form";
import HubspotAndLogosForm from "./hubspot-and-logos-form";
import ZendeskSideBySideForm from "./zendesk-side-by-side-form";
import ZendeskForm from "./zendesk-form";
import HubspotSideBySideForm from "./hubspot-side-by-side-form";

export default {
  // plop_marker:layouts_add_layout
  MondayFormForm,
  HubspotWithBulletsSideBySideForm,
  HubspotAndLogosForm,
  ZendeskSideBySideForm,
  ZendeskForm,
  HubspotSideBySideForm
};
