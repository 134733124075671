import css from "styled-jsx/css";
import Colors from "styles/colors";

export const componentStyles = css.global`
  .person-info {
    display: flex;
    padding-top: 30px;
    .avatar-image {
      height: 60px;
    }
    .person-text {
      .person {
        font-weight: 400;
        color: ${Colors["text-blue"]};
      }
      .person-name {
        font-weight: 700;
      }
      font-size: 0.875rem;
      height: 60px;
      display: flex;
      padding-left: 18px;
      flex-direction: column;
      justify-content: center;
      line-height: 1.6;
    }
  }
`;
