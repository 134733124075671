import { useEffect, useState } from "react";
import { isServerSide } from "utils/general";

function getSize() {
  if (isServerSide()) {
    return { width: undefined, height: undefined };
  }

  return {
    width: window?.innerWidth || document?.documentElement?.clientWidth || document?.body?.clientWidth,
    height: window?.innerHeight || document?.documentElement?.clientHeight || document?.body?.clientHeight
  };
}

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getSize());

  useEffect(() => {
    if (isServerSide()) {
      return false;
    }
    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}
