import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
import AssetAdvancedConfig from "components/core/asset/advanced-field-config";

export default {
  ...SharedAdvancedFieldsConfig,
  timerEnabled: { type: FIELD_TYPES.BOOLEAN, label: "Timer enabled" },
  alwaysShowProgressbar: { type: FIELD_TYPES.BOOLEAN, label: "Always show progress bar" },
  arrowSize: { type: FIELD_TYPES.TEXT, label: "Arrow size" },
  title: {
    type: FIELD_TYPES.NESTED,
    label: "Title",
    fields: {
      fontSize: { type: FIELD_TYPES.TEXT, label: "Font size" },
      selectedFontSize: { type: FIELD_TYPES.TEXT, label: "Selected font size" },
      fontColor: { type: FIELD_TYPES.COLOR_PICKER, label: "Font color" },
      selectedFontColor: { type: FIELD_TYPES.COLOR_PICKER, label: "Selected font color" },
    }
  },
  ...AssetAdvancedConfig
};