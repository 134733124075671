import css from "styled-jsx/css";

export const componentStyles = css.global`
  .globe-mobile-homepage-first-fold-component {
    .globe-text-and-asset-content {
      .image-wrapper {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
`;
