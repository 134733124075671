import { useState } from "react";
import PictureComponent from "/components/core/picture/picture-component";
import SelectableTagsGrid from "segments/desktop/core-components/selectable-tags-grid/selectable-tags-grid";
import Carousel from "segments/desktop/core-components/carousel/carousel";
import DefaultProps from "./default-props";
import { trackEvent } from "/services/bigbrain-service";
import { CAROUSEL_SWIPED, CAROUSEL_SLIDE_CHANGED } from "/constants/bigbrain-event-types";
import { componentStyles } from "./selectable-tags-with-carousel-grid-component.scss";

const SelectableTagsWithCarouselGridComponent = (props) => {
  const { tagsAlign, gridSelectableTags } = props;

  const [ selectedIndex, setSelectedIndex ] = useState(null);

  const onTagToggle = values => {
    const newIndex = values.length >= 1 ? values[values.length - 1]?.index : null;
    setSelectedIndex(newIndex);
  }

  const itemRenderer = image => {
    return (<PictureComponent className="cluster-tag-image" src={image?.src} alt={image?.alt} />)
  }

  const onSwipe = direction => {
    trackEvent(CAROUSEL_SWIPED, { kind: direction });
  }

  const onCarouselSlideChange = current => {
    trackEvent(CAROUSEL_SLIDE_CHANGED, { kind: current });
  }

  const tagsImages = (gridSelectableTags || []).map(item => item?.galleryImage);

  return (
    <div className="selectable-tags-with-carousel-grid-component">
      <div className="selectable-tags-grid-wrapper">
        <SelectableTagsGrid {...props} align={tagsAlign} onTagToggle={onTagToggle} />
      </div>

      <div className="carousel-wrapper">
        <Carousel {...props}
          items={tagsImages}
          selectedIndex={selectedIndex}
          itemRenderer={itemRenderer}
          onSwipe={onSwipe}
          onChange={onCarouselSlideChange} />
      </div>

      <style jsx>{componentStyles}</style>
    </div>
  );
}

SelectableTagsWithCarouselGridComponent.defaultProps = DefaultProps;

export default SelectableTagsWithCarouselGridComponent;
