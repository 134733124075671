import css from "styled-jsx/css";
import { TOP_LAYER, LAYER_ONE } from "constants/z-index";

export const componentStyles = css.global`
  .video-background {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    .video-wrapper {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      position: relative;
      .dark-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: ${TOP_LAYER};
      }
    }
    .full-paragraph-wrapper {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      z-index: ${LAYER_ONE};
      display: flex;
      .full-paragraph-component {
        margin-left: 100px;
        margin-top: 200px;
        .full-paragraph-wrapper {
          .full-paragraph {
            .button-wrapper {
              margin-top: 12px;
            }
          }
        }
      }
    }
  }
`;
