import { componentStyles } from "./product-tag.scss";
import { withGenericConfig } from "contexts/generic-config-context";
import {COLORS_BY_PRODUCTS} from "./consts";
import { trackEvent } from "/services/bigbrain-service";
import { SIGNUP_PRODUCT_TAG_HOVERED } from "/constants/bigbrain-event-types";


const ProductTag =(props) => {
  const {index, tag, translate, productId} = props;
  const color = COLORS_BY_PRODUCTS[productId]?.color;
  const backgroundColor = COLORS_BY_PRODUCTS[productId]?.backgroundColor;

  const onTagMouseEnter = () => {
      trackEvent(SIGNUP_PRODUCT_TAG_HOVERED, {
      kind: productId,
      info1: translate(tag),
      info2: index
    });
  };

  return (
        <div key={index} className="tag" style={{color: color, backgroundColor: backgroundColor}} onMouseEnter={onTagMouseEnter}>
            {translate(tag)}
            <style jsx>{componentStyles}</style>
        </div>  );
};

export default withGenericConfig(ProductTag);
