import { FIELD_TYPES } from "constants/template-field-type";
import ThemeConfig from "segments/core-configs/theme-config";
import { PARAGRAPH_AND_ASSET_VIDEOS } from "/constants/video-types";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Youtube Video Popup",
  fields: {
    ...ThemeConfig,
    backgroundImg: {
      type: FIELD_TYPES.GALLERY,
      defaultImagesKeys: [],
      label: "Select an image placeholder"
    },
    alt: { type: FIELD_TYPES.TEXT, label: "Description (alt)", translatable: true },
    videoPlaceholder: {
      type: FIELD_TYPES.CLOUDINARY_VIDEO_PICKER,
      label: "Video placeholder",
      labelDescription: "Using this will override the image placeholder",
      videoType: PARAGRAPH_AND_ASSET_VIDEOS
    },
    videoId: {
      type: FIELD_TYPES.TEXT,
      label: "Video ID"
    }
  }
};
