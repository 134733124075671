import TitleAdvancedConfig from "segments/desktop/core-components/title/advanced-field-config";
import ParagraphBodyAdvancedConfig from "segments/desktop/core-components/paragraph/advanced-fields-config";
import { FIELD_TYPES } from "constants/template-field-type";

export default {
  ...TitleAdvancedConfig,
  ...ParagraphBodyAdvancedConfig,
  topicBottomMargin: {
    type: FIELD_TYPES.TEXT,
    label: "Margin between topic and paragraph (defaults: text - 16px | image - 40px)"
  },
  spaceFromParagraph: { type: FIELD_TYPES.TEXT, label: "Margin between title and text (px)" },
  buttonTopMargin: { type: FIELD_TYPES.TEXT, label: "Margin above button (default 32px)" }
};
