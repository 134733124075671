import { FIELD_TYPES } from "constants/template-field-type";
import SideBySideTextAndAssetFieldsConfig from "segments/desktop/core-components/side-by-side-text-and-asset/fields-config";
import ThemeConfig from "segments/core-configs/theme-config";
import BoardTabFields from "segments/desktop/core-components/board-tabs/fields-config";

const { asset, ...selectedSideBySideTextAndAssetFieldsConfig } = SideBySideTextAndAssetFieldsConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "Board tab side by side",
  fields: {
    ...ThemeConfig,
    ...selectedSideBySideTextAndAssetFieldsConfig,
    boardTabsProps: {
      type: FIELD_TYPES.NESTED,
      label: "Board tabs",
      fields: BoardTabFields
    }
  }
};
