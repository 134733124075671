import { PureComponent } from "react";
import { componentStyles } from "./youtube-video-asset-component.scss";
import YoutubeVideoWithPosterComponent from "components/core/youtube-video-with-poster/youtube-video-with-poster-component";
import YoutubeVideoModalComponent from "components/core/youtube-video-modal/youtube-video-modal-component";
import { YOUTUBE_MODAL_OPEN, YOUTUBE_MODAL_CLOSE } from "/constants/bigbrain-event-types";
import { AUTO } from "/constants/cloudinary-asset-qualities";
import AssetComponent from "/components/core/asset/asset-component";
import classnames from "classnames";

const DEFAULT_WIDTH_PRECENTAGE = 100;
const VIDEO = "video";
const IMAGE = "image";

export default class YoutubeVideoAssetComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { openModal: false, windowWidth: 780, windowHeight: 928 };

    this.onPlayButtonClicked = this.onPlayButtonClicked.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    const { videoId } = this.props;
    BigBrain("track", YOUTUBE_MODAL_OPEN, { kind: videoId });
    this.setState({ windowHeight: window.innerHeight, windowWidth: window.innerWidth });
  }

  onPlayButtonClicked() {
    const { videoId } = this.props;
    BigBrain("track", YOUTUBE_MODAL_OPEN, { kind: videoId });
    this.setState({ windowHeight: window.innerHeight - 100, windowWidth: window.innerWidth - 100 }, () =>
      this.setState({ openModal: true })
    );
  }

  closeModal() {
    const { videoId } = this.props;
    BigBrain("track", YOUTUBE_MODAL_CLOSE, { kind: videoId });
    this.setState({ openModal: false });
  }

  renderPlayButton = () => {
    return (
      <button className="play-button-container">
        <div className="play-button" />
      </button>
    );
  };

  getPreviewAsset = () => {
    const { backgroundImg, videoPlaceholder, alt } = this.props;

    if (videoPlaceholder) {
      return { [VIDEO]: { src: videoPlaceholder, loop: true, ariaLabel: alt } };
    } else {
      return { [IMAGE]: { src: backgroundImg, pictureClass: "poster-image", alt: alt } };
    }
  };

  renderDesktopPreview = () => {
    return <div className="poster-image-wrapper">{this.previewRenderer(this.onPlayButtonClicked)}</div>;
  };

  previewRenderer = (onClick) => {
    const { assetQuality } = this.props;

    const asset = this.getPreviewAsset();

    return (
      <div onClick={onClick}>
        <AssetComponent {...asset} assetQuality={assetQuality} />
        {this.renderPlayButton()}
      </div>
    );
  };

  render() {
    const { openModal, windowHeight, windowWidth, previewWidth } = this.state;
    const { videoId, backgroundImg, isMobileOrTabletBrowser, mobileWidth, width, alt, fullWidth } = this.props;
    const finalWidth = isMobileOrTabletBrowser && mobileWidth ? mobileWidth : width;
    const widthInPercentage = finalWidth ? `${finalWidth}%` : "100%";

    return (
      <div className={classnames("youtube-video-asset-component",{ "full-width": fullWidth })}>
        <YoutubeVideoModalComponent
          videoId={videoId}
          minHeight={546}
          isOpen={openModal}
          opts={{
            width: windowWidth,
            height: windowHeight,
            playerVars: {
              autoplay: true,
              rel: 0,
              showinfo: 0
            }
          }}
          onCloseModal={this.closeModal}
          withSignup={false}
        />
        <div className="video-part" style={{ width: widthInPercentage }}>
          {isMobileOrTabletBrowser ? (
            <div className="video-component-wrapper">
              <YoutubeVideoWithPosterComponent
                videoId={videoId}
                poster={backgroundImg}
                alt={alt}
                muted={false}
                volume={100}
                previewRenderer={this.previewRenderer}
                fitToParent={true}
                videoOpts={{
                  playerVars: {
                    autoplay: true,
                    rel: 0,
                    showinfo: 0
                  }
                }}
              />
            </div>
          ) : (
            this.renderDesktopPreview()
          )}
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
YoutubeVideoAssetComponent.defaultProps = {
  backgroundImg: "/static/img/tags/video_image.png",
  videoId: "l-7awVpecvU",
  width: DEFAULT_WIDTH_PRECENTAGE,
  mobileWidth: DEFAULT_WIDTH_PRECENTAGE,
  assetQuality: AUTO
};
