import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
import FullParagraphAdvancedFieldsConfig from "segments/desktop/core-components/full-paragraph/advanced-fields-config";
import AssetAdvancedConfig from "components/core/asset/advanced-field-config";
import SideBySideTextAndAssetFieldsConfig from "segments/desktop/core-components/side-by-side-text-and-asset/fields-config";
import SideBySideTextAndAssetAdvancedFieldsConfig from "segments/desktop/core-components/side-by-side-text-and-asset/advanced-field-config";

const {
  swapTextAndAssetPosition,
  fullParagraph,
  asset,
  ...selectedSideBySideTextAndAssetFieldsConfig
} = SideBySideTextAndAssetFieldsConfig;

export default {
  type: FIELD_TYPES.NESTED,
  label: "",
  fields: {
    ...SharedAdvancedFieldsConfig,
    ...FullParagraphAdvancedFieldsConfig,
    ...AssetAdvancedConfig,
    ...selectedSideBySideTextAndAssetFieldsConfig,
    ...SideBySideTextAndAssetAdvancedFieldsConfig
  }
};
