import css from "styled-jsx/css";
import { TABS_WIDTH, FULL_ASSET_WIDTH } from "segments/desktop/core-components/board-tabs/board-tab-consts";

const FULL_COMPONENT_SIZE_PX = `${TABS_WIDTH + FULL_ASSET_WIDTH}px`;
const FULL_ASSET_WIDTH_PX = `${FULL_ASSET_WIDTH}px`;

export const componentStyles = css.global`
  .board-tabs-component {
    width: ${FULL_COMPONENT_SIZE_PX};
    margin: auto;
    display: flex;
    justify-content: center;

    .board-content-asset-wrapper {
      min-height: 521px;
      width: ${FULL_ASSET_WIDTH_PX};
    }
  }
`;
