import { PureComponent } from "react";
import { componentStyles } from "./full-paragraph-component.scss";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import FullParagraphDefaultProps from "segments/desktop/core-components/full-paragraph/default-props";
import { MD } from "constants/sizes";

export default class FullParagraphComponent extends PureComponent {
  render() {
    const { maxWidth } = this.props;

    return (
      <div className="full-paragraph-component">
        <div className="full-paragraph-wrapper" style={{ maxWidth }}>
          <FullParagraph {...this.props} />
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

FullParagraphComponent.defaultProps = {
  ...FullParagraphDefaultProps,
  paddingTop: "64px",
  paddingBottom: "64px",
  titleSize: MD,
  textSize: MD,
  maxWidth: "1100px"
};
