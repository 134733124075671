import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, MOBILE_QUERY_MAX_WIDTH_M } from "constants/vp-sizes";
import Colors from "styles/colors";

export const componentStyles = css.global`
  .cards-grid-mobile-component {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
    margin: 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .grid-image-with-paragraph-component-wrapper {
      width: 100%;
      height: 101px;
      background-color: ${Colors["white"]};
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
      margin: 8px 0;
      display: flex;

      .inner-frame {
        display: flex;
        padding: 8px;

        .grid-image-wrapper {
          height: 100%;
        }

        .mobile-paragraph-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 12px;

          .title-wrapper {
            .title {
              font-weight: bold;
              font-size: 1.069rem;
              line-height: 24px;
            }
          }

          .button-wrapper {
            padding-top: 8px;
          }
        }
      }
    }
  }
  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH_M}) {
    .cards-grid-mobile-component {
      .grid-image-with-paragraph-component-wrapper {
        .inner-frame {
          .mobile-paragraph-wrapper {
            .title-wrapper {
              .title {
                font-weight: 400;
                font-size: 0.875rem;
              }
            }

            .button-wrapper {
              .secondary-button {
                font-size: 0.8125rem;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
`;
