import VerticalTimerTabs from "segments/desktop/core-components/vertical-timer-tabs/vertical-timer-tabs";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import { XS } from "constants/sizes";
import { componentStyles } from "./side-by-side-tabs-and-testimonial-tabs-component.scss";
import DefaultProps from "./default-props";
import Testimonial from "segments/desktop/core-components/testimonial/testimonial";

const SideBySideTabsAndTestimonialTabsComponent = ({ tabContent, ...rest }) => {
  const contentRenderer = (contentObject) => <FullParagraph {...contentObject} {...tabContent} titleSize={XS} />;
  const testimonialRenderer = ({ testimonial }) => <Testimonial {...testimonial} />;

  return (
    <div className="side-by-side-tabs-and-testimonial-tabs-component">
      <VerticalTimerTabs {...rest} tabContentRenderer={contentRenderer} rightSideRenderer={testimonialRenderer} />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

SideBySideTabsAndTestimonialTabsComponent.defaultProps = DefaultProps;

export default SideBySideTabsAndTestimonialTabsComponent;
