import css from "styled-jsx/css";
import Colors from "/styles/colors";
import { MOBILE_QUERY_MAX_WIDTH_M } from "constants/vp-sizes";
import {getComponentShadow} from "segments/desktop/constants";
import Constants from "./constants";

const REVIEW_CARD_HEIGHT = `${Constants.REVIEW_CARD_HEIGHT}px`;
const REVIEW_CARD_WIDTH = `${Constants.REVIEW_CARD_WIDTH}px`;
const MOBILE_REVIEW_CARD_WIDTH = `${Constants.MOBILE_REVIEW_CARD_WIDTH}px`;

export const componentStyles = css.global`
  .review-card {
    height: ${REVIEW_CARD_HEIGHT};
    width: ${REVIEW_CARD_WIDTH};
    background: ${Colors.white};
    ${getComponentShadow()}
    display: flex;
    flex-direction: column;
    align-items: center;

    .review-card-color-decoration {
      height: 10px;
      width: 100%;
    }

    .review-card-quote-title {
      width: 100%;
      flex-grow: 1;
      padding: 32px;
      text-align: center;
      overflow: hidden;
      line-height: 36px;
    }

    .review-card-stars {
      margin-bottom: 16px;
    }

    .review-card-quote-name {
      text-align: center;
      margin-bottom: 24px;
      padding: 0 16px;
      .review-card-person-info {
        font-size: 1rem;
      }
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH_M}) {
    .review-card {
      width: ${MOBILE_REVIEW_CARD_WIDTH};

      .review-card-quote-title {
        font-size: 1rem !important; // overide inline style
        line-height: 30px !important;
      }

      .review-card-quote-name {
        .review-card-person-info {
          font-size: 0.844rem;
        }
      }
    }
  }
`;
