import classnames from "classnames";
import { componentStyles } from "./mobile-tab-nav-plain-button.scss";

const MobileTabNavPlainButton = (props) => {
  const { tabNumber, label, selected, theme } = props;
  return (
    <>
      <div className={classnames("tab-nav-plain-button", { selected }, theme)}>
        {tabNumber && <span className="tab-nav-plain-button-text-number">{`${tabNumber}. `}</span>}
        <span className="tab-nav-plain-button-text-label">{label}</span>
      </div>
      <style jsx>{componentStyles}</style>
    </>
  );
};

export default MobileTabNavPlainButton;
