import css from "styled-jsx/css";
import {MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, SM} from "constants/vp-sizes";
import DesktopHeaderConstants from "segments/desktop/header/layouts/components/basic-header/constants";
import BodyConstants from "segments/desktop/constants";

const HEADER_LOGO_HORIZONTAL_PADDING = `${DesktopHeaderConstants.HEADER_CONTENT_HORIZONTAL_PADDING}px`;
const BODY_HORIZONTAL_PADDING = `${BodyConstants.BODY_CONTENT_HORIZONTAL_PADDING}px`;

export const componentStyles = css`
  .masonry-grid-component {
    max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
    margin: auto;

    .grid-container {
      display: grid;
      grid-template-areas:
        "column1      battery       phone   automation  comment         .    "
        "column2      battery       phone       .           .           .    "
        "column2      battery       phone   dashboard   dashboard   dashboard"
        "integration  integration   phone   dashboard   dashboard   dashboard"
        "   .         pie           phone   dashboard   dashboard   dashboard"
        "   .            .            .     dashboard   dashboard   dashboard";

      grid-template-columns: 12.64vw 11.54vw 14.79vw 14.72vw 12.64vw 18%;
      grid-template-rows: 5vw 2.5vw 1.25vw 6.39vw 9.17vw auto;
      grid-gap: 1.67vw;
      padding-left: ${BODY_HORIZONTAL_PADDING};

      &.align-to-logo {
        padding-left: ${HEADER_LOGO_HORIZONTAL_PADDING};
      }
    }

    .item1 {
      grid-area: column1;
    }
    .item2 {
      grid-area: column2;
    }
    .item3 {
      grid-area: battery;
    }
    .item4 {
      grid-area: integration;
    }
    .item5 {
      grid-area: pie;
    }
    .item6 {
      grid-area: phone;
    }
    .item7 {
      grid-area: automation;
    }
    .item8 {
      grid-area: comment;
    }
    .item9 {
      grid-area: dashboard;
    }

    .item {
      filter: drop-shadow(-10.8923px 14.523px 35.0973px rgba(29, 140, 242, 0.2));
      border-radius: 8px;
      z-index: -1;
      :global(.asset-inner) {
        width: 100%;
        height: auto;
      }
      :global(img),
      :global(video) {
        border-radius: 8px;
      }
      &.timeline,
      &.status,
      &.battery,
      &.mobile {
        background-color: white;
      }
      &.status {
        display: flex;
      }
      &.battery,
      &.integration,
      &.pie {
        z-index: 0;
      }
      &.battery {
        display: inherit;
        :global(.asset-inner) {
          margin: auto;
        }
      }
      &.mobile {
        padding: 7px;
        border-radius: 24px;
        :global(.asset-inner) {
          filter: drop-shadow(-8.52044px 11.3606px 27.4548px rgba(29, 140, 242, 0.3));
        }
        :global(img),
        :global(video) {
          border-radius: 24px;
        }
      }
      &.dashboard {
        filter: none;
        min-width: 638.81px;
        :global(.window-mask) {
          padding: 0;
          :global(.video-component) {
            height: unset;
          }
          :global(img),
          :global(video) {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
          }
        }
      }
    }
  }

  @media (min-width: ${SM + 1}px) and (max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`}) {
    .masonry-grid-component {
      .grid-container {
        grid-template-columns: 182px 166.19px 213px 211.96px 182px auto;
        grid-template-rows: 72px 36px 12px 92px 132px auto;
        grid-gap: 24px;
      }
    }
  }

  @media (max-width: ${`${SM}px`}) {
    .masonry-grid-component {
      margin: auto;

      .grid-container {
        display: grid;
        grid-template-areas:
          "phone automation comment"
          "phone . ."
          "phone column1 battery"
          "phone column2 battery"
          "phone integration integration";

        grid-template-columns: 33% 29% 28%;
        grid-template-rows: 24% 2% 18% 18% 22%;
        grid-gap: 4%;
        padding: 10px;

        &.align-to-logo {
          padding-left: 10px;
        }
      }

      .item {
        box-shadow: -10.8923px 14.523px 35.0973px rgba(29, 140, 242, 0.2);
        &.dashboard {
          filter: none;
          min-width: unset;
        }
        &.mobile {
          padding: 7px;
          border-radius: 24px;
          :global(img),
          :global(video) {
            border-radius: 14px;
          }
        }
        &.dashboard,
        &.pie {
          display: none;
        }
      }
    }
  }
`;
