import { FIELD_TYPES } from "constants/template-field-type";
import SelectableTagsGridFieldsConfig from "segments/desktop/core-components/selectable-tags-grid/fields-config";
import CarouselFieldsConfig from "segments/desktop/core-components/carousel/fields-config";
import { ALIGN_TYPES, ALIGN_LABELS } from "constants/align-types";

export default {
  type: FIELD_TYPES.NESTED,
  label: "Tags With Carousel",
  fields: {
    tagsAlign: {
      type: FIELD_TYPES.COMBO,
      label: "Tags Align",
      options: [
        { value: ALIGN_TYPES.LEFT, text: ALIGN_LABELS.LEFT },
        { value: ALIGN_TYPES.CENTER, text: ALIGN_LABELS.CENTER },
        { value: ALIGN_TYPES.RIGHT, text: ALIGN_LABELS.RIGHT }
      ]
    },
    ...CarouselFieldsConfig,
    ...SelectableTagsGridFieldsConfig,
  }
};
