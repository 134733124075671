import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
import FullParagraphAdvancedFieldsConfig from "segments/desktop/core-components/full-paragraph/advanced-fields-config";
import SelectableTagsGridAdvancedFieldsConfig
  from "segments/desktop/core-components/selectable-tags-grid/advanced-field-config";

export default {
  type: FIELD_TYPES.NESTED,
  label: "",
  fields: {
    ...SharedAdvancedFieldsConfig,
    fullParagraph: {
      type: FIELD_TYPES.NESTED,
      label: "Full paragraph",
      fields: {
        ...FullParagraphAdvancedFieldsConfig
      }
    },
    selectableTags: {
      type: FIELD_TYPES.NESTED,
      label: "Selectable tags",
      fields: {
        ...SelectableTagsGridAdvancedFieldsConfig
      }
    }
  }
};
