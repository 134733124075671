import {
  inputTypes,
  FIRSTNAME_INPUT,
  LASTNAME_INPUT,
  PHONE_INPUT,
  EMAIL_INPUT,
  COMPANY_SIZE_INPUT,
  CHECKBOX_INPUT
} from "client/services/form-service/form-inputs";

export const ZENDESK_FIRST_NAME_INPUT_NAME = "first_name";
export const ZENDESK_LAST_NAME_INPUT_NAME = "last_name";
export const ZENDESK_EMAIL_INPUT_NAME = "email";
export const ZENDESK_PHONE_NUM_INPUT_NAME = "phone_num";
export const ZENDESK_COMPANY_SIZE_INPUT_NAME = "company_size";
export const ZENDESK_TOPIC_INPUT_NAME = "topic";
export const ZENDESK_TICKET_SUBJECT_NAME = "subject";
export const ZENDESK_TICKET_DESCRIPTION_NAME = "description";
export const ZENDESK_TICKET_CONTACT_CONSENT_CHECKBOX_NAME = "contact_consent";

const TICKET_TOPIC_OPTIONS = [
  {
    label: "forms.zendesk.tickets.category.topic.gettingStarted",
    zendeskValue: "form_how_do_i"
  },
  {
    label: "forms.zendesk.tickets.category.topic.workflow",
    zendeskValue: "form_workflow_questions"
  },
  { label: "forms.zendesk.tickets.category.topic.billing", zendeskValue: "form_billing" },
  {
    label: "forms.zendesk.tickets.category.topic.automationsAndIntegrations",
    zendeskValue: "form_automations/integrations"
  },
  {
    label: "forms.zendesk.tickets.category.topic.appsMarketplace",
    zendeskValue: "form_apps/api_markteplace"
  },
  {
    label: "forms.zendesk.tickets.category.topic.notWorking",
    zendeskValue: "form_something_is_not_working"
  },
  {
    label: "forms.zendesk.tickets.category.topic.legalAndSecurity",
    zendeskValue: "form_legal_security"
  },
  {
    label: "forms.zendesk.tickets.category.topic.login",
    zendeskValue: "form_having_trouble_logging_in"
  },
  {
    label: "forms.zendesk.tickets.category.topic.signup",
    zendeskValue: "form_having_trouble_signup"
  },
  {
    label: "forms.zendesk.tickets.category.topic.featureRequest",
    zendeskValue: "form_feature_request"
  },
  {
    label: "forms.zendesk.tickets.category.topic.mobile",
    zendeskValue: "form_mobile"
  },
  { label: "forms.zendesk.tickets.category.topic.other", zendeskValue: "form_other" }
];

const getTicketTopicOptions = () => {
  return TICKET_TOPIC_OPTIONS.map((option) => option.label);
};

export const getTopicZendeskValue = (topicLabel) => {
  const option = TICKET_TOPIC_OPTIONS.filter((option) => option.label === topicLabel)[0];
  return option?.zendeskValue;
};

export const getTopicOptionLabelByZendeskValue = (valueToFind) => {
  const option = TICKET_TOPIC_OPTIONS.find((option) => {
    return option.zendeskValue === valueToFind;
  });
  return option?.label;
};

const ZENDESK_FIRSTNAME_INPUT = {
  ...FIRSTNAME_INPUT,
  inputName: ZENDESK_FIRST_NAME_INPUT_NAME,
  isRequired: true
};

const ZENDESK_LASTNAME_INPUT = {
  ...LASTNAME_INPUT,
  inputName: ZENDESK_LAST_NAME_INPUT_NAME,
  isRequired: true
};

const ZENDESK_EMAIL_INPUT = {
  ...EMAIL_INPUT,
  inputName: ZENDESK_EMAIL_INPUT_NAME,
  isRequired: true
};

const ZENDESK_PHONE_INPUT = {
  ...PHONE_INPUT,
  inputName: ZENDESK_PHONE_NUM_INPUT_NAME,
  isRequired: false,
  validate: null
};

const ZENDESK_COMPANYSIZE_INPUT = {
  ...COMPANY_SIZE_INPUT,
  inputName: ZENDESK_COMPANY_SIZE_INPUT_NAME,
  isRequired: false
};

const ZENDESK_TOPIC_INPUT = {
  id: "topic",
  type: inputTypes.SELECT,
  title: "forms.zendesk.tickets.topicQuestion",
  options: getTicketTopicOptions(),
  inputName: ZENDESK_TOPIC_INPUT_NAME,
  isRequired: true,
  defaultValue: "forms.zendesk.tickets.topicQuestionPlaceholder"
};

const ZENDESK_TICKET_SUBJECT = {
  id: "subject",
  inputName: ZENDESK_TICKET_SUBJECT_NAME,
  type: inputTypes.TEXT,
  isRequired: true,
  title: "forms.zendesk.tickets.subjectQuestion"
};

const ZENDESK_DESCRIPTION_INPUT = {
  id: "description",
  inputName: ZENDESK_TICKET_DESCRIPTION_NAME,
  type: inputTypes.TEXT_AREA,
  isRequired: false,
  placeholder: "forms.zendesk.tickets.detailsPlacholder",
  title: "forms.zendesk.tickets.detailsQuestion"
};

const ZENDESK_CONTACT_CONSENT_CHECKBOX = {
  ...CHECKBOX_INPUT,
  inputName: ZENDESK_TICKET_CONTACT_CONSENT_CHECKBOX_NAME,
  isRequired: false
};

export const getZendeskFormConfig = () => {
  return {
    rows: [
      [ZENDESK_FIRSTNAME_INPUT, ZENDESK_LASTNAME_INPUT],
      [ZENDESK_EMAIL_INPUT],
      [ZENDESK_PHONE_INPUT, ZENDESK_COMPANYSIZE_INPUT],
      [ZENDESK_TOPIC_INPUT],
      [ZENDESK_TICKET_SUBJECT],
      [ZENDESK_DESCRIPTION_INPUT],
      [ZENDESK_CONTACT_CONSENT_CHECKBOX]
    ]
  };
};
