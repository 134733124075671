import { useEffect, useState } from "react";
import AssetComponent from "components/core/asset/asset-component";
import WindowMask from "segments/desktop/core-components/window-mask/window-mask";
import classnames from "classnames";
import VisibilitySensor from "react-visibility-sensor";
import DefaultProps from "./default-props";
import { componentStyles } from "./masonry-grid-component.scss";

const MasonryGridComponent = (props) => {
  const { assets, hideControls, alignToLogo } = props;

  const [wasComponentEverVisible, setWasComponentEverVisible] = useState(false);
  const [assetTimingsProps, setAssetTimingsProps] = useState([]);

  const VISIBILITY_SENSOR_OFFSET = 100;

  const updateAssetTimingsProps = ({ index }) => {
    const updatedAssetTimingsProps = [...assetTimingsProps];
    updatedAssetTimingsProps[index] = {
      ...assets?.[index]?.asset,
      video: {
        ...assets?.[index]?.asset?.video,
        autoPlay: true
      }
    };

    setAssetTimingsProps(updatedAssetTimingsProps);
  };

  useEffect(() => {
    if (wasComponentEverVisible) {
      assets?.forEach(({ asset }, index) => {
        setTimeout(() => {
          updateAssetTimingsProps({ index });
        }, Number(asset?.video?.startAfter) || 0);
      });
    }
    return () => {};
  }, [wasComponentEverVisible]);

  const onVisibilityChange = (isVisible) => {
    if (!wasComponentEverVisible && isVisible) {
      setWasComponentEverVisible(true);
    }
  };

  return (
    <VisibilitySensor
      offset={{ bottom: VISIBILITY_SENSOR_OFFSET, top: VISIBILITY_SENSOR_OFFSET }}
      partialVisibility={true}
      onChange={onVisibilityChange}
      active={!wasComponentEverVisible} // turn it off once the component becomes visible
      scrollCheck={!wasComponentEverVisible} // turn it off once the component becomes visible
    >
      <div className="masonry-grid-component">
        <div className={classnames("grid-container", { "align-to-logo": alignToLogo })}>
          <div className={classnames("item", "item1", "timeline")}>
            <AssetComponent {...assets?.[0]?.asset} hideControls={hideControls} {...assetTimingsProps?.[0]} />
          </div>
          <div className={classnames("item", "item2", "status")}>
            <AssetComponent {...assets?.[1]?.asset} hideControls={hideControls} {...assetTimingsProps?.[1]} />
          </div>
          <div className={classnames("item", "item3", "battery")}>
            <AssetComponent {...assets?.[2]?.asset} hideControls={hideControls} {...assetTimingsProps?.[2]} />
          </div>
          <div className={classnames("item", "item4", "integration")}>
            <AssetComponent {...assets?.[3]?.asset} hideControls={hideControls} {...assetTimingsProps?.[3]} />
          </div>
          <div className={classnames("item", "item5", "pie")}>
            <AssetComponent {...assets?.[4]?.asset} hideControls={hideControls} {...assetTimingsProps?.[4]} />
          </div>
          <div className={classnames("item", "item6", "mobile")}>
            <AssetComponent {...assets?.[5]?.asset} hideControls={hideControls} {...assetTimingsProps?.[5]} />
          </div>
          <div className={classnames("item", "item7", "automation")}>
            <AssetComponent {...assets?.[6]?.asset} hideControls={hideControls} {...assetTimingsProps?.[6]} />
          </div>
          <div className={classnames("item", "item8", "comment")}>
            <AssetComponent {...assets?.[7]?.asset} hideControls={hideControls} {...assetTimingsProps?.[7]} />
          </div>
          <div className={classnames("item", "item9", "dashboard")}>
            <WindowMask>
              <AssetComponent {...assets?.[8]?.asset} hideControls={hideControls} {...assetTimingsProps?.[8]} />
            </WindowMask>
          </div>
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    </VisibilitySensor>
  );
};

MasonryGridComponent.defaultProps = DefaultProps;

export default MasonryGridComponent;
